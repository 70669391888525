import { type ReactNode } from 'react';

import styles from './SideFileViewer.module.css';

type Props = {
  children: ReactNode;
};

export function SideFileViewer({ children }: Props) {
  return <div className={styles.viewer}>{children}</div>;
}
