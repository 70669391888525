import { Button, EmptyState, HighlightIcon } from '@dev-spendesk/grapes';
import React from 'react';
import { useSelector } from 'react-redux';

import { DownloadAppIllustration } from 'common/components/DownloadAppIllustration';
import { getComponentByIllustrationName } from 'common/components/Illustration/Illustration';
import { useTranslation } from 'common/hooks/useTranslation';
import { useHasAccessToKycStateRelatedFeatures } from 'modules/kyc/hooks/useHasAccessToKycStateRelatedFeatures';
import { getImpersonator } from 'src/core/selectors/globalSelectors';

type Props = {
  userHasMadePayments?: boolean;
  onButtonClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const PaymentsListEmptyState = ({
  userHasMadePayments = false,
  onButtonClick,
}: Props) => {
  const { t } = useTranslation('global');
  const impersonator = useSelector(getImpersonator);
  const hasAccessToKycStateRelatedFeatures =
    useHasAccessToKycStateRelatedFeatures();

  if (userHasMadePayments) {
    return (
      <EmptyState
        illustration={getComponentByIllustrationName('cocktail')}
        title={t('homepage.paymentsList.empty.title')}
        subtitle={t('homepage.paymentsList.empty.desc')}
        actions={
          !impersonator ? (
            <Button
              type="primary"
              text={t('homepage.paymentsList.empty.button')}
              onClick={onButtonClick}
            />
          ) : undefined
        }
      />
    );
  }

  if (!hasAccessToKycStateRelatedFeatures) {
    return (
      <div className="relative flex flex-col content-stretch gap-24">
        <div className="text-center">
          <HighlightIcon name="clock-outline" variant="info" />
          <h2 className="mt-16 title-l">
            {t('requests.notAvailableForRequesterYet.title')}
          </h2>
          <p className="text-secondary-bg-primary body-m">
            {t('requests.notAvailableForRequesterYet.message')}
          </p>
        </div>
        <DownloadAppIllustration
          className="absolute bottom-[-24px] flex overflow-hidden"
          hasBottomMargin
        />
      </div>
    );
  }

  // show the onboarding empty state if the user has never made a payment
  return (
    <EmptyState
      illustration={getComponentByIllustrationName('payment')}
      title={t('homepage.paymentsList.empty.onboardingTitle')}
      subtitle={t('homepage.paymentsList.empty.onboardingDesc')}
      actions={
        !impersonator ? (
          <Button
            type="primary"
            text={t('homepage.paymentsList.empty.onboardingButton')}
            onClick={onButtonClick}
          />
        ) : undefined
      }
    />
  );
};
