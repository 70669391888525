import { Callout } from '@dev-spendesk/grapes';

import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';

import { useIntegrationStatusQuery } from '../../../hooks';
import { getIntegrationName } from '../../../integration/name';
import { type AccountingSoftware } from '../../../integration/status';
import { type Payable } from '../../containers/PayablePanelContainer';

export const EditAfterExportEditCallout = ({
  payable,
}: {
  payable: Pick<Payable, 'state'>;
}) => {
  const { t } = useTranslation('global');
  const accountingIntegrationStatusQueryResult =
    useIntegrationStatusQuery(false);

  if (
    !accountingIntegrationStatusQueryResult ||
    accountingIntegrationStatusQueryResult.status !== 'success'
  ) {
    return null;
  }

  const integrationName = getIntegrationName(
    t,
    accountingIntegrationStatusQueryResult.data.integration,
  );
  const title = getCalloutTitle(t, payable.state, {
    integration: accountingIntegrationStatusQueryResult.data.integration,
    integrationName,
  });

  if (!title) {
    return null;
  }

  return (
    <Callout
      data-testid="edit-after-export-edit-callout"
      className="mb-16"
      title={title}
    />
  );
};

function getCalloutTitle(
  t: TGlobalFunctionTyped,
  state: Payable['state'],
  {
    integration,
    integrationName,
  }: {
    integrationName: string;
    integration: AccountingSoftware | 'noIntegration' | 'switchInProgress';
  },
): string | null {
  switch (state) {
    case 'prepared':
      return t('payables.panel.callouts.editAfterExportPrepared');
    case 'inAccounting':
    case 'inAccountingManually':
      if (
        integration === 'SpendeskAccounting' ||
        integration === 'SpendeskAccountingSingleEntry'
      ) {
        return t(
          'payables.panel.callouts.editAfterExportAlreadyExportedFileBased',
        );
      }
      return t(
        'payables.panel.callouts.editAfterExportAlreadyExportedIntegration',
        {
          integrationName,
        },
      );
    default:
      return null;
  }
}
