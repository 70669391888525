import { Banner, Button, Tag } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { SearchFilter } from 'common/components/SearchFilter';
import { useFeature } from 'common/hooks/useFeature';
import { CreateControlRuleModalContainer } from 'modules/company/members/components/ControlRules';
import { BetaFeatureFeedbackModal } from 'src/core/common/components/BetaFeatureNotice/BetaFeatureNotice/BetaFeatureFeedbackPopover/BetaFeatureFeedbackModal';
import { useModal } from 'src/core/common/hooks/useModalGrapes';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { TableControlRulesContainer } from './TableControlRules/TableControlRulesContainer';
import Illustration from './assets/cop.svg';

type Props = {
  controlRulesCount: number;
  searchPattern: string;
  setSearchPattern(argument: string): void;
};

export const MembersControlRulesTab = ({
  controlRulesCount,
  searchPattern,
  setSearchPattern,
}: Props) => {
  const { t } = useTranslation('global');
  const hasPlayByRulesFeature = useFeature(FEATURES.PLAY_BY_RULES);
  const hasPlayByTheRulesReceiptAndFieldsFeature = useFeature(
    FEATURES.PLAY_BY_THE_RULES_RECEIPT_AND_FIELDS,
  );
  const hasPlayByRulesMultipleFeature = useFeature(
    FEATURES.PLAY_BY_RULES_MULTIPLE,
  );
  const [showCreateModal, setShowCreateModal] = useState(false);
  const canCreateControlRule =
    hasPlayByRulesFeature &&
    (hasPlayByRulesMultipleFeature || controlRulesCount === 0);

  const [feedbackModal, showFeedbackModal, hideFeedbackModal] = useModal(
    ({ isOpen }) => {
      return (
        <BetaFeatureFeedbackModal
          isOpen={isOpen}
          onCancel={() => {
            hideFeedbackModal();
          }}
          onConfirm={() => {
            hideFeedbackModal();
          }}
          feedbackTags={['pbrv2']}
        />
      );
    },
  );

  if (!hasPlayByRulesFeature) {
    return null;
  }

  return (
    <>
      {feedbackModal}
      <div className="flex w-full flex-col content-stretch gap-24">
        <div className="flex items-center gap-16">
          <h2 className="text-primary heading-l">
            {t('controlRules.create.title')}
          </h2>

          {canCreateControlRule && (
            <div className="ml-auto flex items-center gap-16">
              <SearchFilter
                minLength={0}
                search={searchPattern}
                setSearch={(search) => setSearchPattern(search)}
                placeholder={t('controlRules.searchPlaceholder')}
              />
              <Button
                text={t('controlRules.create.newRule')}
                onClick={() => setShowCreateModal(true)}
              />
            </div>
          )}
        </div>
        {hasPlayByTheRulesReceiptAndFieldsFeature && (
          <Banner
            variant="brand"
            title={
              <div className="flex items-center gap-8">
                {t('controlRules.mandatoryFields.marketingBanner.title')}
                <Tag variant="purple" className="uppercase">
                  {t('misc.new')}
                </Tag>
              </div>
            }
            actionText={t(
              'controlRules.mandatoryFields.marketingBanner.button',
            )}
            onClick={() => {
              showFeedbackModal();
            }}
            illustration={<img src={Illustration} alt="" />}
          >
            <div className="flex flex-col gap-8 body-m">
              <p>
                {t('controlRules.mandatoryFields.marketingBanner.description')}
              </p>
              <strong className="font-semi-large">
                {t(
                  'controlRules.mandatoryFields.marketingBanner.descriptionBold',
                )}
              </strong>
            </div>
          </Banner>
        )}
        <TableControlRulesContainer searchPattern={searchPattern} />
        {showCreateModal && (
          <CreateControlRuleModalContainer
            isOpen={showCreateModal}
            onSuccess={() => {
              setShowCreateModal(false);
            }}
            onCancel={() => {
              setShowCreateModal(false);
            }}
          />
        )}
      </div>
    </>
  );
};
