import { colors } from '@dev-spendesk/grapes';
import classNames from 'classnames';

export type BreakdownGaugeBar = {
  key: 'scheduled-transfers' | 'card-allocations' | 'available' | 'shortfall';
  name: string;
  value: {
    raw: number;
    formatted: string;
  };
  tooltip?: React.ReactNode;
  subText?: React.ReactNode;
};

export type BreakdownGaugePreparedBar = {
  key: 'scheduled-transfers' | 'card-allocations' | 'available' | 'shortfall';
  name: string;
  backgrounds: {
    ellipsis: string;
    bar: string;
  };
  label: {
    value: React.ReactNode;
    color: string;
    tooltip?: React.ReactNode;
  };
  value: {
    raw: number;
    formatted: string;
    color: string;
  };
  subText?: React.ReactNode;
};

type Props = {
  bars: BreakdownGaugeBar[];
  trend: 'positive' | 'warning' | 'group';
  isInactive?: boolean;
  children?: (preparedBars: BreakdownGaugePreparedBar[]) => React.ReactNode;
};

const GRADIENT_SIZES = {
  color: Math.sqrt(2 * 1.5 ** 2), // px
  bg: Math.sqrt(2 * 2.5 ** 2), // px
};

const buildGradient = (color: string, backgroundColor: string) => {
  return `repeating-linear-gradient(
    -45deg,
    ${color} 0px,
    ${color} ${GRADIENT_SIZES.color}px,
    ${backgroundColor} ${GRADIENT_SIZES.color}px,
    ${backgroundColor} ${GRADIENT_SIZES.color + GRADIENT_SIZES.bg}px
  )`;
};

const prepareBreakdownGaugeData = ({
  bars,
  trend,
  isInactive,
}: {
  bars: BreakdownGaugeBar[];
  trend: Props['trend'];
  isInactive: boolean;
}): BreakdownGaugePreparedBar[] => {
  const getBackgroundColors = (key: BreakdownGaugePreparedBar['key']) => {
    switch (key) {
      case 'scheduled-transfers':
        if (isInactive) {
          return {
            ellipsis: '#BABBBB',
            bar: '#BABBBB',
          };
        }

        if (trend === 'warning') {
          return {
            ellipsis: colors.contentWarningDefault,
            bar: colors.contentWarningDefault,
          };
        }

        return {
          ellipsis: colors.contentBrandDefault,
          bar: colors.contentBrandDefault,
        };

      case 'card-allocations':
        if (isInactive) {
          const gradient = buildGradient(
            colors.contentDisabled,
            colors.backgroundTertiaryDefault,
          );
          return {
            ellipsis: gradient,
            bar: gradient,
          };
        }

        if (trend === 'warning') {
          return {
            ellipsis: buildGradient(
              colors.borderWarning,
              colors.backgroundSecondaryWarningDefault,
            ),
            bar: buildGradient(
              colors.borderWarning,
              colors.backgroundSecondaryWarningDefault,
            ),
          };
        }

        return {
          ellipsis: buildGradient(
            colors.borderSelected,
            colors.backgroundSecondaryBrandPressed,
          ),
          bar: buildGradient(
            colors.borderSelected,
            colors.backgroundSecondaryBrandPressed,
          ),
        };

      case 'shortfall':
        return {
          ellipsis: colors.contentWarningDefault,
          bar: colors.contentWarningDefault,
        };

      default:
        return {
          ellipsis: colors.backgroundTertiaryDefault,
          bar: colors.backgroundTertiaryDefault,
        };
    }
  };

  return bars.map((bar) => ({
    ...bar,
    backgrounds: getBackgroundColors(bar.key),
    label: {
      value: bar.name,
      color: colors.contentSecondaryBgPrimary,
      tooltip: bar.tooltip,
    },
    value: {
      ...bar.value,
      raw: isInactive ? 100 / 3 : bar.value.raw,
      color: isInactive ? '#A3A4A4' : colors.contentPrimary,
    },
  }));
};

export const BreakdownGauge = ({
  bars,
  trend,
  children,
  isInactive = false,
}: Props) => {
  const preparedBars = prepareBreakdownGaugeData({ bars, trend, isInactive });

  const total = preparedBars.reduce(
    (accumulator, bar) => accumulator + bar.value.raw,
    0,
  );
  const isEmpty = preparedBars.every((bar) => bar.value.raw === 0);

  return (
    <div className="box-border flex w-full min-w-[120px] flex-col flex-nowrap gap-16">
      <div
        className={classNames(
          'box-border flex w-full flex-nowrap gap-[2px] overflow-hidden',
          trend === 'group' ? 'rounded-8' : 'rounded',
        )}
      >
        <>
          {preparedBars
            .filter(
              (bar) => bar.value.raw > 0 && (bar.value.raw * 100) / total > 1,
            )
            .map((bar) => (
              <div
                key={`bar-${bar.key}`}
                className={trend === 'group' ? 'h-16' : 'h-8'}
                style={{
                  width: `${(bar.value.raw * 100) / total}%`,
                  background: bar.backgrounds.bar,
                }}
              />
            ))}
          {isEmpty && (
            <div
              className={classNames(
                'w-full',
                trend === 'group' ? 'h-16 rounded-8' : 'h-8 rounded',
              )}
              style={{
                background: isInactive
                  ? colors.backgroundTertiaryDefault
                  : colors.backgroundSecondaryDefault,
              }}
            />
          )}
        </>
      </div>
      {children && (
        <div className="box-border w-full">{children?.(preparedBars)}</div>
      )}
    </div>
  );
};
