import {
  Button,
  Callout,
  DropdownItem,
  Icon,
  type IconName,
  Modal,
} from '@dev-spendesk/grapes';
import React from 'react';

import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import {
  type FormOnSubmit,
  FormSelect,
  FormSubmitButton,
  useForm,
} from 'common/utils/forms';

import { type ControlRule } from '../../models/controlRule';
import { type Member } from '../../models/member';
import { type Policy } from '../../models/policy';
import { translateControlRuleName } from '../../utils/translateControlRuleName';

export type MembersBulkUpdateForm = {
  approvalPolicyId?: string;
  controlRuleId?: string;
  costCenterId?: string;
};

type CostCenter = {
  id: string;
  name: string;
};

type CostCenterOption = {
  key: string;
  label: string;
  iconName?: IconName;
};

export const NULL_VALUE = 'NULL_VALUE';

type Props = {
  policies: Policy[];
  costCenters: CostCenter[];
  controlRules: ControlRule[];
  members: Member[];
  onSubmit: FormOnSubmit<MembersBulkUpdateForm>;
  onClose(): void;
};
export function MembersBulkEditModal({
  policies,
  controlRules,
  costCenters,
  members,
  onSubmit,
  onClose,
}: Props) {
  const { t } = useTranslation('global');

  const defaultApprovalPolicy = policies.find((policy) => policy.isDefault);

  // uniqValue is defined if all members have the same value
  const {
    uniqValue: singleCostCenterId,
    distinctValuesCount: distinctCostCentersCount,
  } = getSingleUniqValue({
    list: members.map((member) => member.costCenter?.id),
    defaultValue: NULL_VALUE,
  });

  const {
    uniqValue: singleApprovalPolicyId,
    distinctValuesCount: distinctApprovalPoliciesCount,
  } = getSingleUniqValue({
    list: members
      .filter((member) => member.isRequester)
      .map((member) => member.policy?.id),
    defaultValue: defaultApprovalPolicy?.id,
  });

  const {
    uniqValue: singleControlRuleId,
    distinctValuesCount: distinctControlRulesCount,
  } = getSingleUniqValue({
    list: members
      .filter((member) => member.isRequester)
      .map((member) => member.controlRule?.id),
    defaultValue: NULL_VALUE,
  });

  const membersNotRequestersCount = members.filter(
    (member) => !member.isRequester,
  ).length;

  const form = useForm<MembersBulkUpdateForm>({
    onSubmit,
    initialValues: {
      costCenterId: singleCostCenterId,
      approvalPolicyId: singleApprovalPolicyId,
      controlRuleId: singleControlRuleId,
    },
    onSubmitSuccess: onClose,
    successMessage: t('members.bulkEditModal.saveSuccess', {
      count: members.length,
    }),
    errorMessage: t('members.bulkEditModal.saveFailed'),
  });

  const noControlRuleOption = {
    key: NULL_VALUE,
    label: t('approvalPolicy.settings.noControlRule'),
  };

  const costCenterOptions: CostCenterOption[] = costCenters.map(
    (costCenter) => ({
      key: costCenter.id,
      label: costCenter.name,
    }),
  );

  const noCostCenterOption = {
    key: NULL_VALUE,
    label: t('members.bulk.costCenterAutomatic'),
    iconName: 'thunder',
  };

  return (
    <Modal
      isOpen
      iconName="pen"
      iconVariant="purple"
      title={t('members.bulk.modalTitle', { count: members.length })}
      actions={
        <>
          <Button
            variant="secondaryNeutral"
            text={t('misc.cancel')}
            onClick={onClose}
          />
          <FormSubmitButton form={form} text={t('misc.saveChanges')} />,
        </>
      }
    >
      <form onSubmit={form.submitForm} className="flex flex-col gap-24">
        {/* if no members are requesters, we disable approval policy and control rules */}
        {membersNotRequestersCount === members.length ? (
          <>
            <FormSelect
              form={form}
              isDisabled
              placeholder={t('members.bulk.policyOnlyForRequesters')}
              label={t('approvalPolicy.settings.approvalPolicy')}
              options={[]}
              name="approvalPolicyId"
            />
            <FormSelect
              form={form}
              isDisabled
              placeholder={t('members.bulk.controlRuleOnlyForRequesters')}
              label={t('approvalPolicy.settings.controlRule')}
              options={[]}
              name="controlRuleId"
            />
          </>
        ) : (
          <>
            <div>
              <FormSelect
                form={form}
                name="approvalPolicyId"
                options={policies.map((policy) => ({
                  key: policy.id,
                  label: policy.name ?? '',
                }))}
                placeholder={getPlaceholderMultiValues(
                  distinctApprovalPoliciesCount,
                  t('members.bulk.selectApprovalPolicy'),
                  t,
                )}
                label={t('approvalPolicy.settings.approvalPolicy')}
              />
              {membersNotRequestersCount > 0 && (
                <Callout
                  variant="info"
                  className="mt-8"
                  title={t('members.bulk.policyOnlyForRequestersCallout', {
                    count: members.length - membersNotRequestersCount,
                  })}
                />
              )}
            </div>
            <div>
              <FormSelect
                form={form}
                name="controlRuleId"
                placeholder={getPlaceholderMultiValues(
                  distinctControlRulesCount,
                  t('members.bulk.selectControlRule'),
                  t,
                )}
                options={[
                  noControlRuleOption,
                  ...controlRules.map((controlRule) => ({
                    key: controlRule.id,
                    label: translateControlRuleName(controlRule, t),
                  })),
                ]}
                label={t('approvalPolicy.settings.controlRule')}
              />
              {membersNotRequestersCount > 0 && (
                <Callout
                  variant="info"
                  className="mt-8"
                  title={t('members.bulk.controlRuleOnlyForRequestersCallout', {
                    count: members.length - membersNotRequestersCount,
                  })}
                />
              )}
            </div>
          </>
        )}
        <FormSelect
          form={form}
          name="costCenterId"
          options={[noCostCenterOption, ...costCenterOptions]}
          renderOption={(option: CostCenterOption, optionState) =>
            option.iconName ? (
              <DropdownItem
                label={
                  <div className="flex items-center gap-4">
                    {option.label}
                    <Icon
                      name={option.iconName}
                      className={
                        optionState?.isSelected
                          ? 'text-brand-default'
                          : 'text-secondary-bg-secondary'
                      }
                    />
                  </div>
                }
                {...optionState}
              />
            ) : (
              <DropdownItem label={option.label} {...optionState} />
            )
          }
          placeholder={getPlaceholderMultiValues(
            distinctCostCentersCount,
            t('approvalPolicy.settings.costCenterSelect'),
            t,
          )}
          label={t('approvalPolicy.settings.costCenter')}
        />
      </form>
    </Modal>
  );
}

/**
 * Find the common value
 */
export const getSingleUniqValue = ({
  list,
  defaultValue = NULL_VALUE,
}: {
  list: (string | undefined)[];
  defaultValue?: string;
}) => {
  const uniqList = [...new Set(list)];

  if (uniqList.length === 1) {
    return {
      uniqValue: uniqList[0] ?? defaultValue,
      distinctValuesCount: 1,
    };
  }

  return { uniqValue: undefined, distinctValuesCount: uniqList.length };
};

const getPlaceholderMultiValues = (
  distinctValuesCount: number,
  defaultPlaceholder: string,
  t: TGlobalFunctionTyped,
) => {
  if (distinctValuesCount >= 2) {
    return t('misc.multipleValues');
  }

  return defaultPlaceholder;
};
