import { FormField, TextInput } from '@dev-spendesk/grapes';
import { format, parse } from 'date-fns';
import { useFormik, type FormikErrors } from 'formik';
import { type ReactNode, useState } from 'react';

import { CountryAutocomplete } from 'src/core/common/components/CountryAutocomplete';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { formatDateToApiString } from 'src/core/common/utils/formatDateToApiString';
import { getCountryCodes } from 'src/core/config/country';
import { type User } from 'src/core/modules/app/hooks/useUser';
import { useNotifications } from 'src/core/modules/app/notifications';
import { removeTimezone } from 'src/core/modules/requests/utils/removeTimezone';
import { isValidBirthDate } from 'src/core/utils/validators';

import { ScreeningCityAutocomplete } from './ScreeningCityAutocomplete';

type Props = {
  user: {
    first_name: User['first_name'];
    last_name: User['last_name'];
    birth_date: User['birth_date'];
    birth_location_city: User['birth_location_city'];
    birth_location_country_code: User['birth_location_country_code'];
  };
  onSuccess: (data: {
    first_name: string;
    last_name: string;
    birth_date: string;
    birth_location_city: string;
    birth_location_country_code: string;
  }) => Promise<void>;
  onError?: () => void;
  renderButtons: (isLoading: boolean, onClick: () => void) => ReactNode;
};

type MemberScreeningForm = {
  firstname: string;
  lastname: string;
  dateOfBirth: string;
  cityOfBirth: string;
  countryOfBirth: string;
};

export const ScreeningForm = ({
  user,
  onSuccess,
  onError,
  renderButtons,
}: Props) => {
  const { t } = useTranslation('global');
  const [isLoading, setIsLoading] = useState(false);
  const { dangerNotif, successNotif } = useNotifications();

  const formikProps = useFormik<MemberScreeningForm>({
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    initialValues: {
      firstname: user.first_name,
      lastname: user.last_name,
      dateOfBirth: user.birth_date
        ? format(removeTimezone(new Date(user.birth_date)), 'dd/MM/yyyy')
        : '',
      cityOfBirth: user.birth_location_city ?? '',
      countryOfBirth: user.birth_location_country_code ?? '',
    },
    validate: (values) => {
      const errors: FormikErrors<MemberScreeningForm> = {};
      if (!values.firstname) {
        errors.firstname = t('forms.errorMessages.required');
      }
      if (!values.lastname) {
        errors.lastname = t('forms.errorMessages.required');
      }
      if (!values.dateOfBirth) {
        errors.dateOfBirth = t('forms.errorMessages.required');
      } else if (!isValidBirthDate(values.dateOfBirth, 'dd/MM/yyyy')) {
        errors.dateOfBirth = t('forms.errorMessages.invalid');
      }
      if (!values.cityOfBirth) {
        errors.cityOfBirth = t('forms.errorMessages.required');
      }
      if (!values.countryOfBirth) {
        errors.countryOfBirth = t('forms.errorMessages.required');
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const data = {
          first_name: values.firstname,
          last_name: values.lastname,
          birth_date: formatDateToApiString(
            parse(values.dateOfBirth, 'dd/MM/yyyy', new Date()),
          ),
          birth_location_city: values.cityOfBirth,
          birth_location_country_code: values.countryOfBirth,
        };
        await onSuccess(data);
        successNotif(t('members.screeningModal.success'));
      } catch {
        dangerNotif(t('misc.errors.unknownError'));
        if (onError) {
          onError();
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <div className="flex flex-col gap-24 pb-8">
      <div className="flex w-full gap-16">
        <div className="flex-1">
          <FormField
            label={t('members.screeningModal.firstNameLabel')}
            alertMessage={formikProps.errors.firstname}
          >
            <TextInput
              name="firstname"
              placeholder={t('members.screeningModal.firstNamePlaceholder')}
              value={formikProps.values.firstname}
              onChange={formikProps.handleChange}
            />
          </FormField>
        </div>
        <div className="flex-1">
          <FormField
            label={t('members.screeningModal.lastNameLabel')}
            alertMessage={formikProps.errors.lastname}
          >
            <TextInput
              name="lastname"
              placeholder={t('members.screeningModal.lastNamePlaceholder')}
              value={formikProps.values.lastname}
              onChange={formikProps.handleChange}
            />
          </FormField>
        </div>
      </div>
      <div className="flex w-full gap-16">
        <div className="min-w-0 flex-1">
          <FormField
            label={t('members.screeningModal.dateOfBirthLabel')}
            alertMessage={formikProps.errors.dateOfBirth}
          >
            <TextInput
              maskOptions={{
                date: true,
                datePattern: ['d', 'm', 'Y'],
                delimiter: '/',
              }}
              name="dateOfBirth"
              onChange={formikProps.handleChange}
              value={formikProps.values.dateOfBirth}
              placeholder={t('members.screeningModal.dateOfBirthHint')}
            />
          </FormField>
        </div>
        <div className="min-w-0 flex-1">
          <FormField
            label={t('members.screeningModal.cityOfBirthLabel')}
            alertMessage={formikProps.errors.cityOfBirth}
          >
            <ScreeningCityAutocomplete
              placeholder={t('members.screeningModal.cityOfBirthPlaceholder')}
              onPlaceSelect={(cityOfBirth, countryCode) => {
                formikProps.setFieldValue('cityOfBirth', cityOfBirth);
                if (countryCode && getCountryCodes().includes(countryCode)) {
                  formikProps.setFieldValue('countryOfBirth', countryCode);
                }
              }}
              initialInputValue={formikProps.values.cityOfBirth}
            />
          </FormField>
        </div>
        <div className="min-w-0 flex-1">
          <FormField
            label={t('members.screeningModal.countryOfBirthLabel')}
            alertMessage={formikProps.errors.countryOfBirth}
          >
            <CountryAutocomplete
              className="w-full"
              placeholder={t(
                'members.screeningModal.countryOfBirthPlaceholder',
              )}
              onSelect={(countryCode) => {
                formikProps.setFieldValue('countryOfBirth', countryCode);
              }}
              countryCode={formikProps.values.countryOfBirth}
            />
          </FormField>
        </div>
      </div>
      <div className="mt-32 flex w-full justify-center gap-24">
        {renderButtons(isLoading, () => formikProps.handleSubmit())}
      </div>
    </div>
  );
};
