import { Button, Callout } from '@dev-spendesk/grapes';
import { type MonetaryValue } from 'ezmoney';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useGoToCompanyWalletFunding } from 'modules/company';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { useConfirmTransfersTrackingEvents } from '../../../hooks';

type Props = {
  missingFunds: MonetaryValue;
  walletAmount: MonetaryValue;
};

export const MissingFundsWarning = ({ missingFunds, walletAmount }: Props) => {
  const { t } = useTranslation('global');
  const goToCompanyWalletFunding = useGoToCompanyWalletFunding();
  const confirmTransfersTrackingEvents = useConfirmTransfersTrackingEvents();

  return (
    <Callout
      title={t('invoices.transfer.errorState.missingFundsWarningTitle')}
      variant="warning"
      className="mb-16"
    >
      <>
        <div className="my-16">
          {t('invoices.transfer.errorState.missingFundsWarningDescription', {
            missingFunds: formatMonetaryValue(missingFunds),
            walletAmount: formatMonetaryValue(walletAmount),
          })}
        </div>

        <Button
          variant="secondaryNeutral"
          text={t('invoices.transfer.errorState.missingFundsWarningCTA')}
          onClick={() => {
            confirmTransfersTrackingEvents.onAddFundsClicked();
            goToCompanyWalletFunding();
          }}
        />
      </>
    </Callout>
  );
};
