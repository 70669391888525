import { colors, Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { type I18nKey, useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routeFor } from 'src/core/constants/routes';

import styles from './NavigationBreadcrumb.module.css';

export type TaskStep = {
  label: I18nKey;
  path: string;
};

type Props = {
  steps: TaskStep[];
};

export const NavigationBreadcrumb = ({ steps }: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const history = useHistory();
  const location = useLocation();

  const currentIndex = useMemo(() => {
    return steps.findIndex((step) => {
      const stepPath = routeFor(step.path, {
        company: companyId,
      });
      return location.pathname === stepPath;
    });
  }, [location]);

  const openStep = (step: TaskStep) => {
    const path = routeFor(step.path, {
      company: companyId,
    });
    history.push(path);
  };

  return (
    <div className={styles.root}>
      {steps.map((step, index) => {
        const isPast = index < currentIndex;
        const isCurrent = index === currentIndex;
        const isNext = index > currentIndex;

        return (
          <div
            role="presentation"
            onClick={isPast || isCurrent ? () => openStep(step) : undefined}
            key={step.path}
            className={cx(styles.menuItem, {
              [styles.pastMenuItem]: isPast,
              [styles.currentMenuItem]: isCurrent,
              [styles.nextMenuItem]: isNext,
            })}
          >
            {isCurrent || isNext ? (
              <span className={styles.icon} />
            ) : (
              <Icon
                name="circle-check"
                color={colors.contentBrandDefault}
                size="m"
              />
            )}
            <span className="title-m">{t(step.label)}</span>
          </div>
        );
      })}
    </div>
  );
};
