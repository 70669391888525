import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { isValidCurrencyKey } from 'src/core/config/money';

import {
  DEFAULT_ENTITY_FILTERS,
  EntityFilterQueryParameterKey,
  type EntityFilters,
  isValidEntityStatusFilterValue,
  isValidFundsStatusFilterValue,
} from './entity-list-filters/types';
import {
  isEntityInformationEnriched,
  type EntityBasicInformation,
} from '../../types';

export const FilteredEntityListWrapper = <T extends EntityBasicInformation>({
  entities,
  isFilteringEnabled,
  children,
}: {
  entities: T[];
  isFilteringEnabled: boolean;
  children: ({
    filters,
    setFilters,
    filteredEntities,
    hasActiveFilters,
  }: {
    filters: EntityFilters;
    setFilters: (filters: EntityFilters) => void;
    filteredEntities: T[];
    hasActiveFilters: boolean;
  }) => React.ReactNode;
}) => {
  const [filters, setFilters] = useState<EntityFilters>(DEFAULT_ENTITY_FILTERS);
  const { currencyFilters, entityStatusFilters, fundsStatusFilters } = filters;
  const filterByCurrencies = (entity: T) =>
    currencyFilters.length === 0 || currencyFilters.includes(entity.currency);

  const isEntityActive = (entity: T) => {
    const hasExtendedData = isEntityInformationEnriched(entity);
    if (!hasExtendedData) {
      return !entity.isChurning && !entity.hasChurned;
    }

    return (
      !entity.isChurning &&
      !entity.hasChurned &&
      !entity.isKycAwaitingApproval &&
      !entity.isKycInProgress
    );
  };
  const filterByEntityStatus = (entity: T) =>
    entityStatusFilters.length === 0 ||
    (entityStatusFilters.includes('active') && isEntityActive(entity)) ||
    (entityStatusFilters.includes('inactive') && !isEntityActive(entity));

  const filterByFundsStatus = (entity: T) => {
    if (fundsStatusFilters.length === 0) {
      return true;
    }

    const hasExtendedData = isEntityInformationEnriched(entity);
    if (!hasExtendedData) {
      return true;
    }

    return fundsStatusFilters.some(
      (status) =>
        (status === 'available' && entity.breakdown.shortfall === 0) ||
        (status === 'shortfall' && entity.breakdown.shortfall !== 0),
    );
  };

  const querySearch = useLocation().search.toString();
  const [hasActiveFilters, setHasActiveFilters] = useState(false);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(querySearch);
    const currenciesQueryParameter =
      urlSearchParams
        .get(EntityFilterQueryParameterKey.currencyFilters)
        ?.trim() || '';
    const entityStatusQueryParameter =
      urlSearchParams
        .get(EntityFilterQueryParameterKey.entityStatusFilters)
        ?.trim() || '';
    const fundsStatusQueryParameter =
      urlSearchParams
        .get(EntityFilterQueryParameterKey.fundsStatusFilters)
        ?.trim() || '';

    setHasActiveFilters(
      [
        currenciesQueryParameter,
        entityStatusQueryParameter,
        fundsStatusQueryParameter,
      ].some((f) => f.length > 0),
    );
  }, []);

  useEffect(() => {
    if (isFilteringEnabled) {
      const urlSearchParams = new URLSearchParams(querySearch);
      const validCurrencyFilters = urlSearchParams
        .getAll(EntityFilterQueryParameterKey.currencyFilters)
        .filter(isValidCurrencyKey);
      const validEntityStatusFilters = urlSearchParams
        .getAll(EntityFilterQueryParameterKey.entityStatusFilters)
        .filter(isValidEntityStatusFilterValue);
      const validFundsStatusFilters = urlSearchParams
        .getAll(EntityFilterQueryParameterKey.fundsStatusFilters)
        .filter(isValidFundsStatusFilterValue);

      setHasActiveFilters(true);
      setFilters({
        currencyFilters: validCurrencyFilters,
        entityStatusFilters: validEntityStatusFilters,
        fundsStatusFilters: validFundsStatusFilters,
      });
    }
  }, [isFilteringEnabled]);

  const history = useHistory();
  useEffect(() => {
    if (isFilteringEnabled) {
      const searchParams = new URLSearchParams(querySearch);
      toggleSearchParams(
        searchParams,
        EntityFilterQueryParameterKey.currencyFilters,
        currencyFilters,
      );
      toggleSearchParams(
        searchParams,
        EntityFilterQueryParameterKey.entityStatusFilters,
        entityStatusFilters,
      );
      toggleSearchParams(
        searchParams,
        EntityFilterQueryParameterKey.fundsStatusFilters,
        fundsStatusFilters,
      );

      history.push({
        search: searchParams.toString(),
      });
    }
  }, [filters, isFilteringEnabled]);

  const toggleSearchParams = (
    searchParams: URLSearchParams,
    key: string,
    values: string[],
  ) => {
    if (values.length > 0) {
      searchParams.set(key, values.join(','));
      setHasActiveFilters(true);
    } else {
      searchParams.delete(key);
      setHasActiveFilters(false);
    }
  };

  return children({
    filters,
    setFilters,
    filteredEntities: entities.filter(
      (entity) =>
        filterByCurrencies(entity) &&
        filterByEntityStatus(entity) &&
        filterByFundsStatus(entity),
    ),
    hasActiveFilters,
  });
};
