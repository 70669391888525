import { Callout, CheckboxBox } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { translateRoleName } from 'common/utils/translateRoleName';
import FEATURES from 'src/core/constants/features';

import * as MemberModel from '../../models/member';
import { type CompanyRole, type AssignableRole } from '../../models/roles';
import { type MemberEditFormikValues } from '../MemberEditModal/validate';

type EnrichedRole = {
  name: AssignableRole;
  isEditable: boolean;
  checked: boolean;
};

type Props = {
  member: MemberModel.MemberDetails;
  enrichedRoles: EnrichedRole[];
  formikProps: FormikProps<MemberEditFormikValues>;
};

export const MemberEditTabRoles = ({
  member,
  enrichedRoles,
  formikProps,
}: Props) => {
  const { values, setFieldValue, errors } = formikProps;

  const { t } = useTranslation('global');
  const isTeamsFeatureEnabled = useFeature(FEATURES.TEAMS);

  const getRoleDescription = (role: CompanyRole) => {
    switch (role) {
      case 'admin':
        return isTeamsFeatureEnabled
          ? t('members.roleDescriptionAdminWithTeams')
          : t('members.roleDescriptionAdmin');
      case 'controller':
        return t('members.roleDescriptionController');
      case 'requester':
        return t('members.roleDescriptionRequester');
      default:
        throw new Error('Unknown role');
    }
  };

  const updateRole = (enrichedRole: EnrichedRole) => {
    const newRoles = enrichedRole.checked
      ? values.roles.filter(
          (selectedRole) => selectedRole !== enrichedRole.name,
        )
      : [...values.roles, enrichedRole.name];
    const draftMemberRoles = MemberModel.getDraftMemberRoles({
      ...formikProps.values,
      roles: newRoles,
    });

    if (!draftMemberRoles.isController) {
      setFieldValue('canConfirmPayments', false);
    } else if (member.canConfirmPayments) {
      setFieldValue('canConfirmPayments', true);
    }

    return setFieldValue('roles', newRoles);
  };

  return (
    <div className="flex flex-col gap-16">
      {errors.roles ? (
        <Callout variant="alert" title={t('members.editModal.roleIdsError')} />
      ) : null}
      {enrichedRoles.map((role) => (
        <CheckboxBox
          key={`role-block-${role.name}`}
          isDisabled={!role.isEditable}
          isChecked={role.checked}
          label={translateRoleName(role.name, t)}
          description={getRoleDescription(role.name)}
          onChange={() => updateRole(role)}
        />
      ))}
    </div>
  );
};
