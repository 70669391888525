import {
  Button,
  Callout,
  FormField,
  Modal,
  Select,
  TextInput,
} from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { CURRENCIES } from 'src/core/config/money';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';

type Props = {
  errored: boolean;
  handleClose: () => void;
  handleCreateCurrencyBranch: (companyName: string) => Promise<void>;
};
export const SfsBranchCurrencyFormModal = ({
  errored,
  handleCreateCurrencyBranch,
  handleClose,
}: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();
  const currency = CURRENCIES[company.currency];
  const currencyForDisplay = {
    key: currency.value,
    label: currency.label,
  };

  const {
    errors,
    handleChange,
    handleSubmit,
    dirty,
    isValid,
    isSubmitting,
    values,
    resetForm,
  } = useFormik<{
    companyName: string;
  }>({
    initialValues: {
      companyName: '',
    },
    onSubmit: async (submitValues) => {
      await handleCreateCurrencyBranch(submitValues.companyName);
      resetForm({
        values: {
          companyName: submitValues.companyName,
        },
      });
    },
    validate: (validateValues) => {
      if (validateValues.companyName.length === 0) {
        return {
          companyName: t('misc.requiredField'),
        };
      }

      return {};
    },
  });

  return (
    <Modal
      isOpen
      onClose={handleClose}
      title={t('sfsBranchCurrencyModal.title')}
      iconName="plus"
      iconVariant="purple"
      actions={[
        <Button
          key="back"
          text={t('misc.back')}
          variant="secondaryNeutral"
          onClick={() => handleClose()}
        />,
        <Button
          key="submit"
          text={t('sfsBranchCurrencyModal.submitButton')}
          type="submit"
          form="CompanyNameForCurrencyBranchModalForm"
          variant="primaryBrand"
          isDisabled={!isValid || !dirty}
          isLoading={isSubmitting}
        />,
      ]}
    >
      <form id="CompanyNameForCurrencyBranchModalForm" onSubmit={handleSubmit}>
        <FormField label={t('sfsBranchCurrencyModal.nameLabel')}>
          <TextInput
            name="companyName"
            value={values.companyName}
            fit="parent"
            placeholder={t('sfsBranchCurrencyModal.companyNamePlaceholder')}
            onChange={handleChange}
            isInvalid={Boolean(errors.companyName)}
          />
        </FormField>
        <FormField
          className="mt-16 text-left"
          label={t('sfsBranchCurrencyModal.currencyLabel')}
          description={t('sfsBranchCurrencyModal.currencyDescription', {
            currency: currencyForDisplay.key,
          })}
        >
          <Select
            name="currency"
            options={[currencyForDisplay]}
            value={currencyForDisplay}
            onSelect={() => null}
            fit="parent"
            isDisabled
          />
        </FormField>
      </form>
      {errored && (
        <Callout
          className="mt-16"
          variant="alert"
          title={t('misc.somethingWrong')}
        />
      )}
    </Modal>
  );
};
