import { Button, Link } from '@dev-spendesk/grapes';
import { isbot } from 'isbot';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { getPrivacyPolicyLink } from 'modules/legals';
import { getCookiePolicyLink } from 'modules/legals/helpers';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import appConfig from 'src/core/config';
import {
  loadAnalytics,
  hasReviewedCookiePolicy,
  initAnalyticsContext,
} from 'src/core/utils/analytics';

import CookieIconURI from './assets/cookie.svg';

import './CookieBanner.css';

type Props = {
  user?: {
    id: string;
    intercom_hash: string;
    lang: string;
    fullname: string;
    email: string;
    is_account_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
    is_requester: boolean;
  };
  company?: {
    id: string;
    type: string;
    created_from: string;
    organisation: {
      id: string;
    };
  };
  supervisor?: {
    name: string;
    email: string;
  };
  chatWidgetLocation?: 'left' | 'right';
};

export const CookieBanner = ({
  user,
  company,
  supervisor,
  chatWidgetLocation = 'right',
}: Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const categories = [
    {
      key: 'functional',
      name: t('legals.cookies.categories.functional.name'),
      description: t('legals.cookies.categories.functional.description'),
      example: t('legals.cookies.categories.functional.example'),
    },
    {
      key: 'marketing',
      name: t('legals.cookies.categories.marketing.name'),
      description: t('legals.cookies.categories.marketing.description'),
      example: t('legals.cookies.categories.marketing.example'),
    },
    {
      key: 'advertising',
      name: t('legals.cookies.categories.advertising.name'),
      description: t('legals.cookies.categories.advertising.description'),
      example: t('legals.cookies.categories.advertising.example'),
    },
    {
      key: 'essential',
      name: t('legals.cookies.categories.essential.name'),
      description: t('legals.cookies.categories.essential.description'),
      example: t('legals.cookies.categories.essential.example'),
    },
  ];

  const refuseAll = () => {
    setShowCookieBanner(false);
    window.consentManager?.preferences?.savePreferences({
      customPreferences: {
        marketingAndAnalytics: false,
        advertising: false,
        functional: false,
      },
      destinationPreferences: {
        All: false,
      },
    });
    initAnalyticsContext({ user, company, supervisor });
  };

  const agreeAll = () => {
    setShowCookieBanner(false);
    window.consentManager?.preferences?.savePreferences({
      customPreferences: {
        marketingAndAnalytics: true,
        advertising: true,
        functional: true,
      },
      destinationPreferences: {
        All: true,
      },
    });
    loadAnalytics();
    initAnalyticsContext({ user, company, supervisor });
  };

  const openConsentManager = async () => {
    window.consentManager.preferences.onPreferencesSaved(() => {
      initAnalyticsContext({ user, company, supervisor });
      setShowCookieBanner(false);
    });

    await window.consentManager.openConsentManager();
  };

  useEffect(() => {
    if (!appConfig.hasDirectIntercomIntegration) {
      window.intercomSettings = {
        alignment: chatWidgetLocation,
        ...(user ? { user_id: user.id, user_hash: user.intercom_hash } : {}),
        custom_launcher_selector: '#intercom-message',
        app_id: appConfig.intercomAppId,
      };
    }

    window.wootricSettings = {
      modal_footprint: 'compact',
      language: activeLanguage,
    };

    // FullStory Content Security Policy
    window._fs_csp = true;

    window.consentManagerConfig = ({
      React: InnerReact,
      inEU,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any) => {
      if (isbot(navigator.userAgent) || supervisor) {
        return;
      }

      setShowCookieBanner(true);

      if (!inEU()) {
        agreeAll();
      }

      if (hasReviewedCookiePolicy()) {
        initAnalyticsContext({ user, company, supervisor });
        setShowCookieBanner(false);
      }

      return {
        container: '#consent-manager',
        writeKey: appConfig.segment.key,
        bannerContent: InnerReact.createElement('div'),
        defaultDestinationBehavior: 'imply',
        preferencesDialogTitle: t('legals.cookies.preferencesDialogTitle'),
        preferencesDialogContent: t('legals.cookies.preferencesDialogContent'),
        cancelDialogTitle: t('legals.cookies.cancelDialogTitle'),
        cancelDialogContent: t('legals.cookies.cancelDialogContent'),
        preferencesDialogTemplate: {
          headings: {
            allowValue: t('legals.cookies.allowValue'),
            categoryValue: t('legals.cookies.categoryValue'),
            purposeValue: t('legals.cookies.purposeValue'),
            toolsValue: t('legals.cookies.toolsValue'),
          },
          checkboxes: {
            noValue: t('misc.no'),
            yesValue: t('misc.yes'),
          },
          actionButtons: {
            cancelValue: t('misc.cancel'),
            saveValue: t('misc.save'),
          },
          cancelDialogButtons: {
            cancelValue: t('misc.cancel'),
            backValue: t('misc.back'),
          },
          categories,
        },
      };
    };

    if (!isbot(navigator.userAgent) && !supervisor) {
      // Add script tag to head
      const script = document.createElement('script');
      script.defer = true;
      script.src =
        'https://unpkg.com/@segment/consent-manager@5.4.0/standalone/consent-manager.js';
      document.head.append(script);
    }
  }, []);

  return (
    <>
      {showCookieBanner && (
        <aside
          className="CookieBanner box shadow-l"
          aria-labelledby="bannerTitle"
        >
          <div className="text-primary title-xl" id="bannerTitle">
            {t('legals.cookies.bannerTitle')}
          </div>
          <p className="my-16 text-primary body-m">
            <Trans i18nKey="legals.cookies.bannerContent">
              <Link
                href={getCookiePolicyLink(activeLanguage)}
                className="text-primary"
                isExternal
              />
              <Link
                href={getPrivacyPolicyLink(activeLanguage)}
                className="text-primary"
                isExternal
              />
            </Trans>
          </p>
          <div className="flex gap-16">
            <Button
              key="accept"
              variant="primaryBrand"
              text={t('legals.cookies.accept')}
              onClick={agreeAll}
            />
            <Button
              key="personalize"
              variant="secondaryNeutral"
              text={t('legals.cookies.personalize')}
              onClick={openConsentManager}
            />
            <Button
              key="deny"
              variant="secondaryNeutral"
              text={t('legals.cookies.deny')}
              onClick={refuseAll}
            />
          </div>
          <img
            src={CookieIconURI}
            width={68}
            height={68}
            className="CookieBanner__icon"
            alt=""
          />
        </aside>
      )}
      <div id="consent-manager" />
    </>
  );
};
