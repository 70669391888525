import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ExportBudgetModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen={isOpen}
      title={t('budget.exportBudget.exportBudgetModal.title')}
      onClose={onClose}
      actions={[
        <Button
          key="1"
          variant="primaryBrand"
          text={t('budget.exportBudget.exportBudgetModal.hide')}
          onClick={onClose}
        />,
      ]}
      iconName="circle-information"
      iconVariant="info"
    >
      <div>{t('budget.exportBudget.exportBudgetModal.subtitle')}</div>
    </Modal>
  );
};
