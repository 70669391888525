import { Modal, Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useDeleteBudgetaryExerciseMutation } from './hooks/deleteBudgetaryExerciseMutation';
import { useRedirectToBudgetaryExercises } from './hooks/useRedirectToBudgetaryExercises';
import { type BudgetaryExercise } from '../../models/budgetaryExercise';

type Props = {
  budgetaryExercise: BudgetaryExercise;
  onHide(): void;
};

export const DeleteBudgetaryExerciseModalContainer = ({
  budgetaryExercise,
  onHide,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const [deleteBudgetaryExercise] = useDeleteBudgetaryExerciseMutation(
    budgetaryExercise.id,
  );
  const redirectToBudgetaryExercises = useRedirectToBudgetaryExercises();

  const handleDeleteBudgetaryExercise = async () => {
    try {
      await deleteBudgetaryExercise();
      redirectToBudgetaryExercises();
      pushNotif({
        type: NotificationType.Success,
        message: t('budget.budgetaryExercise.delete.notifSuccess'),
      });
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('budget.budgetaryExercise.delete.notifFailure'),
      });
    }
    onHide();
  };

  return (
    <Modal
      isOpen
      title={t('budget.budgetaryExercise.delete.modalTitle')}
      iconName="cross"
      iconVariant="alert"
      actions={[
        <Button
          key="cancel-button"
          text={t('budget.budgetaryExercise.delete.cancel')}
          variant="secondaryNeutral"
          onClick={() => onHide()}
        />,
        <Button
          key="delete-button"
          text={t('budget.budgetaryExercise.delete.yes')}
          variant="primaryAlert"
          onClick={handleDeleteBudgetaryExercise}
        />,
      ]}
    >
      {t('budget.budgetaryExercise.delete.modalContent')}
    </Modal>
  );
};
