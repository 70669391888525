import {
  DropdownMenu,
  DropdownItem,
  FormField,
  Tag,
  Avatar,
} from '@dev-spendesk/grapes';

import { fallbackSupplierLogoSrc } from 'common/components/SupplierLogo';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useIntegrationStatusQuery } from 'modules/bookkeep/hooks';
import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
  hasExternalConnection,
  isIntegrationsPlanEnabled,
} from 'modules/bookkeep/integration/status';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import FEATURES from 'src/core/constants/features';

import { AccountingBaseToggle } from './AccountingBaseInput';
import { getAccountingIntegrationDetails } from '../../../../integrations/helper';

import './AccountingBaseSelect.css';

// TODO: we should be able to fetch all available integrations with
// their capabilities, which would allow us to only select the
// integrations with the switch capability for the dropdown.
const getAccountingIntegrationOptions = (
  optionList: AccountingSoftware[],
  currentOption: AccountingSoftware | 'noIntegration' | 'switchInProgress',
  featureFlags: {
    netsuite: boolean;
  },
): AccountingSoftware[] => {
  const filteredOptionList = optionList.filter(
    (option: AccountingSoftware) =>
      option !== currentOption &&
      isIntegrationsPlanEnabled({
        integrationName: option,
        featureFlags,
      }),
  );

  // If the current integration is native, we only allow switching to a filebased one
  if (hasExternalConnection(currentOption)) {
    return filteredOptionList.filter((option: AccountingSoftware) =>
      hasIntegrationFileBasedExport(option),
    );
  }

  return filteredOptionList;
};

type Props = {
  alertMessage?: string;
  selectedIntegration: AccountingSoftware | 'noIntegration';
  onSelect: (accountingSoftware: AccountingSoftware) => void;
  visuallyHideLabel?: boolean;
};

export const AccountingBaseSelectInput = ({
  alertMessage,
  selectedIntegration,
  onSelect,
  visuallyHideLabel = false,
}: Props) => {
  const { t } = useTranslation('global');

  const accountingIntegrationStatusQueryResult = useIntegrationStatusQuery();
  const accountingIntegrationStatus = unwrapQuery(
    accountingIntegrationStatusQueryResult,
  );
  const currentAccountingIntegration =
    accountingIntegrationStatus?.integration ?? 'noIntegration';
  const availableAccountingIntegrations =
    accountingIntegrationStatus?.availableAccountingIntegrations ?? [];

  const isNetsuiteEnterpriseEnabled = useFeature(
    FEATURES.NETSUITE_ACCOUNTING_INTEGRATION,
  );
  const isNetsuiteBetaEnabled = useFeature(FEATURES.TMP_NETSUITE_BETA);

  const featureFlags = {
    netsuite: isNetsuiteEnterpriseEnabled || isNetsuiteBetaEnabled,
  };

  const options = getAccountingIntegrationOptions(
    availableAccountingIntegrations,
    currentAccountingIntegration,
    featureFlags,
  );

  const renderOption = (
    {
      key: availableIntegration,
      label,
    }: { key: AccountingSoftware; label: string },
    index: number,
  ) => (
    <DropdownItem
      key={index}
      isSelected={availableIntegration === selectedIntegration}
      prefix={
        <Avatar
          text={availableIntegration}
          src={getAccountingIntegrationDetails(availableIntegration).logoPath}
          fallbackSrc={fallbackSupplierLogoSrc}
          size="xs"
          variant="square"
        />
      }
      label={
        <>
          <span>{label}</span>{' '}
          <span className="AccountingBaseSelect__integrationType body-m">
            {t(
              getAccountingIntegrationDetails(availableIntegration)
                .i18nSelectType,
            )}
          </span>
          {getAccountingIntegrationDetails(availableIntegration).isBeta && (
            <span>
              {' '}
              <Tag variant="purple">{t('bookkeep.integrations.openBeta')}</Tag>
            </span>
          )}
        </>
      }
      helpText={t(
        getAccountingIntegrationDetails(availableIntegration)
          .i18nSelectDescription,
        { integration: availableIntegration },
      )}
    />
  );

  return (
    <DropdownMenu
      options={options.map((key) => ({
        key,
        label: t(getAccountingIntegrationDetails(key).i18nSelectTitle),
      }))}
      className="AccountingBaseSelect__dropdown"
      placement="bottom-start"
      dropdownContentMaxHeight="350px"
      renderButton={(getToggleButtonProps) => (
        <FormField
          alertMessage={alertMessage}
          label={t('bookkeep.integrations.settings.baseSelect.selectLabel')}
          visuallyHideLabel={visuallyHideLabel}
        >
          {/* @ts-expect-error: No helpful comment */}
          <div {...getToggleButtonProps()}>
            <AccountingBaseToggle
              value={selectedIntegration}
              isBeta={
                selectedIntegration !== 'noIntegration'
                  ? getAccountingIntegrationDetails(selectedIntegration).isBeta
                  : false
              }
            />
          </div>
        </FormField>
      )}
      renderOption={renderOption}
      onSelect={(option) => {
        onSelect(option.key);
      }}
    />
  );
};
