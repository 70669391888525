import {
  FormField,
  RadioField,
  RadioGroup,
  Select,
  SwitchField,
  TextInput,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';

import { useTranslation } from 'common/hooks/useTranslation';
import type { AccountingSoftware } from 'modules/bookkeep/integration/status';
import {
  columnDelimiters,
  columnDelimiterToTranslationKey,
  dateDelimiters,
  dateDelimiterToTranslationKey,
  decimalDelimiters,
  decimalDelimiterToTranslationKey,
  fileTypeEncodingOptions,
  languageToTranslationKey,
  parseDateFormat,
  rowDisplayOptions,
} from 'modules/bookkeep/settings/export';
import { AccountingSoftwareField } from 'modules/bookkeep/settings/export/components/TemplateEditorForm/AccountingSoftwareField';
import type { FormValues } from 'modules/bookkeep/settings/export/components/TemplateEditorForm/formValues';
import {
  getDateFormatOptions,
  getLanguageOptions,
  rowGroupToI18nKey,
} from 'modules/bookkeep/settings/export/components/TemplateEditorForm/utils';
import { SettingFieldPanel } from 'modules/onboarding/setup-hub/pages/Accounting/AccountingExportsSettings/components/SettingFieldPanel';

type Props = FormikProps<FormValues> & {
  activeAccountingIntegration?: AccountingSoftware | 'noIntegration';
};

export const ExportSettingsForm = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  activeAccountingIntegration,
}: Props) => {
  const { t } = useTranslation('global');
  const languageOptions = getLanguageOptions();
  const dateFormatOptions = getDateFormatOptions(values.dateDelimiter);

  const isDoubleEntry =
    !!activeAccountingIntegration &&
    activeAccountingIntegration === 'SpendeskAccounting';
  const noRowDisplaySelected =
    isDoubleEntry &&
    (values.rowDisplayOptions || []).filter((o) => o.active).length === 0;

  return (
    <form className="flex flex-col gap-16">
      <FormField
        label={t('bookkeep.settings.export.nameTemplateLabel')}
        className="rounded-8 bg-secondary-default p-24"
        alertMessage={errors.name}
        description={t('setupHub.exportGeneralSettings.nameHelper')}
      >
        <TextInput
          placeholder={t('bookkeep.settings.export.nameTemplatePlaceholder')}
          name="name"
          onChange={handleChange}
          value={values.name}
          isInvalid={Boolean(errors.name)}
        />
      </FormField>
      <SettingFieldPanel
        value={
          values.accountingSoftware !== t('exports.others')
            ? values.accountingSoftware
            : values.customSoftwareName
        }
        label={t('exports.accountingSoftwareNameLabel')}
      >
        <AccountingSoftwareField
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          visuallyHideLabel
        />
      </SettingFieldPanel>
      <SettingFieldPanel
        value={values.fileTypeEncoding}
        label={t('bookkeep.settings.export.fileTypeEncodingLabel')}
      >
        <FormField
          label={t('bookkeep.settings.export.fileTypeEncodingLabel')}
          visuallyHideLabel
        >
          <Select
            fit="parent"
            value={{
              key: values.fileTypeEncoding,
              label: values.fileTypeEncoding,
            }}
            options={fileTypeEncodingOptions.map((value) => ({
              key: value,
              label: value,
            }))}
            onSelect={(option) => setFieldValue('fileTypeEncoding', option.key)}
          />
        </FormField>
      </SettingFieldPanel>
      <SettingFieldPanel
        value={t(columnDelimiterToTranslationKey(values.columnDelimiter))}
        label={t('bookkeep.settings.export.columnDelimiterLabel')}
      >
        <FormField
          label={t('bookkeep.settings.export.columnDelimiterLabel')}
          description={t('bookkeep.settings.export.columnDelimiterTooltip')}
          visuallyHideLabel
        >
          <RadioGroup
            name="columnDelimiter"
            value={values.columnDelimiter}
            onChange={handleChange}
          >
            {columnDelimiters.map((delimiter) => (
              <RadioField
                value={delimiter}
                key={delimiter}
                label={t(columnDelimiterToTranslationKey(delimiter))}
              />
            ))}
          </RadioGroup>
        </FormField>
      </SettingFieldPanel>
      <SettingFieldPanel
        value={t(languageToTranslationKey(values.language))}
        label={t('bookkeep.settings.export.languageLabel')}
      >
        <FormField
          label={t('bookkeep.settings.export.languageLabel')}
          description={t('bookkeep.settings.export.languageTooltip')}
          visuallyHideLabel
        >
          <RadioGroup
            name="language"
            value={values.language}
            onChange={handleChange}
          >
            {languageOptions.map((language) => (
              <RadioField
                value={language}
                key={language}
                label={t(languageToTranslationKey(language))}
              />
            ))}
          </RadioGroup>
        </FormField>
      </SettingFieldPanel>
      <SettingFieldPanel
        value={values.shouldOutputHeaders ? t('misc.yes') : t('misc.no')}
        label={t('bookkeep.settings.export.includeHeaderRowLabel')}
      >
        <SwitchField
          label=""
          isChecked={values.shouldOutputHeaders}
          onChange={(event) =>
            setFieldValue('shouldOutputHeaders', event.target.checked)
          }
        />
      </SettingFieldPanel>
      <SettingFieldPanel
        value={t(dateDelimiterToTranslationKey(values.dateDelimiter))}
        label={t('bookkeep.settings.export.dateDelimiterLabel')}
      >
        <FormField
          label={t('bookkeep.settings.export.dateDelimiterLabel')}
          visuallyHideLabel
          description={t('bookkeep.settings.export.dateDelimiterTooltip')}
          alertMessage={errors.dateDelimiter}
        >
          <RadioGroup
            name="dateDelimiter"
            value={values.dateDelimiter}
            onChange={handleChange}
          >
            {dateDelimiters.map((delimiter) => (
              <RadioField
                value={delimiter}
                key={delimiter}
                label={t(dateDelimiterToTranslationKey(delimiter))}
              />
            ))}
          </RadioGroup>
        </FormField>
      </SettingFieldPanel>
      <SettingFieldPanel
        value={values.dateFormat.join(values.dateDelimiter)}
        label={t('bookkeep.settings.export.dateFormatLabel')}
      >
        <FormField
          label={t('bookkeep.settings.export.dateFormatLabel')}
          visuallyHideLabel
        >
          <Select
            fit="parent"
            value={{
              key: values.dateFormat.join(values.dateDelimiter),
              label: values.dateFormat.join(values.dateDelimiter),
            }}
            options={dateFormatOptions}
            placeholder={t('bookkeep.settings.export.dateFormatPlaceholder')}
            onSelect={(option) =>
              setFieldValue(
                'dateFormat',
                parseDateFormat(option.key, values.dateDelimiter),
              )
            }
          />
        </FormField>
      </SettingFieldPanel>
      <SettingFieldPanel
        value={t(decimalDelimiterToTranslationKey(values.decimalDelimiter))}
        label={t('bookkeep.settings.export.decimalDelimiterLabel')}
      >
        <FormField
          label={t('bookkeep.settings.export.decimalDelimiterLabel')}
          visuallyHideLabel
          description={t('bookkeep.settings.export.decimalDelimiterTooltip')}
        >
          <RadioGroup
            name="decimalDelimiter"
            value={values.decimalDelimiter}
            onChange={handleChange}
          >
            {decimalDelimiters.map((delimiter) => (
              <RadioField
                value={delimiter}
                key={delimiter}
                label={t(decimalDelimiterToTranslationKey(delimiter))}
              />
            ))}
          </RadioGroup>
        </FormField>
      </SettingFieldPanel>
      {isDoubleEntry && (
        <SettingFieldPanel
          label={t(
            'bookkeep.settings.export.rowDisplayOptions.headerFormLabel',
          )}
        >
          <FormField
            label={t(
              'bookkeep.settings.export.rowDisplayOptions.headerFormLabel',
            )}
            visuallyHideLabel
            description={t(
              'bookkeep.settings.export.rowDisplayOptions.headerFormLabelTooltip',
            )}
            alertMessage={
              noRowDisplaySelected
                ? t('bookkeep.settings.export.rowDisplayOptions.selectRowAlert')
                : ''
            }
          >
            <div className="flex flex-col">
              {rowDisplayOptions.map(({ group }) => {
                const rowDisplayOptionIndex =
                  values.rowDisplayOptions?.findIndex((r) => r.group === group);

                const rowDisplayOptionValue =
                  rowDisplayOptionIndex !== undefined &&
                  rowDisplayOptionIndex > -1
                    ? values.rowDisplayOptions?.[rowDisplayOptionIndex]
                    : undefined;

                const field = (
                  <SwitchField
                    isChecked={!!rowDisplayOptionValue?.active}
                    key={group}
                    onChange={(event) => {
                      const currentRowDisplayOptions = [
                        ...(values.rowDisplayOptions ?? []),
                      ];

                      if (
                        rowDisplayOptionIndex !== undefined &&
                        rowDisplayOptionIndex > -1 &&
                        rowDisplayOptionValue
                      ) {
                        currentRowDisplayOptions[rowDisplayOptionIndex] = {
                          ...rowDisplayOptionValue,
                          active: event.target.checked,
                        };
                      }

                      setFieldValue(
                        'rowDisplayOptions',
                        currentRowDisplayOptions,
                      );
                    }}
                    label={t(rowGroupToI18nKey[group])}
                  />
                );

                if (
                  rowDisplayOptionValue?.group === 'analytical' &&
                  rowDisplayOptionValue?.active
                ) {
                  return (
                    <div
                      key={`${group}_line_with_analyticalCommonLine`}
                      className="flex flex-col"
                    >
                      {field}
                      <SwitchField
                        isChecked={
                          !!rowDisplayOptionValue?.analyticalCommonLine?.active
                        }
                        key={`${group}_analyticalCommonLine`}
                        onChange={(event) => {
                          const currentRowDisplayOptions = [
                            ...(values.rowDisplayOptions ?? []),
                          ];

                          if (
                            (rowDisplayOptionIndex ||
                              rowDisplayOptionIndex === 0) &&
                            rowDisplayOptionValue
                          ) {
                            currentRowDisplayOptions[rowDisplayOptionIndex] = {
                              ...rowDisplayOptionValue,
                              analyticalCommonLine: {
                                active: event.target.checked,
                              },
                            };
                          }

                          setFieldValue(
                            'rowDisplayOptions',
                            currentRowDisplayOptions,
                          );
                        }}
                        label={t(
                          'bookkeep.settings.export.rowDisplayOptions.analyticalCommonLine',
                        )}
                        className="pl-24"
                      />
                    </div>
                  );
                }

                return field;
              })}
            </div>
          </FormField>
        </SettingFieldPanel>
      )}
    </form>
  );
};
