import { Button, Modal } from '@dev-spendesk/grapes';
import { type MouseEventHandler } from 'react';

type Props = {
  isOpen: boolean;
  onCloseModal: MouseEventHandler<HTMLElement>;
  onConfirm: MouseEventHandler<HTMLElement>;
};
export const NodeDeleteConfirmationModal = ({
  isOpen,
  onCloseModal,
  onConfirm,
}: Props) => {
  return (
    <Modal
      title="Delete node"
      iconName="sparkle"
      iconVariant="info"
      actions={[
        <Button
          key="secondary"
          type="button"
          variant="secondaryNeutral"
          onClick={(event) => {
            onCloseModal(event);
          }}
          text="Cancel"
        />,
        <Button
          key="primary"
          type="button"
          variant="primaryBrand"
          text="Yess! delete it"
          onClick={(event) => {
            onConfirm(event);
          }}
        />,
      ]}
      isOpen={isOpen}
    >
      Deletion confirmation content
    </Modal>
  );
};
