import { AccordionItem } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import styles from './OperationalOnboardingItem.module.css';
import { type OperationalOnboardingTask } from '../../../../../models';
import { OnboardingTaskAccordionHeader } from '../../OnboardingTask/OnboardingTaskAccordionHeader';
import { OnboardingTaskButtons } from '../../OnboardingTask/OnboardingTaskButtons';
import { useNavigateToOnboardingTask } from '../../OnboardingTask/useNavigateToOnboardingTask';
import {
  useTaskDescription,
  useTaskName,
  useTaskRedirectionCta,
  useTaskLearnMoreLink,
} from '../../translationHooks';

type Props = {
  task: OperationalOnboardingTask;
  closeAccordion: () => void;
  closeAccordionAndOpenNext: () => void;
};

export const OperationalOnboardingItem = ({
  task,
  closeAccordion,
  closeAccordionAndOpenNext,
}: Props) => {
  const taskName = useTaskName(task);
  const learnMore = useTaskLearnMoreLink(task);
  const redirectCta = useTaskRedirectionCta(task);
  const navigateToOnboardingTask = useNavigateToOnboardingTask(task);

  return (
    <div className={styles.container}>
      <AccordionItem
        className={cx(styles.accordion, {
          [styles.disabled]: task.status === 'disabled',
        })}
        renderHeader={() => (
          <OnboardingTaskAccordionHeader task={task} taskName={taskName} />
        )}
      >
        <div
          className={cx('px-64 pb-32 text-primary body-m', {
            [styles.disabled]: task.status === 'disabled',
          })}
        >
          <p>
            {useTaskDescription(task)}{' '}
            {learnMore && (
              <a
                className={styles.helpCenterLink}
                href={learnMore.uri}
                rel="noreferrer"
                target="_blank"
              >
                {learnMore.text}
              </a>
            )}
          </p>
          <div className="mt-32 flex items-center gap-8">
            <OnboardingTaskButtons
              task={task}
              redirectCta={redirectCta}
              postUnskipAction={closeAccordion}
              postSkipAction={closeAccordionAndOpenNext}
              withCaretRightIcon={false}
              handleStartOnboardingTask={navigateToOnboardingTask}
            />
          </div>
        </div>
      </AccordionItem>
    </div>
  );
};
