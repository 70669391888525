import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';
import { useAccessibleCardsLinks } from 'src/core/pages/CardsPage/CardsPage';

import { NavLink } from '../NavLink/NavLink';

type Props = {
  company: {
    id: string;
  };
};

export const CardLink = ({ company }: Props) => {
  const { t } = useTranslation('global');
  const cardsLinks = useAccessibleCardsLinks();

  return (
    <NavLink
      href={routeFor(cardsLinks[0].path, { company: company.id })}
      iconName="card"
      activePaths={[
        routes.SUBSCRIPTIONS.path,
        routes.CARD.path,
        routes.CARDS.path,
      ]}
    >
      {t('misc.card_plural')}
    </NavLink>
  );
};
