import {
  Button,
  CheckboxInput,
  DropdownItem,
  DropdownMenu,
} from '@dev-spendesk/grapes';
import classnames from 'classnames';
import React from 'react';

import styles from './EntityListFilter.module.css';

interface Props<T extends string> {
  label: string;
  activeFilters: T[];
  setActiveFilters: (filters: T[]) => void;
  filterOptions: {
    label: string;
    key: T;
  }[];
  isDisabled?: boolean;
  isMonoValue?: boolean;
}

export const EntityListFilter = <T extends string>({
  label,
  activeFilters,
  setActiveFilters,
  filterOptions,
  isDisabled = false,
  isMonoValue = false,
}: Props<T>) => {
  const getButtonLabel = () => {
    if (activeFilters.length === 0) {
      return label;
    }

    if (activeFilters.length === 1) {
      return (
        filterOptions.find(({ key }) => key === activeFilters[0])?.label ??
        label
      );
    }

    return `${label} (${activeFilters.length})`;
  };

  return (
    <DropdownMenu
      keepOpenOnSelect
      renderButton={(getToggleButtonProps) => (
        <Button
          {...getToggleButtonProps()}
          iconName="adjustments-horizontal"
          iconPosition="left"
          variant={
            activeFilters.length && !isDisabled
              ? 'secondaryInfo'
              : 'secondaryNeutral'
          }
          className={classnames(
            'text-primary body-m',
            styles.EntityListFilterEllipsis,
            activeFilters.length && !isDisabled && 'text-selected',
          )}
          text={getButtonLabel()}
          isDisabled={isDisabled}
          aria-selected={activeFilters.length > 0}
        />
      )}
      options={filterOptions}
      onSelect={({ key }) => {
        const isAlreadySelected = activeFilters.includes(key);

        if (isMonoValue) {
          setActiveFilters(isAlreadySelected ? [] : [key]);
          return;
        }

        if (isAlreadySelected) {
          const filteredSelectedOptions = activeFilters.filter(
            (s) => s !== key,
          );
          setActiveFilters(
            filteredSelectedOptions.length ? filteredSelectedOptions : [],
          );
        } else {
          setActiveFilters([...activeFilters, key]);
        }
      }}
      renderOption={(option) => {
        const isSelected = activeFilters.includes(option.key);
        return (
          <DropdownItem
            key={option.key}
            label={option.label}
            className="text-nowrap"
            isSelected={isSelected}
            isHighlighted={isSelected}
            prefix={
              !isMonoValue && (
                <CheckboxInput isChecked={isSelected} onChange={() => {}} />
              )
            }
          />
        );
      }}
    />
  );
};
