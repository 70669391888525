import { HighlightIcon, Skeleton } from '@dev-spendesk/grapes';
import React from 'react';

export const HomepageWalletPlaceholder = () => {
  return (
    <div className="rounded-8 bg-secondary-default p-24">
      <div className="grid grid-cols-2 content-center gap-24">
        <Skeleton width="208px" height="128px" />
        <div className="flex flex-col content-stretch gap-24 self-center">
          <div className="flex items-center gap-12">
            <HighlightIcon name="wallet" variant="purple" size="m" />
            <Skeleton width="164px" height="36px" />
          </div>
          <div className="flex items-center gap-12">
            <HighlightIcon
              name="card-rounded-arrows"
              variant="purple"
              size="m"
            />
            <Skeleton width="164px" height="36px" />
          </div>
        </div>
      </div>
    </div>
  );
};
