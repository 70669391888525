import { Button, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import { useBulkArchiveSupplierMutation } from 'modules/bookkeep/accounts-payable/hooks/useBulkArchiveSupplierMutation';

import { type SupplierAndAccountPayable } from '../../AccountingSupplierAccountsList';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  supplierAndAccountPayable: SupplierAndAccountPayable | null;
};

export const ArchiveSupplierModal = ({
  isOpen,
  onClose,
  supplierAndAccountPayable,
}: Props) => {
  const { t } = useTranslation('global');

  const [bulkArchiveSupplier] = useBulkArchiveSupplierMutation();

  const onConfirm = async () => {
    if (!supplierAndAccountPayable) {
      return;
    }

    try {
      await bulkArchiveSupplier({
        supplierIds: [supplierAndAccountPayable.supplierId],
      });
      onClose();
    } catch {
      // Not doing anything because the hook already show an error notification
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      iconName="trash"
      iconVariant="alert"
      title={t('setupHub.supplierAccounts.archiveSupplier.title', {
        name: supplierAndAccountPayable?.supplierName ?? '',
      })}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          onClick={onClose}
          text={t('misc.cancel')}
        />,
        <Button
          key="confirm"
          variant="primaryAlert"
          form="update-accounts-payable-form"
          onClick={onConfirm}
          text={t('misc.delete')}
        />,
      ]}
    />
  );
};
