import { Button, Modal } from '@dev-spendesk/grapes';
import { useSelector } from 'react-redux';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { getI18nContext } from 'src/core/selectors/i18n';

type WireTransfersApprovedModalProps = {
  count: number;
  isOpen: boolean;
  onComplete: () => void;
};

export const WireTransfersApprovedModal = ({
  count,
  isOpen,
  onComplete,
}: WireTransfersApprovedModalProps) => {
  const { t } = useTranslation('global');
  const i18nContext = useSelector(getI18nContext);

  const title = t(
    'submitMyInvoice.scheduled.wireTransfer.transfersConfirmedTitle',
    {
      count,
    },
  );

  return (
    <Modal
      actions={[
        <Button
          key="continue"
          text={t('submitMyInvoice.scheduled.wireTransfer.continue')}
          variant="primaryBrand"
          onClick={onComplete}
        />,
      ]}
      iconName="arrow-left-right"
      iconVariant="info"
      title={title}
      isOpen={isOpen}
    >
      <p>
        {t(
          'submitMyInvoice.scheduled.wireTransfer.transfersConfirmedDescription',
          { count, ...i18nContext },
        )}
      </p>
    </Modal>
  );
};
