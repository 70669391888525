import { Button, SkeletonButton } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { PaymentContext } from 'modules/invoices/hooks';

import {
  useWalletTransactionForecastOnSelectedTransfersQuery,
  useConfirmTransfersTrackingEvents,
} from '../../../../hooks';
import { selectors, actions, useSelectorWithContext } from '../../../../redux';
import { TransferConfirmationModal } from '../../../TransferConfirmationModal';

export const ConfirmTransfersButton = () => {
  const { t } = useTranslation('global');
  const { type } = PaymentContext.useContext();
  const confirmTransfersTrackingEvents = useConfirmTransfersTrackingEvents();

  const transfersSelected = useSelectorWithContext(
    selectors.selectTransfersSelected,
  );
  const transfersSelectedCount = useSelectorWithContext(
    selectors.selectTransfersSelectedCount,
  );
  const walletTransactionForecastOnSelectedTransfersQueryState =
    useWalletTransactionForecastOnSelectedTransfersQuery()();

  const [isConfirmTransfersModalOpen, setIsConfirmTransfersModalOpen] =
    useState<boolean>(false);
  const openConfirmTransfersModal = () => {
    setIsConfirmTransfersModalOpen(true);
    confirmTransfersTrackingEvents.onConfirmTransfersConfirmationModalOpened({
      transfers: transfersSelected,
    });
  };
  const closeConfirmTransfersModal = () => {
    setIsConfirmTransfersModalOpen(false);
    confirmTransfersTrackingEvents.onConfirmTransfersConfirmationModalClosed({
      transfers: transfersSelected,
    });
  };

  const dispatch = useDispatch();

  const resetTransfersSelection = () => {
    dispatch(actions.resetTransfersSelection({ type }));
  };

  return (
    <>
      <QuerySuspense
        queryState={walletTransactionForecastOnSelectedTransfersQueryState}
        loading={<SkeletonButton />}
        fallback={() => (
          // If we could not verify that there's enough funds, we authorise the action and fallback on the back-end check
          <Button
            text={t('invoices.transfer.actions.confirmTransfer', {
              count: transfersSelectedCount,
            })}
            variant="primaryBrand"
            onClick={openConfirmTransfersModal}
          />
        )}
      >
        {({ hasEnoughFunds }) => (
          <Button
            text={t('invoices.transfer.actions.confirmTransfer', {
              count: transfersSelectedCount,
            })}
            variant="primaryBrand"
            onClick={openConfirmTransfersModal}
            isDisabled={!hasEnoughFunds}
          />
        )}
      </QuerySuspense>
      <TransferConfirmationModal
        transfers={transfersSelected}
        isOpen={isConfirmTransfersModalOpen}
        onClose={closeConfirmTransfersModal}
        onConfirm={resetTransfersSelection}
      />
    </>
  );
};
