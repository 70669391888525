import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type Payment } from '../models/payment';

const getPaymentsByRequestIdKey = (requestId: string | undefined) => [
  'payments',
  { request_id: requestId },
];

export const useFetchPaymentsByRequestId = (
  requestId: string | undefined,
): QueryState<Payment[]> => {
  return useQuery({
    key: getPaymentsByRequestIdKey(requestId),
    isEnabled: Boolean(requestId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/payments`,
      params: {
        request_id: requestId,
      },
    },
    reshapeData: (data) => data,
  });
};
