import {
  Button,
  Callout,
  ModalFooter,
  RadioField,
  RadioGroup,
  TextInput,
  IconButton,
  Tooltip,
  colors,
} from '@dev-spendesk/grapes';
import { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import { useCompanyAccountOwnerQuery } from 'modules/members/hooks/useCompanyAccountOwnerQuery';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { track, AnalyticEventName } from 'src/core/utils/analytics';
import Validations from 'src/core/utils/validations';

import { type InviteLink } from '../../models/invite';

type Props = {
  companyDomain: string;
  inviteLink?: InviteLink;
  createInviteLink: (domain?: string) => Promise<void>;
  updateInviteLink: (domain?: string) => Promise<void>;
  deleteInviteLink: () => void;
  onClose: () => void;
};

export const MemberInviteLinkContent = ({
  companyDomain,
  createInviteLink,
  updateInviteLink,
  deleteInviteLink,
  onClose,
  inviteLink,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();
  const accountOwnerQuery = useCompanyAccountOwnerQuery(!!inviteLink?.key);
  const accountOwner = unwrapQuery(accountOwnerQuery);

  const accountOwnerDomain = useMemo(() => {
    if (accountOwner) {
      const { email } = accountOwner;
      return email.split('@')[1];
    }
    return companyDomain;
  }, [accountOwner, companyDomain]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDomainRestricted, setIsDomainRestricted] = useState(
    Boolean(inviteLink?.restrictDomain),
  );
  const [domain, setDomain] = useState<string | undefined>(
    inviteLink?.restrictDomain ?? accountOwnerDomain,
  );
  const isDomainValid = domain ? Validations.validateDomain(domain) : true;

  const handleCreateInviteLink = async (handleDomain: boolean) => {
    try {
      const domainToSet = handleDomain ? domain : undefined;
      setIsLoading(true);
      await createInviteLink(domainToSet);
    } catch {
      dangerNotif(t('members.createShareableLinkError'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateInviteLink = async (handleDomain: boolean) => {
    try {
      const domainToSet = handleDomain ? domain : undefined;
      if (domainToSet && !Validations.validateDomain(domainToSet)) {
        return;
      }
      setIsLoading(true);
      await updateInviteLink(domainToSet);
      onClose();
    } catch {
      dangerNotif(t('members.shareableLinkUpdateError'));
    } finally {
      setIsLoading(false);
    }
  };

  const updateDomain = (value: string) => {
    setDomain(value[0] === '@' ? value.slice(1) : value);
  };

  const onOptionSelect = (selectedOption: boolean) => {
    setIsDomainRestricted(selectedOption);
  };

  const clipboardCopy = async (value: string) => {
    try {
      track(AnalyticEventName.SETTINGS_MEMBERS_INVITE_COPY_BUTTON_CLICKED);
      await navigator.clipboard.writeText(value);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const renderDomainRestrictOption = () => {
    let domainPrefixed = domain;
    if (domain?.[0] !== '@') {
      domainPrefixed = `@${domainPrefixed}`;
    }

    const domainTextInput = (
      <TextInput
        className="mx-8"
        value={domain ?? domainPrefixed}
        isDisabled={!isDomainRestricted}
        isInvalid={!isDomainValid}
        onChange={(e) => updateDomain(e.target.value)}
        onBlur={() => {
          if (domain) {
            setIsDomainRestricted(true);
          }
        }}
      />
    );
    return (
      <div>
        <Trans
          i18nKey="members.onlyAllowEmailAddresses"
          components={[domainTextInput]}
        />
      </div>
    );
  };

  const renderLinkActions = () => (
    <>
      <Button
        variant="secondaryNeutral"
        text={t('misc.cancel')}
        onClick={onClose}
      />
      <Button
        variant="primaryBrand"
        text={t('misc.save')}
        onClick={() => handleUpdateInviteLink(isDomainRestricted)}
      />
    </>
  );

  const renderLinkView = () => {
    const link = `${window.location.origin}/auth/invites/link?token=${inviteLink?.key}`;
    return (
      <>
        <RadioGroup
          direction="column"
          name="radio-buttons-invite-link"
          value={isDomainRestricted.toString()}
          onChange={(event) => {
            onOptionSelect(event.target.value === 'true');
          }}
        >
          <RadioField
            className="mb-8"
            value="false"
            label={t('members.allowAnyoneWithLink')}
          />
          <RadioField value="true" label={renderDomainRestrictOption()} />
        </RadioGroup>
        {isDomainRestricted && !isDomainValid && (
          <Callout
            className="mt-16"
            title={
              accountOwnerDomain
                ? t('members.invalidDomain', { accountOwnerDomain })
                : t('members.invalidDomainWithoutExample')
            }
            variant="alert"
          />
        )}
        <div className="mt-24 flex w-full gap-8">
          <TextInput
            className="MemberInviteLinkContent__input"
            fit="parent"
            value={link}
            rightAddon={
              <Button
                variant="tertiaryNeutral"
                text={t('misc.copy')}
                onClick={() => clipboardCopy(link)}
              />
            }
          />
          <Tooltip content={t('members.deleteTheLink')}>
            <IconButton
              iconName="trash"
              variant="tertiaryNeutral"
              onClick={deleteInviteLink}
              iconColor={colors.contentAlertDefault}
              aria-label={t('members.deleteTheLink')}
            />
          </Tooltip>
        </div>
        <ModalFooter>{renderLinkActions()}</ModalFooter>
      </>
    );
  };

  const renderNolinkActions = () => (
    <>
      <Button
        variant="secondaryNeutral"
        text={t('misc.cancel')}
        onClick={onClose}
      />
      <Button
        text={t('members.createShareableLink')}
        isLoading={isLoading}
        onClick={() => handleCreateInviteLink(false)}
      />
    </>
  );

  return (
    <div className="text-left">
      <p className="py-16">{t('members.allowPeopleToJoinSpendeskLink')}</p>
      {inviteLink?.key ? (
        renderLinkView()
      ) : (
        <ModalFooter>{renderNolinkActions()}</ModalFooter>
      )}
    </div>
  );
};
