import { Button, Modal } from '@dev-spendesk/grapes';
import { type Dispatch, type SetStateAction } from 'react';

import { type EmployeeAccountCode } from 'modules/bookkeep';
import { type SupplierAccount } from 'modules/bookkeep/accounts-payable/types';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { type QueryState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { AccountPayableCreationForm } from './AccountPayableCreationForm';
import { type AccountPayableCreationModalState } from './AccountPayableCreationModalState';
import { useAccountLength, useHasAuxiliaryAccountsEnabled } from '../../hooks';
import { validateAccountPayable } from '../../settings/integrations/pages/LegacyIntegrationsAccountingPage/utils';

export type AccountPayableCreationModalProps = {
  integrationStatus: IntegrationStatusWithIntegration;
  accountPayableCreationModalState: AccountPayableCreationModalState;
  setAccountPayableCreationModalState: Dispatch<
    SetStateAction<AccountPayableCreationModalState>
  >;
  getSupplierAccountsQueryState: QueryState<SupplierAccount[], unknown>;
  getEmployeeAccountCodesQuery: QueryState<EmployeeAccountCode[], unknown>;
  onClose: () => void;
  createAccountPayable: (
    generalAccountCode: string,
    auxiliaryAccountCode: string | undefined,
  ) => Promise<string | undefined>;
};

export const AccountPayableCreationModal = ({
  integrationStatus,
  accountPayableCreationModalState,
  setAccountPayableCreationModalState,
  getSupplierAccountsQueryState,
  getEmployeeAccountCodesQuery,
  onClose,
  createAccountPayable,
}: AccountPayableCreationModalProps) => {
  const { t } = useTranslation('global');
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();
  const accountLength = useAccountLength();

  return (
    <Modal
      actions={[
        <Button
          key="no"
          onClick={() => {
            setAccountPayableCreationModalState({
              ...accountPayableCreationModalState,
              kind: 'closed',
              error: undefined,
            });
            onClose();
          }}
          text={t('misc.cancel')}
          variant="secondaryNeutral"
        />,
        <Button
          key="yes"
          text={t('bookkeep.accountsPayable.createAccountPayable.modalSave')}
          variant="primaryBrand"
          isDisabled={Boolean(
            accountPayableCreationModalState.kind !== 'form' ||
              accountPayableCreationModalState.error,
          )}
          onClick={async () => {
            if (accountPayableCreationModalState.kind !== 'form') {
              // eslint-disable-next-line no-console
              console.warn(
                "This shouldn't happen: modal will always be open when we see modal buttons",
              );
              return;
            }
            const result = validateAccountPayable(
              accountPayableCreationModalState.createdAccount
                .generalAccountCode,
              accountPayableCreationModalState.createdAccount
                .auxiliaryAccountCode,
              accountLength,
              getSupplierAccountsQueryState,
              getEmployeeAccountCodesQuery,
              integrationStatus.integration,
              auxiliaryAccountsEnabled,
            );

            if (result.outcome === 'valid') {
              try {
                const createAccountPayableId = await createAccountPayable(
                  accountPayableCreationModalState.createdAccount
                    .generalAccountCode,
                  accountPayableCreationModalState.createdAccount
                    .auxiliaryAccountCode ?? undefined,
                );

                if (createAccountPayableId) {
                  setAccountPayableCreationModalState({ kind: 'closed' });
                }
              } catch (error) {
                // eslint-disable-next-line no-console
                console.warn(
                  'An error occurred while creating account payable',
                  {
                    error,
                  },
                );
              }
              return;
            }

            switch (result.reason) {
              case 'required':
              case 'invalidPattern':
              case 'codeAlreadyExists': {
                setAccountPayableCreationModalState({
                  ...accountPayableCreationModalState,
                  error: result.reason,
                });
                return;
              }
              default:
                rejectUnexpectedValue('accountPayableValidation', result);
            }
          }}
        />,
      ]}
      iconName="plus"
      iconVariant="info"
      title={t('bookkeep.accountsPayable.createAccountPayable.modalTitle')}
      isOpen={accountPayableCreationModalState.kind === 'form'}
      onClose={() => {
        setAccountPayableCreationModalState({
          ...accountPayableCreationModalState,
          kind: 'closed',
          error: undefined,
        });
        onClose();
      }}
    >
      {accountPayableCreationModalState.kind === 'form' && (
        <AccountPayableCreationForm
          error={accountPayableCreationModalState.error}
          createdAccount={accountPayableCreationModalState.createdAccount}
          setCreatedAccount={(account) => {
            const newAccountPayableCreationModalState = {
              ...accountPayableCreationModalState,
              createdAccount: account,
            };
            return setAccountPayableCreationModalState({
              ...newAccountPayableCreationModalState,
              error: undefined,
            });
          }}
        />
      )}
    </Modal>
  );
};
