import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';

import { GET_TEAMS } from './queries';

type RawTeam = {
  id: string;
  name: string;
  archiveDate?: string | null;
};

type Team = {
  id: string;
  name: string;
};

export type LoadTeamsByIdsRawResponse = {
  company: { teams: { edges: { node: RawTeam }[] } };
};

export type LoadTeamsByIdsVariables = {
  ids: string[];
};

export const useLoadTeamsByIds = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  return async (ids: string[]): Promise<Team[]> => {
    if (!ids.length) {
      return [];
    }

    const data = await queryClient.fetchQuery(
      ['useLoadTeamsByIds', companyId, ids],
      getFetcher<LoadTeamsByIdsRawResponse>({
        companyId,
        getRequest: () => ({
          type: 'graphQL',
          target: 'v2',
          query: GET_TEAMS,
          variables: {
            ids,
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return reshapeFilterValues(data.company.teams.edges);
  };
};

/**
 * Reshapers
 */

const reshapeFilterValues = (
  edges: {
    node: RawTeam;
  }[],
): Team[] => {
  return edges
    .map(({ node }) =>
      node.archiveDate
        ? null
        : {
            id: node.id,
            name: node.name,
          },
    )
    .filter((item): item is Team => item !== null);
};
