import { Label } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import { DefaultTaxAccountCodeFormField } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/DefaultTaxAccountCodeFormField';
import { DefaultTaxAccountRateFormField } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/DefaultTaxAccountRateFormField';

type Props = {
  code: string;
  codeError?: string;
  rate: string;
  rateError?: string;
  setCode: (code: string) => void;
  setRate: (rate: string) => void;
};

export const DefaultVatAccountForm = ({
  code,
  codeError,
  rate,
  rateError,
  setCode,
  setRate,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="flex flex-col gap-8 rounded-4 bg-primary-default px-24 py-16">
      <div className="mb-8">
        <Label
          className="p-0"
          label={t('setupHub.vatAccounts.vat.defaultVatLabel')}
        />
        <p className="text-secondary-bg-primary body-m">
          {t('setupHub.vatAccounts.vat.defaultVatDescription')}
        </p>
      </div>
      <div className="flex gap-16">
        <DefaultTaxAccountCodeFormField
          error={codeError}
          value={code}
          onChange={setCode}
          visuallyHideLabel
        />
        <DefaultTaxAccountRateFormField
          error={rateError}
          onChange={setRate}
          value={rate}
          visuallyHideLabel
        />
      </div>
    </div>
  );
};
