import {
  Autocomplete,
  FormField,
  SkeletonText,
  SwitchField,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense/';
import { useDebouncedState } from 'common/hooks/useDebounceState';
import {
  type I18nKey,
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { type AccountingSoftware } from 'src/core/modules/bookkeep/integration/status';

import styles from './AuthSection.module.css';
import {
  type DefaultVendors,
  type DefaultVendorTypes,
  type Vendor,
} from '../../../../../accounting/vendor';
import { useDeleteDefaultVendorMutation } from '../../../../hooks/useDeleteDefaultVendorMutation';
import { useGetDefaultVendorsQuery } from '../../../../hooks/useGetDefaultVendorsQuery';
import { useGetVendorsQuery } from '../../../../hooks/useGetVendorsQuery';
import { useSetDefaultVendorMutation } from '../../../../hooks/useSetDefaultVendorMutation';

type DefaultVendorInputProps = {
  defaultVendorType: DefaultVendorTypes;
  savedDefaultVendor: Vendor | undefined;
  integration: AccountingSoftware;
};

export type AutocompleteOption = { key: string; label: string };

const defaultVendorTypes: DefaultVendorTypes[] = [
  'physical',
  'singlePurchase',
  'subscription',
];

export const DefaultVendorsSection = ({
  integration,
}: {
  integration: AccountingSoftware;
}) => {
  const defaultVendorsQueryState = useGetDefaultVendorsQuery();
  const { t } = useTranslation('global');

  return (
    <>
      <div className="separator my-24" />
      <p className={classNames(styles.defaultForTitle, 'title-m')}>
        {t('bookkeep.integrations.settings.integrationSettings')}
      </p>
      <QuerySuspense
        queryState={defaultVendorsQueryState}
        loading={
          <>
            {defaultVendorTypes.map((type) => (
              <SkeletonText size="xxl" key={`${type}_loading`} />
            ))}
          </>
        }
        fallback={(error) => (
          <QueryError queryError={error} componentType="Callout" />
        )}
      >
        {(defaultVendors: DefaultVendors) => (
          <>
            {defaultVendorTypes.map((type) => (
              <DefaultVendorToggle
                key={type}
                defaultVendorType={type}
                savedDefaultVendor={defaultVendors[type]}
                integration={integration}
              />
            ))}
          </>
        )}
      </QuerySuspense>
      <div className="separator my-24" />
    </>
  );
};

const DefaultVendorToggle = ({
  defaultVendorType,
  savedDefaultVendor,
  integration,
}: DefaultVendorInputProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!savedDefaultVendor);
  const [deleteDefaultVendorMutation] = useDeleteDefaultVendorMutation();
  const { t } = useTranslation('global');
  useEffect(() => {
    if (savedDefaultVendor && !isChecked) {
      deleteDefaultVendorMutation({ type: defaultVendorType });
    }
  }, [isChecked, savedDefaultVendor]);

  const defaultVendorTypeString = t(defaultVendorsToI18nKey[defaultVendorType]);

  return (
    <>
      <SwitchField
        fit="parent"
        label={t('bookkeep.integrations.settings.defaultVendors.switchLabel', {
          type: defaultVendorTypeString,
        })}
        helpText={t(
          'bookkeep.integrations.settings.defaultVendors.switchText',
          {
            type: defaultVendorTypeString,
          },
        )}
        isChecked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
      />
      {isChecked && (
        <DefaultVendorSelect
          defaultVendorType={defaultVendorType}
          savedDefaultVendor={savedDefaultVendor}
          integration={integration}
        />
      )}
    </>
  );
};

const DefaultVendorSelect = ({
  defaultVendorType,
  savedDefaultVendor,
  integration,
}: DefaultVendorInputProps) => {
  const [selectedOption, setSelectedOption] = useState<
    AutocompleteOption | undefined
  >(
    savedDefaultVendor
      ? {
          key: savedDefaultVendor.id,
          label: savedDefaultVendor.name,
        }
      : undefined,
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [search, setSearch] = useDebouncedState<string>('', 300);
  const [setDefaultVendor] = useSetDefaultVendorMutation();
  const vendorsQueryState = useGetVendorsQuery(search, undefined, 'synced');
  const { pushNotif } = useNotifications();
  const { t } = useTranslation('global');

  const options =
    vendorsQueryState.status === 'success'
      ? vendorsQueryState.data.map((vendor: Vendor) => ({
          key: vendor.id,
          label: vendor.name,
        }))
      : [];

  const onSelect = async (vendor: AutocompleteOption) => {
    if (!vendor || vendor === selectedOption) {
      return;
    }
    try {
      await setDefaultVendor({
        id: vendor.key,
        type: defaultVendorType,
      });
      setSelectedOption(vendor);
      pushNotif({
        type: NotificationType.Success,
        message: t(
          'bookkeep.integrations.settings.defaultVendors.successMessage',
        ),
      });
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('misc.errors.unknownError'),
      });
    }
  };

  return (
    <FormField
      className={styles.supplierSelectFormField}
      label={getSelectLabelTranslation(integration, t)}
    >
      <Autocomplete
        fit="parent"
        // TODO: add the supplier icon in front of the supplier name?
        placeholder={t(
          'bookkeep.integrations.settings.defaultVendors.selectPlaceholder',
        )}
        isLoading={vendorsQueryState.status === 'loading'}
        value={isFocused ? undefined : selectedOption}
        options={options}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onSearch={(keyword) => setSearch(keyword ?? '')}
        onSelect={onSelect}
        renderNoOptions={(rawValue) => (
          <AutocompleteNoOptions value={rawValue} />
        )}
      />
    </FormField>
  );
};

function getSelectLabelTranslation(
  integration: AccountingSoftware,
  t: TGlobalFunctionTyped,
) {
  if (integration === 'Netsuite') {
    return t(
      'bookkeep.integrations.settings.defaultVendors.selectLabelNetsuite',
    );
  }

  return t('bookkeep.integrations.settings.defaultVendors.selectLabelSage100');
}

const defaultVendorsToI18nKey: Record<DefaultVendorTypes, I18nKey> = {
  physical: 'bookkeep.integrations.settings.defaultVendors.types.physical',
  singlePurchase:
    'bookkeep.integrations.settings.defaultVendors.types.singlePurchase',
  subscription:
    'bookkeep.integrations.settings.defaultVendors.types.subscription',
};
