import { AutocompleteMultiple } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { spendTypeOptions } from '../../spendType';

export const SpendTypeSelect = ({
  selectedValues,
  onSelect,
}: {
  selectedValues: string[] | undefined;
  onSelect: (values: string[]) => void;
}) => {
  const [options, setOptions] = useState(spendTypeOptions);

  return (
    <AutocompleteMultiple
      fit="content"
      className="w-full"
      options={options}
      values={spendTypeOptions.filter((option) =>
        selectedValues?.includes(option.key),
      )}
      translations={{
        selectAll: `Select all (${options.length} spend types)`,
        selected: selectedValues
          ? selectedValues
              .map(
                (value) =>
                  spendTypeOptions.find((option) => option.key === value)
                    ?.label,
              )
              .join(', ')
          : '',
      }}
      onSearch={(value) => {
        if (!value) {
          setOptions(spendTypeOptions);
          return;
        }
        setOptions(
          spendTypeOptions.filter((option) =>
            option.label.toLowerCase().includes(value.toLowerCase()),
          ),
        );
      }}
      onSelect={(values) => {
        onSelect(values.map((value) => value.key));
      }}
      placeholder="Select a spend type"
    />
  );
};
