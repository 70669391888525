import {
  ModalBody,
  Skeleton,
  SkeletonButton,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

import { useFeature } from 'common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import './MemberModalLoader.css';

export const MemberModalLoader = () => {
  const isTeamsFeatureEnabled = useFeature(FEATURES.TEAMS);
  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  const isPlayByRulesFeatureEnabled = useFeature(FEATURES.PLAY_BY_RULES);

  let numberOfRows = 5;
  if (isTeamsFeatureEnabled) {
    numberOfRows++;
  }
  if (isCostCentersFeatureEnabled) {
    numberOfRows++;
  }
  if (isPlayByRulesFeatureEnabled) {
    numberOfRows++;
  }

  return (
    <ModalBody>
      <div>
        <div>
          <Skeleton
            height="var(--unit-64)"
            width="var(--unit-64)"
            className="MemberModalLoader__content__icon__skeleton"
          />
        </div>
        <SkeletonText size="xl" width="212px" className="mb-16" />
        <SkeletonText size="l" width="410px" className="mb-16" />
        <div className="MemberModalLoader__divider separator" />

        <div className="mb-24 flex items-center justify-between">
          <SkeletonText size="xl" width="136px" />
          <SkeletonButton />
        </div>
        {Array.from({ length: numberOfRows }, (_, key) => (
          <SkeletonRow key={key} />
        ))}
        <div className="MemberModalLoader__divider separator" />
      </div>
    </ModalBody>
  );
};

function SkeletonRow() {
  return (
    <div className="mb-[14px] flex items-center gap-40">
      <SkeletonText size="m" width="100px" />
      <Skeleton height="36px" width="100%" className="flex-1" />
    </div>
  );
}
