import { Skeleton } from '@dev-spendesk/grapes';
import React from 'react';

import { ProfileMenu } from './ProfileMenu/ProfileMenu';

import '../pages/ProfilePage.css';

export const ProfilePageSkeleton = () => {
  return (
    <div className="page__container">
      <ProfileMenu />
      <div className="ProfilePage m-auto flex max-w-[1000px] grow flex-col items-center gap-48 bg-primary-default p-40">
        <div className="ProfilePage__TabContainer">
          <div className="flex flex-col content-stretch gap-24">
            <div className="ProfilePage__TabContainer flex flex-col content-stretch gap-16">
              <section className="flex flex-col content-stretch gap-24">
                <div className="flex flex-col content-stretch gap-16">
                  <Skeleton height="32px" width="100%" className="w-full" />
                  <Skeleton height="20px" width="100%" className="w-full" />
                </div>
                <Skeleton height="256px" width="100%" className="w-full" />
              </section>
              <section className="flex flex-col content-stretch gap-24">
                <div className="flex flex-col content-stretch gap-16">
                  <Skeleton height="32px" width="100%" className="w-full" />
                  <Skeleton height="20px" width="100%" className="w-full" />
                </div>
                <Skeleton height="256px" width="100%" className="w-full" />
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
