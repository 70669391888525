import { InfoTip } from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { type BreakdownGaugePreparedBar } from './BreakdownGauge';

type Props = {
  preparedBar: BreakdownGaugePreparedBar;
  isLastItem: boolean;
  isCondensed?: boolean;
};

export const BreakdownGaugeLegendItem = ({
  preparedBar,
  isLastItem,
  isCondensed = false,
}: Props) => {
  return (
    <div
      key={`legend-${preparedBar.key}`}
      className="flex shrink grow-0 flex-col"
    >
      <div className="flex items-center gap-4">
        <div
          className="inline-block h-8 w-8 rounded-[50%]"
          style={{
            background: preparedBar.backgrounds.ellipsis,
          }}
        />
        <p
          className={classNames(
            'text-secondary-bg-primary',
            isCondensed ? 'body-s' : 'body-m',
          )}
          style={{ color: preparedBar.label.color }}
        >
          {preparedBar.label.value}
        </p>
        {preparedBar.label.tooltip && (
          <InfoTip
            content={
              <div className="leading-normal body-s">
                {preparedBar.label.tooltip}
              </div>
            }
          />
        )}
      </div>
      <p
        className={classNames(
          isCondensed ? 'body-s' : 'body-m',
          isLastItem && 'md:text-end',
        )}
        style={{ color: preparedBar.value.color }}
      >
        {preparedBar.value.formatted}
      </p>
      {preparedBar.subText && (
        <p
          className={classNames(
            'text-secondary-bg-primary',
            isCondensed ? 'body-s' : 'body-m',
            isLastItem && 'md:text-end',
          )}
        >
          {preparedBar.subText}
        </p>
      )}
    </div>
  );
};
