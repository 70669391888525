import { Button, Input } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type CurrenciesKey } from 'src/core/config/money';

import { EntityListFilter } from './EntityListFilter';
import {
  type EntityFilters,
  type EntityStatusFilterValue,
  type FundsStatusFilterValue,
} from './types';

type Props = {
  filters: EntityFilters;
  setFilters: (value: EntityFilters) => void;
  filtersConfig: {
    currencyOptions: { key: CurrenciesKey; label: string }[];
  };
  search: string;
  setSearch: (value: string) => void;
  isFilteringEnabled?: boolean;
};

export const EntityListFilters = ({
  filters,
  setFilters,
  filtersConfig: { currencyOptions },
  search,
  setSearch,
  isFilteringEnabled = false,
}: Props) => {
  const { t } = useTranslation('global');

  const filterOptions: {
    currencyFilters: { key: CurrenciesKey; label: string }[];
    entityStatusFilters: { key: EntityStatusFilterValue; label: string }[];
    fundsStatusFilters: { key: FundsStatusFilterValue; label: string }[];
  } = {
    currencyFilters: currencyOptions,
    entityStatusFilters: [
      {
        key: 'active',
        label: t(
          'organisation.reporting.page.entities.filters.entityStatus.values.active',
        ),
      },
      {
        key: 'inactive',
        label: t(
          'organisation.reporting.page.entities.filters.entityStatus.values.inactive',
        ),
      },
    ],
    fundsStatusFilters: [
      {
        key: 'shortfall',
        label: t(
          'organisation.reporting.page.entities.filters.fundsStatus.values.shortfall',
        ),
      },
      {
        key: 'available',
        label: t(
          'organisation.reporting.page.entities.filters.fundsStatus.values.available',
        ),
      },
    ],
  };

  const [localCurrencyFilters, setLocalCurrencyFilters] = useState<
    CurrenciesKey[]
  >(filters.currencyFilters);
  const [localEntityStatusFilters, setLocalEntityStatusFilters] = useState<
    EntityStatusFilterValue[]
  >(filters.entityStatusFilters);
  const [localFundsStatusFilters, setLocalFundsStatusFilters] = useState<
    FundsStatusFilterValue[]
  >(filters.fundsStatusFilters);

  useEffect(() => {
    setLocalCurrencyFilters(filters.currencyFilters);
    setLocalEntityStatusFilters(filters.entityStatusFilters);
    setLocalFundsStatusFilters(filters.fundsStatusFilters);
  }, [filters]);

  const hasActiveFilters = [
    localCurrencyFilters,
    localEntityStatusFilters,
    localFundsStatusFilters,
  ].some((f) => f.length > 0);

  const resetFilters = () => {
    if (hasActiveFilters) {
      setLocalCurrencyFilters([]);
      setLocalEntityStatusFilters([]);
      setLocalFundsStatusFilters([]);

      setFilters({
        currencyFilters: [],
        entityStatusFilters: [],
        fundsStatusFilters: [],
      });
    }
  };

  return (
    <div key="filters" className="flex flex-row flex-wrap items-center gap-8">
      <Input
        isDisabled={!isFilteringEnabled}
        className="w-[248px]"
        placeholder={t(
          'organisation.reporting.page.entities.searchPlaceholder',
        )}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      {filterOptions.currencyFilters.length > 1 && (
        <EntityListFilter
          label={t(
            'organisation.reporting.page.entities.filters.currency.label',
          )}
          activeFilters={localCurrencyFilters ?? []}
          setActiveFilters={(values) => {
            setLocalCurrencyFilters(values);
            setFilters({
              ...filters,
              currencyFilters: values,
            });
          }}
          filterOptions={filterOptions.currencyFilters}
          isDisabled={!isFilteringEnabled}
        />
      )}
      <EntityListFilter
        label={t(
          'organisation.reporting.page.entities.filters.entityStatus.label',
        )}
        activeFilters={localEntityStatusFilters}
        setActiveFilters={(values) => {
          setLocalEntityStatusFilters(values);
          setFilters({
            ...filters,
            entityStatusFilters: values,
          });
        }}
        filterOptions={filterOptions.entityStatusFilters}
        isDisabled={!isFilteringEnabled}
        isMonoValue
      />
      <EntityListFilter
        label={t(
          'organisation.reporting.page.entities.filters.fundsStatus.label',
        )}
        activeFilters={localFundsStatusFilters ?? []}
        setActiveFilters={(values) => {
          setLocalFundsStatusFilters(values);
          setFilters({
            ...filters,
            fundsStatusFilters: values,
          });
        }}
        filterOptions={filterOptions.fundsStatusFilters}
        isDisabled={!isFilteringEnabled}
        isMonoValue
      />
      {hasActiveFilters && (
        <Button
          variant="tertiaryNeutral"
          isDisabled={!isFilteringEnabled}
          className="body-m"
          text={t('organisation.reporting.page.entities.filters.clear')}
          onClick={resetFilters}
        />
      )}
    </div>
  );
};
