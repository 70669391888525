import { Button, Modal } from '@dev-spendesk/grapes';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routes, routeFor } from 'src/core/constants/routes';

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  onClose: () => void;
};

export const CostCenterActivationModal = ({
  onClose,
  isOpen,
  title,
  description,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  return (
    <Modal
      actions={[
        <Button
          text={t('purchaseOrders.activation.modal.cancel')}
          variant="secondaryNeutral"
          key="cancel"
          onClick={() => {
            onClose();
          }}
        />,
        <Button
          key="submit"
          text={t('purchaseOrders.activation.modal.submit')}
          variant="primaryBrand"
          onClick={() => {
            history.push(
              routeFor(routes.COST_CENTERS.path, {
                company: companyId,
              }),
            );
          }}
        />,
      ]}
      iconName="triangle-warning"
      iconVariant="warning"
      title={title}
      isOpen={isOpen}
    >
      {description}
    </Modal>
  );
};
