import { Modal, Button } from '@dev-spendesk/grapes';
import React from 'react';
import { useDispatch } from 'react-redux';

import { LocalStorageKey } from 'src/core/constants/storage';
import { getLocalItem } from 'src/core/utils/storage';

import { hideSwitchedSupervisedUserModal } from './redux/actions';

export const SwitchedSupervisedUserModal = () => {
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    window.location.reload();
  };

  const newSupervisedUserName = getLocalItem(
    LocalStorageKey.LastSupervisedUserName,
    true,
  );

  const handleClose = () => {
    dispatch(hideSwitchedSupervisedUserModal());
  };

  return (
    <Modal
      isOpen
      iconName="arrow-path"
      title={`You just switched to the supervision account of ${newSupervisedUserName}`}
      onClose={handleClose}
      subtitle="Please reload the page to update"
      actions={<Button text="Reload" onClick={handleButtonClick} />}
    />
  );
};
