import { Button, Callout, Icon, SkeletonText, Tag } from '@dev-spendesk/grapes';
import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { useTranslation } from 'common/hooks/useTranslation';
import { useGetKybStatusQuery } from 'modules/kyc/hooks/useGetKybStatusQuery';
import { type HubTask } from 'modules/onboarding/types/hubTask';

import { StartOrContinueButton } from '../StartOrContinueButton';

type Props = {
  task: HubTask;
};

export const DefaultKycTaskContent = ({ task }: Props) => {
  const { t } = useTranslation('global');

  const getKybStatusQuery = useGetKybStatusQuery({
    isEnabled: task.status === 'ongoing',
  });

  if (task.status === 'completed') {
    return null;
  }

  if (getKybStatusQuery.status === 'loading') {
    return <SkeletonText width="80%" className="mt-16" />;
  }

  if (getKybStatusQuery.status === 'error') {
    return (
      <QueryError
        componentType="ErrorState"
        queryError={getKybStatusQuery.error}
        translations={{
          networkError: 'misc.errors.networkError',
          serverError: 'misc.errors.serverError',
          requestError: () => 'misc.errors.networkError',
        }}
      />
    );
  }

  const { data: kybStatus } = getKybStatusQuery;

  if (kybStatus?.state.code === 'initiated') {
    return <StartOrContinueButton />;
  }

  if (kybStatus?.state.code === 'in-review') {
    return (
      <Tag className="mt-16" variant="neutral">
        <Icon size="m" name="clock-outline" className="mr-8" />
        {t('onboardingHub.kycTask.pendingStatus')}
      </Tag>
    );
  }

  if (kybStatus?.state.code === 'information-needed') {
    return (
      <>
        <Callout
          className="mt-16"
          title={t('onboardingHub.kycTask.missingDocuments')}
          variant="warning"
        />
        {kybStatus.state.redirectionCta &&
          kybStatus.state.redirectionLocation && (
            <Button
              className="mt-16"
              variant="primaryWarning"
              text={kybStatus.state.redirectionCta}
              href={kybStatus.state.redirectionLocation}
              target="_blank"
            />
          )}
      </>
    );
  }

  return null;
};
