/* eslint-disable unicorn/no-await-expression-member */

import { SidePanel } from '@dev-spendesk/grapes';

import { EmptyStateError, lazyLoad } from 'src/core/common/components/LazyLoad';

import { LoadingState } from './LoadingState';

export const LazyRequestsPanel = lazyLoad({
  loader: async () => ({
    default: (
      await import(
        /* webpackChunkName: "RequestsPanel", webpackPrefetch: true */ '../../../list/components/RequestPanelContainer'
      )
    ).RequestPanelContainer,
  }),
  loading: <LoadingState />,
  fallback: (props) => (
    <SidePanel>
      <EmptyStateError {...props} />
    </SidePanel>
  ),
});
