import { Button, DropdownItem, DropdownMenu } from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import './SettlementStateFilter.css';

type Props = {
  settlementState: string | undefined;
  setSettlementState(status: string | undefined): void;
};

export const SettlementStateFilter = ({
  settlementState,
  setSettlementState,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <DropdownMenu
      keepOpenOnSelect
      onSelect={(option) =>
        setSettlementState(
          settlementState !== option.key ? option.key : undefined,
        )
      }
      options={[
        {
          key: 'not_settled',
          label: t('payables.filters.settlementStateFilter.notSettled'),
        },
        {
          key: 'settled',
          label: t('payables.filters.settlementStateFilter.settled'),
        },
      ]}
      placement="bottom-start"
      renderButton={(getToggleButtonProps, isOpen) => (
        <Button
          {...getToggleButtonProps()}
          className={classNames('SettlementStateFilter__button', {
            'SettlementStateFilter__button--open': isOpen,
            'SettlementStateFilter__button--active': settlementState,
          })}
          text={t('payables.filters.settlementState')}
          variant="secondaryNeutral"
        />
      )}
      renderOption={(option) => (
        <DropdownItem
          label={option.label}
          isSelected={option.key === settlementState}
        />
      )}
    />
  );
};
