import { type Filter } from '@spendesk/bookkeeping-core-types';
import { gql } from 'graphql-tag';

export const query = ({ filtersV2 }: { filtersV2: Filter | undefined }) => gql`
  query PayablesCount(
    $companyId: ID!
    $payableStates: [PayableState!]
    $settlementState: SettlementState
    $documentaryEvidenceStatus: DocumentaryEvidenceStatus
    $search: String
    $costCenter: String
    $creationDate: PartialDateRange
    $payableTypes: [PayableType!]
    ${filtersV2 ? '$filtersV2: JSON' : ''}
    $textualSearch: String
  ) {
    company(id: $companyId) {
      payables(
        sort: desc
        filter: {
          state: $payableStates
          settlementState: $settlementState
          documentaryEvidenceStatus: $documentaryEvidenceStatus
          search: $search
          costCenter: $costCenter
          creationDate: $creationDate
          types: $payableTypes
        }
        ${filtersV2 ? 'filtersV2: $filtersV2' : ''}
        textualSearch: $textualSearch
      ) {
        totalCount
      }
    }
  }
`;

export type RawResult = {
  company: {
    payables: {
      totalCount: number;
    };
  };
};
