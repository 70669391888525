import { SkeletonText, SkeletonButton } from '@dev-spendesk/grapes';
import React from 'react';

const skeletonButtonCount = 4;

type Props = {
  className?: string;
};

export const SupplierBankInfoFormSkeleton = ({ className }: Props) => {
  return (
    <div className={className}>
      {Array.from({ length: skeletonButtonCount }, (_, key) => (
        <div className="mb-16" key={key}>
          <SkeletonText width="96px" size="l" />
          <SkeletonButton width="100%" />
        </div>
      ))}
    </div>
  );
};
