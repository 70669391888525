import { gql } from 'graphql-tag';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function makeGetPayablesBucketsStatsQuery({
  filters,
  filtersV2,
  withProofFilters,
  withProofFiltersV2,
  withoutProofFilters,
  withoutProofFiltersV2,
  missingProofFilters,
  missingProofFiltersV2,
}: {
  filters?: Record<string, unknown>;
  filtersV2?: Record<string, unknown>;
  withProofFilters?: Record<string, unknown>;
  withProofFiltersV2?: Record<string, unknown>;
  withoutProofFilters?: Record<string, unknown>;
  withoutProofFiltersV2?: Record<string, unknown>;
  missingProofFilters?: Record<string, unknown>;
  missingProofFiltersV2?: Record<string, unknown>;
}) {
  const variables = [
    filters !== undefined ? `$filters: PayableFilter` : '',
    filtersV2 !== undefined ? `$filtersV2: JSON` : '',
    withProofFiltersV2 !== undefined ? `$withProofFiltersV2: JSON` : '',
    withoutProofFiltersV2 !== undefined ? `$withoutProofFiltersV2: JSON` : '',
    missingProofFiltersV2 !== undefined ? `$missingProofFiltersV2: JSON` : '',
    withProofFilters !== undefined ? `$withProofFilters: PayableFilter` : '',
    withoutProofFilters !== undefined
      ? `$withoutProofFilters: PayableFilter`
      : '',
    missingProofFilters !== undefined
      ? `$missingProofFilters: PayableFilter`
      : '',
    `$textualSearch: String`,
  ];

  const applyFilters = (hasFilter: boolean, variableName: string) =>
    hasFilter ? `, filter: ${variableName}` : '';
  const applyFiltersV2 = (hasFilter: boolean, variableName: string) =>
    hasFilter ? `, filtersV2: ${variableName}` : '';

  const payablesFiltered =
    filters !== undefined || filtersV2 !== undefined
      ? `
          payablesFiltered: payables(first: 1, textualSearch: $textualSearch${applyFilters(filters !== undefined, '$filters')}${applyFiltersV2(filtersV2 !== undefined, '$filtersV2')}) {
            connectionId: id
            totalCount
            aggregate {
              sum {
                functionalAmount
              }
            }
          }
        `
      : '';

  const payablesWithProof =
    withProofFilters !== undefined || withProofFiltersV2 !== undefined
      ? `
          payablesWithProof: payables(first: 1, textualSearch: $textualSearch${applyFilters(filters !== undefined, '$withProofFilters')}${applyFiltersV2(filtersV2 !== undefined, '$withProofFiltersV2')}) {
            connectionId: id
            totalCount
            aggregate {
              sum {
                functionalAmount
              }
            }
          }
        `
      : '';

  const payablesWithoutProof =
    withoutProofFilters !== undefined || withoutProofFiltersV2 !== undefined
      ? `
          payablesWithoutProof: payables(first: 1, textualSearch: $textualSearch${applyFilters(filters !== undefined, '$withoutProofFilters')}${applyFiltersV2(filtersV2 !== undefined, '$withoutProofFiltersV2')}) {
            connectionId: id
            totalCount
            aggregate {
              sum {
                functionalAmount
              }
            }
          }
        `
      : '';

  const payablesMissingProof =
    missingProofFilters !== undefined || missingProofFiltersV2 !== undefined
      ? `
          payablesMissingProof: payables(first: 1, textualSearch: $textualSearch${applyFilters(filters !== undefined, '$missingProofFilters')}${applyFiltersV2(filtersV2 !== undefined, '$missingProofFiltersV2')}) {
            connectionId: id
            totalCount
            aggregate {
              sum {
                functionalAmount
              }
            }
          }
        `
      : '';

  return gql(`
    query GetPayablesBucketsStats(
      $companyId: ID!
      ${variables.filter(Boolean).join('\n')}
    ) {
      company(id: $companyId) {
        id
        currency
        payables {
          totalCount
        }

        ${payablesFiltered}
        ${payablesWithProof}
        ${payablesWithoutProof}
        ${payablesMissingProof}
      }
    }
  `);
}
