import React from 'react';

import withErrorBoundary from 'common/components/withErrorBoundary';

import { IntegrationsSectionSkeleton } from '../IntegrationsSection';
import { TellUsBoxSkeleton } from '../TellUsBox';

const IntegrationsListSkeleton = () => {
  const section1 = [{}, { error: 'notConnected' }];
  const section2 = [{}];
  const section3 = [{}];
  return (
    <div className="flex flex-col gap-24">
      <IntegrationsSectionSkeleton
        key="activeIntegrations"
        integrations={section1}
        activeIntegrations
      />
      <IntegrationsSectionSkeleton
        key="availableIntegrations"
        integrations={section2}
        activeIntegrations={false}
        extra={[<TellUsBoxSkeleton key="tellUs" />]}
      />
      <IntegrationsSectionSkeleton
        key="parterIntegrations"
        integrations={section3}
        activeIntegrations={false}
      />
    </div>
  );
};

export default withErrorBoundary({
  scope: 'integrations-list-skeleton',
  team: 'api-integration',
})(IntegrationsListSkeleton);
