import { ListBox } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';

import { CustomExportsListItem } from './CustomExportsListItem';
import { type TemplateItem } from '../../../template';

type Props = {
  customExports: TemplateItem[];
  deleteTemplate: (templateId: string) => Promise<void>;
  setEditingCustomExport: (template: TemplateItem) => void;
  setIsCreateExportModalActive: (isActive: boolean) => void;
};

export const CustomExportsList = ({
  customExports,
  deleteTemplate,
  setEditingCustomExport,
  setIsCreateExportModalActive,
}: Props) => {
  const { t } = useTranslation('global');
  const user = useUser();
  // Only show purchase templates
  customExports = customExports.filter(
    (template) => template.type === 'purchase',
  );

  const hasNoCustomTemplates =
    customExports.filter((template) => !template.isDefault).length === 0;

  return hasNoCustomTemplates ? (
    <div className="box mb-16 w-full rounded text-center">
      <video loop muted autoPlay title="marketing bg" className="h-auto w-full">
        <source
          src={`/static/img/gifs/custom-exports.${user.lang}.mp4`}
          type="video/mp4"
        />
      </video>
    </div>
  ) : (
    <ListBox
      header={t('exports.listHeader')}
      rowHeight="compact"
      options={customExports}
      getOptionId={(option) => option.id}
    >
      {(customExport) => (
        <CustomExportsListItem
          templateItem={customExport}
          deleteTemplate={deleteTemplate}
          setEditingCustomExport={setEditingCustomExport}
          setIsCreateExportModalActive={setIsCreateExportModalActive}
        />
      )}
    </ListBox>
  );
};
