/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DATE_FORMAT } from '@dev-spendesk/grapes';
import { equal, type MonetaryValue } from 'ezmoney';
import * as Money from 'ezmoney';
import { type FormikErrors, setIn } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { type ReactElement } from 'react';

// TODO: move to prepare-payables
import { type ExpenseAccount } from 'modules/bookkeep';
import { type SupplierAccount } from 'modules/bookkeep/accounts-payable/types';
import {
  isIntegrationStatusWithIntegration,
  type AmortisationCapability,
  type IntegrationStatus,
} from 'modules/bookkeep/integration/status';
import { isSpanishDPRPayable } from 'modules/bookkeep/payables/models/payable';
import { isSupplierCounterpartyExpense } from 'modules/bookkeep/prepare/models';
import { isMissingEmployeeAccount } from 'modules/bookkeep/prepare/utils/expense';
import { type MileageScheme, type Vehicle } from 'modules/bookkeep/types';
import { type TaxAccount } from 'modules/payable/components';
import { type AnalyticalFieldAssociationAutomation } from 'modules/payable/models';
import { displayVehicle } from 'modules/requests/mileage/shared/MileageRequestUKVehicleDetailsLine';
import { type QueryState } from 'src/core/api/queryState';
import {
  type LocaleFormat,
  type I18nKey,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';
import { diff } from 'src/core/utils/toolbelt';

import {
  type CustomFieldType,
  isSupplier,
  type Payable,
  type PayableUpdate,
  type CustomField,
  type AnalyticalFieldAssociation,
  type PayableType,
  type PayableSubType,
} from '../../../../models';
import { isValidDateString } from '../../utils/date';
import {
  getHasMixedSigns,
  getTotalGrossAmount,
  getTotalNetAmount,
  getTotalVatAmount,
} from '../../utils/payableLines';

type CustomFields = Pick<
  CustomField,
  'id' | 'type' | 'isOptional' | 'isSplittable' | 'values'
>[];
/**
 * Maps an payable object to form values.
 *
 * @param {Object} payable The payable object.
 * @returns {Object} The form values object.
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const mapPayableToValues = (payable: Payable): PayableUpdate => {
  // we fill-in invoiceNumber in the update request nonetheless
  // if it's a credit note or an invoice to keep working old datev workflow as
  // it is
  let maybeInvoiceNumber;

  if (payable.documentaryEvidence?.type === 'invoice') {
    maybeInvoiceNumber = payable.documentaryEvidence.invoiceNumber;
  }

  if (payable.documentaryEvidence?.type === 'creditNote') {
    maybeInvoiceNumber = payable.documentaryEvidence.creditNoteNumber;
  }

  const customFields =
    mergeCustomFieldsWithAutomationAnalyticalFieldAssociations(
      payable.customFields,
      payable.itemLines,
      payable.automation?.analyticalFieldAssociations || [],
    );

  return {
    description: payable.description,
    supplier: payable.supplier
      ? {
          id: payable.supplier.id ?? '',
          name: payable.supplier.name,
          thumbnailUrl: payable.supplier.thumbnailUrl ?? undefined,
          isArchived: payable.supplier.isArchived ?? false,
        }
      : undefined,
    team: payable.team?.id ?? null,
    costCenter: payable.costCenter?.id ?? null,
    itemLines: payable.itemLines.map(
      ({ grossAmount, vat, expense, analyticalFieldAssociations }, index) => {
        const id = index;
        const hasNoVat = !vat?.vatAccount?.id && !vat?.amount;

        return {
          id,
          grossAmount,
          expense: {
            accountId: expense.expenseAccount?.id ?? null,
            name: expense.expenseAccount?.name ?? null,
            amount: expense.amount,
          },
          vat: hasNoVat
            ? null
            : {
                itemLineId: id,
                accountId: vat?.vatAccount?.id ?? null,
                name: vat?.vatAccount?.name ?? null,
                amount: vat?.amount ?? null,
                rate: vat?.vatAccount?.rate ?? null,
              },
          analyticalFieldAssociations:
            mergeAnalyticalFieldsWithAutomationAnalyticalFieldAssociations(
              analyticalFieldAssociations,
              payable.automation?.analyticalFieldAssociations || [],
            ),
        };
      },
    ),
    customFields,
    invoiceNumber: payable.invoiceNumber ?? maybeInvoiceNumber,
    supplierAccount: payable.supplierAccount?.id ?? null,
    accountingDate: payable.accountingDate ?? payable.creationDate,
    creationDate: payable.creationDate,
    amortisation: payable.amortisation ?? null,
  };
};

const mergeAnalyticalFieldsWithAutomationAnalyticalFieldAssociations = (
  analyticalFieldAssociations: Payable['itemLines'][0]['analyticalFieldAssociations'],
  analyticalFieldAssociationAutomations: AnalyticalFieldAssociationAutomation[],
) => {
  const mergedAnalyticalFieldAssociations = new Map(
    analyticalFieldAssociations.map((association) => [
      association.fieldEntityId,
      association,
    ]),
  );

  for (const analyticalFieldFromAutomation of analyticalFieldAssociationAutomations) {
    if (
      !mergedAnalyticalFieldAssociations.has(
        analyticalFieldFromAutomation.fieldEntityId,
      )
    ) {
      mergedAnalyticalFieldAssociations.set(
        analyticalFieldFromAutomation.fieldEntityId,
        analyticalFieldFromAutomation,
      );
    }
  }

  return [...mergedAnalyticalFieldAssociations.values()];
};

export const mergeCustomFieldsWithAutomationAnalyticalFieldAssociations = (
  customFields: Payable['customFields'],
  itemLines: Payable['itemLines'],
  analyticalFieldAssociationAutomations: AnalyticalFieldAssociationAutomation[],
): Record<string, string | boolean> => {
  return Object.fromEntries(
    Array.from(Object.entries(customFields ?? {})).concat(
      [
        { analyticalFieldAssociations: analyticalFieldAssociationAutomations },
        ...itemLines,
      ].reduce<[string, string | boolean][]>(
        (accumulator, { analyticalFieldAssociations }) =>
          accumulator.concat(
            analyticalFieldAssociations
              .filter(({ fieldKind }) => fieldKind === 'customField')
              .map(({ fieldEntityId, fieldEntityValueId }) => [
                fieldEntityId,
                fieldEntityValueId,
              ]),
          ),
        [],
      ),
    ),
  );
};

export const hasAvailableValues = (
  values: {
    archiveDate?: string | null;
  }[],
): boolean => {
  return values.some((value) => !value.archiveDate);
};

export const computePayableUpdate = ({
  initialValues,
  currentValues,
  originalPayable,
  customFields,
  isAnalyticalSplitActivated,
  isAccountingDateEnabled,
}: {
  initialValues: PayableUpdate;
  currentValues: PayableUpdate;
  originalPayable: Payable;
  customFields: CustomFields;
  isAnalyticalSplitActivated: boolean;
  isAccountingDateEnabled: boolean;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): Partial<PayableUpdate> => {
  const update = diff(initialValues, currentValues);

  const customFieldValueIdByBooleanAndCustomFieldId =
    getCustomFieldValueIdByCustomFieldIdAndBoolean(customFields);

  const analyticalFieldAssociationAutomationWasAppliedOnPayable =
    originalPayable.automation?.analyticalFieldAssociations?.some(
      ({ isAppliedOnPayable }) => isAppliedOnPayable,
    );

  const areCurrentAndInitialSupplierTheSame =
    currentValues.supplier?.id === initialValues.supplier?.id;

  const isResolvedUnknownSupplierIdDifferentFromOriginalSupplierId =
    areCurrentAndInitialSupplierTheSame &&
    currentValues.supplier?.id !== originalPayable.supplier?.id &&
    originalPayable.supplier?.id === 'unknown';

  // explicitly make the resolved supplier part of the update
  if (isResolvedUnknownSupplierIdDifferentFromOriginalSupplierId) {
    update.supplier = currentValues.supplier;
  }

  if (
    (update.itemLines === undefined &&
      (originalPayable.automation?.tax?.isAppliedOnPayable ||
        originalPayable.automation?.expenseAccount?.isAppliedOnPayable)) ||
    analyticalFieldAssociationAutomationWasAppliedOnPayable
  ) {
    update.itemLines = currentValues.itemLines;
  }

  if (
    analyticalFieldAssociationAutomationWasAppliedOnPayable &&
    update.customFields === undefined
  ) {
    update.customFields = currentValues.customFields;
  }

  if (!isAnalyticalSplitActivated && update.customFields !== undefined) {
    update.itemLines = copyCustomFieldsToAnalyticalFieldAssociations(
      update,
      currentValues.itemLines,
      customFieldValueIdByBooleanAndCustomFieldId,
    );
  }

  if (isAnalyticalSplitActivated && update.customFields !== undefined) {
    update.itemLines = mergeNonSplittableCustomFieldsUpdatesIntoItemLines(
      update,
      currentValues.itemLines,
      customFieldValueIdByBooleanAndCustomFieldId,
      customFields,
    );

    // Do not update custom fields when analytical split is activated
    // The custom fields are already updated in the item lines and
    // the BE will throws an error when we try to update them
    delete update.customFields;
  }

  // Legacy: If we are updating cost centers, we need to ensure we pass
  // item lines so we can update the analytical fields later
  if (update.itemLines === undefined && update.costCenter !== undefined) {
    update.itemLines = initialValues.itemLines;
  }

  if (
    update.accountPayableId === undefined &&
    originalPayable.automation?.accountPayable?.isAppliedOnPayable
  ) {
    update.accountPayableId = originalPayable.accountPayableId;
  }

  if (
    update.accountingDate === undefined &&
    currentValues.accountingDate &&
    currentValues.accountingDate !== originalPayable.accountingDate
  ) {
    update.accountingDate = currentValues.accountingDate;
  }

  if (!isAccountingDateEnabled) {
    delete update.accountingDate;
  }

  // Triggers the deletion of the recommendation in the backend in order to hide the automation callout
  if (originalPayable.automation?.documentaryEvidenceDate) {
    update.creationDate = currentValues.creationDate;
  }

  if (
    update.invoiceNumber === undefined &&
    originalPayable.automation?.documentaryEvidenceNumber?.isAppliedOnPayable
  ) {
    switch (originalPayable.documentaryEvidence?.type) {
      case 'invoice': {
        update.invoiceNumber =
          originalPayable.documentaryEvidence.invoiceNumber;
        break;
      }
      case 'creditNote': {
        update.invoiceNumber =
          originalPayable.documentaryEvidence.creditNoteNumber;
        break;
      }
      default: {
        break;
      }
    }
  }

  return update;
};

/**
 * Get form errors for payable update values.
 *
 * @param {object} values The payable form values
 * @param {object} payable The original payable
 * @param customFields
 * @param companyCostCenters The values a company has defined that could be associated to a payable.
 * @param integrationStatusQuery
 * @param getSupplierAccountsQuery
 * @param expenseAccountQuery
 * @param taxAccountQuery
 * @param {object} options
 * @param {boolean} options.areExpenseAccountsRequired
 * @param {boolean} options.areVatAccountsRequired
 * @param {boolean} options.hasEmployeeAccountsFeature
 * @param {boolean} options.hasSupplierAccountsFeature
 * @param {boolean} options.mustValidateSupplierAccount
 * @returns {object} An object holding key/value pairs where key is the errored * field and value is the translation key for the error message.
 */
export const getMarkAsPreparedErrors = async (
  values: PayableUpdate,
  payable: Payable,
  hasInvoiceNumberFeature: boolean,
  customFields: {
    id: string;
    type: CustomFieldType;
    isOptional: boolean;
    isSplittable: boolean;
    values: {
      archiveDate?: string | null;
    }[];
  }[],
  companyCostCenters:
    | {
        values: { key: string; label: string }[];
        valuesTotalCount: number;
      }
    | undefined,
  integrationStatusQuery: QueryState<IntegrationStatus>,
  getSupplierAccountsQuery: QueryState<SupplierAccount[], unknown>,
  expenseAccountQuery: QueryState<ExpenseAccount[], unknown>,
  taxAccountQuery: QueryState<TaxAccount[], unknown>,
  childrenValidations: Record<
    'supplierSpanishDprComplianceValidation',
    (() => Promise<Record<string, string>>) | undefined
  >,
  options: {
    areExpenseAccountsRequired?: boolean;
    areVatAccountsRequired?: boolean;
    hasEmployeeAccountsFeature?: boolean;
    hasSupplierAccountsFeature?: boolean;
    mustValidateSupplierAccount?: boolean;
    hasCustomFieldsFeature?: boolean;
    hasCostCentersFeature?: boolean;
    isAnalyticalSplitActivated?: boolean;
    hasAmortisationFeature: boolean;
  },
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  let errors: FormikErrors<PayableUpdate> & {
    grossAmount?: string | null;
    employeeAccount?: string | null;
  } = {};

  if (
    !['mileage_allowance', 'per_diem_allowance'].includes(payable.type) &&
    (!values.supplier?.name?.trim() || isEmpty(values.supplier))
  ) {
    errors.supplier = 'expenseInbox.expenseEditor.fieldIsRequired';
  }

  if (
    checkIfSupplierAccountInvalid(
      integrationStatusQuery,
      values.supplierAccount,
    )
  ) {
    errors = setIn(errors, `supplierAccount`, 'invalidAccountFormat');
  }

  /**
   * FIXME: better validate itemLines:
   * - loop through them as less as possible
   * - improve precision to find which exact field is erroneous
   * - skip dependent validations when on fails
   */
  const uniqueItemLinesAssociations = new Set<string>();
  // eslint-disable-next-line sonarjs/cognitive-complexity
  values.itemLines?.forEach((itemLine, index) => {
    if (!itemLine.expense?.accountId) {
      errors = setIn(
        errors,
        `itemLines[${index}].expense.accountId`,
        'expenseInbox.expenseEditor.pleaseSelectExpenseAccount',
      );
    }

    if (
      !itemLine.expense?.amount ||
      (itemLine.expense?.amount &&
        itemLine.vat?.amount &&
        Money.toNumber(itemLine.expense.amount) === 0 &&
        Money.toNumber(itemLine.vat.amount) !== 0)
    ) {
      errors = setIn(
        errors,
        `itemLines[${index}].expense.amount`,
        'expenseInbox.expenseEditor.pleaseEnterExpenseAccountAmount',
      );
    }

    if (
      checkIfAccountIsDeleted(expenseAccountQuery, itemLine.expense?.accountId)
    ) {
      if (
        expenseAccountQuery.status === 'success' &&
        !expenseAccountQuery.data
          .filter((expenseAccount) => !expenseAccount.isArchived)
          .some(
            (existingExpenseAccounts) =>
              existingExpenseAccounts.id === itemLine.expense?.accountId,
          )
      ) {
        errors = setIn(
          errors,
          `itemLines[${index}].expense.accountId`,
          'expenseInbox.expenseEditor.deletedExpenseAccountError',
        );
      }

      if (
        checkIfExpenseAccountIsInvalid(
          integrationStatusQuery,
          itemLine.expense?.accountId,
        )
      ) {
        errors = setIn(
          errors,
          `itemLines[${index}].expense.accountId`,
          'invalidAccountFormat',
        );
      }

      if (
        checkIfTaxAccountIsInvalid(
          integrationStatusQuery,
          itemLine.vat?.accountId,
        )
      ) {
        errors = setIn(
          errors,
          `itemLines[${index}].vat.accountId`,
          'invalidAccountFormat',
        );
      }

      if (
        options.areVatAccountsRequired &&
        itemLine.vat?.accountId &&
        checkIfAccountIsDeleted(taxAccountQuery, itemLine.vat?.accountId)
      ) {
        errors = setIn(
          errors,
          `itemLines[${index}].vat.accountId`,
          'expenseInbox.expenseEditor.deletedTaxAccountError',
        );
      }
    }

    if (!itemLine.vat?.accountId && options.areVatAccountsRequired) {
      errors = setIn(
        errors,
        `itemLines[${index}].vat.accountId`,
        'expenseInbox.expenseEditor.pleaseSelectVatAccount',
      );
    }

    if (!itemLine.vat?.amount && options.areVatAccountsRequired) {
      errors = setIn(
        errors,
        `itemLines[${index}].vat.amount`,
        'expenseInbox.expenseEditor.pleaseEnterVatAmount',
      );
    }

    if (itemLine.vat?.amount?.amount && itemLine.vat.rate === 0) {
      errors = setIn(
        errors,
        `itemLines[${index}].vat.amount`,
        'expenseInbox.expenseEditor.pleaseRemoveVatAmount',
      );
    }

    if (
      options.areVatAccountsRequired &&
      itemLine.vat?.accountId &&
      checkIfAccountIsDeleted(taxAccountQuery, itemLine.vat?.accountId)
    ) {
      errors = setIn(
        errors,
        `itemLines[${index}].vat.accountId`,
        'expenseInbox.expenseEditor.deletedTaxAccountError',
      );
    }

    if (options.isAnalyticalSplitActivated) {
      const costCenterAssociation = itemLine.analyticalFieldAssociations.find(
        ({ fieldKind }) => fieldKind === 'costCenter',
      );
      if (
        (companyCostCenters?.valuesTotalCount ?? 0) > 0 &&
        options.hasCostCentersFeature &&
        !costCenterAssociation
      ) {
        errors = setIn(
          errors,
          `itemLines[${index}].analyticalFields.costCenter`,
          'expenseInbox.expenseEditor.fieldIsRequired',
        );
      }

      if (options.hasCustomFieldsFeature) {
        customFields.forEach((customField) => {
          if (
            customField.isSplittable &&
            customField.type === 'List' &&
            !customField.isOptional &&
            hasAvailableValues(customField.values) &&
            !itemLine.analyticalFieldAssociations.find(
              ({ fieldEntityId }) => fieldEntityId === customField.id,
            )?.fieldEntityValueId
          ) {
            errors = setIn(
              errors,
              `itemLines[${index}].analyticalFields[${customField.id}]`,
              'expenseInbox.expenseEditor.fieldIsRequired',
            );
          }
        });
      }
    }

    const itemLineAssociationKey = `${itemLine.expense?.accountId}-${
      itemLine.vat?.accountId
    }-${itemLine.analyticalFieldAssociations
      .sort((a, b) => a.fieldEntityId.localeCompare(b.fieldEntityId))
      .map(({ fieldEntityValueId }) => fieldEntityValueId)
      .join('-')}`;

    if (uniqueItemLinesAssociations.has(itemLineAssociationKey)) {
      errors = setIn(
        errors,
        `itemLines[${index}].uniqueAssociation`,
        options.isAnalyticalSplitActivated
          ? 'expenseInbox.expenseEditor.nonUniqueItemLinesWithAnalytics'
          : 'expenseInbox.expenseEditor.nonUniqueItemLines',
      );
    }
    uniqueItemLinesAssociations.add(itemLineAssociationKey);
  });

  const totalGrossAmount: MonetaryValue | null = values.itemLines
    ? getTotalGrossAmount(
        getTotalNetAmount(values.itemLines, payable.originalAmount.currency),
        getTotalVatAmount(values.itemLines, payable.originalAmount.currency),
      )
    : null;

  const hasMixedSigns = getHasMixedSigns(values.itemLines);

  if (
    (totalGrossAmount && !equal(totalGrossAmount, payable.originalAmount)) ||
    hasMixedSigns
  ) {
    errors.grossAmount =
      values.itemLines?.length === 1
        ? 'expenseInbox.expenseEditor.vatAmountIncorrect'
        : 'expenseInbox.expenseEditor.pleaseCheckTotalsMatch';
  }

  if (options.hasEmployeeAccountsFeature && isMissingEmployeeAccount(payable)) {
    errors.employeeAccount = 'expenseInbox.expenseEditor.fieldIsRequired';
  }

  if (
    options.hasSupplierAccountsFeature &&
    isSupplierCounterpartyExpense(payable) &&
    values.supplierAccount === null
  ) {
    errors.supplierAccount =
      'expenseInbox.expenseEditor.missingDedicatedSupplierAccountWarning';
  }

  // Check if the company has cost centers already defined that could be assigned to a payable.
  // If there are none, even if the company has cost centers enabled, we do not want to block saving.
  const hasDefinedCostCenters = companyCostCenters
    ? companyCostCenters.valuesTotalCount > 0
    : false;

  if (
    options.hasCostCentersFeature &&
    hasDefinedCostCenters &&
    !options.isAnalyticalSplitActivated &&
    !values.costCenter
  ) {
    errors = setIn(
      errors,
      `costCenter`,
      'expenseInbox.expenseEditor.fieldIsRequired',
    );
  }

  if (options.hasCustomFieldsFeature) {
    customFields.forEach((customField) => {
      const hasValues =
        customField.type === 'List'
          ? hasAvailableValues(customField.values)
          : true;

      const isSplittable = options.isAnalyticalSplitActivated
        ? customField.isSplittable
        : false;

      const hasNullOrUndefinedValues =
        values.customFields &&
        (values.customFields[customField.id] === null ||
          values.customFields[customField.id] === undefined);

      if (
        !isSplittable &&
        !customField.isOptional &&
        hasValues &&
        hasNullOrUndefinedValues
      ) {
        errors = setIn(
          errors,
          `customFields[${customField.id}]`,
          'expenseInbox.expenseEditor.fieldIsRequired',
        );
      }
    });
  }

  if (
    checkIfSupplierAccountIsDeleted(
      getSupplierAccountsQuery,
      payable.supplierAccount,
      options.hasSupplierAccountsFeature,
      values.supplierAccount,
    )
  ) {
    errors.supplierAccount =
      'expenseInbox.expenseEditor.deletedAccountPayableError';
  }

  const hasSpanishDPR = isSpanishDPRPayable(payable);

  if (
    checkIfInvoiceNumberIsInvalid(
      hasInvoiceNumberFeature,
      hasSpanishDPR,
      values.invoiceNumber,
    )
  ) {
    errors.invoiceNumber = getInvoiceNumberErrorKey(
      payable.type,
      payable.subType,
    );
  }

  if (checkIfAccountingDateIsInvalid(hasSpanishDPR, values.accountingDate)) {
    errors.accountingDate =
      'expenseInbox.expenseEditor.accountingDateCantBeEmpty';
  }

  // Triggers the children validations only for Spanish DPR payables for now
  if (hasSpanishDPR) {
    errors = {
      ...errors,
      ...(await getChildrenValidationErrors(childrenValidations)),
    };
  }

  let amortisationCapability: AmortisationCapability | undefined;

  if (
    options.hasAmortisationFeature &&
    integrationStatusQuery.status === 'success' &&
    isIntegrationStatusWithIntegration(integrationStatusQuery.data)
  ) {
    amortisationCapability =
      integrationStatusQuery.data.capabilities.amortisation;
  }

  if (amortisationCapability && values.amortisation) {
    if (!values.amortisation?.date) {
      errors.amortisation = 'payables.panel.missingAmortisationDate';
    } else if (!values.amortisation?.date?.from) {
      errors.amortisation = 'payables.panel.missingAmortisationStartDate';
    } else if (!values.amortisation?.date?.to) {
      errors.amortisation = 'payables.panel.missingAmortisationEndDate';
    }
  }

  return errors;
};

export const getSaveErrors = (
  values: PayableUpdate,
  companyCostCenters:
    | {
        values: { key: string; label: string }[];
        valuesTotalCount: number;
      }
    | undefined,
  payable: Payable,
  hasInvoiceNumberFeature: boolean,
  integrationStatusQuery: QueryState<IntegrationStatus>,
  expenseAccountQuery: QueryState<ExpenseAccount[], unknown>,
  taxAccountQuery: QueryState<TaxAccount[], unknown>,
  options: {
    areVatAccountsRequired?: boolean;
    hasCostCentersFeature?: boolean;
    hasAmortisationFeature?: boolean;
  },
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  let errors: FormikErrors<PayableUpdate> & { grossAmount?: string | null } =
    {};

  // eslint-disable-next-line sonarjs/cognitive-complexity
  values.itemLines?.forEach((itemLine, index) => {
    if (itemLine.vat?.amount?.amount && itemLine.vat.rate === 0) {
      errors = setIn(
        errors,
        `itemLines[${index}].vat.amount`,
        'expenseInbox.expenseEditor.pleaseRemoveVatAmount',
      );
    }

    if (
      itemLine.expense?.amount &&
      itemLine.vat?.amount &&
      Money.toNumber(itemLine.expense.amount) === 0 &&
      Money.toNumber(itemLine.vat.amount) !== 0
    ) {
      errors = setIn(
        errors,
        `itemLines[${index}].expense.amount`,
        'expenseInbox.expenseEditor.pleaseEnterExpenseAccountAmount',
      );
    }

    if (
      checkIfAccountIsDeleted(expenseAccountQuery, itemLine.expense?.accountId)
    ) {
      if (
        expenseAccountQuery.status === 'success' &&
        !expenseAccountQuery.data
          .filter((expenseAccount) => !expenseAccount.isArchived)
          .some(
            (existingExpenseAccounts) =>
              existingExpenseAccounts.id === itemLine.expense?.accountId,
          )
      ) {
        errors = setIn(
          errors,
          `itemLines[${index}].expense.accountId`,
          'expenseInbox.expenseEditor.deletedExpenseAccountError',
        );
      }

      if (
        checkIfExpenseAccountIsInvalid(
          integrationStatusQuery,
          itemLine.expense?.accountId,
        )
      ) {
        errors = setIn(
          errors,
          `itemLines[${index}].expense.accountId`,
          'invalidAccountFormat',
        );
      }

      if (
        checkIfTaxAccountIsInvalid(
          integrationStatusQuery,
          itemLine.vat?.accountId,
        )
      ) {
        errors = setIn(
          errors,
          `itemLines[${index}].vat.accountId`,
          'invalidAccountFormat',
        );
      }

      if (
        options.areVatAccountsRequired &&
        itemLine.vat?.accountId &&
        checkIfAccountIsDeleted(taxAccountQuery, itemLine.vat?.accountId)
      ) {
        errors = setIn(
          errors,
          `itemLines[${index}].vat.accountId`,
          'expenseInbox.expenseEditor.deletedTaxAccountError',
        );
      }

      if (
        checkIfTaxAccountIsInvalid(
          integrationStatusQuery,
          itemLine.vat?.accountId,
        )
      ) {
        errors = setIn(
          errors,
          `itemLines[${index}].vat.accountId`,
          'invalidAccountFormat',
        );
      }

      const costCenterAssociation = itemLine.analyticalFieldAssociations.find(
        ({ fieldKind }) => fieldKind === 'costCenter',
      );

      // Check if the company has cost centers already defined that could be assigned to a payable.
      // If there are none, even if the company has cost centers enabled, we do not want to block saving.
      const hasDefinedCostCenters = companyCostCenters
        ? companyCostCenters.valuesTotalCount > 0
        : false;

      if (
        options.hasCostCentersFeature &&
        hasDefinedCostCenters &&
        !costCenterAssociation
      ) {
        errors = setIn(
          errors,
          `itemLines[${index}].analyticalFields.costCenter`,
          'expenseInbox.expenseEditor.missingCostCenter',
        );
      }
    }
  });

  /**
   * FIXME: better validate itemLines:
   * - loop through them as less as possible
   * - improve precision to find which exact field is erroneous
   * - skip dependent validations when on fails
   */

  const totalGrossAmount: MonetaryValue | null = values.itemLines
    ? getTotalGrossAmount(
        getTotalNetAmount(values.itemLines, payable.originalAmount?.currency),
        getTotalVatAmount(values.itemLines, payable.originalAmount?.currency),
      )
    : null;

  const hasMixedSigns = getHasMixedSigns(values.itemLines);

  if (
    (payable.originalAmount &&
      totalGrossAmount &&
      !equal(totalGrossAmount, payable.originalAmount)) ||
    hasMixedSigns
  ) {
    errors.grossAmount =
      values.itemLines?.length === 1
        ? 'expenseInbox.expenseEditor.vatAmountIncorrect'
        : 'expenseInbox.expenseEditor.pleaseCheckTotalsMatch';
  }

  if (hasInvoiceNumberFeature && !values.invoiceNumber) {
    errors.invoiceNumber =
      'expenseInbox.expenseEditor.invoiceNumberCantBeEmpty';
  }

  let amortisationCapability: AmortisationCapability | undefined;

  if (
    options.hasAmortisationFeature &&
    integrationStatusQuery.status === 'success' &&
    isIntegrationStatusWithIntegration(integrationStatusQuery.data)
  ) {
    amortisationCapability =
      integrationStatusQuery.data.capabilities.amortisation;
  }

  if (amortisationCapability && values.amortisation) {
    if (!values.amortisation?.date) {
      errors.amortisation = 'payables.panel.missingAmortisationDate';
    } else if (!values.amortisation?.date?.from) {
      errors.amortisation = 'payables.panel.missingAmortisationStartDate';
    } else if (!values.amortisation?.date?.to) {
      errors.amortisation = 'payables.panel.missingAmortisationEndDate';
    }
  }

  return errors;
};

export const getMileageItems = ({
  vehicle,
  scheme,
  travelDate,
  passengers,
  t,
  localeFormat,
  totalDistanceOverYear,
}: {
  vehicle: Omit<Vehicle, 'totalDistanceOverYear'>;
  scheme: MileageScheme;
  travelDate?: string | Date;
  passengers?: number;
  t: TGlobalFunctionTyped;
  localeFormat: LocaleFormat;
  totalDistanceOverYear: string;
}): { label: string; value: string | ReactElement }[] => {
  let items: { label: string; value: string | ReactElement }[] = [];
  if (vehicle.ownership || vehicle.engineSize || vehicle.fuelType) {
    items = [
      {
        label: t('expenseInbox.mileage.vehicleType'),
        value: displayVehicle({
          vehicle,
          t,
        }),
      },
    ];
    if (passengers && passengers > 0) {
      items.push({
        label: t('expenseInbox.mileage.passengers'),
        value: passengers.toString(),
      });
    }
  } else {
    items = [
      {
        label: t('expenseInbox.mileage.vehicleType'),
        value: getReadableVehicleType(vehicle, t),
      },
      {
        label: t('expenseInbox.mileage.taxHorsepower'),
        value: vehicle.taxHorsepower
          ? `${vehicle.taxHorsepower} ${t('payables.panel.mileage.horsepower')}`
          : ' - ',
      },
    ];
  }
  return items.concat([
    {
      label: t('expenseInbox.mileage.yearTotalDistance'),
      value: totalDistanceOverYear,
    },
    {
      label: t('expenseInbox.mileage.journeyDate'),
      value: travelDate
        ? localeFormat(new Date(travelDate), DATE_FORMAT.MEDIUM)
        : localeFormat(new Date(), DATE_FORMAT.MEDIUM),
    },
    {
      label: t('payables.panel.mileage.rate'),
      value: (
        <div className="flex items-center justify-end gap-4">
          {getReadableSchemeType(scheme, t)}
        </div>
      ),
    },
  ]);
};

const getReadableVehicleType = (
  rawVehicle: Omit<Vehicle, 'totalDistanceOverYear'>,
  translator: TGlobalFunctionTyped,
): string => {
  switch (rawVehicle.type) {
    case 'car':
      return translator('payables.panel.mileage.car', {
        context: rawVehicle.electric ? 'electric' : undefined,
      });
    case 'motorcycle':
      return translator('payables.panel.mileage.motorcycle', {
        context: rawVehicle.electric ? 'electric' : undefined,
      });
    case 'bike':
      return translator('payables.panel.mileage.bike');
    default:
      return '-';
  }
};

const getReadableSchemeType = (
  scheme: MileageScheme,
  translator: TGlobalFunctionTyped,
): string => {
  switch (scheme.type) {
    case 'french':
      return translator('forms.mileageAllowance.frenchRate');
    case 'german':
      return translator('forms.mileageAllowance.germanRate');
    case 'uk':
      return translator('forms.mileageAllowance.ukRate');
    default:
      return scheme.rate
        ? translator('forms.mileageAllowance.customRate', { rate: scheme.rate })
        : translator('forms.mileageAllowance.unknownCustomRate');
  }
};

export const checkIfSupplierAccountInvalid = (
  integrationStatusQuery: QueryState<IntegrationStatus, unknown>,
  supplierAccount?: string | null,
): boolean =>
  integrationStatusQuery.status === 'success' &&
  integrationStatusQuery.data.integration !== 'noIntegration' &&
  integrationStatusQuery.data.integration !== 'switchInProgress' &&
  integrationStatusQuery.data.settingsValidation.supplierAccounts.some(
    (invalidSupplierAccount) =>
      invalidSupplierAccount.error === 'invalidAccount' &&
      invalidSupplierAccount.id === supplierAccount,
  );

export const checkIfAccountIsDeleted = (
  accountQuery: QueryState<{ isArchived: boolean; id: string }[], unknown>,
  accountId?: string | null,
): boolean =>
  accountQuery.status === 'success' &&
  !accountQuery.data
    .filter((account) => !account.isArchived)
    .some((existingAccount) => existingAccount.id === accountId);

export const checkIfSupplierAccountIsDeleted = (
  supplierAccountsQuery: QueryState<SupplierAccount[], unknown>,
  payableSupplierAccount?: {
    id?: string;
    generalAccountCode?: string | null;
  } | null,
  hasSupplierAccountsFeature?: boolean,
  currentSupplierAccount?: string | null,
): boolean =>
  !!(
    hasSupplierAccountsFeature &&
    payableSupplierAccount &&
    checkIfAccountIsDeleted(supplierAccountsQuery, currentSupplierAccount)
  );

export const checkIfInvoiceNumberIsInvalid = (
  hasInvoiceNumberFeature: boolean,
  hasSpanishDPR: boolean,
  invoiceNumber: string | undefined,
): boolean => (hasInvoiceNumberFeature || hasSpanishDPR) && !invoiceNumber;

export const checkIfAccountingDateIsInvalid = (
  hasSpanishDPR: boolean,
  accountingDate: string | undefined,
): boolean =>
  (accountingDate && !isValidDateString(accountingDate)) ||
  (hasSpanishDPR && !accountingDate);

export const checkIfAccountPayableIsInvalid = (
  integrationStatusQuery: QueryState<IntegrationStatus, unknown>,
  payable: Payable,
  currentSupplierAccount?: string,
): boolean => {
  return (
    integrationStatusQuery.status === 'success' &&
    integrationStatusQuery.data.integration !== 'noIntegration' &&
    integrationStatusQuery.data.integration !== 'switchInProgress' &&
    (isSupplier(payable.counterparty)
      ? integrationStatusQuery.data.settingsValidation.supplierAccounts.some(
          (supplierAccount) =>
            supplierAccount.error === 'invalidAccount' &&
            supplierAccount.id === currentSupplierAccount,
        )
      : integrationStatusQuery.data.settingsValidation.employeeAccounts.some(
          (employeeAccount) =>
            employeeAccount.error === 'invalidAccount' &&
            employeeAccount.id === payable.employeeAccount?.id,
        ))
  );
};

export const checkIfExpenseAccountIsInvalid = (
  integrationStatusQuery: QueryState<IntegrationStatus, unknown>,
  accountId?: string | null,
): boolean =>
  integrationStatusQuery.status === 'success' &&
  integrationStatusQuery.data.integration !== 'noIntegration' &&
  integrationStatusQuery.data.integration !== 'switchInProgress' &&
  integrationStatusQuery.data.settingsValidation.expenseAccounts.some(
    (invalidAccount) =>
      invalidAccount.error === 'invalidAccount' &&
      invalidAccount.id === accountId,
  );

export const checkIfTaxAccountIsInvalid = (
  integrationStatusQuery: QueryState<IntegrationStatus, unknown>,
  accountId?: string | null,
): boolean =>
  integrationStatusQuery.status === 'success' &&
  integrationStatusQuery.data.integration !== 'noIntegration' &&
  integrationStatusQuery.data.integration !== 'switchInProgress' &&
  integrationStatusQuery.data.settingsValidation.taxAccounts.some(
    (invalidAccount) => invalidAccount.id === accountId,
  );

function getCustomFieldValueId({
  rawCustomFieldValue,
  customFieldId,
  customFieldValueIdByBooleanAndCustomFieldId,
}: {
  rawCustomFieldValue?: string | boolean | null;
  customFieldId: string;
  customFieldValueIdByBooleanAndCustomFieldId: Map<
    string,
    Map<boolean, string>
  >;
}): string | undefined {
  if (rawCustomFieldValue === null || rawCustomFieldValue === undefined) {
    return;
  }
  return typeof rawCustomFieldValue === 'string'
    ? rawCustomFieldValue
    : customFieldValueIdByBooleanAndCustomFieldId
        .get(customFieldId)
        ?.get(rawCustomFieldValue);
}

function copyCustomFieldsToAnalyticalFieldAssociations(
  update: Partial<PayableUpdate>,
  currentItemLines: PayableUpdate['itemLines'],
  customFieldValueIdByBooleanAndCustomFieldId: Map<
    string,
    Map<boolean, string>
  >,
): PayableUpdate['itemLines'] {
  const customFields = update.customFields;

  if (!customFields || !currentItemLines?.length) {
    return update.itemLines;
  }

  const startingAnalyticalFieldAssociations =
    currentItemLines[0].analyticalFieldAssociations.filter(
      ({ fieldKind }) => fieldKind !== 'customField',
    );

  const analyticalFieldAssociations: AnalyticalFieldAssociation[] = Object.keys(
    customFields,
  ).reduce(
    (accumulator: AnalyticalFieldAssociation[], customFieldId: string) => {
      const fieldEntityValueId = getCustomFieldValueId({
        rawCustomFieldValue: customFields[customFieldId],
        customFieldId,
        customFieldValueIdByBooleanAndCustomFieldId,
      });

      if (!fieldEntityValueId) {
        return accumulator;
      }

      return [
        ...accumulator,
        {
          fieldKind: 'customField' as const,
          fieldEntityId: customFieldId,
          fieldEntityValueId,
        },
      ];
    },
    startingAnalyticalFieldAssociations,
  );

  return currentItemLines.map((itemLine) => ({
    ...itemLine,
    analyticalFieldAssociations,
  }));
}

function mergeNonSplittableCustomFieldsUpdatesIntoItemLines(
  update: Partial<PayableUpdate>,
  currentItemLines: PayableUpdate['itemLines'],
  customFieldValueIdByBooleanAndCustomFieldId: Map<
    string,
    Map<boolean, string>
  >,
  customFields: CustomFields,
): PayableUpdate['itemLines'] {
  const newItemLines = update.itemLines?.slice() ?? currentItemLines;

  if (!newItemLines?.length) {
    return [];
  }

  return customFields.reduce((accumulator, customField) => {
    if (customField.isSplittable) {
      return accumulator;
    }

    const customFieldValueId = getCustomFieldValueId({
      rawCustomFieldValue: update.customFields?.[customField.id],
      customFieldId: customField.id,
      customFieldValueIdByBooleanAndCustomFieldId,
    });

    if (!customFieldValueId) {
      // handles case where value has been removed from optional non-splittable custom field
      return accumulator.map((itemLine) => ({
        ...itemLine,
        analyticalFieldAssociations:
          itemLine.analyticalFieldAssociations.filter(
            (afa) => afa.fieldEntityId !== customField.id,
          ),
      }));
    }

    return accumulator.map((itemLine) => ({
      ...itemLine,
      analyticalFieldAssociations: [
        ...itemLine.analyticalFieldAssociations.filter(
          (afa) => afa.fieldEntityId !== customField.id,
        ),
        {
          fieldKind: 'customField' as const,
          fieldEntityId: customField.id,
          fieldEntityValueId: customFieldValueId,
        },
      ],
    }));
  }, newItemLines);
}

function getCustomFieldValueIdByCustomFieldIdAndBoolean(
  customFields: {
    id: string;
    type: CustomFieldType;
    isOptional: boolean;
    isSplittable: boolean;
    values: {
      id: string;
      name: string | boolean;
      archiveDate?: string | null;
    }[];
  }[],
) {
  const customFieldValueIdByBooleanAndCustomFieldId = new Map<
    string,
    Map<boolean, string>
  >();

  /*
    Example customField payload:
      {
        "id": "i404s25w_l-90u",
        "name": "Boolean",
        "type": "Boolean",
        "isSplittable": true,
        "archiveDate": null,
        "values": [
          { "id": "k4miko375hd47t", "name": false },
          { "id": "f6926se3qkkrj_", "name": true }
        ],
        "valuesTotalCount": 0
      }
  */
  for (const customField of customFields) {
    if (customField.type === 'Boolean') {
      customFieldValueIdByBooleanAndCustomFieldId.set(
        customField.id,
        new Map([
          [true, customField.values.find(({ name }) => name === true)!.id],
          [false, customField.values.find(({ name }) => name === false)!.id],
        ]),
      );
    }
  }
  return customFieldValueIdByBooleanAndCustomFieldId;
}
function getInvoiceNumberErrorKey(
  type: PayableType,
  subtype: PayableSubType | undefined,
): I18nKey {
  if (subtype === 'creditNote') {
    return 'expenseInbox.expenseEditor.creditNoteNumberCantBeEmpty';
  }

  switch (type) {
    case 'expense_claim':
    case 'card_purchase':
    case 'reversal':
      return 'expenseInbox.expenseEditor.receiptNumberCantBeEmpty';
    default:
      return 'expenseInbox.expenseEditor.invoiceNumberCantBeEmpty';
  }
}

export async function getChildrenValidationErrors(
  childrenValidations: Record<
    'supplierSpanishDprComplianceValidation',
    (() => Promise<Record<string, string>>) | undefined
  >,
) {
  let errors = {};

  for (const validation of Object.values(childrenValidations)) {
    const validationResult = await validation?.();

    errors = {
      ...errors,
      ...validationResult,
    };
  }

  return errors;
}
