import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import {
  getDefaultTaxAccountName,
  useIntegrationStatusQuery,
} from 'modules/bookkeep';
import { isIntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { useGetDefaultTaxAccountQuery } from 'modules/bookkeep/settings/integrations/hooks/useGetDefaultTaxAccountQuery';
import { useSetDefaultTaxAccountMutation } from 'modules/bookkeep/settings/integrations/hooks/useSetDefaultTaxAccountMutation';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { routeFor } from 'src/core/constants/routes';
import { useMarkHubTaskAsDoneMutation } from 'src/core/modules/onboarding/components/MarkAsDoneButton/hooks/useMarkHubTaskAsDoneMutation';

import { DefaultVatAccountForm } from './components/DefaultVatAccountForm';
import { VatAccountsTableAndModals } from './components/VatAccountsTableAndModals';
import { validate, type FormValues } from './form';
import { useHubTaskByType } from '../../../hooks/useHubTaskByType';
import { routes } from '../../routes';

export const AccountingVatAccounts = () => {
  const history = useHistory();

  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const companyId = useCompanyId();

  const integrationStatusQueryState = useIntegrationStatusQuery();
  const integrationStatus = unwrapQuery(integrationStatusQueryState);

  // If there is no default VAT account, defaultTaxAccount is undefined
  // If there is an archived default VAT account, defaultTaxAccount is also undefined
  const defaultTaxAccountQueryState = useGetDefaultTaxAccountQuery();
  const defaultTaxAccount =
    defaultTaxAccountQueryState.status === 'success' &&
    !defaultTaxAccountQueryState.data?.isArchived
      ? defaultTaxAccountQueryState.data
      : undefined;

  const [setDefaultTaxAccount] = useSetDefaultTaxAccountMutation();

  const canAccessReverseCharges =
    integrationStatusQueryState.status === 'success' &&
    isIntegrationStatusWithIntegration(integrationStatusQueryState.data) &&
    [
      'Datev',
      'SpendeskAccounting',
      'Sage',
      'Cegid',
      'SpendeskAccountingSingleEntry',
    ].includes(integrationStatusQueryState.data.integration);

  const task = useHubTaskByType('vat_codes');
  const [markTaskAsDone] = useMarkHubTaskAsDoneMutation();
  const completeTaskIfRelevant = async () => {
    if (!task) {
      return;
    }
    // If users' integration supports reverse charges, we do not mark the task as done
    // because it will be handled on the next page
    if (canAccessReverseCharges) {
      return;
    }
    // Users do not have to add a VAT account code nor set a default VAT account
    // so we are manually marking the task as done, as an extra safety net
    try {
      await markTaskAsDone({ endpointParams: { taskId: task.id } });
    } catch {
      // Not doing anything, because this safety net is not critical
    }
  };

  const { errors, handleSubmit, setFieldValue, submitCount, values } =
    useFormik<FormValues>({
      initialValues: {
        code: defaultTaxAccount?.code ?? '',
        rate: defaultTaxAccount?.rate ?? '',
      },
      enableReinitialize: true,
      validate: (newValues) => validate(newValues, t),
      onSubmit: async (newValues) => {
        if (
          !integrationStatus ||
          !isIntegrationStatusWithIntegration(integrationStatus)
        ) {
          return;
        }

        const code = newValues.code.trim();
        const rate = newValues.rate.trim();

        try {
          // If both code and rate are set, we set the default tax account
          if (code && rate) {
            const result = await setDefaultTaxAccount({
              ...defaultTaxAccount,
              name:
                defaultTaxAccount?.name ??
                getDefaultTaxAccountName(integrationStatus.accountingCountry),
              code,
              rate: rate.toString(),
              isArchived: false,
            });

            if (result.outcome === 'notSet') {
              dangerNotif(t('misc.errors.unknownError'));
              return;
            }

            goToNext();
            return;
          }

          // If the default VAT account was not archived
          // and we are not setting any value, then we archive it
          if (defaultTaxAccount && !defaultTaxAccount.isArchived) {
            const result = await setDefaultTaxAccount({
              ...defaultTaxAccount,
              isArchived: true,
            });
            if (result.outcome === 'notSet') {
              dangerNotif(t('misc.errors.unknownError'));
              return;
            }
            goToNext();
            return;
          }

          // If there was nothing to set/update, we check if we need to manually complete the task
          // and then proceed with the flow
          await completeTaskIfRelevant();
          goToNext();
        } catch {
          dangerNotif(t('misc.errors.unknownError'));
        }
      },
    });

  const goToNext = async () => {
    if (!canAccessReverseCharges) {
      goBack();
      return;
    }
    const nextPath = routeFor(routes.ACCOUNTING_REVERSE_CHARGE_ACCOUNTS.path, {
      company: companyId,
    });
    history.push(nextPath);
  };

  const goBack = () => {
    const setupHubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(setupHubPath);
  };

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.vatAccounts.vat.description')}
        isLastStep={!canAccessReverseCharges}
        onBack={goBack}
        onNext={handleSubmit}
        title={t('setupHub.vatAccounts.vat.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('setupHub.vatAccounts.vat.helpTitle'),
        }}
      >
        <div className="flex flex-col gap-16">
          <p className="title-m">{t('setupHub.expenseAccounts.listTitle')}</p>
          <VatAccountsTableAndModals />
        </div>
        <div className="my-16 flex items-center gap-16">
          <div className="separator" />
          <p className="uppercase text-secondary-bg-primary body-l">
            {t('misc.or')}
          </p>
          <div className="separator" />
        </div>
        <form onSubmit={handleSubmit}>
          <DefaultVatAccountForm
            code={values.code}
            codeError={submitCount > 0 ? errors.code : undefined}
            rate={values.rate}
            rateError={submitCount > 0 ? errors.rate : undefined}
            setCode={(code: string) => setFieldValue('code', code)}
            setRate={(rate: string) => setFieldValue('rate', rate)}
          />
        </form>
      </TaskStepLayout>
    </div>
  );
};
