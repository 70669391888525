import { Callout } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type MemberEditFormikValues } from '../../components/MemberEditModal/validate';
import { MemberEditTabSettings } from '../../components/MemberEditTabSettings/MemberEditTabSettings';
import { MemberEditTabSettingsLoader } from '../../components/MemberEditTabSettings/MemberEditTabSettingsLoader';
import { useControlRulesQuery } from '../../hooks/useControlRulesQuery';
import { usePoliciesQuery } from '../../hooks/usePoliciesQuery';
import { useTeamsQuery } from '../../hooks/useTeamsQuery';
import { type Member } from '../../models/member';

type Props = {
  member: Member;
  formikProps: FormikProps<MemberEditFormikValues>;
};

export const MemberEditTabSettingsContainer = ({
  member,
  formikProps,
}: Props) => {
  const { t } = useTranslation('global');

  const queriesState = useQueryStates({
    states: {
      controlRules: useControlRulesQuery(),
      teams: useTeamsQuery(),
      costCenters: useCostCentersQuery(),
      policies: usePoliciesQuery(),
    },
  });

  return (
    <ErrorBoundary
      context={{
        team: 'finance-controller',
        scope: 'member-edit-settings-policies',
      }}
    >
      <QuerySuspense
        queryState={queriesState}
        loading={<MemberEditTabSettingsLoader />}
        fallback={() => (
          <Callout title={t('members.editModal.fetchFailed')} variant="alert" />
        )}
      >
        {({ controlRules, teams, policies, costCenters }) => (
          <MemberEditTabSettings
            formikProps={formikProps}
            member={member}
            teams={teams}
            costCenters={costCenters}
            controlRules={controlRules.filter(
              (controlRule) => !controlRule.isCustom,
            )}
            policies={policies}
          />
        )}
      </QuerySuspense>
    </ErrorBoundary>
  );
};
