import {
  Autocomplete,
  Callout,
  FormField,
  Icon,
  RadioField,
  RadioGroup,
  TextInput,
  Tooltip,
  colors,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React, { useId, useState } from 'react';

import type { CostCenter } from 'modules/budgets/models/costCenter';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { type ExpenseAccount } from 'src/core/modules/bookkeep/accounts-payable/types';

import { type FormValues } from './formValues';
import { MultipleCostCenterAutocomplete } from '../../../cost-centers/components/MultipleCostCenterAutocomplete';
import { useIsExpenseCategoryExpenseAccountRuleEnabled } from '../../hooks/useIsExpenseCategoryExpenseAccountRuleEnabled';

type Props = {
  mode: 'creation' | 'edit';
  costCenters: CostCenter[];
  expenseAccounts: ExpenseAccount[];
} & Pick<
  FormikProps<FormValues>,
  | 'values'
  | 'initialValues'
  | 'errors'
  | 'setFieldValue'
  | 'handleChange'
  | 'handleSubmit'
>;

export const EcFormModalContent = ({
  mode,
  costCenters,
  expenseAccounts,
  // formik props
  values,
  errors,
  initialValues,
  setFieldValue,
  handleChange,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation('global');
  const radioGroupId = useId();
  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  const isExpenseCategoryExpenseAccountRuleEnabled =
    useIsExpenseCategoryExpenseAccountRuleEnabled();

  const [expenseAccountSearch, setExpenseAccountSearch] = useState('');
  const filteredExpenseAccounts: ExpenseAccount[] = expenseAccountSearch
    ? expenseAccounts.filter((expenseAccount) =>
        expenseAccount.name
          .toLowerCase()
          .includes(expenseAccountSearch.toLowerCase()),
      )
    : expenseAccounts;
  const filteredExpenseAccountOptions = filteredExpenseAccounts.map(
    (expenseAccount) => ({
      key: expenseAccount.id,
      label: `${expenseAccount.code} - ${expenseAccount.name}`,
    }),
  );

  const areLinkedExpenseAccountIdsDirty =
    initialValues.linkedExpenseAccountIds.sort().join('') !==
    values.linkedExpenseAccountIds.sort().join('');

  return (
    <form id="ec-form" onSubmit={handleSubmit}>
      <FormField
        className="mb-24"
        label={t('expenseCategories.form.name')}
        alertMessage={errors.name}
      >
        <TextInput
          name="name"
          placeholder={t('expenseCategories.form.namePlaceholder')}
          value={values.name}
          onChange={handleChange}
        />
      </FormField>
      {isCostCentersFeatureEnabled && (
        <>
          <div className="mb-8 text-left">
            <FormField
              label={t('expenseCategories.form.costCenterAssignationFieldName')}
              description={t(
                'expenseCategories.form.costCenterAssignationFieldDescription',
              )}
            >
              <RadioGroup
                aria-labelledby={radioGroupId}
                name="costCenterAssignationType"
                value={values.costCenterAssignationType}
                onChange={(e) => {
                  setFieldValue('costCenterAssignationType', e.target.value);
                }}
              >
                <RadioField
                  value="all"
                  label={t('expenseCategories.form.costCenterAssignation.all')}
                />
                <RadioField
                  value="some"
                  label={t('expenseCategories.form.costCenterAssignation.some')}
                />
              </RadioGroup>
            </FormField>
          </div>
          {values.costCenterAssignationType === 'some' && (
            <FormField
              className="text-left"
              label={t('misc.costCenter')}
              visuallyHideLabel
              alertMessage={
                typeof errors.costCenterIds === 'string'
                  ? errors.costCenterIds
                  : undefined
              }
            >
              <MultipleCostCenterAutocomplete
                costCenters={costCenters}
                selectedCostCenterIds={values.costCenterIds}
                onSelect={(options) => {
                  setFieldValue(
                    'costCenterIds',
                    options.map((option) => option.key),
                  );
                }}
              />
            </FormField>
          )}
        </>
      )}
      {isExpenseCategoryExpenseAccountRuleEnabled && (
        <FormField
          className="my-24"
          label={
            (
              <div className="flex flex-row items-center">
                {t('expenseCategories.form.linkedExpenseAccount.label')}
                <Tooltip
                  placement="top"
                  content={t(
                    'expenseCategories.form.linkedExpenseAccount.tooltip',
                  )}
                >
                  <Icon
                    name="circle-information"
                    size="s"
                    color={colors.contentDecorativeIcon}
                    className="ml-8"
                  />
                </Tooltip>
              </div>
            ) as unknown as string
          }
          hint={t('misc.optional')}
        >
          <Autocomplete
            fit="parent"
            options={filteredExpenseAccountOptions}
            placeholder={t(
              'expenseCategories.form.linkedExpenseAccount.placeholder',
            )}
            name="linkedExpenseAccount"
            showClearSelectionButton
            value={filteredExpenseAccountOptions.find(
              (option) => option.key === values.linkedExpenseAccountIds[0],
            )}
            onSelect={(option) => {
              setFieldValue(
                'linkedExpenseAccountIds',
                option ? [option.key] : [],
              );
            }}
            onSearch={(newSearch) => setExpenseAccountSearch(newSearch || '')}
          />
          {mode === 'edit' && areLinkedExpenseAccountIdsDirty && (
            <Callout
              className="mt-8"
              title={t(
                'expenseCategories.form.linkedExpenseAccount.updateExpenseAccountsWarning',
              )}
              variant="warning"
            />
          )}
        </FormField>
      )}
    </form>
  );
};
