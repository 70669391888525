import { Icon } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { routes as authRoutes } from 'src/auth/constants/routes';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { getHasImpersonationTargets } from 'src/core/selectors/globalSelectors';

import styles from './ImpersonationOrganisationSwitcher.module.css';

export const ImpersonationOrganisationSwitcher = () => {
  const { t } = useTranslation('global');
  const hasImpersonationTargets = useSelector(getHasImpersonationTargets);

  if (!hasImpersonationTargets) {
    return null;
  }

  return (
    <a
      className={classNames('navigation-item h-40', styles.link)}
      href={authRoutes.COMPANY_SELECT.path}
    >
      <Icon name="arrow-left-right" size="m" aria-hidden="true" />
      <span className="navigation-toggle-display title-l">
        {t('navigation.impersonationBackToOrgListButton')}
      </span>
    </a>
  );
};
