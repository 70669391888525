import { useTranslation } from 'common/hooks/useTranslation';

export const FAQ = () => {
  const { t } = useTranslation('global');

  const faqItems = [
    {
      question: t('overdueInvoice.closedDoor.faq.items.question1'),
      answer: t('overdueInvoice.closedDoor.faq.items.answer1'),
    },
    {
      question: t('overdueInvoice.closedDoor.faq.items.question2'),
      answer: t('overdueInvoice.closedDoor.faq.items.answer2'),
    },
    {
      question: t('overdueInvoice.closedDoor.faq.items.question3'),
      answer: t('overdueInvoice.closedDoor.faq.items.answer3'),
    },
    {
      question: t('overdueInvoice.closedDoor.faq.items.question4'),
      answer: t('overdueInvoice.closedDoor.faq.items.answer4'),
    },
    {
      question: t('overdueInvoice.closedDoor.faq.items.question5'),
      answer: t('overdueInvoice.closedDoor.faq.items.answer5'),
    },
    {
      question: t('overdueInvoice.closedDoor.faq.items.question6'),
      answer: t('overdueInvoice.closedDoor.faq.items.answer6'),
    },
  ];

  return (
    <div className="flex flex-col gap-24">
      <h2 className="title-xl">{t('overdueInvoice.closedDoor.faq.title')}</h2>
      <div className="grid grid-cols-2 gap-24">
        {faqItems.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="box-border pr-4">
            <h3 className="title-m">{item.question}</h3>
            <p className="mt-16 body-m">{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
