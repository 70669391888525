import {
  Button,
  FormField,
  SkeletonText,
  SwitchField,
  TextInput,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import {
  type AccountPayable,
  type DefaultSupplierAccount,
  getDefaultSupplierAccountName,
  type SupplierAccountDefaultFor,
} from 'modules/bookkeep';
import { useHasAuxiliaryAccountsEnabled } from 'modules/bookkeep/hooks';
import {
  type MutationQueryState,
  type QueryState,
} from 'src/core/api/queryState';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import styles from './SupplierAccountsLocalOnlySection.module.css';
import { type IntegrationStatusWithIntegration } from '../../../../../../../integration/status';
import { type Result as GetDefaultSupplierAccountQueryResult } from '../../../../../hooks/useGetDefaultSupplierAccountQuery';
import { type Result as SetDefaultSupplierAccountMutationResult } from '../../../../../hooks/useSetDefaultSupplierAccountMutation';
import { DefaultAccountDeleteConfirmationModal } from '../../../components/DefaultAccountConfirmationModal/DefaultAccountDeleteConfirmationModal';
import { DefaultAccountEditConfirmationModal } from '../../../components/DefaultAccountConfirmationModal/DefaultAccountEditConfirmationModal';
import { validateAccountPayable } from '../../../utils';

type ErrorState = {
  error: 'codeAlreadyExists' | 'required' | 'invalidPattern' | 'unknown';
  existingAccount?: AccountPayable;
};

function getErrorTranslation(
  errorState: ErrorState,
  t: TGlobalFunctionTyped,
): string | undefined {
  switch (errorState.error) {
    case 'required':
      return t(
        'bookkeep.integrations.settings.defaultSupplierAccountForm.requiredError',
      );
    case 'invalidPattern':
      return t(
        'bookkeep.integrations.settings.defaultSupplierAccountForm.invalidPatternError',
      );
    case 'codeAlreadyExists':
      return t(
        'bookkeep.integrations.settings.defaultSupplierAccountForm.codeAlreadyExistsError',
      );
    case 'unknown':
      return t('misc.loadingError');
    default:
      return undefined;
  }
}

function getTitleTranslation(
  defaultFor: SupplierAccountDefaultFor,
  t: TGlobalFunctionTyped,
): string | undefined {
  switch (defaultFor) {
    case 'cardSupplier':
      return t(
        'bookkeep.integrations.settings.defaultSupplierAccountForm.cardTitle',
      );
    case 'invoiceSupplier':
      return t(
        'bookkeep.integrations.settings.defaultSupplierAccountForm.invoiceTitle',
      );
    default:
      return undefined;
  }
}

function getDescriptionTranslation(
  defaultFor: SupplierAccountDefaultFor,
  t: TGlobalFunctionTyped,
): string | undefined {
  switch (defaultFor) {
    case 'cardSupplier':
      return t(
        'bookkeep.integrations.settings.defaultSupplierAccountForm.cardDescription',
      );
    case 'invoiceSupplier':
      return t(
        'bookkeep.integrations.settings.defaultSupplierAccountForm.invoiceDescription',
      );
    default:
      return undefined;
  }
}

interface Props {
  onDefaultChange: (
    defaultSupplierAccount: DefaultSupplierAccount,
  ) => Promise<void>;
  defaultFor: SupplierAccountDefaultFor;
  defaultSupplierAccount: DefaultSupplierAccount | undefined;
  getDefaultSupplierAccountQueryState: QueryState<GetDefaultSupplierAccountQueryResult>;
  setDefaultSupplierAccountQueryState: MutationQueryState<SetDefaultSupplierAccountMutationResult>;
  integrationStatus: IntegrationStatusWithIntegration;
  accountLength: number;
  isInitialToggleChecked: boolean;
}

export const DefaultSupplierAccountForm = ({
  onDefaultChange,
  defaultFor,
  defaultSupplierAccount,
  setDefaultSupplierAccountQueryState,
  getDefaultSupplierAccountQueryState,
  integrationStatus,
  accountLength,
  isInitialToggleChecked,
}: Props) => {
  const { t } = useTranslation('global');
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();
  const [newDefaultSupplierAccount, setNewDefaultSupplierAccount] = useState(
    getInitialDefaultAccount(
      integrationStatus,
      defaultSupplierAccount,
      defaultFor,
    ),
  );
  const [errorState, setErrorState] = useState<ErrorState | undefined>();
  const [inputChanged, setInputChanged] = useState(false);
  const [valueChanged, setValueChanged] = useState<{
    generalAccountCode: boolean;
    auxiliaryAccountCode: boolean;
  }>({
    generalAccountCode: false,
    auxiliaryAccountCode: false,
  });
  const [isToggleChecked, setIsToggleChecked] = useState(
    isInitialToggleChecked,
  );
  const [modalState, setModalState] = useState<{
    kind: 'closed' | 'confirmEdit' | 'confirmDelete';
  }>({ kind: 'closed' });

  useEffect(() => {
    setIsToggleChecked(isInitialToggleChecked);
  }, [isInitialToggleChecked]);

  useEffect(() => {
    setValueChanged({ generalAccountCode: false, auxiliaryAccountCode: false });
    setNewDefaultSupplierAccount(
      getInitialDefaultAccount(
        integrationStatus,
        defaultSupplierAccount,
        defaultFor,
      ),
    );
  }, [defaultSupplierAccount]);

  useEffect(() => {
    if (
      setDefaultSupplierAccountQueryState.status === 'success' &&
      setDefaultSupplierAccountQueryState.data.outcome === 'notSet' &&
      setDefaultSupplierAccountQueryState.data.reason === 'codeAlreadyExists'
    ) {
      setErrorState({
        error: 'codeAlreadyExists',
        existingAccount:
          setDefaultSupplierAccountQueryState.data.existingAccount,
      });
    } else if (
      setDefaultSupplierAccountQueryState.status === 'error' ||
      (setDefaultSupplierAccountQueryState.status === 'success' &&
        setDefaultSupplierAccountQueryState.data.outcome === 'notSet')
    ) {
      setErrorState({
        error: 'unknown',
      });
    }
  }, [setDefaultSupplierAccountQueryState]);

  const handleSubmit = () => {
    const result = validateAccountPayable(
      newDefaultSupplierAccount?.generalAccountCode ?? '',
      newDefaultSupplierAccount?.auxiliaryAccountCode,
      accountLength,
      // Not using frontend validation for generalAccountCode uniqueness
      { status: 'loading' },
      { status: 'loading' },
      integrationStatus.integration,
      auxiliaryAccountsEnabled,
    );

    setInputChanged(false);

    if (result.outcome === 'invalid') {
      setErrorState({ error: result.reason });
      return;
    }

    setErrorState(undefined);

    if (!defaultSupplierAccount) {
      setValueChanged({
        generalAccountCode: false,
        auxiliaryAccountCode: false,
      });
      onDefaultChange(newDefaultSupplierAccount);
      return;
    }

    // don't do anything if the Supplier account hasn't change
    if (
      defaultSupplierAccount.generalAccountCode !==
        newDefaultSupplierAccount.generalAccountCode ||
      defaultSupplierAccount.auxiliaryAccountCode !==
        newDefaultSupplierAccount.auxiliaryAccountCode
    ) {
      setModalState({ kind: 'confirmEdit' });
    }
  };

  const handleSwitchToggle = () => {
    if (defaultSupplierAccount) {
      if (isToggleChecked) {
        setModalState({ kind: 'confirmDelete' });
        return;
      }

      onDefaultChange({
        ...defaultSupplierAccount,
        isArchived: isToggleChecked,
      });
    }

    setIsToggleChecked(!isToggleChecked);
  };

  const buildHandleTextChange: (
    key: 'generalAccountCode' | 'auxiliaryAccountCode',
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void =
    (key) => (event) => {
      const accountCode = event.target.value;
      setNewDefaultSupplierAccount({
        ...newDefaultSupplierAccount,
        [key]: accountCode,
        isArchived: false,
      });
      setInputChanged(true);
      setValueChanged({
        ...valueChanged,
        [key]: defaultSupplierAccount?.[key] !== accountCode,
      });
      setErrorState(undefined);
    };

  if (getDefaultSupplierAccountQueryState.status === 'loading') {
    return (
      <div className="box mt-24">
        <SkeletonText size="xl" />
        <SkeletonText size="m" />
      </div>
    );
  }

  const isSubmittable =
    valueChanged.generalAccountCode || valueChanged.auxiliaryAccountCode;
  const isAuxiliaryFieldErrored =
    !!errorState && errorState.error !== 'required' && !inputChanged;
  return (
    <div className="box mt-24">
      <SwitchField
        fit="parent"
        id={`useDefaultAccount_${defaultFor}`}
        name={`useDefaultAccount_${defaultFor}`}
        label={getTitleTranslation(defaultFor, t)}
        helpText={getDescriptionTranslation(defaultFor, t)}
        isChecked={isToggleChecked && !!defaultSupplierAccount}
        onChange={handleSwitchToggle}
      />
      {isToggleChecked && (
        <form
          id={`default-supplier-form_${defaultFor}`}
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.defaultForm__inputWrapper}>
            <FormField
              className={classNames(
                'general-account-field',
                styles.defaultForm__input,
              )}
              label={t(
                'bookkeep.integrations.settings.defaultSupplierAccountForm.name',
              )}
              htmlFor={`set-general-account-input_${defaultFor}`}
              alertMessage={
                !!errorState && !inputChanged
                  ? getErrorTranslation(errorState, t)
                  : undefined
              }
            >
              <TextInput
                placeholder={t(
                  'bookkeep.integrations.settings.defaultSupplierAccountForm.placeholder',
                )}
                isInvalid={!!errorState && !inputChanged}
                value={newDefaultSupplierAccount?.generalAccountCode ?? ''}
                onChange={buildHandleTextChange('generalAccountCode')}
                id={`set-general-account-input_${defaultFor}`}
              />
            </FormField>
            {auxiliaryAccountsEnabled ? (
              <FormField
                className={classNames(
                  'auxiliary-account-field',
                  styles.defaultForm__input,
                )}
                label={t(
                  'bookkeep.integrations.settings.defaultSupplierAccountForm.auxiliaryAccountName',
                )}
                htmlFor={`set-auxiliary-account-input_${defaultFor}`}
                hint={t('misc.optional')}
                alertMessage={
                  isAuxiliaryFieldErrored
                    ? getErrorTranslation(errorState, t)
                    : undefined
                }
              >
                <TextInput
                  placeholder={t(
                    'bookkeep.integrations.settings.defaultSupplierAccountForm.placeholder',
                  )}
                  isInvalid={isAuxiliaryFieldErrored}
                  value={newDefaultSupplierAccount?.auxiliaryAccountCode ?? ''}
                  onChange={buildHandleTextChange('auxiliaryAccountCode')}
                  id={`set-auxiliary-account-input_${defaultFor}`}
                />
              </FormField>
            ) : (
              <div className={styles.defaultForm__input} />
            )}
          </div>

          <Button
            id={`default-supplier-account-save-button_${defaultFor}`}
            variant={isSubmittable ? 'primaryBrand' : 'secondaryNeutral'}
            text={t('misc.saveChanges')}
            className={styles.defaultForm__submit}
            isDisabled={
              !isSubmittable ||
              setDefaultSupplierAccountQueryState.status === 'loading'
            }
            type="submit"
          />

          {newDefaultSupplierAccount && defaultSupplierAccount ? (
            <DefaultAccountEditConfirmationModal
              integration={integrationStatus.integration}
              isOpen={modalState.kind === 'confirmEdit'}
              setModalState={setModalState}
              onSetDefault={onDefaultChange}
              existingDefaultAccount={{
                ...defaultSupplierAccount,
                code: defaultSupplierAccount.generalAccountCode,
              }}
              newDefaultAccount={{
                ...newDefaultSupplierAccount,
                code: newDefaultSupplierAccount.generalAccountCode,
              }}
              actionOrigin="defaultSupplierAccountForm"
              auxiliaryAccountsEnabled={auxiliaryAccountsEnabled}
            />
          ) : null}

          {defaultSupplierAccount ? (
            <DefaultAccountDeleteConfirmationModal
              integration={integrationStatus.integration}
              isOpen={modalState.kind === 'confirmDelete'}
              setModalState={setModalState}
              onSetDefault={onDefaultChange}
              defaultAccount={{
                ...defaultSupplierAccount,
                code: defaultSupplierAccount.generalAccountCode,
              }}
              actionOrigin="defaultSupplierAccountForm"
            />
          ) : null}
        </form>
      )}
    </div>
  );
};

function getInitialDefaultAccount(
  integrationStatus: IntegrationStatusWithIntegration,
  defaultSupplierAccount: DefaultSupplierAccount | undefined,
  defaultFor: SupplierAccountDefaultFor,
): DefaultSupplierAccount {
  const name = getDefaultSupplierAccountName(
    integrationStatus.accountingCountry,
  );
  return defaultSupplierAccount
    ? {
        ...defaultSupplierAccount,
        name: defaultSupplierAccount.name || name,
      }
    : {
        name,
        generalAccountCode: '',
        auxiliaryAccountCode: '',
        isArchived: false,
        defaultFor,
      };
}
