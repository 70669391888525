import { AmountInput, FormField, TextInput } from '@dev-spendesk/grapes';
import * as Money from 'ezmoney';
import { type FormikProps } from 'formik';
import React from 'react';

import { type PayableAmortisationScheme } from 'modules/accounting-integration/models';
import {
  PayableAccountPayableField,
  PayableAmortisationField,
  PayableCustomFields,
  useCustomFieldsInputsConfig,
  PayableExpenseAccountField,
  PayableTaxAccountField,
} from 'modules/payable/components';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type PayableType } from 'src/core/modules/bookkeep/prepare-payables/models';

import { type Payable, type CostCenter } from '../../../PayablePanelContainer';
import { type AccountingFormValues } from '../../hooks/usePayableAccountingEditForm';
import { PayableAccountingSectionCostCenter } from '../PayableAccountingSectionCostCenter';

type Props = {
  payable: Payable;
  costCenters: CostCenter[];
  amortisationSchemes: PayableAmortisationScheme[];
  items: {
    label: string;
    value: string;
    rawValue?: unknown;
    type?: string;
    expenseCategoryValueId?: string;
    deleted?: boolean;
  }[];
};

export const PayableAccountingEditSection = ({
  payable,
  costCenters,
  amortisationSchemes,
  items: allItems,
  // formik props
  initialValues,
  values,
  errors,
  setFieldValue,
  setValues,
}: Props & FormikProps<AccountingFormValues>) => {
  const { t } = useTranslation('global');

  const items = allItems.filter(({ type }) => type !== 'customField');

  const withAmortisationScheme = items.some(
    (item) => item.type === 'amortisationScheme',
  );

  const reshapedPayable = reshapePayableForField(payable);

  const customFieldsInputsConfig = useCustomFieldsInputsConfig({
    payableType: reshapedPayable.type,
    teamId: payable.team?.id,
    customFields: values.customFields,
  });

  const amortisationDateField = items.find(
    (item) => item.type === 'amortisationDate',
  );

  return (
    <div className="PayableDetailsEditSection">
      {/* eslint-disable-next-line sonarjs/cognitive-complexity */}
      {items.map((item) => {
        const defaultField = (
          <div className="mb-16 flex flex-col" key={item.label}>
            <FormField
              label={item.label}
              alertMessage={
                item.deleted ? t('payables.panel.deletedTooltip') : undefined
              }
            >
              <TextInput
                fit="parent"
                isInvalid={false}
                value={item.value}
                isDisabled
              />
            </FormField>
          </div>
        );

        switch (item.type) {
          case 'amount': {
            const amount = item.rawValue as Money.MonetaryValue;

            return (
              <div className="mb-16 flex flex-col" key={item.label}>
                <FormField label={item.label}>
                  <AmountInput
                    fit="parent"
                    isInvalid={false}
                    value={Money.toNumber(amount)}
                    currency={{ key: amount.currency, label: amount.currency }}
                    onChange={() => {
                      // Do nothing
                    }}
                    isDisabled
                  />
                </FormField>
              </div>
            );
          }

          case 'expenseAccount': {
            return (
              <div className="mb-16 flex flex-col" key={item.label}>
                <FormField label={item.label}>
                  <PayableExpenseAccountField
                    values={
                      values.expenseAccount
                        ? {
                            accountId: values.expenseAccount.id,
                            name: values.expenseAccount.name,
                          }
                        : {}
                    }
                    initialValues={
                      values.expenseAccount
                        ? {
                            accountId: values.expenseAccount.id,
                            name: values.expenseAccount.name,
                          }
                        : {}
                    }
                    onChange={(value) => {
                      setFieldValue('expenseAccount', {
                        id: value.accountId,
                        name: value.name,
                      });
                    }}
                    shouldDisplayAmountValue={false}
                    hasAmountError={false}
                    currency={payable.grossAmount.currency}
                    accountAutomation={payable.automation?.expenseAccount}
                    amountAutomation={payable.automation?.expenseAmount}
                    errors={
                      errors.expenseAccount
                        ? { accountId: errors.expenseAccount }
                        : {}
                    }
                  />
                </FormField>
              </div>
            );
          }

          case 'taxAccount': {
            const payableItemLines = [
              {
                vat: values.taxAccount
                  ? {
                      accountId: values.taxAccount?.id,
                      amount: values.taxAmount ?? null,
                      itemLineId: 0,
                      rate: values.taxAccount?.rate ?? null,
                      name: values.taxAccount?.name ?? null,
                    }
                  : null,
                expense: values.expenseAccount
                  ? {
                      accountId: values.expenseAccount?.id,
                      amount: values.expenseAmount ?? null,
                      name: values.expenseAccount?.name ?? null,
                    }
                  : null,
              },
            ];

            return (
              <div className="mb-16 flex flex-col" key={item.label}>
                <FormField label={item.label}>
                  <PayableTaxAccountField
                    payable={reshapedPayable}
                    automation={reshapedPayable.automation}
                    payableItemLineIndex={0}
                    payableItemLines={payableItemLines}
                    initialPayableItemLines={reshapedPayable.itemLines.map(
                      (itemLine) => ({
                        vat: {
                          accountId: itemLine.taxAccount?.id ?? null,
                          amount: itemLine.taxAmount,
                          rate: itemLine.taxAccount?.rate ?? null,
                          itemLineId: 0,
                          name: itemLine.taxAccount?.name,
                        },
                        expense: itemLine.expenseAccount
                          ? {
                              accountId: itemLine.expenseAccount?.id,
                              amount: itemLine.netAmount ?? null,
                              name: itemLine.expenseAccount?.name ?? null,
                            }
                          : null,
                      }),
                    )}
                    errors={{
                      accountId: errors.taxAccount,
                      amount: errors.taxAmount,
                    }}
                    walletCurrency={reshapedPayable.amount.currency}
                    currency={
                      reshapedPayable.originalAmount
                        ? reshapedPayable.originalAmount.currency
                        : reshapedPayable.amount.currency
                    }
                    hasAmountError={!!errors.taxAmount}
                    onChange={([itemLine]) => {
                      setValues({
                        ...values,
                        taxAccount: itemLine?.vat
                          ? {
                              id: itemLine?.vat.accountId ?? '',
                              rate: itemLine?.vat.rate ?? undefined,
                              name: itemLine?.vat.name ?? '',
                              isArchived: false,
                            }
                          : undefined,
                        taxAmount: itemLine?.vat?.amount ?? undefined,
                        expenseAmount: itemLine?.expense?.amount ?? undefined,
                      });
                    }}
                  />
                </FormField>
              </div>
            );
          }

          case 'accountPayable': {
            return (
              <PayableAccountPayableField
                className="mb-16 flex flex-col"
                key={item.label}
                payable={reshapedPayable}
                value={values.accountPayable}
                onChange={(accountPayable) => {
                  setFieldValue('accountPayable', accountPayable);
                }}
                error={errors.accountPayable}
              />
            );
          }

          case 'costCenter': {
            // Edition disabled for now for payables with split values
            if (payable.itemLines.length === 1) {
              return (
                <div className="mb-16 flex flex-col" key={item.label}>
                  <PayableAccountingSectionCostCenter
                    costCenters={costCenters}
                    costCenter={values.costCenter}
                    onChange={(costCenter) => {
                      setFieldValue('costCenter', costCenter);
                    }}
                    error={errors.costCenter}
                  />
                </div>
              );
            }
            return defaultField;
          }

          case 'amortisationDate':
            // Displayed at the end of the section
            return;

          case 'amortisationScheme':
            // Handled in the amortisation date field
            return;

          case 'amortisationSwitch':
            // Do nothing
            return;

          default:
            return defaultField;
        }
      })}
      <PayableCustomFields
        payable={reshapedPayable}
        customFieldsInputsConfig={customFieldsInputsConfig}
        customFields={values.customFields}
        editSplitValues
        onChange={(key, value) => {
          setFieldValue(`customFields.${key}`, value);
        }}
        errors={
          errors.customFields
            ? Object.fromEntries(
                Object.entries(errors.customFields).filter(
                  ([_, value]) => typeof value === 'string',
                ),
              )
            : {}
        }
        spacing="16"
      />
      {!!amortisationDateField && (
        <div className="mb-16 flex flex-col" key={amortisationDateField.label}>
          <PayableAmortisationField
            initialValue={initialValues.amortisation}
            value={values.amortisation}
            onChange={(value) => {
              setFieldValue('amortisation', value);
            }}
            alertMessage={errors.amortisation}
            withAmortisationScheme={withAmortisationScheme}
            amortisationSchemes={amortisationSchemes}
            payableDate={payable.creationDate}
          />
        </div>
      )}
    </div>
  );
};

/**
 * Helpers
 */
const reshapePayableForField = (payable: Payable) => {
  const reshapePayableType = (rawType: string): PayableType => {
    switch (rawType) {
      case 'cardPurchase':
        return 'card_purchase';
      case 'invoicePurchase':
        return 'invoice_purchase';
      case 'claimedBill':
        return 'expense_claim';
      case 'mileageAllowance':
        return 'mileage_allowance';
      case 'perDiemAllowance':
        return 'per_diem_allowance';
      case 'reversal':
        return 'reversal';

      default:
        throw new Error(`Unknown payable type ${rawType}`);
    }
  };

  return {
    ...payable,
    type: reshapePayableType(payable.type),
    subType: payable.subtype,
    originalAmount: payable.grossAmount,
    amount: payable.functionalAmount,
    exchangeRate: payable.functionalExchangeRate,
    createdAt: payable.creationDate.toISOString(),
  };
};
