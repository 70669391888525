import { Button } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type SelectedApprover } from '../../types';
import { SelectApproverDropdown } from '../SelectApproverDropdown';

type Props = {
  onSelect: (selectedApprover: SelectedApprover) => void;
};

export const AddApproverDropdown = ({ onSelect }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation('global');

  return (
    <div className="my-16 flex">
      {isDropdownOpen ? (
        <SelectApproverDropdown
          onSelect={(approver) => {
            onSelect(approver);
            setIsDropdownOpen(false);
          }}
          onBlur={() => {
            setIsDropdownOpen(false);
          }}
        />
      ) : (
        <Button
          className="self-start"
          variant="secondaryNeutral"
          text={t('members.addApprover')}
          iconName="plus"
          iconPosition="left"
          onClick={() => setIsDropdownOpen(true)}
        />
      )}
    </div>
  );
};
