export enum SubnavigationItem {
  Drafts = 'drafts',
  All = 'all',
  Mine = 'mine',
  ToApprove = 'to-approve',
  InboxInvoices = 'inbox-invoices',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSubnavigationItem = (item: any): item is SubnavigationItem =>
  Object.values(SubnavigationItem).includes(item);

export const isDraftsTab = (typeFromRouteParams: string): boolean =>
  typeFromRouteParams === SubnavigationItem.Drafts;

export const isToApproveTab = (typeFromRouteParams: string): boolean =>
  typeFromRouteParams === SubnavigationItem.ToApprove;

export const hasApproverSubNav = (user: {
  is_approver: boolean;
  is_account_owner: boolean;
}): boolean => {
  return user.is_approver || user.is_account_owner;
};
