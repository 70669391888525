import { IconButton } from '@dev-spendesk/grapes';
import { type ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  onClose: () => void;
  onDelete?: () => void;
};
export const FloatingPanel = ({
  children,
  isOpen,
  title,
  onClose,
  onDelete,
}: Props) => {
  return isOpen ? (
    <div className="bg-primary-default box absolute right-16 top-[130px] z-50 m-0 flex h-[calc(100%_-_142px)] w-[480px] flex-col overflow-y-auto p-0 shadow-l">
      <div className="border-b-default flex items-center justify-between border-0 border-b border-solid px-16 py-8">
        <div className="text-primary title-l">{title}</div>
        <div className="flex gap-8">
          {onDelete && (
            <IconButton
              aria-label="delete"
              onClick={onDelete}
              iconName="trash"
            />
          )}
          <IconButton aria-label="cross" onClick={onClose} iconName="cross" />
        </div>
      </div>
      <div className="flex grow flex-col p-16">{children}</div>
    </div>
  ) : null;
};
