import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useMarkHubTaskAsDoneMutation } from 'modules/onboarding/components/MarkAsDoneButton/hooks/useMarkHubTaskAsDoneMutation';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { routeFor } from 'src/core/constants/routes';

import { ReverseChargeAccountsTable } from './components/ReverseChargeAccountsTable';
import { useHubTaskByType } from '../../../hooks/useHubTaskByType';
import { routes } from '../../routes';

export const AccountingReverseChargeAccounts = () => {
  const history = useHistory();

  const { t } = useTranslation('global');

  const companyId = useCompanyId();

  const task = useHubTaskByType('vat_codes');
  const [markTaskAsDone] = useMarkHubTaskAsDoneMutation();

  const onSubmit = async () => {
    // Users could go through the whole step without setting anything
    // so we are manually marking the task as done, as an extra safety net
    if (task) {
      try {
        await markTaskAsDone({ endpointParams: { taskId: task.id } });
      } catch {
        // Not doing anything, because this safety net is not critical
      }
    }

    const setupHubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(setupHubPath);
  };

  const goBack = () => {
    const setupHubPath = routeFor(routes.ACCOUNTING_VAT_ACCOUNTS.path, {
      company: companyId,
    });
    history.push(setupHubPath);
  };

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.vatAccounts.reverseCharge.description')}
        isLastStep
        onBack={goBack}
        onNext={onSubmit}
        title={t('setupHub.vatAccounts.reverseCharge.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('setupHub.vatAccounts.reverseCharge.helpTitle'),
        }}
      >
        <div className="flex flex-col gap-16">
          <p className="title-m">
            {t('setupHub.vatAccounts.reverseCharge.listTitle')}
          </p>
          <ReverseChargeAccountsTable />
        </div>
      </TaskStepLayout>
    </div>
  );
};
