import { Button, EmptyState } from '@dev-spendesk/grapes';
import React, { type MouseEventHandler } from 'react';

import styles from './PageEmptyState.module.css';

type Props = {
  title: string;
  description: string;
  illustrationSrc: string;
  buttonLabel: string;
  isDisabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const PageEmptyState = ({
  title,
  description,
  illustrationSrc,
  buttonLabel,
  isDisabled = false,
  onClick,
}: Props) => {
  return (
    <div className="page__container bg-primary-default">
      <EmptyState
        illustration={
          <img alt="" src={illustrationSrc} className={styles.illustration} />
        }
        title={title}
        subtitle={description}
        actions={[
          <Button
            key="1"
            variant="primaryBrand"
            text={buttonLabel}
            isDisabled={isDisabled}
            onClick={onClick}
          />,
        ]}
        className="py-16"
      />
    </div>
  );
};
