/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';

/**
 * @public
 */
export type RawSupplier = {
  id: string;
  name: string;
  thumbnailUrl?: string;
};

/**
 * @public
 */
export type Supplier = {
  id: string;
  name: string;
  thumbnailUrl?: string;
};

export type SearchSuppliersRawResponse = RawSupplier[];

export const useSearchSuppliers = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  return async (search: string, limit?: number): Promise<Supplier[]> => {
    const data = await queryClient.fetchQuery<SearchSuppliersRawResponse>(
      ['useSearchSuppliers', companyId, search],
      getFetcher({
        companyId,
        getRequest: () => ({
          type: 'rest',
          target: 'companyAPI',
          endpoint: `/suppliers`,
          params: {
            search,
            limit,
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return data.map((supplier) => ({
      id: supplier.id,
      name: supplier.name,
      thumbnailUrl: supplier.thumbnailUrl,
    }));
  };
};
