import { Button, FormField, Modal, TextInput } from '@dev-spendesk/grapes';
import { useFormik, type FormikErrors } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { type TeamRaw } from 'modules/members/models/teams';

type Props = {
  groups?: TeamRaw[];
  group: TeamRaw;
  onEditName: (groupName: string) => Promise<void>;
  onCancel: () => void;
};

export const EditTeamNameModal = ({
  groups = [],
  group,
  onEditName,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');

  const formikProps = useFormik<{ name: string }>({
    initialValues: {
      name: group.name ?? '',
    },
    validateOnChange: false,
    validate: (values) => {
      const errors: FormikErrors<{ name: string }> = {};
      if (!values.name.trim()) {
        errors.name = t('misc.requiredField');
      }
      if (isNameAlreadyTaken(values.name)) {
        errors.name = t('members.nameAlreadyTaken');
      }
      return errors;
    },
    onSubmit: async (values) => {
      await onEditName(values.name);
    },
  });

  const isNameAlreadyTaken = (name: string) => {
    const teamsMinusCurrentOne = groups.filter((g) => g.id !== group.id);
    return teamsMinusCurrentOne.some(
      (g) => g.name?.toLowerCase() === name.toLowerCase(),
    );
  };

  return (
    <Modal
      isOpen
      iconName="pen"
      iconVariant="info"
      title={t('members.editTeamName')}
      onClose={onCancel}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('members.discardChanges')}
          onClick={onCancel}
        />,
        <Button
          key="saveChanges"
          type="submit"
          text={t('misc.saveChanges')}
          form="edit-team-name"
          isLoading={formikProps.isSubmitting}
        />,
      ]}
    >
      <form id="edit-team-name" onSubmit={formikProps.handleSubmit}>
        <FormField
          className="text-left"
          label={t('members.teamName')}
          alertMessage={formikProps.errors.name}
        >
          <TextInput
            name="name"
            value={formikProps.values.name}
            placeholder={t('members.policyNameInputPlaceholder')}
            onChange={formikProps.handleChange}
          />
        </FormField>
      </form>
    </Modal>
  );
};
