import {
  Avatar,
  DropdownItem,
  DropdownMenu,
  IconButton,
} from '@dev-spendesk/grapes';

import { Divider } from 'common/components/Divider';
import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import {
  fallbackSupplierLogoSrc,
  getLogoUrlFromName,
} from 'common/components/SupplierLogo';
import { SupplierSyncStatusForIntegration } from 'modules/bookkeep/integration/components/SupplierSyncStatusForIntegration';
import { type QueryState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { SupplierAvailableCredit } from './SupplierAvailableCredit';
import { SupplierOverviewFormFieldSkeleton } from './SupplierOverviewFormField/SupplierOverviewFormFieldSkeleton';
import { useFetchSupplier } from '../hooks';
import { type SupplierDetails, type SupplierId } from '../models';

type SupplierOverviewOption = {
  key: string;
  label: string;
  action(): void;
};

type Props = {
  options: SupplierOverviewOption[];
  rightAddon?: React.ReactNode;
} & (
  | { supplierId: SupplierId; supplier?: undefined }
  | { supplierId?: undefined; supplier: SupplierDetails }
) &
  (
    | {
        showAvailableCredit?: undefined;
        availableCreditCurrency?: undefined;
      }
    | {
        showAvailableCredit: boolean;
        availableCreditCurrency: string;
      }
  );

export const SupplierOverview = ({
  supplierId,
  supplier,
  options,
  showAvailableCredit = false,
  availableCreditCurrency,
  rightAddon,
}: Props) => {
  const { t } = useTranslation('global');
  const supplierQueryState = useFetchSupplier(supplierId);

  return (
    <QuerySuspense
      queryState={
        (supplierId
          ? supplierQueryState
          : {
              status: 'success',
              data: { supplierDetails: supplier },
            }) as QueryState<{ supplierDetails: SupplierDetails }>
      }
      loading={<SupplierOverviewFormFieldSkeleton />}
      fallback={(queryError) => (
        <QueryError
          className="mt-24"
          queryError={queryError}
          componentType="Callout"
          translations={{
            requestError: () => 'submitMyInvoice.supplier.error',
            serverError: 'submitMyInvoice.supplier.error',
          }}
        />
      )}
    >
      {({ supplierDetails }) => (
        <>
          <div className="flex items-center justify-between gap-x-8">
            <div className="flex min-h-[40px] items-center gap-x-8">
              <Avatar
                className="mt-[2px] flex-none"
                variant="square"
                src={
                  supplierDetails.thumbnailUrl ??
                  getLogoUrlFromName(supplierDetails.name)
                }
                fallbackSrc={fallbackSupplierLogoSrc}
                text={supplierDetails.name}
              />
              <div className="leading-4 text-primary title-l">
                {supplierDetails.name}
              </div>
            </div>
            {rightAddon && (
              <div className="group-hover/supplier-overview:hidden">
                {rightAddon}
              </div>
            )}
            {options.length > 0 && (
              <DropdownMenu<SupplierOverviewOption>
                className="hidden group-hover/supplier-overview:block"
                options={options}
                placement="bottom-end"
                renderOption={(option) => {
                  return <DropdownItem label={option.label} />;
                }}
                renderButton={(getToggleButtonProps) => {
                  return (
                    <IconButton
                      {...getToggleButtonProps()}
                      iconName="ellipsis-horizontal"
                      variant="tertiaryNeutral"
                      aria-label={t('misc.openMenu')}
                    />
                  );
                }}
                onSelect={(option) => option?.action()}
              />
            )}
          </div>
          <Divider className="my-12" />

          {showAvailableCredit && availableCreditCurrency && (
            <SupplierAvailableCredit
              supplierId={supplierId ?? supplierDetails.id}
              availableCreditCurrency={availableCreditCurrency}
            />
          )}

          <SupplierSyncStatusForIntegration supplierId={supplierDetails.id} />
        </>
      )}
    </QuerySuspense>
  );
};
