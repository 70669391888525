import { Button, Modal } from '@dev-spendesk/grapes';
import { useQueryClient } from 'react-query';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import {
  NotificationType,
  useNotifications,
} from 'src/core/modules/app/notifications';

import { deleteSpendLimit } from '../../mutations/deleteSpendLimit';

type Props = {
  isOpen: boolean;
  onClose(): void;
  spendLimitId: string;
};

export const DeleteSpendLimitModal = ({
  isOpen,
  onClose,
  spendLimitId,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const queryClient = useQueryClient();
  const companyId = useCompanyId();

  const handleDelete = async () => {
    try {
      await deleteSpendLimit({ companyId, spendLimitId });
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('spendLimits.modal.deletion.error'),
      });
      return;
    }
    onClose();
    queryClient.invalidateQueries(['spend-limits']);
    pushNotif({
      type: NotificationType.Success,
      message: t('spendLimits.modal.deletion.success'),
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('spendLimits.modal.deletion.title')}
      iconName="cross"
      iconVariant="alert"
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          onClick={onClose}
          text={t('misc.cancel')}
        />,
        <Button
          key="delete"
          variant="primaryAlert"
          onClick={() => {
            handleDelete();
          }}
          text={t('misc.delete')}
        />,
      ]}
    >
      {t('spendLimits.modal.deletion.description')}
    </Modal>
  );
};
