import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { type EmailInvitationResult } from '../models/invite';
import { type AssignableRole } from '../models/roles';

type Payload = {
  emails: string;
  role?: AssignableRole[] | null;
  policy?: string[] | null;
  team?: string[] | null;
  default_cost_center_id?: string | null;
};

type Response = EmailInvitationResult[];

export const useSendInvitesMutation = (): MutationState<Payload, Response> => {
  return useMutation<Payload, Response, Response>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/invites',
    },
    reshapeData: (data) => data,
    options: {
      onSuccess: ({ client }) => {
        client.invalidateQueries(['invites']);
      },
    },
  });
};
