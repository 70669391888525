import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { routeFor, routes } from 'src/core/constants/routes';

import { type Integration, type IntegrationStatus } from '../../../types';
import { type PartnerIntegration } from '../../../types/partnerIntegration';
import { ConnectionModal } from '../ConnectionModal';
import { IntegrationsSection } from '../IntegrationsSection';
import { PartnerIntegrationsSection } from '../IntegrationsSection/PartnerIntegrationsSection';
import { TellUsBox } from '../TellUsBox';

type Props = {
  integrationStatus: IntegrationStatus;
  partnerIntegrations: PartnerIntegration[];
};

type ConnectionModalState =
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
      integration: Integration;
    };

const IntegrationsList = ({
  integrationStatus,
  partnerIntegrations,
}: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const history = useHistory();
  const [modalState, setModalState] = useState<ConnectionModalState>({
    isOpen: false,
  });
  const { active, available, unavailable } = integrationStatus;

  const navigate = (integration: Integration) => {
    history.push({
      pathname: routeFor(routes.COMPANY_INTEGRATION.path, {
        company: company.id,
        integration: integration.id,
      }),
    });
  };

  return (
    <>
      <div className="flex flex-col gap-40">
        {active.length > 0 ? (
          <IntegrationsSection
            key="activeIntegrations"
            title={t('integration.sections.active.title')}
            integrations={active}
            activeIntegrations
            availableIntegrations
            selectIntegration={(integration: Integration) =>
              navigate(integration)
            }
          />
        ) : null}
        <IntegrationsSection
          key="availableIntegrations"
          title={t('integration.sections.available.title')}
          integrations={available}
          activeIntegrations={false}
          availableIntegrations
          extra={[<TellUsBox key="tellUS" />]}
          selectIntegration={(integration: Integration) =>
            setModalState({
              isOpen: true,
              integration,
            })
          }
        />
        {unavailable.length > 0 ? (
          <IntegrationsSection
            title={t('integration.sections.unavailable.title')}
            integrations={unavailable}
            activeIntegrations={false}
            availableIntegrations={false}
            selectIntegration={(integration: Integration) =>
              navigate(integration)
            }
          />
        ) : null}
        <PartnerIntegrationsSection
          key="activePartnerIntegrations"
          title={t('integration.sections.partner.title')}
          integrations={partnerIntegrations}
        />
      </div>
      {modalState.isOpen ? (
        <ConnectionModal
          integration={modalState.integration}
          closeModal={() => setModalState({ isOpen: false })}
        />
      ) : null}
    </>
  );
};

export default withErrorBoundary({
  scope: 'integrations-list',
  team: 'api-integration',
})(IntegrationsList);
