import { Skeleton } from '@dev-spendesk/grapes';

import { CountryFlag } from 'src/core/common/components/CountryFlag/CountryFlag';
import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { getCountryFromCode } from 'src/core/config/country';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { EntityItemHeaderPin } from './EntityItemHeaderPin';
import { type EntityBasicInformation } from '../../../../types';

type Props = {
  entity: EntityBasicInformation;
  isPinned: boolean;
  togglePinnedEntity: (entityId: string) => void;
  isDataVisible: boolean;
  canAccessEntity?: boolean;
};

export const EntityItemHeader = ({
  entity: { country: countryCode, id, name },
  isPinned,
  togglePinnedEntity,
  isDataVisible,
  canAccessEntity = true,
}: Props) => {
  const { t } = useTranslation('global');

  if (!isDataVisible) {
    return <Skeleton width="100%" height="27px" />;
  }

  const country = getCountryFromCode(countryCode);
  const translatedCountry = country ? t(country.translationKey) : countryCode;

  return (
    <div className="flex items-center gap-12">
      <EntityItemHeaderPin
        isPinned={isPinned}
        togglePinnedEntity={() => {
          togglePinnedEntity(id);
        }}
      />
      <div className="flex items-center gap-8 overflow-hidden">
        {canAccessEntity ? (
          <a
            key="name"
            href={routeFor(routes.HOMEPAGE.path, {
              company: id,
            })}
            target="_blank"
            rel="noreferrer"
            className="truncate text-primary underline body-l"
            onClick={() =>
              track(
                AnalyticEventName.ORGANISATION_REPORTING_OPEN_ENTITY_BUTTON_CLICKED,
              )
            }
            aria-label={name}
          >
            <EllipsisTooltip text={name} />
          </a>
        ) : (
          <h3 className="truncate text-primary title-l">
            <EllipsisTooltip text={name} />
          </h3>
        )}
        <span className="w-[9px] text-center text-secondary-bg-secondary body-l">
          •
        </span>
        <div key="country" className="flex items-center gap-4">
          <p className="text-secondary-bg-secondary body-l">
            {translatedCountry}
          </p>
          <CountryFlag country={countryCode} className="h-[18px] w-[18px]" />
        </div>
      </div>
    </div>
  );
};
