import {
  Autocomplete,
  type AutocompleteProps,
  DropdownItem,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

// FIXME: this component should be moved to supplier scope
import { SupplierLogo } from 'common/components/SupplierLogo';
import BankProofUpload from 'modules/requests/invoice/creation/BankProofUpload/BankProofUpload';

import { getVisibleCallout } from './visibleCallout';
import { AutocompleteNoOptions } from '../../../../common/components/AutocompleteNoOptions';
import {
  useSuppliersSearch,
  type Supplier,
  type SupplierSearchOptions,
} from '../../../../common/hooks/useSearchSuppliers';
import {
  useFetchSupplierByName,
  useFetchSupplierByNameLazy,
} from '../../hooks/api/useFetchSupplierByName';
import {
  type BankDetailsSuggestion,
  type SupplierDetails,
  buildDraftSupplier,
  buildRawSupplierFromName,
} from '../../models';
import { SupplierArchivedCallout } from '../SupplierArchivedCallout';
import { SupplierDetailsModal } from '../SupplierDetailsModal';
import { NewSupplierCreationWarning } from '../SupplierOverviewFormField/NewSupplierCreationWarning';

export type SupplierAsyncAutocompleteProps = {
  showWarningWhenIsArchived?: boolean;
  showUnarchiveButtonWhenArchived?: boolean;
  withAddOption?: boolean;
} & (
  | {
      showSupplierCreationWhenNew?: false;
    }
  | ({
      showSupplierCreationWhenNew: true;
    } & SupplierAsyncAutocompletePropsWithSupplierCreation)
) &
  (
    | { showBankInfoUploadWhenNew?: false }
    | ({
        showBankInfoUploadWhenNew: true;
      } & SupplierAsyncAutocompletePropsWithBankInfoUpload)
  );

export type SupplierAsyncAutocompletePropsWithSupplierCreation = {
  documentaryEvidence?: React.ReactNode;
  bankDetailsSuggestion?: BankDetailsSuggestion;
  onCreateSupplierSuccess(supplier: SupplierDetails): void;
};

export type SupplierAsyncAutocompletePropsWithBankInfoUpload = {
  newSupplierIban?: File;
  onBankInfoUploaded: (files: File[]) => void;
  onBankInfoDeleted: () => void;
};

type Props = Omit<
  AutocompleteProps<Supplier>,
  'options' | 'isLoading' | 'renderOption' | 'renderPrefix' | 'onSearch'
> &
  Partial<SupplierSearchOptions> &
  SupplierAsyncAutocompleteProps;

export const SupplierAsyncAutocomplete = (props: Props) => {
  const {
    isInvoicePayable = true,
    includeArchived = false,
    withAddOption = true,
    value,
  } = props;

  const [isSupplierModalVisible, setIsSupplierModalVisible] = useState(false);

  const [suppliersLazyQueryState, addSupplier, handleSearch] =
    useSuppliersSearch({ isInvoicePayable, includeArchived });
  const suppliers =
    suppliersLazyQueryState.status === 'success'
      ? suppliersLazyQueryState.data
      : [];
  const supplierByNameQuery = useFetchSupplierByName(value?.label ?? '');
  // FIXME: remove when autocomplete works with full entities
  const fetchSupplierByName = useFetchSupplierByNameLazy();

  const visibleCallout = getVisibleCallout({
    isSupplierModalVisible,
    props,
    supplierByNameQuery,
  });

  // TODO: When there's an API call error, the autocomplete does not display properly the renderNoOptions because of the onAddOption which internally adds an option to the empty option array
  return (
    <>
      {/* @ts-expect-error not always having add option props causes an error */}
      <Autocomplete
        {...removeExtraProps(props)}
        value={value}
        isLoading={suppliersLazyQueryState.status === 'loading'}
        options={suppliers}
        onSearch={handleSearch}
        renderPrefix={(supplier) => {
          if (!supplier && !value) {
            return undefined;
          }
          let supplierName = '';
          if (supplier) {
            supplierName = supplier.key !== 'unknown' ? supplier.label : '';
          } else if (value) {
            supplierName = value.key !== 'unknown' ? value.label : '';
          }
          return <SupplierLogo size="s" name={supplierName} />;
        }}
        renderOption={(supplier, state) => (
          <DropdownItem
            {...state}
            label={supplier.label}
            prefix={<SupplierLogo size="s" name={supplier.label} />}
          />
        )}
        renderNoOptions={(rawValue) => (
          <AutocompleteNoOptions value={rawValue} />
        )}
        {...(withAddOption
          ? {
              onAddOption: async (newSupplierLabel: string) => {
                const supplierDetails =
                  await fetchSupplierByName(newSupplierLabel);

                if (supplierDetails) {
                  return {
                    key: supplierDetails.id,
                    label: newSupplierLabel,
                  };
                }

                const newSupplier = {
                  key: newSupplierLabel,
                  label: newSupplierLabel,
                };
                addSupplier(newSupplier);

                return newSupplier;
              },
              renderAddOption: (inputValue) => {
                return (
                  <DropdownItem
                    label={
                      <Trans
                        i18nKey="misc.createNewAutocompleteOption"
                        values={{ value: inputValue }}
                        components={[
                          <span key="so-unique" className="text-primary" />,
                        ]}
                      />
                    }
                  />
                );
              },
            }
          : {})}
      />
      {visibleCallout.kind === 'supplierCreationWarning' && (
        <NewSupplierCreationWarning
          bankDetailsSuggestion={visibleCallout.bankDetailsSuggestion}
          className="mt-16"
          draftSupplier={buildDraftSupplier({
            supplierName: visibleCallout.value.label,
          })}
          onCreate={() => setIsSupplierModalVisible(true)}
          onCreateSupplierSuccess={visibleCallout.onCreateSupplierSuccess}
        />
      )}
      {visibleCallout.kind === 'supplierArchivedWarning' && (
        <SupplierArchivedCallout
          showUnarchiveButtonWhenArchived={
            props.showUnarchiveButtonWhenArchived
          }
        />
      )}
      {visibleCallout.kind === 'bankInfoUpload' && (
        <BankProofUpload
          file={visibleCallout.newSupplierIban}
          fileMediaType={visibleCallout.newSupplierIban?.type}
          onUpload={visibleCallout.onBankInfoUploaded}
          onDelete={visibleCallout.onBankInfoDeleted}
        />
      )}
      {visibleCallout.kind === 'supplierCreationWarning' &&
        visibleCallout.showSupplierModal && (
          <SupplierDetailsModal
            documentaryEvidence={visibleCallout.documentaryEvidence}
            onClose={() => setIsSupplierModalVisible(false)}
            onCreateSupplierSuccess={(supplier) => {
              setIsSupplierModalVisible(false);
              props.onSelect({
                key: supplier.id,
                label: supplier.name,
              });
            }}
            supplier={{
              ...buildRawSupplierFromName(visibleCallout.value.label),
              supplier_details_suggestion:
                visibleCallout.bankDetailsSuggestion ?? null,
            }}
          />
        )}
    </>
  );
};

const removeExtraProps = (props: Props) => {
  if (props.showBankInfoUploadWhenNew) {
    const {
      isInvoicePayable,
      includeArchived,
      value,
      onBankInfoUploaded,
      onBankInfoDeleted,
      showBankInfoUploadWhenNew,
      newSupplierIban,
      showWarningWhenIsArchived,
      showUnarchiveButtonWhenArchived,
      ...rest
    } = props;
    return rest;
  }

  const {
    isInvoicePayable,
    includeArchived,
    value,
    showWarningWhenIsArchived,
    showUnarchiveButtonWhenArchived,
    ...rest
  } = props;
  return rest;
};
