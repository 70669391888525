import { type RegisteredUserExpenseBeneficiary } from '../expense/api/useExpenseBenefericiariesQuery';

export const getDisplayNameRegisteredBeneficiary = (
  beneficiary: RegisteredUserExpenseBeneficiary,
) => {
  if (
    beneficiary.registeredUser.firstName &&
    beneficiary.registeredUser.lastName
  ) {
    return `${beneficiary.registeredUser.firstName} ${beneficiary.registeredUser.lastName}`;
  }
  return beneficiary.registeredUser.emailAddress || '';
};
