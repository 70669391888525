import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';

import { SupplierBankInfoForm } from './SupplierBankInfoForm';
import { SupplierBankInfoFormSkeleton } from './SupplierBankInfoFormSkeleton';
import { useFetchSupplier, supplierErrorTranslations } from '../../hooks';
import {
  type BankDetailsSuggestion,
  type SupplierDetails,
  type SupplierId,
  type DraftSupplier,
  isUpdateMode,
} from '../../models';

type BaseProps = {
  className?: string;
  bankDetailsSuggestion?: BankDetailsSuggestion;
  onSupplierCreationOrUpdateSuccess?(supplier: SupplierDetails): void;
  onCreate?(): void;
};

type Props = BaseProps &
  (
    | {
        supplierId: SupplierId;
        draftSupplier?: never;
      }
    | {
        draftSupplier: DraftSupplier;
        supplierId?: never;
      }
  );

type CreationModeProps = BaseProps & { draftSupplier: DraftSupplier };
type UpdateModeProps = BaseProps & { supplierId: SupplierId };

/**
 * In case of a new supplier: provide the draftSupplier prop, from which the form will be initialised to.
 * In case an existing supplier: provide the supplierId prop. The component will make an api call to fetch the supplier data and fill the form.
 * @param {string} supplierId - existing supplierId
 * @param {DraftSupplier} draftSupplier - new supplier draft (or existing supplier from which the id is extracted)
 * @param {BankDetailsSuggestion} bankDetailsSuggestion - bank details extracted by the OCR
 */
export const SupplierBankInfoFormContainer = ({
  supplierId,
  draftSupplier,
  ...rest
}: Props) => {
  if (supplierId !== undefined) {
    return <OnUpdateMode supplierId={supplierId} {...rest} />;
  }

  return isUpdateMode(draftSupplier) ? (
    <OnUpdateMode supplierId={draftSupplier.id} {...rest} />
  ) : (
    <OnCreateMode draftSupplier={draftSupplier} {...rest} />
  );
};

const OnCreateMode = ({
  draftSupplier,
  className,
  ...rest
}: CreationModeProps) => {
  const { t } = useTranslation('global');

  return (
    <form
      aria-label={t('submitMyInvoice.fields.supplierForm.label')}
      className={className}
    >
      <SupplierBankInfoForm draftSupplier={draftSupplier} {...rest} />
    </form>
  );
};

const OnUpdateMode = ({ supplierId, className, ...rest }: UpdateModeProps) => {
  const { t } = useTranslation('global');
  const supplierQueryState = useFetchSupplier(supplierId);

  return (
    <form
      aria-label={t('submitMyInvoice.fields.supplierForm.label')}
      className={className}
    >
      <QuerySuspense
        queryState={supplierQueryState}
        loading={<SupplierBankInfoFormSkeleton />}
        fallback={(error) => (
          <QueryError
            queryError={error}
            componentType="ErrorState"
            translations={supplierErrorTranslations}
          />
        )}
      >
        {({ supplierDetails }) => (
          <SupplierBankInfoForm draftSupplier={supplierDetails} {...rest} />
        )}
      </QuerySuspense>
    </form>
  );
};
