import { useMemo, useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './VideoBlock.module.css';
import placeholderSource from './assets/placeholder.png';
import { VideoPageModal } from '../../../components/VideoPageModal';

type Props = {
  id: string;
  title: string;
  description: string;
};

export const VideoBlock = ({ id, title, description }: Props) => {
  const { activeLanguage } = useTranslation('global');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const url = useMemo(() => {
    switch (id) {
      case 'custom_fields': {
        if (activeLanguage === 'de') {
          return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/videos/control_settings-analytical_fields-de.mp4';
        }
        if (activeLanguage === 'fr') {
          return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/videos/control_settings-analytical_fields-fr.mp4';
        }
        if (activeLanguage === 'es') {
          return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/videos/Analytical+Fields+ES.mp4';
        }
        return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/videos/control_settings-analytical_fields-en.mp4';
      }
      case 'expense_categories': {
        if (activeLanguage === 'de') {
          return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/videos/control_settings-expense_categories-de.mp4';
        }
        if (activeLanguage === 'fr') {
          return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/videos/control_settings-expense_categories-fr.mp4';
        }
        if (activeLanguage === 'es') {
          return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/videos/Expense+Categories+ES.mp4';
        }
        return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/videos/control_settings-expense_categories-en.mp4';
      }
      default:
        return null;
    }
  }, [activeLanguage, id]);

  if (!url) {
    return null;
  }

  return (
    <>
      <div className="flex max-w-[428px] flex-col items-center">
        <button
          aria-label={title}
          className={styles.placeholder}
          onClick={() => setIsModalOpen(true)}
          type="button"
        >
          <img
            className="w-full rounded-4"
            src={placeholderSource}
            alt={title}
          />
        </button>
        <p className="mb-24 text-center title-xl">{title}</p>
        <p className="text-left text-secondary-bg-primary body-m">
          {description}
        </p>
      </div>
      <VideoPageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={title}
        url={url}
      />
    </>
  );
};
