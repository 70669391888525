import { SkeletonText, SkeletonButton, Skeleton } from '@dev-spendesk/grapes';
import React from 'react';

import withErrorBoundary from 'common/components/withErrorBoundary';

import { IntegrationBenefitsBoxSkeleton } from '../IntegrationBenefitsBox';

const IntegrationDetailsSkeleton = () => {
  return (
    <div className="flex grow justify-center bg-primary-default px-0 py-40">
      <div className="w-[1200px]">
        <div className="flex flex-col gap-24">
          <SkeletonButton className="self-start" />
          <div className="flex flex-row items-center gap-16">
            <div className="flex h-[88px] w-[88px]">
              <Skeleton width="88px" height="88px" />
            </div>
            <div className="mr-auto flex gap-16 text-left">
              <SkeletonText size="xl" width="250px" className="self-center" />
              <Skeleton height="32px" width="77px" className="self-center" />
            </div>

            <SkeletonText size="m" width="100px" />
            <SkeletonButton />
          </div>
          <div className="flex flex-col gap-8">
            <SkeletonText size="m" />
            <SkeletonText size="m" />
            <SkeletonText size="m" />
            <SkeletonText size="m" width="50%" />
          </div>
          <IntegrationBenefitsBoxSkeleton />
          <SkeletonButton className="self-start" />
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary({
  scope: 'integration-details-skeleton',
  team: 'api-integration',
})(IntegrationDetailsSkeleton);
