import {
  Skeleton,
  SkeletonAvatar,
  SkeletonCheckbox,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';

export const RequestsListLoader = () => (
  <div className="mt-24 flex h-full w-full flex-col bg-primary-default">
    <SkeletonTable
      numberOfRows={14}
      columns={[
        {
          cell: (
            <div className="flex w-full items-center justify-between">
              <div className="flex grow items-center">
                <SkeletonCheckbox className="mr-16" />
                <SkeletonAvatar size="m" variant="square" className="mr-16" />
                <Skeleton height="var(--unit-32)" width="60%" />
              </div>
              <Skeleton height="var(--unit-32)" width="150px" />
            </div>
          ),
          header: (
            <div className="flex w-full justify-between">
              <SkeletonText width="25%" />
              <SkeletonText width="200px" />
            </div>
          ),
        },
      ]}
      withHeader
    />
  </div>
);
