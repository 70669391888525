import {
  Button,
  Callout,
  DatePicker,
  FormField,
  Input,
  TextArea,
} from '@dev-spendesk/grapes';
import { startOfTomorrow } from 'date-fns';
import { useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import ConsentForm from './ConsentForm';
import ScopesForm from './ScopesForm';
import withErrorBoundary from '../../../../../../common/components/withErrorBoundary';
import { routeFor, routes } from '../../../../../../constants/routes';
import { type PublicApiScopeListEntry } from '../../../../domain/public-api/scopes';
import {
  type KeyFormData,
  getOneYearAfterNow,
  validateKeyValues,
  type ApiKeyListItem,
  toUTCDate,
} from '../domain';
import { type CreateApiKeyResponse, useCreateApiKey } from '../hooks';

type Props = {
  scopes: PublicApiScopeListEntry[];
  duplicateKeySource?: ApiKeyListItem;
  onKeyCreated: (keyResult: CreateApiKeyResponse) => void;
};

const NewApiAccess = ({ scopes, duplicateKeySource, onKeyCreated }: Props) => {
  const companyId = useCompanyId();
  const history = useHistory();
  const [createKey] = useCreateApiKey();
  const { t } = useTranslation('global');
  const availableScopeKeys = new Set(scopes.map(({ name }) => name));

  const formState = useFormik<KeyFormData>({
    initialValues: {
      name: duplicateKeySource?.name ?? '',
      scopes: new Set(
        duplicateKeySource?.scope
          .split(' ')
          .filter((scope) => availableScopeKeys.has(scope)) ?? [],
      ),
      expiredAt: undefined,
      description: duplicateKeySource?.description ?? '',
      hasExpressedConsent: false,
    },
    validateOnChange: false,
    onSubmit: async (values, helpers) => {
      const errors = validateKeyValues(values);

      if (errors) {
        helpers.setErrors(errors);
        return;
      }

      const { expiredAt } = values;

      const createKeyResult = await createKey({
        ...values,
        scope: [...values.scopes].join(' '),
        name: values.name.trim(),
        description: values.description.trim(),
        // Safe, because we have checked the preconditions above
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        expiredAt: toUTCDate(expiredAt!),
      });

      onKeyCreated(createKeyResult);

      history.push(
        routeFor(routes.COMPANY_INTEGRATIONS_API_ACCESS_MANAGEMENT.path, {
          company: companyId,
        }),
      );
    },
  });

  return (
    <>
      <Button
        variant="tertiaryNeutral"
        text={t('publicApi.flowApiKey.main.back')}
        iconName="chevron-left"
        iconPosition="left"
        fit="content"
        onClick={() => {
          history.push(
            routeFor(routes.COMPANY_INTEGRATIONS_API_ACCESS_MANAGEMENT.path, {
              company: companyId,
            }),
          );
        }}
      />
      <h2 className="mt-16 title-xl">
        {t('publicApi.flowApiKey.create.title')}
      </h2>
      <p className="mt-8 text-secondary-bg-primary body-m">
        {t('publicApi.flowApiKey.create.description')}
      </p>
      <Callout
        variant="info"
        title={t('publicApi.flowApiKey.create.info.title')}
        className="mt-16"
      >
        {t('publicApi.flowApiKey.create.info.description')}
      </Callout>
      <div className="w-100 box mt-16 flex flex-col">
        <h3 className="mb-24 title-m">
          {t('publicApi.flowApiKey.create.details.title')}
        </h3>
        <div className="mb-16 flex flex-row">
          <FormField
            className="mr-16 w-[50%]"
            label={t('misc.name')}
            alertMessage={formState.errors.name}
            data-testid="api-key-name-field"
          >
            <Input
              type="text"
              placeholder={t('publicApi.flowApiKey.create.details.name')}
              value={formState.values.name}
              onChange={(event) => {
                formState.setFieldValue('name', event.target.value);
              }}
            />
          </FormField>
          <div className="flex w-[50%] flex-row">
            <FormField
              label={t('publicApi.flowApiKey.create.details.expire.label')}
              className="grow"
              alertMessage={formState.errors.expiredAt}
              data-testid="api-key-expired-at-field"
            >
              <div className="flex flex-row">
                <div className="mr-8 grow">
                  <DatePicker
                    fit="parent"
                    minDate={startOfTomorrow()}
                    maxDate={getOneYearAfterNow()}
                    placeholder={t(
                      'publicApi.flowApiKey.create.details.expire.placeholder',
                    )}
                    value={formState.values.expiredAt}
                    onChange={(value) => {
                      formState.setFieldValue('expiredAt', value);
                    }}
                  />
                </div>
                <Button
                  variant="tertiaryNeutral"
                  fit="content"
                  text={t('publicApi.flowApiKey.create.details.expire.button')}
                  className="grow-0"
                  onClick={() => {
                    formState.setFieldValue('expiredAt', getOneYearAfterNow());
                  }}
                />
              </div>
            </FormField>
          </div>
        </div>
        <FormField
          label={t('misc.description')}
          alertMessage={formState.errors.description}
          data-testid="api-key-description-field"
        >
          <TextArea
            placeholder={t('publicApi.flowApiKey.create.details.description')}
            value={formState.values.description}
            onChange={(event) => {
              formState.setFieldValue('description', event.target.value);
            }}
          />
        </FormField>
        <div className="separator my-24" />

        <ScopesForm
          mode="edit"
          scopes={scopes}
          selectedScopes={formState.values.scopes}
          onScopeChange={(scopeName) => {
            const previousSet = new Set(formState.values.scopes);

            if (previousSet.has(scopeName)) {
              previousSet.delete(scopeName);
              formState.setFieldValue('scopes', previousSet);
              return;
            }

            formState.setFieldValue('scopes', previousSet.add(scopeName));
          }}
          formError={formState.errors.scopes as string}
        />
        <ConsentForm
          mode="edit"
          hasExpressedConsent={formState.values.hasExpressedConsent}
          onConsentChange={() => {
            formState.setFieldValue(
              'hasExpressedConsent',
              !formState.values.hasExpressedConsent,
            );
          }}
          formError={formState.errors.hasExpressedConsent as string}
        />
        <div className="mt-24">
          <Button
            fit="content"
            text={t('publicApi.flowApiKey.create.details.button')}
            onClick={() => {
              formState.submitForm();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default withErrorBoundary({
  scope: 'create-api-key',
  team: 'api-integration',
})(NewApiAccess);
