import { Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { type AccountingSoftware } from 'src/core/modules/bookkeep/integration/status';
import {
  getCounterpartyMembersCalloutText,
  getCounterpartyMembersCalloutTitle,
  getCounterpartyMembersTitle,
} from 'src/core/modules/bookkeep/payables/components/PayablePanel/translations';

import {
  SupplierOrMemberSyncStatus,
  type SupplierOrMemberSyncStatusProps,
} from '../../../bookkeep/components/SupplierOrMemberSyncStatus';

type Props = {
  status: { synced: boolean; url: string | null };
  integrationName?: AccountingSoftware;
};

export function MemberSyncStatus({ status, integrationName }: Props) {
  const { synced, url } = status;
  const { t } = useTranslation('global');

  const syncStatus: SupplierOrMemberSyncStatusProps = {
    anchor: 'none',
    synced,
    label: getCounterpartyMembersTitle(t, integrationName).synced,
    url,
  };

  return (
    <>
      <div className="my-16 flex items-center justify-center">
        <SupplierOrMemberSyncStatus {...syncStatus} />
      </div>
      {!synced && (
        <Callout title={getCounterpartyMembersCalloutTitle(t, integrationName)}>
          <p>{getCounterpartyMembersCalloutText(t, integrationName)}</p>
        </Callout>
      )}
    </>
  );
}
