import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ProtectedRoute } from 'common/components/ProtectedRoute';
import { useUser } from 'modules/app/hooks/useUser';
import { GraphQLProvider, useIntegrationStatusQuery } from 'modules/bookkeep';
import { isIntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { AccountingExportsSettings } from 'modules/onboarding/setup-hub/pages/Accounting/AccountingExportsSettings';
import { routes as globalRoutes } from 'src/core/constants/routes';

import { AccountingBankAccounts } from './Accounting/AccountingBankAccounts';
import { AccountingEmployeeAccounts } from './Accounting/AccountingEmployeeAccounts';
import { AccountingExpenseAccounts } from './Accounting/AccountingExpenseAccounts';
import { AccountingExpenseAccountsList } from './Accounting/AccountingExpenseAccountsList';
import { AccountingExportsColumns } from './Accounting/AccountingExportsColumns';
import { AccountingReceiptFileName } from './Accounting/AccountingReceiptFileName';
import { AccountingReverseChargeAccounts } from './Accounting/AccountingReverseChargeAccounts';
import { AccountingSoftware } from './Accounting/AccountingSoftware';
import { AccountingSupplierAccounts } from './Accounting/AccountingSupplierAccounts';
import { AccountingSupplierAccountsDefault } from './Accounting/AccountingSupplierAccountsDefault';
import { AccountingSupplierAccountsList } from './Accounting/AccountingSupplierAccountsList';
import { AccountingVatAccounts } from './Accounting/AccountingVatAccounts';
import { ControlSettingsControlRules } from './ControlSettings/ControlSettingsControlRules';
import { ControlSettingsCustomFieldList } from './ControlSettings/ControlSettingsCustomFieldList';
import { ControlSettingsCustomFieldName } from './ControlSettings/ControlSettingsCustomFieldName';
import { ControlSettingsCustomFieldValues } from './ControlSettings/ControlSettingsCustomFieldValues';
import { ControlSettingsExpenseCategoryList } from './ControlSettings/ControlSettingsExpenseCategoryList';
import { ControlSettingsNewExpenseCategory } from './ControlSettings/ControlSettingsNewExpenseCategory';
import { SetupHubPage } from './SetupHubPage';
import { routes } from './routes';

export const SetupHubRoutes = () => {
  const user = useUser();
  const isController = user.is_controller || user.is_account_owner;

  const integrationStatusQueryState = useIntegrationStatusQuery();
  const hasAccountingIntegration =
    integrationStatusQueryState.status === 'loading' ||
    (integrationStatusQueryState.status === 'success' &&
      isIntegrationStatusWithIntegration(integrationStatusQueryState.data));

  const canAccessAccounting = isController && hasAccountingIntegration;
  const canAccessControlSettings = user.is_admin || user.is_account_owner;

  const redirectTo = { pathname: globalRoutes.SETUP_HUB.path };

  return (
    <GraphQLProvider>
      <Switch>
        <Route
          exact
          path={[routes.SETUP_HUB_TASK_LIST.path]}
          component={SetupHubPage}
        />

        <ProtectedRoute
          path={routes.CONTROL_SETTINGS_NEW_EXPENSE_CATEGORY.path}
          exact
          isAccessAllowed={canAccessControlSettings}
          redirectTo={redirectTo}
        >
          <ControlSettingsNewExpenseCategory />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.CONTROL_SETTINGS_EXPENSE_CATEGORY_LIST.path}
          exact
          isAccessAllowed={canAccessControlSettings}
          redirectTo={redirectTo}
        >
          <ControlSettingsExpenseCategoryList />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.CONTROL_SETTINGS_CUSTOM_FIELD_NAME.path}
          exact
          isAccessAllowed={canAccessControlSettings}
          redirectTo={redirectTo}
        >
          <ControlSettingsCustomFieldName />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.CONTROL_SETTINGS_CUSTOM_FIELD_VALUES.path}
          exact
          isAccessAllowed={canAccessControlSettings}
          redirectTo={redirectTo}
        >
          <ControlSettingsCustomFieldValues />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.CONTROL_SETTINGS_CUSTOM_FIELD_LIST.path}
          exact
          isAccessAllowed={canAccessControlSettings}
          redirectTo={redirectTo}
        >
          <ControlSettingsCustomFieldList />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.CONTROL_SETTINGS_CONTROL_RULES.path}
          exact
          isAccessAllowed={canAccessControlSettings}
          redirectTo={redirectTo}
        >
          <ControlSettingsControlRules />
        </ProtectedRoute>

        <ProtectedRoute
          path={routes.ACCOUNTING_SOFTWARE.path}
          exact
          isAccessAllowed={isController}
          redirectTo={redirectTo}
        >
          <AccountingSoftware />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.ACCOUNTING_BANK_ACCOUNT.path}
          exact
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingBankAccounts />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.ACCOUNTING_EXPENSE_ACCOUNTS.path}
          exact
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingExpenseAccounts />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.ACCOUNTING_EXPENSE_ACCOUNTS_LIST.path}
          exact
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingExpenseAccountsList />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.ACCOUNTING_VAT_ACCOUNTS.path}
          exact
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingVatAccounts />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.ACCOUNTING_REVERSE_CHARGE_ACCOUNTS.path}
          exact
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingReverseChargeAccounts />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.ACCOUNTING_EMPLOYEE_ACCOUNTS.path}
          exact
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingEmployeeAccounts />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.ACCOUNTING_SUPPLIER_ACCOUNTS.path}
          exact
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingSupplierAccounts />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.ACCOUNTING_SUPPLIER_ACCOUNTS_LIST.path}
          exact
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingSupplierAccountsList />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.ACCOUNTING_SUPPLIER_ACCOUNTS_DEFAULT.path}
          exact
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingSupplierAccountsDefault />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.ACCOUNTING_EXPORT_SETTINGS.path}
          exact
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingExportsSettings />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={routes.ACCOUNTING_RECEIPT_FILE_NAME.path}
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingReceiptFileName />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={routes.ACCOUNTING_EXPORT_COLUMNS.path}
          isAccessAllowed={canAccessAccounting}
          redirectTo={redirectTo}
        >
          <AccountingExportsColumns />
        </ProtectedRoute>

        <Redirect to={routes.SETUP_HUB_TASK_LIST.path} />
      </Switch>
    </GraphQLProvider>
  );
};
