import {
  Button,
  FormField,
  SkeletonText,
  SwitchField,
  TextInput,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type AccountPayable,
  type DefaultEmployeeAccount,
  getDefaultEmployeeAccountName,
} from 'modules/bookkeep';
import { type SupplierAccount } from 'modules/bookkeep/accounts-payable/types';
import { useHasAuxiliaryAccountsEnabled } from 'modules/bookkeep/hooks';
import {
  type MutationQueryState,
  type QueryState,
} from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { EmployeeAccountAdvice } from '../../../../../../../components/AccountAdvice';
import { type IntegrationStatusWithIntegration } from '../../../../../../../integration/status';
import { type Result as GetDefaultEmployeeAccountQueryResult } from '../../../../../hooks/useGetDefaultEmployeeAccountQuery';
import { type Result as SetDefaultEmployeeAccountMutationResult } from '../../../../../hooks/useSetDefaultEmployeeAccountMutation';
import { CodeInUseCallout } from '../../../components/CodeInUseCallout/CodeInUseCallout';
import { DefaultAccountDeleteConfirmationModal } from '../../../components/DefaultAccountConfirmationModal/DefaultAccountDeleteConfirmationModal';
import { DefaultAccountEditConfirmationModal } from '../../../components/DefaultAccountConfirmationModal/DefaultAccountEditConfirmationModal';
import {
  accountKindToI18nKey,
  getCodeInUseLinkForAccountsPayable,
  validateAccountPayable,
} from '../../../utils';
import styles from '../EmployeeAccountsSection.module.css';

type ErrorState = {
  error: 'codeAlreadyExists' | 'invalidPattern' | 'required' | 'unknown';
  existingAccount?: AccountPayable;
};

function getErrorTranslation(
  errorState: ErrorState,
  t: TGlobalFunctionTyped,
): string | undefined {
  switch (errorState.error) {
    case 'required':
      return t(
        'bookkeep.integrations.settings.defaultEmployeeAccountForm.requiredError',
      );
    case 'unknown':
      return t('misc.loadingError');
    default:
      return undefined;
  }
}

interface Props {
  onSetDefault: (
    defaultEmployeeAccount: DefaultEmployeeAccount,
  ) => Promise<void>;
  defaultEmployeeAccount: DefaultEmployeeAccount | undefined;
  getSupplierAccountsQueryState: QueryState<SupplierAccount[], unknown>;
  getDefaultEmployeeAccountQueryState: QueryState<GetDefaultEmployeeAccountQueryResult>;
  setDefaultEmployeeAccountQueryState: MutationQueryState<SetDefaultEmployeeAccountMutationResult>;
  accountLength: number;
  integrationStatus: IntegrationStatusWithIntegration;
  isInitialToggleChecked: boolean;
}

export const DefaultEmployeeAccountForm = ({
  onSetDefault,
  defaultEmployeeAccount,
  getSupplierAccountsQueryState,
  getDefaultEmployeeAccountQueryState,
  setDefaultEmployeeAccountQueryState,
  accountLength,
  integrationStatus,
  isInitialToggleChecked,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  const [newDefaultEmployeeAccount, setNewDefaultEmployeeAccount] = useState(
    getInitialDefaultAccount(integrationStatus, defaultEmployeeAccount),
  );
  const [errorState, setErrorState] = useState<ErrorState | undefined>();
  const [inputChanged, setInputChanged] = useState(false);
  const [valueChanged, setValueChanged] = useState<{
    generalAccountCode: boolean;
    auxiliaryAccountCode: boolean;
  }>({
    generalAccountCode: false,
    auxiliaryAccountCode: false,
  });
  const [isToggleChecked, setIsToggleChecked] = useState(
    isInitialToggleChecked,
  );

  useEffect(() => {
    setIsToggleChecked(isInitialToggleChecked);
  }, [isInitialToggleChecked]);

  useEffect(() => {
    setValueChanged({ generalAccountCode: false, auxiliaryAccountCode: false });
    setNewDefaultEmployeeAccount(
      getInitialDefaultAccount(integrationStatus, defaultEmployeeAccount),
    );
  }, [defaultEmployeeAccount]);

  const [modalState, setModalState] = useState<{
    kind: 'closed' | 'confirmEdit' | 'confirmDelete';
  }>({ kind: 'closed' });

  useEffect(() => {
    if (
      defaultEmployeeAccount &&
      !!integrationStatus?.settingsValidation?.employeeAccounts
    ) {
      const defaultAccountServerSideValidationErrors = (
        integrationStatus?.settingsValidation?.employeeAccounts || []
      ).filter(
        (error) =>
          error.error === 'invalidAccount' &&
          error.id &&
          defaultEmployeeAccount.id &&
          error.id === defaultEmployeeAccount.id,
      );

      if (defaultAccountServerSideValidationErrors.length === 1) {
        setErrorState({
          error: 'invalidPattern',
          existingAccount: {
            ...defaultEmployeeAccount,
            kind: 'employeeAccount',
            id: defaultEmployeeAccount.id ?? '',
          },
        });
      } else {
        setErrorState(undefined);
      }
    }
  }, [
    defaultEmployeeAccount,
    integrationStatus.integration,
    integrationStatus.settingsValidation.employeeAccounts,
  ]);

  useEffect(() => {
    if (
      setDefaultEmployeeAccountQueryState.status === 'success' &&
      setDefaultEmployeeAccountQueryState.data.outcome === 'notSet' &&
      setDefaultEmployeeAccountQueryState.data.reason === 'codeAlreadyExists'
    ) {
      setErrorState({
        error: 'codeAlreadyExists',
        existingAccount:
          setDefaultEmployeeAccountQueryState.data.existingAccount,
      });
    } else if (
      setDefaultEmployeeAccountQueryState.status === 'error' ||
      (setDefaultEmployeeAccountQueryState.status === 'success' &&
        setDefaultEmployeeAccountQueryState.data.outcome === 'notSet')
    ) {
      setErrorState({
        error: 'unknown',
      });
    }
  }, [setDefaultEmployeeAccountQueryState]);
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();
  const handleSwitch = () => {
    if (defaultEmployeeAccount) {
      if (isToggleChecked) {
        setModalState({ kind: 'confirmDelete' });
        return;
      }

      onSetDefault({
        ...defaultEmployeeAccount,
        isArchived: isToggleChecked,
      });
    }
    setIsToggleChecked(!isToggleChecked);
  };

  const handleSubmit = () => {
    const result = validateAccountPayable(
      newDefaultEmployeeAccount.generalAccountCode,
      newDefaultEmployeeAccount.auxiliaryAccountCode,
      accountLength,
      getSupplierAccountsQueryState,
      { status: 'loading' },
      integrationStatus.integration,
      auxiliaryAccountsEnabled,
    );

    setInputChanged(false);

    if (result.outcome === 'valid') {
      setErrorState(undefined);

      if (!defaultEmployeeAccount) {
        setValueChanged({
          generalAccountCode: false,
          auxiliaryAccountCode: false,
        });
        onSetDefault(newDefaultEmployeeAccount);
        return;
      }

      // don't do anything if the employee account hasn't change
      if (
        auxiliaryAccountsEnabled &&
        (defaultEmployeeAccount.generalAccountCode !==
          newDefaultEmployeeAccount.generalAccountCode ||
          defaultEmployeeAccount.auxiliaryAccountCode !==
            newDefaultEmployeeAccount.auxiliaryAccountCode)
      ) {
        setModalState({ kind: 'confirmEdit' });
      } else if (
        defaultEmployeeAccount.generalAccountCode !==
        newDefaultEmployeeAccount.generalAccountCode
      ) {
        setModalState({ kind: 'confirmEdit' });
      }
      return;
    }

    switch (result.reason) {
      case 'required':
      case 'invalidPattern': {
        setErrorState({
          error: result.reason,
        });
        return;
      }
      case 'codeAlreadyExists': {
        setErrorState({
          error: 'codeAlreadyExists',
          existingAccount: result.existingAccount,
        });
        return;
      }
      default:
        rejectUnexpectedValue('defaultEmployeeAccountValidation', result);
    }
  };

  if (getDefaultEmployeeAccountQueryState.status === 'loading') {
    return (
      <div className="box mt-24">
        <SkeletonText size="xl" />
        <SkeletonText size="m" />
      </div>
    );
  }

  const isAuxiliaryFieldErrored =
    !!errorState && errorState.error !== 'required' && !inputChanged;
  const isSubmittable =
    valueChanged.generalAccountCode || valueChanged.auxiliaryAccountCode;
  return (
    <div className="box mt-24">
      <SwitchField
        fit="parent"
        id="useDefaultAccount"
        name="useDefaultAccount"
        label={t(
          'bookkeep.integrations.settings.defaultEmployeeAccountForm.title',
        )}
        helpText={t(
          'bookkeep.integrations.settings.defaultEmployeeAccountForm.description',
        )}
        isChecked={isToggleChecked && !!defaultEmployeeAccount}
        onChange={handleSwitch}
      />
      {isToggleChecked && (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.defaultForm__inputWrapper}>
            <FormField
              className={classNames(
                'general-account-field',
                styles.defaultForm__input,
              )}
              label={
                auxiliaryAccountsEnabled
                  ? t(
                      'bookkeep.integrations.settings.defaultEmployeeAccountForm.generalAccountCode',
                    )
                  : t(
                      'bookkeep.integrations.settings.defaultEmployeeAccountForm.name',
                    )
              }
              htmlFor="set-general-account-input"
              alertMessage={
                !!errorState && !inputChanged
                  ? getErrorTranslation(errorState, t)
                  : undefined
              }
            >
              <TextInput
                placeholder={t(
                  'bookkeep.integrations.settings.defaultEmployeeAccountForm.placeholder',
                )}
                isInvalid={!!errorState && !inputChanged}
                value={newDefaultEmployeeAccount?.generalAccountCode ?? ''}
                onChange={(e) => {
                  const generalAccountCode = e.target.value;
                  setNewDefaultEmployeeAccount({
                    ...newDefaultEmployeeAccount,
                    generalAccountCode,
                    isArchived: false,
                  });
                  setInputChanged(true);
                  setValueChanged({
                    ...valueChanged,
                    generalAccountCode:
                      defaultEmployeeAccount?.generalAccountCode !==
                      generalAccountCode,
                  });
                  setErrorState(undefined);
                }}
                id="set-general-account-input"
              />
            </FormField>

            {auxiliaryAccountsEnabled ? (
              <FormField
                hint="Optional"
                className={classNames(
                  'auxiliary-account-field',
                  styles.defaultForm__input,
                )}
                label={t(
                  'bookkeep.integrations.settings.defaultEmployeeAccountForm.auxiliaryAccountName',
                )}
                htmlFor="set-auxiliary-account-input"
                alertMessage={
                  isAuxiliaryFieldErrored
                    ? getErrorTranslation(errorState, t)
                    : undefined
                }
              >
                <TextInput
                  placeholder={t(
                    'bookkeep.integrations.settings.defaultEmployeeAccountForm.placeholder',
                  )}
                  isInvalid={isAuxiliaryFieldErrored}
                  value={newDefaultEmployeeAccount?.auxiliaryAccountCode}
                  onChange={(e) => {
                    const auxiliaryAccountCode = e.target.value;
                    setNewDefaultEmployeeAccount({
                      ...newDefaultEmployeeAccount,
                      auxiliaryAccountCode,
                      isArchived: false,
                    });
                    setInputChanged(true);
                    setValueChanged({
                      ...valueChanged,
                      auxiliaryAccountCode:
                        defaultEmployeeAccount?.auxiliaryAccountCode !==
                        auxiliaryAccountCode,
                    });
                    setErrorState(undefined);
                  }}
                  id="set-auxiliary-account-input"
                />
              </FormField>
            ) : (
              <div className={styles.defaultForm__input} />
            )}
          </div>

          {(!errorState ||
            errorState.error !== 'codeAlreadyExists' ||
            inputChanged) && (
            <EmployeeAccountAdvice
              className={styles.formCallout}
              integrationStatus={integrationStatus}
              showError={!!errorState && !inputChanged}
              title={
                !!errorState && !inputChanged
                  ? t(
                      'bookkeep.integrations.datev.accountCodeAdvice.defaultEmployeeAccount.title.warning',
                    )
                  : t(
                      'bookkeep.integrations.datev.accountCodeAdvice.defaultEmployeeAccount.title.info',
                    )
              }
            />
          )}

          {errorState &&
            errorState.error === 'codeAlreadyExists' &&
            errorState.existingAccount &&
            !inputChanged && (
              <CodeInUseCallout
                className={styles.formCallout}
                accountInfo={`${t(
                  accountKindToI18nKey[errorState.existingAccount.kind],
                )} - ${errorState.existingAccount.generalAccountCode}`}
                linkTo={getCodeInUseLinkForAccountsPayable(
                  errorState,
                  companyId,
                )}
              />
            )}

          <Button
            id="default-employee-account-save-button"
            variant={isSubmittable ? 'primaryBrand' : 'secondaryNeutral'}
            text={t('misc.saveChanges')}
            className={styles.defaultForm__submit}
            isDisabled={
              !isSubmittable ||
              setDefaultEmployeeAccountQueryState.status === 'loading'
            }
            type="submit"
          />

          {newDefaultEmployeeAccount && defaultEmployeeAccount ? (
            <DefaultAccountEditConfirmationModal
              integration={integrationStatus.integration}
              isOpen={modalState.kind === 'confirmEdit'}
              setModalState={setModalState}
              onSetDefault={onSetDefault}
              existingDefaultAccount={{
                ...defaultEmployeeAccount,
                code: defaultEmployeeAccount.generalAccountCode,
              }}
              newDefaultAccount={{
                ...newDefaultEmployeeAccount,
                code: newDefaultEmployeeAccount.generalAccountCode,
              }}
              actionOrigin="defaultEmployeeAccountForm"
              auxiliaryAccountsEnabled={auxiliaryAccountsEnabled}
            />
          ) : (
            <></>
          )}

          {defaultEmployeeAccount ? (
            <DefaultAccountDeleteConfirmationModal
              integration={integrationStatus.integration}
              isOpen={modalState.kind === 'confirmDelete'}
              setModalState={setModalState}
              onSetDefault={onSetDefault}
              defaultAccount={{
                ...defaultEmployeeAccount,
                code: defaultEmployeeAccount.generalAccountCode,
              }}
              actionOrigin="defaultEmployeeAccountForm"
            />
          ) : (
            <></>
          )}
        </form>
      )}
    </div>
  );
};

function getInitialDefaultAccount(
  integrationStatus: IntegrationStatusWithIntegration,
  defaultEmployeeAccount: DefaultEmployeeAccount | undefined,
): DefaultEmployeeAccount {
  return defaultEmployeeAccount
    ? {
        ...defaultEmployeeAccount,
        name:
          defaultEmployeeAccount.name ||
          getDefaultEmployeeAccountName(integrationStatus.accountingCountry),
      }
    : {
        name: getDefaultEmployeeAccountName(
          integrationStatus.accountingCountry,
        ),
        generalAccountCode: '',
        auxiliaryAccountCode: undefined,
        isArchived: false,
      };
}
