import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import { postShowPaymentPassword } from 'modules/cards/api/postShowPaymentPassword';
import { scaProcedureErrors } from 'modules/cards/models/sca';
import { ModalMFAToken } from 'src/core/common/components/ModalMFA';

import { useGetPaymentPasswordProcedure } from '../../hooks/useGetPaymentPasswordProcedure';
import { SfsUkPasswordModal } from '../SfsUkPasswordModal/SfsUkPasswordModal';
import mobileImage from '../assets/mobile.png';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SFSUkScaPasswordModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');
  // dispatch is only injected in postShowPaymentPassword(...) because it's not
  // a React component, while we should not use useDispatch() inside a non-React
  // component.
  const dispatch = useDispatch();

  const [procedureId, setProcedureId] = useState<string | undefined>(undefined);
  const [phoneFactorProcedureId, setPhoneFactorProcedureId] = useState<
    string | undefined
  >(undefined);
  const [isAlternativeSmsOtpFlowOpen, setIsAlternativeSmsOtpFlowOpen] =
    useState(false);

  const { passwordToShow, procedureFailedReason, setProcedureFailedReason } =
    useGetPaymentPasswordProcedure(
      procedureId,
      undefined,
      isAlternativeSmsOtpFlowOpen,
    );

  useEffect(() => {
    const handleSCAShowPassword = async () => {
      try {
        const response = await postShowPaymentPassword(
          dispatch,
          isAlternativeSmsOtpFlowOpen,
        );

        // For the alternative SMS OTP flow, we don't want to set procedureId
        // right away as it would trigger the payment password fetching, while
        // we should do so only when the procedure is validated.
        if (!isAlternativeSmsOtpFlowOpen) {
          setProcedureId(response.procedureId);
        } else {
          setPhoneFactorProcedureId(response.procedureId);
        }
      } catch {
        setProcedureFailedReason('initializationError');
      }
    };

    handleSCAShowPassword();
    // When the user opens the alternative SMS OTP flow, we should create a new
    // procedure using the SMS fallback.
  }, [isAlternativeSmsOtpFlowOpen]);

  if (passwordToShow) {
    return (
      <SfsUkPasswordModal
        isOpen={isOpen}
        onClose={onClose}
        password={passwordToShow}
      />
    );
  }

  // I've decided to couple the SMS OTP fallback flow with the SCA through the
  // mobile app because they functionally are.
  if (isAlternativeSmsOtpFlowOpen) {
    return (
      // ModalMFAToken takes the procedure ID as stored by Redux, and its
      // storing is done inside postShowPaymentPassword(...).
      <ModalMFAToken
        // When the procedure is validated, we set its ID in procedureId's
        // value to trigger the right payment password fetching.
        onMfaTokenValidated={() => setProcedureId(phoneFactorProcedureId)}
        // Closing the SMS OTP modal also acts on closing this modal.
        onClose={onClose}
        isOpen
        flow="standard"
      />
    );
  }

  const handleAlternativeSmsOtpFlowClick = () => {
    setIsAlternativeSmsOtpFlowOpen(true);
    // For erasing the context around the previous mobile app procedure and
    // stopping polling.
    setProcedureId(undefined);
  };

  return (
    <Modal
      illustration={<img src={mobileImage} alt="" />}
      illustrationHeight="400px"
      title={t('cards.otpPasswordModal.title')}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <Button
          key="confirm"
          onClick={onClose}
          text={t('cards.otpPasswordModal.cancel')}
          variant="primaryBrand"
        />,
      ]}
    >
      {procedureId && !procedureFailedReason && (
        <p className="-mt-16 mb-16">{t('cards.otpPasswordModal.subtitle')}</p>
      )}
      {procedureFailedReason && (
        <Callout
          className="mb-16"
          title={t(scaProcedureErrors[procedureFailedReason])}
          variant="alert"
        />
      )}
      <Button
        variant="secondaryNeutral"
        text={t('cards.otpPasswordModal.alternateMethodCta')}
        onClick={handleAlternativeSmsOtpFlowClick}
      />
    </Modal>
  );
};
