import { useParams } from 'react-router-dom';

import { useFeature } from 'common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import {
  unpreparedPayableStates,
  unexportedPayableStates,
  reshapePayableCounts,
} from './mappers';
import { GET_PAYABLES_TO_PREPARE_COUNTS } from './queries';
import { useQuery } from '../hooks/useQuery';

export const usePayableCounts = (creationDate?: { from?: Date; to?: Date }) => {
  const { company: companyId } = useParams();
  const hasPayableFeature = useFeature(FEATURES.BOOKKEEP_PAYABLES);

  const queryOptions = {
    isEnabled: hasPayableFeature,
    variables: {
      companyId,
      prepareFilters: {
        state: unpreparedPayableStates,
        withBookkeepingStartDate: true,
        creationDate,
      },
      exportFilters: { state: unexportedPayableStates },
    },
  };

  return useQuery(GET_PAYABLES_TO_PREPARE_COUNTS, queryOptions, {
    reshapeData: reshapePayableCounts,
  });
};
