import {
  Callout,
  DATE_FORMAT,
  FileCard,
  UploadButton,
} from '@dev-spendesk/grapes';

import { useCompany } from 'modules/app/hooks/useCompany';
import { SpanishDPRCheckmarkFileCard } from 'modules/documentary-evidence/components/SpanishDPRCheckmark';
import { getSpanishDPRFromCompliances } from 'modules/documentary-evidence/utils/getSpanishDPRFromCompliances';
import { isTravelperkPayable } from 'modules/integrations/utils/travelperk';
import { useIsEditablePayable } from 'modules/payable/hooks';
import {
  PanelPreviewsSection,
  PanelTextSection,
} from 'src/core/common/components/Panel';
import {
  type LocaleFormat,
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { getFileTypeFromMime } from 'src/core/utils/files';

import { AutomaticallyCollectedReceipt } from '../../../../integrations/settings/components/AutomaticallyCollectedReceipt';
import type {
  DocumentViewable,
  Payable,
} from '../../containers/PayablePanelContainer';
import { useIsUkMileagePayable } from '../../models/payable';

export const ReceiptSection = ({
  payable,
  onDocumentClick,
  onDocumentUpload,
  isUploadingDocument,
}: {
  payable: Payable;
  onDocumentClick(document: DocumentViewable): void;
  onDocumentUpload?(files: File[] | null): void;
  isUploadingDocument?: boolean;
}) => {
  const { t, localeFormat } = useTranslation('global');

  const {
    id: payableId,
    documentaryEvidence,
    documentaryEvidenceCannotBeProvided,
  } = payable;

  const canUploadDocumentaryEvidence =
    useCanUploadDocumentaryEvidence(payable) && !!onDocumentUpload;

  const title = t('payables.panel.receipt');

  if (documentaryEvidenceCannotBeProvided?.reportMethod === 'manual') {
    return (
      <PanelTextSection isEmphasized title={title}>
        {t('payables.panel.receiptNotRequiredReason', {
          reporter: documentaryEvidenceCannotBeProvided.reporterGivenName,
          reason: documentaryEvidenceCannotBeProvided.reason,
        })}
      </PanelTextSection>
    );
  }

  if (documentaryEvidenceCannotBeProvided?.reportMethod === 'automatic') {
    return (
      <PanelTextSection isEmphasized title={title}>
        {t('payables.panel.missingInvoiceIsRefundAutomatic')}
      </PanelTextSection>
    );
  }

  const attachments = (documentaryEvidence?.attachments ?? []).sort(
    (a, b) => (a.attachedAt?.getTime() ?? 0) - (b.attachedAt?.getTime() ?? 0),
  );

  if (documentaryEvidence?.type === 'declarationOfMissingInvoice') {
    const previews = attachments.map((attachment) => (
      <FileCard
        key={attachment.url}
        onClick={() => {
          onDocumentClick({ ...documentaryEvidence, ...attachment });
        }}
        title={getAttachmentPreviewText(attachment, t, localeFormat, true)}
        mimeType={attachment.mediaType}
      />
    ));

    return (
      <PanelPreviewsSection
        title={title}
        noPreviewContent={t('payables.panel.noReceipt')}
        previews={previews}
      />
    );
  }

  if (documentaryEvidence?.validity.isValid) {
    const previews = attachments.map((attachment) => {
      const { hasSpanishDPR, state: spanishDPRState } =
        getSpanishDPRFromCompliances(attachment.compliances);

      return (
        <div key={attachment.url} className="rounded bg-secondary-default">
          <FileCard
            onClick={() => {
              onDocumentClick({
                ...documentaryEvidence,
                ...attachment,
              });
            }}
            title={
              (
                <span className="text-[13px] leading-5">
                  {getAttachmentPreviewText(attachment, t, localeFormat)}
                </span>
              ) as unknown as string // TODO@grapes: This is a hack, improve later with full width for FileCard content
            }
            mimeType={attachment.mediaType}
          />
          {hasSpanishDPR && (
            <SpanishDPRCheckmarkFileCard state={spanishDPRState} />
          )}
        </div>
      );
    });

    if (isTravelperkPayable(payable)) {
      previews.push(
        <AutomaticallyCollectedReceipt
          key={payableId}
          integrationId="travelperk"
          payableId={payableId}
        />,
      );
    }

    if (isUploadingDocument) {
      previews.push(
        <FileCard
          key="uploading"
          title={t('misc.uploadingFiles')}
          className="animate-pulse"
        />,
      );
    }

    return (
      <div className="flex flex-col gap-16">
        <PanelPreviewsSection
          title={title}
          noPreviewContent={t('payables.panel.noReceipt')}
          previews={previews}
        />
        {canUploadDocumentaryEvidence && (
          <UploadDocumentaryEvidenceButton
            onDocumentUpload={onDocumentUpload}
            label={t('payables.panel.addAnotherReceipt')}
          />
        )}
      </div>
    );
  }

  if (documentaryEvidence && !documentaryEvidence.validity.isValid) {
    const previews = attachments.map((attachment) => (
      <FileCard
        key={attachment.url}
        onClick={() => {
          onDocumentClick({ ...documentaryEvidence, ...attachment });
        }}
        title={
          (
            <span className="text-[13px] leading-5">
              {getAttachmentPreviewText(attachment, t, localeFormat)}
            </span>
          ) as unknown as string // TODO@grapes: This is a hack, improve later with full width for FileCard content
        }
        mimeType={attachment.mediaType}
      />
    ));

    if (isUploadingDocument) {
      previews.push(
        <FileCard
          key="uploading"
          title={t('misc.uploadingFiles')}
          className="animate-pulse"
        />,
      );
    }

    return (
      <div className="flex flex-col gap-16">
        <PanelPreviewsSection
          title={title}
          noPreviewContent={t('payables.panel.noReceipt')}
          previews={previews}
          beforePreviews={
            <Callout
              variant="warning"
              title={t('payments.panel.invalidReceiptTitle')}
            >
              “{documentaryEvidence.validity.reason}”
            </Callout>
          }
        />
        {canUploadDocumentaryEvidence && (
          <UploadDocumentaryEvidenceButton
            onDocumentUpload={onDocumentUpload}
            label={t('payables.panel.addAnotherReceipt')}
          />
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-16">
      <PanelPreviewsSection
        title={title}
        previews={
          isUploadingDocument
            ? [
                <FileCard
                  key="uploading"
                  title={t('misc.uploadingFiles')}
                  className="animate-pulse"
                />,
              ]
            : []
        }
        noPreviewContent={t('payables.panel.noReceipt')}
      />
      {canUploadDocumentaryEvidence && (
        <UploadDocumentaryEvidenceButton onDocumentUpload={onDocumentUpload} />
      )}
    </div>
  );
};

/**
 *  Helpers
 * */

const UploadDocumentaryEvidenceButton = ({
  onDocumentUpload,
  label,
}: {
  label?: string;
  onDocumentUpload(files: File[] | null): void;
}) => {
  const { t } = useTranslation('global');

  return (
    <UploadButton
      text={label ?? t('payables.panel.addReceipt')}
      variant="primaryBrand"
      fit="parent"
      onUpload={(files) => onDocumentUpload(files ? Array.from(files) : null)}
    />
  );
};

const getAttachmentPreviewText = (
  attachment: {
    mediaType: string;
    downloadUrl: string;
    attachedAt: Date | undefined;
  },
  t: TGlobalFunctionTyped,
  localeFormat: LocaleFormat,
  isAffidavit?: boolean,
): string => {
  const fileType = getFileTypeFromMime(attachment.mediaType);
  const readableDate = t('payables.panel.uploadedOn', {
    date: attachment.attachedAt
      ? localeFormat(attachment.attachedAt, DATE_FORMAT.SHORT)
      : t('misc.unknown'),
  });

  if (!fileType) {
    return readableDate;
  }

  if (isAffidavit) {
    return `Affidavit | ${readableDate}`;
  }

  return `${fileType} | ${readableDate}`;
};

export const useCanUploadDocumentaryEvidence = (
  payable: Payable | undefined,
) => {
  const isEditablePayable = useIsEditablePayable(payable);
  const company = useCompany();

  if (!payable) {
    return false;
  }

  const isAllowedPayableType = [
    'cardPurchase',
    'invoicePurchase',
    'claimedBill',
    'reversal',
  ].includes(payable.type ?? '');

  const isUkMileagePayable =
    payable.type === 'mileageAllowance' &&
    company?.mileage_scheme?.schemeType === 'UK';

  return isEditablePayable && (isAllowedPayableType || isUkMileagePayable);
};

export const useCanDeleteDocumentaryEvidence = (
  payable: Payable | undefined,
) => {
  const isEditablePayable = useIsEditablePayable(payable);

  const isUkMileagePayable = useIsUkMileagePayable(payable);

  if (!payable) {
    return false;
  }

  const hasMoreThanOneDocumentaryEvidence =
    (payable.documentaryEvidence?.attachments.length ?? 0) > 1;

  const isCardPayable = [
    'plasticCard',
    'subscriptionCard',
    'singleUseCard',
  ].includes(payable.subtype ?? '');

  return (
    isEditablePayable &&
    hasMoreThanOneDocumentaryEvidence &&
    (isCardPayable || isUkMileagePayable)
  );
};
