import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { AffidavitSignatureContainer } from 'common/components/AffidavitSignature';
import { ProtectedRoute } from 'common/components/ProtectedRoute';
import { useFeature } from 'common/hooks/useFeature';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { BudgetOverviewPageContainer } from 'modules/budgets/containers/BudgetOverviewPageContainer';
import { BudgetaryExercisesPageContainer } from 'modules/budgets/containers/BudgetaryExercisesPageContainer';
import { KybProcedureUpdatedModal } from 'modules/company';
import { Churn } from 'modules/company/churn';
import { CostCenterEditPage } from 'modules/company/cost-centers';
import { WalletContainer } from 'modules/company/wallet';
import { Homepage } from 'modules/homepage';
import { Legals } from 'modules/legals';
import { SetupHubRoutes } from 'modules/onboarding/setup-hub/pages/SetupHubRoutes';
import {
  CardActivationContainer,
  CardsOrderContainer,
  MyCardSetPinContainer,
  RecardContainer,
} from 'modules/physical-cards';
import { ProfilePage } from 'modules/profile/pages';
import { PurchaseOrdersPage } from 'modules/purchase-orders';
import { ExpenseClaimsPageContainer } from 'pages/ExpenseClaimsPage';
import { InvoicesPageContainer } from 'pages/InvoicesPage';
import { NotFound } from 'pages/NotFound/NotFound';
import { MaintenanceContainer } from 'src/core/common/components/Maintenance/MaintenanceContainer';
import appConfig from 'src/core/config';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { WelcomeModal } from 'src/core/modules/onboarding/setup-hub/components/WelcomeModal';
import { CardsPage } from 'src/core/pages/CardsPage/CardsPage';
import { ExpensesPage } from 'src/core/pages/ExpensesPage/ExpensesPage';
import { InboxInvoicesPageContainer } from 'src/core/pages/InboxInvoicesPage';
import { RequestPage } from 'src/core/pages/RequestPage/RequestPage';
import { SettingsAccountingPage } from 'src/core/pages/SettingsAccountingPage/SettingsAccountingPage';
import { SettingsAccountsPayable } from 'src/core/pages/SettingsAccountsPayable/SettingsAccountsPayable';
import { SettingsChartOfAccountsPage } from 'src/core/pages/SettingsChartOfAccountsPage/SettingsChartOfAccountsPage';
import { SettingsCompanyRulesPage } from 'src/core/pages/SettingsCompanyRulesPage/SettingsCompanyRulesPage';
import { SettingsIntegrationPage } from 'src/core/pages/SettingsIntegrationsPage/SettingsIntegrationPage';
import { SettingsOrganisationPage } from 'src/core/pages/SettingsOrganisationPage/SettingsOrganisationPage';
import { SettingsSpendeskPlanPage } from 'src/core/pages/SettingsSpendeskPlanPage/SettingsSpendeskPlanPage';
import { type AppState } from 'src/core/reducers';
import {
  getIsSupervisedUserSwitchedModalVisible,
  getShowKybProcedureValidatedPopup,
} from 'src/core/selectors/globalSelectors';

import ModalAgreement from './components/ModalAgreement/ModalAgreement';
import ModalReminderContainer from './components/ModalReminder/ModalReminderContainer';
import { RedirectToHome } from './components/RedirectToHome';
import { SwitchedSupervisedUserModal } from './components/SwitchedSupervisedUserModal/SwitchedSupervisedUserModal';
import { TopBanners } from './components/TopBanners/TopBanners';
import WhatsAValidReceiptPopup, {
  isVisible as isWhatsAValidReceiptPopupVisible,
} from './components/ValidReceiptModal/ValidReceiptModalContainer';
import { Navigation } from './components/VerticalNavigation/Navigation';
import { getDisplayMode } from './redux/selectors';
import { ApprovalByDimensionsPage } from '../../approval-by-dimensions/components/ApprovalByDimensionsPage';
import { Bookkeep } from '../../bookkeep/components/Bookkeep';
import { BookkeepExportDownloader } from '../../bookkeep/export/components/BookkeepExportDownloader';
import { AchSuccessModal } from '../../company/wallet/ach/components/AchSuccessModal';
import { CheckBankStatementModal } from '../../company/wallet/ach/components/CheckBankStatementModal';
import { CheckBankStatementContainer } from '../../company/wallet/ach/containers/CheckBankStatementContainer';
import { type RenderPropertyParams } from '../../company/wallet/ach/containers/CheckBankStatementContainer/CheckBankStatementContainer';
import { ApiOAuth2AuthorizePage } from '../../integrations/settings/pages/api-oauth2-authorize/ApiOAuth2Authorize';
import { ExternalConnectRedirectPage } from '../../integrations/settings/pages/external-connect-redirect/externalConnectRedirect';
import { MemberScreeningModal } from '../../members/components/MemberScreeningModal/MemberScreeningModal';
import { MultiEntityHubContainer } from '../../organisation-reporting';
import { useHasAccessToMultiEntityHub } from '../../organisation-reporting/hooks/useHasAccessToMultiEntityHub';
import { SearchForPage } from '../components/SearchForPage/SearchForPage';
import { useShouldRedirectMaintenencePage } from '../hooks/useShouldRedirectMaintenencePage';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: any;
  layoutDisplayMode: 'webview' | 'browser';
  showKybProcedureValidatedPopup: boolean;
  isSupervisedUserSwitchedModalVisible: boolean;
};

const Container = ({
  config,
  layoutDisplayMode,
  showKybProcedureValidatedPopup,
  isSupervisedUserSwitchedModalVisible,
}: Props) => {
  const company = useCompany();
  const user = useUser();

  const hasPlayByTheRulesFeature = useFeature(FEATURES.PLAY_BY_RULES);
  const hasBudgetsFeature = useFeature(FEATURES.BUDGETS);
  const hasAccessToWallet = useFeature(FEATURES.WALLET_ACCESS);
  const hasCostCentersFeature = useFeature(FEATURES.COST_CENTERS);
  const hasPayableFeature = useFeature(FEATURES.BOOKKEEP_PAYABLES);
  const isInvoiceInboxEnabled = useFeature(FEATURES.BILL_INBOX);
  const hasPlasticCardsFeature = useFeature(FEATURES.PLASTIC_CARDS);
  const hasAccessToMultiEntityHub = useHasAccessToMultiEntityHub();

  useShouldRedirectMaintenencePage();

  const isAoOrController = user.is_account_owner || user.is_controller;

  const renderModalCheckBankStatement = () => (
    <CheckBankStatementContainer
      render={({
        isWalletPage,
        shouldShowModal,
        hasPendingFundingSource,
        hasSuccessfullyCreatedFundingSource,
        clearHasSuccessfullyCreatedFundingSource,
      }: RenderPropertyParams) => {
        // if wallet page, no need to show a modal through LayoutContainer
        if (
          isWalletPage ||
          !shouldShowModal ||
          !user.is_account_owner ||
          !user.is_controller
        ) {
          return null;
        }

        if (hasPendingFundingSource) {
          return <CheckBankStatementModal />;
        }

        if (hasSuccessfullyCreatedFundingSource) {
          return (
            <AchSuccessModal
              onContinue={clearHasSuccessfullyCreatedFundingSource}
            />
          );
        }

        return null;
      }}
    />
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderContent = () => {
    return (
      <Switch>
        <Route path={routes.HOMEPAGE.path}>
          <Homepage />
        </Route>

        <ProtectedRoute
          path={routes.INBOX_INVOICES.path}
          isAccessAllowed={isInvoiceInboxEnabled}
        >
          <InboxInvoicesPageContainer />
        </ProtectedRoute>

        <ProtectedRoute
          path={routes.REQUESTS.path}
          isAccessAllowed={
            !company.churning_at &&
            (user.is_requester || user.is_approver || user.is_controller)
          }
        >
          <RequestPage />
        </ProtectedRoute>

        <Route path={[routes.PAYMENTS_ALL.path, routes.PAYABLES_ALL.path]}>
          <ExpensesPage />
        </Route>

        <ProtectedRoute
          path={routes.EXPENSE_INBOX.path}
          isAccessAllowed={hasPayableFeature}
          redirectTo={{
            pathname: routeFor(routes.PAYMENTS_ALL.path, {
              company: company.id,
            }),
          }}
        >
          <Bookkeep />
        </ProtectedRoute>

        <Route path={routes.PURCHASE_ORDERS.path}>
          <PurchaseOrdersPage />
        </Route>

        <Route path={routes.CARDS_ORDER.path}>
          <CardsOrderContainer />
        </Route>
        <Route
          path={[
            routes.CARDS.path,
            routes.SUBSCRIPTIONS.path,
            routes.CARD.path,
          ]}
        >
          <CardsPage />
        </Route>

        <ProtectedRoute
          path={routes.CARD_PIN.path}
          isAccessAllowed={user.has_plastic_card && hasPlasticCardsFeature}
        >
          <MyCardSetPinContainer />
        </ProtectedRoute>
        <Route path={routes.CARD_ACTIVATION.path}>
          <CardActivationContainer />
        </Route>

        <Route path={routes.RECARD.path}>
          <RecardContainer />
        </Route>

        <Route path={[routes.CREDIT_NOTES_REVIEW.path, routes.INVOICES.path]}>
          <InvoicesPageContainer />
        </Route>
        <Route path={routes.EXPENSE_CLAIMS.path}>
          <ExpenseClaimsPageContainer />
        </Route>

        <Route
          path={[routes.ACCOUNT_ME.path, routes.ACCOUNT_NOTIFICATIONS.path]}
        >
          <ProfilePage />
        </Route>

        <Route
          exact
          path={[
            routes.COMPANY_ACCOUNTING_BOOKKEEPING_SETUP.path,
            routes.COMPANY_ACCOUNTING.path,
            routes.COMPANY_ACCOUNTING_INTEGRATION.path,
            routes.COMPANY_EXPORTS.path,
            routes.COMPANY_ACCOUNTING_ANALYTICAL_FIELDS.path,
          ]}
        >
          <SettingsAccountingPage />
        </Route>

        <Route
          exact
          path={[
            routes.COMPANY_ACCOUNTING_BANK_ACCOUNTS.path,
            routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path,
            routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path,
            routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path,
            routes.COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS.path,
          ]}
        >
          <SettingsChartOfAccountsPage />
        </Route>

        <Route path={routes.LEGALS.path}>
          <Legals />
        </Route>

        <Route
          path={[
            routes.COMPANY_BILLING_PLAN.path,
            routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path,
            routes.COMPANY_BILLING_INFORMATION.path,
          ]}
        >
          <SettingsSpendeskPlanPage />
        </Route>

        <ProtectedRoute
          path={routes.CHURN.path}
          isAccessAllowed={
            user.is_account_owner && company.churning_at !== null
          }
        >
          <Churn />
        </ProtectedRoute>

        <Route
          path={[
            routes.COMPANY_MEMBERS.path,
            routes.COMPANY_TEAMS.path,
            routes.COST_CENTERS.path,
            routes.COMPANY_CATEGORIES.path,
          ]}
        >
          <SettingsOrganisationPage />
        </Route>

        <Route
          path={[
            routes.COMPANY_POLICIES.path,
            routes.COMPANY_CONTROL_RULES.path,
            routes.SPEND_LIMITS.path,
            routes.APPROVAL_WORKFLOWS.path,
            routes.COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS.path,
            routes.COMPANY_GENERAL_SETTINGS_BANK_INFORMATION.path,
            routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path,
          ]}
        >
          <SettingsCompanyRulesPage />
        </Route>

        <ProtectedRoute
          path={routes.COMPANY_BANK.path}
          isAccessAllowed={hasAccessToWallet && isAoOrController}
        >
          <WalletContainer />
        </ProtectedRoute>

        <Route path={routes.COMPANY_ACCOUNTS_PAYABLE.path}>
          <SettingsAccountsPayable />
        </Route>

        <ProtectedRoute
          path={routes.COMPANY_INTEGRATION_EXTERNAL_CONNECT.path}
          isAccessAllowed={
            !company.churning_at &&
            (user.is_account_owner || user.is_controller)
          }
        >
          <ExternalConnectRedirectPage />
        </ProtectedRoute>

        <Route
          path={[
            routes.COMPANY_INTEGRATIONS.path,
            routes.COMPANY_INTEGRATION.path,
          ]}
        >
          <SettingsIntegrationPage />
        </Route>
        <ProtectedRoute
          path={routes.SPENDESK_OAUTH2.path}
          isAccessAllowed={!company.churning_at}
        >
          <ApiOAuth2AuthorizePage />
        </ProtectedRoute>

        <ProtectedRoute
          path={routes.ORGANISATION_REPORTING.path}
          isAccessAllowed={hasAccessToMultiEntityHub}
          redirectTo={{
            pathname: routeFor(routes.HOMEPAGE.path, {
              company: company.id,
            }),
          }}
        >
          <MultiEntityHubContainer />
        </ProtectedRoute>

        <ProtectedRoute
          path={routes.BUDGETARY_EXERCISES.path}
          isAccessAllowed={hasBudgetsFeature}
        >
          <BudgetaryExercisesPageContainer />
        </ProtectedRoute>
        <ProtectedRoute
          path={routes.BUDGET_OVERVIEW.path}
          isAccessAllowed={hasBudgetsFeature}
        >
          <BudgetOverviewPageContainer />
        </ProtectedRoute>

        <ProtectedRoute
          path={routes.COST_CENTER_EDIT_PAGE.path}
          isAccessAllowed={hasCostCentersFeature}
        >
          <CostCenterEditPage />
        </ProtectedRoute>

        <Route path={routes.EXPORT_DOWNLOAD.path}>
          <BookkeepExportDownloader />
        </Route>

        <Route path={routes.AFFIDAVIT_SIGNATURE.path}>
          <AffidavitSignatureContainer />
        </Route>
        <Route path={routes.APPROVAL_WORKFLOWS.path}>
          <ApprovalByDimensionsPage />
        </Route>
        <ProtectedRoute
          path={routes.SETUP_HUB.path}
          isAccessAllowed={
            !company.churning_at &&
            (user.is_account_owner || user.is_admin || user.is_controller)
          }
        >
          <SetupHubRoutes />
        </ProtectedRoute>
        <Route exact path={routes.APP.path}>
          <RedirectToHome />
        </Route>
        <Route path={routes.MAINTENANCE.path}>
          <MaintenanceContainer />
        </Route>
        <Route path={[routes.NOT_FOUND.path, '*']}>
          <NotFound />
        </Route>
      </Switch>
    );
  };

  if (layoutDisplayMode === 'webview') {
    return renderContent();
  }

  return (
    <>
      {showKybProcedureValidatedPopup &&
        !isWhatsAValidReceiptPopupVisible(company, user) && (
          <KybProcedureUpdatedModal />
        )}
      <MemberScreeningModal />
      {isSupervisedUserSwitchedModalVisible && <SwitchedSupervisedUserModal />}
      {renderModalCheckBankStatement()}
      <TopBanners />
      {appConfig.activateSearch && <SearchForPage />}
      <ModalAgreement
        supervisor={config?.supervisor ?? null}
        user={user}
        company={company}
      />
      <WelcomeModal />
      {!isWhatsAValidReceiptPopupVisible(company, user) &&
        !hasPlayByTheRulesFeature && <ModalReminderContainer />}

      <WhatsAValidReceiptPopup user={user} company={company} />
      <Navigation />
      {renderContent()}
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    config: state.global.config,
    layoutDisplayMode: getDisplayMode(state),
    isSupervisedUserSwitchedModalVisible:
      getIsSupervisedUserSwitchedModalVisible(state),
    showKybProcedureValidatedPopup: getShowKybProcedureValidatedPopup(state),
  };
};

export const PaidLayoutContainer = connect(mapStateToProps)(Container);
