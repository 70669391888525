import {
  HighlightIcon,
  type HighlightIconVariant,
  type IconName,
} from '@dev-spendesk/grapes';
import cx from 'classnames';

import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import './PbrRuleTimeline.css';

type PbrRuleTimelineProps = {
  incompletePaymentsLimit: number;
  completionDeadline: number;
  mode: 'requester' | 'admin';
  hasReceiptAndFieldsFeature?: boolean;
  className?: string;
};

export const PbrRuleTimeline = ({
  incompletePaymentsLimit,
  completionDeadline,
  mode,
  hasReceiptAndFieldsFeature = false,
  className,
}: PbrRuleTimelineProps) => {
  const { t } = useTranslation('global');

  const translations = getTranslations(
    {
      incompletePaymentsLimit,
      completionDeadline,
      mode,
      hasReceiptAndFieldsFeature,
    },
    t,
  );

  const steps: {
    key: string;
    title?: string | React.ReactElement;
    description?: string | React.ReactElement;
    icon: string;
    variant: HighlightIconVariant;
  }[] = [
    {
      key: 'step-1',
      ...translations.steps[0],
      icon: 'card',
      variant: 'purple',
    },
    {
      key: 'step-2',
      ...translations.steps[1],
      icon: 'clock-filled',
      variant: 'apricot',
    },
    {
      key: 'step-3',
      ...translations.steps[2],
      icon: 'triangle-warning',
      variant: 'apricot',
    },
    {
      key: 'step-4',
      ...translations.steps[3],
      icon: 'lock-close',
      variant: 'peach',
    },
    {
      key: 'step-5',
      ...translations.steps[4],
      icon: 'lock-open',
      variant: 'forest',
    },
  ];

  return (
    <div className={cx('PbrRuleTimeline', className)}>
      {translations.mainTitle && (
        <div className="mb-24 text-primary title-m">
          {translations.mainTitle}
        </div>
      )}
      <div className="PbrRuleTimeline__desc">
        {steps.map((step) => (
          <div key={step.key} className="PbrRuleTimeline__desc__row">
            <div className="PbrRuleTimeline__desc__row__icon">
              <HighlightIcon
                name={step.icon as IconName}
                variant={step.variant}
                size="m"
              />
              <div className="PbrRuleTimeline__desc__row__icon__line" />
            </div>
            <div className="flex flex-col content-stretch gap-4 font-medium body-m">
              {step.title && (
                <p className="leading-[20px] text-primary">{step.title}</p>
              )}
              {step.description && (
                <p className="leading-[20px] text-secondary-bg-primary">
                  {step.description}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const getTranslations = (
  {
    incompletePaymentsLimit,
    completionDeadline,
    mode,
    hasReceiptAndFieldsFeature,
  }: PbrRuleTimelineProps,
  t: TGlobalFunctionTyped,
) => {
  if (mode === 'requester') {
    return {
      mainTitle: undefined,
      steps: [
        {
          title: t('pbrTimeline.homepage.sectionTitle1'),
          description: t('pbrTimeline.homepage.sectionDesc1', {
            count: completionDeadline,
          }),
        },
        {
          title: t('pbrTimeline.homepage.sectionTitle2', {
            count: completionDeadline - 1,
          }),
          description: t('pbrTimeline.homepage.sectionDesc2'),
        },
        {
          title: t('pbrTimeline.homepage.sectionTitle3', {
            count: completionDeadline,
          }),
          description: t('pbrTimeline.homepage.sectionDesc3'),
        },
        {
          title: t('pbrTimeline.homepage.sectionTitle4', {
            count: incompletePaymentsLimit,
          }),
          description: t('pbrTimeline.homepage.sectionDesc4'),
        },
        {
          title: t('pbrTimeline.homepage.sectionTitle5'),
          description: t('pbrTimeline.homepage.sectionDesc5'),
        },
      ],
    };
  }
  return {
    mainTitle: t('pbrTimeline.admin.mainTitle'),
    steps: [
      {
        title: t('pbrTimeline.admin.sectionTitle1', {
          interval: `1-${completionDeadline}`,
        }),
        description: hasReceiptAndFieldsFeature
          ? t(
              'controlRules.mandatoryFields.create.ruleDescription.limitDescription',
            )
          : t('pbrTimeline.admin.sectionDesc1', {
              count: completionDeadline,
            }),
      },
      {
        title: t('pbrTimeline.admin.sectionTitle2', {
          count: completionDeadline - 1,
        }),
        description: hasReceiptAndFieldsFeature
          ? t(
              'controlRules.mandatoryFields.create.ruleDescription.dayDescriptionUrgent',
            )
          : t('pbrTimeline.admin.sectionDesc2'),
      },
      {
        title: t('pbrTimeline.admin.sectionTitle3', {
          count: completionDeadline,
        }),
        description: hasReceiptAndFieldsFeature
          ? t(
              'controlRules.mandatoryFields.create.ruleDescription.dayDescriptionLate',
            )
          : t('pbrTimeline.admin.sectionDesc3'),
      },
      {
        title: t('pbrTimeline.admin.sectionTitle4new', {
          count: incompletePaymentsLimit ?? 1,
        }),
        description: t('pbrTimeline.admin.sectionDesc4'),
      },
      {
        title: t('pbrTimeline.admin.sectionTitle5'),
        description: hasReceiptAndFieldsFeature
          ? t(
              'controlRules.mandatoryFields.create.ruleDescription.unblockingDescription',
            )
          : t('pbrTimeline.admin.sectionDesc5'),
      },
    ],
  };
};
