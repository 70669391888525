import { Box } from '@dev-spendesk/grapes';
import { useId, type ReactNode } from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { ApprovalPolicySpendingRule } from './ApprovalPolicySpendingRule';
import { PlayByTheRulesSpendingRule } from './PlayByTheRulesSpendingRule';
import approvalPolicyImage from './assets/approval-policy.svg';
import receiptsPhysicalCardsImage from './assets/receipts-physical-cards.svg';

type Props = {
  incompletePaymentsLimit: number;
  completionDeadline: number;
};

export const SpendingRules = ({
  incompletePaymentsLimit,
  completionDeadline,
}: Props) => {
  const { t } = useTranslation('global');
  const hasApprovalFlowFeature = useFeature(FEATURES.APPROVAL_FLOWS);

  return (
    <>
      <SpendingRuleBlock
        title={
          hasApprovalFlowFeature
            ? t('control.approvalPolicyWidget.approvalPolicyTitle')
            : t('control.approvalPolicyWidget.spendingPolicyTitle')
        }
        image={approvalPolicyImage}
      >
        <ApprovalPolicySpendingRule
          hasFeatureEnabled={hasApprovalFlowFeature}
        />
      </SpendingRuleBlock>
      <SpendingRuleBlock
        title={t('homepage.rules.panel.title')}
        image={receiptsPhysicalCardsImage}
      >
        <PlayByTheRulesSpendingRule
          incompletePaymentsLimit={incompletePaymentsLimit}
          completionDeadline={completionDeadline}
        />
      </SpendingRuleBlock>
    </>
  );
};

type SpendingRuleBlockProps = {
  title: string;
  image: string;
  children: ReactNode;
};

const SpendingRuleBlock = ({
  title,
  image,
  children,
}: SpendingRuleBlockProps) => {
  const titleId = useId();
  return (
    <Box
      variant="secondary"
      role="region"
      aria-labelledby={titleId}
      className="flex items-center justify-between gap-24"
    >
      <div className="flex flex-col content-stretch gap-8">
        <h3 className="text-primary title-l" id={titleId}>
          {title}
        </h3>
        {children}
      </div>
      <img width="100" height="100" src={image} alt={title} />
    </Box>
  );
};
