import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

export const UploadReceiptSlideThree = () => {
  const { t } = useTranslation('global');

  const companyId = useCompanyId();

  return (
    <div className="flex flex-col gap-16">
      <div className="flex flex-col gap-8">
        <p className="title-m">
          {t('readyToSpend.slideshows.uploadReceipt.three.items.one.title')}
        </p>
        <p>
          {t('readyToSpend.slideshows.uploadReceipt.three.items.one.content')}
        </p>
      </div>
      <div className="flex flex-col gap-8">
        <p className="title-m">
          {t('readyToSpend.slideshows.uploadReceipt.three.items.two.title')}
        </p>
        <p>
          {t('readyToSpend.slideshows.uploadReceipt.three.items.two.content')}
        </p>
      </div>
      <div className="flex flex-col gap-8">
        <p className="title-m">
          {t('readyToSpend.slideshows.uploadReceipt.three.items.three.title')}
        </p>
        <p>
          {t('readyToSpend.slideshows.uploadReceipt.three.items.three.content')}
        </p>
      </div>
      <div className="flex flex-col gap-8">
        <p className="title-m">
          {t('readyToSpend.slideshows.uploadReceipt.three.items.four.title')}
        </p>
        <p>
          <Trans
            i18nKey={t(
              `readyToSpend.slideshows.uploadReceipt.three.items.four.content`,
            )}
            values={{ companyId }}
            components={{
              i: <i>-</i>,
            }}
          />
        </p>
      </div>
    </div>
  );
};
