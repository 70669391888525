import { Button, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import tomTalker from './tomtalker.svg';

type Props = {
  onClose: () => void;
  onShowPasswordModal: () => void;
};

export const SfsUkPasswordEducation = ({
  onClose,
  onShowPasswordModal,
}: Props) => {
  const { t } = useTranslation('global');
  return (
    <Modal
      illustration={
        <div className="flex w-full items-center justify-center">
          <img
            src={tomTalker}
            alt=""
            className="max-h-[100%] min-h-0 w-[100%] min-w-0"
          />
        </div>
      }
      illustrationHeight="424px"
      title={t('requests.sfsUkPasswordEducationModal.title')}
      subtitle={t('requests.sfsUkPasswordEducationModal.description')}
      isOpen
      onClose={onClose}
      actions={[
        <div key="ctas" className="flex flex-col items-center">
          <Button
            onClick={onShowPasswordModal}
            text={t('requests.sfsUkPasswordEducationModal.closeButton')}
            variant="primaryBrand"
          />
        </div>,
      ]}
    />
  );
};
