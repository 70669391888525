import {
  Icon,
  Table,
  type TableColumn,
  Tooltip,
  colors,
} from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import {
  type ControlRule,
  EditControlRuleModalContainer,
} from 'modules/company/members/components/ControlRules';

type Props = {
  controlRules: ControlRule[];
  setSelectedControlRule(id: string): void;
};

export const TableControlRules = ({
  controlRules,
  setSelectedControlRule,
}: Props) => {
  const { t } = useTranslation('global');

  const columns: TableColumn<ControlRule>[] = [
    {
      header: t('misc.name'),
      id: 'name',
      getSortValue: (controlRule) => controlRule.name,
      width: 'auto',
      renderCell: (controlRule) => {
        return (
          <div className="flex items-center gap-8">
            {controlRule.isInitial
              ? t('controlRules.initialRuleName')
              : controlRule.name}
            {controlRule.isInitial && controlRule.isDefault && (
              <Tooltip
                maxWidth={260}
                placement="bottom"
                content={t('controlRulesModal.autoAssignDesc')}
                triggerAsChild
              >
                <Icon
                  name="person-group"
                  color={colors.contentDecorativeIcon}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      header: t('misc.members'),
      id: 'membersCount',
      getSortValue: (controlRule) => controlRule.userIds?.length,
      width: '180px',
      renderCell: (controlRule) => controlRule.userIds?.length,
    },
    {
      header: t('controlRules.paymentsLimit'),
      id: 'incompletePaymentsLimit',
      width: '260px',
      getSortValue: (controlRule) => controlRule.incompletePaymentsLimit,
      renderCell: (controlRule) => controlRule.incompletePaymentsLimit,
    },
    {
      header: t('controlRules.paymentsDelay'),
      id: 'completionDeadline',
      width: '270px',
      getSortValue: (controlRule) => controlRule.completionDeadline,
      renderCell: (controlRule) => controlRule.completionDeadline,
    },
  ];

  const sortedRules: ControlRule[] = controlRules.filter(
    (controlRule) => !controlRule.isInitial,
  );
  const initialControlRule = controlRules.find(
    (controlRule) => controlRule.isInitial,
  );
  // always put the initial control rule at the start of the list
  if (initialControlRule) {
    sortedRules.unshift(initialControlRule);
  }

  return (
    <>
      <EditControlRuleModalContainer />
      <Table
        onRowClick={(controlRule) => {
          setSelectedControlRule(controlRule.id);
        }}
        data={sortedRules}
        columns={columns}
        nextSortDirection={{
          none: 'descending',
          ascending: 'descending',
          descending: 'ascending',
        }}
        emptyState={{
          title: t('controlRules.emptyFilterTitle'),
          subtitle: t('controlRules.emptyFilterDesc'),
        }}
        getRowId={(row) => row.id}
      />
    </>
  );
};
