import { useQuery } from 'react-query';

import { useCompany } from 'modules/app/hooks/useCompany';
import { type ApprovalSchemeInputApi } from 'modules/company/structure/approval-flows';
import { apiUrl, apiV2Url } from 'src/core/utils/api';

import {
  type ApprovalWorkflow,
  type ApprovalWorkflowDefinition,
} from '../approvalWorkflow';

const fetchActiveApprovalWorkflow = async (companyId: string) =>
  fetch(apiV2Url('/approval-workflows/active', companyId), {
    method: 'GET',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
  });

const createApprovalScheme = async (scheme: ApprovalSchemeInputApi) =>
  fetch(apiUrl('/approval-scheme', scheme.companyId), {
    method: 'POST',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(scheme),
  });

const createApprovalWorkflow = async (
  companyId: string,
  workflow: ApprovalWorkflowDefinition,
) =>
  fetch(apiV2Url('/approval-workflows', companyId), {
    method: 'POST',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(workflow),
  });

const getDefaultApprovalScheme = (
  companyId: string,
): ApprovalSchemeInputApi => ({
  companyId,
  owner: null,
  // FIXME: temp rule, the AO rule should be used
  // (this is a minimal valid rule in the meantime)
  rules: [
    {
      upTo: { value: null },
      steps: [{ approverType: 'costCenterOwner' }],
    },
  ],
});

const getDefaultApprovalWorkflowDefinition = async (
  approvalSchemeId: string,
) => ({
  values: null,
  outcome: { approvalSchemeId },
});

const createDefaultApprovalWorklow = async (
  companyId: string,
): Promise<ApprovalWorkflow> => {
  const schemeResponse = await createApprovalScheme(
    getDefaultApprovalScheme(companyId),
  );
  if (!schemeResponse.ok) {
    throw new Error('Failed to create approval scheme');
  }
  const scheme = await schemeResponse.json();
  const workflowResponse = await createApprovalWorkflow(
    companyId,
    await getDefaultApprovalWorkflowDefinition(scheme.id),
  );
  if (!workflowResponse.ok) {
    throw new Error('Failed to create approval workflow');
  }
  return workflowResponse.json();
};

export const useApprovalWorkflowQuery = () => {
  const company = useCompany();
  return useQuery(
    ['approval-workflows', 'active'],
    async (): Promise<ApprovalWorkflow> => {
      const response = await fetchActiveApprovalWorkflow(company.id);
      if (response.status === 404) {
        return createDefaultApprovalWorklow(company.id);
      }
      if (!response.ok) {
        throw new Error('Failed to fetch approval workflow');
      }
      return response.json();
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockResponse = (approvalSchemeId: string): ApprovalWorkflowDefinition => {
  return {
    values: null,
    outcome: {
      approvalSchemeId,
    },
    branch: {
      dimension: 'spendType',
      nodes: [
        {
          values: ['single_purchase'],
          outcome: {
            approvalSchemeId,
          },
        },
        {
          values: ['expense', 'invoice', 'purchase_order', 'mileage_allowance'],
          outcome: {
            approvalSchemeId,
          },
          branch: {
            dimension: 'costCenter',
            nodes: [
              {
                values: ['b5a00dc2-c758-48d8-8506-628bb02684bd'],
                outcome: {
                  approvalSchemeId,
                },
              },
              {
                values: [
                  'c2c96e23-2966-4d14-85d4-91295373e9a5',
                  '21ce9b01-cd0a-4e56-9d22-c2522953cdb8',
                ],
                outcome: {
                  approvalSchemeId,
                },
                // branch: {
                //   dimension: 'expenseCategory',
                //   nodes: [
                //     {
                //       values: ['ec1', 'ec2'],
                //       outcome: {
                //         approvalSchemeId,
                //       },
                //     },
                //     {
                //       values: ['ec3'],
                //       outcome: {
                //         approvalSchemeId,
                //       },
                //     },
                //     {
                //       values: ['ec4'],
                //       outcome: {
                //         approvalSchemeId,
                //       },
                //     },
                //   ],
                // },
              },
            ],
          },
        },
      ],
    },
  };
};
