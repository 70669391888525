import { QuerySuspenseBase } from 'common/components/QuerySuspenseBase/QuerySuspenseBase';
import { useUsersRequestsQuery } from 'modules/requests/api/useUsersRequestsQuery';
import { UnexpectedErrorContainer } from 'src/core/common/components/UnexpectedError/UnexpectedErrorContainer';

import { RequestsListLoader } from '../RequestsListsLoader';

export const RequestsToApproveList = () => {
  const usersRequestsQueryState = useUsersRequestsQuery({
    enabled: true,
  });

  return (
    <QuerySuspenseBase
      queryState={usersRequestsQueryState}
      loading={<RequestsListLoader />}
      fallback={() => <UnexpectedErrorContainer useAutoNotificationWording />}
    >
      {(usersRequestsData) => (
        <div className="flex w-full flex-col">
          {usersRequestsData.data.map((userRequests) => (
            <div key={userRequests.user.id}>{userRequests.user.email}</div>
          ))}
        </div>
      )}
    </QuerySuspenseBase>
  );
};
