import { Button } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type CompanyInformationFormValues } from './form';
import { AddressForm } from '../AddressForm';

type Props = FormikProps<CompanyInformationFormValues>;

export const CompanyInformationForm = (props: Props) => {
  const { t } = useTranslation('global');
  const { dirty, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit} className="box mt-24">
      <AddressForm {...props} />
      <Button
        className="mt-24"
        variant="primaryBrand"
        type="submit"
        isDisabled={!dirty}
        text={t('generalSettings.companyInformation.submitButton')}
      />
    </form>
  );
};
