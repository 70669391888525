import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { useFiltersValuesQuery } from './useFiltersValuesQuery';
import {
  type AdditionalFilter,
  type EnabledAdditionalFilters,
} from '../../../../../models';

export function getActiveFiltersCount(
  enabledFilters: EnabledAdditionalFilters | undefined,
  selectedFilters: AdditionalFilter[],
) {
  return enabledFilters
    ? selectedFilters.filter(({ values, id, type }) => {
        if (!values.length) {
          return false;
        }
        if (id === 'costCenter') {
          return enabledFilters.costCenters;
        }
        if (id === 'team') {
          return enabledFilters.teams;
        }
        if (type === 'CustomBoolean' || type === 'CustomList') {
          return enabledFilters.customFields;
        }
        return true;
      }).length
    : undefined;
}

export const useEnabledFilters = (): EnabledAdditionalFilters | undefined => {
  const filtersValuesQueryResult = useFiltersValuesQuery()[1];

  const fieldsValues =
    filtersValuesQueryResult.status === 'success'
      ? filtersValuesQueryResult.data
      : undefined;

  const hasCustomFieldsFilters = useFeature(FEATURES.CUSTOM_FIELDS);
  const hasTeamsFilter = useFeature(FEATURES.TEAMS);
  const hasCostCentersFilter = Boolean(fieldsValues?.costCenters);

  return (
    fieldsValues && {
      customFields: hasCustomFieldsFilters,
      teams: hasTeamsFilter,
      costCenters: hasCostCentersFilter,
    }
  );
};
