import { Button } from '@dev-spendesk/grapes';

import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import logout from 'src/core/utils/logout';

type Props = {
  t: TGlobalFunctionTyped;
  companyId: string;
  hasAccessToMultiEntityHub: boolean;
};

export const NavBar = ({ t, companyId, hasAccessToMultiEntityHub }: Props) => {
  return (
    <div className="fixed z-50 flex size-full h-64 items-center justify-between bg-primary-brand-default px-24">
      <div>
        {hasAccessToMultiEntityHub && (
          <Button
            variant="primaryBrand"
            className="MultiHubButton"
            text={t('organisation.reporting.links.button')}
            iconName="square-4"
            onClick={() => {
              window.location.href = routeFor(
                routes.ORGANISATION_REPORTING.path,
                {
                  company: companyId,
                },
              );
            }}
          />
        )}
      </div>
      <img
        width="148px"
        src="/static/img/logo_horizontal_white.svg"
        alt="spendeskLogo"
      />
      <div>
        <Button
          variant="secondaryNeutral"
          text={t('overdueInvoice.closedDoor.nav.logOut')}
          onClick={async () => {
            await logout();
          }}
        />
      </div>
    </div>
  );
};
