import { Table } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorState } from 'common/components/ErrorState';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import ApiAccessStateTag from './ApiAccessStateTag';
import { routeFor, routes } from '../../../../../../constants/routes';
import { useGetApiKeys } from '../hooks';

const ApiAccessList = () => {
  const { t, activeLanguage } = useTranslation('global');
  const dateTimeFormat = new Intl.DateTimeFormat(activeLanguage, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const companyId = useCompanyId();
  const history = useHistory();
  const keysResult = useGetApiKeys();

  if (keysResult.status === 'loading') {
    // TODO skeleton
    return <></>;
  }
  if (keysResult.status === 'error') {
    return <ErrorState title={t('misc.errorState.title')} />;
  }

  return (
    <div className="flex flex-col gap-24">
      <Table
        data={keysResult.data}
        data-testid="api-key-list"
        onRowClick={(key) => {
          history.push(
            routeFor(routes.COMPANY_INTEGRATIONS_API_ACCESS_VIEW.path, {
              company: companyId,
              id: key.apiId,
            }),
          );
        }}
        defaultSortState={{
          columnId: 'expiredAt',
          direction: 'descending',
        }}
        columns={[
          {
            id: 'name',
            header: t('misc.name'),
            renderCell: ({ name }) => name,
          },
          {
            id: 'description',
            header: t('misc.description'),
            renderCell: ({ description }) => (
              <p className="p-8" style={{ whiteSpace: 'normal' }}>
                {description}
              </p>
            ),
          },
          {
            id: 'id',
            header: t('publicApi.flowApiKey.main.apiId'),
            renderCell: ({ apiId }) => <code>{apiId}</code>,
          },
          {
            id: 'expiredAt',
            width: '15%',
            header: t('publicApi.flowApiKey.main.expires'),
            renderCell: ({ expiredAt }) => dateTimeFormat.format(expiredAt),
            getSortValue: ({ expiredAt }) => expiredAt.getTime(),
          },
          {
            id: 'active',
            width: '10%',
            header: t('misc.status'),
            renderCell: (apiAccess) => (
              <ApiAccessStateTag apiAccess={apiAccess} />
            ),
            // Active is considered "lower" than revoked
            getSortValue: ({ active }) => (active ? 1 : 0),
          },
        ]}
        emptyState={{
          title: t('publicApi.flowApiKey.list.title'),
          subtitle: t('publicApi.flowApiKey.list.subtitle'),
        }}
      />
    </div>
  );
};

export default ApiAccessList;
