import { Callout, FormField } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { SupplierAsyncAutocomplete } from 'modules/suppliers';

type Props = {
  selectedSupplier?: { id: string; name: string };
  error: boolean;
  onChange(
    change:
      | { existingSupplier: { id: string; name: string } }
      | { newSupplierName: string | undefined },
  ): void;
  showLabel?: boolean;
  isDisabled?: boolean;
};

export const SupplierFormField = ({
  selectedSupplier,
  error,
  onChange,
  showLabel = true,
  isDisabled,
}: Props) => {
  const { t } = useTranslation('global');

  const field = (
    <SupplierAsyncAutocomplete
      showUnarchiveButtonWhenArchived
      showWarningWhenIsArchived
      isInvoicePayable={false}
      fit="parent"
      placeholder={t('misc.supplier')}
      value={
        selectedSupplier
          ? { key: selectedSupplier.id, label: selectedSupplier.name }
          : undefined
      }
      onSelect={(selection) => {
        if (selection && selection?.key) {
          if (selection.key === selection.label) {
            return onChange({
              newSupplierName: selection.key.length ? selection.key : undefined,
            });
          }
          return onChange({
            existingSupplier: { id: selection.key, name: selection.label },
          });
        }
        onChange({ newSupplierName: undefined });
      }}
      isInvalid={Boolean(error)}
      isDisabled={isDisabled}
    />
  );

  if (showLabel) {
    return (
      <FormField
        label={t('expenseInbox.expenseEditor.supplier')}
        alertMessage={error ? t('misc.requiredField') : undefined}
        data-testid="supplier-autocomplete"
      >
        {field}
      </FormField>
    );
  }

  return (
    <div data-testid="supplier-autocomplete">
      {field}
      {error && (
        <Callout
          variant="alert"
          className="mt-8"
          title={t('misc.requiredField')}
        />
      )}
    </div>
  );
};
