import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { EmptyStateLoading } from 'common/components/LazyLoad';
import { routes } from 'src/core/constants/routes';

import { TransferConfirmPageSkeleton } from './InvoicesConfirmPage/TransferConfirmPageSkeleton';
import { HistoryPageSkeleton } from './InvoicesHistoryPage/HistoryPageSkeleton';
import { InvoicesPayPageSkeleton } from './InvoicesPayPage/InvoicesPayPageSkeleton';
import { InvoicesToReviewPageSkeleton } from './InvoicesToReviewPage/InvoicesToReviewPageSkeleton';

export const InvoicesPageSkeleton = () => {
  return (
    <div className="max-h-full grow overflow-hidden bg-primary-default">
      <Switch>
        <Route path={routes.INVOICES_REVIEW.path}>
          <InvoicesToReviewPageSkeleton />
        </Route>
        <Route path={routes.INVOICES_PAY.path}>
          <InvoicesPayPageSkeleton />
        </Route>
        <Route path={routes.INVOICES_HISTORY.path}>
          <HistoryPageSkeleton />
        </Route>
        <Route path={routes.INVOICES_CONFIRM.path}>
          <TransferConfirmPageSkeleton />
        </Route>
        <Route>
          <EmptyStateLoading />
        </Route>
      </Switch>
    </div>
  );
};
