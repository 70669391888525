import React from 'react';

import {
  type LocaleFormat,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';

import { AccountRiskAlert } from './components/AccountRiskAlert';
import { AccountSuspensionEffects } from './components/AccountSuspensionEffects';
import { FAQ } from './components/FAQ';
import { FundMyWallet } from './components/FundMyWallet';
import { HeaderText } from './components/HeaderText';
import { OutstandingInvoices } from './components/OutstandingInvoices';
import { type BillingStatus } from './types';

type Props = {
  billingStatus: BillingStatus;
  localeFormat: LocaleFormat;
  t: TGlobalFunctionTyped;
  companyCurrency: string;
};

export const ClosedDoor = ({
  billingStatus,
  t,
  localeFormat,
  companyCurrency,
}: Props) => (
  <div className="mx-auto flex max-w-[1500px] flex-col gap-24">
    <AccountRiskAlert t={t} />
    <div className="flex gap-24">
      <div className="ContentBlock box">
        <HeaderText
          t={t}
          localeFormat={localeFormat}
          delinquentStartDate={billingStatus.delinquentStartDate}
          overdueInvoices={billingStatus.overdueInvoicesDetails}
          companyCurrency={companyCurrency}
        />
        <AccountSuspensionEffects t={t} />
        <FAQ />
      </div>
      <div className="ContentBlock box min-w-[435px]">
        <OutstandingInvoices
          localeFormat={localeFormat}
          overdueInvoices={billingStatus.overdueInvoicesDetails}
          t={t}
          companyCurrency={companyCurrency}
        />
        <FundMyWallet t={t} />
      </div>
    </div>
  </div>
);
