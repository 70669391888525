import cx from 'classnames';
import replace from 'lodash/replace';
import truncate from 'lodash/truncate';
import React from 'react';

import { CardLabel } from './CardLabel';
import dove from '../../../../../../public/static/img/card-design/dove.png';

import './SFSCardBackDesign.css';

const CARD_WIDTH = 380;
const CARD_HEIGHT = 240;

type Props = {
  numbers: string;
  name: string;
  width?: number;
  className?: string;
};

export const SFSWhiteCardBackDesign = ({
  name,
  numbers,
  className,
  width = CARD_WIDTH,
}: Props) => {
  const height = (width * CARD_HEIGHT) / CARD_WIDTH;

  let numbersWithNoSpaces = replace(
    replace(numbers ?? '', /\s/g, ''),
    /\./g,
    '•',
  );
  if (numbersWithNoSpaces.length < 16) {
    numbersWithNoSpaces = `${numbersWithNoSpaces}${'•'.repeat(
      16 - numbersWithNoSpaces.length,
    )}`;
  }
  // We wrap each number in fixed width spans
  // to make the selected font
  // fixed width even if it isn't
  const numbersWithinSpans = (
    <>
      {[...numbersWithNoSpaces].map((character, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={{
            width: '14px',
            maxWidth: '14px',
            marginLeft: `${index > 0 && index % 4 === 0 ? 6 : 0}px`,
          }}
        >
          {character}
        </div>
      ))}
    </>
  );

  return (
    <div
      className={cx(
        'SFSCardBackDesign__card',
        'SFSCardBackDesign__physical',
        'SFSCardBackDesign__activating',
        className,
      )}
      style={{
        width,
        height,
      }}
    >
      <BackSVG width={width} height={height}>
        <CardLabel label="CARD NUMBER" className="text-primary" />
        <p
          className="mb-16 flex text-primary"
          style={{
            lineHeight: '23px',
            fontSize: 26,
          }}
        >
          {numbersWithinSpans}
        </p>
        <div>
          {name && <CardLabel label="CARD HOLDER" className="text-primary" />}
          <div
            style={{
              lineHeight: '24px',
              fontSize: 20,
            }}
            className="text-primary"
          >
            {truncate(name?.toUpperCase(), { length: 21, omission: '' })}
          </div>
        </div>
      </BackSVG>
    </div>
  );
};

const BackSVG = ({
  children,
  width,
  height,
}: {
  children: React.ReactNode;
  width: number;
  height: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 398 251"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_653_1285)">
        <g filter="url(#filter0_ii_653_1285)">
          <path
            d="M398 240.706C398 245.896 393.793 250.103 388.603 250.103C262.201 250.103 135.799 250.103 9.39737 250.103C4.20735 250.103 0 245.896 0 240.706V9.39828C0 4.20825 4.20735 0.00090017 9.39738 0.000901559C135.799 0.00093539 262.201 0.00162623 388.603 0.000910339C393.793 0.000880944 398 4.20824 398 9.39829V240.706Z"
            fill="#EEF0F3"
          />
        </g>
        <path
          d="M408.338 22.8694H-11.8687V61.7181H408.338V22.8694Z"
          fill="black"
        />
        <path
          d="M241.256 134.605C224.674 129.102 207.04 142.359 207.04 156.108C207.04 173.293 224.512 186.33 247.08 186.33C269.648 186.33 285.289 174.774 285.289 159.071C285.289 137.737 258 126.478 241.256 124.7"
          stroke="#4FDC7B"
          stroke-width="3.20049"
          stroke-linecap="round"
        />
      </g>
      <foreignObject x="34" y="130" width={width * 0.85} height={height * 0.75}>
        {children}
      </foreignObject>
      <foreignObject x="318.42" y="149.34" width="52.78" height="70.26">
        <img className="w-full object-contain" src={dove} alt="" />
      </foreignObject>
      <defs>
        <filter
          id="filter0_ii_653_1285"
          x="-1.17467"
          y="-1.17382"
          width="400.349"
          height="252.452"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.17467" dy="-1.76201" />
          <feGaussianBlur stdDeviation="0.587336" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0 0.133333 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_653_1285"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1.17467" dy="1.17467" />
          <feGaussianBlur stdDeviation="2.34934" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="overlay"
            in2="effect1_innerShadow_653_1285"
            result="effect2_innerShadow_653_1285"
          />
        </filter>
        <clipPath id="clip0_653_1285">
          <rect width="398" height="250.271" rx="11.5633" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
