import { Button, FormField, Select } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';

import { toApiFormat as toApiApprovalRules } from 'modules/company/structure/approval-flows/models';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import {
  NotificationType,
  useNotifications,
} from 'src/core/modules/app/notifications';
import { createApprovalScheme } from 'src/core/modules/company/cost-centers/containers/CostCenterFormModalContainer/hooks/createApprovalScheme';
import { editApprovalScheme } from 'src/core/modules/company/cost-centers/containers/CostCenterFormModalContainer/hooks/editApprovalSchemeQuery';
import { type ApprovalRule } from 'src/core/modules/company/structure/approval-flows';

import { useWorkflowContext } from '../../WorkflowContext';
import { defaultApprovalRules } from '../../approvalScheme';
import { DIMENSIONS, type Dimension } from '../../approvalWorkflow';
import { ApprovalScheme } from '../ApprovalScheme';
import { DimensionValuesSelect } from '../DimensionValuesSelect';

type Props = {
  approvalRules?: ApprovalRule[];
  dimension?: Dimension;
  selectedValues?: string[] | null | undefined;
  approvalSchemeId: string | undefined;
  mode: 'create' | 'edit';
  onCancel: () => void;
};

type FormProps = {
  dimension: Dimension | undefined;
  values: string[] | null | undefined;
  approvalRules: ApprovalRule[];
};

export const DimensionApprovalFlowPanelContent = ({
  onCancel,
  approvalRules,
  approvalSchemeId,
  dimension,
  selectedValues,
  mode,
}: Props) => {
  const companyId = useCompanyId();
  const { pushNotif } = useNotifications();
  const { nodes, setNodes, selectedNodeId, selectedNodeLevel } =
    useWorkflowContext();
  const {
    values: formValues,
    errors,
    setFieldValue,
    handleSubmit,
  } = useFormik<FormProps>({
    enableReinitialize: true,
    initialValues: {
      dimension: getInitialDimension(dimension, selectedNodeLevel),
      values: selectedValues,
      approvalRules: approvalRules ?? defaultApprovalRules,
    },
    onSubmit: async (values, { setSubmitting }) => {
      const updatedNodes = nodes.map((node) => {
        if (node.id === selectedNodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              dimensionName: values.dimension,
              values: values.values,
            },
          };
        }
        return node;
      });

      try {
        if (mode === 'create') {
          await createApprovalScheme(
            {
              owner: null,
              rules: toApiApprovalRules(values.approvalRules),
            },
            companyId,
          );
        }
        if (mode === 'edit' && approvalSchemeId) {
          await editApprovalScheme({
            companyId,
            payload: {
              rules: toApiApprovalRules(values.approvalRules),
            },
            approvalSchemeId,
          });
        }
      } catch (error) {
        pushNotif({
          type: NotificationType.Danger,
          message: 'Failed to update approval flow',
        });
        throw new Error('Failed to update approval flow', error);
      }
      setSubmitting(false);
      pushNotif({
        type: NotificationType.Success,
        message: 'Approval flow updated',
      });
      setNodes(updatedNodes);
      onCancel();
    },
  });

  return (
    <>
      <FormField className="text-left" label="When">
        <Select
          name="dimension"
          options={getDimensionOptions()}
          value={getDimensionOptions().find(
            (dimensionOption) => dimensionOption.key === formValues.dimension,
          )}
          // We disable the select on the first Spend Type level
          isDisabled={selectedNodeLevel === 1}
          onSelect={(value) => setFieldValue('dimension', value.key)}
          fit="parent"
          placeholder="Select dimension"
        />
      </FormField>
      {formValues.dimension &&
        formValues.values !== null && ( // TODO: null is for the 'Others' option
          <FormField className="mt-16 text-left" label="Is">
            <DimensionValuesSelect
              dimension={formValues.dimension}
              selectedValues={formValues.values}
              onSelect={(values) => setFieldValue('values', values)}
            />
          </FormField>
        )}
      <div className="bg-background-tertiary my-24 h-[1px] w-full" />
      <div className="text-content-primary mb-8 title-m">
        Approval required from
      </div>
      <ApprovalScheme
        error={
          errors.approvalRules ? (errors.approvalRules as string) : undefined
        }
        onChange={(rules) => setFieldValue('approvalRules', rules)}
        rules={formValues.approvalRules}
      />
      <div className="flex justify-between gap-16">
        <Button
          text="Save"
          className="flex-grow"
          variant="primaryBrand"
          onClick={() => {
            handleSubmit();
          }}
        />
        <Button
          text="Cancel"
          className="flex-grow"
          variant="secondaryNeutral"
          onClick={() => {
            onCancel();
          }}
        />
      </div>
    </>
  );
};

const getDimensionOptions = () => {
  return DIMENSIONS.map((dimension) => ({
    key: dimension,
    label: getDimensionTranslation(dimension),
  }));
};

const getDimensionTranslation = (dimension: Dimension) => {
  switch (dimension) {
    case 'spendType':
      return 'Spend Type';
    case 'costCenter':
      return 'Cost Center';
    // case 'ExpenseCategory':
    //   return 'Expense Category';
    default:
      return dimension;
  }
};

const getInitialDimension = (
  dimension: Dimension | undefined,
  selectedNodeLevel: number | null,
): Dimension | undefined => {
  // By spec the first level of the tree should always be Spend type
  if (!dimension && selectedNodeLevel === 1) {
    return 'spendType';
  }

  return dimension;
};
