import { Callout } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';

import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { WarningRow } from 'src/core/common/components/WarningRow/WarningRow';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import type { CostCenterReplacePreview } from './costCenterReplacePreview';
import type { FormValues } from './formValues';
import type { CostCenter } from '../../costCenter';

type Props = {
  costCenter: {
    name: string;
    replacePreview: CostCenterReplacePreview;
  };
  isReplacementRequired: boolean;
  substituteCostCenters: CostCenter[];
};

export const CostCenterDeletionDetails = ({
  costCenter,
  isReplacementRequired,
  substituteCostCenters,
  // formik props
  setFieldValue,
  errors,
}: Props & FormikProps<FormValues>) => {
  const { t } = useTranslation('global');

  return (
    <div className="text-left">
      <div className="mb-16">
        <div className="mb-8 title-m">
          {t('costCenters.deletion.transactionsLabel')}
        </div>
        <Callout
          className="px-8"
          variant="success"
          title={t('costCenters.deletion.alreadyExported')}
        />

        {costCenter.replacePreview.notReadyPaymentsCount > 0 && (
          <WarningRow
            text={t('costCenters.deletion.nonPreparedPayments', {
              count: costCenter.replacePreview.notReadyPaymentsCount,
            })}
          />
        )}
        {costCenter.replacePreview.approvedRequestsCount > 0 && (
          <WarningRow
            text={t('costCenters.deletion.approvedRequests', {
              count: costCenter.replacePreview.approvedRequestsCount,
            })}
          />
        )}
        {costCenter.replacePreview.pendingRequestsCount > 0 && (
          <WarningRow
            text={t('costCenters.deletion.pendingRequests', {
              count: costCenter.replacePreview.pendingRequestsCount,
            })}
          />
        )}
        {costCenter.replacePreview.subscriptionsCount > 0 && (
          <WarningRow
            text={t('costCenters.deletion.subscriptions', {
              count: costCenter.replacePreview.subscriptionsCount,
            })}
          />
        )}
        {costCenter.replacePreview.purchaseOrdersCount > 0 && (
          <WarningRow
            text={t('costCenters.deletion.purchaseOrders', {
              count: costCenter.replacePreview.purchaseOrdersCount,
            })}
          />
        )}
      </div>
      {costCenter.replacePreview.usersCount > 0 && (
        <div className="mb-16">
          <div className="mb-8 title-m">
            {t('costCenters.deletion.membersLabel')}
          </div>
          <WarningRow
            text={t('costCenters.deletion.members', {
              count: costCenter.replacePreview.usersCount,
            })}
          />
        </div>
      )}
      {isReplacementRequired && (
        <>
          <div className="separator my-24" />

          <div>
            <div className="mb-8 body-m">
              {t('costCenters.deletion.replaceLabel')}
            </div>
            <AutocompleteSearch
              fit="parent"
              value={undefined}
              options={substituteCostCenters.map((substituteCostCenter) => ({
                key: substituteCostCenter.id,
                label: substituteCostCenter.name,
              }))}
              placeholder={t(
                'costCenters.deletion.selectCostCenterPlaceholder',
              )}
              renderNoOptions={() => (
                <div className="p-16 text-center">
                  {t('costCenters.deletion.selectCostCenterNoOption')}
                </div>
              )}
              onSelect={(selectedCostCenter) =>
                setFieldValue('costCenterId', selectedCostCenter?.key)
              }
            />
            {errors.costCenterId && (
              <Callout
                variant="alert"
                title={errors.costCenterId}
                className="mt-8"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
