import { Button, EmptyState } from '@dev-spendesk/grapes';

import { ContactUs } from 'src/core/common/components/ContactUs';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

export const NotFound = () => {
  const { t } = useTranslation('errors');
  const companyId = useCompanyId();

  return (
    <div className="page__container bg-primary-default">
      <EmptyState
        actions={[
          <Button
            text={t('notFound.returnToHome')}
            variant="primaryBrand"
            key="go_back"
            component="a"
            href={routeFor(routes.APP.path, { company: companyId })}
          />,
          <ContactUs key="contact_us" />,
        ]}
        illustration={
          <img
            src="/static/img/card-design/physical-back-error.webp"
            className="max-w-[400px]"
            alt=""
          />
        }
        subtitle={t('notFound.subtitle')}
        title={t('somethingWrong_short')}
      />
    </div>
  );
};
