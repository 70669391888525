import {
  Button,
  Callout,
  CheckboxField,
  FormField,
  ListItem,
  ListView,
  RadioField,
  RadioGroup,
  Select,
  SkeletonText,
  TextInput,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { type FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useWindowSize } from 'react-use';

import { useTranslation } from 'common/hooks/useTranslation';
import { getHelpCenterBase } from 'common/utils/help-center/getHelpCenterBase';
import { ActiveAccountingIntegrationApi } from 'modules/bookkeep/hooks';
import { AccountingSoftwareField } from 'modules/bookkeep/settings/export/components/TemplateEditorForm/AccountingSoftwareField';
import {
  getDateFormatOptions,
  getLanguageOptions,
  rowGroupToI18nKey,
} from 'modules/bookkeep/settings/export/components/TemplateEditorForm/utils';
import {
  TemplateBuilderV2,
  type InternalColumn,
} from 'modules/bookkeep/settings/export-legacy/components/TemplateBuilder';

import { type FormValues } from './formValues';
import { type CustomField } from '../../customField';
import {
  type Column,
  type CompositeOption,
  dateDelimiters,
  decimalDelimiters,
  columnDelimiters,
  dateDelimiterToTranslationKey,
  decimalDelimiterToTranslationKey,
  columnDelimiterToTranslationKey,
  parseDateFormat,
  languageToTranslationKey,
  type PreviewRow,
  rowDisplayOptions,
  type RowDisplayOption,
  fileTypeEncodingOptions,
} from '../../template';

import './TemplateEditorForm.css';

const LOADER_ITEMS = 6;

type Props = {
  rows: PreviewRow[];
  compositePatternPartOptions: CompositeOption[];
  isTemplateEditionMode: boolean;
  isLoading: boolean;
  isComputingRows: boolean;
  searchAvailableColumns: (columns: Column[], query: string) => Column[];
  onCancel: () => void;
  customFields: CustomField[];
  expenseCategoryCustomFieldId: CustomField['id'] | undefined;
} & FormikProps<FormValues>;

export const TemplateEditorForm = ({
  rows,
  compositePatternPartOptions,
  isTemplateEditionMode,
  isLoading: isDefaultLoading,
  isComputingRows,
  searchAvailableColumns,
  onCancel,
  customFields,
  expenseCategoryCustomFieldId,
  // FormikProps
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const formReference = useRef<HTMLFormElement>(null);
  const { width } = useWindowSize();
  const [cannotReorderColumn, setCannotReorderColumn] =
    useState<InternalColumn | null>(null);

  const activeAccountingIntegration =
    ActiveAccountingIntegrationApi.useActiveAccountingIntegration();
  const isLoading =
    isDefaultLoading || activeAccountingIntegration.status === 'loading';
  const totalFixtureLines =
    activeAccountingIntegration.status === 'success' &&
    activeAccountingIntegration.data.activeAccountingIntegration ===
      'SpendeskAccountingSingleEntry'
      ? 3
      : 9;

  const dateFormatOptions = getDateFormatOptions(values.dateDelimiter);

  const languageOptions = getLanguageOptions();

  const isDoubleEntry =
    activeAccountingIntegration.status === 'success' &&
    activeAccountingIntegration.data.activeAccountingIntegration ===
      'SpendeskAccounting';

  const isSingleEntry =
    activeAccountingIntegration.status === 'success' &&
    activeAccountingIntegration.data.activeAccountingIntegration ===
      'SpendeskAccountingSingleEntry';

  const noRowDisplaySelected =
    isDoubleEntry &&
    (values.rowDisplayOptions || []).filter((o) => o.active).length === 0;

  return isLoading ? (
    <>
      <div className="flex items-center">
        <div className="TemplateEditorForm__badge body-l">1</div>
        <div className="ml-8 text-primary title-xl">
          {t('bookkeep.settings.export.setupPreferences')}
        </div>
      </div>
      <ListView className="mt-16">
        {Array.from({ length: LOADER_ITEMS }, (_, key) => (
          <ListItem
            key={key}
            className={classNames('flex flex-col', {
              'pt-16': key !== 0,
              'pb-16': key !== LOADER_ITEMS - 1,
            })}
          >
            <SkeletonText width="30%" className="mb-8" />
            <SkeletonText width="50%" />
          </ListItem>
        ))}
      </ListView>
    </>
  ) : (
    <form
      onSubmit={handleSubmit}
      noValidate
      className="TemplateEditorForm__form"
      ref={formReference}
    >
      <fieldset className="border-none">
        <legend className="flex items-center">
          <div className="TemplateEditorForm__badge body-l">1</div>
          <div className="ml-8 text-primary title-xl">
            {t('bookkeep.settings.export.setupPreferences')}
          </div>
        </legend>
        <ListView className="mb-24 mt-24">
          <ListItem>
            <div className="mb-8 mt-8">
              <FormField
                label={t('bookkeep.settings.export.nameTemplateLabel')}
                className="mb-16 w-[436px]"
                alertMessage={errors.name}
              >
                <TextInput
                  placeholder={t(
                    'bookkeep.settings.export.nameTemplatePlaceholder',
                  )}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  isInvalid={Boolean(errors.name)}
                />
              </FormField>
              <AccountingSoftwareField
                values={values}
                errors={errors}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </div>
          </ListItem>
          <ListItem>
            <p
              className="mb-8 mt-8 text-primary body-m"
              style={
                formReference.current
                  ? { width: `${formReference.current.offsetWidth}px` }
                  : undefined
              }
            >
              File
            </p>
            <FormField
              label={t('bookkeep.settings.export.fileTypeEncodingLabel')}
              className="mb-8 w-[288px]"
            >
              <Select
                fit="parent"
                value={{
                  key: values.fileTypeEncoding,
                  label: values.fileTypeEncoding,
                }}
                options={fileTypeEncodingOptions.map((value) => ({
                  key: value,
                  label: value,
                }))}
                onSelect={(option) =>
                  setFieldValue('fileTypeEncoding', option.key)
                }
                onBlur={() => setFieldTouched('fileTypeEncoding', true)}
              />
            </FormField>
            <FormField
              label={t('bookkeep.settings.export.columnDelimiterLabel')}
              infoTipContent={t(
                'bookkeep.settings.export.columnDelimiterTooltip',
              )}
              className="mb-8"
            >
              <RadioGroup
                name="columnDelimiter"
                value={values.columnDelimiter}
                onChange={handleChange}
              >
                {columnDelimiters.map((delimiter) => (
                  <RadioField
                    value={delimiter}
                    key={delimiter}
                    label={t(columnDelimiterToTranslationKey(delimiter))}
                  />
                ))}
              </RadioGroup>
            </FormField>
            <FormField
              label={t('bookkeep.settings.export.languageLabel')}
              infoTipContent={t('bookkeep.settings.export.languageTooltip')}
              className="mb-8"
            >
              <RadioGroup
                name="language"
                value={values.language}
                onChange={handleChange}
              >
                {languageOptions.map((language) => (
                  <RadioField
                    value={language}
                    key={language}
                    label={t(languageToTranslationKey(language))}
                  />
                ))}
              </RadioGroup>
            </FormField>

            <CheckboxField
              isChecked={values.shouldOutputHeaders}
              onChange={(e) =>
                setFieldValue('shouldOutputHeaders', e.target.checked)
              }
              label="Show column headers in the export?" // TODO: translate
              className="AutoCompleteMulti__list__option__checkbox mb-8 w-[436px]"
            />
          </ListItem>
          <ListItem>
            <p
              className="mb-8 mt-8 text-primary body-m"
              style={
                formReference.current
                  ? { width: `${formReference.current.offsetWidth}px` }
                  : undefined
              }
            >
              Formatting
            </p>
            <FormField
              label={t('bookkeep.settings.export.dateDelimiterLabel')}
              infoTipContent={t(
                'bookkeep.settings.export.dateDelimiterTooltip',
              )}
              className="mb-8"
            >
              <RadioGroup
                name="dateDelimiter"
                value={values.dateDelimiter}
                onChange={handleChange}
              >
                {dateDelimiters.map((delimiter) => (
                  <RadioField
                    value={delimiter}
                    key={delimiter}
                    label={t(dateDelimiterToTranslationKey(delimiter))}
                  />
                ))}
              </RadioGroup>
            </FormField>
            <FormField
              label={t('bookkeep.settings.export.dateFormatLabel')}
              className="mb-16 w-[288px]"
            >
              <Select
                fit="parent"
                value={{
                  key: values.dateFormat.join(values.dateDelimiter),
                  label: values.dateFormat.join(values.dateDelimiter),
                }}
                options={dateFormatOptions}
                placeholder={t(
                  'bookkeep.settings.export.dateFormatPlaceholder',
                )}
                onSelect={(option) =>
                  setFieldValue(
                    'dateFormat',
                    parseDateFormat(option.key, values.dateDelimiter),
                  )
                }
                onBlur={() => setFieldTouched('dateFormat', true)}
              />
            </FormField>
            <FormField
              label={t('bookkeep.settings.export.decimalDelimiterLabel')}
              infoTipContent={t(
                'bookkeep.settings.export.decimalDelimiterTooltip',
              )}
              className="mb-8"
            >
              <RadioGroup
                name="decimalDelimiter"
                value={values.decimalDelimiter}
                onChange={handleChange}
              >
                {decimalDelimiters.map((delimiter) => (
                  <RadioField
                    value={delimiter}
                    key={delimiter}
                    label={t(decimalDelimiterToTranslationKey(delimiter))}
                  />
                ))}
              </RadioGroup>
            </FormField>
          </ListItem>
          {isDoubleEntry && (
            <ListItem>
              <p
                className="mb-8 mt-8 text-primary body-m"
                style={
                  formReference.current
                    ? { width: `${formReference.current.offsetWidth}px` }
                    : undefined
                }
              >
                Rows
              </p>
              <FormField
                label={t(
                  'bookkeep.settings.export.rowDisplayOptions.headerFormLabel',
                )}
                infoTipContent={t(
                  'bookkeep.settings.export.rowDisplayOptions.headerFormLabelTooltip',
                )}
                alertMessage={
                  noRowDisplaySelected
                    ? t(
                        'bookkeep.settings.export.rowDisplayOptions.selectRowAlert',
                      )
                    : ''
                }
              >
                <div className="flex flex-col">
                  {rowDisplayOptions.map(({ group }) => {
                    const rowDisplayOptionIndex =
                      values.rowDisplayOptions?.findIndex(
                        (r) => r.group === group,
                      );

                    let rowDisplayOptionValue: RowDisplayOption | undefined;
                    if (
                      (rowDisplayOptionIndex || rowDisplayOptionIndex === 0) &&
                      rowDisplayOptionIndex !== -1
                    ) {
                      rowDisplayOptionValue =
                        values.rowDisplayOptions?.[rowDisplayOptionIndex];
                    }

                    const checkbox = (
                      <CheckboxField
                        isChecked={!!rowDisplayOptionValue?.active}
                        key={group}
                        onChange={(e) => {
                          const currentRowDisplayOptions = [
                            ...(values.rowDisplayOptions ?? []),
                          ];

                          if (
                            (rowDisplayOptionIndex ||
                              rowDisplayOptionIndex === 0) &&
                            rowDisplayOptionValue
                          ) {
                            currentRowDisplayOptions[rowDisplayOptionIndex] = {
                              ...rowDisplayOptionValue,
                              active: e.target.checked,
                            };
                          }

                          setFieldValue(
                            'rowDisplayOptions',
                            currentRowDisplayOptions,
                          );
                        }}
                        label={t(rowGroupToI18nKey[group])}
                        className="AutoCompleteMulti__list__option__checkbox w-[436px]"
                      />
                    );

                    if (
                      rowDisplayOptionValue?.group === 'analytical' &&
                      rowDisplayOptionValue?.active
                    ) {
                      return (
                        <div
                          key={`${group}_line_with_analyticalCommonLine`}
                          className="flex flex-col"
                        >
                          {checkbox}
                          <CheckboxField
                            isChecked={
                              !!rowDisplayOptionValue?.analyticalCommonLine
                                ?.active
                            }
                            key={`${group}_analyticalCommonLine`}
                            onChange={(e) => {
                              const currentRowDisplayOptions = [
                                ...(values.rowDisplayOptions ?? []),
                              ];

                              if (
                                (rowDisplayOptionIndex ||
                                  rowDisplayOptionIndex === 0) &&
                                rowDisplayOptionValue
                              ) {
                                currentRowDisplayOptions[
                                  rowDisplayOptionIndex
                                ] = {
                                  ...rowDisplayOptionValue,
                                  analyticalCommonLine: {
                                    active: e.target.checked,
                                  },
                                };
                              }

                              setFieldValue(
                                'rowDisplayOptions',
                                currentRowDisplayOptions,
                              );
                            }}
                            label={t(
                              'bookkeep.settings.export.rowDisplayOptions.analyticalCommonLine',
                            )}
                            className="pl-24"
                          />
                        </div>
                      );
                    }

                    return checkbox;
                  })}
                </div>
              </FormField>
            </ListItem>
          )}
        </ListView>
      </fieldset>
      <fieldset className="border-none">
        <legend className="flex items-center">
          <div className="TemplateEditorForm__badge body-l">2</div>
          <div className="ml-8 text-primary title-xl">
            {t('bookkeep.settings.export.selectColumnsLabel')}
          </div>
        </legend>
        <p
          className="mt-16 text-primary body-m"
          style={
            formReference.current
              ? { width: `${formReference.current.offsetWidth}px` }
              : undefined
          }
        >
          <Trans
            i18nKey="bookkeep.settings.export.exportColumnsInfo"
            components={[
              <a
                key="help"
                className="text-primary underline"
                href={`${getHelpCenterBase({
                  lang: activeLanguage,
                })}articles/4152880`}
              >
                -
              </a>,
            ]}
          />
        </p>
        <div
          style={
            formReference.current
              ? { width: `${formReference.current.offsetWidth}px` }
              : undefined
          }
        >
          <Callout
            className="mb-8 mt-16"
            title={t('exports.templatePreviewFlashMessage')}
          />
          {!isDoubleEntry && !isSingleEntry && cannotReorderColumn && (
            <Callout
              variant="warning"
              className="my-8"
              title={t('exports.cannotReorderColumnFlashMessage', {
                name: cannotReorderColumn.name,
              })}
            />
          )}
        </div>
        <TemplateBuilderV2
          customFields={customFields}
          expenseCategoryCustomFieldId={expenseCategoryCustomFieldId}
          setCannotReorderColumn={setCannotReorderColumn}
          totalFixtureLines={totalFixtureLines}
          columns={values.columns}
          onChange={(columns) => {
            setFieldValue('columns', columns);
          }}
          rowDisplayOptions={values.rowDisplayOptions}
          rows={rows}
          isLoading={isComputingRows}
          compositePatternPartOptions={compositePatternPartOptions}
          searchAvailableColumns={searchAvailableColumns}
          style={
            formReference.current
              ? {
                  width: `${width}px`,
                  marginLeft: `-${
                    (width - formReference.current.offsetWidth) / 2
                  }px`,
                }
              : undefined
          }
        />
      </fieldset>
      <div>
        <Button
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={onCancel}
        />
        <Button
          variant="primaryBrand"
          className="ml-16"
          type="submit"
          text={
            isTemplateEditionMode
              ? t('bookkeep.settings.export.saveExisting')
              : t('bookkeep.settings.export.saveNew')
          }
          isLoading={isSubmitting}
          isDisabled={isSubmitting || noRowDisplaySelected}
        />
      </div>
    </form>
  );
};
