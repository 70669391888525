import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { userToString } from 'modules/bookkeep/settings/accounting/utils';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type EmployeeAccount } from '../../../../../../../accounting/employeeAccount';
import { type ModalState } from '../ModalState';

interface Props {
  integration: AccountingSoftware;
  onDelete: (employeeAccount: EmployeeAccount) => Promise<void>;
  modalState: ModalState;
  setModalState: Dispatch<SetStateAction<ModalState>>;
}

export const EmployeeAccountDeleteModal = ({
  integration,
  onDelete,
  modalState,
  setModalState,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      title={
        modalState.kind === 'confirmDelete'
          ? t(
              'bookkeep.integrations.settings.employeeAccountsTable.confirmDeleteTitle',
              {
                accountName: modalState.employeeAccount.user
                  ? userToString(modalState.employeeAccount.user)
                  : t(
                      'bookkeep.integrations.settings.employeeAccountsTable.deletedEmployeeAccount',
                    ),
              },
            )
          : ''
      }
      subtitle={
        hasIntegrationFileBasedExport(integration)
          ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
          : t(
              'bookkeep.integrations.settings.employeeAccountsTable.confirmSendBackToPrepareSubtitle',
            )
      }
      isOpen={modalState.kind === 'confirmDelete'}
      iconVariant="alert"
      iconName="trash"
      actions={[
        <Button
          key="cancel"
          onClick={() => setModalState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondaryNeutral"
        />,
        <Button
          key="yes"
          onClick={() => {
            if (modalState.kind === 'confirmDelete') {
              onDelete(modalState.employeeAccount);
            }

            setModalState({ kind: 'closed' });
          }}
          text={t(
            'bookkeep.integrations.settings.expenseAccountsTable.confirmDeletion',
          )}
          variant="primaryAlert"
        />,
      ]}
    />
  );
};
