import React, { type ReactNode } from 'react';

type Props = {
  top?: ReactNode;
  textPrimary?: string;
  textSecondary?: string | ReactNode;
  children?: ReactNode;
};

export function PanelHeader({
  top,
  textPrimary,
  textSecondary,
  children,
}: Props) {
  return (
    <>
      {top && <div className="mb-24">{top}</div>}
      {textPrimary && (
        <div className="text-primary body-xxl">{textPrimary}</div>
      )}
      {textSecondary && (
        <div className="mt-8 text-secondary-bg-primary body-m">
          {textSecondary}
        </div>
      )}
      {children && <div className="mt-24">{children}</div>}
    </>
  );
}
