import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { DownloadAppBadges } from 'common/components/DownloadAppBadges';
import { useTranslation } from 'common/hooks/useTranslation';
import CaroleCooper from 'modules/StrongCustomerAuthentication/images/carole-cooper.svg';
import QrCode from 'modules/StrongCustomerAuthentication/images/qr-code.svg';
import ScanToDownload from 'modules/StrongCustomerAuthentication/images/scan-to-download.svg';
import { openSupportChat } from 'src/core/utils/supportChat';
import './ScaWireTransferActivationModal.css';

interface Props {
  isOpen: boolean;
  isUserEnrolledError: boolean;
  onClose: () => void;
  onEnrollUser: () => void;
}

export const ScaWireTransferActivationModal = ({
  isOpen,
  isUserEnrolledError,
  onClose,
  onEnrollUser,
}: Props) => {
  const { t } = useTranslation('global');

  const renderCalloutIfError = () => {
    return (
      isUserEnrolledError && (
        <Callout
          className="ScaWireTransferActivationModal__content__error"
          title={t('wiretransfer.modal.sca.activateWireTransfer.error')}
          variant="alert"
        />
      )
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      iconName="lock-close"
      iconVariant="info"
      title={t('wiretransfer.modal.sca.activateWireTransfer.title')}
      subtitle={t('wiretransfer.modal.sca.activateWireTransfer.desc')}
      actions={[
        <Button
          key="haveSetUpApp"
          text={t('wiretransfer.modal.sca.activateWireTransfer.haveSetUpApp')}
          variant="primaryBrand"
          onClick={onEnrollUser}
        />,
      ]}
      onClose={onClose}
    >
      <div className="ScaWireTransferActivationModal__content">
        <div className="ScaWireTransferActivationModal__content__imgs">
          <img
            src={ScanToDownload}
            className="ScaWireTransferActivationModal__content__imgs__scan-to-download"
            alt="Scan to download"
          />
          <img
            src={QrCode}
            className="ScaWireTransferActivationModal__content__imgs__qr-code"
            alt="QR code"
          />
          <DownloadAppBadges className="ScaWireTransferActivationModal__content__imgs__badges" />
          <img
            src={CaroleCooper}
            className="ScaWireTransferActivationModal__content__imgs__carole-cooper"
            alt="Working woman holding phone"
          />
        </div>

        {renderCalloutIfError()}

        <Button
          className="ScaWireTransferActivationModal__content__need-help"
          key="needHelp"
          text={t('wiretransfer.modal.sca.activateWireTransfer.needHelp')}
          variant="tertiaryNeutral"
          onClick={() => openSupportChat()}
        />
      </div>
    </Modal>
  );
};
