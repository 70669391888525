import { FormField, Input, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import {
  type TypeFormValues,
  type DirectionFormValues,
  type FormValues,
  type JournalCodeFormValues,
} from './form';

export const CommonEditComponents = ({
  values,
  handleChange,
  touched,
  errors,
}:
  | FormikProps<FormValues>
  | FormikProps<DirectionFormValues>
  | FormikProps<TypeFormValues>
  | FormikProps<JournalCodeFormValues>) => {
  const { t } = useTranslation('global');

  return (
    <>
      <FormField
        label={t('exports.renameInputLabel')}
        alertMessage={
          touched.title && Boolean(errors.title) ? errors.title : undefined
        }
      >
        <TextInput
          name="title"
          id="title"
          value={values.title}
          onChange={handleChange}
          isInvalid={touched.title && Boolean(errors.title)}
        />
      </FormField>
      <FormField label={t('exports.columnLengthLabel')} className="mt-24">
        <Input
          name="maxLength"
          id="maxLength"
          value={values.maxLength}
          type="number"
          onChange={handleChange}
        />
      </FormField>
    </>
  );
};
