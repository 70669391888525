import { ListItem, ListView } from '@dev-spendesk/grapes';
import React from 'react';
import { useDispatch } from 'react-redux';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import type { AppDispatch } from 'modules/app/redux/store';
import { fetchWireTransferActivationStatus } from 'modules/company/billing-legacy/redux';
import FEATURES from 'src/core/constants/features';

import { useShouldDisplayWireTransferPaymentMethod } from './useShouldDisplayWireTransferPaymentMethod';
import { WireTransfer } from '../../../WireTransfer';
import { getAllowedSpendingMethods } from '../../spendingMethods';
import { SpendingMethod } from '../SpendingMethod';

const COUNTRY_MILEAGE_AUTO_SETUP = ['DE', 'FR', 'GB'];

export const ListSpendingMethods = () => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const hasMileageScheme =
    !!company.mileage_scheme ||
    COUNTRY_MILEAGE_AUTO_SETUP.includes(company.country);

  const dispatch = useDispatch<AppDispatch>();
  const isInvoiceEnabled = useFeature(FEATURES.SUBMIT_MY_INVOICE);
  const isCostCenterActivated = useFeature(FEATURES.COST_CENTERS_ACTIVATED);
  const isExpenseClaimsEnabled = useFeature(FEATURES.EXPENSE_CLAIMS);

  const isPurchaseOrderEnabled = useFeature(FEATURES.PURCHASE_ORDERS_ACTIVATED);
  const isControlOnPurchaseOrderEnabled = useFeature(
    FEATURES.CONTROL_ON_PURCHASE_ORDER,
  );
  const getShouldDisplayWireTransferPaymentMethod =
    useShouldDisplayWireTransferPaymentMethod();

  React.useEffect(() => {
    dispatch(fetchWireTransferActivationStatus());
  }, [isExpenseClaimsEnabled]);

  // Memoized spending methods to avoid infinite loop
  const allowedSpendingMethods = React.useMemo(() => {
    return getAllowedSpendingMethods(company.country, {
      isInvoiceEnabled,
      isControlOnPurchaseOrderEnabled,
      isPurchaseOrderEnabled,
      isCostCenterActivated,
      hasMileageScheme,
    });
  }, [
    isInvoiceEnabled,
    isPurchaseOrderEnabled,
    isControlOnPurchaseOrderEnabled,
    isCostCenterActivated,
    hasMileageScheme,
  ]);

  return (
    <ErrorBoundary context={{ scope: 'list-spending-methods', team: 'kyc' }}>
      <section aria-labelledby="paymentsSettings">
        <h1 id="paymentsSettings" className="text-primary heading-l">
          {t('payments.settings.title')}
        </h1>
        <p className="mt-8 text-secondary-bg-primary body-m">
          {t('payments.settings.description')}
        </p>
        <ListView className="mt-24">
          {allowedSpendingMethods.map(({ featureCode, isDisabled }) => (
            <ListItem key={featureCode}>
              <SpendingMethod
                featureCode={featureCode}
                isDisabled={isDisabled}
              />
            </ListItem>
          ))}
          {getShouldDisplayWireTransferPaymentMethod('expenses') && (
            <ListItem>
              <WireTransfer wireTransferPayableEntity="expenses" />
            </ListItem>
          )}
          {getShouldDisplayWireTransferPaymentMethod('invoices') && (
            <ListItem>
              <WireTransfer wireTransferPayableEntity="invoices" />
            </ListItem>
          )}
        </ListView>
      </section>
    </ErrorBoundary>
  );
};
