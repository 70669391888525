import { Button } from '@dev-spendesk/grapes';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { getI18nContext } from 'src/core/selectors/i18n';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

export const HomepageWalletDetailNoFunds = () => {
  const { t } = useTranslation('global');
  const i18nContext = useSelector(getI18nContext);
  const { company } = useParams();
  return (
    <div className="flex flex-col gap-20 rounded-4">
      <div className="flex flex-col content-stretch gap-8">
        <h2 className="text-primary title-m">
          {t('homepage.wallet.emptyTitle', { ...i18nContext })}
        </h2>
        <p className="text-secondary-bg-secondary body-m">
          {t('homepage.wallet.emptyDescription', { ...i18nContext })}
        </p>
      </div>
      <Link
        to={routeFor(routes.COMPANY_BANK_FUNDING.path, { company })}
        onClick={() => {
          track(AnalyticEventName.HOMEPAGE_WALLET_ADD_FUNDS_BUTTON_CLICKED);
        }}
      >
        <Button
          text={t('homepage.wallet.addFunds', { ...i18nContext })}
          variant="primaryBrand"
        />
      </Link>
    </div>
  );
};
