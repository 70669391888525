import { ModalSlideshow } from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import illustration from './assets/illustration.webp';

type Props = {
  isOpen: boolean;
};

export const SuccessModal = ({ isOpen }: Props) => {
  const history = useHistory();

  const { t } = useTranslation('global');

  const companyId = useCompanyId();

  const goToHub = () => {
    const setupHubPath = routeFor(routes.SETUP_HUB.path, {
      company: companyId,
    });
    history.push(setupHubPath);
  };

  return (
    <ModalSlideshow
      isOpen={isOpen}
      slides={[
        {
          title: t('setupHub.exportGeneralSettings.successModal.title'),
          content: (
            <p className="text-primary body-l">
              <Trans
                i18nKey="setupHub.exportGeneralSettings.successModal.description"
                components={{
                  a: (
                    <Link
                      className="text-primary underline"
                      to={routeFor(routes.COMPANY_EXPORTS.path, {
                        company: companyId,
                      })}
                    >
                      -
                    </Link>
                  ),
                }}
              />
            </p>
          ),
          illustration: <img src={illustration} alt="" />,
        },
      ]}
      onCancel={goToHub}
      onClose={goToHub}
      onDone={goToHub}
      translations={{
        cancel: t('misc.cancel'),
        previous: t('misc.back'),
        next: t('misc.next'),
        done: t('misc.done'),
      }}
    />
  );
};
