import { Button } from '@dev-spendesk/grapes';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

type Props = {
  company: {
    id: string;
  };
};

export const HomepageFirstFundingInProgress = ({ company }: Props) => {
  const { t } = useTranslation('global');
  return (
    <div className="text-center">
      <h2 className="text-primary title-l">
        {t('homepage.firstFundingInProgress.title')}
      </h2>
      <p className="mb-16 mt-4 text-secondary-bg-secondary body-m">
        {t('homepage.firstFundingInProgress.description')}
      </p>
      <Link
        to={routeFor(routes.COMPANY_BANK_FUNDING_SOURCE.path, {
          company: company.id,
        })}
      >
        <Button
          iconName="clock-outline"
          iconPosition="left"
          isDisabled
          text={t('homepage.firstFundingInProgress.cta')}
          variant="secondaryNeutral"
        />
      </Link>
    </div>
  );
};
