import { Button, Callout, Modal, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type DeclarationData } from './declarationData';

type Props = Readonly<{
  companyName: string;
  onCancel: () => void;
  onContinue: () => void;
  onBack: () => void;
}>;

export const AffidavitUserRoleModal = ({
  companyName,
  onCancel,
  onContinue,
  onBack,
  // formik props
  handleBlur,
  values,
  setFieldValue,
  errors,
  dirty,
}: Props & FormikProps<DeclarationData>) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      onClose={onCancel}
      iconName="receipt-question"
      title={t('payments.affidavitModal.userRoleModal.title', { companyName })}
      actions={[
        <Button
          key="back"
          variant="secondaryNeutral"
          text={t('payments.affidavitModal.userRoleModal.backButton')}
          onClick={onBack}
        />,
        <Button
          key="continue"
          text={t('payments.affidavitModal.userRoleModal.continueButton')}
          onClick={onContinue}
          isDisabled={Boolean(errors.userRole || !dirty)}
        />,
      ]}
    >
      <TextInput
        fit="parent"
        name="userRole"
        value={values.userRole}
        onBlur={handleBlur}
        onChange={(event) => setFieldValue('userRole', event.target.value)}
      />
      {errors.userRole && (
        <Callout title={errors.userRole} variant="alert" className="mt-8" />
      )}
    </Modal>
  );
};
