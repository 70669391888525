import { colors, Icon } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import styles from './WarningRow.module.css';

type Props = {
  text: string;
};

export const WarningRow = ({ text }: Props) => {
  return (
    <div className={classNames(styles.WarningRow, 'shadow-xs')}>
      <Icon name="triangle-warning" color={colors.contentWarningDefault} />
      <div className="body-m">{text}</div>
    </div>
  );
};
