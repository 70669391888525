import React from 'react';

import { SchedulingProcess } from '../../../../components/SchedulingProcess';
import { type TransferDetails } from '../../../models';

type Props = {
  transfer: TransferDetails;
};

export const SchedulingProcessSection = ({ transfer }: Props) => {
  const { bill, schedulingProcess } = transfer;

  return (
    <SchedulingProcess
      className="mt-16"
      schedulingProcess={schedulingProcess}
      bill={bill}
      activeItemId={transfer.id}
      isEditable={false}
      isCollapsible
    />
  );
};
