import { datadogRum } from '@datadog/browser-rum-slim';

import CONFIG from '../core/config';

if (CONFIG.datadog) {
  datadogRum.init({
    applicationId: CONFIG.datadog.appId,
    clientToken: CONFIG.datadog.clientToken,
    site: 'datadoghq.eu',
    service: 'app-desktop',
    env: CONFIG.environmentName,
    version: process.env.REACT_APP_SHA,
    sessionSampleRate: 20,
    trackLongTasks: true,
    trackUserInteractions: false,
    allowedTracingUrls: [
      CONFIG.apiUrls.base,
      CONFIG.apiUrls.api,
      CONFIG.apiUrls.auth,
    ],
    traceSampleRate: 20,
  });
}

export const addSpendeskContextToDatadogRum = ({
  userId,
  companyId,
}: {
  userId: string;
  companyId: string;
}) => {
  if (CONFIG.datadog && Boolean(datadogRum.getInitConfiguration())) {
    datadogRum.setUser({
      id: userId,
      companyId,
    });
  }
};
