import { Button, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useNotifications } from 'modules/app/notifications';
import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

import { useAccountsQuery } from '../../../app/hooks/useAccountQuery';
import { type BankDetails } from '../types';

type Props = {
  t: TGlobalFunctionTyped;
};

type BankInfo = {
  name: string;
  value: string;
};

export const FundMyWallet = ({ t }: Props) => {
  const { successNotif } = useNotifications();
  const accountsQuery = useAccountsQuery();

  const renderBankDetails = (bankDetails: BankDetails | null) => {
    if (!bankDetails) {
      return null;
    }

    const bankDetailsReshaped: BankInfo[] = [
      ...(bankDetails.iban && bankDetails.bic
        ? [
            {
              name: t('overdueInvoice.closedDoor.fundWallet.bankInfo.iban'),
              value: bankDetails.iban,
            },
            {
              name: t('overdueInvoice.closedDoor.fundWallet.bankInfo.bic'),
              value: bankDetails.bic,
            },
          ]
        : []),
      ...(bankDetails.accountNumber && bankDetails.sortCode
        ? [
            {
              name: t(
                'overdueInvoice.closedDoor.fundWallet.bankInfo.accountNumber',
              ),
              value: bankDetails.accountNumber,
            },
            {
              name: t('overdueInvoice.closedDoor.fundWallet.bankInfo.sortCode'),
              value: bankDetails.sortCode,
            },
          ]
        : []),
      ...(bankDetails.beneficiary
        ? [
            {
              name: t(
                'overdueInvoice.closedDoor.fundWallet.bankInfo.beneficiary',
              ),
              value: bankDetails.beneficiary,
            },
          ]
        : []),
      {
        name: t('overdueInvoice.closedDoor.fundWallet.bankInfo.bankName'),
        value: bankDetails.bankName,
      },
      {
        name: t('overdueInvoice.closedDoor.fundWallet.bankInfo.bankAddress'),
        value: bankDetails.bankAddress,
      },
    ];

    return bankDetailsReshaped.map(({ name, value }: BankInfo, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className="flex justify-evenly">
        <div className="flex flex-1 items-center gap-16">
          <span className="text-neutral-dark w-1/4 title-m">{name}</span>
          <span className="text-content-primary body-m">{value}</span>
        </div>
        <Button
          text={t('misc.copy')}
          variant="tertiaryNeutral"
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(value);
              successNotif(t('misc.textCopiedToClipboard'));
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error);
            }
          }}
          aria-label={t('misc.copy', { name })}
        />
      </div>
    ));
  };

  return (
    <div className="flex flex-col gap-24">
      <div className="flex flex-col gap-8">
        <h2 className="title-xl">
          {t('overdueInvoice.closedDoor.fundWallet.title')}
        </h2>
        <p className="body-m">
          {t('overdueInvoice.closedDoor.fundWallet.description')}
        </p>
      </div>
      <QuerySuspense
        queryState={accountsQuery}
        loading={<SkeletonText width="80%" className="mt-s" />}
        fallback={(error) => (
          <QueryError
            componentType="ErrorState"
            queryError={error}
            translations={{
              networkError: 'misc.errors.networkError',
              serverError: 'misc.errors.serverError',
              requestError: () => 'misc.errors.networkError',
            }}
          />
        )}
      >
        {(account) => {
          const bankDetails: BankDetails | null = account
            ? {
                iban: account.iban,
                bic: account.bic,
                accountNumber: account.account_number,
                sortCode: account.sort_code,
                beneficiary: account.beneficiary,
                bankName: account.bank_name,
                bankAddress: account.bank_address,
              }
            : null;
          return <div>{renderBankDetails(bankDetails)}</div>;
        }}
      </QuerySuspense>
    </div>
  );
};
