import { type MonetaryValue } from 'ezmoney';

import type { User as UserRedux } from 'modules/app/hooks/useUser';
import { type PurchaseOrderRequestDetails } from 'modules/requests/purchase-order/models/purchaseOrderRequestDetails';

import { type CostCenter } from './costCenter';
import { type Permission } from './permission';
import { type RequestState } from './requestState';
import { type RequestType } from './requestType';
import { type Supplier } from './supplier';
import { type Team } from './team';
import { type User } from './user';

export type PurchaseOrderRequestItem = {
  name: string;
  quantity: number;
  unitPrice: MonetaryValue;
  vatRate: number;
};

export interface PurchaseOrderRequest {
  id: string;
  type: RequestType;
  state: RequestState;
  createdAt: string;
  deletedAt: string | null;
  description: string;
  amount: MonetaryValue;
  netAmount: MonetaryValue;
  startDate: string; // 'YYYY-MM-DD' format
  endDate: string; // 'YYYY-MM-DD' format
  costCenter?: CostCenter;
  team?: Team;
  supplier: Supplier;
  requester: User;
  userPermission: Permission;
  items?: PurchaseOrderRequestItem[];
  purchaseOrderId?: string;
}

export const MaxPurchaseOrderDateRangeInYears = 50;
export const MinPurchaseOrderStartDate = new Date(2000, 0);

export const isPurchaseOrderRequestApprovable = ({
  state,
  userPermission,
}: PurchaseOrderRequest): boolean =>
  state === 'pending' && userPermission.canApprove;

export const isUserRequester = (
  { requester }: PurchaseOrderRequest,
  user: { id: string },
): boolean => {
  return user.id === requester.id;
};

export const isPendingRequest = ({ state }: PurchaseOrderRequest): boolean => {
  return state === 'pending';
};

export const canCancelPurchaseOrder = (
  purchaseOrderRequest: PurchaseOrderRequest,
  user: { id: string; is_admin: boolean },
): boolean =>
  isPendingRequest(purchaseOrderRequest) &&
  (isUserRequester(purchaseOrderRequest, user) || user.is_admin);

export const canAddQuoteToPurchaseOrderRequest = (
  purchaseOrderRequest: PurchaseOrderRequest,
  user: UserRedux,
): boolean => {
  return (
    isUserRequester(purchaseOrderRequest, user) &&
    isPendingRequest(purchaseOrderRequest)
  );
};

export const getPurchaseOrderManagerRejectionReason = (
  appraisals?: NonNullable<
    PurchaseOrderRequestDetails['approval']
  >['appraisals'],
): string | undefined => {
  return (
    appraisals?.find((appraisal) => appraisal.appraisalType === 'denial')
      ?.reason ?? undefined
  );
};
