import { Button, FormField, Modal, TextInput } from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { type Column } from 'modules/bookkeep/settings/export';

type Props = {
  column: Column | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
};

export const UpdateColumnNameModal = ({
  column,
  isOpen,
  onClose,
  onSave,
}: Props) => {
  const { t } = useTranslation('global');

  const [name, setName] = useState<string>(column?.name ?? '');
  const isValid = name.trim().length > 0;

  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  // Set the name when the modal is opened
  useEffect(() => {
    if (isOpen) {
      setName(column?.name ?? '');
    }
  }, [isOpen]);

  const onSubmit = () => {
    if (!isValid) {
      setHasSubmitted(true);
      return;
    }

    onSave(name.trim());
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('exports.columnEditModalTitle', { title: column?.name ?? '' })}
      subtitle={column?.description}
      onClose={onClose}
      iconName="pen"
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={onClose}
        />,
        <Button
          key="saveChanges"
          text={t('misc.saveChanges')}
          onClick={onSubmit}
        />,
      ]}
    >
      <FormField
        label={t('exports.renameInputLabel')}
        alertMessage={
          hasSubmitted && !isValid ? t('misc.requiredField') : undefined
        }
      >
        <TextInput
          name="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </FormField>
    </Modal>
  );
};
