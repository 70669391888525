import React from 'react';

import { track } from 'src/core/utils/analytics';

import { WelcomePanelButton } from './WelcomePanelButton';
import { useGetActionsConfig, useGetNewAccountActionsConfig } from './helper';

type Props = {
  isNewAccount: boolean;
  companyId: string;
  actions: {
    requestsToReview: number;
    invoicesToPay: number;
    invoiceTransfersToConfirm?: number;
    expenseTransfersToConfirm?: number;
    paymentsWithoutReceipts: number;
    paymentsToPrepare: number;
  };
  user: {
    is_admin: boolean;
    is_account_owner: boolean;
  };
};

export const WelcomePanelButtons = ({
  isNewAccount,
  companyId,
  actions,
  user,
}: Props) => {
  const activeActionsConfig = useGetActionsConfig(actions, companyId);
  const actionsConfig = useGetNewAccountActionsConfig(companyId, user);

  if (!actions) {
    return null;
  }

  if (isNewAccount) {
    return (
      <>
        {actionsConfig.map((action) => (
          <WelcomePanelButton
            key={action.key}
            link={action.link}
            title={action.title}
            onClick={() => track(action.analyticEvent)}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {activeActionsConfig.map((action) => (
        <WelcomePanelButton
          key={action.key}
          link={action.link}
          title={action.title}
          numberOfItems={actions[action.key as keyof typeof actions]}
          onClick={() => track(action.analyticEvent)}
        />
      ))}
    </>
  );
};
