import { Badge, Icon } from '@dev-spendesk/grapes';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  link: string;
  title?: string;
  numberOfItems?: number;
  onClick?: () => void;
};

export const WelcomePanelButton = ({
  link,
  title,
  numberOfItems,
  onClick = () => {},
}: Props) => {
  if (!title) {
    return null;
  }

  return (
    <Link
      to={link}
      onClick={onClick}
      className="rounded-8 border border-solid border-default bg-primary-default px-12 py-8 text-primary body-m hover:border-hover hover:bg-primary-hover"
    >
      <div className="flex w-full items-center justify-between gap-8">
        <p className="overflow-hidden text-ellipsis text-nowrap" title={title}>
          {title}
        </p>
        <div className="flex items-center gap-8">
          {numberOfItems && <Badge key="link-highlight">{numberOfItems}</Badge>}
          <Icon
            aria-hidden="true"
            name="chevron-right"
            size="m"
            className="order-2"
          />
        </div>
      </div>
    </Link>
  );
};
