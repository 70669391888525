export type TitleState =
  | 'controller'
  | 'requester'
  | 'requesterOnboarding'
  | 'requesterRestricted'
  | 'requesterRestrictedByAdmin'
  | 'requesterPaymentsOverdue'
  | 'requesterPaymentsToComplete';

export const getHeaderTitle = (
  state: TitleState,
  actionCountsSum: number,
): string => {
  switch (state) {
    case 'controller':
      return getControllerTitle(actionCountsSum);
    case 'requester':
      return 'homepage.requester.introNoPayments';
    case 'requesterOnboarding':
      return 'homepage.requester.introOnboarding';
    case 'requesterRestricted':
      return 'homepage.requester.introRestricted';
    case 'requesterRestrictedByAdmin':
      return 'homepage.requester.introRestrictedByAdmin';
    case 'requesterPaymentsOverdue':
      return 'homepage.requester.introSomeOverduePayments';
    case 'requesterPaymentsToComplete':
      return 'homepage.requester.introSomePayments';
    default:
      return '';
  }
};

const getControllerTitle = (actionCountsSum: number) => {
  if (actionCountsSum !== 0) {
    return 'homepage.todo.intro';
  }
  return '';
};
