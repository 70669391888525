import { type Filter } from '@spendesk/bookkeeping-core-types';
import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type PayableSubType } from 'modules/bookkeep/payables/containers/PayablePanelContainer';
import { type FiltersState } from 'modules/bookkeep/payables/containers/PayablesFiltersContainer/hooks/useFiltersContext';
import type { Payable } from 'modules/payable/models';
import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';
import {
  type FilterWithOptionalField,
  withoutOptionalFields,
} from 'src/core/common/components/FilterBuilder';

import { type RawResult, query } from './query';
import {
  reshapeMember,
  reshapeSupplier,
  reshapeCounterparty,
  reshapeMileageDetails,
  reshapePerDiem,
  reshapeFilters,
} from './reshaper';
import { toCoreTypeFilter } from '../payable-filter/payableFilterTransformer';
import { getPayableListQueryKey } from '../query-key-registry';

export const useInvalidateGetPayableListQueryCache = () => {
  const queryClient = useQueryClient();

  return async (companyId: string, filters?: FiltersState): Promise<void> => {
    await queryClient.invalidateQueries(
      getPayableListQueryKey(companyId, filters),
    );
  };
};

export type PayableListRawResponse = RawResult;

export type PayableListVariables = {
  companyId: string;
  cursor: string | number | undefined;
  filtersV2: Filter | undefined;
  textualSearch: string | undefined;
} & FiltersState;

export const useGetPayableList = ({
  filters,
  filtersV2: rawFiltersV2,
  options: { isEnabled = true, ...options },
}: {
  filters: FiltersState;
  filtersV2: FilterWithOptionalField | undefined;
  options: { onSuccess: () => void; isEnabled?: boolean };
}): InfiniteQueryState<Payable[]> => {
  const companyId = useCompanyId();

  const reshapedFilters = reshapeFilters(filters);

  const filtersV2 = rawFiltersV2
    ? toCoreTypeFilter(withoutOptionalFields(rawFiltersV2))
    : undefined;

  return useInfiniteQuery<Payable, RawResult>({
    key: getPayableListQueryKey(companyId, reshapedFilters, filtersV2),
    getRequest: (cursor) => {
      const variables: PayableListVariables = {
        companyId,
        cursor,
        ...reshapedFilters,
        filtersV2,
        textualSearch: filters.search,
      };

      return {
        type: 'graphQL',
        target: 'v2',
        query: query({ filtersV2 }),
        variables,
      };
    },
    getNextPageParam(data) {
      if (data.company.payables.pageInfo.hasNextPage) {
        const { edges, pageInfo } = data.company.payables;
        const lastEdge = edges.at(-1);

        if (pageInfo.hasNextPage && lastEdge) {
          return lastEdge.cursor;
        }
        return undefined;
      }
    },
    reshapeData(data) {
      return data.company.payables.edges.map(({ node: rawPayable }) => {
        return {
          id: rawPayable.id,
          version: rawPayable.version,
          subtype: (rawPayable.subtype as PayableSubType) || undefined,
          kind: rawPayable.kind,
          description: rawPayable.description,
          counterparty: rawPayable.counterparty
            ? reshapeCounterparty(rawPayable.counterparty)
            : undefined,
          member: rawPayable.member
            ? reshapeMember(rawPayable.member)
            : undefined,
          supplier: rawPayable.supplier
            ? reshapeSupplier(rawPayable.supplier)
            : undefined,
          documentaryEvidence: rawPayable.documentaryEvidence,
          grossAmount: rawPayable.grossAmount,
          functionalAmount: rawPayable.functionalAmount,
          creationDate: new Date(rawPayable.creationDate),
          mileageDetails: rawPayable.mileageDetails
            ? reshapeMileageDetails(rawPayable.mileageDetails)
            : undefined,
          perDiem: rawPayable.perDiem
            ? reshapePerDiem(rawPayable.perDiem)
            : undefined,
        };
      });
    },
    options,
    isEnabled,
  });
};
