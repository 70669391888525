import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// TODO Should be moved to common components folder
export const FilteredListBySearchWrapper = <T extends object>({
  items,
  searchableFields,
  isSearchEnabled,
  isSyncedWithUrl = false,
  children,
}: {
  items: T[];
  searchableFields: (keyof T)[];
  isSearchEnabled: boolean;
  isSyncedWithUrl?: boolean;
  children: ({
    search,
    setSearch,
    filteredItems,
    hasActiveSearch,
  }: {
    search: string;
    setSearch: (search: string) => void;
    filteredItems: T[];
    hasActiveSearch: boolean;
  }) => React.ReactNode;
}) => {
  const [search, setSearch] = useState<string>('');

  const sanitizedTerm = search.trim().toLowerCase();
  const filterBySearchTerm = (item: T) => {
    return searchableFields
      .map((field) => String(item[field]).toLowerCase())
      .some((itemField) => itemField.includes(sanitizedTerm));
  };

  const querySearch = useLocation().search.toString();
  const [hasActiveSearch, setHasActiveSearch] = useState(false);
  useEffect(() => {
    const term = new URLSearchParams(querySearch).get('q')?.trim() || '';
    setHasActiveSearch(term.length > 0);
  }, []);

  useEffect(() => {
    if (isSyncedWithUrl && isSearchEnabled) {
      const searchTermFromUrl =
        new URLSearchParams(querySearch).get('q')?.trim() || '';
      if (searchTermFromUrl.length > 0) {
        setHasActiveSearch(true);
        setSearch(searchTermFromUrl);
      }
    }
  }, [isSearchEnabled]);

  const history = useHistory();
  useEffect(() => {
    if (isSyncedWithUrl && isSearchEnabled) {
      const searchParams = new URLSearchParams(querySearch);
      if (sanitizedTerm.length > 0) {
        setHasActiveSearch(true);
        searchParams.set('q', sanitizedTerm);
      } else {
        setHasActiveSearch(false);
        searchParams.delete('q');
      }

      history.push({
        search: searchParams.toString(),
      });
    }
  }, [sanitizedTerm, isSearchEnabled]);

  return children({
    search,
    setSearch,
    filteredItems: items.filter((entity) => filterBySearchTerm(entity)),
    hasActiveSearch,
  });
};
