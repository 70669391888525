import classNames from 'classnames';

import styles from './DotSeparator.module.css';

export const DotSeparator = () => {
  return (
    <span
      className={classNames(
        'mx-[6px] h-[3px] w-[3px] self-center rounded-[50%]',
        styles.DotSeparator,
      )}
    />
  );
};
