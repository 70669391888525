import {
  type DraftExpense,
  type RawExpense,
} from '@finance-review/models/expense';
import { isNilOrEmpty } from 'common/utils/extended-lodash';
import { type ExpenseBeneficiary } from 'modules/requests/expense/api/useExpenseBenefericiariesQuery';
import { type Beneficiary } from 'modules/requests/expense/expense-form/model';
import { getDisplayNameRegisteredBeneficiary } from 'modules/requests/models/beneficiaries';

import { transformRawUserToRequester } from './transformRawUserToRequester';

export const transformRawExpense = (rawExpense: RawExpense): DraftExpense => {
  const requester = transformRawUserToRequester(rawExpense);

  const expense = {
    id: rawExpense.id,
    requester,
    duplicate: !isNilOrEmpty(rawExpense?.duplicates)
      ? rawExpense?.duplicates[0]
      : undefined,
    expenseDate: rawExpense.expense_paid_at
      ? new Date(rawExpense.expense_paid_at)
      : undefined,
    amount: rawExpense.amount_declared ?? 0,
    currency: rawExpense.currency_declared,
    amountToReimburse: rawExpense.amount_to_refund ?? 0,
    teamId: rawExpense?.group_id ?? undefined,
    costCenterId: rawExpense.cost_center?.id ?? undefined,
    supplier: rawExpense.supplier
      ? {
          id: rawExpense.supplier.id,
          name: rawExpense.supplier.name,
        }
      : {
          id: undefined,
          name: undefined,
        },
    description: rawExpense.description,
    timeline: rawExpense.timeline ?? undefined,
    customFieldsAssociations: rawExpense.custom_fields
      ? rawExpense.custom_fields.map((item) => ({
          customFieldId: item.field?.id,
          customFieldValueId: item.value.id,
          value: item.value.value,
        }))
      : [],
    invoices: rawExpense.invoices,
    requesterId: rawExpense.user?.id ?? rawExpense.user_id,
  };

  if (rawExpense.type === 'mileage_allowance') {
    return {
      ...expense,
      type: 'mileage_allowance',
      mileageAllowanceRequest: {
        vehicle: rawExpense.mileage_allowance_request.vehicle,
        passengers: rawExpense.mileage_allowance_request.passengers,
        mileageAllowanceRequestJourneys:
          rawExpense.mileage_allowance_request.mileage_allowance_request_journeys.map(
            (journey) => ({
              ...journey,
              mileageAllowanceRequestJourneyLocations:
                journey.mileage_allowance_request_journey_locations,
            }),
          ),
      },
      mileageScheme: rawExpense.mileage_scheme,
    };
  }

  if (rawExpense.type === 'per_diem_allowance') {
    return {
      ...expense,
      type: 'per_diem_allowance',
      perDiem: rawExpense.perDiem,
    };
  }

  return {
    ...expense,
    expenseBeneficiaries: (rawExpense.expenseBeneficiaries ?? []).map(
      reshapeExpenseBeneficiary,
    ),
    type: 'expense_claim',
  };
};

const reshapeExpenseBeneficiary = (
  expenseBeneficiary: ExpenseBeneficiary,
): Beneficiary => {
  if (expenseBeneficiary.type === 'registeredUser') {
    return {
      type: 'registeredUser',
      registeredUserId: expenseBeneficiary.registeredUser.id,
      fullName: getDisplayNameRegisteredBeneficiary(expenseBeneficiary),
      avatarUrl: expenseBeneficiary.registeredUser.avatarUrl ?? '',
    };
  }
  return {
    type: 'unregisteredUser',
    unregisteredUserName: expenseBeneficiary.unregisteredUser.name,
    unregisteredUserCompanyName:
      expenseBeneficiary.unregisteredUser.companyName,
  };
};
