import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';

type Props = {
  onClose: () => void;
};

export const NoBankableEntityModal = ({ onClose }: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();
  const isUkCompany = company.currency === 'GBP';

  return (
    <Modal
      isOpen
      title={t('noMoreBankableEntity.title')}
      subtitle={
        isUkCompany
          ? t('noMoreBankableEntity.subtitleUk')
          : t('noMoreBankableEntity.subtitle')
      }
      iconName="hexagone-cross"
      iconVariant="alert"
      actions={[
        <Button key="close" onClick={onClose} text={t('misc.close')} />,
      ]}
    />
  );
};
