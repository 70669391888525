import type {
  Filter,
  MemberId,
  SavedFilter as PayableSavedFilterWithoutId,
} from '@spendesk/bookkeeping-core-types';
import type { CompanyId } from '@spendesk/company-core-types';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUserId } from 'modules/app/hooks/useUserId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';
import {
  type SelectedFilter,
  type SavedFilter,
} from 'src/core/common/components/FilterBuilder';

import {
  reshapeFilter,
  reshapeFilterForApi,
  useUpdatePayableFilterQueryCache,
} from './usePayableFilter';

/**
 * @public
 */
export type CreatePayableFilterError = unknown;

/**
 * @public
 */
export type CreatePayableFilterRawResponse = {
  payableFilter: {
    id: string;
    name: string;
    filter: Filter;
    userId: MemberId;
    companyId: CompanyId;
  };
};

/**
 * @public
 */
export type CreatePayableFilterResponse = SavedFilter;

/**
 * @public
 */
export type CreatePayableFilterPayload = SelectedFilter;

/**
 * Mutation
 **/

export const useCreatePayableFilter = (): MutationState<
  CreatePayableFilterPayload,
  CreatePayableFilterResponse,
  CreatePayableFilterError
> => {
  const companyId = useCompanyId() as CompanyId;
  const userId = useUserId() as MemberId;

  const updatePayableFilterQueryCache = useUpdatePayableFilterQueryCache();

  const [mutate, ...rest] = useMutation<
    PayableSavedFilterWithoutId,
    CreatePayableFilterResponse,
    CreatePayableFilterRawResponse,
    CreatePayableFilterError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/bookkeeping/payables/filters',
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ rawData: { payableFilter } }) => {
        updatePayableFilterQueryCache(payableFilter.id, payableFilter);
      },
    },
    reshapeData(data) {
      return reshapeFilter(data.payableFilter);
    },
  });

  return [
    (params) => mutate(reshapeFilterForApi(companyId, userId, params)),
    ...rest,
  ];
};
