import { FormField } from '@dev-spendesk/grapes';
import classNames from 'classnames';

import {
  type InputValueId,
  type PreparePayablesInputConfig,
} from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/panelInputConfig';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { createInputComponent } from './factories';

type Props<T extends PreparePayablesInputConfig> = {
  input: T;
  allowNewValue?: boolean;
  error?: string;
  showErrorMessage?: boolean;
  value: T['selectedValue'];
  className?: string;
  warningMessage?: string;
  onChange: (value: InputValueId | boolean | null) => void;
  onCreateNewValue?: (newValue: string) => Promise<string | undefined>;
  onInputChanged?: (value?: string) => void;
  automationMessage?: string;
};

export const PayableInputComponent = <T extends PreparePayablesInputConfig>({
  input,
  allowNewValue = false,
  error = undefined,
  showErrorMessage = true,
  value,
  className = '',
  // TODO: warning message for archived custom field values
  warningMessage,
  onChange,
  onCreateNewValue,
  onInputChanged,
  automationMessage,
}: Props<T>) => {
  const { t } = useTranslation();

  return (
    <FormField
      className={classNames('[&:not(:last-of-type)]:my-24', className)}
      label={input.name || ''}
      hint={input.isOptional ? t('misc.optional') : undefined}
      alertMessage={showErrorMessage && error ? t(error) : undefined}
      warningMessage={showErrorMessage ? warningMessage : undefined}
      visuallyHideLabel={!input.name}
    >
      {createInputComponent(
        {
          ...input,
          selectedValue: value,
        },
        (newValue) => {
          onChange(newValue);
        },
        t,
        {
          label: input.name,
          allowMultipleValues: false,
          openOnTop: false,
          allowNewValue,
          onCreateNewValue,
          error,
          onInputChanged,
          automationMessage,
        },
      ) || <></>}
    </FormField>
  );
};
