import { Redirect, Route, Switch } from 'react-router';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import type { NavigationLinkProps } from 'src/core/common/components/NavigationLink';
import { ProtectedRoute } from 'src/core/common/components/ProtectedRoute';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { useIntegrationStatusQuery } from 'src/core/modules/bookkeep';
import type {
  AccountingSoftware,
  AnalyticalFieldsCapability,
} from 'src/core/modules/bookkeep/integration/status';
import ExportsContainer from 'src/core/modules/bookkeep/settings/export/ExportsContainer';
import { canAccessAnalyticalFieldsSettings } from 'src/core/modules/bookkeep/settings/integrations/helper/settings-access';
import { IntegrationsAccountingSetupPage } from 'src/core/modules/bookkeep/settings/integrations/pages/IntegrationsAccountingSetupPage/IntegrationsAccountingSetupPage';
import { AnalyticalFieldsSection } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/AnalyticalFieldsSection';
import { BookkeepingStartDateSectionContainer } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/BookkeepingSetupSection';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

function getAnalyticalFieldsI18nKey(integration: AccountingSoftware): I18nKey {
  switch (integration) {
    case 'Datev':
      return 'bookkeep.integrations.datev.analyticalFieldsHeader';
    case 'Quickbooks':
    case 'Xero':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Netsuite':
    case 'Sage':
    case 'Cegid':
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return 'bookkeep.integrations.settings.sectionAnalyticalFields';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export const SettingsAccountingPage = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const company = useCompany();
  const user = useUser();
  const isAccountingExportsSettingsEnabled = useFeature(
    FEATURES.SETTINGS_ACCOUNTING_EXPORTS,
  );
  const isAccountingSettingsEnabled = useFeature(FEATURES.SETTINGS_ACCOUNTING);

  const accountingIntegrationStatusQueryResult = useIntegrationStatusQuery();
  const integrationsStatus =
    accountingIntegrationStatusQueryResult.status === 'success'
      ? accountingIntegrationStatusQueryResult.data
      : undefined;

  const hasIntegration =
    accountingIntegrationStatusQueryResult.status === 'success' &&
    accountingIntegrationStatusQueryResult.data.integration !== 'noIntegration';

  const links: NavigationLinkProps[] = [];

  const isAoOrController = user.is_account_owner || user.is_controller;

  if (isAccountingSettingsEnabled && isAoOrController) {
    links.push({
      key: 'accounting-integration',
      path: routeFor(routes.COMPANY_ACCOUNTING_INTEGRATION.path, {
        company: companyId,
      }),
      text: t('bookkeep.integrations.settings.sectionAccountingSoftware'),
    });

    links.push({
      key: 'bookkeeping-date',
      path: routeFor(routes.COMPANY_ACCOUNTING_BOOKKEEPING_SETUP.path, {
        company: companyId,
      }),
      text: t('bookkeep.integrations.settings.sectionBookkeepingSetup'),
    });
  }

  if (
    isAccountingExportsSettingsEnabled &&
    hasIntegration &&
    !company.churning_at &&
    isAoOrController
  ) {
    links.push({
      key: 'exports',
      path: routeFor(routes.COMPANY_EXPORTS.path, {
        company: companyId,
      }),
      text: t('settings.links.exports'),
    });
  }

  if (canAccessAnalyticalFieldsSettings(integrationsStatus)) {
    links.push({
      key: 'custom-fields',
      text: t(getAnalyticalFieldsI18nKey(integrationsStatus.integration)),
      path: routeFor(routes.COMPANY_ACCOUNTING_ANALYTICAL_FIELDS.path, {
        company: companyId,
      }),
    });
  }

  return (
    <div className="page__container">
      <HeaderWithNavigation links={links}>
        {t('navigation.accounting')}
      </HeaderWithNavigation>
      <div className="grow bg-primary-default p-24">
        <div className="flex max-w-[760px] flex-col gap-48">
          <Switch>
            <Route exact path={routes.COMPANY_ACCOUNTING_INTEGRATION.path}>
              <IntegrationsAccountingSetupPage />
            </Route>
            <Route path={routes.COMPANY_ACCOUNTING_BOOKKEEPING_SETUP.path}>
              <BookkeepingStartDateSectionContainer />
            </Route>
            <Route path={routes.COMPANY_EXPORTS.path}>
              <ExportsContainer />
            </Route>
            <ProtectedRoute
              path={routes.COMPANY_ACCOUNTING_ANALYTICAL_FIELDS.path}
              isAccessAllowed={canAccessAnalyticalFieldsSettings(
                integrationsStatus,
              )}
            >
              {canAccessAnalyticalFieldsSettings(integrationsStatus) && (
                <AnalyticalFieldsSection
                  integration={integrationsStatus.integration}
                  analyticalFieldsCapability={
                    integrationsStatus.capabilities
                      .analyticalFields as AnalyticalFieldsCapability
                  }
                />
              )}
            </ProtectedRoute>
            <Redirect
              from={routes.COMPANY_ACCOUNTING.path}
              to={routes.COMPANY_ACCOUNTING_INTEGRATION.path}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};
