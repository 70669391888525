import { Button, Modal } from '@dev-spendesk/grapes';
import { useMemo, type Dispatch, type SetStateAction } from 'react';

import { useNotifications } from 'modules/app/notifications';
import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { useSetTaxAccountMutation } from 'modules/bookkeep/settings/integrations/hooks/useSetTaxAccountMutation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ModalState } from '../ModalState';

interface Props {
  integration: AccountingSoftware;
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
  hideSubtitle?: boolean;
}

export const ReverseChargeDeleteModal = ({
  integration,
  formState,
  setFormState,
  hideSubtitle = false,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const [setTaxAccount] = useSetTaxAccountMutation();

  const subtitle = useMemo(() => {
    if (hideSubtitle) {
      return undefined;
    }
    return hasIntegrationFileBasedExport(integration)
      ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
      : t(
          'bookkeep.integrations.settings.vatAccountsTable.confirmSendBackToPrepareSubtitle',
        );
  }, [hideSubtitle, integration]);

  return (
    <Modal
      title={
        formState.kind === 'confirmDeleteReverseCharge'
          ? t(
              'bookkeep.integrations.settings.reverseChargeAccountsTable.confirmDeleteTaxAccountTitle',
              { accountName: formState.name },
            )
          : ''
      }
      subtitle={subtitle}
      isOpen={formState.kind === 'confirmDeleteReverseCharge'}
      iconVariant="alert"
      iconName="trash"
      actions={[
        <Button
          key="cancel"
          onClick={() => setFormState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondaryNeutral"
        />,
        <Button
          key="yes"
          onClick={() => {
            try {
              if (formState.kind === 'confirmDeleteReverseCharge') {
                setTaxAccount({
                  id: formState.id,
                  name: formState.name,
                  codeDebit: formState.codeDebit,
                  codeCredit: formState.codeCredit,
                  type: 'reverseCharge',
                  isArchived: true,
                });
              }
              setFormState({ kind: 'closed' });
            } catch {
              dangerNotif(t('misc.errors.unknownError'));
            }
          }}
          text={t(
            'bookkeep.integrations.settings.reverseChargeAccountsTable.confirmDeletion',
          )}
          variant="primaryAlert"
        />,
      ]}
    />
  );
};
