import {
  type MemberId,
  type SavedFilter as PayableSavedFilterWithoutId,
} from '@spendesk/bookkeeping-core-types';
import { type CompanyId } from '@spendesk/company-core-types';
import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  fromCoreTypeFilter,
  toCoreTypeFilter,
} from 'modules/payable/hooks/api/payable-filter/payableFilterTransformer';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryError } from 'src/core/api/queryError';
import {
  type SavedFilter,
  type SelectedFilter,
  withoutOptionalFields,
} from 'src/core/common/components/FilterBuilder';
import { useQueryError } from 'src/core/common/components/QueryError';

import { getPayableFilterQueryKey } from '../query-key-registry';

/**
 * @public
 */
export type PayableFilterError = unknown;

type PayableSavedFilter = PayableSavedFilterWithoutId & {
  id: string;
};

/**
 * Query and cache config
 */

/**
 * @public
 */
export const useInvalidatePayableFilterQueryCache = () => {
  const queryClient = useQueryClient();
  const companyId = useCompanyId();

  return async (): Promise<void> => {
    await queryClient.invalidateQueries(getPayableFilterQueryKey(companyId));
  };
};

/**
 * @public
 */
export const useUpdatePayableFilterQueryCache = () => {
  const queryClient = useQueryClient();
  const companyId = useCompanyId();

  return (
    filterId: string,
    newFilter?: PayableFilterRawResponse['payableFilters'][number],
  ) => {
    const data = queryClient.getQueryData<PayableFilterRawResponse>(
      getPayableFilterQueryKey(companyId),
    );

    if (data) {
      const existingIndex = data.payableFilters.findIndex(
        (filter) => filter.id === filterId,
      );
      let payableFilters;
      if (newFilter) {
        if (existingIndex !== -1) {
          payableFilters = data.payableFilters.map((filter, index) =>
            index === existingIndex ? newFilter : filter,
          );
        } else {
          payableFilters = data.payableFilters.concat(newFilter);
        }
      } else {
        payableFilters = data.payableFilters.filter(
          (filter) => filter.id !== filterId,
        );
      }
      queryClient.setQueryData<PayableFilterRawResponse>(
        getPayableFilterQueryKey(companyId),
        {
          ...data,
          payableFilters,
        },
      );
    }
  };
};

/**
 * REST query hook
 */

/**
 * @public
 */
export type PayableFilterRawResponse = {
  payableFilters: PayableSavedFilter[];
};

/**
 * @public
 */
export type PayableFilterResponse = SavedFilter[];

export const usePayableFilter = () => {
  const companyId = useCompanyId();

  return useQuery<PayableFilterResponse, PayableFilterRawResponse>({
    key: getPayableFilterQueryKey(companyId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/bookkeeping/payables/filters`,
    },
    reshapeData: (data) =>
      data.payableFilters.map((filter) => reshapeFilter(filter)),
  });
};

/**
 * Error messages
 */

type TranslationParams = Record<string, unknown>;

/**
 * @public
 */
export const usePayableFilterErrorMessage = () => {
  const getErrorMessage = useQueryError<PayableFilterError, TranslationParams>({
    requestError: () => 'common.filterBuilder.createFilterError.unknown',
    serverError: 'common.filterBuilder.createFilterError.unknown',
  });

  return (
    queryError: QueryError<PayableFilterError>,
    translationParams?: TranslationParams,
  ): string => getErrorMessage(queryError, translationParams);
};

/**
 * Filter reshaper
 */

export const reshapeFilter = (savedFilter: PayableSavedFilter): SavedFilter => {
  return {
    ...savedFilter,
    filter: fromCoreTypeFilter(savedFilter.filter),
  };
};

/**
 * Filter reshaper for API
 */

export const reshapeFilterForApi = (
  companyId: CompanyId,
  userId: MemberId,
  filter: SelectedFilter,
): PayableSavedFilterWithoutId => {
  return {
    ...filter,
    companyId,
    userId,
    filter: toCoreTypeFilter(withoutOptionalFields(filter.filter)),
  };
};
