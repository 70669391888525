import { Button, Callout } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useUserRoles } from 'src/core/modules/app/hooks/useUserRoles';

interface Props {
  className?: string;
  showUnarchiveButtonWhenArchived?: boolean;
}

export const SupplierArchivedCallout = ({
  className,
  showUnarchiveButtonWhenArchived = false,
}: Props) => {
  const { isController } = useUserRoles();
  const companyId = useCompanyId();
  const history = useHistory();
  const { t } = useTranslation('global');

  return (
    <Callout
      variant="warning"
      title={t('forms.supplier.archivedCallout')}
      className={className}
    >
      {isController && showUnarchiveButtonWhenArchived && (
        <Button
          text={t('forms.supplier.unarchive')}
          variant="primaryWarning"
          onClick={() => {
            history.push(
              routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
                company: companyId,
              }),
            );
          }}
        />
      )}
    </Callout>
  );
};
