import { type Lang } from 'modules/profile/models/lang';

type GetHelpCenterBase = {
  lang: Lang;
};

export const getHelpCenterBase = ({
  lang = 'en',
}: GetHelpCenterBase): string => {
  const langLocales = {
    fr: 'fr',
    en: 'en',
    de: 'en', // TODO: i18n German
    es: 'es',
  };

  const hcLang = langLocales[lang] || 'en';

  return `https://helpcenter.spendesk.com/${hcLang}/`;
};
