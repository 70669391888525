import { Button } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { Children, type ReactNode, useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './SeeMore.module.css';

type Props = {
  maxRows?: number;
  children: ReactNode;
  className?: string;
};

export const SeeMore = ({ maxRows = 4, children, className }: Props) => {
  const { t } = useTranslation('global');
  const hasCollapseAction = Children.count(children) > maxRows;
  const [isListCollapsed, setIsListCollapsed] = useState(true);
  if (Children.count(children) === 0) {
    return null;
  }
  return (
    <div className="flex w-full flex-col">
      <div
        className={classNames(
          className,
          'relative flex max-w-full flex-col gap-16 overflow-hidden',
        )}
      >
        {hasCollapseAction && isListCollapsed && (
          <div className={styles.collapsedBackground} />
        )}
        {Children.map(children, (child, index) => {
          const isHidden =
            hasCollapseAction && isListCollapsed && index >= maxRows;

          return <div className={isHidden ? 'hidden' : ''}>{child}</div>;
        })}
      </div>
      {hasCollapseAction &&
        (isListCollapsed ? (
          <Button
            className="mt-8"
            text={t('misc.seeMore')}
            onClick={() => setIsListCollapsed(false)}
            iconName="chevron-down"
            iconPosition="right"
            variant="secondaryNeutral"
          />
        ) : (
          <Button
            className="mt-8"
            text={t('misc.seeLess')}
            onClick={() => setIsListCollapsed(true)}
            iconName="chevron-up"
            iconPosition="right"
            variant="secondaryNeutral"
          />
        ))}
    </div>
  );
};
