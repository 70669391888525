import {
  type I18nKey,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';
import { TypeGuardError } from 'src/core/utils/switchGuard';

import { type UsersManagedByKomboHr } from '../../members/hooks/useGetManagedUsersByKomboHrIntegrationQuery';
import { integrationLogo } from '../assets/integration-logos/integration-logo';
import logoSpendesk from '../assets/spendesk.svg';
import {
  komboIntegrations,
  type IntegrationError,
  type IntegrationId,
  type KomboIntegration,
} from '../types';

export const isKomboIntegration = (
  integrationId: IntegrationId,
): integrationId is KomboIntegration =>
  (komboIntegrations as unknown as string[]).includes(integrationId);

export const isKnownIntegration = (integrationId: IntegrationId) => {
  if (isKomboIntegration(integrationId)) {
    return true;
  }

  switch (integrationId) {
    case 'travelperk':
    case 'spendesk-oauth2':
      return true;
    default:
      return false;
  }
};

export const getIntegrationLogo = (integrationId: IntegrationId) => {
  if (isKomboIntegration(integrationId) || integrationId === 'travelperk') {
    return integrationLogo[integrationId];
  }

  switch (integrationId) {
    case 'spendesk-oauth2':
      // TODO@api update logo
      return logoSpendesk;
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationLogo: Unknown integrationId',
      );
  }
};

export const getPartnerIntegrationLogoByName = (name: string) => {
  const logo = Object.keys(integrationLogo).includes(name);

  if (logo) {
    return integrationLogo[name as keyof typeof integrationLogo];
  }

  return logoSpendesk;
};

export const getSpendeskLogo = () => logoSpendesk;

export const getIntegrationName = (
  t: TGlobalFunctionTyped,
  integrationId: IntegrationId,
) => {
  if (isKomboIntegration(integrationId)) {
    return t(integrationNameToI18nKey[integrationId]);
  }

  switch (integrationId) {
    case 'travelperk':
      return t('integration.travelperk.name');
    case 'spendesk-oauth2':
      return t('integration.spendesk-oauth2.name');
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationName: Unknown integrationId',
      );
  }
};

export const integrationNameToI18nKey: Record<IntegrationId, I18nKey> = {
  alexishr: 'integration.kombo.alexishr.name',
  bamboohr: 'integration.kombo.bamboohr.name',
  breathehr: 'integration.kombo.breathehr.name',
  cezannehr: 'integration.kombo.cezannehr.name',
  charliehr: 'integration.kombo.charliehr.name',
  deel: 'integration.kombo.deel.name',
  eurecia: 'integration.kombo.eurecia.name',
  factorial: 'integration.kombo.factorial.name',
  googleworkspace: 'integration.kombo.googleworkspace.name',
  hibob: 'integration.kombo.hibob.name',
  humaans: 'integration.kombo.humaans.name',
  kenjo: 'integration.kombo.kenjo.name',
  lucca: 'integration.kombo.lucca.name',
  entraid: 'integration.kombo.entraid.name',
  officient: 'integration.kombo.officient.name',
  okta: 'integration.kombo.okta.name',
  payfitpartner: 'integration.kombo.payfitpartner.name',
  personio: 'integration.kombo.personio.name',
  remotecom: 'integration.kombo.remotecom.name',
  sagehr: 'integration.kombo.sagehr.name',
  successfactors: 'integration.kombo.successfactors.name',
  workday: 'integration.kombo.workday.name',
  travelperk: 'integration.travelperk.name',
  'spendesk-oauth2': 'integration.spendesk-oauth2.name',
};

export const getIntegrationUrl = (
  t: TGlobalFunctionTyped,
  integrationId: IntegrationId,
) => {
  switch (integrationId) {
    case 'alexishr':
      return t('integration.kombo.alexishr.url');
    case 'bamboohr':
      return t('integration.kombo.bamboohr.url');
    case 'breathehr':
      return t('integration.kombo.breathehr.url');
    case 'cezannehr':
      return t('integration.kombo.cezannehr.url');
    case 'charliehr':
      return t('integration.kombo.charliehr.url');
    case 'deel':
      return t('integration.kombo.deel.url');
    case 'eurecia':
      return t('integration.kombo.eurecia.url');
    case 'factorial':
      return t('integration.kombo.factorial.url');
    case 'googleworkspace':
      return t('integration.kombo.googleworkspace.url');
    case 'hibob':
      return t('integration.kombo.hibob.url');
    case 'humaans':
      return t('integration.kombo.humaans.url');
    case 'kenjo':
      return t('integration.kombo.kenjo.url');
    case 'lucca':
      return t('integration.kombo.lucca.url');
    case 'entraid':
      return t('integration.kombo.entraid.url');
    case 'officient':
      return t('integration.kombo.officient.url');
    case 'okta':
      return t('integration.kombo.okta.url');
    case 'payfitpartner':
      return t('integration.kombo.payfitpartner.url');
    case 'personio':
      return t('integration.kombo.personio.url');
    case 'remotecom':
      return t('integration.kombo.remotecom.url');
    case 'sagehr':
      return t('integration.kombo.sagehr.url');
    case 'successfactors':
      return t('integration.kombo.successfactors.url');
    case 'workday':
      return t('integration.kombo.workday.url');
    // Non kombo
    case 'travelperk':
    case 'spendesk-oauth2':
      return null;
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationName: Unknown integrationId',
      );
  }
};

export const getHelpCenter = (integrationId: IntegrationId) => {
  if (isKomboIntegration(integrationId)) {
    return 'https://helpcenter.spendesk.com/articles/8469957';
  }

  switch (integrationId) {
    case 'travelperk':
      return 'https://helpcenter.spendesk.com/articles/8006223';
    case 'spendesk-oauth2':
      return 'https://www.spendesk.com';
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationName: Unknown integrationId',
      );
  }
};

export const getIntegrationDescriptionKey = (integrationId: IntegrationId) => {
  if (isKomboIntegration(integrationId)) {
    return 'integration.kombo.integration.description';
  }

  switch (integrationId) {
    case 'travelperk':
      return 'integration.travelperk.description';
    case 'spendesk-oauth2':
      return 'integration.spendesk-oauth2.description';
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationDescription: Unknown integrationId',
      );
  }
};

export const getIntegrationDetailsDescription = (
  t: TGlobalFunctionTyped,
  integrationId: IntegrationId,
) => {
  if (isKomboIntegration(integrationId)) {
    return t('integration.kombo.integration.details.description', {
      name: t(integrationNameToI18nKey[integrationId]),
    });
  }

  switch (integrationId) {
    case 'travelperk':
      return t('integration.travelperk.details.description');
    case 'spendesk-oauth2':
      return t('integration.spendesk-oauth2.details.description');
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationFullDescription: Unknown integrationId',
      );
  }
};

export const getIntegrationErrorTitle = (
  t: TGlobalFunctionTyped,
  integrationError: IntegrationError,
) => {
  switch (integrationError) {
    case 'unknown':
      return t('integration.errors.unknown.title');
    case 'wrongPlan':
      return t('integration.errors.wrongPlan.title');
    case 'notConnected':
      return t('integration.errors.notConnected.title');
    case 'pendingSync':
      return t('integration.errors.pendingSync.title');
    case 'pendingAction':
      return t('integration.errors.pendingAction.title');
    case 'hrIntegrationAlreadyConnected':
      return t('integration.errors.hrIntegrationAlreadyConnected.title');
    default:
      throw new TypeGuardError(
        integrationError,
        'getIntegrationErrorTitle: Unknown integrationError',
      );
  }
};

export const getIntegrationErrorDetails = (
  t: TGlobalFunctionTyped,
  integrationError: IntegrationError,
) => {
  switch (integrationError) {
    case 'unknown':
      return t('integration.errors.unknown.details');
    case 'wrongPlan':
      return t('integration.errors.wrongPlan.details');
    case 'notConnected':
      return t('integration.errors.notConnected.details');
    case 'pendingSync':
      return t('integration.errors.pendingSync.details');
    case 'pendingAction':
      return t('integration.errors.pendingAction.details');
    case 'hrIntegrationAlreadyConnected':
      return t('integration.errors.hrIntegrationAlreadyConnected.details');
    default:
      throw new TypeGuardError(
        integrationError,
        'getIntegrationErrorDetails: Unknown integrationError',
      );
  }
};

export const canRefreshSettings = (integrationId: IntegrationId) => {
  if (isKomboIntegration(integrationId)) {
    return true;
  }

  switch (integrationId) {
    case 'travelperk':
    case 'spendesk-oauth2':
      return false;
    default:
      throw new TypeGuardError(
        integrationId,
        'canRefreshSettings: Unknown integrationId',
      );
  }
};

export const isIntegrationWithAutomaticallyCollectedReceipt = (
  integrationId: IntegrationId,
) => {
  if (isKomboIntegration(integrationId)) {
    return false;
  }

  switch (integrationId) {
    case 'travelperk':
      return true;
    case 'spendesk-oauth2':
      return false;
    default:
      throw new TypeGuardError(
        integrationId,
        'isIntegrationWithAutomaticallyCollectedReceipt: Unknown integrationId',
      );
  }
};

export type MemberIntegrationMetadata =
  | {
      existsInKombo: true;
      iconName: 'triangle-warning' | 'circle-check';
      iconVariant: 'warning' | 'success';
      tooltipText: string;
    }
  | { existsInKombo: false };

export const classifyManagedEmployees = ({
  memberId,
  usersManagedByIntegration,
}: {
  memberId: string;
  usersManagedByIntegration: UsersManagedByKomboHr | undefined;
}) => {
  if (
    !usersManagedByIntegration ||
    !usersManagedByIntegration.managedUsers.has(memberId)
  ) {
    return 'notManaged';
  }

  return usersManagedByIntegration.managedUsers.get(memberId)
    ? 'onSync'
    : 'outOfSync';
};

export const memberIntegrationMetadata = ({
  memberId,
  usersManagedByIntegration,
  t,
}: {
  memberId: string;
  usersManagedByIntegration: UsersManagedByKomboHr | undefined;
  t: TGlobalFunctionTyped;
}): MemberIntegrationMetadata => {
  if (
    usersManagedByIntegration &&
    usersManagedByIntegration.managedUsers.has(memberId)
  ) {
    const activelyManaged =
      usersManagedByIntegration.managedUsers.get(memberId);
    return {
      existsInKombo: true,
      tooltipText: activelyManaged
        ? t('integration.members.tooltipOnSync')
        : t('integration.members.tooltipOutOfSync'),
      iconName: activelyManaged
        ? ('circle-check' as const)
        : ('triangle-warning' as const),
      iconVariant: activelyManaged
        ? ('success' as const)
        : ('warning' as const),
    };
  }

  return {
    existsInKombo: false,
  };
};
