import classNames from 'classnames';
import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import {
  type ActionsCountsState,
  sumActionCounts,
} from 'src/core/modules/homepage/models/actionCounts';
import { type AppState } from 'src/core/reducers';
import { getUserPaymentCount } from 'src/core/selectors/users';

import { WelcomePanelContentSkeleton } from './WelcomePanelContentSkeleton';
import { getHeaderTitle } from '../../../../header';
import {
  useActionsCountsQuery,
  useAoActionsCountsQuery,
  useDisplayMode,
  useHomepageMode,
  useTitleState,
} from '../../../../hooks';
import { WelcomePanelActions } from '../WelcomePanelActions/WelcomePanelActions';

export const WelcomePanelContent = () => {
  const user = useUser();
  const displayMode = useDisplayMode();
  const mode = useHomepageMode();
  const company = useCompany();
  const userPaymentCount = useSelector((state: AppState) =>
    getUserPaymentCount(state, company.id),
  );
  const controllerActionsCountsQueryState = useActionsCountsQuery(
    displayMode === 'controller' && !user.is_account_owner,
  );
  const aoActionsCountsQueryState = useAoActionsCountsQuery(
    displayMode === 'controller' && user.is_account_owner,
  );
  const actionsCountsQueryState = user.is_account_owner
    ? aoActionsCountsQueryState
    : controllerActionsCountsQueryState;

  const actionCountsSum =
    actionsCountsQueryState.status === 'success'
      ? sumActionCounts(actionsCountsQueryState.data)
      : 0;

  const actionsCountsState: ActionsCountsState =
    actionsCountsQueryState.status === 'success'
      ? {
          loading: false,
          counts: actionsCountsQueryState.data,
        }
      : { loading: true };

  const titleState = useTitleState({ displayMode, userPaymentCount });
  const subtitle = getHeaderTitle(titleState, actionCountsSum);
  const noCount =
    !actionsCountsState.loading &&
    sumActionCounts(actionsCountsState.counts) === 0;

  return (
    <div className="flex flex-col justify-stretch gap-24">
      <p
        className={classNames('text-secondary-bg-primary body-l', {
          'text-alert-default': mode === 'danger',
        })}
      >
        <Trans i18nKey={subtitle} />
      </p>
      {displayMode === 'controller' && !noCount && (
        <QuerySuspense
          queryState={actionsCountsQueryState}
          loading={<WelcomePanelContentSkeleton />}
          fallback={(error) => (
            <QueryError queryError={error} componentType="Callout" />
          )}
        >
          {(actionsCounts) => (
            <WelcomePanelActions actionsCounts={actionsCounts} />
          )}
        </QuerySuspense>
      )}
    </div>
  );
};
