import { HighlightIcon, Link } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';

import {
  getCookiePolicyLink,
  getLegalNoticeLink,
  getPrivacyPolicyLink,
  getTermsOfServiceLink,
} from '../helpers';

export const Legals = () => {
  const { t, activeLanguage } = useTranslation('global');

  return (
    <section
      className="page__container flex w-full flex-col content-stretch bg-primary-default p-40"
      aria-labelledby="legals"
    >
      <div className="flex flex-col content-stretch gap-16">
        <h1 id="legals" className="text-primary heading-xl">
          {t('legals.title')}
        </h1>
        <h2 className="text-secondary-bg-primary body-m">
          {t('legals.subtitle')}
        </h2>
      </div>
      <hr
        className="separator mb-40 mt-24"
        style={{
          borderTop: 'none',
        }}
      />
      <div className="space-around flex justify-center">
        <div className="flex flex-wrap items-center gap-16 rounded-12 bg-secondary-default p-16">
          <HighlightIcon name="link" variant="pink" size="m" />
          <Link href={getTermsOfServiceLink(activeLanguage)} isExternal>
            {t('legals.termsOfServiceLink')}
          </Link>
          <Link href={getPrivacyPolicyLink(activeLanguage)} isExternal>
            {t('legals.privacyPolicyLink')}
          </Link>
          <Link href={getLegalNoticeLink()} isExternal>
            {t('legals.legalNoticeLink')}
          </Link>
          <Link href={getCookiePolicyLink(activeLanguage)} isExternal>
            {t('legals.cookiePolicyLink')}
          </Link>
          <button
            className="cursor-pointer border-none bg-primary-default text-[inherit] underline body-m"
            onClick={() => {
              window.consentManager.openConsentManager();
            }}
            type="button"
          >
            {t('legals.cookieSettingsLink')}
          </button>
        </div>
      </div>
    </section>
  );
};
