import { Button, FormField, Modal, TextInput } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import {
  type FormValues,
  validateCustomFieldsModalForm,
} from '../../validators';

type Props = {
  isOpen: boolean;
  customFieldValues: string[];
  onClose: () => void;
  onSubmit: (value: string) => void;
};

export const CreateCustomFieldValueModal = ({
  isOpen,
  onClose,
  onSubmit,
  customFieldValues,
}: Props) => {
  const { t } = useTranslation('global');

  const formikProps = useFormik<FormValues>({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      value: '',
    },
    validate: (values) => {
      return validateCustomFieldsModalForm(values, customFieldValues, t);
    },
    onSubmit: (values) => {
      onSubmit(values.value);
    },
  });

  useEffect(() => {
    // clear input manually as the modal is "always opened" due to the isOpen state
    formikProps.setFieldValue('value', '');
  }, [isOpen]);

  return (
    <Modal
      iconName="circle-information"
      iconVariant="info"
      isOpen={isOpen}
      actions={[
        <Button
          variant="secondaryNeutral"
          text={t('customFields.settings.modals.creation.cancel')}
          onClick={() => onClose()}
          key="secondary"
        />,
        <Button
          variant="primaryBrand"
          text={t('customFields.settings.modals.creation.submit')}
          onClick={() => formikProps.handleSubmit()}
          key="primary"
        />,
      ]}
      title={t('customFields.settings.modals.creation.title')}
    >
      <FormField
        label={t('customFields.settings.modals.creation.label')}
        alertMessage={formikProps.errors.value}
      >
        <TextInput
          value={formikProps.values.value}
          placeholder={t('customFields.settings.modals.creation.placeholder')}
          onChange={(event) => {
            formikProps.setFieldValue('value', event.target.value);
          }}
        />
      </FormField>
    </Modal>
  );
};
