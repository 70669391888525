import React from 'react';

import { PanelItemsSection } from 'common/components/Panel';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type CurrenciesKey } from 'src/core/config/money';
import { displayMoney } from 'src/core/utils/money';

import type { LineItem } from '../ManageLineItemsModal/types';

type Props = {
  before?: React.ReactNode;
  lineItems: LineItem[];
  currency: CurrenciesKey;
};
export const InvoiceLineItemsPanelSection = ({
  before,
  lineItems,
  currency,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <>
      {before}
      <PanelItemsSection
        title={t('requests.panel.invoice.lineItems.overview.title')}
        items={lineItems.map((lineItem) => ({
          label: `${lineItem.unitQuantity}x ${lineItem.description}`,
          value: displayMoney({
            value: lineItem.unitPriceWithoutTax,
            currency: currency,
          }),
        }))}
      />
    </>
  );
};
