import React, { useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Notification } from './Notification';
import { useNotificationAnimation } from './useNotificationAnimation';
import type { RemoveNotification } from '../redux/actionTypes';
import { removeNotification } from '../redux/actions';
import { getNotificationsStack } from '../redux/selectors';

import './NotificationsStack.css';

export const NotificationsStack = () => {
  const notificationsStack = useSelector(getNotificationsStack);
  const dispatch = useDispatch();
  const listRef = useRef<HTMLUListElement>(null);
  const getOffsetFromIndex = useNotificationAnimation(listRef);

  const handleRemoveNotification = useCallback(
    (payload: RemoveNotification['payload']) => {
      dispatch(removeNotification(payload));
    },
    [dispatch],
  );

  if (document) {
    return ReactDOM.createPortal(
      <div className="NotificationsStack">
        <ul className="NotificationStackContainer" ref={listRef}>
          {notificationsStack.toReversed().map((notification, index) => (
            <li
              key={notification.id}
              className="NotificationToast"
              data-index={index}
              style={{
                ['--translate' as string]: `${getOffsetFromIndex(index)}px`,
              }}
            >
              <Notification
                notification={notification}
                removeNotification={handleRemoveNotification}
              />
            </li>
          ))}
        </ul>
      </div>,
      document.body,
    );
  }

  return null;
};
