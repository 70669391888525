import { type i18n, type TFunction } from 'i18next';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { InvoicePatternBuilder } from 'modules/bookkeep/settings/export-legacy/components/InvoicePatternBuilder';

type Props = {
  t: TFunction<'global', undefined>;
  i18n: i18n;
  tReady: boolean;
};

class InvoicesPattern extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <h1 className="text-primary heading-l">
          {t('exports.receiptsFileNamesTitle')}
        </h1>
        <p className="mb-16 mt-8 text-secondary-bg-primary body-m">
          {t('exports.receiptsFileNamesSubTitle')}
        </p>
        <div className="box">
          <InvoicePatternBuilder showSuccess />
        </div>
      </div>
    );
  }
}

export default withTranslation()(InvoicesPattern);
