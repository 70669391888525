import { Accordion, AccordionItem } from '@dev-spendesk/grapes';

type Props = {
  value?: string;
  children: React.ReactNode;
  label: string;
};

export const SettingFieldPanel = ({ value, children, label }: Props) => {
  return (
    <div className="rounded-8 border-[1px] border-solid border-default bg-primary-default">
      <Accordion>
        <AccordionItem
          renderHeader={() => (
            <div className="mr-16 flex w-full justify-between">
              <span className="title-m">{label}</span>
              {value && (
                <span className="text-secondary-bg-primary body-m">
                  {value}
                </span>
              )}
            </div>
          )}
        >
          <div className="px-24 py-16">{children}</div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
