import { isRequestError } from 'src/core/api/queryError';
import { ErrorState } from 'src/core/common/components/ErrorState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { BankInformationFormContainer } from './components/BankInformationForm';
import { useGetBankInformationQuery } from './hooks/useGetBankInformationQuery';
import { CompanySectionLoader } from '../../../components/CompanySectionLoader';

export const BankInformationPage = () => {
  const { t } = useTranslation('global');
  const bankInformationQuery = useGetBankInformationQuery();

  if (bankInformationQuery.status === 'error') {
    if (
      isRequestError(bankInformationQuery.error) &&
      bankInformationQuery.error.data.code === 'notFound'
    ) {
      return <BankInformationFormContainer />;
    }
    return (
      <ErrorState
        className="ml-48 justify-start"
        title={t('generalSettings.loadErrorTitle')}
        description={t('generalSettings.loadErrorDescription')}
      />
    );
  }

  return (
    <section
      aria-labelledby="bankInformation"
      className="GeneralSettingsPage__section"
    >
      <h1 id="bankInformation" className="text-primary heading-l">
        {t('generalSettings.bankInformation.title')}
      </h1>
      <p className="mt-8 text-secondary-bg-primary body-m">
        {t('generalSettings.bankInformation.description')}
      </p>
      {bankInformationQuery.status === 'loading' && <CompanySectionLoader />}
      {bankInformationQuery.status === 'success' && (
        <BankInformationFormContainer
          bankInformation={bankInformationQuery.data}
        />
      )}
    </section>
  );
};
