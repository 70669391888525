import { Link, Callout, Button } from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';
import {
  Link as ReactRouterLink,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { routes, routeFor } from 'src/core/constants/routes';
import { track, AnalyticEventName } from 'src/core/utils/analytics';

type Props = {
  initiator: 'user' | 'controlRuleProcess';
  restrictedUserName?: string;
  isFullPage?: boolean;
  className?: string;
};

export const RestrictedAccessWarning = ({
  initiator,
  restrictedUserName,
  isFullPage = false,
  className,
}: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const history = useHistory();
  const location = useLocation();

  return (
    <Callout
      iconName="lock-close"
      variant="alert"
      title={t('restrictedAccessWarning.title')}
      className={className}
    >
      {initiator === 'user' && restrictedUserName && (
        <Trans
          i18nKey="restrictedAccessWarning.descriptionRestrictedByAdminUser"
          values={{ user: restrictedUserName }}
        >
          <Link
            as={ReactRouterLink}
            to={routeFor(routes.COMPANY_MEMBERS.path, { company: company.id })}
          >
            -
          </Link>
        </Trans>
      )}
      {initiator === 'user' && !restrictedUserName && (
        <Trans i18nKey="restrictedAccessWarning.descriptionRestrictedByAdminSelf" />
      )}
      {initiator === 'controlRuleProcess' && restrictedUserName && (
        <Trans
          i18nKey="restrictedAccessWarning.descriptionRestrictedByControlRulesUser"
          values={{ user: restrictedUserName }}
        />
      )}
      {initiator === 'controlRuleProcess' && !restrictedUserName && (
        <>
          <div>
            <Trans i18nKey="restrictedAccessWarning.descriptionRestrictedByControlRulesSelf" />
          </div>
          <Button
            fit={isFullPage ? 'content' : 'parent'}
            variant="secondaryNeutral"
            text={t('restrictedAccessWarning.buttonAttachReceipts')}
            className="mt-16"
            onClick={() => {
              track(
                AnalyticEventName.RESTRICTED_JUSTIFY_PAYMENTS_BUTTON_CLICKED,
                { from: location.pathname },
              );
              const homeRoute = routeFor(routes.HOMEPAGE.path, {
                company: company.id,
              });
              history.push(`${homeRoute}?mode=requester`);
            }}
          />
        </>
      )}
    </Callout>
  );
};
