import { SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const PlaceholderBox = () => {
  return (
    <div className="box flex flex-col items-center gap-24">
      <SkeletonText size="s" width="200px" />
      <SkeletonText size="s" width="250px" />
      <SkeletonText size="s" width="250px" />
    </div>
  );
};
