import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { useDeleteInviteLinkMutation } from '../../hooks/useDeleteInviteLinkMutation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const MemberInviteLinkDeleteModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');

  const [deleteInviteLink] = useDeleteInviteLinkMutation();

  const onDelete = async () => {
    await deleteInviteLink();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      iconName="triangle-warning"
      iconVariant="warning"
      title={t('members.deleteLink')}
      subtitle={t('members.deleteLinkWontBeAbleToJoin')}
      onClose={onClose}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={onClose}
        />,
        <Button
          key="delete"
          variant="primaryWarning"
          text={t('members.deleteThisLink')}
          onClick={onDelete}
        />,
      ]}
    />
  );
};
