import { Callout } from '@dev-spendesk/grapes';

import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import {
  ApprovalRulesContainer,
  type ApprovalRule,
} from 'src/core/modules/company/structure/approval-flows';

import { Loading } from './Loading';
import { useMembersQuery } from '../../hooks/useMembersQuery';

type Props = {
  rules: ApprovalRule[];
  error: string | undefined;
  onChange: (approvalRules: ApprovalRule[]) => void;
};

export const ApprovalScheme = ({ rules, error, onChange }: Props) => {
  const queryStates = useQueryStates({
    states: {
      members: useMembersQuery(),
    },
  });

  return (
    <>
      <div>
        <QuerySuspense
          loading={<Loading />}
          fallback={(e) => (
            <QueryError queryError={e} componentType="Callout" />
          )}
          queryState={queryStates}
        >
          {({ members }) => {
            return (
              <ApprovalRulesContainer
                rules={rules}
                members={members}
                onChange={onChange}
                mode="approvalByDimensionsPanel"
              />
            );
          }}
        </QuerySuspense>
      </div>
      {error && <Callout variant="alert" title={error} />}
    </>
  );
};
