import {
  Button,
  FormField,
  SkeletonText,
  SwitchField,
  TextInput,
} from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import {
  type ExpenseAccount,
  type ExpenseAccountUpdate,
  getDefaultExpenseAccountName,
} from 'modules/bookkeep';
import { useHasAuxiliaryAccountsEnabled } from 'modules/bookkeep/hooks';
import {
  type MutationQueryState,
  type QueryState,
} from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './ExpenseAccountLocalOnlySection.module.css';
import { type IntegrationStatusWithIntegration } from '../../../../../../integration/status';
import { type Result as GetDefaultExpenseAccountQueryResult } from '../../../../hooks/useGetDefaultExpenseAccountQuery';
import { type Result as SetDefaultExpenseAccountMutationResult } from '../../../../hooks/useSetDefaultExpenseAccountMutation';
import { DefaultAccountDeleteConfirmationModal } from '../../components/DefaultAccountConfirmationModal/DefaultAccountDeleteConfirmationModal';
import { DefaultAccountEditConfirmationModal } from '../../components/DefaultAccountConfirmationModal/DefaultAccountEditConfirmationModal';
import { validateExpenseAccount } from '../../utils';

type ErrorState = {
  error: 'codeAlreadyExists' | 'required' | 'unknown';
  existingAccount?: ExpenseAccount;
};

function getErrorTranslation(
  errorState: ErrorState,
  t: TGlobalFunctionTyped,
): string | undefined {
  switch (errorState.error) {
    case 'required':
      return t(
        'bookkeep.integrations.settings.defaultExpenseAccountForm.requiredError',
      );
    case 'codeAlreadyExists':
      return t(
        'bookkeep.integrations.settings.defaultExpenseAccountForm.codeAlreadyExistsError',
      );
    case 'unknown':
      return t('misc.loadingError');
    default:
      return undefined;
  }
}

interface Props {
  onDefaultChange: (
    defaultExpenseAccount: ExpenseAccountUpdate,
  ) => Promise<void>;
  defaultExpenseAccount: ExpenseAccount | undefined;
  getDefaultExpenseAccountQueryState: QueryState<GetDefaultExpenseAccountQueryResult>;
  setDefaultExpenseAccountQueryState: MutationQueryState<SetDefaultExpenseAccountMutationResult>;
  integrationStatus: IntegrationStatusWithIntegration;
  isInitialToggleChecked: boolean;
}

export const DefaultExpenseAccountForm = ({
  onDefaultChange,
  defaultExpenseAccount,
  setDefaultExpenseAccountQueryState,
  getDefaultExpenseAccountQueryState,
  integrationStatus,
  isInitialToggleChecked,
}: Props) => {
  const { t } = useTranslation('global');
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();
  const [newDefaultExpenseAccount, setNewDefaultExpenseAccount] = useState(
    getInitialDefaultAccount(integrationStatus, defaultExpenseAccount),
  );
  const [errorState, setErrorState] = useState<ErrorState | undefined>();
  const [inputChanged, setInputChanged] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);
  const [isToggleChecked, setIsToggleChecked] = useState(
    isInitialToggleChecked,
  );

  const [modalState, setModalState] = useState<{
    kind: 'closed' | 'confirmEdit' | 'confirmDelete';
  }>({ kind: 'closed' });

  useEffect(() => {
    setIsToggleChecked(isInitialToggleChecked);
  }, [isInitialToggleChecked]);

  useEffect(() => {
    setValueChanged(false);
    setNewDefaultExpenseAccount(
      getInitialDefaultAccount(integrationStatus, defaultExpenseAccount),
    );
  }, [defaultExpenseAccount]);

  useEffect(() => {
    if (
      setDefaultExpenseAccountQueryState.status === 'success' &&
      setDefaultExpenseAccountQueryState.data.outcome === 'notSet' &&
      setDefaultExpenseAccountQueryState.data.reason === 'codeAlreadyExists'
    ) {
      setErrorState({
        error: 'codeAlreadyExists',
        existingAccount:
          setDefaultExpenseAccountQueryState.data.existingAccount,
      });
    } else if (
      setDefaultExpenseAccountQueryState.status === 'error' ||
      (setDefaultExpenseAccountQueryState.status === 'success' &&
        setDefaultExpenseAccountQueryState.data.outcome === 'notSet')
    ) {
      setErrorState({
        error: 'unknown',
      });
    }
  }, [setDefaultExpenseAccountQueryState]);

  const handleSubmit = () => {
    const result = validateExpenseAccount(newDefaultExpenseAccount?.code ?? '');

    setInputChanged(false);

    if (result.outcome === 'valid') {
      setErrorState(undefined);

      if (!defaultExpenseAccount) {
        setValueChanged(false);
        onDefaultChange(newDefaultExpenseAccount);
        return;
      }

      // don't do anything if the Expense account hasn't change
      if (defaultExpenseAccount.code !== newDefaultExpenseAccount.code) {
        setModalState({ kind: 'confirmEdit' });
      }
      return;
    }

    setErrorState({
      error: result.reason,
    });
  };

  const handleSwitchToggle = () => {
    if (defaultExpenseAccount) {
      if (isToggleChecked) {
        setModalState({ kind: 'confirmDelete' });
        return;
      }

      onDefaultChange({
        ...defaultExpenseAccount,
        isArchived: isToggleChecked,
      });
    }
    setIsToggleChecked(!isToggleChecked);
  };

  if (getDefaultExpenseAccountQueryState.status === 'loading') {
    return (
      <div className="box mt-24">
        <SkeletonText size="xl" />
        <SkeletonText size="m" />
      </div>
    );
  }

  return (
    <div className="box mt-24">
      <SwitchField
        fit="parent"
        id="useDefaultAccount"
        name="useDefaultAccount"
        label={t(
          'bookkeep.integrations.settings.defaultExpenseAccountForm.title',
        )}
        helpText={t(
          'bookkeep.integrations.settings.defaultExpenseAccountForm.description',
        )}
        isChecked={isToggleChecked && !!defaultExpenseAccount}
        onChange={handleSwitchToggle}
      />
      {isToggleChecked && (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <div className={styles.defaultForm__inputWrapper}>
            <FormField
              className={styles.defaultForm__input}
              label={t(
                'bookkeep.integrations.settings.defaultExpenseAccountForm.name',
              )}
              htmlFor="set-account-input"
              alertMessage={
                !!errorState && !inputChanged
                  ? getErrorTranslation(errorState, t)
                  : undefined
              }
            >
              <TextInput
                placeholder={t(
                  'bookkeep.integrations.settings.defaultExpenseAccountForm.placeholder',
                )}
                isInvalid={!!errorState && !inputChanged}
                value={newDefaultExpenseAccount?.code ?? ''}
                onChange={(e) => {
                  const code = e.target.value;
                  setNewDefaultExpenseAccount({
                    ...newDefaultExpenseAccount,
                    code,
                    isArchived: false,
                  });
                  setInputChanged(true);
                  setValueChanged(defaultExpenseAccount?.code !== code);
                  setErrorState(undefined);
                }}
                id="set-account-input"
              />
            </FormField>
            <div className={styles.defaultForm__input} />
          </div>

          <Button
            variant={valueChanged ? 'primaryBrand' : 'secondaryNeutral'}
            text={t('misc.saveChanges')}
            className={styles.defaultForm__submit}
            isDisabled={
              !valueChanged ||
              setDefaultExpenseAccountQueryState.status === 'loading'
            }
            type="submit"
          />

          {newDefaultExpenseAccount ? (
            <DefaultAccountEditConfirmationModal
              integration={integrationStatus.integration}
              isOpen={modalState.kind === 'confirmEdit'}
              setModalState={setModalState}
              onSetDefault={onDefaultChange}
              existingDefaultAccount={defaultExpenseAccount}
              newDefaultAccount={newDefaultExpenseAccount}
              actionOrigin="defaultExpenseAccountForm"
              auxiliaryAccountsEnabled={auxiliaryAccountsEnabled}
            />
          ) : (
            <></>
          )}

          {defaultExpenseAccount ? (
            <DefaultAccountDeleteConfirmationModal
              integration={integrationStatus.integration}
              isOpen={modalState.kind === 'confirmDelete'}
              setModalState={setModalState}
              onSetDefault={onDefaultChange}
              defaultAccount={defaultExpenseAccount}
              actionOrigin="defaultExpenseAccountForm"
            />
          ) : (
            <></>
          )}
        </form>
      )}
    </div>
  );
};

function getInitialDefaultAccount(
  integrationStatus: IntegrationStatusWithIntegration,
  defaultExpenseAccount: ExpenseAccount | undefined,
): ExpenseAccountUpdate {
  return defaultExpenseAccount
    ? {
        ...defaultExpenseAccount,
        name:
          defaultExpenseAccount.name ||
          getDefaultExpenseAccountName(integrationStatus.accountingCountry),
      }
    : {
        name: getDefaultExpenseAccountName(integrationStatus.accountingCountry),
        code: '',
        isArchived: false,
      };
}
