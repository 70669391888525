import { Callout, SkeletonText } from '@dev-spendesk/grapes';
import { useMemo } from 'react';

import { useCompany } from 'modules/app/hooks/useCompany';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { convertToCompanyCurrency, formatMoney } from 'src/core/utils/money';

import { useSpendLimitStatusQuery } from '../../hooks/useSpendLimitStatusQuery';

export const StatusByCategoryLimitCallout = ({
  requestDate,
  currency,
  requestAmount,
  expenseCategoryId,
  className,
}: {
  requestDate: Date;
  currency: string;
  requestAmount: number;
  expenseCategoryId: string;
  className?: string;
}) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const spendLimitStatusQueryState = useSpendLimitStatusQuery({
    requestDate,
    expenseCategoryId,
    enabled: true,
  });

  const requestAmountCalculated = useMemo(() => {
    return convertToCompanyCurrency(requestAmount, currency, company);
  }, [requestAmount, currency, company, spendLimitStatusQueryState]);

  // TODO: handle 404 specifically
  if (spendLimitStatusQueryState.status === 'error') {
    return null;
  }

  return (
    <QuerySuspense
      loading={<SkeletonText size="xxl" />}
      queryState={spendLimitStatusQueryState}
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
    >
      {(spendLimitStatus) => {
        const isUnderLimit =
          spendLimitStatus.usedAmount + requestAmountCalculated <=
          spendLimitStatus.limitAmount;
        const isOverLimit =
          spendLimitStatus.usedAmount + requestAmountCalculated >
          spendLimitStatus.limitAmount;
        const overLimitAmount =
          spendLimitStatus.usedAmount +
          requestAmountCalculated -
          spendLimitStatus.limitAmount;

        const calloutTitleKey =
          spendLimitStatus.period === 'day'
            ? 'spendLimits.modal.request.preRequestCallout.titleDay'
            : 'spendLimits.modal.request.preRequestCallout.titleMonth';
        if (isUnderLimit) {
          return (
            <Callout
              className={className}
              variant="info"
              title={t(calloutTitleKey, {
                amount: formatMoney(
                  spendLimitStatus.limitAmount,
                  spendLimitStatus.currency,
                ),
              })}
            />
          );
        }
        if (isOverLimit) {
          const calloutDescriptionKey =
            spendLimitStatus.period === 'day'
              ? 'spendLimits.modal.request.calloutWarning.descriptionDay'
              : 'spendLimits.modal.request.calloutWarning.descriptionMonth';
          return (
            <Callout
              className={className}
              variant="warning"
              title={t('spendLimits.modal.request.calloutWarning.title', {
                amount: formatMoney(overLimitAmount, spendLimitStatus.currency),
              })}
            >
              {t(calloutDescriptionKey, {
                amount: formatMoney(
                  spendLimitStatus.limitAmount,
                  spendLimitStatus.currency,
                ),
              })}
            </Callout>
          );
        }

        return null;
      }}
    </QuerySuspense>
  );
};
