import queryString from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SearchFilter } from 'common/components/SearchFilter';
import { useParams } from 'src/core/common/hooks/useParams';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

export const AccountPayableSuppliersListSearch = () => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const { company } = useParams(routes.COMPANY_ACCOUNTS_PAYABLE.path);
  const { search } = useLocation();
  const parsedSearch = queryString.parse(search);

  const textSearch = (parsedSearch.search as string | undefined) || '';

  const navigateWithSearch = (searchValue: string) => {
    history.push({
      pathname: routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
        company,
      }),
      search: searchValue
        ? `?search=${encodeURIComponent(searchValue)}`
        : undefined,
    });
  };

  return (
    <SearchFilter
      search={textSearch}
      setSearch={(searchPattern) => navigateWithSearch(searchPattern)}
      minLength={0}
      placeholder={t('misc.searchPlaceholder')}
    />
  );
};
