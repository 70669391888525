import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { routeFor } from 'src/core/constants/routes';

import { useUpdateCachedTaskStatus } from '../../SetupHubPage/hooks/useUpdateCachedTaskStatus';
import { routes } from '../../routes';
import { ExpenseAccountTableAndModals } from '../components/ExpenseAccountTableAndModals';

export const AccountingExpenseAccountsList = () => {
  const history = useHistory();

  const { t } = useTranslation('global');

  const companyId = useCompanyId();

  const updateCachedTaskStatus = useUpdateCachedTaskStatus();

  const onBack = () => {
    const previousPath = routeFor(routes.ACCOUNTING_EXPENSE_ACCOUNTS.path, {
      company: companyId,
    });
    history.push(previousPath);
  };

  const onNext = () => {
    updateCachedTaskStatus('expense_accounts_codes');
    const setupHubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(setupHubPath);
  };

  return (
    <>
      <div className="page__container">
        <TaskStepLayout
          description={t('setupHub.expenseAccounts.description')}
          extraDescription={
            <p className="text-secondary-bg-primary body-l">
              {t('setupHub.expenseAccounts.extraDescription')}
            </p>
          }
          isLastStep
          onBack={onBack}
          onNext={onNext}
          title={t('setupHub.expenseAccounts.title')}
          video={{
            // TODO(GROW-1484): Replace with localized video URL
            url: '',
            title: t('setupHub.expenseAccounts.helpTitle'),
          }}
        >
          <p className="mb-16 title-m">
            {t('setupHub.expenseAccounts.listTitle')}
          </p>
          <ExpenseAccountTableAndModals />
        </TaskStepLayout>
      </div>
    </>
  );
};
