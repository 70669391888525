import {
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  SkeletonButton,
  SkeletonText,
  Skeleton,
} from '@dev-spendesk/grapes';
import React from 'react';

import styles from './ModalLoader.module.css';

export const ModalLoader = () => {
  return (
    <ModalOverlay isOpen>
      <ModalContent>
        <ModalBody>
          <div>
            <Skeleton
              className={styles.modalLoaderIcon}
              width="var(--unit-48)"
              height="var(--unit-48)"
            />
            <div>
              <SkeletonText size="s" />
              <SkeletonText size="s" />
              <SkeletonText size="s" />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <SkeletonButton />
          <SkeletonButton />
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  );
};
