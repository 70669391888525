import React from 'react';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';

export const ProfileMenu = () => {
  const company = useCompany();
  const companyId = company.id;
  const { t } = useTranslation('global');

  const links = [
    {
      key: 'me-profile',
      text: t('profile.navigation.profileInfo'),
      path: routeFor(routes.ACCOUNT_ME_PROFILE.path, {
        company: companyId,
      }),
    },
    {
      key: 'me-bankinfo',
      text: t('profile.navigation.bankAndSecurityInfo'),
      path: routeFor(routes.ACCOUNT_ME_BANKINFO.path, {
        company: companyId,
      }),
    },
    {
      key: 'me-roles',
      text: t('profile.navigation.roleAndPermissions'),
      path: routeFor(routes.ACCOUNT_ME_ROLES.path, {
        company: companyId,
      }),
    },
    {
      key: 'user-notifications',
      text: t('profile.navigation.notifications'),
      path: routeFor(routes.ACCOUNT_NOTIFICATIONS_GENERAL.path, {
        company: company.id,
      }),
    },
  ];

  return (
    <HeaderWithNavigation links={links} navigationLabel="Profile navigation">
      {t('profile.profileV2Title')}
    </HeaderWithNavigation>
  );
};
