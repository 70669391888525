import { GridContainer, GridItem } from '@dev-spendesk/grapes';
import React from 'react';

import { ScheduledPaymentsByUserListSkeleton } from 'modules/reimbursements/components/ScheduledPaymentsByUserList/ScheduledPaymentsByUserListSkeleton';
import { SendToPaymentLoader } from 'modules/reimbursements/components/SendToPaymentLoader';

export const ExpenseClaimsPayPageSkeleton = () => {
  return (
    <div className="h-full">
      <GridContainer className="mt-48">
        <GridItem columnSpan={6}>
          <ScheduledPaymentsByUserListSkeleton numberOfRows={5} />
        </GridItem>
        <GridItem columnSpan={6}>
          <div className="box">
            <SendToPaymentLoader />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};
