import { Button, useDateFormatter, DATE_FORMAT } from '@dev-spendesk/grapes';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { COUNTRIES } from 'src/core/config/country';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  card: {
    id: string;
    addressLine1: string;
    addressLine2: string;
    zipCode: string;
    city: string;
    country: string;
    brand: string;
    recard_request: {
      next_card_order: {
        address_recipient_name: string;
        address: string;
        zipcode: string;
        city: string;
        country: string;
        estimated_received_at: string;
        considered_as_lost?: boolean;
        card_id: string;
        user_id: string;
        created_at: string;
      };
    };
  };
};

export const RecardCardLost = ({ card }: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();
  const dateFormatter = useDateFormatter();

  const nextCardOrder = card.recard_request.next_card_order;

  return (
    <div className="mx-auto mt-32 w-[420px]">
      <h1 className="text-primary title-xl">
        {t('recard.lostReorder.cardLostValidation.title')}
      </h1>
      <div className="box my-24 title-m">
        <p>
          {t('recard.lostReorder.cardLostValidation.addressTitle', {
            date: dateFormatter(
              new Date(nextCardOrder.created_at),
              DATE_FORMAT.MEDIUM,
            ),
          })}
        </p>
        <p className="my-24 body-m">
          {nextCardOrder.address_recipient_name}
          <br />
          {nextCardOrder.address}
          <br />
          {nextCardOrder.zipcode} {nextCardOrder.city}
          <br />
          {COUNTRIES[nextCardOrder.country].name}
        </p>
        <p className="mb-24">
          {t('recard.lostReorder.cardLostValidation.willBeCancelled')}
        </p>

        <div className="flex justify-between">
          <Link to={routeFor(routes.CARD.path, { company: company.id })}>
            <Button
              variant="secondaryNeutral"
              text={t('recard.lostReorder.cardLostValidation.goBack')}
            />
          </Link>
          <Link
            to={routeFor(routes.RECARD.path, {
              company: company.id,
              status: 'card_lost_reorder',
            })}
            onClick={() =>
              track(AnalyticEventName.CARD_NOT_RECEIVED_CONFIRMED, {
                cardId: nextCardOrder.card_id,
              })
            }
          >
            <Button text={t('recard.lostReorder.cardLostValidation.order')} />
          </Link>
        </div>
      </div>
    </div>
  );
};
