import { Callout, Link } from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { type SchemeType } from 'modules/requests/models/mileageScheme';

type Props = {
  className?: string;
  schemeType: SchemeType | undefined;
};

const getExplanationBySchemeType = (
  schemeType: SchemeType | undefined,
  t: TGlobalFunctionTyped,
) => {
  let explanation = null;
  switch (schemeType) {
    case 'FR':
      explanation = (
        <Trans
          i18nKey="requests.panel.mileage.computationFooterExplanation"
          components={{
            a: (
              <Link
                href="https://helpcenter.spendesk.com/articles/4729427"
                target="_blank"
                rel="noreferrer"
              >
                -
              </Link>
            ),
          }}
        />
      );
      break;
    case 'UK':
      explanation = (
        <Trans
          i18nKey="requests.panel.mileage.computationFooterExplanation"
          components={{
            a: (
              <Link
                href="https://helpcenter.spendesk.com/articles/9206924"
                target="_blank"
                rel="noreferrer"
              >
                -
              </Link>
            ),
          }}
        />
      );
      break;
    case 'DE':
      explanation = (
        <Trans
          i18nKey="requests.panel.mileage.computationFooterExplanation"
          components={{
            a: (
              <Link
                href="https://helpcenter.spendesk.com/articles/9207025"
                target="_blank"
                rel="noreferrer"
              >
                -
              </Link>
            ),
          }}
        />
      );
      break;
    case 'FX':
      explanation = (
        <>{t('requests.panel.mileage.computationFooterExplanationCustom')}</>
      );
      break;
    default:
      explanation = (
        <>{t('requests.panel.mileage.computationFooterExplanationNoLink')}</>
      );
  }
  return explanation;
};

export const MileageRequestComputationContentSchemeExplanation = ({
  className,
  schemeType,
}: Props) => {
  const { t } = useTranslation('global');
  const explanation = getExplanationBySchemeType(schemeType, t);
  return (
    <Callout
      className={className}
      iconName="circle-information"
      title={explanation}
    />
  );
};
