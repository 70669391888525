import { Callout, CheckboxField } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  mode: 'edit' | 'view';
  hasExpressedConsent: boolean;
  onConsentChange: () => void;
  formError?: string;
};

const ConsentForm = ({
  mode,
  hasExpressedConsent,
  onConsentChange,
  formError,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <>
      <div
        className="mt-24 flex flex-row items-center rounded-8 bg-secondary-default p-16"
        data-testid="api-key-create-consent"
      >
        <CheckboxField
          isChecked={hasExpressedConsent}
          onChange={onConsentChange}
          isDisabled={mode === 'view'}
          label={t('publicApi.scopes.consense')}
        />
      </div>
      {formError && (
        <Callout title={formError} variant="alert" className="mt-8" />
      )}
    </>
  );
};

export default ConsentForm;
