import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';

import { LOAD_CUSTOM_FIELD_VALUES_BY_IDS } from './queries';

/**
 * @public
 */
export type RawCustomFieldValue = {
  id: string;
  name: string;
  archiveDate?: string | null;
};

/**
 * @public
 */
export type CustomFieldValue = {
  id: string;
  name: string;
  isArchived: boolean;
};

export type LoadCustomFieldValuesByIdsRawResponse = {
  company: {
    customFields: Array<{ values: { edges: { node: RawCustomFieldValue }[] } }>;
  };
};

export type LoadCustomFieldValuesByIdsVariables = {
  customFieldId: string;
  customFieldValueIds: string[];
};

export const useLoadCustomFieldValuesByIds = (customFieldId: string) => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  return async (ids: string[]): Promise<CustomFieldValue[]> => {
    if (!ids.length) {
      return [];
    }

    const data = await queryClient.fetchQuery(
      ['useLoadCustomFieldValuesByIds', companyId, customFieldId, ids],
      getFetcher<LoadCustomFieldValuesByIdsRawResponse>({
        companyId,
        getRequest: () => ({
          type: 'graphQL',
          target: 'v2',
          query: LOAD_CUSTOM_FIELD_VALUES_BY_IDS,
          variables: {
            customFieldId,
            customFieldValueIds: ids,
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return data.company.customFields.flatMap((customField) =>
      reshapeFilterValues(customField.values.edges),
    );
  };
};

/**
 * Reshapers
 */

const reshapeFilterValues = (
  edges: {
    node: RawCustomFieldValue;
  }[],
): CustomFieldValue[] => {
  return edges
    .map(({ node }) =>
      node.archiveDate
        ? null
        : {
            id: node.id,
            name: node.name,
            isArchived: !!node.archiveDate,
          },
    )
    .filter((item): item is CustomFieldValue => item !== null);
};
