import { CostCenterSelect } from './CostCenterSelect';
import { SpendTypeSelect } from './SpendTypeSelect';
import { type Dimension } from '../../approvalWorkflow';

type Props = {
  dimension: Dimension;
  selectedValues: string[] | undefined;
  onSelect: (values: string[]) => void;
};

export const DimensionValuesSelect = ({
  dimension,
  selectedValues,
  onSelect,
}: Props) => {
  if (dimension === 'spendType') {
    return (
      <SpendTypeSelect selectedValues={selectedValues} onSelect={onSelect} />
    );
  }
  if (dimension === 'costCenter') {
    return (
      <CostCenterSelect selectedValues={selectedValues} onSelect={onSelect} />
    );
  }
  return null;
};
