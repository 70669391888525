import { type AssignableRole } from 'modules/members/models/roles';
import { useMutation } from 'src/core/api/hooks/useMutation';

type Payload = {
  userId: string;
  roleUpdates: {
    role: AssignableRole;
    enabled: boolean;
  }[];
};

export const useUpdateRolesMutation = () => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: () => `/roles`,
      method: 'put',
    },
  });
};
