import React from 'react';

import { ExpenseReviewListSkeleton } from '@finance-review/components/expense/ExpenseReview/ExpenseReviewListSkeleton';
import { ExpenseReviewFormSkeleton } from '@finance-review/components/expense/ExpenseReviewFormContainer/ExpenseReviewFormSkeleton';

import styles from './ExpenseReviewPageSkeleton.module.css';

export const ExpenseReviewPageSkeleton = () => {
  return (
    <div className={styles.container}>
      <ExpenseReviewListSkeleton />
      <ExpenseReviewFormSkeleton />
    </div>
  );
};
