import { OptionGroup } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { track, AnalyticEventName } from 'src/core/utils/analytics';

import {
  useDisplayMode,
  type HomepageMode,
} from '../../../../hooks/useDisplayMode';

export const DisplayModeSwitch = () => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const displayMode = useDisplayMode() ?? 'controller';

  return (
    <OptionGroup
      name="dashboard"
      options={[
        { value: 'controller', label: t('homepage.welcome.controllerView') },
        { value: 'requester', label: t('homepage.welcome.requesterView') },
      ]}
      value={displayMode}
      onChange={(event) => {
        track(AnalyticEventName.HOMEPAGE_SWITCH_MODE_CLICKED, {
          from: displayMode,
          to: event.target.value as HomepageMode,
          icon: null,
        });
        history.push({ search: `?mode=${event.target.value}` });
      }}
    />
  );
};
