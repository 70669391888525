import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type Addon, getAddonsBySlug } from '../../../../models';

type Props = {
  addons: Addon[];
};

export const CompanyPlanAddons = ({ addons }: Props) => {
  const { t } = useTranslation('global');

  const { supplementaryWalletsAddon, samlSsoAddon, cardInsuranceAddon } =
    getAddonsBySlug(addons);

  return (
    <ul className="ml-16 list-disc">
      {supplementaryWalletsAddon && supplementaryWalletsAddon.quantity > 0 && (
        <li>
          <Trans
            i18nKey="billing.companyPlan.addons.supplementaryWallet"
            values={{
              count: supplementaryWalletsAddon.quantity,
            }}
            // eslint-disable-next-line react/jsx-key
            components={[<strong className="text-primary">-</strong>]}
          />
        </li>
      )}
      {samlSsoAddon && <li>{t('billing.companyPlan.addons.samlSSO')}</li>}
      {cardInsuranceAddon && (
        <li>{t('billing.companyPlan.addons.cardInsurance')}</li>
      )}
    </ul>
  );
};
