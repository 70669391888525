import { Button, Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type IntegrationError } from '../../../types';
import {
  getIntegrationErrorDetails,
  getIntegrationErrorTitle,
} from '../../../utils';

type Props = {
  error: IntegrationError;
  onAction: () => void;
};
export const IntegrationErrorCallout = (props: Props) => {
  const { error, onAction } = props;
  const { t } = useTranslation('global');
  const errorTitle = getIntegrationErrorTitle(t, error);
  const errorDetails = getIntegrationErrorDetails(t, error);

  return (
    <>
      <Callout
        variant={error === 'wrongPlan' ? 'info' : 'alert'}
        title={error === 'wrongPlan' ? errorDetails : errorTitle}
      >
        {error !== 'wrongPlan' ? (
          <div className="flex flex-col items-start gap-8">
            <span className="flex">{getIntegrationErrorDetails(t, error)}</span>
            {error === 'notConnected' ? (
              <Button
                variant="secondaryNeutral"
                fit="content"
                text={t('integration.reconnect')}
                onClick={onAction}
                className="flex"
              />
            ) : null}
          </div>
        ) : null}
      </Callout>
      <div className="flex h-[1px] w-full flex-col rounded-4 bg-secondary-default" />
    </>
  );
};
