import { Button, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';

import './DeleteThresholdModal.css';

type Props = {
  approversCount: number;
  onConfirm: () => void;
  onCancel: () => void;
};

const DeleteThresholdModal = ({
  onConfirm,
  onCancel,
  approversCount,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      iconVariant="alert"
      iconName="cross"
      title={`${t('teams.approvalFlows.deleteThreshold')}?`}
      actions={[
        <Button
          type="button"
          key="button-cancel-threshold-deletion"
          onClick={onCancel}
          text={t('misc.cancel')}
          variant="tertiaryNeutral"
        />,
        <Button
          type="button"
          key="button-confirm-threshold-deletion"
          variant="primaryAlert"
          onClick={onConfirm}
          text={t('teams.approvalFlows.deleteThreshold')}
        />,
      ]}
    >
      {approversCount > 1
        ? t('teams.approvalFlows.deleteThresholdConfirmMsg.plural', {
            approversCount,
          })
        : t('teams.approvalFlows.deleteThresholdConfirmMsg.singular')}
    </Modal>
  );
};

export default DeleteThresholdModal;
