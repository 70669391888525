import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { invalidatePurchaseOrdersQueries } from '../../hooks/useFetchPurchaseOrdersQuery';
import { type PurchaseOrderDetails } from '../models';

export type CancelPurchaseOrderError = {
  outcome: 'notCancelled';
  reason:
    | 'alreadyExists'
    | 'attachedInvoicesExist'
    | 'forbidden'
    | 'purchaseOrderNotFound'
    | 'statusNotOpened';
};

export const useCancelPurchaseOrder = (
  purchaseOrderId: string,
): MutationState<object, PurchaseOrderDetails, CancelPurchaseOrderError> => {
  return useMutation<
    object,
    PurchaseOrderDetails,
    PurchaseOrderDetails,
    CancelPurchaseOrderError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/purchase-orders/${purchaseOrderId}/cancel`,
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client }): void => {
        invalidatePurchaseOrdersQueries(client);
      },
    },
    reshapeData: (data) => data,
  });
};
