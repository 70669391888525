import {
  AutocompleteNoOptions,
  AutocompletePlace,
  Button,
  FormField,
  Modal,
  TextInput,
} from '@dev-spendesk/grapes';
import type { FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { CountryAutocomplete } from 'common/components/CountryAutocomplete';
import { useTranslation } from 'common/hooks/useTranslation';
import { getPlace } from 'common/utils/mapbox';

import { type EntityAddressForm } from '../../form';

type Props = {
  onCancel: () => void;
  onClose: () => void;
  country: string;
} & Pick<
  FormikProps<EntityAddressForm>,
  | 'values'
  | 'errors'
  | 'handleChange'
  | 'isSubmitting'
  | 'handleSubmit'
  | 'setFieldValue'
>;

export const EntityAddressStep = ({
  onCancel,
  onClose,
  country,
  values,
  errors,
  handleSubmit,
  isSubmitting,
  handleChange,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation('global');
  const [allowSearch, setAllowSearch] = useState(true);
  const streetAddressInputReference = useRef<HTMLInputElement | null>(null);

  return (
    <Modal
      isOpen
      iconName="plus"
      iconVariant="purple"
      title={t('branch.entityAddressStep.title')}
      subtitle={t('branch.entityAddressStep.subtitle')}
      onClose={onClose}
      actions={[
        <Button
          key="cancel"
          onClick={onCancel}
          text={t('misc.back')}
          variant="secondaryNeutral"
        />,
        <Button
          key="confirm"
          type="submit"
          form="entity-address"
          isLoading={isSubmitting}
          isDisabled={!values.address || !isEmpty(errors)}
          text={t('branch.entityAddressStep.cta')}
        />,
      ]}
    >
      <form
        id="entity-address"
        className="flex flex-col gap-24"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-16">
          <FormField label={t('branch.entityAddressStep.addressLabel')}>
            {allowSearch ? (
              <AutocompletePlace
                fit="parent"
                value={values.address || ''}
                country={country}
                placeholder={t('branch.entityAddressStep.addressPlaceholder')}
                name="address.streetAddress"
                handleSelect={(result) => {
                  const place = getPlace(result);
                  if (place) {
                    setFieldValue('address', {
                      address: place.shortAddress,
                      city: place.city,
                      country: place.country,
                      state: place.region,
                      zipcode: place.postcode,
                    });
                  }
                }}
                onBlur={(blurEvent) => {
                  setFieldValue('address.address', blurEvent.target.value);
                }}
                renderNoOptions={(value, debouncedValue) => {
                  if (value.length < 3 || !debouncedValue) {
                    return (
                      <AutocompleteNoOptions>
                        <div>{t('misc.asyncAutocompleteEmptyState')}</div>
                      </AutocompleteNoOptions>
                    );
                  }
                  return (
                    <AutocompleteNoOptions>
                      <Trans
                        i18nKey="misc.noOptions"
                        values={{ account: value }}
                        components={[
                          <span className="text-primary" key="no-option" />,
                        ]}
                      />
                    </AutocompleteNoOptions>
                  );
                }}
              />
            ) : (
              <TextInput
                fit="parent"
                name="address.address"
                onChange={handleChange}
                value={values.address || ''}
                placeholder={t('branch.entityAddressStep.addressPlaceholder')}
                ref={streetAddressInputReference}
              />
            )}
          </FormField>
          {allowSearch && (
            <Button
              variant="secondaryNeutral"
              fit="content"
              className="w-fit"
              onClick={() => {
                setAllowSearch(!allowSearch);
                setTimeout(() => {
                  if (streetAddressInputReference.current) {
                    streetAddressInputReference.current.focus();
                  }
                }, 0);
              }}
              text={t('branch.entityAddressStep.enterManually')}
            />
          )}
        </div>
        <FormField
          label={t('branch.entityAddressStep.additionalInfoLabel')}
          hint={t('misc.optional')}
        >
          <TextInput
            fit="parent"
            name="address.address2"
            onChange={handleChange}
            placeholder={t(
              'branch.entityAddressStep.additionalInfoPlaceholder',
            )}
            value={values.address2 || ''}
          />
        </FormField>
        <div className="flex gap-16">
          <FormField
            label={t('branch.entityAddressStep.cityLabel')}
            className="flex-1"
          >
            <TextInput
              fit="parent"
              name="address.city"
              onChange={handleChange}
              value={values.city || ''}
            />
          </FormField>
          <FormField
            label={t('branch.entityAddressStep.stateLabel')}
            className="flex-1"
            hint={t('misc.optional')}
          >
            <TextInput
              fit="parent"
              name="address.state"
              onChange={handleChange}
              value={values.state}
            />
          </FormField>
        </div>
        <div className="flex gap-16">
          <FormField
            label={t('branch.entityAddressStep.postalCodeLabel')}
            className="flex-1"
          >
            <TextInput
              fit="parent"
              name="address.zipcode"
              onChange={handleChange}
              value={values.zipcode || ''}
            />
          </FormField>
          <FormField
            label={t('branch.entityAddressStep.countryLabel')}
            className="flex-1"
          >
            <CountryAutocomplete
              countryCode={country}
              fit="parent"
              name="address.country"
              onSelect={() => {}}
              isDisabled
            />
          </FormField>
        </div>
      </form>
    </Modal>
  );
};
