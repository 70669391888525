import { useState } from 'react';

import {
  SORTED_CURRENCY_OPTIONS,
  type CurrenciesKey,
} from 'src/core/config/money';

import { FilteredEntityListWrapper } from './FilteredEntityListWrapper';
import { EntityListContainer } from './entity-list/EntityListContainer';
import { EntityListFilters } from './entity-list-filters/EntityListFilters';
import { useOrganisationCompanyList } from '../../hooks/data/useOrganisationCompanyList';
import type {
  EnrichedEntityInformation,
  EntityBasicInformation,
} from '../../types';
import { FilteredListBySearchWrapper } from '../common/FilteredListBySearchWrapper';

type Props = {
  onDataLoaded: () => void;
};

export const MultiEntityHubOverviewPage = ({ onDataLoaded }: Props) => {
  const [orgEntities, setOrgEntities] = useState<
    (EntityBasicInformation | EnrichedEntityInformation)[]
  >(useOrganisationCompanyList());

  const [canPerformFiltering, enableFiltering] = useState(false);
  const orgCurrencies = [
    ...new Set(orgEntities.map(({ currency }) => currency)),
  ];
  const orgCurrencyOptions = SORTED_CURRENCY_OPTIONS.filter(({ key }) =>
    orgCurrencies.includes(key as CurrenciesKey),
  ) as { key: CurrenciesKey; label: string }[];

  return (
    <FilteredListBySearchWrapper
      items={orgEntities}
      searchableFields={['name']}
      isSearchEnabled={canPerformFiltering}
      isSyncedWithUrl
    >
      {({
        filteredItems: entitiesFromSearch,
        search,
        setSearch,
        hasActiveSearch,
      }) => (
        <FilteredEntityListWrapper
          entities={entitiesFromSearch}
          isFilteringEnabled={canPerformFiltering}
        >
          {({ filteredEntities, filters, setFilters, hasActiveFilters }) => (
            <div className="flex flex-col gap-16">
              <EntityListFilters
                filters={filters}
                setFilters={setFilters}
                filtersConfig={{
                  currencyOptions: orgCurrencyOptions,
                }}
                search={search}
                setSearch={setSearch}
                isFilteringEnabled={canPerformFiltering}
              />

              <EntityListContainer
                entities={filteredEntities}
                onDataLoaded={(entities) => {
                  setOrgEntities(entities);
                  enableFiltering(true);
                  onDataLoaded();
                }}
                isDataVisible={
                  hasActiveSearch || hasActiveFilters
                    ? canPerformFiltering
                    : true
                }
              />
            </div>
          )}
        </FilteredEntityListWrapper>
      )}
    </FilteredListBySearchWrapper>
  );
};
