import { Button, colors, IconButton } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';
import { type TooltipRenderProps } from 'react-joyride';

import { useTranslation } from 'common/hooks/useTranslation';

import styles from './ProductTourTooltip.module.css';

type Props = TooltipRenderProps & {
  className?: string;
  variant: 'light' | 'dark';
  withActionButtons?: boolean;
};

export const ProductTourTooltip = ({
  backProps,
  className,
  closeProps,
  index,
  isLastStep,
  primaryProps,
  size,
  step,
  tooltipProps,
  variant,
  withActionButtons = true,
}: Props) => {
  const { t } = useTranslation('global');

  const { content, title } = step;

  const hasMultipleSteps = size > 1;
  const stepper = `${index + 1}/${size}`;

  const isLightVariant = variant === 'light';

  return (
    <div
      {...tooltipProps}
      className={cx(className, 'flex', {
        [styles.leftStart]: step.placement === 'left-start',
        [styles.leftEnd]: step.placement === 'left-end',
        [styles.bottomEnd]: step.placement === 'bottom-end',
        [styles.bottomStart]: step.placement === 'bottom-start',
      })}
    >
      <div
        className={cx('flex w-[276px] flex-col gap-8 rounded-8 p-16 shadow-l', {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'bg-primary-default': isLightVariant,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'bg-complementary-default text-complementary': !isLightVariant,
        })}
      >
        <div className="flex items-baseline justify-between gap-16">
          <div className="title-l">{title}</div>
          <IconButton
            {...closeProps}
            iconColor={
              isLightVariant ? undefined : colors.backgroundPrimaryDefault
            }
            iconName="cross"
            variant="tertiaryNeutral"
            hasNegativeMargins
            aria-label={t('misc.close')}
          />
        </div>
        <div className="whitespace-pre-line body-m">{content}</div>
        <div className="flex items-center justify-between">
          {withActionButtons && hasMultipleSteps && (
            <>
              <div
                className={cx('body-m', {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'text-primary': isLightVariant,
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'text-complementary': !isLightVariant,
                })}
              >
                {stepper}
              </div>
              <div className="flex gap-8">
                {index >= 1 && (
                  <Button
                    {...backProps}
                    className={
                      isLightVariant ? undefined : 'text-complementary'
                    }
                    variant={
                      isLightVariant ? 'secondaryNeutral' : 'tertiaryNeutral'
                    }
                    text={t('misc.back')}
                  />
                )}
                <Button
                  {...primaryProps}
                  variant={isLightVariant ? 'primaryBrand' : 'secondaryNeutral'}
                  text={isLastStep ? t('misc.done') : t('misc.next')}
                />
              </div>
            </>
          )}
          {withActionButtons && !hasMultipleSteps && (
            <div className="flex flex-1 justify-end">
              <Button
                {...primaryProps}
                variant={isLightVariant ? 'primaryBrand' : 'secondaryNeutral'}
                text={t('misc.gotIt')}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={cx({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'my-24': step.placement.includes('left'),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'mx-24 h-16 -rotate-90': step.placement.includes('bottom'),
        })}
      >
        <svg
          className={cx({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'fill-background-primary': isLightVariant,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'fill-background-complementary': !isLightVariant,
          })}
          width="16"
          height="20"
          viewBox="0 0 16 20"
        >
          <path
            d="M14.853 8.25169C16.2247 9.0137 16.2247 10.9863 14.853 11.7483L-8.74228e-07 20L0 -7.86805e-07L14.853 8.25169Z"
            fill="inherit"
          />
        </svg>
      </div>
    </div>
  );
};
