import { FormField, OptionGroup } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { useIntegrationStatusQuery } from 'modules/bookkeep';
import { AccountingBaseSelectInput } from 'modules/bookkeep/settings/accounting/components/AccountingBasePage/components/AccountingBaseSelectInput';
import { useSetAccountingIntegrationMutation } from 'modules/bookkeep/settings/accounting/graphql/hooks';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { routeFor } from 'src/core/constants/routes';

import { type FormValues } from './form';
import { useCompleteTasksInCategoryMutation } from '../../../hooks/useCompleteTasksInCategoryMutation';
import { useUpdateCachedTaskStatus } from '../../SetupHubPage/hooks/useUpdateCachedTaskStatus';
import { routes } from '../../routes';

export const AccountingSoftware = () => {
  const history = useHistory();

  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const companyId = useCompanyId();

  const accountingIntegrationStatusQueryResult = useIntegrationStatusQuery();
  const accountingIntegrationStatus = unwrapQuery(
    accountingIntegrationStatusQueryResult,
  );
  const currentAccountingIntegration =
    accountingIntegrationStatus?.integration ?? 'noIntegration';
  const setAccountingIntegration = useSetAccountingIntegrationMutation({
    isAccountingSwitch: currentAccountingIntegration !== 'noIntegration',
  });

  const updateCachedTaskStatus = useUpdateCachedTaskStatus();
  const [completeTasksInCategory] = useCompleteTasksInCategoryMutation();

  const goToHub = () => {
    const setupHubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(setupHubPath);
  };

  const { errors, handleSubmit, setFieldValue, submitCount, values } =
    useFormik<FormValues>({
      initialValues: {
        useSpendeskForPreBookkeeping: null,
        selectedIntegration: 'noIntegration',
      },
      enableReinitialize: true,
      validate: (newValues) => {
        const newErrors: FormikErrors<FormValues> = {};
        if (newValues.useSpendeskForPreBookkeeping === null) {
          newErrors.useSpendeskForPreBookkeeping = t('misc.requiredField');
        }
        if (
          newValues.useSpendeskForPreBookkeeping &&
          newValues.selectedIntegration === 'noIntegration'
        ) {
          newErrors.selectedIntegration = t('misc.requiredField');
        }
        return newErrors;
      },
      validateOnChange: true,
      onSubmit: async (newValues) => {
        if (newValues.useSpendeskForPreBookkeeping) {
          if (newValues.selectedIntegration === 'noIntegration') {
            // Shouldn't happen thanks to validation, but we need to please TS
            return;
          }
          await setAccountingIntegration(newValues.selectedIntegration);
          updateCachedTaskStatus('accounting_software');
          goToHub();
          return;
        }

        try {
          await completeTasksInCategory({ category: 'accounting' });
          goToHub();
        } catch {
          dangerNotif(t('misc.errors.unknownError'));
        }
      },
    });

  return (
    <>
      <div className="page__container">
        <TaskStepLayout
          description={t('setupHub.accountingSoftware.description')}
          isLastStep
          onBack={goToHub}
          onNext={handleSubmit}
          title={t('setupHub.accountingSoftware.title')}
          video={{
            // TODO(GROW-1484): Replace with localized video URL
            url: '',
            title: t('setupHub.accountingSoftware.helpTitle'),
          }}
        >
          <FormField
            className="mb-16"
            label={t(
              'setupHub.accountingSoftware.useSpendeskForPreBookkeepingLabel',
            )}
            alertMessage={
              submitCount > 0 ? errors.useSpendeskForPreBookkeeping : undefined
            }
          >
            <OptionGroup
              name="useSpendeskForPreBookkeeping"
              onChange={(event) =>
                setFieldValue(
                  'useSpendeskForPreBookkeeping',
                  event.target.value === 'true',
                )
              }
              options={[
                { value: true, label: t('misc.yes') },
                { value: false, label: t('misc.no') },
              ]}
              value={values.useSpendeskForPreBookkeeping}
            />
          </FormField>
          {values.useSpendeskForPreBookkeeping && (
            <AccountingBaseSelectInput
              alertMessage={
                submitCount > 0 ? errors.selectedIntegration : undefined
              }
              onSelect={(integration) =>
                setFieldValue('selectedIntegration', integration)
              }
              selectedIntegration={values.selectedIntegration}
              visuallyHideLabel
            />
          )}
        </TaskStepLayout>
      </div>
    </>
  );
};
