import {
  CalendarRange,
  Popover,
  DatePickerInput,
  FormField,
  type DateRange,
  type PopoverProps,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useId } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { DateRangeActions } from './DateRangeActions';

export type Props = {
  calendarId: string;
  initialValue?: DateRange;
  value: DateRange;
  onChange: (value: DateRange) => void;
  fit?: 'content' | 'parent';
  className?: string;
  withLabel?: boolean;
  withSeparator?: boolean;
  name?: string;
  inputClassName?: string;
  placement?: PopoverProps['placement'];
};

export const DateRangeFormField = ({
  className = '',
  value,
  initialValue = [undefined, undefined],
  onChange,
  fit = 'content',
  calendarId,
  withLabel = true,
  withSeparator = false,
  inputClassName,
  placement,
  ...props
}: Props) => {
  const { t } = useTranslation('global');

  const openCalendar = (onClick: () => void, isOpen: boolean) => {
    if (!isOpen) {
      onClick();
    }
  };

  const closeCalendar = (onClick: () => void, isOpen: boolean) => {
    if (isOpen) {
      onClick();
    }
  };

  const id = useId();

  const [from, to] = value;

  return (
    <Popover
      placement={placement}
      className={fit === 'parent' ? 'w-full' : 'w-fit'}
      renderTrigger={({ onClick, ...triggerProps }, isOpen) => {
        const fromInput = (
          <DatePickerInput
            calendarId={calendarId}
            {...triggerProps}
            {...props}
            {...{ id: `from-${id}` }}
            openCalendar={() => openCalendar(onClick, isOpen)}
            closeCalendar={() => closeCalendar(onClick, isOpen)}
            isCalendarOpen={isOpen}
            value={from}
            onChange={(date) => onChange([date, to])}
            // @ts-expect-error DatePickerInput is missing `className` prop definition
            className={inputClassName}
          />
        );

        const toInput = (
          <DatePickerInput
            calendarId={calendarId}
            {...triggerProps}
            {...props}
            {...{ id: `to-${id}` }}
            openCalendar={() => openCalendar(onClick, isOpen)}
            closeCalendar={() => closeCalendar(onClick, isOpen)}
            isCalendarOpen={isOpen}
            value={to}
            onChange={(date) => onChange([from, date])}
            // @ts-expect-error DatePickerInput is missing `className` prop definition
            className={inputClassName}
          />
        );

        return (
          <div
            className={classNames(
              'flex flex-row items-center gap-8',
              className,
            )}
          >
            {withLabel ? (
              <FormField
                label={t('misc.from')}
                className="w-full"
                htmlFor={`from-${id}`}
              >
                {fromInput}
              </FormField>
            ) : (
              fromInput
            )}

            {withSeparator && t('misc.and')}

            {withLabel ? (
              <FormField
                label={t('misc.to')}
                className="w-full"
                htmlFor={`to-${id}`}
              >
                {toInput}
              </FormField>
            ) : (
              toInput
            )}
          </div>
        );
      }}
    >
      {(closeDropdown) => (
        <>
          <DateRangeActions
            className="border-0 border-b border-solid border-default"
            onChange={(newValue) => {
              if (newValue.every(Boolean)) {
                onChange(newValue);
              } else {
                onChange(initialValue);
              }
              closeDropdown();
            }}
          />
          <div className="px-24 py-16" id={calendarId}>
            <CalendarRange
              value={value}
              onClick={(newValue) => {
                onChange(newValue);
                if (newValue.every(Boolean)) {
                  closeDropdown();
                }
              }}
            />
          </div>
        </>
      )}
    </Popover>
  );
};
