import { IconButton } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import { createRef, type ReactNode } from 'react';
import { useClickAway } from 'react-use';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import Illustration from './Illustration.svg';

import './Popover.css';

type Props = {
  title: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
  className?: string;
  onClose(): void;
  disableClickAwayClose?: boolean;
  hideCloseButton?: boolean;
};

export const Popover = ({
  title,
  content,
  actions,
  className,
  disableClickAwayClose = false,
  hideCloseButton = false,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');
  const notificationReference = createRef<HTMLElement>();
  useClickAway(notificationReference, () => {
    if (!disableClickAwayClose) {
      onClose();
    }
  });

  return (
    <aside
      className={classnames('Popover', className)}
      ref={notificationReference}
    >
      {!hideCloseButton && (
        <IconButton
          className="Popover__close-button"
          variant="tertiaryNeutral"
          iconName="cross"
          onClick={onClose}
          aria-label={t('misc.close')}
        />
      )}
      <div className="rounded-t-8 bg-primary-brand-default py-24 text-center">
        <img src={Illustration} className="text-center" alt="" />
      </div>
      <div className="p-16">
        <h3 className="title-l">{title}</h3>
        {content && <p className="body-m">{content}</p>}
        {actions && <footer className="Popover__footer">{actions}</footer>}
      </div>
    </aside>
  );
};
