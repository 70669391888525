import { Avatar, Table, type TableColumn } from '@dev-spendesk/grapes';
import uniq from 'lodash/uniq';
import React, { useMemo } from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { type TeamUserRaw, type TeamRaw } from 'modules/members/models/teams';
import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import FEATURES from 'src/core/constants/features';

export type ApprovalScheme = {
  teamId: string;
  rules: {
    steps: {
      approverId: string;
    }[];
    isAoRule: boolean;
  }[];
};

type Props = {
  teams: TeamRaw[];
  approvalSchemes: ApprovalScheme[];
  users: TeamUserRaw[];
  onEditGroup: (groupId: string) => Promise<void>;
};

type TeamRow = {
  group: TeamRaw;
  members: number;
  admins: TeamUserRaw[];
  approvers: TeamUserRaw[];
};

function getGroupApprovers(
  group: TeamRaw,
  approvalSchemes: ApprovalScheme[],
  users: TeamUserRaw[],
): TeamUserRaw[] {
  const scheme = approvalSchemes.find(
    (approvalScheme) => approvalScheme.teamId === group.id,
  );
  const rules = (scheme && scheme.rules) || [];
  // filter-out the `isAoRule` we don't want to include the AO in the list of the approvers if he/she's not part of the approval flow
  // However, if there's only one rule and it's the AO rule, we still want to show the AO
  const showAo = rules.length === 1 && rules[0].isAoRule;

  const filteredRules = showAo ? rules : rules.filter((rule) => !rule.isAoRule);

  const approversIds = uniq(
    (filteredRules ?? []).flatMap((rule) =>
      (rule.steps || []).map((step) => step.approverId),
    ),
  );

  return approversIds
    .map((id) => {
      const user = users.find((u) => u.id === id);
      if (!user) {
        // eslint-disable-next-line no-console
        console.error(`Could not find a user with ID ${id}`);
      }
      return user;
    })
    .filter(Boolean) as TeamUserRaw[];
}

export const TeamList = ({
  teams,
  approvalSchemes,
  users,
  onEditGroup,
}: Props) => {
  const { t } = useTranslation('global');

  const isTeamManagerFeatureEnabled = useFeature(FEATURES.TEAM_MANAGER);
  const isApprovalFlowsFeatureEnabled = useFeature(FEATURES.APPROVAL_FLOWS);
  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );

  const data: TeamRow[] = useMemo(
    () =>
      // users & invites are mixed in the table, merge them
      teams.map((team) => ({
        group: team,
        members: team.members,
        admins: team.admins,
        approvers: getGroupApprovers(team, approvalSchemes, users),
      })),
    [teams],
  );

  const showTeamManagersColumn =
    isTeamManagerFeatureEnabled && !isCostCentersFeatureEnabled;
  const showApproversColumn =
    isApprovalFlowsFeatureEnabled && !isCostCentersFeatureEnabled;

  const columns: TableColumn<TeamRow>[] = [
    {
      header: t('misc.name'),
      id: 'name',
      width: '25%',
      renderCell: ({ group }) => (
        <div className="flex items-center gap-16">
          <Avatar className="shrink-0" text={group.name} />
          <EllipsisTooltip text={group.name} />
        </div>
      ),
    },
    {
      header: t('misc.members'),
      id: 'members',
      width: !showApproversColumn && !showTeamManagersColumn ? '75%' : '15%',
      renderCell: ({ members }) => {
        return <span className="ellipsis">{members}</span>;
      },
    },
  ];

  if (showTeamManagersColumn) {
    columns.push({
      header: t('misc.manager'),
      id: 'admins',
      width: showApproversColumn ? '20%' : '60%',
      renderCell: ({ admins }) => {
        const adminsValues = admins.map((admin, index) => {
          return (
            <span
              key={admin.id}
              className={admin.pending ? 'text-primary' : undefined}
            >
              {admin.pending ? admin.email : admin.fullname}
              {index < admins.length - 1 ? ', ' : ''}
            </span>
          );
        });

        return <span className="ellipsis">{adminsValues}</span>;
      },
    });
  }

  if (showApproversColumn) {
    columns.push({
      header: t('misc.approvers'),
      id: 'approvers',
      width: '40%',
      renderCell: ({ approvers }) => {
        const approversValues = approvers.map((approver, index) => {
          return (
            <span
              key={approver.id}
              className={approver.pending ? 'text-primary' : undefined}
            >
              {approver.pending ? approver.email : approver.fullname}
              {index < approvers.length - 1 ? ', ' : ''}
            </span>
          );
        });

        return <span>{approversValues}</span>;
      },
    });
  }

  return (
    <Table
      columns={columns}
      data={data}
      emptyState={{ title: t('members.noDataContentTeams') }}
      onRowClick={({ group }) => onEditGroup(group.id)}
    />
  );
};
