import { Button, SkeletonText } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useState } from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { type EmployeeAccount } from 'modules/bookkeep/settings/accounting';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { EmployeeAccountAddModal } from './EmployeeAccountAddModal';
import { EmployeeAccountDeleteModal } from './EmployeeAccountDeleteModal';
import { type ModalState } from './ModalState';
import styles from '../EmployeeAccountsSection.module.css';
import { IndividualEmployeeAccountsTable } from '../IndividualEmployeeAccountsTable';
import {
  getSpecificEmployeeAccountsDescriptionI18nKey,
  getSpecificEmployeeAccountsHelpCenterLink,
} from '../translations';

interface Props {
  integrationStatus: IntegrationStatusWithIntegration;
  employeeAccountsQueryState: InfiniteQueryState<EmployeeAccount[]>;
}

export function IndividualEmployees({
  integrationStatus,
  employeeAccountsQueryState,
}: Props) {
  const { t } = useTranslation('global');

  const [modalState, setModalState] = useState<ModalState>({ kind: 'closed' });

  return (
    <div
      id="IntegrationAccountingPage__employee-accounts-individual-employees"
      className="IntegrationAccountingPage__employee-accounts-individual-employees"
    >
      <div className={styles.header}>
        <div className={styles.headerText}>
          <h3 className="IntegrationAccountingPage__section-title heading-l">
            {t(
              'bookkeep.integrations.settings.sectionSpecificEmployeeAccounts',
            )}
          </h3>
          <p className={classNames(styles.headerTextDescription, 'body-m')}>
            {t(
              getSpecificEmployeeAccountsDescriptionI18nKey(
                integrationStatus.integration,
              ),
            )}
            &nbsp;|&nbsp;
            <a
              href={getSpecificEmployeeAccountsHelpCenterLink(
                integrationStatus.integration,
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('misc.helpCenterArticle')}
            </a>
          </p>
        </div>
        <Button
          data-testid="new-employee-account"
          className={styles.headerBtn}
          text={t(
            'bookkeep.integrations.settings.employeeAccountsTable.newEmployee',
          )}
          variant="primaryBrand"
          onClick={() =>
            setModalState({
              kind: 'add',
              employeeAccount: {},
            })
          }
        />
      </div>
      <QuerySuspense
        queryState={employeeAccountsQueryState}
        loading={<SkeletonText size="xxl" key="default_employee_loading" />}
        fallback={(error) => (
          <QueryError queryError={error} componentType="Callout" />
        )}
      >
        {(existingEmployeeAccounts) => (
          <>
            <IndividualEmployeeAccountsTable
              employeeAccounts={existingEmployeeAccounts}
              hasNextPage={employeeAccountsQueryState.hasNextPage}
              fetchNextPage={employeeAccountsQueryState.fetchNextPage}
              isLoading={employeeAccountsQueryState.status === 'loading'}
              integrationStatus={integrationStatus}
              onDelete={(employeeAccount) => {
                setModalState({
                  kind: 'confirmDelete',
                  employeeAccount,
                });
              }}
            />

            <EmployeeAccountAddModal
              integrationStatus={integrationStatus}
              modalState={modalState}
              setModalState={setModalState}
            />

            <EmployeeAccountDeleteModal
              integration={integrationStatus.integration}
              modalState={modalState}
              setModalState={setModalState}
            />
          </>
        )}
      </QuerySuspense>
    </div>
  );
}
