import { Modal, Button } from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  onContinue(): void;
};
export const ImportBudgetModalWarning = ({ onContinue }: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      iconName="triangle-warning"
      iconVariant="warning"
      title={t('budget.budgetaryExercise.importModal.warning.title')}
      actions={[
        <Button
          key="continue"
          text={t('misc.continue')}
          variant="primaryBrand"
          onClick={onContinue}
        />,
      ]}
    >
      <Trans i18nKey="budget.budgetaryExercise.importModal.warning.message" />
    </Modal>
  );
};
