import { Icon } from '@dev-spendesk/grapes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { Component } from 'react';
import { withTranslation, type WithTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';
import TagsInput from 'react-tagsinput';

import Tag from 'src/core/common/components/legacy/Tag/Tag';

import 'react-tagsinput/react-tagsinput.css';
import './InputTagsSelect.css';

const getScrollOffset = (element: HTMLElement): number =>
  element.offsetTop - (element.parentElement?.scrollTop ?? 0);

type BaseOption = { id: string; name?: string };
type Option = BaseOption & { subItems?: BaseOption[] };

type Props = {
  selectedOptions: string[] | null;
  options: Option[] | null;
  createNewItemText: string;
  allowCreateNewItem: boolean;
  getTagDisplayTheme: (value: string) => 'info' | 'success';
  getTagDisplayValue: (value: string) => string;
  onChange: (parts: string[] | null) => void;
} & WithTranslation;

type State = {
  hoveredMenuItem: Option | null;
  hoveredMenuItemOffset: number;
  showOptions: boolean;
  shouldSuggestCreation: boolean;
  newStaticValue: string;
};

class InputTagsSelect extends Component<Props, State> {
  tagInput: HTMLInputElement | null;
  closeTimeout: ReturnType<typeof setTimeout> | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      hoveredMenuItem: null,
      hoveredMenuItemOffset: 0,
      showOptions: false,
      shouldSuggestCreation: false,
      newStaticValue: '',
    };
    this.tagInput = null;
    this.closeTimeout = null;
  }

  handleClickOutside = () => this.setState({ showOptions: false });

  handleChange = (tags: string[]) => {
    this.props.onChange(tags);
    this.setState({
      shouldSuggestCreation: false,
      newStaticValue: '',
    });
  };

  handleTyping = (event: React.ChangeEvent<HTMLInputElement>) => {
    const content = event.target.value;
    this.setState({
      newStaticValue: content,
      shouldSuggestCreation: !isEmpty(content),
    });
  };

  handleHover = (
    option: Option,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (this.closeTimeout !== null) {
      clearTimeout(this.closeTimeout);
    }

    const subOptions = option.subItems;
    const hasSubOptions = !isEmpty(subOptions);
    this.setState({
      hoveredMenuItem: hasSubOptions ? option : null,
      hoveredMenuItemOffset: getScrollOffset(event.currentTarget),
    });
  };

  canShowAvailableChoices = () => {
    const { allowCreateNewItem, options } = this.props;
    const { showOptions, shouldSuggestCreation } = this.state;

    return (
      !isEmpty(options) && // has options
      showOptions && // can show them (focused on input)
      (!allowCreateNewItem || !shouldSuggestCreation)
    ); // not typing
  };

  canShowCreateNewItemChoice = () => {
    const { allowCreateNewItem } = this.props;
    const { shouldSuggestCreation } = this.state;
    return shouldSuggestCreation && allowCreateNewItem;
  };

  selectChoice = (option: Option, event?: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const nextSelectedOptions = (this.props.selectedOptions ?? []).concat(
      option.id,
    );
    this.props.onChange(nextSelectedOptions);
    this.setState({
      shouldSuggestCreation: false,
      hoveredMenuItem: null,
      newStaticValue: '',
    });

    this.tagInput?.focus();
  };

  renderTag = ({
    tag,
    key,
    disabled,
    onRemove,
  }: {
    tag: string;
    key: number;
    disabled: boolean;
    onRemove: (key: number) => void;
  }) => {
    const { getTagDisplayTheme, getTagDisplayValue } = this.props;
    return (
      <Tag
        key={key}
        theme={getTagDisplayTheme(tag)}
        text={getTagDisplayValue(tag)}
        onClick={() => onRemove(key)}
        hasCloseIcon
        isDisabled={disabled}
      />
    );
  };

  renderNewStaticChoice = () => {
    const { createNewItemText, t } = this.props;
    const { newStaticValue } = this.state;

    if (!this.canShowCreateNewItemChoice()) {
      return;
    }

    return (
      <div className="InputTagsSelect__choices is-static-value">
        <button
          type="button"
          className="InputTagsSelect__choices-item"
          onClick={() => this.selectChoice({ id: newStaticValue })}
        >
          <span>{`+ ${createNewItemText ?? t('misc.createNewValue')}`}</span>
        </button>
      </div>
    );
  };

  renderAvailableChoices = () => {
    const { options } = this.props;

    if (!this.canShowAvailableChoices()) {
      return;
    }

    return (
      <div className="InputTagsSelect__choices">
        {map(options, (option) => {
          const subRecommended = option.subItems;
          const hasSubRecommendedItems = !isEmpty(subRecommended);

          return (
            <button
              type="button"
              key={option.id}
              className="InputTagsSelect__choices-item"
              onMouseEnter={(event: React.MouseEvent<HTMLButtonElement>) =>
                this.handleHover(option, event)
              }
              onClick={(event) =>
                !hasSubRecommendedItems && this.selectChoice(option, event)
              }
            >
              <span>{option.name}</span>
              {hasSubRecommendedItems && <Icon name="chevron-right" />}
            </button>
          );
        })}
      </div>
    );
  };

  renderSubChoices = () => {
    const { hoveredMenuItem, hoveredMenuItemOffset } = this.state;

    if (!hoveredMenuItem || !hoveredMenuItem.subItems) {
      return;
    }

    return (
      <div
        className="InputTagsSelect__subchoices"
        style={{ transform: `translateY(${hoveredMenuItemOffset}px)` }}
      >
        {map(hoveredMenuItem.subItems, (subItem) => (
          <button
            type="button"
            key={subItem.id}
            className="InputTagsSelect__subchoices-item"
            onMouseEnter={() => {
              if (this.closeTimeout !== null) {
                clearTimeout(this.closeTimeout);
              }
            }}
            onClick={(event) => this.selectChoice(subItem, event)}
          >
            {subItem.name}
          </button>
        ))}
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const { newStaticValue } = this.state;

    const inputProps = {
      placeholder: t('exports.entryId.selectOrType'),
      onFocus: () => this.setState({ showOptions: true }),
      onChange: this.handleTyping,
      value: newStaticValue || '',
      ref: (element: HTMLInputElement | null) => {
        this.tagInput = element;
      },
    };

    return (
      <div
        className="InputTagsSelect"
        onMouseLeave={() => {
          // do not close instantly as there is spacing between the two menus
          this.closeTimeout = setTimeout(() => {
            this.setState({ hoveredMenuItem: null });
          }, 100);
        }}
      >
        <TagsInput
          className="InputTagsSelect__selector ColumnEditModal__tag-selector"
          value={this.props.selectedOptions || []}
          renderTag={this.renderTag}
          onChange={this.handleChange}
          inputProps={inputProps}
        />
        {this.renderAvailableChoices()}
        {this.renderSubChoices()}
        {this.renderNewStaticChoice()}
      </div>
    );
  }
}

export default withTranslation()(onClickOutside(InputTagsSelect));
