import React from 'react';

import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

type Props = { t: TGlobalFunctionTyped };

export const AccountRiskAlert = ({ t }: Props) => {
  return (
    <div className="flex flex-col gap-8 rounded-8 bg-primary-alert-default p-32 text-complementary">
      <span className="title-l">
        {t('overdueInvoice.closedDoor.risk.title')}
      </span>
      <p className="body-m">
        {t('overdueInvoice.closedDoor.risk.description')}
      </p>
    </div>
  );
};
