import { Table, type TableMiniColumn } from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { buildSupplierBankInfoItems } from './helpers';
import { type Supplier } from '../supplier';

type Props = {
  className?: string;
  supplier: Supplier;
};

type TableField = {
  label: string;
  value: string;
};

const columns: TableMiniColumn<TableField>[] = [
  {
    id: 'bankField',
    width: '150px',
    renderCell: ({ label }) => <div className="truncate">{label}</div>,
  },
  {
    id: 'bankFieldValue',
    renderCell: ({ value }) => value,
  },
];

export const BankInfoPreview = ({ className, supplier }: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();

  const bankInfoItems = buildSupplierBankInfoItems({
    supplier,
    companyCountry: company.country,
    companyCurrency: company.currency,
    translator: t,
  });

  return bankInfoItems.length ? (
    <Table.Mini
      getRowId={(row) => row.label}
      className={classNames('bg-primary-default', className)}
      data={bankInfoItems}
      columns={columns}
    />
  ) : null;
};
