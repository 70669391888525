import { Avatar, DATE_FORMAT } from '@dev-spendesk/grapes';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';
import { SupplierLogo } from 'common/components/SupplierLogo';
import { useTranslation } from 'common/hooks/useTranslation';
import { RequestStateTag } from 'modules/requests/list/components/RequestStateTag/RequestStateTag';
import { getAmountToDisplay } from 'src/core/utils/entities/request';
import { formatMoney } from 'src/core/utils/money';

import { type Request } from './RequestsListBox';

type Props = {
  request: Request;
  titleId: string;
};

const PlaceholderBlock = ({ size }: { size: string }) => (
  <div className="h-8 rounded bg-tertiary-default" style={{ width: size }} />
);

export const DraftRequestRow = ({ request, titleId }: Props) => {
  const { localeFormat } = useTranslation('global');
  const requestAmount = getAmountToDisplay(request);

  return (
    <div className="flex min-w-0 items-center gap-16 py-4">
      {request.supplier ? (
        <SupplierLogo
          name={request.supplier.name}
          className="shrink-0"
          lazyLoad
        />
      ) : (
        <Avatar
          text={request.user?.fullname ?? request.user?.email ?? ''}
          src={request.user?.avatar ?? ''}
          className="shrink-0"
          lazyLoad
        />
      )}
      <div className="min-w-0 flex-grow">
        <div
          className="flex items-center gap-8 text-primary body-m"
          id={titleId}
        >
          {request.supplier ? (
            <EllipsisTooltip
              className="max-w-[50%] shrink-0"
              text={request.supplier.name}
            />
          ) : (
            <PlaceholderBlock size="60px" />
          )}
          {request.cost_center && (
            <>
              <span>•</span>
              <EllipsisTooltip text={request.cost_center?.name} />
            </>
          )}
          <RequestStateTag request={request} />
        </div>
        <div className="flex gap-8 text-secondary-bg-primary body-s">
          {request.user?.fullname && (
            <>
              <EllipsisTooltip
                className="max-w-[70%] shrink-0 title-s"
                text={request.user?.fullname ?? request.user?.email ?? ''}
              />
              {request.description && <span>•</span>}
            </>
          )}
          <EllipsisTooltip text={request.description} />
        </div>
      </div>
      <div className="flex shrink-0 flex-col items-end">
        {requestAmount && request.currency_declared ? (
          <div className="text-primary title-m">
            {formatMoney(requestAmount, request.currency_declared)}
          </div>
        ) : (
          <PlaceholderBlock size="60px" />
        )}
        <div className="mt-4 text-secondary-bg-primary body-s">
          {localeFormat(new Date(request.created_at), DATE_FORMAT.MEDIUM)}
        </div>
      </div>
    </div>
  );
};
