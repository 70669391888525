import {
  type ValueFilter,
  type Filter as CoreTypeFilter,
} from '@spendesk/bookkeeping-core-types';

import {
  type SubfilterValueWithOptionalField,
  type Filter as DomainFilter,
  type SubfilterValue,
} from 'common/components/FilterBuilder/FilterBuilderModel';

/**
 * Domain to core type filter transformer
 */

export const toCoreTypeFilter = (filter: DomainFilter): CoreTypeFilter => {
  return {
    ...filter,
    subfilters: filter.subfilters.map((subfilter) => ({
      ...subfilter,
      subfilters: subfilter.subfilters.map((subfilterValue) => {
        const [, customFieldId] =
          subfilterValue.field?.match(/customField-(.*)/) ?? [];
        if (customFieldId) {
          return getSubfilterValueForIdOperatorApi({
            ...subfilterValue,
            field: 'customField',
            value: {
              key: customFieldId,
              value: Array.isArray(subfilterValue.value)
                ? subfilterValue.value
                : [subfilterValue.value],
            },
          });
        }

        switch (subfilterValue.field) {
          case 'payableType':
          case 'paymentStatus':
          case 'bookkeepingStatus':
          case 'documentaryEvidenceStatus':
          case 'requestor':
          case 'supplier':
          case 'expenseAccount':
          case 'team':
          case 'costCenter':
            return getSubfilterValueForIdOperatorApi({
              ...subfilterValue,
              value: Array.isArray(subfilterValue.value)
                ? subfilterValue.value
                : [subfilterValue.value],
            });

          case 'grossAmount':
          case 'netAmount':
          case 'vatAmount':
            return getSubfilterValueForAmountOperatorApi(subfilterValue);

          case 'documentaryEvidenceNumber':
          case 'description':
            return getSubfilterValueForStringOperatorApi(subfilterValue);

          case 'payableDate':
            return getSubfilterValueForDateOperatorApi(subfilterValue);

          // TODO@financeAccountant@CORE-5208: Implement other fields

          default:
            throw new Error(`Unknown field: ${subfilterValue.field}`);
        }
      }),
    })),
  };
};

const getSubfilterValueForIdOperatorApi = (
  subfilterValue: SubfilterValueWithOptionalField,
): ValueFilter => {
  switch (subfilterValue.operator) {
    case 'isEmpty':
      return {
        field: subfilterValue.field,
        operator: '=',
        value: null,
      } as ValueFilter;

    case 'isNotEmpty':
      return {
        field: subfilterValue.field,
        operator: '!=',
        value: null,
      } as ValueFilter;

    case 'is':
      return {
        field: subfilterValue.field,
        operator: '=',
        value: subfilterValue.value,
      } as ValueFilter;

    case 'isNot':
      return {
        field: subfilterValue.field,
        operator: '!=',
        value: subfilterValue.value,
      } as ValueFilter;

    default:
      throw new Error(`Unknown operator: ${subfilterValue.operator}`);
  }
};

const getSubfilterValueForStringOperatorApi = (
  subfilterValue: SubfilterValueWithOptionalField,
): ValueFilter => {
  switch (subfilterValue.operator) {
    case 'isEmpty':
      return {
        field: subfilterValue.field,
        operator: '=',
        value: null,
      } as ValueFilter;

    case 'isNotEmpty':
      return {
        field: subfilterValue.field,
        operator: '!=',
        value: null,
      } as ValueFilter;

    case 'is':
      return {
        field: subfilterValue.field,
        operator: '=',
        value: subfilterValue.value,
      } as ValueFilter;

    case 'isNot':
      return {
        field: subfilterValue.field,
        operator: '!=',
        value: subfilterValue.value,
      } as ValueFilter;

    case 'contains':
    case 'notContains':
    case 'startsWith':
    case 'endsWith':
      return subfilterValue as ValueFilter;

    default:
      throw new Error(`Unknown operator: ${subfilterValue.operator}`);
  }
};

const getSubfilterValueForDateOperatorApi = (
  subfilterValue: SubfilterValueWithOptionalField,
): ValueFilter => {
  switch (subfilterValue.operator) {
    case 'is':
      return {
        field: subfilterValue.field,
        operator: '=',
        value: subfilterValue.value,
      } as ValueFilter;

    case 'isBetween':
      return {
        field: subfilterValue.field,
        operator: 'between',
        value: subfilterValue.value,
      } as ValueFilter;

    case 'inLast':
    case 'before':
    case 'after':
      return subfilterValue as ValueFilter;

    default:
      throw new Error(`Unknown operator: ${subfilterValue.operator}`);
  }
};

const getSubfilterValueForAmountOperatorApi = (
  subfilterValue: SubfilterValueWithOptionalField,
): ValueFilter => {
  switch (subfilterValue.operator) {
    case '=':
    case '!=':
    case '<':
    case '<=':
    case '>':
    case '>=':
      return {
        field: subfilterValue.field,
        operator: subfilterValue.operator,
        value: subfilterValue.value,
      } as ValueFilter;

    case 'isBetween':
      return {
        field: subfilterValue.field,
        operator: 'between',
        value: subfilterValue.value,
      } as ValueFilter;

    case 'isNotBetween':
      return {
        field: subfilterValue.field,
        operator: 'notBetween',
        value: subfilterValue.value,
      } as ValueFilter;

    default:
      throw new Error(`Unknown operator: ${subfilterValue.operator}`);
  }
};

/**
 * Core type to domain filter transformer
 */

export const fromCoreTypeFilter = (filter: CoreTypeFilter): DomainFilter => {
  const domainFilter =
    'value' in filter
      ? {
          operator: 'and' as const,
          subfilters: [{ operator: 'and' as const, subfilters: [filter] }],
        }
      : filter;

  return {
    ...domainFilter,
    subfilters: domainFilter.subfilters
      .filter((subfilter) => 'subfilters' in subfilter)
      .map((subfilter) => ({
        ...subfilter,
        subfilters: subfilter.subfilters
          .filter((subfilter2) => 'field' in subfilter2)
          .map((subfilterValue) => {
            switch (subfilterValue.field) {
              case 'payableType':
              case 'paymentStatus':
              case 'bookkeepingStatus':
              case 'documentaryEvidenceStatus':
              case 'requestor':
              case 'supplier':
              case 'expenseAccount':
              case 'team':
              case 'costCenter':
                return getSubfilterValueForIdOperator(subfilterValue);

              case 'customField': {
                if (!subfilterValue.value) {
                  throw new Error('Unknown custom field id');
                }
                const { key, value } = subfilterValue.value;
                return {
                  ...getSubfilterValueForIdOperator({
                    ...subfilterValue,
                    value,
                  } as unknown as ValueFilter),
                  field: `customField-${key}`,
                };
              }

              case 'grossAmount':
              case 'netAmount':
              case 'vatAmount':
                return getSubfilterValueForAmountOperator(subfilterValue);

              case 'documentaryEvidenceNumber':
              case 'description':
                return getSubfilterValueForStringOperator(subfilterValue);

              case 'payableDate':
                return getSubfilterValueForDateOperator(subfilterValue);

              // TODO@financeAccountant@CORE-5208: Implement other fields

              default:
                throw new Error(`Unknown field: ${subfilterValue.field}`);
            }
          }),
      })),
  };
};

const getSubfilterValueForIdOperator = (
  valueFilter: ValueFilter,
): SubfilterValue => {
  if (valueFilter.operator === '=' && valueFilter.value === null) {
    return {
      field: valueFilter.field,
      operator: 'isEmpty',
      value: null,
    };
  }

  if (valueFilter.operator === '=' && valueFilter.value !== null) {
    return {
      field: valueFilter.field,
      operator: 'is',
      value: valueFilter.value,
    };
  }

  if (valueFilter.operator === '!=' && valueFilter.value === null) {
    return {
      field: valueFilter.field,
      operator: 'isNotEmpty',
      value: null,
    };
  }

  if (valueFilter.operator === '!=' && valueFilter.value !== null) {
    return {
      field: valueFilter.field,
      operator: 'isNot',
      value: valueFilter.value,
    };
  }

  throw new Error(`Unknown operator: ${valueFilter.operator}`);
};

const getSubfilterValueForStringOperator = (
  valueFilter: ValueFilter,
): SubfilterValue => {
  if (valueFilter.operator === '=' && valueFilter.value === null) {
    return {
      field: valueFilter.field,
      operator: 'isEmpty',
      value: null,
    };
  }

  if (valueFilter.operator === '=' && valueFilter.value !== null) {
    return {
      field: valueFilter.field,
      operator: 'is',
      value: valueFilter.value,
    };
  }

  if (valueFilter.operator === '!=' && valueFilter.value === null) {
    return {
      field: valueFilter.field,
      operator: 'isNotEmpty',
      value: null,
    };
  }

  if (valueFilter.operator === '!=' && valueFilter.value !== null) {
    return {
      field: valueFilter.field,
      operator: 'isNot',
      value: valueFilter.value,
    };
  }

  if (
    ['contains', 'notContains', 'startsWith', 'endsWith'].includes(
      valueFilter.operator,
    )
  ) {
    return valueFilter;
  }

  throw new Error(`Unknown operator: ${valueFilter.operator}`);
};

const getSubfilterValueForDateOperator = (
  valueFilter: ValueFilter,
): SubfilterValue => {
  if (valueFilter.operator === '=') {
    return {
      field: valueFilter.field,
      operator: 'is',
      value: valueFilter.value,
    };
  }

  if (valueFilter.operator === 'between') {
    return {
      field: valueFilter.field,
      operator: 'isBetween',
      value: valueFilter.value,
    };
  }

  if (['inLast', 'before', 'after'].includes(valueFilter.operator)) {
    return valueFilter;
  }

  throw new Error(`Unknown operator: ${valueFilter.operator}`);
};

const getSubfilterValueForAmountOperator = (
  valueFilter: ValueFilter,
): SubfilterValue => {
  switch (valueFilter.operator) {
    case '=':
    case '!=':
    case '<':
    case '<=':
    case '>':
    case '>=':
      return {
        field: valueFilter.field,
        operator: valueFilter.operator,
        value: valueFilter.value,
      };

    case 'between':
      return {
        field: valueFilter.field,
        operator: 'isBetween',
        value: valueFilter.value,
      };

    case 'notBetween':
      return {
        field: valueFilter.field,
        operator: 'isNotBetween',
        value: valueFilter.value,
      };

    default:
      throw new Error(`Unknown operator: ${valueFilter.operator}`);
  }
};
