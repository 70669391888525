import { Tag } from '@dev-spendesk/grapes';

import { type ApprovalRule } from 'src/core/modules/company/structure/approval-flows';

import { ApprovalSchemeCreation } from './ApprovalSchemeCreation';
import { ApprovalSchemeEdition } from './ApprovalSchemeEdition';

type Props = {
  onClose: () => void;
  approvalRules: ApprovalRule[];
  approvalSchemeId?: string;
};

export const StandardApprovalFlowPanelContent = ({
  onClose,
  approvalRules,
  approvalSchemeId,
}: Props) => {
  // TODO: refactor this component as like in DimensionApprovalFlowPanelContent. Here it shouldn't use two components for creation and edition.
  let content = null;
  if (approvalSchemeId === undefined) {
    content = <ApprovalSchemeCreation onClose={onClose} />;
  } else {
    content = (
      <ApprovalSchemeEdition
        approvalRules={approvalRules}
        approvalSchemeId={approvalSchemeId}
        onClose={onClose}
      />
    );
  }

  return (
    <>
      <div>
        <Tag variant="purple">Standard approval workflow</Tag>
        <div className="mb-24 mt-16 text-primary body-s">
          This condition will be executed if no other workflow applies.
          <br />
          This ensures that any request has a reviewer.
        </div>
      </div>
      <div className="mb-8 text-primary title-m">Approval required from</div>
      {content}
    </>
  );
};
