import { Banner, Button } from '@dev-spendesk/grapes';
import { format } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import internationalHenry from './icons/internationalHenry.svg';

type Props = {
  company: {
    id: string;
  };
  recardRequest: {
    next_card_order: {
      address_recipient_name: string;
      address: string;
      zipcode: string;
      city: string;
      estimated_received_at: string;
      considered_as_lost?: boolean;
      card_id: string;
      user_id: string;
    };
  };
};

export const RecardBanner = ({ company, recardRequest }: Props) => {
  const { t } = useTranslation('global');
  const history = useHistory();

  return (
    <Banner
      className="mb-24"
      illustration={
        <img
          src={internationalHenry}
          alt=""
          className="w-[150px] object-contain"
        />
      }
      onClick={() => {
        if (recardRequest?.next_card_order) {
          history.push(
            routeFor(routes.CARD_ACTIVATION.path, {
              cardId: recardRequest?.next_card_order?.card_id,
              company: company.id,
            }),
          );
        } else {
          history.push(
            routeFor(routes.RECARD.path, {
              company: company.id,
              status: 'activate',
            }),
          );
        }
      }}
      actionText={
        recardRequest.next_card_order
          ? t('recard.banner.activateCard')
          : t('recard.banner.confirmOrder')
      }
      title={
        recardRequest.next_card_order
          ? t('recard.banner.orderedTitleWithDate', {
              date: format(
                new Date(recardRequest.next_card_order.estimated_received_at),
                'dd/MM',
              ),
            })
          : t('recard.banner.readyTitle')
      }
    >
      <p>
        {recardRequest.next_card_order
          ? `${recardRequest.next_card_order.address_recipient_name} -
          ${recardRequest.next_card_order.address}
          ${recardRequest.next_card_order.zipcode}
          ${recardRequest.next_card_order.city}`
          : t('recard.banner.readySubtitle')}
      </p>
      {recardRequest.next_card_order?.considered_as_lost && (
        <Link
          to={routeFor(routes.RECARD.path, {
            company: company.id,
            status: 'card_lost_validation',
          })}
          onClick={() =>
            track(AnalyticEventName.CARD_NOT_RECEIVED_CLICKED, {
              cardId: recardRequest.next_card_order?.card_id,
            })
          }
        >
          <Button
            variant="secondaryNeutral"
            text={t('recard.banner.cardNotReceived')}
            className="mt-16"
          />
        </Link>
      )}
    </Banner>
  );
};
