import { EmptyState } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  className?: string;
  title?: string;
  subtitle?: string;
};

export const EmptyStateLoading = ({ className, title, subtitle }: Props) => {
  const { t } = useTranslation('global');

  return (
    <EmptyState
      className={className}
      title={title ?? t('lazyLoading.loading.page.title')}
      subtitle={subtitle ?? t('lazyLoading.loading.page.subtitle')}
      iconName="ellipsis-horizontal"
      iconVariant="info"
      actions={<></>}
    />
  );
};
