import { Callout, Button } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { getI18nContext } from 'src/core/selectors/i18n';
import { openSupportChat } from 'src/core/utils/supportChat';

import {
  isCannotActivateReason,
  type WireTransferCannotToggleReason,
  type WireTransferPayableEntity,
} from '../../../../models';
import { WireTransferBankInfoModal } from '../WireTransferBankInfoModal';

interface WireTransferFeatureToggleErrorProps {
  wireTransferPayableEntity: WireTransferPayableEntity;
  reason: WireTransferCannotToggleReason;
}

export const WireTransferFeatureToggleError = ({
  reason,
  wireTransferPayableEntity,
}: WireTransferFeatureToggleErrorProps) => {
  const { t } = useTranslation('global');
  const i18nContext = useSelector(getI18nContext);
  const [isWireTransferBankInfoModalOpen, setIsWireTransferBankInfoModal] =
    useState(false);

  return (
    <>
      <WireTransferBankInfoModal
        accountConfirmation
        isOpen={isWireTransferBankInfoModalOpen}
        onClose={() => setIsWireTransferBankInfoModal(false)}
      />
      {reason === 'ongoingKybProcess' && (
        <Callout title={t('wiretransfer.panel.ongoingKYB')} variant="info" />
      )}
      {reason === 'providerSetupPending' && (
        <Callout title={t('wiretransfer.panel.ongoingSetup')} variant="info" />
      )}
      {reason === 'ongoingWireTransferRequests' && (
        <Callout
          className="mb-16"
          title={
            wireTransferPayableEntity === 'expenses'
              ? t('wiretransfer.panel.ongoingScheduledPayments')
              : t('wiretransfer.panel.invoices.ongoingScheduledPayments')
          }
          variant="warning"
        />
      )}
      {reason === 'missingCompanyBankInfo' && (
        <Button
          onClick={() => setIsWireTransferBankInfoModal(true)}
          text={t('wiretransfer.panel.button')}
          variant="secondaryNeutral"
        />
      )}
      {reason !== 'ongoingKybProcess' &&
        reason !== 'providerSetupPending' &&
        reason !== 'missingCompanyBankInfo' && (
          <Button
            onClick={() => openSupportChat()}
            text={
              i18nContext
                ? t(
                    isCannotActivateReason(reason)
                      ? 'wiretransfer.panel.contactUsToActivate'
                      : 'wiretransfer.panel.contactUsToDeactivate',
                    i18nContext,
                  )
                : t(
                    isCannotActivateReason(reason)
                      ? 'wiretransfer.panel.contactUsToActivate'
                      : 'wiretransfer.panel.contactUsToDeactivate',
                  )
            }
            variant="secondaryNeutral"
          />
        )}
    </>
  );
};
