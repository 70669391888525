import {
  Button,
  FormField,
  Modal,
  TextArea,
  TextInput,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';

import { useTranslation } from 'common/hooks/useTranslation';
import { SupplierAsyncAutocomplete } from 'modules/suppliers';

import { type DeclarationData } from './declarationData';

import './AffidavitPurchaseInfoModal.css';

type Props = Readonly<{
  supplierName?: string;
  onCancel: () => void;
  onContinue: () => void;
  onBack: () => void;
}>;

export const AffidavitPurchaseInfoModal = ({
  onCancel,
  onContinue,
  onBack,
  supplierName,
  // formik props
  handleChange,
  handleBlur,
  setFieldValue,
  values,
  errors,
  touched,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: Props & FormikProps<DeclarationData>) => {
  const { t } = useTranslation('global');

  const hasErrors =
    (errors.purchaseDescription && touched.purchaseDescription) ||
    (errors.supplierAddressCity && touched.supplierAddressCity) ||
    (errors.supplierAddressCountry && touched.supplierAddressCountry) ||
    (errors.supplierAddressThoroughfare &&
      touched.supplierAddressThoroughfare) ||
    (errors.supplierAddressZipCode && touched.supplierAddressZipCode) ||
    (errors.supplierName && touched.supplierName);

  const areFieldsEmpty =
    values.purchaseDescription === '' ||
    values.supplierAddressCity === '' ||
    values.supplierAddressCountry === '' ||
    values.supplierAddressThoroughfare === '' ||
    values.supplierAddressZipCode === '' ||
    values.supplierName === '';

  return (
    <Modal
      isOpen
      onClose={onCancel}
      iconName="receipt-question"
      title={t('payments.affidavitModal.purchaseInfo.title')}
      actions={[
        <Button
          key="back"
          variant="secondaryNeutral"
          text={t('payments.affidavitModal.reasonModal.backButton')}
          onClick={onBack}
        />,
        <Button
          key="continue"
          text={t('payments.affidavitModal.reasonModal.continueButton')}
          onClick={onContinue}
          isDisabled={hasErrors || areFieldsEmpty}
        />,
      ]}
    >
      <FormField
        className="mb-24"
        label={t('misc.supplier')}
        alertMessage={
          touched.supplierName && errors.supplierName
            ? errors.supplierName
            : undefined
        }
      >
        <SupplierAsyncAutocomplete
          isInvoicePayable={false}
          fit="parent"
          placeholder={t('forms.supplier.placeholder')}
          value={
            values.supplierName
              ? {
                  key: values.supplierName,
                  label: values.supplierName,
                }
              : undefined
          }
          isDisabled={!!supplierName}
          onSelect={(newSupplier) => {
            if (newSupplier) {
              setFieldValue('supplierName', newSupplier.label);
            }
          }}
        />
      </FormField>
      <FormField
        className="mb-24"
        label={t('payments.affidavitModal.purchaseInfo.textareaLabel')}
        alertMessage={
          touched.purchaseDescription && errors.purchaseDescription
            ? errors.purchaseDescription
            : undefined
        }
      >
        <TextArea
          rows={5}
          name="purchaseDescription"
          value={values.purchaseDescription ?? ''}
          placeholder={t(
            'payments.affidavitModal.purchaseInfo.describePlaceholder',
          )}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </FormField>
      <div className="AffidavitPurchaseInfoModal__input__grid">
        <FormField
          className="AffidavitPurchaseInfoModal__input__grid__thoroughfare"
          label={t('payments.affidavitModal.purchaseInfo.supplierAddress')}
          alertMessage={
            touched.supplierAddressThoroughfare &&
            errors.supplierAddressThoroughfare
              ? errors.supplierAddressThoroughfare
              : undefined
          }
        >
          <TextInput
            placeholder={t(
              'payments.affidavitModal.purchaseInfo.addressPlaceholder',
            )}
            value={values.supplierAddressThoroughfare}
            name="supplierAddressThoroughfare"
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormField>
        <FormField
          className="AffidavitPurchaseInfoModal__input__grid__zip"
          label={t('payments.affidavitModal.purchaseInfo.zipCodePlaceholder')}
          alertMessage={
            touched.supplierAddressZipCode && errors.supplierAddressZipCode
              ? errors.supplierAddressZipCode
              : undefined
          }
          visuallyHideLabel
        >
          <TextInput
            placeholder={t(
              'payments.affidavitModal.purchaseInfo.zipCodePlaceholder',
            )}
            value={values.supplierAddressZipCode}
            name="supplierAddressZipCode"
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormField>
        <FormField
          className="AffidavitPurchaseInfoModal__input__grid__city"
          label={t('payments.affidavitModal.purchaseInfo.cityPlaceholder')}
          alertMessage={
            touched.supplierAddressCity && errors.supplierAddressCity
              ? errors.supplierAddressCity
              : undefined
          }
          visuallyHideLabel
        >
          <TextInput
            placeholder={t(
              'payments.affidavitModal.purchaseInfo.cityPlaceholder',
            )}
            value={values.supplierAddressCity}
            name="supplierAddressCity"
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormField>
        <FormField
          className="AffidavitPurchaseInfoModal__input__grid__country"
          label={t('payments.affidavitModal.purchaseInfo.countryPlaceholder')}
          alertMessage={
            touched.supplierAddressCountry && errors.supplierAddressCountry
              ? errors.supplierAddressCountry
              : undefined
          }
          visuallyHideLabel
        >
          <TextInput
            placeholder={t(
              'payments.affidavitModal.purchaseInfo.countryPlaceholder',
            )}
            value={values.supplierAddressCountry}
            name="supplierAddressCountry"
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormField>
      </div>
    </Modal>
  );
};
