import { Button, Callout, IconButton, Table } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type ForeignExchangeAccountDifferenceType } from 'src/core/modules/bookkeep/settings/accounting/foreignExchangeAccount';

import styles from './BankAccountsSection.module.css';
import { ForeignExchangeAccountsSkeletonTable } from './ForeignExchageAccountsSkeletonTable';
import { ForeignExchangeAccountModal } from './ForeignExchangeAccountModal';
import {
  type ForeignExchangeAccountsModalState,
  type ForeignExchangeAccountRow,
} from './foreignExchangeAcounts';
import { useForeignExchangeAccountsQuery } from '../../../../hooks/useForeignExchangeAccountsQuery';
import { CellWithButton } from '../../components/CellWithIconButton/CellWithIconButton';

export const ForeignExchangeAccountsSection = () => {
  const { t } = useTranslation('global');

  const foreignExchangeAccountsQueryState = useForeignExchangeAccountsQuery();

  const differenceTypes: ForeignExchangeAccountDifferenceType[] = [
    'loss',
    'gain',
  ];

  const [modalState, setModalState] = useState({
    editedAccount: undefined,
  } as ForeignExchangeAccountsModalState);

  return (
    <div
      id="foreign-exchange-accounts"
      className={styles.foreignEschangeAccountsSection}
    >
      <h3 className="IntegrationAccountingPage__section-title title-xl">
        {t(
          'bookkeep.integrations.settings.sectionForeignExchangeAccounts.title',
        )}
      </h3>
      <p className="IntegrationAccountingPage__section-description body-m">
        {t(
          'bookkeep.integrations.settings.sectionForeignExchangeAccounts.description',
        )}
      </p>

      <QuerySuspense
        queryState={foreignExchangeAccountsQueryState}
        loading={<ForeignExchangeAccountsSkeletonTable />}
        fallback={() => (
          <Callout
            title={t(
              'bookkeep.integrations.settings.sectionForeignExchangeAccounts.table.error',
            )}
            variant="alert"
          />
        )}
      >
        {(foreignExchangeAccounts) => {
          const foreignExchangeAccountRows: ForeignExchangeAccountRow[] =
            differenceTypes.map((differenceType) => {
              const existingDifferenceTypeAccount =
                foreignExchangeAccounts.find(
                  (account) => account.differenceType === differenceType,
                );

              return {
                id: existingDifferenceTypeAccount?.id,
                name: t(
                  `bookkeep.integrations.settings.sectionForeignExchangeAccounts.table.${differenceType}.title`,
                ),
                code: existingDifferenceTypeAccount?.code,
                differenceType,
              };
            });

          return (
            <Table
              rowHeight="compact"
              columns={[
                {
                  id: 'name',
                  header: t(
                    'bookkeep.integrations.settings.sectionForeignExchangeAccounts.table.accountNameColumnTitle',
                  ),
                  width: '50%',
                  renderCell(row) {
                    return <span>{row.name}</span>;
                  },
                },
                {
                  id: 'code',
                  header: t(
                    'bookkeep.integrations.settings.sectionForeignExchangeAccounts.table.accountCodeColumnTitle',
                  ),
                  width: '50%',
                  renderCell(row, { isRowHovered }) {
                    const editAccountButton = row.code ? (
                      <IconButton
                        iconName="pen"
                        onClick={() =>
                          setModalState({
                            editedAccount: row,
                          })
                        }
                        className="CellWithButton__button"
                        aria-label={t('misc.edit')}
                      />
                    ) : (
                      <Button
                        text={t(
                          'bookkeep.integrations.settings.sectionForeignExchangeAccounts.table.selectAccountButton',
                        )}
                        onClick={() =>
                          setModalState({
                            editedAccount: row,
                          })
                        }
                        variant="secondaryNeutral"
                        className="CellWithButton__button"
                      />
                    );
                    return (
                      <CellWithButton
                        isButtonVisible={!row.code || isRowHovered}
                        button={editAccountButton}
                      >
                        <span>{row.code ?? '-'}</span>
                      </CellWithButton>
                    );
                  },
                },
              ]}
              data={foreignExchangeAccountRows}
            />
          );
        }}
      </QuerySuspense>

      {modalState.editedAccount && (
        <ForeignExchangeAccountModal
          modalState={modalState}
          setModalState={setModalState}
        />
      )}
    </div>
  );
};
