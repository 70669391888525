import { type BreakdownGaugePreparedBar } from './BreakdownGauge';
import { BreakdownGaugeLegendItem } from './BreakdownGaugeLegendItem';

type Props = {
  preparedBars: BreakdownGaugePreparedBar[];
  isCondensed?: boolean;
};

export const BreakdownGaugeLegend = ({
  preparedBars,
  isCondensed = false,
}: Props) => (
  <div className="flex w-full flex-auto flex-row flex-wrap justify-between gap-4">
    {preparedBars.map((preparedBar, index, bars) => (
      <BreakdownGaugeLegendItem
        key={`legend-item-${preparedBar.key}`}
        preparedBar={preparedBar}
        isLastItem={index === bars.length - 1}
        isCondensed={isCondensed}
      />
    ))}
  </div>
);
