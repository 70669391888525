import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';

import { NavLink } from '../NavLink/NavLink';

type Props = Readonly<{
  company: {
    id: string;
  };
}>;

/**
 * All Expenses entry
 * Used only by Requester
 */
export const AllExpensesLink = ({ company }: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavLink
      href={routeFor(routes.PAYMENTS_ALL.path, {
        company: company.id,
      })}
      activePaths={[routes.PAYABLES_ALL.path, routes.PAYMENTS_ALL.path]}
      iconName="shopping-bag"
    >
      {t('navigation.allExpenses')}
    </NavLink>
  );
};
