import { Icon, Tooltip, colors } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

export const OCRIcon = () => {
  const { t } = useTranslation('global');

  return (
    <Tooltip
      content={t('submitMyInvoice.fields.ocrResultTooltip')}
      triggerAsChild
    >
      <Icon
        className="ml-8"
        color={colors.contentSuccessDefault}
        name="robot"
        size="s"
      />
    </Tooltip>
  );
};
