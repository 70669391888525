import { useContext } from 'react';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';
import { withoutOptionalFields } from 'src/core/common/components/FilterBuilder';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { useActivePayableFilter } from 'src/core/modules/payable/components';

import { type PayablesBucketsQueryRawResult } from '../../../bookkeep/apis/prepare-payable';
import { makeGetPayablesBucketsStatsQuery } from '../../../bookkeep/apis/prepare-payable/usePayablesBucketsStatsQuery/query';
import { PreparePayablesFiltersContext } from '../../../bookkeep/prepare-payables/contexts';
import {
  toAPIPayableFilters,
  toAPIPayableFiltersV2,
} from '../../../bookkeep/prepare-payables/models';
// import { useExportPayablesCount } from '../../payable-export/apis';

type PayableStats = {
  toPrepare: number;
  toExport: number;
};

export const useOrganisationEntityPayablesQuery = (
  companyId: string,
  options: {
    isEnabled: boolean;
  } = {
    isEnabled: true,
  },
): QueryState<PayableStats> => {
  const { state: payablesToPrepageFilters } = useContext(
    PreparePayablesFiltersContext,
  );
  const filtersV2 = useActivePayableFilter()?.filter;
  const filtersV2Enabled = useFeature(FEATURES.TMP_PAYABLE_FILTERS);

  const prepageFilters = {
    companyId,
    filters: toAPIPayableFilters({
      filters: payablesToPrepageFilters,
      withBookkeepingStartDate: true,
    }),
    filtersV2: filtersV2Enabled
      ? toAPIPayableFiltersV2({
          filters: filtersV2 ? withoutOptionalFields(filtersV2) : undefined,
          withBookkeepingStartDate: true,
        })
      : undefined,
  };

  return useQueryStates({
    states: {
      payablesToPrepare: useQuery<PayablesBucketsQueryRawResult, PayableStats>({
        key: ['organisation-reporting', companyId, 'payables-to-prepare'],
        request: {
          type: 'graphQL',
          target: 'v2',
          query: makeGetPayablesBucketsStatsQuery(prepageFilters),
          variables: prepageFilters,
        },
        isEnabled: options.isEnabled,
      }),
      // payablesToExport: useExportPayablesCount([undefined, undefined]),
    },
    reshapeData: ({ payablesToPrepare /** payablesToExport */ }) => ({
      toPrepare: payablesToPrepare.company.payablesFiltered?.totalCount ?? 0,
      // toExport: payablesToExport.payablesCounts.unexportedPayablesCount,
      toExport: 0,
    }),
  });
};
