import React from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { CustomExportsContainer } from './components/CustomExports';
import { type CustomField } from './customField';
import { useIntegrationStatusQuery } from '../../hooks';
import { hasIntegrationFileBasedExport } from '../../integration/status';
import InvoicesPattern from '../export-legacy/components/InvoicesPattern';

type Props = {
  customFields: CustomField[] | null;
};

export const Exports = ({ customFields }: Props) => {
  const integrationStatus = useIntegrationStatusQuery();
  const hasCustomExportsFeature = useFeature(FEATURES.CUSTOM_EXPORTS);
  const hasCustomExports =
    hasCustomExportsFeature &&
    integrationStatus.status === 'success' &&
    hasIntegrationFileBasedExport(integrationStatus.data.integration);

  return (
    <div>
      <InvoicesPattern />
      {hasCustomExports && (
        <CustomExportsContainer customFields={customFields} />
      )}
    </div>
  );
};
