import { colors } from '@dev-spendesk/grapes';
import { type MonetaryValue, toNumber } from 'ezmoney';
import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  BudgetGaugeBreakdownKey,
  type BudgetGaugeConfiguration,
  type ClickableGaugeBreakdownKey,
} from './budget-gauge';
import {
  type BudgetBreakdown,
  getBudgetLimit,
  getCommittedAmount,
  getUsedAmount,
  hasExceededAmounts,
  isBudgetBreakdownEmpty,
} from '../../models/breakdown';
import { EnrichedGauge } from '../EnrichedGauge/EnrichedGauge';
import {
  type GaugeMilestone,
  type GaugeSegment,
} from '../EnrichedGauge/gauge-segment';

type Props = {
  budgetBreakdown: BudgetBreakdown;
  gaugeConfiguration?: BudgetGaugeConfiguration;
  onGaugeSegmentClick?: (breakdownKey: ClickableGaugeBreakdownKey) => void;
  hoveredBreakdownKey?: BudgetGaugeBreakdownKey;
};

export const BudgetGauge = ({
  budgetBreakdown,
  gaugeConfiguration = {
    tooltip: { mode: 'normal' },
    highlightSegmentOnHover: false,
    enforceWarningState: false,
    hasAnimation: false,
  },
  onGaugeSegmentClick,
  hoveredBreakdownKey = undefined,
}: Props) => {
  const { t } = useTranslation('global');

  const [localHoveredBreakdownKey, setLocalHoveredBreakdownKey] = useState<
    BudgetGaugeBreakdownKey | undefined
  >();
  useEffect(
    () => setLocalHoveredBreakdownKey(hoveredBreakdownKey),
    [hoveredBreakdownKey],
  );

  const hasExceededBudget = useMemo(
    () => hasExceededAmounts(budgetBreakdown),
    [budgetBreakdown],
  );

  const getGaugeSegment = (
    key: BudgetGaugeBreakdownKey,
    value: MonetaryValue,
  ) => {
    const { highlightSegmentOnHover, tooltip, enforceWarningState } =
      gaugeConfiguration;

    const segmentState =
      hasExceededBudget || enforceWarningState ? 'warning' : 'normal';

    const isNormalShade =
      !highlightSegmentOnHover ||
      [undefined, key].includes(localHoveredBreakdownKey);
    const segmentShade = isNormalShade ? 'normal' : 'shadowed';

    const formattedValue = formatMonetaryValue(value);
    const tooltipContent = tooltip && getTooltipContent(key, formattedValue);

    const isClickable = onGaugeSegmentClick && key !== 'available';

    return {
      key,
      fillColor: getSegmentFillColor(key, segmentState, segmentShade),
      value: toNumber(value),
      tooltipContent,
      onClick: isClickable ? () => onGaugeSegmentClick(key) : undefined,
    } satisfies GaugeSegment<BudgetGaugeBreakdownKey>;
  };

  const getTooltipContent = (key: BudgetGaugeBreakdownKey, value: string) => {
    const { tooltip } = gaugeConfiguration;
    const tooltipMode = tooltip?.mode ?? 'normal';

    const { usedExceeded, committedExceeded } = budgetBreakdown;
    const hasUsedExceeded = toNumber(usedExceeded) > 0;
    const hasCommittedExceeded = toNumber(committedExceeded) > 0;

    const tooltipText = {
      [BudgetGaugeBreakdownKey.AVAILABLE]: {
        compact: t('budget.gauge.legend.available'),
        normal: t('budget.gauge.tooltips.available'),
      },
      [BudgetGaugeBreakdownKey.COMMITTED]: {
        compact: hasCommittedExceeded
          ? t('budget.gauge.legend.committedExceeded')
          : t('budget.gauge.legend.committed'),
        normal: hasCommittedExceeded
          ? t('budget.gauge.tooltips.committedExceeded')
          : t('budget.gauge.tooltips.committed'),
      },
      [BudgetGaugeBreakdownKey.USED]: {
        compact: hasUsedExceeded
          ? t('budget.gauge.legend.usedExceeded')
          : t('budget.gauge.legend.used'),
        normal: hasUsedExceeded
          ? t('budget.gauge.tooltips.usedExceeded')
          : t('budget.gauge.tooltips.used'),
      },
    }[key][tooltipMode];

    return tooltipMode === 'compact'
      ? getTooltipBody([tooltipText, value].join(': '))
      : getTooltipBody(tooltipText, value);
  };

  const displayedBreakdownItems = useMemo(() => {
    if (isBudgetBreakdownEmpty(budgetBreakdown)) {
      return getArbitrarySegmentForEmptyBreakdown();
    }

    const { available } = budgetBreakdown;

    const usedValue = getUsedAmount(budgetBreakdown);
    const committedValue = getCommittedAmount(budgetBreakdown);
    return [
      getGaugeSegment(BudgetGaugeBreakdownKey.USED, usedValue),
      getGaugeSegment(BudgetGaugeBreakdownKey.COMMITTED, committedValue),
      getGaugeSegment(BudgetGaugeBreakdownKey.AVAILABLE, available),
    ].filter(
      ({ value }) => value > 0,
    ) satisfies GaugeSegment<BudgetGaugeBreakdownKey>[];
  }, [budgetBreakdown, localHoveredBreakdownKey]);

  const budgetMilestones = useMemo(() => {
    if (!hasExceededBudget) {
      return undefined;
    }

    const tooltipContent =
      gaugeConfiguration.tooltip &&
      getTooltipBody(t('budget.gauge.tooltips.budgetLimit'));

    const budgetLimit = getBudgetLimit(budgetBreakdown);
    return [
      {
        key: 'budget-limit',
        fillColor: colors.contentPrimary,
        value: toNumber(budgetLimit),
        tooltipContent,
      } satisfies GaugeMilestone,
    ];
  }, [budgetBreakdown]);

  return (
    <EnrichedGauge
      segments={displayedBreakdownItems}
      milestones={budgetMilestones}
      onHoveredSegmentChange={(key) => setLocalHoveredBreakdownKey(key)}
      hasAnimation={gaugeConfiguration.hasAnimation}
    />
  );
};

const getSegmentFillColor = (
  key: BudgetGaugeBreakdownKey,
  state: 'normal' | 'warning' = 'normal',
  shade: 'normal' | 'shadowed' = 'normal',
) => {
  const map: Record<
    BudgetGaugeBreakdownKey,
    { [state: string]: { [shade: string]: string } }
  > = {
    [BudgetGaugeBreakdownKey.AVAILABLE]: {
      normal: {
        normal: colors.backgroundTertiaryDefault,
        shadowed: colors.backgroundTertiaryDefault,
      },
      warning: {
        normal: colors.backgroundTertiaryDefault,
        shadowed: colors.backgroundTertiaryDefault,
      },
    },
    [BudgetGaugeBreakdownKey.COMMITTED]: {
      normal: {
        normal: buildGradient(
          colors.contentBrandDefault,
          colors.backgroundSecondaryBrandDefault,
        ),
        shadowed: buildGradient(
          colors.backgroundSecondaryBrandDefault,
          colors.backgroundSecondaryBrandDefault,
        ),
      },
      warning: {
        normal: buildGradient(
          colors.contentWarningDefault,
          colors.backgroundSecondaryWarningDefault,
        ),
        shadowed: buildGradient(
          colors.backgroundSecondaryWarningDefault,
          colors.backgroundSecondaryWarningDefault,
        ),
      },
    },
    [BudgetGaugeBreakdownKey.USED]: {
      normal: {
        normal: colors.contentBrandDefault,
        shadowed: colors.backgroundSecondaryBrandDefault,
      },
      warning: {
        normal: colors.contentWarningDefault,
        shadowed: colors.backgroundSecondaryWarningDefault,
      },
    },
  };

  return map[key][state][shade];
};

const getTooltipBody = (text: string, value?: string) => {
  return (
    <div className="flex flex-col content-stretch">
      <p>{text}</p>
      {value && <p>{value}</p>}
    </div>
  );
};

const buildGradient = (mainColor: string, stripedColor: string) =>
  `repeating-linear-gradient(-45deg, ${stripedColor} , ${stripedColor} 3px, ${mainColor}  3px, ${mainColor}  5px)`;

const getArbitrarySegmentForEmptyBreakdown = () => {
  const arbitraryValue = 100;

  return [
    {
      key: BudgetGaugeBreakdownKey.AVAILABLE,
      fillColor: getSegmentFillColor(
        BudgetGaugeBreakdownKey.AVAILABLE,
        'normal',
        'shadowed',
      ),
      value: arbitraryValue,
    } satisfies GaugeSegment<BudgetGaugeBreakdownKey>,
  ];
};
