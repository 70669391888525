import { AmountInput, DatePicker, FormField } from '@dev-spendesk/grapes';
import { useParams } from 'react-router-dom';

import { AutocompleteMultipleSearch } from 'common/components/AutocompleteSearch';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import type { CostCenter } from 'modules/budgets/models/costCenter';
import type { Team } from 'modules/budgets/models/team';
import { SubnavigationItem } from 'modules/requests/utils/navigation';
import FEATURES from 'src/core/constants/features';

import { RequestRequesterFilter } from './RequestRequesterFilter';
import { RequestSupplierFilter } from './RequestSupplierFilter';
import { type Filters } from './types';

type Props = {
  costCenters: CostCenter[];
  teams: Team[];
  filters: Filters;
  setFilters: (filters: Filters) => void;
};

export const RequestFiltersForm = ({
  costCenters,
  teams,
  filters,
  setFilters,
}: Props) => {
  const { t } = useTranslation('global');

  const currency = useCompanyCurrency();

  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );

  const isPurchaseOrderFeatureEnabled = useFeature(
    FEATURES.PURCHASE_ORDERS_ACTIVATED,
  );

  const { type } = useParams();

  return (
    <>
      <FormField className="mb-16" label={t('forms.amount.label')}>
        <AmountInput
          className="mb-8"
          fit="parent"
          placeholder={t('forms.numberRange.placeholder.min')}
          currency={{ key: currency, label: currency }}
          value={filters.minAmount ?? null}
          onChange={(_, newValue) => {
            if (Number.isNaN(newValue)) {
              const { minAmount, ...rest } = filters;
              setFilters(rest);
            } else {
              setFilters({ ...filters, minAmount: newValue });
            }
          }}
        />
        <AmountInput
          fit="parent"
          placeholder={t('forms.numberRange.placeholder.max')}
          currency={{ key: currency, label: currency }}
          value={filters.maxAmount ?? null}
          onChange={(_, newValue) => {
            if (Number.isNaN(newValue)) {
              const { maxAmount, ...rest } = filters;
              setFilters(rest);
            } else {
              setFilters({ ...filters, maxAmount: newValue });
            }
          }}
        />
      </FormField>
      {type !== SubnavigationItem.Mine && (
        <FormField className="mb-16" label={t('requests.filters.requester')}>
          <RequestRequesterFilter
            value={filters.requester}
            onSelect={(requester) => {
              if (!requester) {
                const { requester: requesterUnused, ...rest } = filters;
                setFilters(rest);
              } else {
                setFilters({ ...filters, requester });
              }
            }}
          />
        </FormField>
      )}
      <FormField className="mb-16" label={t('requests.filters.status')}>
        <AutocompleteMultipleSearch
          fit="parent"
          values={filters.statuses ?? []}
          options={[
            { key: 'pending', label: t('requests.filters.pending') },
            { key: 'approved', label: t('requests.filters.approved') },
            { key: 'refused', label: t('requests.filters.refused') },
            {
              key: 'rejected_by_controller',
              label: t('requests.filters.rejected'),
            },
            { key: 'expired', label: t('requests.filters.expired') },
          ]}
          translations={{
            selectAll: t('misc.selectAll'),
            selected: t('misc.nItemsSelected', {
              count: filters.statuses?.length,
            }),
          }}
          placeholder={t('requests.filters.status')}
          onSelect={(selectedStatuses) => {
            if (selectedStatuses.length === 0) {
              const { statuses, ...rest } = filters;
              setFilters(rest);
            } else {
              setFilters({ ...filters, statuses: selectedStatuses });
            }
          }}
        />
      </FormField>
      <FormField className="mb-16" label={t('requests.filters.type')}>
        <AutocompleteMultipleSearch
          fit="parent"
          values={filters.types ?? []}
          options={[
            { key: 'expense', label: t('requests.filters.expense') },
            {
              key: 'single_purchase',
              label: t('requests.filters.single_purchase'),
            },
            {
              key: 'subscription',
              label: t('requests.filters.subscription'),
            },
            {
              key: 'subscription_increase',
              label: t('requests.filters.subscription_increase'),
            },
            {
              key: 'mileage_allowance',
              label: t('requests.filters.mileageAllowance'),
            },
            {
              key: 'per_diem_allowance',
              label: t('requests.filters.per_diem'),
            },
            { key: 'card_load', label: t('requests.filters.card_load') },
            { key: 'invoice', label: t('requests.filters.invoice') },
            {
              key: 'credit_note',
              label: t('requests.filters.credit_note'),
            },
            ...(isPurchaseOrderFeatureEnabled
              ? [
                  {
                    key: 'purchase_order',
                    label: t('requests.filters.purchaseOrder'),
                  },
                ]
              : []),
          ]}
          translations={{
            selectAll: t('misc.selectAll'),
            selected: t('misc.nItemsSelected', {
              count: filters.types?.length,
            }),
          }}
          placeholder={t('requests.filters.type')}
          onSelect={(selectedTypes) => {
            if (selectedTypes.length === 0) {
              const { types, ...rest } = filters;
              setFilters(rest);
            } else {
              setFilters({ ...filters, types: selectedTypes });
            }
          }}
        />
      </FormField>
      <FormField className="mb-16" label={t('requests.filters.period')}>
        <DatePicker
          className="mb-8"
          placeholder={t('misc.from')}
          value={filters.fromPeriod}
          maxDate={filters.toPeriod}
          onChange={(date) => {
            if (!date) {
              const { fromPeriod, ...rest } = filters;
              setFilters(rest);
            } else {
              setFilters({ ...filters, fromPeriod: date });
            }
          }}
        />
        <DatePicker
          placeholder={t('misc.to')}
          value={filters.toPeriod}
          minDate={filters.fromPeriod}
          onChange={(date) => {
            if (!date) {
              const { toPeriod, ...rest } = filters;
              setFilters(rest);
            } else {
              setFilters({ ...filters, toPeriod: date });
            }
          }}
        />
      </FormField>
      <FormField className="mb-16" label={t('requests.filters.supplier')}>
        <RequestSupplierFilter
          value={filters.supplier}
          onSelect={(supplier) => {
            if (!supplier) {
              const { supplier: supplierUnused, ...rest } = filters;
              setFilters(rest);
            } else {
              setFilters({ ...filters, supplier });
            }
          }}
        />
      </FormField>
      {isCostCentersFeatureEnabled && (
        <FormField className="mb-16" label={t('requests.filters.costCenter')}>
          <AutocompleteMultipleSearch
            fit="parent"
            values={filters.costCenters ?? []}
            options={costCenters.map((costCenter) => ({
              key: costCenter.id,
              label: costCenter.name,
            }))}
            translations={{
              selectAll: t('misc.selectAll'),
              selected: t('misc.nItemsSelected', {
                count: filters.costCenters?.length,
              }),
            }}
            placeholder={t('requests.filters.costCenter')}
            onSelect={(selectedTypes) => {
              if (selectedTypes.length === 0) {
                const { costCenters: costCentersUnused, ...rest } = filters;
                setFilters(rest);
              } else {
                setFilters({ ...filters, costCenters: selectedTypes });
              }
            }}
          />
        </FormField>
      )}
      {teams.length > 0 && (
        <FormField className="mb-16" label={t('requests.filters.team')}>
          <AutocompleteMultipleSearch
            fit="parent"
            values={filters.teams ?? []}
            options={teams.map((team) => ({
              key: team.id,
              label: team.name,
            }))}
            translations={{
              selectAll: t('misc.selectAll'),
              selected: t('misc.nItemsSelected', {
                count: filters.teams?.length,
              }),
            }}
            placeholder={t('requests.filters.team')}
            onSelect={(selectedTypes) => {
              if (selectedTypes.length === 0) {
                const { teams: teamsUnused, ...rest } = filters;
                setFilters(rest);
              } else {
                setFilters({ ...filters, teams: selectedTypes });
              }
            }}
          />
        </FormField>
      )}
    </>
  );
};
