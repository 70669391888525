import {
  Button,
  Callout,
  DropdownItem,
  DropdownMenu,
  IconButton,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import {
  type ChangeEvent,
  type KeyboardEvent,
  useReducer,
  useEffect,
  useMemo,
} from 'react';
import { usePrevious } from 'react-use';

import { useModal } from 'src/core/common/hooks/useModal';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { currencySymbol, formatMoney } from 'src/core/utils/money';

import styles from './ApprovalThreshold.module.css';
import { ApproversList } from './components/ApproversList';
import reducer, {
  startEditing,
  stopEditing,
  type State,
  updateUpTo,
  startFadeOut,
  stopFadeOut,
  startBlink,
  stopBlink,
  setIsMultiStep,
} from './reducer';
import {
  createRight,
  isMultiStep,
  toMultipleStep,
  toSingleStep,
} from '../../models';
import {
  type Approver,
  type ApprovalRule,
  type ApprovalStep,
  type Member,
  type SelectedApprover,
} from '../../types';
import { AddApproverDropdown } from '../AddApproverDropdown';
import DeleteThresholdModal from '../DeleteThresholdModal';

type Props = {
  approvalRule: ApprovalRule & {
    index: number;
    isLastRule: boolean;
  };
  mode?: 'approvalByDimensionsPanel' | 'default';
  members: Member[];
  hasError: boolean;
  onUpdateRule(ruleIndex: number, rule: ApprovalRule): void;
  onDeleteRule(ruleId: string): void;
};

export const ApprovalThreshold = ({
  approvalRule,
  members,
  mode,
  hasError,
  onUpdateRule,
  onDeleteRule,
}: Props) => {
  const ruleApprovers: Approver[] = useMemo(
    () => getRuleApprovers(approvalRule.steps, members),
    [approvalRule.steps, members],
  );

  const initialState: State = {
    isEditing: false,
    upTo: approvalRule.upTo.value,
    originalUpToValue: approvalRule.upTo.value || Number.POSITIVE_INFINITY,
    fadeOut: false,
    blink: false,
    isMultiStep: isMultiStep(approvalRule.steps),
    isOpenDropDown: false,
  };

  const { t } = useTranslation('global');
  const previousApproval = usePrevious(approvalRule);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (
      !state.isEditing &&
      previousApproval &&
      previousApproval.upTo.value !== approvalRule.upTo.value &&
      previousApproval.upTo.value === state.upTo
    ) {
      dispatch(startFadeOut());
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dispatch(updateUpTo(approvalRule.upTo.value!));
    }
  }, [approvalRule]);

  useEffect(() => {
    if (state.fadeOut) {
      const timeoutId = setTimeout(() => dispatch(stopFadeOut()), 4000);
      return () => clearTimeout(timeoutId);
    }
  }, [state.fadeOut]);

  useEffect(() => {
    if (state.blink) {
      const timeoutId = setTimeout(() => dispatch(stopBlink()), 600);
      return () => clearTimeout(timeoutId);
    }
  }, [state.blink]);

  const [deleteModal, showDeleteModal, hideDeleteModal] = useModal(() => (
    <DeleteThresholdModal
      onConfirm={() => {
        onDeleteRule(approvalRule.id);
        hideDeleteModal();
      }}
      onCancel={() => hideDeleteModal()}
      approversCount={ruleApprovers.length}
    />
  ));

  const onUpdateSteps = (steps: ApprovalStep[]) => {
    onUpdateRule(approvalRule.index, {
      ...approvalRule,
      steps,
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!state.isEditing && approvalRule.upTo.value) {
      dispatch(startEditing(approvalRule.upTo.value));
    }
    const value = Math.abs(Number.parseInt(event.target.value));
    dispatch(updateUpTo(value));
  };

  const handleBlur = () => {
    dispatch(stopEditing());

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (Number.isNaN(state.upTo!)) {
      return dispatch(updateUpTo(state.originalUpToValue));
    }

    if (
      approvalRule.from.value !== null &&
      state.upTo !== null &&
      approvalRule.from.value >= state.upTo
    ) {
      dispatch(startBlink());
      return dispatch(updateUpTo(state.originalUpToValue));
    }

    const rule = { ...approvalRule };
    rule.upTo.value = state.upTo;

    onUpdateRule(approvalRule.index, rule);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleBlur();
    }
  };

  const handleSelectApprover = (selectedApprover: SelectedApprover) => {
    track(
      AnalyticEventName.APPROVALS_WORKFLOW_SETUP_ADD_APPROVER,
      selectedApprover,
    );
    let steps = approvalRule.steps;

    if (state.isMultiStep) {
      steps = [
        ...approvalRule.steps,
        {
          rights: [createRight(selectedApprover)],
        },
      ];
    } else {
      steps =
        approvalRule.steps.length > 0
          ? approvalRule.steps.map((step) => {
              return {
                ...step,
                rights: [...step.rights, createRight(selectedApprover)],
              };
            })
          : [
              {
                rights: [createRight(selectedApprover)],
              },
            ];
    }
    onUpdateSteps(steps);
  };

  const handleDeleteApprover = (index: number) => {
    let steps = approvalRule.steps;

    if (!state.isMultiStep) {
      steps = approvalRule.steps.map((step) => {
        return {
          ...step,
          rights: step.rights.filter((_, rightIndex) => rightIndex !== index),
        };
      });
    } else {
      steps = approvalRule.steps.filter((_, stepIndex) => stepIndex !== index);
    }
    onUpdateSteps(steps);
  };

  const handleChangeApprover = (
    newApprover: SelectedApprover,
    index: number,
  ) => {
    let steps = approvalRule.steps;
    if (!state.isMultiStep) {
      steps = approvalRule.steps.map((step) => {
        return {
          ...step,
          rights: step.rights.map((right, rightIndex) => {
            if (rightIndex === index) {
              return createRight(newApprover);
            }
            return right;
          }),
        };
      });
    } else {
      steps = approvalRule.steps.map((step, stepIndex) => {
        if (stepIndex === index) {
          return {
            ...step,
            rights: [createRight(newApprover)],
          };
        }
        return step;
      });
    }

    onUpdateSteps(steps);
  };

  const handleDeleteThreshold = () => {
    if (ruleApprovers.length > 0) {
      showDeleteModal();
    } else {
      onDeleteRule(approvalRule.id);
    }
  };

  const renderDeleteThreshold = () => (
    <div className={styles.delete}>
      <IconButton
        iconName="trash"
        variant="tertiaryNeutral"
        onClick={handleDeleteThreshold}
        data-testid="ApprovalThreshold__delete-threshold"
        aria-label={t('misc.delete')}
      />
    </div>
  );

  const renderRuleHeader = () => {
    const { from, index, isLastRule } = approvalRule;
    const formattedAmount = formatMoney(from.value || 0, from.currency);

    if (isLastRule) {
      return (
        <div className={cx(styles.header, 'title-m')}>
          <span className="flex h-32 items-center">
            {index === 0
              ? t('teams.approvalFlows.canApproveAllRequests')
              : `${t('teams.approvalFlows.above')} ${formattedAmount}`}
          </span>
          {index !== 0 && renderDeleteThreshold()}
        </div>
      );
    }

    return (
      <div className={cx(styles.header, 'body-m')}>
        <div className="relative">
          <span>
            {from.value === 0
              ? t('teams.approvalFlows.upTo')
              : t('teams.approvalFlows.fromTo', { value: formattedAmount })}
          </span>
          <input
            className={cx(styles.upToInput, {
              [styles.fadeOut]: state.fadeOut,
              [styles.blink]: state.blink,
            })}
            type="number"
            min="0"
            step="1"
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value={state.isEditing ? state.upTo! : approvalRule.upTo.value!}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
          <span className={styles.upToCurrency}>
            {currencySymbol(approvalRule.upTo.currency)}
          </span>
        </div>
        {renderDeleteThreshold()}
      </div>
    );
  };

  const followingModeMenuOptions = [
    {
      key: 'allFollowingUsers',
      label: t('teams.approvalFlows.allFollowingUsers'),
      action: () => {
        dispatch(setIsMultiStep(true));
        onUpdateSteps(toMultipleStep(approvalRule).steps);
      },
    },
    {
      key: 'anyFollowingUsers',
      label: t('teams.approvalFlows.anyFollowingUsers'),
      action: () => {
        dispatch(setIsMultiStep(false));
        onUpdateSteps(toSingleStep(approvalRule).steps);
      },
    },
  ];

  const shouldDisplayAllFollowingHint = useMemo(() => {
    const hasSuperApprover = ruleApprovers.some(
      (a) => a.approverType === 'user' && a.isAccountOwner,
    );
    return state.isMultiStep && hasSuperApprover;
  }, [state.isMultiStep, ruleApprovers]);

  return (
    <div>
      {deleteModal}
      <div
        className={cx(
          'border-default',
          styles.approvalThreshold,
          {
            [styles.hasError]: hasError,
          },
          'box p-16',
        )}
      >
        <div>{renderRuleHeader()}</div>
        <div className="relative">
          <div className="mb-8 flex items-center gap-8 p-0">
            {mode === 'default' && (
              <span className="text-primary body-m">
                {t('teams.approvalFlows.approvedBy')}&nbsp;
              </span>
            )}
            <DropdownMenu
              options={followingModeMenuOptions}
              onSelect={(option) => option.action()}
              renderOption={(option) => {
                return (
                  <DropdownItem
                    label={option.label}
                    data-testid={`ApprovalThreshold_${option.key}`}
                  />
                );
              }}
              renderButton={(getToggleButtonProps) => (
                <Button
                  {...getToggleButtonProps()}
                  text={
                    state.isMultiStep
                      ? t('teams.approvalFlows.allFollowingUsers')
                      : t('teams.approvalFlows.anyFollowingUsers')
                  }
                  variant="secondaryNeutral"
                  data-testid="ApprovalThreshold__body__message__dropdown"
                />
              )}
            />
          </div>
          <ApproversList
            approvers={ruleApprovers}
            isAllFollowingMode={state.isMultiStep}
            onDeleteClicked={handleDeleteApprover}
            onUpdateRule={handleChangeApprover}
          />
          {hasError && (
            <Callout
              variant="alert"
              title={t('teams.approvalFlows.requiredOneApprover')}
            />
          )}

          <AddApproverDropdown
            onSelect={(selectedApprover) => {
              handleSelectApprover(selectedApprover);
            }}
          />
          {shouldDisplayAllFollowingHint && (
            <Callout
              variant="info"
              title={t('teams.approvalFlows.hintApproversAfterAccountOwner')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const getRuleApprovers = (
  steps: ApprovalStep[],
  members: Member[],
): Approver[] => {
  const approvers: Approver[] = [];
  steps.forEach((step) => {
    step.rights.forEach((right) => {
      const member = members.find((m) => {
        if (
          right.approverType === 'reportingManager' ||
          right.approverType === 'costCenterOwner'
        ) {
          return true;
        }
        if (right.approverType === 'user') {
          return m.id === right.approverId;
        }
        return false;
      });
      if (member) {
        approvers.push({
          ...member,
          isAccountOwner: false,
          approverType: right.approverType,
        });
      }
    });
  });
  return approvers;
};
