import { useHistory } from 'react-router-dom';

import { routeBuilder } from 'common/hooks/useGoTo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useExpensesClaimsRouter } from 'modules/reimbursements/hooks/useExpensesClaimsRouter';

type Params = {
  paymentId?: string;
  type: 'invoice' | 'expense';
};

export const useGoToPaymentHistory = () => {
  const expenseRouter = useExpensesClaimsRouter();
  const getRoute = usePaymentHistoryRoute();
  const history = useHistory();

  return (params: Params) => {
    if (params.type === 'expense') {
      expenseRouter.goToHistory(params.paymentId);
    } else {
      const route = getRoute(params);
      history.push(route);
    }
  };
};

const usePaymentHistoryRoute = () => {
  const companyId = useCompanyId();
  return (params: Params): string => getPaymentHistoryRoute(companyId, params);
};

const getPaymentHistoryRoute = (
  companyId: string,
  { paymentId }: Params,
): string => {
  if (paymentId) {
    return routeBuilder('INVOICES_HISTORY', {
      company: companyId,
      paymentId,
    });
  }

  return routeBuilder('INVOICES_HISTORY', {
    company: companyId,
  });
};
