import { Button, Link, Modal, Tooltip } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import CONFIG from 'src/core/config';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const HELP_CENTER_COOKIES_ARTICLE_LINK =
  'https://helpcenter.spendesk.com/articles/7972904-what-to-do-if-the-contact-us-button-doesn-t-work';

export const FixIntercomCookiesModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const openCookieSettings = () => {
    onClose();
    window.consentManager.openConsentManager();
  };

  const copySupportEmail = () => {
    navigator.clipboard.writeText(CONFIG.supportEmail);
    setIsTooltipVisible(true);
    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 3000);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('helpCenter.enableIntercomCookies.title')}
      iconName="lock-close"
      iconVariant="info"
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={onClose}
        />,
        <Button
          key="open-cookies"
          variant="primaryBrand"
          text={t('helpCenter.enableIntercomCookies.ctaLabel')}
          onClick={openCookieSettings}
        />,
      ]}
    >
      <div className="flex flex-col gap-16">
        <p>{t('helpCenter.enableIntercomCookies.message')}</p>
        <Link href={HELP_CENTER_COOKIES_ARTICLE_LINK} isExternal>
          {t('helpCenter.enableIntercomCookies.readMore')}
        </Link>
        <div className="m-auto flex items-baseline">
          {t('helpCenter.enableIntercomCookies.contactUs')}
          <Tooltip
            content={t('helpCenter.emailCopiedToClipboard')}
            isOpen={isTooltipVisible}
          >
            <Button
              variant="tertiaryNeutral"
              onClick={copySupportEmail}
              iconName="square-stack"
              iconPosition="right"
              text={CONFIG.supportEmail}
            />
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};
