import { Button, Modal, RadioBox, RadioGroup, Tag } from '@dev-spendesk/grapes';
import React, { type ReactNode, useState, useId } from 'react';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useIsCurrencyBranchAllowed } from 'modules/app/hooks/useIsCurrencyBranchAllowed';

import { ACCOUNT_TYPES, type AccountTypes } from '../../models/accountTypes';

export type InitialSelectableAccountType = Exclude<
  AccountTypes,
  'EXPENSE_ENTITY'
>;

type AccountChooserModalProps = {
  onSelect: (accountType: InitialSelectableAccountType) => void;
  onCancel: () => void;
};

export const AccountChooserModal = ({
  onSelect,
  onCancel,
}: AccountChooserModalProps) => {
  const company = useCompany();
  const { t } = useTranslation('global');
  const allowCurrencyBranch = useIsCurrencyBranchAllowed();
  const titleId = useId();
  const formId = useId();

  const [newAccountType, setNewAccountType] = useState<
    InitialSelectableAccountType | undefined
  >();

  let currencyBranchSubtitle: ReactNode = t('branch.newCurrencySubtitle');

  if (company.banking_provider === 'sfs') {
    currencyBranchSubtitle = t('branch.newSFSCurrencySubtitle');
  }

  if (!allowCurrencyBranch.isAllowed) {
    if (allowCurrencyBranch.reasonKey === 'branch.comingSoon') {
      currencyBranchSubtitle = (
        <>
          <p>{currencyBranchSubtitle}</p>
          <Tag className="mt-8" variant="info">
            {t(allowCurrencyBranch.reasonKey)}
          </Tag>
        </>
      );
    } else {
      currencyBranchSubtitle = t(allowCurrencyBranch.reasonKey);
    }
  }

  return (
    <ErrorBoundary context={{ scope: 'modal-new-account', team: 'kyc' }}>
      <Modal
        isOpen
        title={t('branch.accountChooserModalTitle')}
        subtitle={t('branch.countryAndCurrencyStep.subtitle')}
        iconName="plus"
        iconVariant="purple"
        actions={[
          <>
            <Button
              key="cancel"
              text={t('misc.cancel')}
              variant="secondaryNeutral"
              onClick={onCancel}
            />
            <Button
              key="continue"
              text={t('misc.continue')}
              variant="primaryBrand"
              iconName="chevron-right"
              iconPosition="right"
              type="submit"
              form={formId}
              isDisabled={!newAccountType}
            />
          </>,
        ]}
        onClose={onCancel}
      >
        <form
          id={formId}
          aria-labelledby={titleId}
          className="mt-24"
          onSubmit={(event) => {
            event.preventDefault();
            if (newAccountType) {
              onSelect(newAccountType);
            }
          }}
        >
          <RadioGroup
            name="accountType"
            direction="column"
            onChange={(event) =>
              setNewAccountType(
                event.target.value as InitialSelectableAccountType,
              )
            }
            value={newAccountType as string}
            className="flex flex-col content-stretch gap-16 text-left"
          >
            <RadioBox
              iconName="document-plus"
              label={t('branch.newCompanyTitle')}
              description={t('branch.newCompanySubtitle')}
              value={ACCOUNT_TYPES.ENTITY}
            />
            <RadioBox
              iconName="document-duplicate"
              label={t('branch.newCurrencyTitle')}
              description={currencyBranchSubtitle}
              value={ACCOUNT_TYPES.CURRENCY}
              isDisabled={!allowCurrencyBranch.isAllowed}
            />
          </RadioGroup>
        </form>
      </Modal>
    </ErrorBoundary>
  );
};
