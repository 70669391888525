import { type Filter } from '@spendesk/bookkeeping-core-types';
import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type FiltersState } from 'modules/bookkeep/payables/containers/PayablesFiltersContainer/hooks/useFiltersContext';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import {
  withoutOptionalFields,
  type FilterWithOptionalField,
} from 'src/core/common/components/FilterBuilder';

import { type RawResult, query } from './query';
import { toCoreTypeFilter } from '../payable-filter/payableFilterTransformer';
import { getPayableCountQueryKey } from '../query-key-registry';
import { reshapeFilters } from '../useGetPayableList/reshaper';

export const useInvalidateGetPayableCountQueryCache = () => {
  const queryClient = useQueryClient();

  return async (companyId: string, filters?: FiltersState): Promise<void> => {
    await queryClient.invalidateQueries(
      getPayableCountQueryKey(companyId, filters),
    );
  };
};

/**
 * @public
 */
export type PayableCountResponse = { totalCount: number };

export type PayableCountRawResponse = RawResult;

export type PayableCountVariables = {
  companyId: string;
  filtersV2: Filter | undefined;
  textualSearch: string | undefined;
} & FiltersState;

export const useGetPayableCount = ({
  filters,
  filtersV2: rawFiltersV2,
}: {
  filters: FiltersState;
  filtersV2: FilterWithOptionalField | undefined;
}): QueryState<PayableCountResponse> => {
  const companyId = useCompanyId();

  const reshapedFilters = reshapeFilters(filters);

  const filtersV2 = rawFiltersV2
    ? toCoreTypeFilter(withoutOptionalFields(rawFiltersV2))
    : undefined;

  const variables: PayableCountVariables = {
    companyId,
    ...reshapedFilters,
    filtersV2,
    textualSearch: filters.search,
  };

  return useQuery<PayableCountResponse, RawResult>({
    key: getPayableCountQueryKey(companyId, reshapedFilters, filtersV2),
    request: {
      type: 'graphQL',
      target: 'v2',
      query: query({ filtersV2 }),
      variables,
    },
    reshapeData: (data) => {
      return {
        totalCount: data.company.payables.totalCount,
      };
    },
  });
};
