import {
  Button,
  Callout,
  DatePicker,
  DATE_FORMAT,
  Input,
  Label,
  Modal,
  OptionGroup,
  RadioField,
  RadioGroup,
} from '@dev-spendesk/grapes';
import { add, endOfYear, startOfYear, endOfMonth } from 'date-fns';
import { type FormikProps } from 'formik';
import React, { type ChangeEvent, useMemo, useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type BudgetaryExerciseInput } from '../../containers/CreateBudgetaryExerciseModalContainer/budgetaryExerciseInput';

type Props = {
  onHide: () => void;
};

export const CreateBudgetaryExerciseModal = ({
  onHide,
  // formikProps
  values,
  errors,
  setFieldValue,
  handleSubmit,
  validateForm,
}: Props & FormikProps<BudgetaryExerciseInput>) => {
  const { localeFormat, t } = useTranslation('global');
  const [dateSettingType, setDateSettingType] = useState<'fullYear' | 'custom'>(
    'fullYear',
  );

  const handleClick = async () => {
    const formErrors = await validateForm(values);
    if (Object.keys(formErrors).length === 0) {
      // no validation errors
      handleSubmit();
    }
  };

  const handleDateSettingChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'fullYear') {
      setDateSettingType('fullYear');
      setFieldValue(
        'startDate',
        add(startOfYear(new Date()), { hours: 12, minutes: 0 }),
      );
      setFieldValue(
        'endDate',
        add(endOfYear(new Date()), { hours: 12, minutes: 0 }),
      );
    } else {
      const shouldRevalidateOnSwitch = !values.startDate || !values.endDate;
      setFieldValue('startDate', undefined, shouldRevalidateOnSwitch);
      setFieldValue('endDate', undefined, shouldRevalidateOnSwitch);
      setDateSettingType('custom');
    }
  };

  const maxEndDate = useMemo(() => {
    return (
      values.startDate && endOfMonth(add(values.startDate, { months: 11 }))
    );
  }, [values.startDate]);

  return (
    <Modal
      isOpen
      title={t('budget.createBEModal.title')}
      actions={[
        <Button
          key="1"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={onHide}
        />,
        <Button
          key="2"
          variant="primaryBrand"
          text={t('budget.createBEModal.createButton')}
          onClick={() => {
            handleClick();
          }}
        />,
      ]}
      iconName="shopping-bag"
      iconVariant="info"
    >
      <form className="flex flex-col content-stretch gap-24">
        <fieldset className="flex flex-col content-stretch gap-8 border-none text-left">
          <Label label={t('budget.createBEModal.nameLabel')} />
          <Input
            fit="parent"
            onChange={(event) => {
              setFieldValue('name', event.target.value);
            }}
            value={values.name}
            placeholder={t('budget.createBEModal.namePlaceholder')}
          />
          {errors.name && <Callout title={errors.name} variant="alert" />}
        </fieldset>
        <fieldset className="flex flex-col content-stretch gap-8 border-none text-left">
          <Label label={t('budget.createBEModal.dateLabel')} />
          <RadioGroup
            name="date"
            onChange={handleDateSettingChange}
            value={dateSettingType}
          >
            <RadioField
              value="fullYear"
              label={t('budget.createBEModal.dateFullYear')}
            />
            <RadioField
              value="custom"
              label={t('budget.createBEModal.dateCustom')}
            />
          </RadioGroup>
          {dateSettingType === 'fullYear' ? (
            <div className="flex flex-col gap-8">
              <div className="rounded bg-secondary-default px-16 py-8 text-primary body-m">
                {t('budget.createBEModal.fullYearFromTo', {
                  from:
                    values.startDate &&
                    localeFormat(values.startDate, DATE_FORMAT.SHORT),
                  to:
                    values.endDate &&
                    localeFormat(values.endDate, DATE_FORMAT.SHORT),
                })}
              </div>
              {errors.startDate && (
                <Callout title={errors.startDate} variant="alert" />
              )}
            </div>
          ) : (
            <div className="grid grid-flow-col gap-16">
              <div className="flex flex-col gap-8">
                <DatePicker
                  className="w-full"
                  placeholder={t('budget.createBEModal.datePlaceholderStart')}
                  onChange={(value) => setFieldValue('startDate', value)}
                  maxDate={values.endDate}
                  value={values.startDate}
                />
                {errors.startDate && (
                  <Callout title={errors.startDate} variant="alert" />
                )}
              </div>
              <div className="flex flex-col gap-8">
                <DatePicker
                  className="w-full"
                  placeholder={t('budget.createBEModal.datePlaceholderEnd')}
                  onChange={(value) => setFieldValue('endDate', value)}
                  minDate={values.startDate}
                  maxDate={maxEndDate}
                  value={values.endDate}
                />
                {errors.endDate && (
                  <Callout title={errors.endDate} variant="alert" />
                )}
              </div>
            </div>
          )}
        </fieldset>
        <fieldset className="flex flex-col content-stretch gap-8 border-none text-left">
          <Label label={t('budget.createBEModal.periodicityLabel')} />
          <OptionGroup
            name="periodicityOptionGroup"
            options={[
              { value: 'monthly', label: t('budget.createBEModal.monthly') },
              { value: 'yearly', label: t('budget.createBEModal.global') },
            ]}
            value={values.periodicity ?? null}
            onChange={(event) =>
              setFieldValue('periodicity', event.target.value)
            }
          />
          {errors.periodicity && !values.periodicity && (
            <Callout title={errors.periodicity} variant="alert" />
          )}
          {values.periodicity && errors.periodicity && (
            <Callout title={errors.periodicity} variant="info" />
          )}
        </fieldset>
      </form>
    </Modal>
  );
};
