import { Button, FormField, Input } from '@dev-spendesk/grapes';
import { type CleaveOptions } from 'cleave.js/options';
import { type FormikProps } from 'formik';
import React from 'react';

import { CountryAutocomplete } from 'src/core/common/components/CountryAutocomplete/CountryAutocomplete';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { IBANCountries } from 'src/core/config/country';

import { type BankInformationFormValues } from './form';

import './BankInformationForm.css';

type Props = FormikProps<BankInformationFormValues>;

const IBANCountriesMasks = Object.fromEntries(
  Object.entries(IBANCountries).map(([key, { blocks }]) => [
    key,
    {
      blocks,
    },
  ]),
);

const unmaskValue = (value: string, mask?: CleaveOptions) => {
  let delimiter = mask?.delimiter || '\\s';
  if (delimiter === '.') {
    delimiter = '\\.';
  }

  return value.replaceAll(new RegExp(delimiter, 'gi'), '');
};

const MASKS: { [key: string]: CleaveOptions } = {
  BIC_SWIFT: {
    blocks: [11],
  },
  CUC: {
    blocks: [10],
  },
};

export const BankInformationForm = ({
  // Formik props
  dirty,
  errors,
  values,
  setFieldValue,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    mask?: CleaveOptions,
  ) => {
    // If mask has a delimiter, unmask value first
    const value = unmaskValue(event.target.value, mask);
    setFieldValue(fieldName, value);
  };

  return (
    <form onSubmit={handleSubmit} className="box mt-24">
      <div className="flex gap-16">
        <FormField
          className="BankInformationForm__field-side"
          label={t('generalSettings.bankInformation.bankCountryLabel')}
          alertMessage={errors.bankCountry ? t(errors.bankCountry) : undefined}
        >
          <CountryAutocomplete
            className="BankInformationForm__country-field"
            name="bankCountry"
            fit="parent"
            countryCode={values.bankCountry}
            placeholder={t(
              'generalSettings.bankInformation.bankCountryPlaceholder',
            )}
            onSelect={(selectedKey: string | undefined) => {
              if (selectedKey !== values.bankCountry) {
                setFieldValue('bankCountry', selectedKey || '');
                setFieldValue('iban', '');
                setFieldValue('bic', '');
                setFieldValue('cuc', '');
              }
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              if (e.target.value === '') {
                setFieldValue('bankCountry', '');
                setFieldValue('iban', '');
                setFieldValue('bic', '');
                setFieldValue('cuc', '');
              }
            }}
          />
        </FormField>

        <FormField
          className="BankInformationForm__field-middle"
          label={t('generalSettings.bankInformation.ibanLabel')}
          alertMessage={errors.iban ? t(errors.iban) : undefined}
        >
          <Input
            name="iban"
            value={values.iban}
            placeholder={t('generalSettings.bankInformation.ibanPlaceholder')}
            maskOptions={
              IBANCountriesMasks[values.bankCountry]
                ? { ...IBANCountriesMasks[values.bankCountry] }
                : undefined
            }
            onChange={(e) =>
              handleChange(e, 'iban', IBANCountriesMasks[values.bankCountry])
            }
          />
        </FormField>

        <FormField
          className="BankInformationForm__field-side"
          label={t('generalSettings.bankInformation.bicLabel')}
          alertMessage={errors.bic ? t(errors.bic) : undefined}
        >
          <Input
            name="bic"
            value={values.bic}
            placeholder={t('generalSettings.bankInformation.bicPlaceholder')}
            maskOptions={MASKS.BIC_SWIFT}
            onChange={(e) => handleChange(e, 'bic', MASKS.BIC_SWIFT)}
          />
        </FormField>
      </div>
      {values.bankCountry === 'IT' && (
        <FormField
          className="mt-16"
          label={t('generalSettings.bankInformation.cucLabel')}
          alertMessage={errors.cuc ? t(errors.cuc) : undefined}
        >
          <Input
            name="cuc"
            value={values.cuc}
            placeholder={t('generalSettings.bankInformation.cucPlaceholder')}
            maskOptions={MASKS.CUC}
            onChange={(e) => handleChange(e, 'cuc', MASKS.CUC)}
          />
        </FormField>
      )}
      <Button
        className="mt-24"
        variant="primaryBrand"
        type="submit"
        isDisabled={!dirty}
        text={t('generalSettings.bankInformation.submitButton')}
      />
    </form>
  );
};
