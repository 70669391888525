import { Skeleton } from '@dev-spendesk/grapes';
import React from 'react';

export const WelcomePanelContentSkeleton = () => {
  return (
    <div className="flex flex-col justify-stretch gap-24">
      <Skeleton height="26px" width="300px" />
      <div className="flex gap-16">
        <Skeleton height="42px" width="296px" />
        <Skeleton height="42px" width="296px" />
        <Skeleton height="42px" width="296px" />
      </div>
    </div>
  );
};
