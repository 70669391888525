import { create, add, type MonetaryValue } from 'ezmoney';

import { type InvoiceDetail } from './types';

export const getTotalAmountFromInvoices = (
  invoices: InvoiceDetail[],
  companyCurrency: string,
): MonetaryValue => {
  const currency = invoices.length
    ? invoices[0].functionalAmountToCollect.currency
    : companyCurrency;

  const totalAmount: MonetaryValue = invoices.reduce(
    (sum, invoice) => {
      return add(sum, invoice.functionalAmountToCollect);
    },
    create(0, currency, 2),
  );

  return totalAmount;
};
