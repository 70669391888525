import {
  Button,
  DropdownItem,
  DropdownMenu,
  IconButton,
  Tooltip,
} from '@dev-spendesk/grapes';

import { SearchFilter } from 'common/components/SearchFilter';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useSeatLimit } from 'modules/members/hooks/useSeatLimit';
import { apiUrl } from 'src/core/utils/api';

import { useCurrentMember } from '../../hooks';

import './MembersTableHeader.css';

type Props = {
  canInviteMembers: boolean;
  selectedMembersCount: number;
  searchPattern: string;
  selectedTab: 'active' | 'pending' | 'archived';
  isCompanyManagedByIntegration: boolean;
  setSearchPattern(search: string): void;
  onInviteClick(): void;
  onTabSelect(tab: 'active' | 'pending' | 'archived'): void;
  onEditMembersClick(): void;
};
export function MembersTableHeader({
  canInviteMembers,
  isCompanyManagedByIntegration,
  selectedMembersCount,
  searchPattern,
  setSearchPattern,
  onInviteClick,
  onEditMembersClick,
  onTabSelect,
  selectedTab,
}: Props) {
  const company = useCompany();
  const currentMember = useCurrentMember();
  const { t } = useTranslation('global');
  const seatLimit = useSeatLimit();

  const getMemberStatusFilterLabel = () => {
    switch (selectedTab) {
      case 'pending':
        return t('members.table.pending');
      case 'archived':
        return t('members.table.archived');
      default:
        return t('members.table.active');
    }
  };

  const statusOptions = [
    {
      key: 'active',
      label: t('members.table.active'),
    },
    {
      key: 'pending',
      label: t('members.table.pending'),
    },
    ...(isCompanyManagedByIntegration
      ? [
          {
            key: 'archived',
            label: t('members.table.archived'),
          },
        ]
      : []),
  ];

  return (
    <div className="mb-16 flex items-center justify-between gap-16">
      <div className="flex items-center gap-8">
        <SearchFilter
          placeholder={t('members.table.searchPlaceholder')}
          minLength={0}
          search={searchPattern}
          setSearch={(search) => {
            setSearchPattern(search);
          }}
        />
        <DropdownMenu
          renderButton={(getToggleButtonProps) => (
            <Button
              {...getToggleButtonProps()}
              variant="secondaryBrand"
              text={getMemberStatusFilterLabel()}
            />
          )}
          options={statusOptions}
          onSelect={({ key }) => {
            onTabSelect(key as 'active' | 'pending' | 'archived');
          }}
          renderOption={(option) => {
            return (
              <DropdownItem
                key={option.key}
                label={option.label}
                className="text-nowrap"
                isSelected={selectedTab === option.key}
                isHighlighted={selectedTab === option.key}
              />
            );
          }}
        />
      </div>
      <div className="flex items-center gap-8">
        {selectedMembersCount > 0 && (
          <Button
            text={t('members.table.bulkEditButton', {
              count: selectedMembersCount,
            })}
            variant="secondaryNeutral"
            aria-label="Edit members button"
            onClick={onEditMembersClick}
          />
        )}
        {selectedMembersCount === 0 && (
          <>
            {(currentMember.isAccountOwner || currentMember.isAdmin) && (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={apiUrl('/users/export', company.id)}
                aria-label={t('members.downloadMembersList')}
              >
                <Tooltip
                  content={t('members.downloadMembersList')}
                  triggerAsChild
                >
                  <IconButton
                    iconName="arrow-down-tray"
                    variant="secondaryNeutral"
                    aria-hidden="true"
                  />
                </Tooltip>
              </a>
            )}
            <Tooltip
              isDisabled={canInviteMembers}
              maxWidth={248}
              content={t('members.seatsAlreadyUsedToolTip', {
                count: seatLimit,
              })}
            >
              <Button
                text={t('members.table.inviteMemberButton')}
                variant="primaryBrand"
                isDisabled={!canInviteMembers}
                onClick={onInviteClick}
              />
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}
