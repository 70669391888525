import styles from './PreviewCard.module.css';

type Props = {
  title: string;
  description: string;
  illustration: React.ReactNode;
};

export const PreviewCard = ({ title, description, illustration }: Props) => (
  <div className={styles.root}>
    <div>{illustration}</div>
    <div className="py-24 pl-8 pr-24">
      <p className="uppercase text-secondary-bg-primary title-s">After</p>
      <p className="mb-8 leading-none text-brand-default title-xl">{title}</p>
      <p className="text-secondary-bg-primary body-m">{description}</p>
    </div>
  </div>
);
