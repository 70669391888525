import { Skeleton, SkeletonButton, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export function TellUsBoxSkeleton() {
  return (
    <div className="box flex flex-col items-center justify-center gap-16 bg-secondary-default p-24">
      <Skeleton height="70px" width="70px" />
      <SkeletonText size="m" width="100px" />
      <SkeletonButton />
    </div>
  );
}
