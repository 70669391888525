import { ListBox } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type Member } from '../../member';
import { MemberDisplay } from '../MemberDisplay/MemberDisplay';

import './ListMembers.css';

type Props = {
  members: Member[];
  onClick?(clickedMember: Member): void;
};

export const ListMembers = ({ members, onClick }: Props) => {
  const { t } = useTranslation('global');

  return (
    <ListBox
      key="listwithusers"
      header={
        <p className="p-16 text-left text-secondary-bg-primary body-m">
          {t('controlRulesModal.members.withThisControlRule', {
            count: members.length,
          })}
        </p>
      }
      className={classNames(
        'ListMembers__list',
        members.length === 0 && 'p-64',
      )}
      rowHeight="compact"
      options={members}
      emptyState={{
        title: t('controlRulesModal.members.noMembersSelected'),
      }}
      getOptionId={(option) => option.id}
    >
      {(option) => (
        <MemberDisplay key={option.id} member={option} onClick={onClick} />
      )}
    </ListBox>
  );
};
