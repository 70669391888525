import { useState, useEffect } from 'react';

import { type AdditionalFilter } from '../../../../../models';

export const useSelectedFilters = (
  initialSelectedFilters: AdditionalFilter[],
  applyFilters: (filters: AdditionalFilter[]) => void,
  resetFilters: () => void,
): {
  state: AdditionalFilter[];
  updateFilter(filter: AdditionalFilter): void;
  applyFilters(): void;
  resetFilters(): void;
  resetUnappliedFilters(): void;
} => {
  const [selectedFilters, setSelectedFilters] = useState(
    initialSelectedFilters,
  );

  useEffect(() => {
    setSelectedFilters(initialSelectedFilters);
  }, [initialSelectedFilters]);

  return {
    state: selectedFilters,
    updateFilter: (filter: AdditionalFilter): void => {
      setSelectedFilters(
        selectedFilters
          .filter(({ id }) => id !== filter.id)
          .concat([
            {
              id: filter.id,
              values: filter.values,
              type: filter.type,
            } as AdditionalFilter,
          ]),
      );
    },
    applyFilters: (): void => {
      applyFilters(selectedFilters);
    },
    resetUnappliedFilters: (): void => {
      setSelectedFilters(initialSelectedFilters);
    },
    resetFilters: (): void => {
      resetFilters();
      setSelectedFilters([]);
    },
  };
};
