import { Button, Modal, Callout, CheckboxField } from '@dev-spendesk/grapes';
import { stringify } from 'qs';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import appConfig from 'src/core/config';

import { AccountingBaseSelectInput } from './AccountingBaseSelectInput';

import './AccountingBaseSelect.css';

export function AccountingBaseSelectModal({
  accountingIntegration,
  initialAccountingIntegration,
  isOpen,
  onClose,
  onConfirm,
  onSelect,
}: {
  accountingIntegration: AccountingSoftware | 'noIntegration';
  initialAccountingIntegration: AccountingSoftware | 'noIntegration';
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onSelect: (accountingSoftware: AccountingSoftware) => void;
}) {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  const hadNoIntegration = initialAccountingIntegration === 'noIntegration';

  const howIntegrationWorksInfo = [
    'Sage100',
    'ACD',
    'Odoo',
    'ExactOnlineFr',
  ].includes(accountingIntegration);

  const integrationRequiresAcknowledgement = ['Sage100'].includes(
    accountingIntegration,
  );

  const [nextButtonEnabled, setNextButtonEnabled] = useState(
    !integrationRequiresAcknowledgement,
  );

  useEffect(() => {
    setNextButtonEnabled(integrationRequiresAcknowledgement);
  }, [integrationRequiresAcknowledgement]);

  const modalActions = [
    <Button
      key="close"
      text={t('misc.cancel')}
      variant="secondaryNeutral"
      onClick={() => {
        onClose();
      }}
    />,
    <Button
      key="next"
      text={t('bookkeep.integrations.settings.baseSelect.continue')}
      variant="primaryBrand"
      onClick={() => {
        return onConfirm();
      }}
      iconName={hadNoIntegration ? 'chevron-right' : undefined}
      iconPosition={hadNoIntegration ? 'right' : undefined}
      isDisabled={nextButtonEnabled}
    />,
  ];

  const fileBasedPreview = (
    <div className="AccountingBaseSelect__preview">
      <Button
        component="a"
        href={`${
          appConfig.apiUrls.api
        }/${companyId}/accounting-export-templates/file-preview?${stringify({
          accountingIntegration,
        })}`}
        target="_blank"
        variant="tertiaryNeutral"
        iconName="arrow-down-tray"
        iconPosition="left"
        text={t('bookkeep.integrations.settings.baseSelect.preview')}
      />
    </div>
  );

  const chiftInfoCallout = (
    <Callout
      title={t('bookkeep.integrations.settings.chiftInfoCallout.title')}
      iconName="plug"
      variant="info"
    >
      <div className="IntegrationInfoCallout__content">
        <Trans
          i18nKey="bookkeep.integrations.settings.chiftInfoCallout.content"
          values={{ integration: accountingIntegration }}
        >
          <a
            // TODO: this link should be updated to the correct one
            href={getChiftHelpCenterArticle(accountingIntegration)}
            target="_blank"
            rel="noopener noreferrer"
          >
            this article
          </a>
        </Trans>
      </div>
    </Callout>
  );

  const chiftWarningSection = (
    <>
      <Callout
        title={t('bookkeep.integrations.settings.chiftWarningCallout.title')}
        variant="warning"
      >
        <div className="IntegrationInfoCallout__warning">
          {t('bookkeep.integrations.settings.chiftWarningCallout.content', {
            integration: accountingIntegration,
          })}
        </div>
      </Callout>

      <CheckboxField
        className="mt-16 hover:bg-[transparent]"
        isChecked={!nextButtonEnabled}
        label={
          <Trans
            i18nKey="bookkeep.integrations.settings.chiftAcknowledgeTechnicalSupportNeeded"
            values={{ integration: accountingIntegration }}
          />
        }
        onChange={() => setNextButtonEnabled(!nextButtonEnabled)}
      />
    </>
  );

  const netsuiteInfoCallout = (
    <Callout
      title={t('bookkeep.integrations.settings.netsuiteInfoCallout.title')}
      iconName="plug"
      variant="info"
    >
      <div className="IntegrationInfoCallout__content">
        <Trans i18nKey="bookkeep.integrations.settings.netsuiteInfoCallout.content">
          <a
            href="https://helpcenter.spendesk.com/articles/6899573-netsuite-native-integration-contents"
            target="_blank"
            rel="noopener noreferrer"
          >
            this article
          </a>
        </Trans>
      </div>
    </Callout>
  );

  return (
    <Modal
      isOpen={isOpen}
      iconName="gear"
      iconVariant="info"
      title={t(
        hadNoIntegration
          ? 'bookkeep.integrations.settings.baseSelect.headerNoIntegration'
          : 'bookkeep.integrations.settings.baseSelect.header',
      )}
      subtitle={t('bookkeep.integrations.settings.baseSelect.description')}
      actions={modalActions}
    >
      <div
        className="AccountingBaseSelect__form"
        data-testid="accounting-base-select-form"
      >
        <AccountingBaseSelectInput
          selectedIntegration={accountingIntegration}
          onSelect={onSelect}
        />
        {hasIntegrationFileBasedExport(accountingIntegration) &&
          fileBasedPreview}
        {accountingIntegration === 'Netsuite' && netsuiteInfoCallout}
        {howIntegrationWorksInfo && (
          <div className="mb-16">{chiftInfoCallout}</div>
        )}
        {integrationRequiresAcknowledgement && <div>{chiftWarningSection}</div>}
      </div>
    </Modal>
  );
}

function getChiftHelpCenterArticle(
  accountingIntegration: AccountingSoftware | 'noIntegration',
) {
  switch (accountingIntegration) {
    case 'Sage100':
      return 'https://helpcenter.spendesk.com/collections/9677966-sage100-sur-compta-2-0';
    case 'ACD':
      return 'https://helpcenter.spendesk.com/fr/collections/10760041-integration-native-avec-acd';
    default:
      return '';
  }
}
