import {
  Avatar,
  Button,
  Callout,
  FormField,
  Modal,
} from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useNotifications } from 'src/core/modules/app/notifications';

import {
  type BulkCreateManagerAssociationPayload,
  postBulkCreateManagerAssociation,
} from '../../api/post-bulk-create-manager-associations';
import { type Member } from '../../models/member';
import {
  MemberManagerAutoComplete,
  type ManagerOption,
} from '../MemberManagerAutoComplete/MemberManagerAutoComplete';

type Props = {
  membersWithMissingManagers: Member[];
  isOpen: boolean;
  onClose: () => void;
};

export const BulkEditManagersModal = ({
  isOpen,
  onClose,
  membersWithMissingManagers,
}: Props) => {
  const { t } = useTranslation('global');
  const { successNotif, dangerNotif } = useNotifications();
  const queryClient = useQueryClient();
  const companyId = useCompanyId();

  const [managerMapping, setManagerMapping] = useState<{
    [key: string]: string | undefined;
  }>({});
  const isSaveButtonDisabled =
    Object.values(managerMapping).filter((v) => v !== undefined).length === 0;

  const handleOnClose = () => {
    setManagerMapping({});
    onClose();
  };

  const handleManagerSelection = (
    member: Member,
    manager: ManagerOption | undefined,
  ) => {
    setManagerMapping((previous) => ({
      ...previous,
      [member.id]: manager ? manager.key : undefined,
    }));
  };

  const handleCreateManagers = async () => {
    const bulkCreateManagersPayload: BulkCreateManagerAssociationPayload =
      Object.keys(managerMapping)
        .filter((memberId) => managerMapping[memberId] !== undefined)
        .map((memberId) => ({
          userId: memberId,
          managerId: managerMapping[memberId] as string,
        }));

    try {
      const { data } = await postBulkCreateManagerAssociation({
        companyId,
        payload: bulkCreateManagersPayload,
      });
      await queryClient.invalidateQueries(['users']);

      successNotif(
        t('members.reportingManagers.bulkUpdateModal.outcomeSuccess', {
          count: data.successIds.length,
        }),
      );

      // if some members were not updated, display an error notification alongside the success one & keep modal open
      if (data.errorIds.length > 0) {
        dangerNotif(
          t('members.reportingManagers.bulkUpdateModal.outcomePartialError', {
            users: getErrorMembersString(
              t,
              data.errorIds,
              membersWithMissingManagers,
            ),
          }),
        );
      } else {
        onClose();
      }
    } catch {
      dangerNotif(t('members.reportingManagers.bulkUpdateModal.outcomeError'));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      iconVariant="info"
      iconName="circle-information"
      title={t('members.reportingManagers.bulkUpdateModal.title')}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={onClose}
        />,
        <Button
          key="save"
          variant="primaryBrand"
          text={t('misc.save')}
          onClick={handleCreateManagers}
          isDisabled={isSaveButtonDisabled}
        />,
      ]}
    >
      <Callout
        className="p-16"
        variant="info"
        title={t('members.reportingManagers.missingManagersCallout.title', {
          count: membersWithMissingManagers.length,
        })}
      />
      <div className="mt-16 flex max-h-[60vh] min-h-[50vh] flex-col gap-16 overflow-y-auto">
        {membersWithMissingManagers.map((member, index) => (
          <div
            key={member.id}
            data-testid="MemberManagerSelection"
            className="grid grid-cols-5 gap-16 rounded-8 border border-solid border-default px-16 py-8"
          >
            <div className="col-span-2 flex items-center gap-16 text-left">
              <div>
                <Avatar src={member.avatar} text={member.fullname} size="m" />
              </div>
              <div className="grid">
                <span className="text-primary body-m">{member.fullname}</span>
                <span className="truncate text-primary body-s">
                  <EllipsisTooltip tooltipMaxWidth={400} text={member.email} />
                </span>
              </div>
            </div>

            <div className="col-span-3 flex flex-col gap-8 py-8 text-left">
              <FormField
                label={t('members.reportingManagers.bulkUpdateModal.selection')}
              >
                <MemberManagerAutoComplete
                  currentMember={member}
                  isDisabled={false}
                  // open autocomplete dropdown upwards for the last two items to avoid overlapping end of modal
                  isDropdownReversed={
                    membersWithMissingManagers.length > 2 &&
                    membersWithMissingManagers.length - 2 <= index
                  }
                  onSelect={(manager) =>
                    handleManagerSelection(member, manager)
                  }
                />
              </FormField>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

const getErrorMembersString = (
  t: TGlobalFunctionTyped,
  errorIds: string[],
  members: Member[],
) => {
  if (errorIds.length < 3) {
    return errorIds
      .map((errorId) => {
        const errorMember = members.find((member) => member.id === errorId);
        return errorMember ? errorMember.fullname : errorId;
      })
      .join(` ${t('misc.and')} `);
  }

  const firstTwoErrorMembers = errorIds
    .slice(0, 2)
    .map((errorId) => {
      const errorMember = members.find((member) => member.id === errorId);
      return errorMember ? errorMember.fullname : errorId;
    })
    .join(`, `);

  return `${firstTwoErrorMembers}, ${t('misc.and')} ${
    errorIds.length - 2
  } ${t('misc.others')}`;
};
