import { Button, Modal } from '@dev-spendesk/grapes';
import { type TFunction } from 'i18next';
import { type MouseEventHandler } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  isOpen: boolean;
  onCloseModal: MouseEventHandler<HTMLElement>;
  onConfirm: MouseEventHandler<HTMLElement>;
};
export const CostCenterActivationConfirmationModal = ({
  isOpen,
  onCloseModal,
  onConfirm,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      title={t('costCenters.activation.modal.confirmation.title')}
      iconName="sparkle"
      iconVariant="info"
      actions={[
        <Button
          key="secondary"
          type="button"
          variant="secondaryNeutral"
          onClick={(event) => {
            track(
              AnalyticEventName.COST_CENTERS_MIGRATION_CANCEL_BUTTON_CLICKED,
            );
            onCloseModal(event);
          }}
          text={t('costCenters.activation.modal.confirmation.cancel')}
        />,
        <Button
          key="primary"
          type="button"
          variant="primaryBrand"
          text={t('costCenters.activation.modal.confirmation.confirm')}
          onClick={(event) => {
            track(
              AnalyticEventName.COST_CENTERS_MIGRATION_CONFIRM_BUTTON_CLICKED,
            );
            onConfirm(event);
          }}
        />,
      ]}
      isOpen={isOpen}
    >
      <Trans
        i18nKey="costCenters.activation.modal.confirmation.content"
        t={t as TFunction}
      />
    </Modal>
  );
};
