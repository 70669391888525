import { Button, Modal } from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  isRemoving: boolean;
  controlRuleName: string;
  onCancel(): void;
  onDelete(): void;
};

export const DeleteControlRule = ({
  isRemoving,
  controlRuleName,
  onCancel,
  onDelete,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      title={t('controlRulesModal.delete.title')}
      iconName="hexagone-cross"
      iconVariant="alert"
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={onCancel}
        />,
        <Button
          key="delete"
          variant="primaryAlert"
          text={t('controlRulesModal.delete.buttonConfirm')}
          isDisabled={isRemoving}
          isLoading={isRemoving}
          onClick={onDelete}
        />,
      ]}
    >
      <Trans
        i18nKey="controlRulesModal.delete.userWilllooseControlRule"
        values={{ name: controlRuleName }}
        // eslint-disable-next-line react/jsx-key
        components={[<strong />]}
      />
    </Modal>
  );
};
