import { AutocompleteNoOptions, SkeletonText } from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useExpenseCategoriesQuery } from 'src/core/modules/company/expense-categories/containers/EcContainer/hooks/useExpenseCategoriesQuery';

import { useSpendLimitsQuery } from '../../hooks/useSpendLimitsQuery';

type Props = {
  value: string | undefined;
  onChange: (expenseCategoryId: string) => void;
};

export const ExpenseCategoryDropdown = ({ value, onChange }: Props) => {
  const { t } = useTranslation('global');
  const queryStates = useQueryStates({
    states: {
      expenseCategories: useExpenseCategoriesQuery(),
      spendLimits: useSpendLimitsQuery(),
    },
  });

  return (
    <QuerySuspense
      loading={<SkeletonText />}
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
      queryState={queryStates}
    >
      {({ expenseCategories, spendLimits }) => {
        const selectedValue = expenseCategories.find(
          (category) => category.id === value,
        );

        // Filter out the expense categories that are already used in a spend limit
        const availableCategories = expenseCategories.filter(
          (category) =>
            !spendLimits.some(
              (spendLimit) => spendLimit.customFieldValueId === category.id,
            ),
        );

        return (
          <AutocompleteSearch
            fit="parent"
            placeholder={t('spendLimits.modal.expenseCategoryPlaceholder')}
            value={
              selectedValue && {
                key: selectedValue.id,
                label: selectedValue.name,
              }
            }
            options={availableCategories.map((category) => {
              return {
                key: category.id,
                label: category.name,
              };
            })}
            onSelect={(v) => {
              if (v) {
                onChange(v.key);
              }
            }}
            renderNoOptions={(rawValue) => (
              <AutocompleteNoOptions className="text-center body-m">
                <div>
                  <Trans
                    i18nKey="misc.noOptions"
                    values={{ account: rawValue }}
                    components={[
                      <span key="noOptions" className="text-primary" />,
                    ]}
                  />
                </div>
              </AutocompleteNoOptions>
            )}
          />
        );
      }}
    </QuerySuspense>
  );
};
