import { Navigation } from '@dev-spendesk/grapes';
import { type ReactNode } from 'react';

import {
  NavigationLink,
  type NavigationLinkProps,
} from 'src/core/common/components/NavigationLink';

type HeaderWithNavigationProps = {
  children: ReactNode;
  links: NavigationLinkProps[];
  navigationLabel?: string;
};
export const HeaderWithNavigation = ({
  children,
  links,
  navigationLabel,
}: HeaderWithNavigationProps) => {
  return (
    <header className="sticky top-0 z-10 mx-24 bg-primary-default">
      <h1 className="py-16 text-primary heading-xl">{children}</h1>
      <Navigation
        aria-label={navigationLabel}
        leftNavigationItems={links.map((link) => (
          <NavigationLink {...link} key={link.key} />
        ))}
      />
    </header>
  );
};
