import { QuerySuspenseBase } from 'common/components/QuerySuspenseBase/QuerySuspenseBase';
import { UnexpectedErrorContainer } from 'src/core/common/components/UnexpectedError/UnexpectedErrorContainer';
import { useRequestsToApproveQuery } from 'src/core/modules/requests/api/useRequestsToApproveQuery';

import { RequestsListLoader } from '../RequestsListsLoader';

export const RequestsToApproveList = () => {
  const usersRequestsQueryState = useRequestsToApproveQuery({
    enabled: true,
  });

  return (
    <QuerySuspenseBase
      queryState={usersRequestsQueryState}
      loading={<RequestsListLoader />}
      fallback={() => <UnexpectedErrorContainer useAutoNotificationWording />}
    >
      {(usersRequestsData) => (
        <div className="flex w-full flex-col">
          {usersRequestsData.data.requests.map((request) => (
            <div key={request.id}>{request.description}</div>
          ))}
        </div>
      )}
    </QuerySuspenseBase>
  );
};
