import { Button, Skeleton, SkeletonButton } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import { useEffect } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { type useOrganisationEntityWalletSummaryQuery } from 'src/core/modules/organisation-reporting/hooks/data/useOrganisationEntityWalletSummaryQuery';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { WalletSummaryBreakdown } from './WalletSummaryBreakdown';
import { type EntityBasicInformation } from '../../../../types';
import { BreakdownGaugeSkeleton } from '../../../common/BreakdownGauge/BreakdownGaugeSkeleton';
import type { getEntityStatus, OnDataLoadedResult } from '../EntityListItem';
import { isEntityInactive } from '../types';

type Props = {
  entity: EntityBasicInformation;
  entityStatus: ReturnType<typeof getEntityStatus>;
  entityWalletSummaryQueryState: ReturnType<
    typeof useOrganisationEntityWalletSummaryQuery
  >;
  onWalletSummaryLoaded: (result: OnDataLoadedResult) => void;
  isDataVisible: boolean;
};

export const EntityItemWalletSummary = ({
  entity,
  entityStatus,
  entityWalletSummaryQueryState,
  onWalletSummaryLoaded,
  isDataVisible,
}: Props) => {
  const { t } = useTranslation('global');

  useEffect(() => {
    if (entityWalletSummaryQueryState.status === 'error') {
      onWalletSummaryLoaded({ entityData: entity, status: 'error' });
    } else if (entityWalletSummaryQueryState.status === 'success') {
      onWalletSummaryLoaded({
        entityData: { ...entity, ...entityWalletSummaryQueryState.data },
        status: 'success',
      });
    }
  }, [entityWalletSummaryQueryState.status]);

  if (entityWalletSummaryQueryState.status !== 'success' || !isDataVisible) {
    return (
      <div className="flex grow flex-col content-stretch gap-24">
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-col gap-20">
            <Skeleton width="120px" height="24px" />
            <SkeletonButton className="w-full" />
          </div>
        </div>
        <BreakdownGaugeSkeleton isCondensed legendMatrix={[2, 2, 2]} />
      </div>
    );
  }

  if (entityWalletSummaryQueryState.status === 'success') {
    // TODO: Remove this when we want to display churned entities
    // See https://spendesk.atlassian.net/browse/ECO-395
    if (entity.hasChurned) {
      return null;
    }

    const entityWalletSummary = entityWalletSummaryQueryState.data;

    const isInactive = isEntityInactive(entityStatus);
    const isAvailableFundsLow =
      entityWalletSummary.breakdown.trend === 'warning';

    const onClick = () => {
      window.open(
        routeFor(routes.COMPANY_BANK_FUNDING.path, { company: entity.id }),
        '_blank',
      );
      track(AnalyticEventName.ORGANISATION_REPORTING_ADD_FUNDS_BUTTON_CLICKED);
    };

    return (
      <div className="flex flex-col content-stretch gap-20">
        <p
          className={classnames(
            'leading-[24px] title-xl',
            isInactive ? 'text-secondary-bg-secondary' : 'text-primary',
          )}
        >
          {formatMonetaryValue({
            amount: entityWalletSummary.walletBalance,
            currency: entity.currency,
            precision: 2,
          })}
        </p>
        <Button
          className="inline-block text-nowrap"
          variant={
            isAvailableFundsLow ? 'secondaryWarning' : 'secondaryNeutral'
          }
          isDisabled={isInactive}
          text={t(
            'organisation.reporting.page.entities.entity.walletBalanceAddFunds',
          )}
          onClick={onClick}
        />
        <WalletSummaryBreakdown
          currency={entity.currency}
          breakdown={entityWalletSummary.breakdown}
          isInactive={isInactive && entityStatus !== 'churning'}
        />
      </div>
    );
  }
};
