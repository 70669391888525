import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useGetSupplierAccountsQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetSupplierAccountsQuery';
import {
  useAccountLength,
  useHasAuxiliaryAccountsEnabled,
} from 'modules/bookkeep/hooks';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { useGetEmployeeAccountCodesQuery } from 'modules/bookkeep/settings/integrations/hooks/useGetEmployeeAccountCodesQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { validateAccountPayable } from '../../../../utils';
import { EmployeeAccountsAddEditForm } from '../EmployeeAccountsAddEditForm';
import { type ModalState } from '../ModalState';

interface Props {
  isFixedUser?: boolean;
  modalState: ModalState;
  setModalState: (modalState: ModalState) => void;
  integrationStatus: IntegrationStatusWithIntegration;
}

export const EmployeeAccountAddEditModal = ({
  isFixedUser,
  integrationStatus,
  modalState,
  setModalState,
}: Props) => {
  const { t } = useTranslation('global');
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();
  const getSupplierAccountsQueryState = useGetSupplierAccountsQuery({
    includeArchived: false,
  });
  const getEmployeeAccountCodesQueryState = useGetEmployeeAccountCodesQuery();
  const accountLength = useAccountLength();

  return (
    <Modal
      data-testid="employee-account-add-modal"
      title={t('bookkeep.integrations.settings.employeeAccountsTable.addTitle')}
      isOpen={modalState.kind === 'add' || modalState.kind === 'edit'}
      iconVariant="info"
      iconName="plus"
      actions={[
        <Button
          key="cancel"
          onClick={() => setModalState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondaryNeutral"
        />,
        <Button
          data-testid="employee-account-save"
          key="saveChanges"
          onClick={() => {
            if (modalState.kind === 'add' || modalState.kind === 'edit') {
              const result = validateAccountPayable(
                modalState.employeeAccount.generalAccountCode ?? '',
                modalState.employeeAccount.auxiliaryAccountCode,
                accountLength,
                getSupplierAccountsQueryState,
                getEmployeeAccountCodesQueryState,
                integrationStatus.integration,
                auxiliaryAccountsEnabled,
              );

              if (result.outcome === 'valid') {
                setModalState({
                  ...modalState,
                  kind: 'confirmAdd',
                });
                return;
              }

              switch (result.reason) {
                case 'required':
                case 'invalidPattern': {
                  setModalState({
                    ...modalState,
                    error: result.reason,
                    inputChanged: false,
                  });
                  return;
                }
                case 'codeAlreadyExists': {
                  setModalState({
                    ...modalState,
                    error: 'codeAlreadyInUse',
                    codeInUseAccount:
                      result.existingAccount.kind === 'employeeAccount'
                        ? {
                            kind: 'employeeAccount',
                            account: {
                              isArchived: false,
                              ...result.existingAccount,
                            },
                          }
                        : {
                            kind: 'supplierAccount',
                            account: {
                              isArchived: false,
                              isDefault: false /* Not needed */,
                              ...result.existingAccount,
                            },
                          },
                    inputChanged: false,
                  });
                  return;
                }
                default:
                  rejectUnexpectedValue(
                    'defaultEmployeeAccountValidation',
                    result,
                  );
              }
            }
          }}
          text={t('misc.saveChanges')}
          variant="primaryBrand"
          isDisabled={
            (modalState.kind !== 'add' && modalState.kind !== 'edit') ||
            !modalState.employeeAccount.user ||
            !modalState.employeeAccount.generalAccountCode ||
            (modalState.error !== 'none' && !modalState.inputChanged)
          }
        />,
      ]}
    >
      <EmployeeAccountsAddEditForm
        isFixedUser={isFixedUser}
        modalState={modalState}
        integrationStatus={integrationStatus}
        setModalState={setModalState}
      />
    </Modal>
  );
};

export { type ModalState } from '../ModalState';
