import {
  Button,
  Callout,
  DropdownItem,
  IconButton,
  Input,
  Select,
} from '@dev-spendesk/grapes';
import { type Dispatch, useEffect, useReducer, useRef, useState } from 'react';

import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { useNotifications } from 'src/core/modules/app/notifications';
import { logger } from 'src/utils/datadog-log-wrapper';

import {
  ApplyTriggerProductTour,
  FilterNameProductTour,
  SaveTriggerProductTour,
} from './FilterBuilderFeaturePromotion';
import {
  type SubfilterValueWithId,
  type SelectedFilter,
  type FilterWithId,
  type SelectedFilterWithId,
  toFilterWithId,
  emptySelectedFilter,
  emptySubfilter,
  emptyFilter,
  emptySubfilterValue,
  type SavedFilter,
  toFilterUrlParameter,
  type Field,
} from './FilterBuilderModel';

export const FilterBuilderForm = ({
  savedFilters,
  fields,
  activeFilter,
  selectedFilter,
  applyFilter,
  createFilter,
  updateFilter,
  renderSubfilterValue,
  dispatchFilterAction: dispatch,
}: {
  savedFilters: SavedFilter[];
  fields: Field[];
  activeFilter: SelectedFilter | undefined;
  selectedFilter: SelectedFilterWithId;
  applyFilter(filter: SelectedFilter | undefined): void;
  createFilter(filter: SelectedFilter): Promise<SavedFilter>;
  updateFilter(filter: SelectedFilter): Promise<SavedFilter>;
  renderSubfilterValue(params: {
    subfilterValue: SubfilterValueWithId;
    updateSubfilterValue(newSubfilterValue: SubfilterValueWithId): void;
    subfilterOperatorName: string;
    subfilterValueName: string;
  }): React.ReactNode;
  dispatchFilterAction: Dispatch<FilterBuilderAction>;

  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { t } = useTranslation('global');
  const { successNotif } = useNotifications();
  const filterOperators = getFilterOperators(t);
  const subfilterValueOperators = getSubfilterValueOperators(t);
  const filter = selectedFilter.filter;
  const subfilterGroups = filter.subfilters;
  const isSavedFilter = Boolean(selectedFilter.id);
  const isDuplicatedFilter = selectedFilter.id === '';
  const isSavedOrDuplicatedFilter = isSavedFilter || isDuplicatedFilter;
  const isFilterReady = checkIsFilterReady(filter);
  const isFilterTouched = checkIsFilterTouched(
    selectedFilter,
    savedFilters,
    fields,
  );

  const [showSavingForm, setShowSavingForm] = useState(false);

  const [error, setError] = useState<Error | null>(null);

  const { subfilterFieldName, subfilterOperatorName, subfilterValueName } =
    useAutomaticallyFocusOnFirstEmptyField(filter);

  return (
    <div className="flex flex-col gap-16 p-16">
      {/* Filter top toolbar */}
      <header className="flex h-[36px] flex-row items-center gap-8">
        {(isSavedOrDuplicatedFilter ||
          (!isSavedOrDuplicatedFilter && showSavingForm)) && (
          <FilterNameField
            onCancel={
              isSavedFilter || (isDuplicatedFilter && !selectedFilter.name)
                ? undefined
                : () => {
                    setShowSavingForm(false);
                    dispatch({
                      type: 'updateFilter',
                      filterName: '',
                    });
                  }
            }
            filterName={selectedFilter.name}
            onChange={(filterName) => {
              dispatch({
                type: 'updateFilter',
                filterName,
              });
            }}
          />
        )}

        {!isSavedOrDuplicatedFilter && !showSavingForm && (
          <>
            <h3 className="text-primary title-l">
              {t('common.filterBuilder.title')}
            </h3>

            {isFilterReady && (
              <div className="ml-auto flex flex-row items-center gap-8">
                <SaveTriggerProductTour />

                <Button
                  text={t('common.filterBuilder.save')}
                  variant="tertiaryNeutral"
                  iconName="arrow-down-tray"
                  id="filter-builder-save-trigger"
                  onClick={() => {
                    setShowSavingForm(true);
                  }}
                />
              </div>
            )}
          </>
        )}
      </header>
      {/* Filter groups */}
      {subfilterGroups.map((subfilterGroup, subfilterGroupIndex) => {
        const subfilterGroupId = subfilterGroup.id;

        return (
          <div key={subfilterGroupId} className="flex w-full flex-row gap-8">
            {/* Filter group operator prefix */}
            {subfilterGroups.length > 1 && subfilterGroupIndex === 0 && (
              <div className="mt-[22px] w-[80px] shrink-0 pl-8 text-primary body-m">
                {t('common.filterBuilder.where')}
              </div>
            )}
            {subfilterGroups.length > 1 && subfilterGroupIndex === 1 && (
              <div>
                <Select
                  placeholder={t('common.filterBuilder.selectFilterOperator')}
                  onSelect={({ key }) => {
                    dispatch({
                      type: 'updateFilter',
                      filterOperator: key,
                    });
                  }}
                  options={filterOperators}
                  value={{
                    key: filter.operator,
                    label: filter.operator,
                  }}
                  className="mt-16 w-[80px] [&_input]:w-full"
                />
              </div>
            )}
            {subfilterGroups.length > 1 && subfilterGroupIndex > 1 && (
              <div className="mt-[22px] w-[80px] shrink-0 pl-8 text-primary body-m">
                {filter.operator}
              </div>
            )}
            {/* Subfilters */}
            <div className="flex w-[800px] flex-col gap-8 rounded-8 bg-secondary-default p-16 pb-8">
              {subfilterGroup.subfilters.map(
                (subfilterValue, subfilterValueIndex) => (
                  <div
                    key={subfilterValue.id}
                    className="flex flex-row items-center gap-8"
                  >
                    {/* Subfilter operator prefix */}
                    {subfilterValueIndex === 0 && (
                      <div className="w-[80px] shrink-0 pl-8 text-primary body-m">
                        {t('common.filterBuilder.where')}
                      </div>
                    )}

                    {subfilterGroup.subfilters.length > 1 &&
                      subfilterValueIndex === 1 && (
                        <Select
                          placeholder={t(
                            'common.filterBuilder.selectFilterGroupOperator',
                          )}
                          onSelect={({ key }) => {
                            dispatch({
                              type: 'updateSubfilterValue',
                              subfilterGroupId,
                              subfilterOperator: key,
                              subfilterValue: subfilterValue,
                            });
                          }}
                          options={subfilterValueOperators}
                          value={{
                            key: subfilterGroup.operator,
                            label: subfilterGroup.operator,
                          }}
                          className="w-[80px] [&_input]:w-full"
                        />
                      )}

                    {subfilterGroup.subfilters.length > 1 &&
                      subfilterValueIndex > 1 && (
                        <div className="w-[80px] shrink-0 pl-8 text-primary body-m">
                          {subfilterGroup.operator}
                        </div>
                      )}

                    {/* Subfilter field type */}
                    <Select
                      placeholder={t('common.filterBuilder.selectField')}
                      onSelect={({ key }) => {
                        dispatch({
                          type: 'updateSubfilterValue',
                          subfilterGroupId,
                          subfilterOperator: subfilterGroup.operator,
                          subfilterValue: {
                            ...subfilterValue,
                            field: key,
                            operator: undefined,
                            value: undefined,
                          },
                        });
                      }}
                      options={fields}
                      value={fields
                        .flatMap((field) => field.options ?? [field])
                        .find((field) => field?.key === subfilterValue.field)}
                      className="w-[160px] [&_input]:w-full"
                      renderOption={(option, optionState) => (
                        <DropdownItem
                          {...option}
                          {...optionState}
                          className="w-[240px]"
                        />
                      )}
                      name={`${subfilterFieldName}-${subfilterValue.id}`}
                      dropdownContentMaxHeight="300px"
                    />

                    {/* Subfilter field operator and value */}
                    {renderSubfilterValue({
                      subfilterValue,
                      updateSubfilterValue: (newSubfilterValue) => {
                        dispatch({
                          type: 'updateSubfilterValue',
                          subfilterGroupId,
                          subfilterOperator: subfilterGroup.operator,
                          subfilterValue: newSubfilterValue,
                        });
                      },
                      subfilterOperatorName: `${subfilterOperatorName}-${subfilterValue.id}`,
                      subfilterValueName: `${subfilterValueName}-${subfilterValue.id}`,
                    })}

                    {/* Subfilter actions */}
                    {!(
                      subfilterGroups.length === 1 &&
                      subfilterGroup.subfilters.length === 1 &&
                      !subfilterValue.field
                    ) && (
                      <IconButton
                        iconName="trash"
                        aria-label={t('common.filterBuilder.removeFilter')}
                        className="-ml-4 -mr-12 flex-shrink-0"
                        onClick={() =>
                          dispatch({
                            type: 'removeSubfilterValue',
                            subfilterGroupId,
                            subfilterValueId: subfilterValue.id,
                          })
                        }
                      />
                    )}
                  </div>
                ),
              )}

              {/* Filter group toolbar */}
              <div className="mt-8 flex flex-row items-center gap-8">
                <Button
                  text={t('common.filterBuilder.addFilter')}
                  variant="tertiaryNeutral"
                  hasNoHorizontalPadding
                  iconName="plus"
                  onClick={() =>
                    dispatch({
                      type: 'addSubfilterValue',
                      subfilterGroupId,
                    })
                  }
                />
                {subfilterGroup.subfilters.length > 1 && (
                  <Button
                    text={t('common.filterBuilder.clearAll')}
                    hasNoHorizontalPadding
                    variant="tertiaryNeutral"
                    className="ml-auto"
                    onClick={() =>
                      dispatch({
                        type: 'removeSubfilterGroup',
                        subfilterGroupId,
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
      {/* Filter create/update errors if any */}
      {error && <Callout title={error.message} variant="alert" />}
      {/* Filter bottom toolbar */}
      <footer className="flex flex-row items-center gap-8">
        <Button
          text={t('common.filterBuilder.addFilterGroup')}
          variant="tertiaryNeutral"
          hasNoHorizontalPadding
          iconName="plus"
          onClick={() => dispatch({ type: 'addSubfilterGroup' })}
        />

        {isSavedFilter && (
          <>
            <Button
              text={activeFilter ? t('misc.reset') : t('misc.cancel')}
              variant="secondaryNeutral"
              className="ml-auto"
              onClick={() => {
                applyFilter(undefined);
              }}
            />

            <Button
              id="filter-builder-apply-trigger"
              text={
                isFilterReady && selectedFilter.name && isFilterTouched
                  ? t('misc.saveAndApply')
                  : t('misc.apply')
              }
              variant="primaryBrand"
              onClick={async () => {
                if (!isFilterTouched) {
                  return applyFilter(selectedFilter);
                }

                try {
                  setError(null);
                  await updateFilter(selectedFilter);
                  successNotif(
                    t('common.filterBuilder.saveFilterSuccess', {
                      filterName: selectedFilter.name,
                    }),
                  );
                  applyFilter(selectedFilter);
                } catch (updateFilterError) {
                  logger.error(updateFilterError, {
                    team: 'none',
                    scope: 'filter-builder',
                  });
                  setError(updateFilterError);
                }
              }}
              isDisabled={!isFilterReady || !selectedFilter.name}
            />
          </>
        )}

        {!isSavedFilter &&
          (isFilterReady ? (
            <>
              <Button
                text={t('misc.reset')}
                variant="secondaryNeutral"
                className="ml-auto"
                onClick={() => {
                  applyFilter(undefined);
                }}
              />
              <Button
                id="filter-builder-apply-trigger"
                text={
                  selectedFilter.name ? t('misc.saveAndApply') : t('misc.apply')
                }
                variant="primaryBrand"
                onClick={async () => {
                  if (!selectedFilter.name) {
                    return applyFilter(selectedFilter);
                  }

                  try {
                    setError(null);
                    const newFilter = await createFilter(selectedFilter);
                    successNotif(
                      t('common.filterBuilder.saveFilterSuccess', {
                        filterName: newFilter.name,
                      }),
                    );
                    setShowSavingForm(false);
                    dispatch({ type: 'updateFilter', filterId: newFilter.id });
                    applyFilter(newFilter);
                  } catch (createFilterError) {
                    logger.error(createFilterError, {
                      team: 'none',
                      scope: 'filter-builder',
                    });
                    setError(createFilterError);
                  }
                }}
              />

              {selectedFilter.name && <ApplyTriggerProductTour />}
            </>
          ) : (
            <Button
              text={t('misc.cancel')}
              variant="secondaryNeutral"
              className="ml-auto"
              onClick={() => {
                applyFilter(undefined);
              }}
            />
          ))}
      </footer>
    </div>
  );
};

/**
 * State handler hook
 */

type FilterBuilderState = SelectedFilterWithId;

type FilterBuilderAction =
  | {
      type: 'reset';
      filter?: SelectedFilter;
    }
  | {
      type: 'updateFilter';
      filterId?: string;
      filterOperator?: 'and' | 'or';
      filterName?: string;
    }
  | { type: 'addSubfilterGroup' }
  | {
      type: 'removeSubfilterGroup';
      subfilterGroupId: string;
    }
  | {
      type: 'addSubfilterValue';
      subfilterGroupId: string;
    }
  | {
      type: 'updateSubfilterValue';
      subfilterGroupId: string;
      subfilterOperator: 'and' | 'or';
      subfilterValue: SubfilterValueWithId;
    }
  | {
      type: 'removeSubfilterValue';
      subfilterGroupId: string;
      subfilterValueId: string;
    };

export const useFilterBuilder = (
  initialFilter: SelectedFilter = emptySelectedFilter(),
) => {
  const initialState: FilterBuilderState = toFilterWithId(initialFilter);

  return useReducer(
    (
      state: FilterBuilderState,
      action: FilterBuilderAction,
    ): FilterBuilderState => {
      switch (action.type) {
        case 'reset': {
          return {
            ...state,
            ...(action.filter
              ? toFilterWithId(action.filter)
              : emptySelectedFilter()),
          };
        }
        case 'addSubfilterGroup': {
          return {
            ...state,
            filter: {
              ...state.filter,
              subfilters: state.filter.subfilters.concat(emptySubfilter()),
            },
          };
        }
        case 'updateFilter': {
          return {
            ...state,
            filter: {
              ...state.filter,
              operator:
                'filterOperator' in action
                  ? (action.filterOperator ?? 'and')
                  : state.filter.operator,
            },
            name:
              'filterName' in action ? (action.filterName ?? '') : state.name,
            id: 'filterId' in action ? action.filterId : state.id,
          };
        }
        case 'removeSubfilterGroup': {
          return {
            ...state,
            filter:
              state.filter.subfilters.length <= 1
                ? emptyFilter()
                : {
                    ...state.filter,
                    subfilters: state.filter.subfilters.filter(
                      (subfilterGroup) =>
                        subfilterGroup.id !== action.subfilterGroupId,
                    ),
                  },
          };
        }
        case 'addSubfilterValue': {
          return {
            ...state,
            filter: {
              ...state.filter,
              subfilters: state.filter.subfilters.map((subfilterGroup) => {
                if (subfilterGroup.id !== action.subfilterGroupId) {
                  return subfilterGroup;
                }

                return {
                  ...subfilterGroup,
                  subfilters: subfilterGroup.subfilters.concat(
                    emptySubfilterValue(),
                  ),
                };
              }),
            },
          };
        }
        case 'updateSubfilterValue': {
          return {
            ...state,
            filter: {
              ...state.filter,
              subfilters: state.filter.subfilters.map((subfilterGroup) => {
                if (subfilterGroup.id !== action.subfilterGroupId) {
                  return subfilterGroup;
                }

                // "," are used to separate array values in the URL, so we do not allow them in the value
                const sanitizeValue = (value: unknown) =>
                  typeof value === 'string' ? value.replace(',', '') : value;

                const actionValue = Array.isArray(action.subfilterValue.value)
                  ? action.subfilterValue.value.map(sanitizeValue)
                  : sanitizeValue(action.subfilterValue.value);

                return {
                  ...subfilterGroup,
                  operator: action.subfilterOperator,
                  subfilters: subfilterGroup.subfilters.map((subfilterValue) =>
                    subfilterValue.id === action.subfilterValue.id
                      ? { ...action.subfilterValue, value: actionValue }
                      : subfilterValue,
                  ),
                };
              }),
            },
          };
        }
        case 'removeSubfilterValue': {
          return {
            ...state,
            filter: {
              ...state.filter,
              subfilters: state.filter.subfilters.flatMap((subfilterGroup) => {
                if (subfilterGroup.id !== action.subfilterGroupId) {
                  return subfilterGroup;
                }

                if (
                  state.filter.subfilters.length > 1 &&
                  subfilterGroup.subfilters.length <= 1
                ) {
                  return [];
                }

                return {
                  ...subfilterGroup,
                  subfilters:
                    subfilterGroup.subfilters.length <= 1
                      ? [emptySubfilterValue()]
                      : subfilterGroup.subfilters.filter(
                          (subfilterValue) =>
                            subfilterValue.id !== action.subfilterValueId,
                        ),
                };
              }),
            },
          };
        }
        default:
          throw new Error(`Unknown action type`);
      }
    },
    initialState,
  );
};

const useAutomaticallyFocusOnFirstEmptyField = (filter: FilterWithId) => {
  const subfilterFieldName = 'subfilter-field';
  const subfilterOperatorName = 'subfilter-operator';
  const subfilterValueName = 'subfilter-value';

  const filterReference = useRef(filter);

  useEffect(() => {
    // Get the first changed subfilter value compare to previous version
    const changedSubfilterValue = filter.subfilters.reduce(
      (accumulator: SubfilterValueWithId | undefined, subfilterGroup, index) =>
        subfilterGroup === filterReference.current.subfilters[index]
          ? accumulator
          : subfilterGroup.subfilters.reduce(
              (
                subAccumulator: SubfilterValueWithId | undefined,
                subfilterValue,
                subIndex,
              ) =>
                subfilterValue ===
                filterReference.current.subfilters[index]?.subfilters[subIndex]
                  ? subAccumulator
                  : subfilterValue,
              undefined,
            ),
      undefined,
    );

    if (
      // When the popover open initially
      document.activeElement === document.body ||
      // Or when some change occurred on the subfilter value
      changedSubfilterValue
    ) {
      const getSubfilterValue = (element: Element) => {
        return filter.subfilters.reduce(
          (accumulator: SubfilterValueWithId | undefined, subfilterGroup) =>
            subfilterGroup.subfilters.find((subfilter) =>
              element.getAttribute('name')?.endsWith(subfilter.id),
            ) ?? accumulator,
          undefined,
        );
      };

      const valueElements = Array.from(
        document.querySelectorAll(`[name^=${subfilterValueName}]`),
      ).filter((element) => {
        // Keep only elements with empty value
        const value = getSubfilterValue(element)?.value;
        return !(value && typeof value === 'object'
          ? Object.values(value).every(Boolean)
          : value);
      });

      const operatorElements = Array.from(
        document.querySelectorAll(`[name^=${subfilterOperatorName}][value=""]`),
      );

      const fieldElements = Array.from(
        document.querySelectorAll(`[name^=${subfilterFieldName}][value=""]`),
      );

      let focusedElement: Element | null = null;
      [...fieldElements, ...operatorElements, ...valueElements].forEach(
        (element) => {
          if (element instanceof HTMLInputElement) {
            if (
              !focusedElement &&
              (changedSubfilterValue
                ? getSubfilterValue(element) === changedSubfilterValue
                : true)
            ) {
              element.focus();
              element.click();
              focusedElement = element;
            } else {
              element.blur();
            }
          }
        },
      );
    }

    filterReference.current = filter;
  }, [filter]);

  return { subfilterFieldName, subfilterOperatorName, subfilterValueName };
};

/**
 * Helpers
 */

const getFilterOperators = (t: TGlobalFunctionTyped) => [
  { key: 'and' as const, label: t('common.filterBuilder.and') },
  { key: 'or' as const, label: t('common.filterBuilder.or') },
];

const getSubfilterValueOperators = (t: TGlobalFunctionTyped) => [
  { key: 'and' as const, label: t('common.filterBuilder.and') },
  { key: 'or' as const, label: t('common.filterBuilder.or') },
];

const checkIsFilterReady = (filter: FilterWithId): boolean => {
  return filter.subfilters.some((subfilterGroup) =>
    subfilterGroup.subfilters.some(
      (subfilter) =>
        subfilter.field &&
        subfilter.operator &&
        (subfilter.value && typeof subfilter.value === 'object'
          ? Object.values(subfilter.value).every(Boolean)
          : subfilter.value !== undefined),
    ),
  );
};

const checkIsFilterTouched = (
  selectedFilter: SelectedFilter,
  savedFilters: SavedFilter[],
  fields: Field[],
) => {
  const existingFilter = savedFilters.find(
    ({ id }) => id === selectedFilter.id,
  );

  if (!existingFilter) {
    return false;
  }

  return (
    existingFilter.name + toFilterUrlParameter(existingFilter, fields) !==
    selectedFilter.name + toFilterUrlParameter(selectedFilter, fields)
  );
};

/**
 * Child components
 */

const FilterNameField = ({
  onCancel,
  onChange,
  filterName,
}: {
  onCancel?(): void;
  onChange(filterName: string): void;
  filterName: string;
}) => {
  const { t } = useTranslation('global');

  useEffect(() => {
    const element = document.getElementById('filter-builder-filter-name');
    if (element && document.activeElement !== element && onCancel) {
      element.focus();
    }
  }, []);

  return (
    <>
      <Input
        id="filter-builder-filter-name"
        placeholder={t('common.filterBuilder.giveName')}
        value={filterName}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        fit="parent"
        rightAddon={
          onCancel && (
            <IconButton
              aria-label={t('misc.cancel')}
              iconName="cross"
              onClick={onCancel}
            />
          )
        }
      />

      {!filterName && <FilterNameProductTour />}
    </>
  );
};
