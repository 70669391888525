import { Button, Callout } from '@dev-spendesk/grapes';
import cx from 'classnames';
import type * as Money from 'ezmoney';
import React from 'react';
import { Link } from 'react-router-dom';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { buildShortfallCalloutTitle } from '../../../wallet/buildShortfallCalloutTitle';
import { type DetailedWalletSummary } from '../../../wallet/walletSummary';
import { hasCompanyNegativeAvailableAmount } from '../hasCompanyNegativeAvailableAmount';

import './CompanyDropdownWalletDetails.css';

type Props = {
  className?: string;
  company: DetailedWalletSummary;
  onRedirectToWalletSettings: () => void;
};

export const CompanyDropdownWalletDetails = ({
  className,
  company,
  onRedirectToWalletSettings,
}: Props) => {
  const { t } = useTranslation('global');

  const hasAccessToWallet = useFeature(FEATURES.WALLET_ACCESS);
  const hasNegativeAvailableAmount = hasCompanyNegativeAvailableAmount(company);

  return (
    <div className={cx('CompanyDropdownWalletDetails', className)}>
      <div className="mb-8 flex items-end justify-between body-m">
        <span>{t('wallet.walletDetails.allocatedOnCards')}</span>
        <span>
          {formatMonetaryValue(
            company.walletDetails.allocatedOnCards.totalAmount,
          )}
        </span>
      </div>
      <div className="flex flex-col gap-8 text-secondary-bg-primary body-s">
        <div className="flex h-20 items-center justify-between">
          <span>{t('wallet.walletDetails.subscriptions')}</span>
          <span>
            {formatMonetaryValue(
              company.walletDetails.allocatedOnCards.subscriptionCardsAmount,
            )}
          </span>
        </div>
        <div className="flex h-20 items-center justify-between">
          <span>{t('wallet.walletDetails.plasticCards')}</span>
          <span>
            {formatMonetaryValue(
              company.walletDetails.allocatedOnCards.physicalCardsAmount,
            )}
          </span>
        </div>
        <div className="flex h-20 items-center justify-between">
          <span>{t('wallet.walletDetails.virtualCards')}</span>
          <span>
            {formatMonetaryValue(
              company.walletDetails.allocatedOnCards.singlePurchaseCardsAmount,
            )}
          </span>
        </div>
      </div>
      {company.walletDetails.scheduledTransfers && (
        <>
          <hr className="separator my-12" style={{ borderTop: 'none' }} />
          <ScheduledTransfersDetails company={company} />
        </>
      )}
      <hr className="separator my-12" style={{ borderTop: 'none' }} />
      <div className="mb-16 flex items-end justify-between title-m">
        <span>
          {hasNegativeAvailableAmount
            ? t('wallet.walletDetails.expectedShortfall')
            : t('wallet.walletDetails.available')}
        </span>
        <span
          className={cx('text-success-default', {
            'text-warning-default': hasNegativeAvailableAmount,
          })}
        >
          {formatMonetaryValue(company.walletDetails.available.totalAmount, {
            // FIXME: can't access const enum SignDisplay with isolatedModules
            signDisplay: 'never' as Money.SignDisplay,
          })}
        </span>
      </div>
      {hasNegativeAvailableAmount && company.totalAmount && (
        <Callout
          className="mb-16"
          variant="warning"
          title={buildShortfallCalloutTitle({ walletSummary: company, t })}
        />
      )}
      {hasAccessToWallet && (
        <>
          <hr className="separator my-16" style={{ borderTop: 'none' }} />
          <Link
            to={routeFor(routes.COMPANY_BANK.path, { company: company.id })}
            onClick={onRedirectToWalletSettings}
          >
            <Button
              fit="parent"
              variant="secondaryNeutral"
              text={
                hasNegativeAvailableAmount
                  ? t('wallet.walletDetails.topUpWallet')
                  : t('wallet.walletDetails.viewDetails')
              }
            />
          </Link>
        </>
      )}
    </div>
  );
};

const ScheduledTransfersDetails = ({
  company,
}: {
  company: DetailedWalletSummary;
}) => {
  const { t } = useTranslation('global');

  if (!company.walletDetails?.scheduledTransfers) {
    return null;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-end justify-between title-m">
        <span>{t('wallet.walletDetails.scheduledTransfers')}</span>
        <span>
          {formatMonetaryValue(
            company.walletDetails.scheduledTransfers.totalAmount,
          )}
        </span>
      </div>
      <div className="flex flex-col gap-8 text-secondary-bg-primary body-s">
        <div className="flex h-20 items-center justify-between">
          <span>{t('wallet.walletDetails.expenseClaims')}</span>
          <span>
            {formatMonetaryValue(
              company.walletDetails.scheduledTransfers.expenseClaimsAmount,
            )}
          </span>
        </div>
        <div className="flex h-20 items-center justify-between">
          <span>{t('wallet.walletDetails.invoices')}</span>
          <span>
            {formatMonetaryValue(
              company.walletDetails.scheduledTransfers.invoicesAmount,
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
