import { type MonetaryValue } from 'ezmoney';
import { useQuery } from 'react-query';

import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { apiV2Url } from 'src/core/utils/api';

import { type RequestState } from '../models/requestState';
import { type RequestType } from '../models/requestType';

export type UserRequest = {
  id: string;
  state: RequestState;
  createdAt: string;
  amount: MonetaryValue;
  originalConvertedAmount?: MonetaryValue;
  description: string;
  supplier?: {
    id: string;
    name: string;
  };
} & (
  | {
      type: Omit<RequestType, 'mileage_allowance'>;
    }
  | {
      type: 'mileage_allowance';
      distance: number;
      distanceUnit: 'km' | 'mi';
    }
);

export type UserRequests = {
  user: {
    id: string;
    avatarUrl: string | null;
    firstname: string;
    lastname: string;
    email: string;
  };
  requests: UserRequest[];
};

export const useUsersRequestsQuery = ({ enabled }: { enabled: boolean }) => {
  const company = useCompany();
  return useQuery(
    ['v2', 'users', 'requests'],
    async (): Promise<{ data: UserRequests[] }> => {
      const response = await fetch(
        apiV2Url('/users/requests?state=pending', company.id),
        {
          method: 'GET',
          credentials: 'include',
        },
      );
      if (!response.ok) {
        throw new Error('Failed to fetch payment beneficiaries');
      }
      return response.json();
    },
    {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};
