import {
  Button,
  FormField,
  Modal,
  RadioField,
  RadioGroup,
} from '@dev-spendesk/grapes';
import React from 'react';

import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitStatus';
import { type CustomField } from 'modules/custom-fields/models/customField';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useExpenseCategoriesEditSettingsFormikProps } from './getFormikProps';

export type Props = {
  isOpen: boolean;
  expenseCategoryCustomField: CustomField;
  onSubmit(editedExpenseCategoryCustomField: CustomField): Promise<void>;
  onCancel(): void;
};

export const ExpenseCategoriesEditSettingsModal = ({
  isOpen,
  expenseCategoryCustomField,
  onSubmit,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();

  const formikProps = useExpenseCategoriesEditSettingsFormikProps({
    expenseCategoryCustomField,
    onSubmit,
  });

  const handleCancel = () => {
    formikProps.resetForm();
    onCancel();
  };

  return (
    <Modal
      isOpen={isOpen}
      iconName="circle-information"
      onClose={onCancel}
      title={t('misc.expenseCategory')}
      actions={[
        <Button
          key="cancel-button"
          text={t('misc.cancel')}
          variant="secondaryNeutral"
          onClick={handleCancel}
        />,
        <Button
          key="submit-button"
          disabled={formikProps.isSubmitting}
          text={t('expenseCategories.edit.editButton')}
          onClick={formikProps.submitForm}
          isLoading={formikProps.isSubmitting}
        />,
      ]}
    >
      <div className="text-left">
        <FormField label={t('expenseCategories.edit.requirementLabel')}>
          <RadioGroup
            name="isRequired"
            value={`${formikProps.values.isRequired}`}
            onChange={(e) => {
              formikProps.setFieldValue(
                'isRequired',
                e.target.value === 'true',
              );
            }}
          >
            <RadioField
              value="true"
              label={t('expenseCategories.edit.requirementOptionMandatory')}
            />
            <RadioField
              value="false"
              label={t('expenseCategories.edit.requirementOptionOptional')}
            />
          </RadioGroup>
        </FormField>
        {isAnalyticalSplitActivated ? (
          <FormField
            label={t('expenseCategories.edit.splittableLabel')}
            className="mt-16"
          >
            <RadioGroup
              name="isSplittable"
              value={formikProps.values.isSplittable ? 'true' : 'false'}
              onChange={(e) => {
                formikProps.setFieldValue(
                  'isSplittable',
                  e.target.value === 'true',
                );
              }}
            >
              <RadioField
                value="true"
                label={t('expenseCategories.edit.splittableOptionYes')}
              />
              <RadioField
                value="false"
                label={t('expenseCategories.edit.splittableOptionNo')}
              />
            </RadioGroup>
          </FormField>
        ) : null}
      </div>
    </Modal>
  );
};
