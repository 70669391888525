import type { FormikErrors } from 'formik';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import {
  getAvailableAccountingSoftwares,
  getRequiredAnalyticalFields,
} from 'modules/bookkeep/settings/export/components/TemplateEditorForm/utils';
import type { CustomField } from 'modules/bookkeep/settings/export/customField';
import { toFileTypeEncoding } from 'modules/bookkeep/settings/export/redux/reshapers';
import type { RawCustomField } from 'modules/custom-fields/models/customField';

import {
  type DateFormat,
  type DecimalDelimiter,
  type DateDelimiter,
  type ColumnDelimiter,
  type Column,
  type RowDisplayOption,
  type FileTypeEncodingOption,
  type Template,
} from '../../template';

export type FormValues = {
  name: string;
  dateFormat: DateFormat;
  dateDelimiter: DateDelimiter;
  decimalDelimiter: DecimalDelimiter;
  shouldOutputHeaders: boolean;
  fileTypeEncoding: FileTypeEncodingOption;
  columnDelimiter: ColumnDelimiter;
  columns: Column[];
  language: 'en' | 'fr' | 'de';
  accountingSoftware: string;
  customSoftwareName?: string;
  rowDisplayOptions?: RowDisplayOption[];
};

type InitFormParams = {
  template?: Template;
  isDoubleOrSingleEntry: boolean;
  customFields: CustomField[] | RawCustomField[];
  isAnalyticalSplitActivated: boolean;
  othersLabel: string;
};

export const getFormInitialValues = ({
  template,
  isDoubleOrSingleEntry,
  customFields,
  isAnalyticalSplitActivated,
  othersLabel,
}: InitFormParams): FormValues => ({
  dateFormat: template?.dateFormat ?? ['DD', 'MM', 'YYYY'],
  dateDelimiter: template?.dateDelimiter ?? '-',
  decimalDelimiter: template?.decimalDelimiter ?? '.',
  columnDelimiter: template?.columnDelimiter ?? ';',
  fileTypeEncoding: toFileTypeEncoding({
    encoding: template?.encoding ?? 'utf-8',
    includeByteOrderMark: !!template?.includeByteOrderMark,
    format: template?.outputFormat ?? 'csv',
  }),
  name: template?.name ?? '',
  shouldOutputHeaders: template?.shouldOutputHeaders ?? true,
  columns: isDoubleOrSingleEntry
    ? (template?.columns ?? [])
    : getRequiredAnalyticalFields(
        template?.columns ?? [],
        customFields,
        isAnalyticalSplitActivated,
      ),
  language:
    template?.language == null || template?.language === 'en-GB'
      ? 'en'
      : template?.language,
  accountingSoftware:
    (template?.accountingSoftware === 'Others'
      ? othersLabel
      : template?.accountingSoftware) ?? '',
  customSoftwareName: template?.customSoftwareName,
  rowDisplayOptions: template?.rowDisplayOptions,
});

export const validate = (values: FormValues, t: TGlobalFunctionTyped) => {
  const othersLabel = t('exports.others');
  const availableAccountingSoftwares =
    getAvailableAccountingSoftwares(othersLabel);

  // TODO: Add the "close-match" validation / update logic somewhere either here or in another formik hook

  const errors: FormikErrors<typeof values> = {};

  if (values.name.trim() === '') {
    errors.name = t('exports.errors.invalidTemplateName');
  }
  const accountingSoftware = values.accountingSoftware?.trim();
  if (
    !accountingSoftware ||
    !availableAccountingSoftwares
      .map(({ label }) => label)
      .includes(accountingSoftware)
  ) {
    errors.accountingSoftware = t('exports.errors.invalidAccountingSoftware');
  }
  if (
    accountingSoftware === othersLabel &&
    !values.customSoftwareName?.trim()
  ) {
    errors.customSoftwareName = t('exports.errors.invalidAccountingSoftware');
  }

  return errors;
};
