import {
  Callout,
  colors,
  Icon,
  Label,
  AmountInput,
  RadioField,
} from '@dev-spendesk/grapes';
import { type FormikErrors } from 'formik';
import React from 'react';
import { Trans } from 'react-i18next';

import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { AutocompleteMultipleSearch } from 'src/core/common/components/AutocompleteSearch';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import type {
  ApprovalPolicyFormValues,
  ApprovalPolicyParamsFormValues,
} from '../../formValues';
import {
  type SpendingType,
  getReadableSpendingType,
  getReadableSpendingTypes,
  getValidSpendingTypes,
} from '../../spendingTypes';

import './ApprovalPolicyParamsContent.css';

type Props = {
  values: ApprovalPolicyParamsFormValues;
  errors: FormikErrors<ApprovalPolicyFormValues>;
  setFieldValue(field: string, value: string | string[] | number): void;
};

export const ApprovalPolicyParamsContent = ({
  values,
  errors,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation('global');
  const companyCurrency = useCompanyCurrency();
  const validSpendingTypes = getValidSpendingTypes({
    isPlasticCardEnabled: useFeature(FEATURES.PLASTIC_CARDS),
    isExpenseClaimEnabled: useFeature(FEATURES.EXPENSE_CLAIMS),
    isInvoiceEnabled: useFeature(FEATURES.SUBMIT_MY_INVOICE),
    isPurchaseOrderEnabled: useFeature(FEATURES.PURCHASE_ORDERS_ACTIVATED),
  });

  return (
    <div className="ApprovalPolicyParamsContent">
      <RadioField
        name="approvalNeeded"
        id="always"
        value="always"
        label={t('approvalPolicy.details.radioLabelAlways')}
        isChecked={values.approvalNeeded === 'always'}
        onChange={() => setFieldValue('approvalNeeded', 'always')}
      />
      <RadioField
        name="approvalNeeded"
        id="sometimes"
        value="sometimes"
        label={t('approvalPolicy.details.radioLabelSometimes')}
        isChecked={values.approvalNeeded === 'sometimes'}
        onChange={() => {
          setFieldValue('approvalNeeded', 'sometimes');
          setFieldValue('amountPerMonth', 0);
          setFieldValue('amountPerTransaction', 0);
        }}
      />
      {values.approvalNeeded === 'sometimes' && (
        <>
          <div className="ApprovalPolicyParamsContent__field__addition">
            <div className="ApprovalPolicyParamsContent__field__addition__text">
              <Trans
                i18nKey="approvalPolicy.details.addition"
                components={[
                  <span key="1">-</span>,
                  <AmountInput
                    key="amountPerTransaction"
                    fit="parent"
                    currency={{
                      key: companyCurrency,
                      label: companyCurrency,
                    }}
                    value={values.amountPerTransaction ?? null}
                    className="ApprovalPolicyParamsContent__field__money-input"
                    onChange={(event) => {
                      if (isSafeIntegerForEzMoney(event.target.valueAsNumber)) {
                        setFieldValue(
                          'amountPerTransaction',
                          event.target.valueAsNumber,
                        );
                      }
                    }}
                  />,
                  <span key="2">-</span>,
                  <AmountInput
                    key="amountPerMonth"
                    fit="parent"
                    currency={{
                      key: companyCurrency,
                      label: companyCurrency,
                    }}
                    value={values.amountPerMonth ?? null}
                    className="ApprovalPolicyParamsContent__field__money-input"
                    onChange={(event) => {
                      if (isSafeIntegerForEzMoney(event.target.valueAsNumber)) {
                        setFieldValue(
                          'amountPerMonth',
                          event.target.valueAsNumber,
                        );
                      }
                    }}
                  />,
                  <span key="3">-</span>,
                ]}
              />
            </div>
            <Label
              className="ApprovalPolicyParamsContent__field__label body-m"
              label={t('approvalPolicy.details.spendingType')}
            />
            <AutocompleteMultipleSearch
              fit="parent"
              placeholder={t('approvalPolicy.members.selectTypes')}
              options={transformToSpendingTypeOptions(validSpendingTypes, t)}
              values={transformToSpendingTypeOptions(values.spendingTypes, t)}
              translations={{
                selectAll: t('approvalPolicy.members.selectAll'),
                selected: getReadableSpendingTypes(values.spendingTypes, t),
              }}
              className="ApprovalPolicyParamsContent__field__autocomplete"
              onSelect={(selectedValues) =>
                setFieldValue(
                  'spendingTypes',
                  selectedValues.map((value) => value.key) as string[],
                )
              }
            />
            <div className="ApprovalPolicyParamsContent__field__hint body-s">
              <Icon
                name="circle-information"
                size="s"
                color={colors.contentSecondaryBgPrimary}
                className="ApprovalPolicyParamsContent__field__hint__icon"
              />
              <span>{t('approvalPolicy.details.spendingTypeInfo')}</span>
            </div>
          </div>
          {errors.amountPerMonth && (
            <Callout
              variant="alert"
              title={errors.amountPerMonth}
              className="ApprovalPolicyDetailsContent__field__error"
            />
          )}
          {errors.amountPerTransaction && (
            <Callout
              variant="alert"
              title={errors.amountPerTransaction}
              className="ApprovalPolicyDetailsContent__field__error"
            />
          )}
          {errors.spendingTypes && (
            <Callout
              variant="alert"
              title={errors.spendingTypes as string}
              className="ApprovalPolicyDetailsContent__field__error"
            />
          )}
        </>
      )}
      <RadioField
        name="approvalNeeded"
        id="never"
        value="never"
        label={t('approvalPolicy.details.radioLabelNever')}
        isChecked={values.approvalNeeded === 'never'}
        onChange={() => setFieldValue('approvalNeeded', 'never')}
      />
    </div>
  );
};

const transformToSpendingTypeOptions = (
  spendingTypes: Readonly<SpendingType[]>,
  translator: TGlobalFunctionTyped,
): { key: SpendingType; label: string }[] => {
  return spendingTypes.map((spendingType) => ({
    key: spendingType,
    label: getReadableSpendingType(spendingType, translator),
  }));
};

const isSafeIntegerForEzMoney = (value: number): boolean => {
  return Number.isSafeInteger(value * 1000);
};
