// When top notifications are displayed, we must the subtract the
// notifications container height from the actions container position,
// otherwise the container is displayed offscreen.
export const getTopNotificationOffset = (): number => {
  let topNotificationsOffset = 0;

  topNotificationsOffset +=
    document.querySelector('.HeaderLoadNotif')?.getBoundingClientRect()
      .height ?? 0;

  topNotificationsOffset +=
    document.querySelector('.Supervision')?.getBoundingClientRect().height ?? 0;

  return topNotificationsOffset;
};
