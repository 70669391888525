import {
  DATE_FORMAT,
  type IconName,
  Table,
  Tag,
  type TagVariant,
  colors,
  Icon,
  SkeletonTable,
  SkeletonAvatar,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { SupplierLogo } from 'src/core/common/components/SupplierLogo';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { formatMoney } from 'src/core/utils/money';

import { LatePaymentsSectionEmpty } from './LatePaymentsSectionEmpty';
import styles from './styles.module.css';
import { usePaymentsToCompleteQuery } from '../../hooks/usePaymentsToCompleteQuery';
import { useUserControlRuleQuery } from '../../hooks/userControlRuleQuery';
import { LatePaymentAnalytics } from '../LatePaymentAnalytics';

type PaymentToComplete = {
  id: string;
  type: 'singlePurchaseCard' | 'subscriptionCard' | 'physicalCard';
  description: string;
  transactionDescription: string;
  amountDeclared: number;
  currencyDeclared: string;
  amountBilled: number;
  currencyBilled: string;
  fxFeeAmount: number;
  supplier: {
    name: string;
    logoUrl: string;
  } | null;
  completionDeadline: {
    status: 'late' | 'urgent' | 'onTime';
    daysLeft: number;
  } | null;
  paidAt: Date | null;
  createdAt: Date;
};

export const LatePaymentsSection = () => {
  const { t, localeFormat } = useTranslation('global');
  const companyCurrency = useCompany().currency;
  const history = useHistory();
  const companyId = useCompanyId();

  const controlRuleQueryState = useUserControlRuleQuery();
  const paymentsListQueryState = useQueryStates({
    states: {
      incompletePaymentsResult: usePaymentsToCompleteQuery({
        companyCurrency,
      }),
      controlRule: controlRuleQueryState,
    },
    reshapeData({ incompletePaymentsResult, controlRule }) {
      const latePayments = incompletePaymentsResult.payments.filter(
        (payment) => payment.completionDeadline?.status === 'late',
      );
      return {
        payments: incompletePaymentsResult.payments,
        controlRule,
        latePaymentsCount: latePayments.length,
        isOptimistic: incompletePaymentsResult.hasNextPage,
      };
    },
  });

  return (
    <div className="box col-span-2 px-16 py-24 shadow-xs">
      <QuerySuspense
        queryState={paymentsListQueryState}
        loading={
          <SkeletonTable
            columns={[
              {
                cell: (
                  <>
                    <SkeletonAvatar />
                    <SkeletonText width="80%" />
                  </>
                ),
                header: (
                  <>
                    <SkeletonAvatar />
                    <SkeletonText width="50%" />
                  </>
                ),
              },
            ]}
            withHeader
          />
        }
        fallback={(error) => (
          <QueryError queryError={error} componentType="Callout" />
        )}
      >
        {(data) => {
          if (data.payments.length === 0) {
            return <LatePaymentsSectionEmpty />;
          }

          return (
            <>
              <div className="mb-8 text-primary title-l">
                {t('homepage.paymentsList.tableTitle')}
              </div>
              {data.latePaymentsCount !== 0 && (
                <LatePaymentAnalytics
                  incompletePaymentsLimit={
                    data.controlRule.incompletePaymentsLimit
                  }
                  latePaymentsCount={data.latePaymentsCount}
                  isLate={
                    data.latePaymentsCount >=
                    data.controlRule.incompletePaymentsLimit
                  }
                  isOptimistic={data.isOptimistic}
                  className="mb-24 mt-24"
                />
              )}
              <div className="-mx-16 -mb-24">
                <Table
                  className={styles.table}
                  columns={[
                    {
                      header: '',
                      id: 'supplier',
                      renderCell: (row) => (
                        <div className="flex items-center gap-8">
                          <SupplierLogo
                            className="shrink-0"
                            name={row.supplier?.name}
                          />
                          <div>
                            <div className="text-primary body-m">
                              {row.supplier?.name ??
                                t('homepage.paymentsList.row.missingSupplier')}
                            </div>
                            <div className="flex items-center gap-4">
                              <div className="max-w-[130px] truncate text-primary body-s">
                                {row.description ?? row.transactionDescription}
                              </div>
                              <Icon
                                className="shrink-0"
                                color={colors.contentDecorativeIcon}
                                name={getIconFromPaymentType(row.type)}
                              />
                            </div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      header: '',
                      id: 'amount',
                      renderCell: (row) =>
                        formatMoney(row.amountDeclared, row.currencyDeclared),
                    },
                    {
                      header: '',
                      id: 'date',
                      renderCell: (row) => {
                        if (row.paidAt) {
                          return localeFormat(
                            new Date(row.paidAt),
                            DATE_FORMAT.MEDIUM,
                          );
                        }
                        return <div />;
                      },
                    },
                    {
                      align: 'right',
                      header: '',
                      id: 'tag',
                      renderCell: (row) => (
                        <div>
                          {row.completionDeadline && (
                            <Tag
                              variant={getTagVariant(
                                row.completionDeadline.status,
                              )}
                            >
                              {getTagText(
                                row.completionDeadline?.status,
                                row.completionDeadline?.daysLeft,
                                t,
                              )}
                            </Tag>
                          )}
                        </div>
                      ),
                      width: '20%',
                    },
                  ]}
                  data={data.payments}
                  onRowClick={(row) => {
                    history.push(
                      routeFor(routes.PAYMENTS_ALL.path, {
                        company: companyId,
                        id: row.id,
                      }),
                    );
                  }}
                />
              </div>
            </>
          );
        }}
      </QuerySuspense>
    </div>
  );
};

const getTagVariant = (type: 'late' | 'urgent' | 'onTime'): TagVariant => {
  switch (type) {
    case 'late':
      return 'alert';
    case 'urgent':
      return 'warning';
    default:
      return 'info';
  }
};

const getTagText = (
  status: string,
  daysLeft: number,
  t: TGlobalFunctionTyped,
) => {
  if (status === 'late') {
    return t('homepage.paymentsList.row.overdue');
  }
  return t('homepage.paymentsList.row.dueIn', { count: daysLeft });
};

const getIconFromPaymentType = (type: PaymentToComplete['type']): IconName => {
  switch (type) {
    case 'singlePurchaseCard':
      return 'card';
    case 'physicalCard':
      return 'card';
    case 'subscriptionCard':
      return 'card-rounded-arrows';
    default:
      return 'card';
  }
};
