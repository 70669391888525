import { type Edge } from '@xyflow/react';
import { v4 as uuid } from 'uuid';

import {
  initialLabelNode,
  getInitialEdge,
  getStandardApprovalFlowNode,
} from './initialNodes';
import {
  type Dimension,
  type ApprovalWorkflow,
  type ApprovalWorkflowNode,
} from '../approvalWorkflow';
import { type CustomNode } from '../node';

export const transformApprovalWorkflowToNodesAndEdges = (
  approvalWorkflow: ApprovalWorkflow,
) => {
  const workflowRootNode = approvalWorkflow.definition;

  const standardApprovalFlowNode = getStandardApprovalFlowNode(
    approvalWorkflow.id,
    workflowRootNode.outcome.approvalSchemeId,
  );

  const { nodes, edges } = collectNodesAndEdges(
    workflowRootNode,
    initialLabelNode.id,
  );

  return {
    nodes: [...nodes, standardApprovalFlowNode, initialLabelNode],
    edges: [
      ...edges,
      getInitialEdge(initialLabelNode.id, standardApprovalFlowNode.id),
    ],
  };
};

const collectNodesAndEdges = (
  parentWorkflowNode: ApprovalWorkflowNode,
  parentId: string | null = null,
) => {
  if (!parentWorkflowNode.branch) {
    return { nodes: [], edges: [] };
  }

  const collectedNodes: CustomNode[] = [];
  const collectedEdges: Edge[] = [];
  const { nodes: childNodes } = parentWorkflowNode.branch;
  const dimensionName = parentWorkflowNode.branch.dimension;

  childNodes.forEach((node) => {
    const newNodeId = `node-${uuid()}`;
    const newNode = nodeTemplate(
      newNodeId,
      node.outcome?.approvalSchemeId,
      dimensionName,
      node.values ?? ['Default'],
    );

    collectedNodes.push(newNode);
    if (parentId) {
      collectedEdges.push(edgeTemplate(parentId, newNodeId));
    }

    const { nodes: nestedChildNodes, edges: nestedChildEdges } =
      collectNodesAndEdges(node, newNodeId);
    collectedNodes.push(...nestedChildNodes);
    collectedEdges.push(...nestedChildEdges);
  });

  return { nodes: collectedNodes, edges: collectedEdges };
};

const nodeTemplate = (
  id: string,
  schemeId: string | undefined,
  dimensionName: Dimension,
  values?: string[],
): CustomNode => ({
  id,
  type: 'baseNode',
  data: {
    schemeId,
    dimensionName,
    values,
  },
  position: { x: 0, y: 0 },
});

const edgeTemplate = (sourceId: string, targetId: string): Edge => ({
  id: `edge-${sourceId}-${targetId}`,
  source: sourceId,
  target: targetId,
  type: 'labelEdge',
  data: {
    labelText: 'If',
    labelVariant: 'purple',
  },
});
