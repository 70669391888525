import { Tooltip } from '@dev-spendesk/grapes';
import React, { useMemo } from 'react';

import { Gauge, type Segment } from 'common/components/Gauge';

import {
  type GaugeMilestone,
  type GaugeSegment,
  getSegmentWidth,
} from './gauge-segment';

type Props<
  SegmentIdentifier extends string,
  MilestoneIdentifier extends string,
> = {
  segments: GaugeSegment<SegmentIdentifier>[];
  milestones?: GaugeMilestone<MilestoneIdentifier>[];
  onHoveredSegmentChange?: (key: SegmentIdentifier | undefined) => void;
  hasAnimation?: boolean;
};

export const EnrichedGauge = <
  SegmentIdentifier extends string,
  MilestoneIdentifier extends string,
>({
  segments,
  milestones = undefined,
  onHoveredSegmentChange = undefined,
  hasAnimation = false,
}: Props<SegmentIdentifier, MilestoneIdentifier>) => {
  const gaugeTotalValue = useMemo(
    () => segments.reduce((amount, { value }) => amount + value, 0),
    [segments],
  );

  const gaugeSegments: Segment[] = useMemo(() => {
    return segments.map((seg) => {
      const percentage = (seg.value / gaugeTotalValue) * 100;
      const events = onHoveredSegmentChange && {
        onMouseEnter: () => onHoveredSegmentChange(seg.key),
        onMouseLeave: () => onHoveredSegmentChange(undefined),
      };

      return {
        ...seg,
        width: getSegmentWidth(percentage),
        fill: seg.fillColor,
        ...events,
      } satisfies Segment;
    });
  }, [segments]);

  const renderMilestone = (milestone: GaugeMilestone) => {
    let percentage = (milestone.value / gaugeTotalValue) * 100;
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }

    const milestoneLine = (
      <div
        className="absolute -top-4 mx-[-1px] my-0 h-24 w-4 shrink-0 rounded after:absolute after:left-[-6px] after:h-full after:w-16 after:content-['']"
        data-testid="EnrichedGaugeMilestone"
        style={{
          backgroundColor: milestone.fillColor,
        }}
      />
    );

    return (
      <div
        key={milestone.key}
        style={{ position: 'absolute', left: `${percentage}%` }}
      >
        {milestone.tooltipContent ? (
          <Tooltip content={milestone.tooltipContent} triggerAsChild>
            {milestoneLine}
          </Tooltip>
        ) : (
          milestoneLine
        )}
      </div>
    );
  };

  return (
    <div className="relative flex w-full flex-row">
      <Gauge
        segments={gaugeSegments}
        bypassDefaultHoverBehaviour
        hasAnimation={hasAnimation}
      />
      {milestones?.map((milestone) => renderMilestone(milestone))}
    </div>
  );
};
