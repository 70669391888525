import { Tag } from '@dev-spendesk/grapes';
import React from 'react';

import { DropdownSwitcherContent } from 'src/core/common/components/DropdownSwitcherContent';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { sortByNameAsc } from 'src/core/common/utils/sortByNameAsc';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  type CompanyWalletSummary,
  isCreatingCompany,
  isInReviewCompany,
} from '../../../companyWalletSummary';
import { hasDetailedWalletSummary } from '../../../wallet/walletSummary';
import { CompanyDropdownLimitedAccess } from '../CompanyDropdownLimitedAccess';
import { CompanyDropdownWalletDetails } from '../CompanyDropdownWalletDetails';

import './CompanyDropdownContent.css';

type Props = {
  currentCompany: CompanyWalletSummary;
  otherCompanies: CompanyWalletSummary[];
  onCompanyClick(company: CompanyWalletSummary): void;
  openNewEntityButton: { label: string; onClick: () => void } | undefined;
  closeDropdown(): void;
};

export const CompanyDropdownWithBreakdown = ({
  currentCompany,
  otherCompanies,
  onCompanyClick,
  openNewEntityButton,
  closeDropdown,
}: Props) => {
  const { t } = useTranslation('global');

  const activeItem = {
    left: currentCompany.name,
    right:
      !isCreatingCompany(currentCompany) && currentCompany.totalAmount
        ? formatMonetaryValue(currentCompany.totalAmount)
        : undefined,
    details: getActiveItemDetails(currentCompany, closeDropdown),
  };

  return (
    <DropdownSwitcherContent
      activeItem={activeItem}
      items={getDropdownItems(otherCompanies, onCompanyClick, t)}
      button={openNewEntityButton}
    />
  );
};

const getDropdownItems = (
  companies: CompanyWalletSummary[],
  onCompanyClick: (company: CompanyWalletSummary) => void,
  t: TGlobalFunctionTyped,
) => {
  return sortByNameAsc(companies).map((company) => {
    let right;
    let onClick: (() => void) | undefined = () => onCompanyClick(company);

    if (isCreatingCompany(company)) {
      right = (
        <Tag variant="purple" className="CompanyDropdownContent__tag">
          {t('wallet.warningStepsRemaining')}
        </Tag>
      );
    } else if (
      isInReviewCompany(company) &&
      company.bankingProvider === 'sfs'
    ) {
      right = (
        <Tag variant="purple" className="CompanyDropdownContent__tag">
          {t('wallet.companyInReview')}
        </Tag>
      );
      onClick = undefined;
    } else {
      right = company.totalAmount && formatMonetaryValue(company.totalAmount);
    }
    return {
      id: company.id,
      left: company.name,
      right,
      onClick,
    };
  });
};

const getActiveItemDetails = (
  company: CompanyWalletSummary,
  closeDropdown: () => void,
) => {
  if (isInReviewCompany(company)) {
    return <CompanyDropdownLimitedAccess />;
  }

  if (!hasDetailedWalletSummary(company)) {
    return null;
  }

  return (
    <CompanyDropdownWalletDetails
      company={company}
      onRedirectToWalletSettings={closeDropdown}
    />
  );
};
