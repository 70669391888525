import { SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';
import { useTimeout } from 'react-use';

type Props = {
  count: number;
};

/**
 * TODO: this is an experiment, we may make it more generic later.
 *
 * This loader will display itself only after a small delay.
 * If the operation completes fast we don't want to display a loader for only a
 * few milliseconds because it will look glitchy.
 * Research suggests than any operation completed in under 100ms is perceived as
 * instantaneous by users (see: https://www.nngroup.com/articles/response-times-3-important-limits/)
 */
export const PaymentsBatchesLoader = ({ count }: Props) => {
  // Ideal timeout is 100ms but we use 80ms to let some time for the browser to
  // update the screen afterwards.
  const [isReady] = useTimeout(80);

  if (!isReady()) {
    return null;
  }

  return (
    <ul>
      {Array.from({ length: count }, (_1, key1) => (
        <li key={key1} className="w-[892px]">
          <SkeletonText className="my-4" size="l" width="70px" />
          <ul>
            {Array.from({ length: 3 }, (_2, key2) => (
              <li className="box mb-16 p-16" key={key2}>
                <div className="mb-8 flex items-center justify-between">
                  <SkeletonText size="l" width="160px" />
                  <SkeletonText size="l" width="70px" />
                </div>
                <div className="flex items-center justify-between">
                  <SkeletonText size="s" width="250px" />
                  <SkeletonText size="s" width="150px" />
                </div>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};
