import { IconButton, Skeleton, SkeletonText } from '@dev-spendesk/grapes';
import {
  useHistory,
  useParams,
  useLocation,
  matchPath,
} from 'react-router-dom';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';

export const AccountPayableSuppliersPanelLoader = () => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const location = useLocation();
  const { company } = useParams();
  const matchSupplierDetails = matchPath<{ supplierId: string }>(
    location.pathname,
    {
      path: routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIER_DETAIL.path,
      exact: true,
      strict: false,
    },
  );
  const matchAccountPayableSuppliersDetail = matchPath<{
    accountPayableId: string;
  }>(location.pathname, {
    path: routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS_DETAIL.path,
    exact: true,
    strict: false,
  });

  const handleSuppliersPanelClose = () => {
    if (matchSupplierDetails) {
      history.push({
        pathname: routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
          company,
        }),
        search: location.search,
      });
    }
    if (matchAccountPayableSuppliersDetail) {
      history.push({
        pathname: routeFor(routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path, {
          accountPayableId:
            matchAccountPayableSuppliersDetail?.params.accountPayableId ?? '',
          company,
        }),
        search: location.search,
      });
    }
  };

  return (
    <div className="box h-full w-[368px] overflow-y-scroll p-0">
      <div className="p-24">
        <div className="mb-16 flex items-center justify-between">
          <SkeletonText size="l" width="50%" />
          <IconButton
            iconName="cross"
            onClick={() => handleSuppliersPanelClose()}
            variant="tertiaryNeutral"
            hasNegativeMargins
            aria-label={t('misc.close')}
          />
        </div>
        <div className="flex items-center gap-8">
          <Skeleton width="var(--unit-32)" height="var(--unit-32)" />
          <div className="w-[80%]">
            <SkeletonText size="l" className="mb-8 block" width="70%" />
            <SkeletonText size="s" className="block" width="70%" />
          </div>
        </div>
      </div>
      <div className="separator" />
      <div className="p-24">
        <SkeletonText size="l" className="mb-32" width="30%" />
        <div className="mb-24 flex justify-between">
          <SkeletonText size="l" width="40%" />
          <SkeletonText size="l" width="50%" />
        </div>
        <div className="mb-32 flex justify-between">
          <SkeletonText size="l" width="40%" />
          <SkeletonText size="l" width="50%" />
        </div>
        <div className="separator" />
      </div>
    </div>
  );
};
