import { Icon } from '@dev-spendesk/grapes';

import { useJiraLink } from './useJiraLink';

const DEFAULT_PORTAL_URL =
  'https://spendesk.atlassian.net/servicedesk/customer/portal/2/group/4/create/632';

export const JiraIssueReporter = () => {
  const defaultLink = useJiraLink(DEFAULT_PORTAL_URL);

  if (defaultLink === null) {
    return null;
  }

  return (
    <li>
      <button
        onClick={() => {
          window.open(defaultLink, '_blank');
        }}
        className="navigation-item h-40"
        type="button"
      >
        <Icon
          name="bell-desk"
          aria-hidden="true"
          size="m"
          className="shrink-0"
        />
        <span className="navigation-toggle-display grow">Report an issue</span>
      </button>
    </li>
  );
};
