import { EmptyState } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';

import './MaintenanceContainer.css';

export const MaintenanceContainer = () => {
  const { t } = useTranslation('errors');

  return (
    <div className="page__container">
      <EmptyState
        className="Maintenance"
        iconName="circle-information"
        iconVariant="info"
        title={t('maintenance.title')}
        subtitle={t('maintenance.subtitle')}
      />
    </div>
  );
};
