/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer } from '@reduxjs/toolkit';
import includes from 'lodash/includes';
import merge from 'lodash/merge';

import { UPDATE_SUCCESS as REQUEST_UPDATE_SUCCESS } from 'modules/requests/redux/actions';
import * as customFieldsTypes from 'src/core/actionTypes/customFields';
import * as groupsTypes from 'src/core/actionTypes/groups';
import PerDiemModule from 'src/core/modules/per-diem';
import { enrichRequestWithExpenseCategory } from 'src/core/utils/entities/request';
import { getUrlParams } from 'src/core/utils/urlParser';

import * as types from './actionTypes';
import * as actions from './actions';
import { convertFiltersFromUrl } from '../../utils/convertFiltersForApi';

export type Stats = {
  all: number;
  card_load: number;
  credit_notes: number;
  drafts: number;
  expenses: number;
  invoices: number;
  mine: number;
  purchase_order: number;
  purchases: number;
  single_purchases: number;
  subscription_increase: number;
  subscriptions: number;
  to_approve: number;
};

type Requests = {
  invoices: unknown[];
  purchases: any[];
  expense_claims: any[];
  mileage_allowances: any[];
  per_diem_allowances: any[];
  purchase_orders: any[];
  mine_expenses: any[];
  mine_purchases: any[];
  mine_purchase_orders: any[];
  mine_invoices: any[];
  team_expenses: any[];
  team_purchases: any[];
  team_invoices: any[];
  mine_mileage_allowances: any[];
  team_mileage_allowances: any[];
  mine_per_diem_allowances: any[];
  team_per_diem_allowances: any[];
};

export type RequestsSections = {
  stats: Record<string, any>;
  requests: Requests;
};

type Behavior = {
  isLoading: boolean;
  errors: any;
};

type SearchFilters = {
  search: string | undefined;
  period: string | undefined;
};

type RequestState = {
  stats: Stats;
  request:
    | {
        id?: string | undefined;
        custom_fields?:
          | { field: { id: string }; value: { id: string } }[]
          | undefined;
      }
    | undefined
    | null;
  requestsSections: RequestsSections;
  draftRequests: any[];
  draftRequest: any;
  customFields: any[];
  groups: any[];
  behavior: Behavior;
  searchFilters: SearchFilters;
  expenseCategoryCustomFieldId: string;
};

export const initialFiltersState = {
  search: undefined,
  period: undefined,
  ...convertFiltersFromUrl(getUrlParams(window.location.search)),
};

const initialState: RequestState = {
  stats: {
    all: 0,
    card_load: 0,
    credit_notes: 0,
    drafts: 0,
    expenses: 0,
    invoices: 0,
    mine: 0,
    purchase_order: 0,
    purchases: 0,
    single_purchases: 0,
    subscription_increase: 0,
    subscriptions: 0,
    to_approve: 0,
  },
  request: null,
  requestsSections: {
    stats: {},
    requests: {
      invoices: [],
      purchases: [],
      expense_claims: [],
      mileage_allowances: [],
      per_diem_allowances: [],
      purchase_orders: [],
      mine_expenses: [],
      mine_purchases: [],
      mine_purchase_orders: [],
      mine_invoices: [],
      team_expenses: [],
      team_purchases: [],
      team_invoices: [],
      mine_mileage_allowances: [],
      team_mileage_allowances: [],
      mine_per_diem_allowances: [],
      team_per_diem_allowances: [],
    },
  },
  draftRequests: [],
  draftRequest: null,
  customFields: [],
  groups: [],
  behavior: {
    isLoading: false,
    errors: null,
  },
  searchFilters: initialFiltersState,
  expenseCategoryCustomFieldId: '',
};

// Update or delete existing request among sections of requests
const updateRequestAmongSection = (
  request: { id?: string } = {},
  requests: Requests,
): Requests => {
  if (!request) {
    return requests;
  }

  return Object.keys(requests).reduce(
    (accumulator, requestKey) => {
      // @ts-expect-error requestKey is a key of requests
      const sectionReqs = requests[requestKey].map((r) => {
        if (r.id === request.id) {
          return request;
        }
        return r;
      });

      return { ...accumulator, [requestKey]: sectionReqs };
    },
    {
      invoices: [],
      purchases: [],
      expense_claims: [],
      mileage_allowances: [],
      per_diem_allowances: [],
      purchase_orders: [],
      mine_expenses: [],
      mine_purchases: [],
      mine_purchase_orders: [],
      mine_invoices: [],
      team_expenses: [],
      team_purchases: [],
      team_invoices: [],
      mine_mileage_allowances: [],
      team_mileage_allowances: [],
      mine_per_diem_allowances: [],
      team_per_diem_allowances: [],
    },
  );
};

const fetchRequestsLoading = (state: RequestState) =>
  merge({}, state, { behavior: { isLoading: true, errors: null } });

const fetchRequestsSuccess = (
  state: RequestState,
  action: { payload: { requestsSections: RequestsSections } },
) => {
  const { requestsSections } = action.payload;
  return {
    ...state,
    requestsSections,
    behavior: { ...state.behavior, isLoading: false, errors: null },
  };
};

const fetchRequestsFailure = (state: RequestState, action: { payload: any }) =>
  merge({}, state, {
    errors: action.payload,
    behavior: { errors: action.payload, isLoading: false },
  });

const fetchRequestsSectionsStatsSuccess = (
  state: RequestState,
  action: { payload: { sectionsStats: Stats } },
): RequestState => {
  const { sectionsStats } = action.payload;
  return {
    ...state,
    requestsSections: {
      ...state.requestsSections,
      stats: sectionsStats,
    },
  };
};

const updateRequestSuccess = (
  state: RequestState,
  action: { payload: { id: string } },
): RequestState => {
  const updatedRequest = action.payload;
  let { request } = state;
  // Maybe update the currently opened request if it's still the same
  if (request && updatedRequest.id === request.id) {
    request = {
      ...request,
      ...updatedRequest,
    };
  }

  return {
    ...state,
    requestsSections: {
      ...state.requestsSections,
      requests: updateRequestAmongSection(
        request ?? undefined,
        state.requestsSections.requests,
      ),
    },
  };
};

const fetchDraftRequestsLoading = (state: RequestState) => ({
  ...state,
  behavior: { ...state.behavior, isLoading: true },
});
const fetchDraftRequestsSuccess = (
  state: RequestState,
  action: { payload: any[] },
): RequestState => {
  const draftRequests = action.payload;
  return {
    ...state,
    draftRequests,
    behavior: { ...state.behavior, isLoading: false },
  };
};
const fetchDraftRequestsFailure = (
  state: RequestState,
  action: { payload: any },
) => ({
  ...state,
  behavior: { ...state.behavior, isLoading: false, errors: action.payload },
});

const addRequestInRequestsList = (
  requests: unknown[] = [],
  request: { id: string; state: string },
) => {
  return request.state === 'pending'
    ? [request, ...requests]
    : [...requests, request];
};

const addRequestLocally = (
  state: RequestState,
  action: { payload: { id: string; type: string; state: string } },
): RequestState => {
  const request = action.payload;
  const { requests } = state.requestsSections;

  const newRequests: {
    purchases?: unknown[];
    mine_purchases?: unknown[];
    mine_expenses?: unknown[];
    expense_claims?: unknown[];
    invoices?: unknown[];
    mine_invoices?: unknown[];
    mileage_allowances?: unknown[];
    mine_mileage_allowances?: unknown[];
    per_diem_allowances?: unknown[];
    mine_per_diem_allowances?: unknown[];
    purchase_orders?: unknown[];
    mine_purchase_orders?: unknown[];
  } = {};
  if (includes(['single_purchase', 'subscription'], request.type)) {
    newRequests.purchases = addRequestInRequestsList(
      state.requestsSections.requests.purchases,
      request,
    );
    newRequests.mine_purchases = addRequestInRequestsList(
      state.requestsSections.requests.mine_purchases,
      request,
    );
  } else {
    switch (request.type) {
      case 'expense': {
        newRequests.expense_claims = addRequestInRequestsList(
          state.requestsSections.requests.expense_claims,
          request,
        );
        newRequests.mine_expenses = addRequestInRequestsList(
          state.requestsSections.requests.mine_expenses,
          request,
        );
        break;
      }
      case 'invoice': {
        newRequests.invoices = addRequestInRequestsList(
          state.requestsSections.requests.invoices,
          request,
        );
        newRequests.mine_invoices = addRequestInRequestsList(
          state.requestsSections.requests.mine_invoices,
          request,
        );

        break;
      }
      case 'mileage_allowance': {
        newRequests.mileage_allowances = addRequestInRequestsList(
          state.requestsSections.requests.mileage_allowances,
          request,
        );
        newRequests.mine_mileage_allowances = addRequestInRequestsList(
          state.requestsSections.requests.mine_mileage_allowances,
          request,
        );

        break;
      }
      case 'per_diem_allowance': {
        newRequests.per_diem_allowances = addRequestInRequestsList(
          state.requestsSections.requests.per_diem_allowances,
          request,
        );
        newRequests.mine_per_diem_allowances = addRequestInRequestsList(
          state.requestsSections.requests.mine_per_diem_allowances,
          request,
        );

        break;
      }
      case 'purchase_order': {
        newRequests.purchase_orders = addRequestInRequestsList(
          state.requestsSections.requests.purchase_orders,
          request,
        );
        newRequests.mine_purchase_orders = addRequestInRequestsList(
          state.requestsSections.requests.mine_purchase_orders,
          request,
        );

        break;
      }
      default: {
        return state;
      }
    }
  }

  return {
    ...state,
    requestsSections: {
      ...state.requestsSections,
      requests: {
        ...requests,
        ...newRequests,
      },
    },
  };
};

const updateRequestLocally = (
  state: RequestState,
  action: { payload: any },
): RequestState => {
  const request = action.payload;

  const currentEnrichedRequest = enrichRequestWithExpenseCategory(
    request,
    state.expenseCategoryCustomFieldId,
  );

  return {
    ...state,
    request: currentEnrichedRequest,
    requestsSections: {
      ...state.requestsSections,
      requests: updateRequestAmongSection(
        request,
        state.requestsSections.requests,
      ),
    },
  };
};
const removeRequestLocally = (
  state: RequestState,
  action: { payload: string; type: string },
) => {
  const requestToDelete = action.payload;

  const newRequests: Requests = {
    invoices: [],
    purchases: [],
    expense_claims: [],
    mileage_allowances: [],
    per_diem_allowances: [],
    purchase_orders: [],
    mine_expenses: [],
    mine_purchases: [],
    mine_purchase_orders: [],
    mine_invoices: [],
    team_expenses: [],
    team_purchases: [],
    team_invoices: [],
    mine_mileage_allowances: [],
    team_mileage_allowances: [],
    mine_per_diem_allowances: [],
    team_per_diem_allowances: [],
  };

  const requests = state?.requestsSections?.requests;
  if (!requests) {
    return state;
  }

  for (const section in requests) {
    const sectionReqs = requests[section as keyof Requests];

    newRequests[section as keyof Requests] = sectionReqs.filter(
      (request: any) => request.id !== requestToDelete,
    );
  }

  return {
    ...state,
    requestsSections: {
      ...state.requestsSections,
      requests: newRequests,
    },
  };
};
const fetchCustomFieldsLoading = (state: RequestState) => ({
  ...state,
  hasFetchingCustomFieldsError: undefined,
});
const fetchCustomFieldsSuccess = (
  state: RequestState,
  action: { payload: { customFields: unknown[] } },
) => ({
  ...state,
  customFields: action.payload.customFields,
  hasFetchingCustomFieldsError: undefined,
});
const fetchCustomFieldsFailure = (state: RequestState) => ({
  ...state,
  hasFetchingCustomFieldsError: true,
});
const setExpenseCategoryValues = (
  state: RequestState,
  action: { payload?: unknown[] },
) => {
  return {
    ...state,
    expenseCategoryValues: action.payload ?? [],
  };
};
const setExpenseCategoryCustomField = (
  state: RequestState,
  action: { payload: any },
) => {
  return {
    ...state,
    expenseCategory: action.payload,
  };
};
const setExpenseCategoryCustomFieldId = (
  state: RequestState,
  action: { payload: any },
) => {
  return {
    ...state,
    expenseCategoryCustomFieldId: action.payload,
  };
};

const refreshRequestWithExpenseCategory = (state: RequestState) => {
  if (!state.request) {
    return state;
  }
  return {
    ...state,
    request: enrichRequestWithExpenseCategory(
      state.request,
      state.expenseCategoryCustomFieldId,
    ),
  };
};

const fetchGroupsSuccess = (
  state: RequestState,
  action: { payload: { groups: unknown[] } },
) => ({
  ...state,
  groups: action.payload.groups,
});

// Fetch requests stats
const fetchRequestsStatsSuccess = (
  state: RequestState,
  action: { payload: Stats },
) => ({
  ...state,
  stats: action.payload,
});

// Update draft requests locally
const addDraftRequestsLocally = (
  state: RequestState,
  action: { payload: any },
) => {
  const newDraftRequests = action.payload;

  return {
    ...state,
    draftRequests: [...newDraftRequests, ...state.draftRequests],
  };
};
const removeDraftRequestLocally = (
  state: RequestState,
  action: { payload: { id: string } },
) => ({
  ...state,
  draftRequests: state.draftRequests.filter(
    (draftRequest) => draftRequest.id !== action.payload.id,
  ),
});

const setRequestFilters = (
  state: RequestState,
  { payload }: { payload: any },
) => {
  return {
    ...state,
    searchFilters: {
      search: state.searchFilters.search,
      ...payload,
    },
  };
};

const setTextFilter = (state: RequestState, { payload }: { payload: any }) => {
  return {
    ...state,
    searchFilters: {
      ...state.searchFilters,
      search: payload,
    },
  };
};

const resetTextFilter = (state: RequestState) => {
  return {
    ...state,
    searchFilters: {
      ...state.searchFilters,
      search: undefined,
    },
  };
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(types.FETCH_REQUESTS_LOADING, fetchRequestsLoading)
    .addCase(actions.fetchRequestsSuccess, fetchRequestsSuccess)
    .addCase(actions.fetchRequestsFailure, fetchRequestsFailure)
    .addCase(
      actions.fetchRequestsSectionsStatsSuccess,
      fetchRequestsSectionsStatsSuccess,
    )
    .addCase(types.FETCH_DRAFT_REQUESTS_LOADING, fetchDraftRequestsLoading)
    .addCase(actions.fetchDraftRequestsSuccess, fetchDraftRequestsSuccess)
    .addCase(actions.fetchDraftRequestsFailure, fetchDraftRequestsFailure)
    .addCase(actions.updateRequestSuccess, updateRequestSuccess)
    .addCase(actions.addRequestLocally, addRequestLocally)
    .addCase(actions.updateRequestLocally, updateRequestLocally)
    .addCase(actions.removeRequestLocally, removeRequestLocally)
    .addCase(customFieldsTypes.FETCH_CF_LOADING, fetchCustomFieldsLoading)
    .addCase(customFieldsTypes.FETCH_CF_FAILURE, fetchCustomFieldsFailure)
    .addCase(
      customFieldsTypes.REFRESH_REQUEST_WITH_EXPENSE_CATEGORY,
      refreshRequestWithExpenseCategory,
    )
    .addCase(actions.fetchRequestsStatsSuccess, fetchRequestsStatsSuccess)
    .addCase(actions.addDraftRequestsLocally, addDraftRequestsLocally)
    .addCase(actions.removeDraftRequestLocally, removeDraftRequestLocally)
    .addCase(actions.setRequestFilters, setRequestFilters)
    .addCase(actions.setTextFilter, setTextFilter)
    .addCase(actions.resetTextFilter, resetTextFilter)
    // @ts-expect-error: quick expect error to fix the issue
    .addCase(customFieldsTypes.FETCH_CF_SUCCESS, fetchCustomFieldsSuccess)
    // @ts-expect-error: quick expect error to fix the issue
    .addCase(groupsTypes.FETCH_GROUPS_SUCCESS, fetchGroupsSuccess)
    // @ts-expect-error: quick expect error to fix the issue
    .addCase(REQUEST_UPDATE_SUCCESS, updateRequestSuccess)
    // @ts-expect-error: quick expect error to fix the issue
    .addCase(PerDiemModule.actions.CREATE_SUCCESS, addRequestLocally)
    // @ts-expect-error: quick expect error to fix the issue
    .addCase(
      customFieldsTypes.SET_EXPENSE_CATEGORY_CUSTOM_FIELD,
      setExpenseCategoryCustomField,
    )
    // @ts-expect-error: quick expect error to fix the issue
    .addCase(
      customFieldsTypes.SET_EXPENSE_CATEGORY_VALUES,
      setExpenseCategoryValues,
    )
    // @ts-expect-error: quick expect error to fix the issue
    .addCase(
      customFieldsTypes.SET_EXPENSE_CATEGORY_CUSTOM_FIELD_ID,
      setExpenseCategoryCustomFieldId,
    );
});
