import { DropdownItem } from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';

import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { type Option } from 'common/components/AutocompleteSearch/option';
import { useTranslation } from 'common/hooks/useTranslation';

import { getCodeWithAuxiliaryAccounts } from '../../../../../utils/accountPayable';
import { useHasAuxiliaryAccountsEnabled } from '../../../hooks/useHasAuxiliaryAccountsEnabled';
import { type SupplierAccount } from '../../types';

type Props = {
  accountPayables: SupplierAccount[];
  error: string | undefined;
  onAddOption: (label: string) => Promise<Option>;
  onChange: (value: string) => void;
  setError: (error: string | undefined) => void;
  value: string;
};

export const AccountPayableAutocompleteSearch = ({
  accountPayables,
  error,
  onAddOption,
  onChange,
  setError,
  value,
}: Props) => {
  const { t } = useTranslation('global');

  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();

  const reshapedAccountPayables = accountPayables.map((accountPayable) => ({
    key: accountPayable.id,
    label: auxiliaryAccountsEnabled
      ? getCodeWithAuxiliaryAccounts(accountPayable)
      : accountPayable.generalAccountCode,
  }));

  const selectedAccountPayable = reshapedAccountPayables.find(
    (accountPayable) => accountPayable.key === value,
  );

  return (
    <AutocompleteSearch
      name="accountPayableId"
      fit="parent"
      placeholder={t(
        'bookkeep.accountsPayable.createSupplier.accountPayablePlaceholder',
      )}
      options={reshapedAccountPayables}
      value={selectedAccountPayable}
      onSelect={(selectedOption) => {
        if (selectedOption?.key) {
          setError(undefined);
        }
        onChange(selectedOption?.key || '');
      }}
      onInputChanged={(newValue) => {
        if (newValue && error) {
          setError(undefined);
        }
      }}
      onAddOption={onAddOption}
      renderAddOption={(rawValue) => (
        <DropdownItem
          label={
            <Trans
              i18nKey="bookkeep.accountsPayable.createSupplier.addOption"
              values={{ account: rawValue }}
              components={[<span key="value" className="text-primary" />]}
            />
          }
        />
      )}
    />
  );
};
