import {
  SkeletonAvatar,
  SkeletonTable,
  SkeletonText,
  SkeletonCheckbox,
} from '@dev-spendesk/grapes';
import React from 'react';

const TABLE_LOADER_ROWS = 5;

export const ExpenseReviewListSkeleton = () => {
  return (
    <SkeletonTable
      className="w-full"
      numberOfRows={TABLE_LOADER_ROWS}
      columns={[
        {
          cell: (
            <div
              className="grid w-full items-center gap-16"
              style={{
                gridTemplateColumns: 'min-content min-content 2fr 64px',
              }}
            >
              <SkeletonCheckbox />
              <SkeletonAvatar variant="circle" />
              <div className="grid items-center gap-8">
                <SkeletonText width="50%" size="l" />
              </div>
              <div className="grid items-center gap-8">
                <SkeletonText size="s" />
                <SkeletonText size="s" />
              </div>
            </div>
          ),
        },
      ]}
    />
  );
};
