import { createAction, type Dispatch } from '@reduxjs/toolkit';

import * as types from 'src/core/actionTypes/customFields';
import { companyAPI } from 'src/core/api/axios';
import FEATURES from 'src/core/constants/features';
import { getIsFeatureEnabled } from 'src/core/selectors/globalSelectors';

import { type CustomFieldDefinition } from '../modules/requests/models/customFieldDefinition';
import { type AppState } from '../reducers';
import { getCompanyId } from '../selectors/globalSelectorsTyped';

// Custom fields
const fetchCustomFieldsLoading = createAction(types.FETCH_CF_LOADING);
const fetchCustomFieldsFailure = createAction(types.FETCH_CF_FAILURE);
const fetchCustomFieldsSuccess = createAction<{
  customFields: CustomFieldDefinition[];
}>(types.FETCH_CF_SUCCESS);

const setExpenseCategoryCustomField = createAction(
  types.SET_EXPENSE_CATEGORY_CUSTOM_FIELD,
);
const setExpenseCategoryValues = createAction(
  types.SET_EXPENSE_CATEGORY_VALUES,
);
const setExpenseCategoryCustomFieldId = createAction(
  types.SET_EXPENSE_CATEGORY_CUSTOM_FIELD_ID,
);

const refreshRequestWithExpenseCategory = createAction(
  types.REFRESH_REQUEST_WITH_EXPENSE_CATEGORY,
);

export const fetchCustomFields =
  (withSeparateExpenseCategory = false) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(fetchCustomFieldsLoading());

    let customFields = [];
    try {
      ({ data: customFields } = await companyAPI.get('/custom-fields', {
        companyId,
      }));
      customFields = customFields.filter(
        (customField: CustomFieldDefinition) => !customField.deleted_at,
      );

      if (getIsFeatureEnabled(state, FEATURES.EXPENSE_CATEGORIES)) {
        const expenseCategoryCustomFieldId =
          await fetchExpenseCategoryCustomFieldId(companyId);
        // When the expense categories feature is enable, a basic custom field
        // list is used/flagged to represent the possible values of expense
        // categories (especially related for specific product flows for the
        // budgets project)
        // with expense categories feature the custom field that is assigned
        // as an expense category is filtered out from the custom fields list
        // and becomes a separate from custom fields field
        const expenseCategoryCustomField = customFields.find(
          (customField: CustomFieldDefinition) => {
            return customField.id === expenseCategoryCustomFieldId;
          },
        );

        if (expenseCategoryCustomFieldId && withSeparateExpenseCategory) {
          customFields = customFields.filter(
            (customField: CustomFieldDefinition) => {
              return customField.id !== expenseCategoryCustomFieldId;
            },
          );
        }

        dispatch(
          setExpenseCategoryValues(
            expenseCategoryCustomField?.custom_fields_values ?? [],
          ),
        );

        dispatch(setExpenseCategoryCustomField(expenseCategoryCustomField));
        dispatch(setExpenseCategoryCustomFieldId(expenseCategoryCustomFieldId));
      }
    } catch (error) {
      dispatch(fetchCustomFieldsFailure());
      throw error;
    }

    dispatch(fetchCustomFieldsSuccess({ customFields }));
    dispatch(refreshRequestWithExpenseCategory());
  };

const fetchExpenseCategoryCustomFieldId = async (companyId: string) => {
  try {
    const { data: ecCustomFieldResponse } = await companyAPI.get(
      '/expense-categories/designated-custom-field',
      {
        companyId,
      },
    );
    return ecCustomFieldResponse?.customFieldId;
  } catch {
    return undefined;
  }
};
