import {
  type UpdateRequest,
  type UpdateSuccess,
  type UpdateFailure,
  type RequestAPI,
} from '../types';

export const UPDATE_REQUEST = 'request/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'request/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'request/UPDATE_FAILURE';

export const updateRequest = (): UpdateRequest => ({
  type: UPDATE_REQUEST,
});

export const updateSuccess = (request: RequestAPI): UpdateSuccess => ({
  type: UPDATE_SUCCESS,
  payload: request,
});

export const updateFailure = (error: unknown): UpdateFailure => ({
  type: UPDATE_FAILURE,
  payload: error,
});
