import { PageModal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import styles from './SupplierDetailsModal.module.css';
import type { BankDetailsSuggestion, RawSupplier } from '../../models';
import { ToReviewPanelSupplier } from '../ToReviewPanelSupplier';

type LegacySupplier = RawSupplier & {
  supplier_details_suggestion?: BankDetailsSuggestion | null;
};

export type Props = {
  documentaryEvidence?: React.ReactNode;
  supplier: LegacySupplier;
  onClose(): void;
  onCreateSupplierSuccess?(supplier: RawSupplier): void;
  onUpdateSupplierSuccess?(supplier: RawSupplier): void;
};

export const SupplierDetailsModal = ({
  documentaryEvidence,
  supplier,
  onClose,
  onCreateSupplierSuccess,
  onUpdateSupplierSuccess,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <PageModal
      isOpen
      onClose={onClose}
      title={t('submitMyInvoice.supplier.supplierInfo')}
    >
      <div className={styles.layout}>
        {documentaryEvidence}
        <ToReviewPanelSupplier
          // @ts-expect-error FIXME: mismatch between the types RawInvoiceData['supplier'] and ApiSupplier
          supplier={supplier}
          // @ts-expect-error FIXME: mismatch between the types RawInvoiceData['supplier'] and ApiSupplier
          onCreateSupplierSuccess={onCreateSupplierSuccess}
          // @ts-expect-error FIXME: mismatch between the types RawInvoiceData['supplier'] and ApiSupplier
          onUpdateSupplierSuccess={onUpdateSupplierSuccess}
        />
      </div>
    </PageModal>
  );
};
