import { ListBox } from '@dev-spendesk/grapes';

import { PageNoResult as PageNoResultContainer } from 'common/components/PageNoResult/PageNoResultContainer';
import { type Mode } from 'common/components/PageNoResult/mode';
import { type MileageScheme } from 'modules/requests/models/mileageScheme';
import type { RequestAPI } from 'modules/requests/types';
import {
  isDraftsTab,
  SubnavigationItem,
} from 'modules/requests/utils/navigation';
import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { DraftRequestRow } from './DraftRequestRow';
import styles from './RequestListBox.module.css';
import { RequestListRow } from './RequestRow';
import { RequestsListBoxHeader } from './RequestsListBoxHeader';
import { getSection, sectionGroupByRequestType } from './utils';
import { RequestsListLoader } from '../RequestsListsLoader';
import type { RequestStats, Sections } from '../requestsSectionsProps';

export type Request = RequestAPI & {
  mileage_scheme: MileageScheme;
};

type RequestsListBoxProps = {
  requests: Request[];
  sectionsStats: Sections<RequestStats>;
  onOptionClick: (request: Request) => void;
  activeRequest: string | undefined;
  type: SubnavigationItem | 'request';
  checkedRequests: string[];
  onRequestToggle: (requestId: string, checked: boolean) => void;
  onRequestToggleAll: (isChecked: boolean) => void;
  hasFiltersApplied?: boolean;
  isLoading?: boolean;
};

export const RequestsListBox = ({
  activeRequest,
  checkedRequests,
  hasFiltersApplied,
  isLoading,
  onOptionClick,
  onRequestToggle,
  onRequestToggleAll,
  requests,
  sectionsStats,
  type,
}: RequestsListBoxProps) => {
  const user = useUser();
  const { t } = useTranslation('global');

  if (isLoading) {
    return <RequestsListLoader />;
  }

  if (requests.length === 0) {
    let mode = 'request';
    if (isDraftsTab(type)) {
      mode = 'draftRequest';
    } else if (type === SubnavigationItem.Mine) {
      mode = 'mineRequest';
    } else if (type === SubnavigationItem.ToApprove) {
      mode = 'toApproveRequest';
    }
    return (
      <PageNoResultContainer
        mode={mode as Mode}
        hasFiltersApplied={hasFiltersApplied}
      />
    );
  }

  return (
    <ListBox
      getOptionId={(option) => option.id}
      options={requests}
      groupBy={(option) => sectionGroupByRequestType[option.type]}
      checkedOptionIds={checkedRequests}
      onOptionChange={(_, id, checked) => onRequestToggle(id, checked)}
      onAllOptionsChange={(_, _1, isChecked) => onRequestToggleAll(isChecked)}
      getIsOptionActive={(option) => option.id === activeRequest}
      onOptionClick={onOptionClick}
      className={styles.listbox}
      header={
        <RequestsListBoxHeader
          requests={requests}
          checkedRequests={checkedRequests}
          unselectRequests={() => onRequestToggleAll(false)}
        />
      }
      renderGroupedOptionsHeader={(value) => {
        const section = getSection(type, user)[value];

        if (!section) {
          return null;
        }
        const translationKey = section.translationKey;
        const sectionStats = sectionsStats[section.sectionStatsKey];
        return (
          <div className="flex justify-between">
            <span>{t(translationKey)}</span>
            <div className="flex gap-4">
              <span>
                {t('misc.requestWithCount', {
                  count: sectionStats?.count,
                })}
              </span>
              {sectionStats?.totalAmount && (
                <>
                  <span>-</span>
                  <span>{formatMonetaryValue(sectionStats.totalAmount)}</span>
                </>
              )}
            </div>
          </div>
        );
      }}
    >
      {(option, titleId) => {
        if (isDraftsTab(type)) {
          return (
            <ErrorBoundary
              context={{
                team: 'capture',
                scope: 'requests::list::draftRow',
              }}
            >
              <DraftRequestRow request={option} titleId={titleId} />
            </ErrorBoundary>
          );
        }
        return (
          <ErrorBoundary
            context={{
              team: 'capture',
              scope: 'requests::list::draftRow',
            }}
          >
            <RequestListRow request={option} titleId={titleId} />
          </ErrorBoundary>
        );
      }}
    </ListBox>
  );
};
