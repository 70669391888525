import { Button, DropdownItem, DropdownMenu } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import './ReceiptFilter.css';

type Props = {
  receiptStatus: string | undefined;
  setReceiptStatus(status: string | undefined): void;
};

export const ReceiptFilter = ({ receiptStatus, setReceiptStatus }: Props) => {
  const { t } = useTranslation('global');
  const hasSpanishDprFeature = useFeature(FEATURES.SPANISH_DPR);

  return (
    <DropdownMenu
      onSelect={(option) =>
        setReceiptStatus(receiptStatus !== option.key ? option.key : undefined)
      }
      options={[
        {
          key: 'provided',
          label: t('payables.filters.receiptFilter.provided'),
        },
        hasSpanishDprFeature
          ? {
              key: 'provided_and_dpr',
              label: t(
                'expenseInbox.filters.documentaryEvidenceStatusOptions.provided_and_dpr',
              ),
            }
          : undefined,
        {
          key: 'not_provided',
          label: t('payables.filters.receiptFilter.notProvided'),
        },
        {
          key: 'missing',
          label: t('payables.filters.receiptFilter.missing'),
        },
      ].filter(
        (item): item is { key: string; label: string } => item !== undefined,
      )}
      placement="bottom-start"
      renderButton={(getToggleButtonProps, isOpen) => (
        <Button
          {...getToggleButtonProps()}
          className={classNames('ReceiptFilter__button', {
            'ReceiptFilter__button--open': isOpen,
            'ReceiptFilter__button--active': receiptStatus,
          })}
          text={t('payables.filters.receipt')}
          variant="secondaryNeutral"
        />
      )}
      renderOption={(option) => (
        <DropdownItem
          label={option.label}
          isSelected={option.key === receiptStatus}
        />
      )}
    />
  );
};
