import { Callout, FileCard, Icon, Link, Tag } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import {
  HELP_CENTER_SPANSIH_DPR_LINK,
  SpanishDPRModal,
} from '../../SpanishDPR/SpanishDPRModal';

type Props = {
  fileName: string;
  isHighlighted: boolean;
  mimeType?: string;
  onPreview: () => void;
  onDelete?: () => void;
  onOpenFacturXLink?: () => void;
} & (
  | {
      requesterId: string;
      spainEreceiptState:
        | 'ongoing'
        | 'done'
        | 'failed'
        | 'not-applicable'
        | undefined;
    }
  | {
      requesterId?: undefined;
      spainEreceiptState?: undefined;
    }
);

export const PreviewFileItem = ({
  fileName,
  isHighlighted,
  mimeType,
  onPreview,
  onDelete,
  onOpenFacturXLink,
  requesterId = '',
  spainEreceiptState,
}: Props) => {
  const { t } = useTranslation('global');
  const user = useUser();
  const hasSpanishDPR = useFeature(FEATURES.SPANISH_DPR);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const shouldDisplaySpanishDprError =
    hasSpanishDPR && spainEreceiptState === 'failed' && requesterId === user.id;

  return (
    <div className="mb-4">
      <div className="rounded bg-secondary-default">
        <FileCard
          isHighlighted={isHighlighted}
          onClick={onPreview}
          title={fileName}
          mimeType={mimeType}
          onDelete={onDelete}
          variant={shouldDisplaySpanishDprError ? 'alert' : 'neutral'}
        />
        {hasSpanishDPR && spainEreceiptState === 'ongoing' && (
          <div className="flex items-center gap-4 px-16 py-4 text-primary body-s">
            <Icon size="s" name="clock-filled" />
            {t('requests.panel.spanishDPR.previewOngoing')}
            <Link
              href={HELP_CENTER_SPANSIH_DPR_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('requests.panel.spanishDPR.calloutReadMore')}
            </Link>
          </div>
        )}
        {hasSpanishDPR && spainEreceiptState === 'done' && (
          <div className="flex items-center gap-4 px-16 py-4 text-primary body-s">
            <Icon size="s" name="circle-check" />
            {t('requests.panel.spanishDPR.previewDone')}
            <Link
              href={HELP_CENTER_SPANSIH_DPR_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('requests.panel.spanishDPR.calloutReadMore')}
            </Link>
          </div>
        )}
      </div>
      {shouldDisplaySpanishDprError && (
        <Callout
          className="mt-8"
          variant="alert"
          title={t('requests.panel.spanishDPR.previewFailed')}
        >
          <div>
            {t('requests.panel.spanishDPR.previewFailedContent')}
            <div className="flex gap-4">
              <Link
                href={HELP_CENTER_SPANSIH_DPR_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('requests.panel.spanishDPR.calloutReadMore')}
              </Link>
              {' • '}
              <Link
                onClick={(event) => {
                  event.preventDefault();
                  setIsModalOpen(true);
                }}
                href=""
              >
                {t('requests.panel.spanishDPR.calloutSwitchToMobile')}
              </Link>
            </div>
          </div>
        </Callout>
      )}
      <SpanishDPRModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      {onOpenFacturXLink && (
        <div className="mt-[2px] flex items-center justify-between">
          <Tag variant="neutral">{t('requests.panel.facturX')}</Tag>
          <Link
            as="a"
            onClick={onOpenFacturXLink}
            className="inline-flex items-center gap-4"
          >
            {t('requests.panel.facturXTooltip')}
            <Icon name="arrow-top-right-square" size="s" aria-hidden="true" />
          </Link>
        </div>
      )}
    </div>
  );
};
