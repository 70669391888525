import * as Money from 'ezmoney';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';

import { useUpdateGetPayableQueryCache } from 'modules/bookkeep/apis/prepare-payable/useGetPayableQuery';
import { type RawPayable } from 'modules/bookkeep/apis/prepare-payable/useGetPayableQuery/graphql-reshaper';
import { type PayableId } from 'modules/payable/models';
import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';
import { withoutOptionalFields } from 'src/core/common/components/FilterBuilder';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useActivePayableFilter } from 'src/core/modules/payable/components';
import { getToPreparePayableListQueryKey } from 'src/core/modules/payable/hooks';

import { GET_PAYABLES } from './queries';
import {
  type RawPayable as RawPayableSummary,
  reshapePayable,
} from './reshaper';
import { PreparePayablesFiltersContext } from '../../../../contexts';
import {
  type BucketType,
  type GroupId,
  type PayableRow,
  payableVersionWhenPartiallyLoaded,
  toAPIPayableFilters,
  toAPIPayableFiltersV2,
} from '../../../../models';

/**
 * Query and cache config
 */

type RawData = {
  company: {
    payables: {
      totalCount: number;
      pageInfo: {
        hasNextPage: boolean;
      };
      edges: {
        cursor: string;
        node: RawPayableSummary;
      }[];
    };
  } | null;
};

export const useInvalidatePayblesQueryCache = () => {
  const queryClient = useQueryClient();

  return async (
    companyId: string,
    filters?: ReturnType<typeof toAPIPayableFilters>,
  ): Promise<void> => {
    await queryClient.invalidateQueries(
      getToPreparePayableListQueryKey(companyId, filters),
    );
  };
};

export const useUpdatePayablesQueryCache = () => {
  const queryClient = useQueryClient();

  return (
    companyId: string,
    payableId: PayableId | null,
    payableUpdate: Partial<RawPayableSummary>,
  ) => {
    const payablesQueries = queryClient
      .getQueryCache()
      .findAll({ queryKey: getToPreparePayableListQueryKey(companyId) });

    for (const query of payablesQueries) {
      query.setData(
        (
          oldData: { pages: RawData[] } | undefined,
        ): { pages: RawData[] } | undefined => {
          const matchingPage = oldData?.pages.find((page) =>
            page.company?.payables.edges.some(
              (edge) => edge.node.id === payableId,
            ),
          );

          if (matchingPage) {
            const updatedEdges = matchingPage.company?.payables.edges.map(
              (edge) => {
                if (edge.node.id === payableId) {
                  return {
                    cursor: edge.cursor,
                    node: { ...edge.node, ...payableUpdate },
                  };
                }
                return edge;
              },
            );

            const updatedPages =
              oldData?.pages.map((page) => {
                if (
                  page === matchingPage &&
                  page.company !== null &&
                  updatedEdges
                ) {
                  page.company.payables.edges = updatedEdges;
                }
                return page;
              }) ?? [];

            return { ...oldData, pages: updatedPages };
          }

          return oldData;
        },
      );
    }
  };
};

/**
 * GraphQL query hook
 */

export const usePayablesQuery = (
  companyId: string,
  bucketType: BucketType,
  isEnabled: boolean,
  groupId?: GroupId,
): InfiniteQueryState<PayableRow[]> => {
  const { localeFormat } = useTranslation('global');

  const filtersContext = useContext(PreparePayablesFiltersContext);
  const filtersV2 = useActivePayableFilter()?.filter;
  const filtersV2Enabled = useFeature(FEATURES.TMP_PAYABLE_FILTERS);

  const filters = toAPIPayableFilters({
    filters: filtersContext.state,
    bucketType,
    groupId,
    withBookkeepingStartDate: true,
  });

  const filtersV2ForAPI = filtersV2Enabled
    ? toAPIPayableFiltersV2({
        filters: filtersV2 ? withoutOptionalFields(filtersV2) : undefined,
        bucketType,
        groupFilter: filtersContext.state.group,
        groupId,
        withBookkeepingStartDate: true,
      })
    : undefined;

  const updateGetPayableQueryCache = useUpdateGetPayableQueryCache();

  return useInfiniteQuery<PayableRow, RawData>({
    key: getToPreparePayableListQueryKey(
      companyId,
      filters,
      filtersV2ForAPI,
      filters?.search,
    ),
    isEnabled: isEnabled && filters !== undefined,
    getRequest: (cursor) => ({
      type: 'graphQL',
      target: 'v2',
      query: GET_PAYABLES,
      variables: {
        filters: filters,
        filtersV2: filtersV2ForAPI,
        after: typeof cursor === 'string' ? cursor : undefined,
        textualSearch: filters?.search,
      },
    }),
    getNextPageParam: (data) => {
      if (!data.company) {
        return undefined;
      }
      const { edges, pageInfo } = data.company.payables;
      const lastEdge = edges.at(-1);

      if (pageInfo.hasNextPage && lastEdge) {
        return lastEdge.cursor;
      }
      return undefined;
    },
    reshapeData: (rawData) => {
      if (!rawData.company) {
        return [];
      }
      const { edges } = rawData.company.payables;
      const filteredEdges = edges.filter(
        ({ node }) => node.state !== 'prepared',
      );

      filteredEdges.forEach(({ node }) => {
        updateGetPayableQueryCache(
          companyId,
          node.id,
          getPayableFromSummary(node),
        );
      });

      return filteredEdges.map(({ node }) =>
        reshapePayable(node, bucketType, localeFormat),
      );
    },
  });
};

const getPayableFromSummary = (summary: RawPayableSummary): RawPayable => {
  const getTypename = ({
    type,
  }: RawPayableSummary): RawPayable['__typename'] => {
    switch (type) {
      case 'reversal':
        return 'ReversalPayable';
      case 'mileage_allowance':
        return 'MileageAllowanceExpensePayable';
      case 'per_diem_allowance':
        return 'PerDiemAllowanceExpensePayable';
      case 'expense_claim':
      case 'card_purchase':
      case 'invoice_purchase':
        return 'SupplierExpensePayable';
      default:
        throw new Error(`Unknown payable type: ${type}`);
    }
  };

  return {
    id: summary.id,
    state: summary.state as RawPayable['state'],
    creationDate: summary.creationDate,
    netAmount: summary.netAmount,
    grossAmount: summary.grossAmount,
    functionalAmount: summary.functionalAmount,
    functionalExchangeRate: { amount: 1, precision: 0 },
    type: summary.type,
    subtype: summary.subtype,
    description: summary.description,
    member: summary.member as RawPayable['member'],
    counterparty: summary.counterparty,
    supplier: summary.supplier
      ? {
          ...summary.supplier,
          thumbnailUrl: summary.supplier.thumbnailUrl ?? '',
        }
      : null,
    itemLines: [
      {
        netAmount: summary.netAmount,
        grossAmount: summary.grossAmount,
        taxAmount: Money.subtract(summary.grossAmount, summary.netAmount),
        taxAccount: null,
        expenseAccount: null,
        analyticalFieldAssociations: [],
        natureId: 'natureId',
      },
    ],
    version: payableVersionWhenPartiallyLoaded,
    accountPayable: null,
    fxFees: null,
    team: null,
    costCenter: null,
    settlementDate: '',
    accountingDate: summary.creationDate,
    vatConfidence: null,
    customFields: undefined,
    mileageDetails: null,
    perDiem: null,
    invoiceNumber: null,
    allocations: [],
    amortisation: null,
    automation: {
      accountPayable: null,
      expenseAccount: null,
      expenseAmount: null,
      tax: null,
      analyticalFieldAssociations: null,
    },
    documentaryEvidence: {
      type: 'invoice',
      invoiceNumber: null,
      creditNoteNumber: null,
    },
    __typename: getTypename(summary),
  };
};
