import { Link } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useUser } from 'modules/app/hooks/useUser';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type MemberEditFormikValues } from '../../components/MemberEditModal/validate';
import { MemberEditTabRoles } from '../../components/MemberEditTabRoles/MemberEditTabRoles';
import { ROLE_NAMES_TO_ASSIGN } from '../../constants/roles';
import { type MemberDetails } from '../../models/member';
import { type CompanyRole } from '../../models/roles';

type Props = {
  member: MemberDetails;
  formikProps: FormikProps<MemberEditFormikValues>;
};

export const MemberEditTabRolesContainer = ({ member, formikProps }: Props) => {
  const { t, activeLanguage } = useTranslation('global');

  // retrieve global data from Redux store
  const currentUser = useUser();

  const isRoleEditable = (role: CompanyRole) => {
    if (member.isAccountOwner) {
      return false;
    }

    // Super admins can edit all roles
    if (currentUser.is_account_owner) {
      return true;
    }

    // Admins can't assign or retract the "Admin" role to other users
    return !(currentUser.is_admin && role === 'admin');
  };

  return (
    <div className="flex flex-col content-stretch gap-24">
      <MemberEditTabRoles
        member={member}
        formikProps={formikProps}
        enrichedRoles={ROLE_NAMES_TO_ASSIGN.map((role) => ({
          name: role,
          isEditable: isRoleEditable(role),
          checked:
            member.isAccountOwner || formikProps.values.roles.includes(role),
        }))}
      />
      <Link
        href={getLocalisedHelpCenterLink(activeLanguage)}
        isExternal
        className="self-center text-primary no-underline body-m"
      >
        {t('members.learnMoreAboutRoles')}
      </Link>
    </div>
  );
};

const getLocalisedHelpCenterLink = (locale: string) => {
  switch (locale) {
    case 'fr':
      return 'https://helpcenter.spendesk.com/fr/articles/4835151-comprendre-les-roles-et-permissions-sur-spendesk';
    case 'de':
      return 'https://helpcenter.spendesk.com/de/articles/4835151-rollen-und-berechtigungen-in-spendesk';
    default:
      return 'https://helpcenter.spendesk.com/en/articles/4835151-understand-roles-and-permissions';
  }
};
