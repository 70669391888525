import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { DownloadAppBadges } from 'common/components/DownloadAppBadges';
import { useTranslation } from 'common/hooks/useTranslation';
import CaroleCooper from 'modules/StrongCustomerAuthentication/images/carole-cooper.svg';
import QrCode from 'modules/StrongCustomerAuthentication/images/qr-code.svg';
import ScanToDownload from 'modules/StrongCustomerAuthentication/images/scan-to-download.svg';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onEnroll: () => void;
}

export const ScaWireTransferConfirmationEnrollmentModal = ({
  isOpen,
  onClose,
  onEnroll,
}: Props) => {
  const { t } = useTranslation('global');

  const [isScaEnrolledError, setIsScaEnrolledError] = React.useState(false);

  return (
    <Modal
      isOpen={isOpen}
      iconName="lock-close"
      iconVariant="info"
      title={t('wiretransfer.modal.sca.confirmWireTransferEnrollment.title')}
      subtitle={t('wiretransfer.modal.sca.confirmWireTransferEnrollment.desc')}
      actions={[
        <Button
          key="cancel"
          text={t(
            'wiretransfer.modal.sca.confirmWireTransferEnrollment.cancel',
          )}
          variant="secondaryNeutral"
          onClick={onClose}
        />,
        <Button
          key="haveSetUpApp"
          text={t(
            'wiretransfer.modal.sca.confirmWireTransferEnrollment.haveSetUpApp',
          )}
          variant="primaryBrand"
          onClick={async () => {
            try {
              await onEnroll();
            } catch {
              setIsScaEnrolledError(true);
            }
          }}
        />,
      ]}
      onClose={() => {
        onClose();
        setIsScaEnrolledError(false);
      }}
    >
      <div className="flex w-full flex-col items-center">
        <div className="relative mt-24 flex h-[305px] w-full flex-col items-center justify-center rounded-8 bg-[#F1F3FF]">
          <img
            src={ScanToDownload}
            className="absolute bottom-[138px] left-16"
            alt="Scan to download"
          />
          <img src={QrCode} className="w-[180px]" alt="QR code" />
          <DownloadAppBadges className="mt-24" />
          <img
            src={CaroleCooper}
            className="absolute bottom-0 right-4"
            alt="Working woman holding phone"
          />
        </div>

        {isScaEnrolledError && (
          <Callout
            className="mt-16"
            title={t(
              'wiretransfer.modal.sca.confirmWireTransferEnrollment.error',
            )}
            variant="alert"
          />
        )}
      </div>
    </Modal>
  );
};
