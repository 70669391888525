import { useFormik } from 'formik';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { useIntegrationStatusQuery } from 'modules/bookkeep';
import { canAccessEmployeeAccountsSettings } from 'modules/bookkeep/settings/integrations/helper/settings-access';
import { useGetDefaultEmployeeAccountQuery } from 'modules/bookkeep/settings/integrations/hooks/useGetDefaultEmployeeAccountQuery';
import { useSetDefaultEmployeeAccountMutation } from 'modules/bookkeep/settings/integrations/hooks/useSetDefaultEmployeeAccountMutation';
import { ExpenseAccountsSkeletonTable } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/ExpenseAccountsSection/ExpenseAccountsSkeletonTable';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { DefaultEmployeeAccount } from 'modules/onboarding/setup-hub/pages/Accounting/AccountingEmployeeAccounts/components/DefaultEmployeeAccount';
import { EmployeeAccountsTable } from 'modules/onboarding/setup-hub/pages/Accounting/AccountingEmployeeAccounts/components/EmployeeAccountsTable';
import { useUpdateCachedTaskStatus } from 'modules/onboarding/setup-hub/pages/SetupHubPage/hooks/useUpdateCachedTaskStatus';
import { routes } from 'modules/onboarding/setup-hub/pages/routes';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { routeFor } from 'src/core/constants/routes';

import type { FormValues } from './form';

export const AccountingEmployeeAccounts = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const history = useHistory();
  const { dangerNotif } = useNotifications();

  const getDefaultEmployeeAccountsQuery = useGetDefaultEmployeeAccountQuery({
    isEnabled: true,
  });
  const [setDefaultEmployeeAccount] = useSetDefaultEmployeeAccountMutation();
  const defaultEmployeeAccount = unwrapQuery(getDefaultEmployeeAccountsQuery);

  const integrationStatusQueryState = useIntegrationStatusQuery();

  const updateCachedTaskStatus = useUpdateCachedTaskStatus();

  const { values, handleChange, handleSubmit } = useFormik<FormValues>({
    initialValues: {
      useDefaultValue: Boolean(
        !defaultEmployeeAccount?.isArchived &&
          !!defaultEmployeeAccount?.isDefault,
      ),
      defaultEmployeeAccount: defaultEmployeeAccount?.generalAccountCode,
    },
    enableReinitialize: true,
    onSubmit: async () => {
      try {
        if (!defaultEmployeeAccount) {
          return dangerNotif(
            t(
              'bookkeep.integrations.settings.employeeAccountsTable.editDefaultAccountFailure',
            ),
          );
        }
        await setDefaultEmployeeAccount({
          ...defaultEmployeeAccount,
          isArchived: !values.useDefaultValue || !values.defaultEmployeeAccount,
          generalAccountCode:
            values.defaultEmployeeAccount ||
            defaultEmployeeAccount.generalAccountCode,
        });
        updateCachedTaskStatus('employee_account_codes');
        goBackToHub();
      } catch {
        return dangerNotif(
          t(
            'bookkeep.integrations.settings.employeeAccountsTable.editDefaultAccountFailure',
          ),
        );
      }
    },
  });

  const goBackToHub = () => {
    const previousPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(previousPath);
  };

  const getPageContent = () => {
    if (integrationStatusQueryState.status === 'loading') {
      return <ExpenseAccountsSkeletonTable />;
    }
    if (
      integrationStatusQueryState.status === 'error' ||
      !canAccessEmployeeAccountsSettings(integrationStatusQueryState.data)
    ) {
      dangerNotif(t('misc.errors.unknownError'));
      return (
        <Redirect
          to={routeFor(routes.SETUP_HUB_TASK_LIST.path, { company: companyId })}
        />
      );
    }
    return (
      <>
        <DefaultEmployeeAccount values={values} handleChange={handleChange} />
        <EmployeeAccountsTable
          useDefaultValue={values.useDefaultValue}
          integrationStatus={integrationStatusQueryState.data}
        />
      </>
    );
  };

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.employeeAccounts.description')}
        isLastStep
        onBack={goBackToHub}
        onNext={handleSubmit}
        title={t('setupHub.employeeAccounts.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('misc.needHelp'),
        }}
      >
        {getPageContent()}
      </TaskStepLayout>
    </div>
  );
};
