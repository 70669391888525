import { Badge } from '@dev-spendesk/grapes';
import classnames from 'classnames';

import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';

type Props = {
  label: string;
  count: number;
  href: string;
  callback?: () => void;
  isInactive?: boolean;
};

export const EntityItemActionItem = ({
  label,
  count,
  href,
  callback,
  isInactive = false,
}: Props) => (
  <button
    type="button"
    disabled={isInactive}
    className={classnames(
      'flex w-full items-center justify-between gap-8 rounded-8 border border-solid border-default px-12 py-8',
      isInactive
        ? 'bg-primary-disabled'
        : 'cursor-pointer bg-primary-default hover:border-hover hover:bg-secondary-brand-hover',
    )}
    onClick={() => {
      window.open(href, '_blank');
      callback?.();
    }}
  >
    <EllipsisTooltip text={label} className="text-primary body-m" />
    <Badge variant={isInactive ? 'secondary' : 'primary'}>{count}</Badge>
  </button>
);
