import React from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCanAccessInvoicesPage } from 'src/core/modules/invoices/hooks/useCanAccessInvoicesPage';
import { useCanAccessExpenseClaimsPage } from 'src/core/modules/reimbursements/hooks/useCanAccessExpenseClaimsPage';

import { useRequestLink } from './RequestLink';
import { DisclosureItem } from '../DisclosureItem/DisclosureItem';
import { SubNavLink } from '../SubNavItem/SubNavItem';

type Props = Readonly<{
  company: {
    id: string;
  };
}>;

export const ExpendituresLink = ({ company }: Props) => {
  const { t } = useTranslation('global');
  const isInvoiceInboxEnabled = useFeature(FEATURES.BILL_INBOX);

  const canAccessInvoicesPage = useCanAccessInvoicesPage();
  const canAccessExpenseClaimsPage = useCanAccessExpenseClaimsPage();
  const requestLink = useRequestLink(company.id);

  const isPurchaseOrderActivated = useFeature(
    FEATURES.PURCHASE_ORDERS_ACTIVATED,
  );

  return (
    <DisclosureItem
      summary={t('navigation.expenditures')}
      iconName="shopping-bag"
    >
      {isInvoiceInboxEnabled && (
        <li>
          <SubNavLink
            href={routeFor(routes.INBOX_INVOICES.path, {
              company: company.id,
            })}
            label={t('requests.inboxInvoicesSection')}
          />
        </li>
      )}
      <li>
        <SubNavLink
          href={requestLink}
          activePaths={[
            // Cannot use REQUESTS.path because we need to exclude `requests/inbox`
            '/app/:company/requests/all',
            '/app/:company/requests/to-approve',
            '/app/:company/requests/mine',
            '/app/:company/requests/drafts',
          ]}
          label={t('misc.request_plural')}
        />
      </li>
      {canAccessExpenseClaimsPage && (
        <li>
          <SubNavLink
            href={routeFor(routes.EXPENSE_CLAIMS_REVIEW.path, {
              company: company.id,
            })}
            activePaths={[routes.EXPENSE_CLAIMS.path]}
            label={t('misc.expenseClaimsTitle')}
          />
        </li>
      )}
      {canAccessInvoicesPage && (
        <li>
          <SubNavLink
            href={routeFor(routes.INVOICES_REVIEW.path, {
              company: company.id,
            })}
            activePaths={[
              routes.INVOICES.path,
              routes.CREDIT_NOTES_REVIEW.path,
            ]}
            label={t('misc.invoicesTitle')}
          />
        </li>
      )}
      <li>
        <SubNavLink
          href={routeFor(routes.PAYMENTS_ALL.path, {
            company: company.id,
          })}
          activePaths={[routes.PAYABLES_ALL.path, routes.PAYMENTS_ALL.path]}
          label={t('navigation.allExpenses')}
        />
      </li>
      {isPurchaseOrderActivated && (
        <li>
          <SubNavLink
            href={routeFor(routes.PURCHASE_ORDERS.path, {
              company: company.id,
            })}
            label={t('misc.purchaseOrders')}
          />
        </li>
      )}
    </DisclosureItem>
  );
};
