import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';

import { SEARCH_CUSTOM_FIELD_VALUES } from './queries';

/**
 * @public
 */
export type RawCustomFieldValue = {
  id: string;
  name: string;
  archiveDate?: string | null;
};

/**
 * @public
 */
export type CustomFieldValue = {
  id: string;
  name: string;
  isArchived: boolean;
};

export type SearchCustomFieldValuesRawResponse = {
  company: {
    customFields: Array<{
      values: { edges: { node: RawCustomFieldValue }[] };
    }>;
  };
};

export type SearchCustomFieldValuesVariables = {
  search: string;
  customFieldId: string;
};

/**
 * @public
 */
export type SearchCustomFieldValuesResponse = CustomFieldValue[];

export const useSearchCustomFieldValues = (customFieldId: string) => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  return async (search: string): Promise<SearchCustomFieldValuesResponse> => {
    const data = await queryClient.fetchQuery(
      ['useSearchCustomFieldValues', companyId, customFieldId, search],
      getFetcher<SearchCustomFieldValuesRawResponse>({
        companyId,
        getRequest: () => ({
          type: 'graphQL',
          target: 'v2',
          query: SEARCH_CUSTOM_FIELD_VALUES,
          variables: {
            customFieldId,
            search,
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return data.company.customFields.flatMap((customField) =>
      reshapeFilterValues(customField.values.edges),
    );
  };
};

/**
 * Reshapers
 */

const reshapeFilterValues = (
  edges: {
    node: RawCustomFieldValue;
  }[],
): CustomFieldValue[] => {
  return edges
    .map(({ node }) =>
      node.archiveDate
        ? null
        : {
            id: node.id,
            name: node.name,
            isArchived: !!node.archiveDate,
          },
    )
    .filter((item): item is CustomFieldValue => item !== null);
};
