import { type IconName } from '@dev-spendesk/grapes';

import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

// TODO: use Bookkeep 2.0 payable type
export type PayableType =
  | 'card_purchase'
  | 'invoice_purchase'
  | 'expense_claim'
  | 'mileage_allowance'
  | 'per_diem_allowance'
  | 'reversal';

export type PayableSubType =
  | 'plasticCard'
  | 'subscriptionCard'
  | 'singleUseCard'
  | 'creditNote';

class TypeGuardError extends Error {
  constructor(_switchCase: never, message: string) {
    super(message);
  }
}

export const getReadablePayableTypeIcon = (
  payableType: PayableType,
  payableSubType: PayableSubType | undefined,
): IconName | undefined => {
  switch (payableType) {
    case 'card_purchase':
    case 'reversal':
      return payableSubType === 'creditNote'
        ? 'dollar-circle-arrow'
        : 'card-lightning';
    case 'invoice_purchase':
      return 'invoice';
    case 'expense_claim':
      return 'clipboard';
    case 'mileage_allowance':
      return 'car';
    case 'per_diem_allowance':
      return 'plane';
    default:
      throw new TypeGuardError(
        payableType,
        `Unknown payableType type ${payableType}`,
      );
  }
};

export const getReadablePayableType = (
  payableType: PayableType,
  payableSubType: PayableSubType | undefined,
  translator: TGlobalFunctionTyped,
): string => {
  switch (payableType) {
    case 'card_purchase':
      return getReadableCardPayableType(payableSubType, translator);
    case 'invoice_purchase':
      return translator('payables.panel.types.invoice');
    case 'expense_claim':
      return translator('payables.panel.types.expenseClaim');
    case 'mileage_allowance':
      return translator('payables.panel.types.mileage');
    case 'per_diem_allowance':
      return translator('payables.panel.types.perDiem');
    case 'reversal':
      return payableSubType === 'creditNote'
        ? translator('payables.panel.types.creditNote')
        : translator('payables.panel.types.reversal');
    default:
      return '';
  }
};

const getReadableCardPayableType = (
  payableSubType: PayableSubType | undefined,
  translator: TGlobalFunctionTyped,
) => {
  switch (payableSubType) {
    case 'singleUseCard':
      return translator('payables.panel.types.singleUseCardPurchase');
    case 'subscriptionCard':
      return translator('payables.panel.types.subscriptionCardPurchase');
    case 'plasticCard':
      return translator('payables.panel.types.platicCardPurchase');
    default:
      return translator('payables.panel.types.cardPurchase');
  }
};
