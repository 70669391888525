import {
  Button,
  Modal,
  Select,
  FormField,
  SkeletonTag,
} from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { rejectUnexpectedValue } from '../../../../../../../../utils/switchGuard';
import {
  type TenantChooserState,
  type TenantChooserOpenState,
} from '../../../../../../integration/status';

interface Props {
  brandName: string;
  chooserState: TenantChooserState;
  onChooseTenant: (tenantId: string) => Promise<void>;
}

export const ChooseTenantDialog = ({
  brandName,
  chooserState,
  onChooseTenant,
}: Props) => {
  const [tenantId, setTenantId] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation('global');

  const handleChooseTenant = async () => {
    if (!tenantId) {
      throw new Error('Expecting tenantId to have a value once submitting');
    }

    setIsLoading(true);
    await onChooseTenant(tenantId);
    setIsLoading(false);
  };

  return (
    <Modal
      iconName="plug"
      iconVariant="info"
      isOpen={chooserState.kind !== 'closed'}
      title={t('bookkeep.integrations.settings.tenantsModal.title', {
        brandName,
      })}
      subtitle={t('bookkeep.integrations.settings.tenantsModal.description', {
        brandName,
      })}
      actions={[
        <Button
          key="connect"
          text={t('bookkeep.integrations.settings.tenantsModal.connect')}
          variant="primaryBrand"
          onClick={handleChooseTenant}
          isDisabled={isLoading || !tenantId}
          data-testid="test_connectButton"
        />,
      ]}
    >
      {chooserState.kind !== 'closed' ? (
        <FormContent
          chooserState={chooserState}
          tenantId={tenantId}
          setTenantId={setTenantId}
        />
      ) : undefined}
    </Modal>
  );
};

const FormContent = ({
  chooserState,
  tenantId,
  setTenantId,
}: {
  chooserState: TenantChooserOpenState;
  tenantId: string | undefined;
  setTenantId: (newId: string) => void;
}) => {
  const { t } = useTranslation('global');

  switch (chooserState.kind) {
    case 'loading':
      return <SkeletonTag />;
    case 'chooseTenant': {
      const reshapedTenants = (
        chooserState.kind === 'chooseTenant' ? chooserState.tenants : []
      ).map((tenant) => ({
        key: tenant.externalId,
        label: tenant.name,
      }));
      const selectedTenant = reshapedTenants.find(
        (tenant) => tenant.key === tenantId,
      );

      return (
        <>
          <FormField
            label={t('bookkeep.integrations.settings.tenantsModal.selectLabel')}
          >
            <Select
              fit="parent"
              placeholder={t(
                'bookkeep.integrations.settings.tenantsModal.placeholder',
              )}
              options={reshapedTenants}
              value={selectedTenant}
              onSelect={(tenant) => setTenantId(tenant?.key)}
            />
          </FormField>
        </>
      );
    }
    default:
      return rejectUnexpectedValue('chooserState', chooserState);
  }
};
