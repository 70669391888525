import { Button, Callout, SkeletonText } from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { type SupplierAccountDefaultFor } from 'modules/bookkeep/settings/accounting';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import { DefaultSupplierToggle } from './DefaultSupplierToggle';
import {
  type RawData as GetDefaultSupplierAccountQueryResult,
  useGetDefaultSupplierAccountQuery,
} from '../../../../../hooks/useGetDefaultSupplierAccountQuery';

interface Props {
  companyId: string;
  integrationStatus: IntegrationStatusWithIntegration;
}

const defaultSupplierTypes: SupplierAccountDefaultFor[] = [
  'cardSupplier',
  'invoiceSupplier',
];

export function SupplierAccountsPullWithDefaultAccountsSection({
  companyId,
  integrationStatus,
}: Props) {
  const { t } = useTranslation('global');

  const getDefaultSupplierAccountQueryState =
    useGetDefaultSupplierAccountQuery();

  const setSelectedCardSupplierId = useState('')[1];
  const setSelectedInvoiceSupplierId = useState('')[1];

  // Initial selected card and invoice suppliers
  useEffect(() => {
    if (getDefaultSupplierAccountQueryState.status === 'success') {
      if (
        !getDefaultSupplierAccountQueryState.data.supplierAccountForCard
          ?.isArchived
      ) {
        setSelectedCardSupplierId(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
          getDefaultSupplierAccountQueryState.data.supplierAccountForCard?.id!,
        );
      }
      if (
        !getDefaultSupplierAccountQueryState.data.supplierAccountForInvoice
          ?.isArchived
      ) {
        setSelectedInvoiceSupplierId(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
          getDefaultSupplierAccountQueryState.data.supplierAccountForInvoice
            ?.id!,
        );
      }
    }
  }, [getDefaultSupplierAccountQueryState]);

  return (
    <div>
      <QuerySuspense
        queryState={getDefaultSupplierAccountQueryState}
        loading={
          <>
            {defaultSupplierTypes.map((type) => (
              <SkeletonText size="xxl" key={`${type}_loading`} />
            ))}
          </>
        }
        fallback={(error) => (
          <QueryError queryError={error} componentType="Callout" />
        )}
      >
        {(defaultAccounts: GetDefaultSupplierAccountQueryResult) => (
          <>
            <DefaultSupplierToggle
              defaultFor="cardSupplier"
              savedDefaultSupplierFor={defaultAccounts.supplierAccountForCard}
              integrationStatus={integrationStatus}
              t={t}
              onSelectOption={setSelectedCardSupplierId}
            />

            <DefaultSupplierToggle
              defaultFor="invoiceSupplier"
              savedDefaultSupplierFor={
                defaultAccounts.supplierAccountForInvoice
              }
              integrationStatus={integrationStatus}
              t={t}
              onSelectOption={setSelectedInvoiceSupplierId}
            />
          </>
        )}
      </QuerySuspense>
      <Callout
        className="mt-24"
        variant="info"
        title={t(
          'bookkeep.integrations.settings.sectionSupplierAccountsManageTitle',
        )}
      >
        <p className="mb-16">
          {t(
            'bookkeep.integrations.settings.sectionSupplierAccountsManageDescription',
          )}
        </p>
        <Link
          to={routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
            company: companyId,
          })}
          component={(props: { navigate: () => void }) => {
            return (
              <Button
                data-testid="go-to-supplier-page"
                variant="secondaryNeutral"
                text={t(
                  'bookkeep.integrations.settings.sectionSupplierAccountsManageAction',
                )}
                onClick={props.navigate}
              />
            );
          }}
        />
      </Callout>
    </div>
  );
}
