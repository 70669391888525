import {
  colors,
  Icon,
  type CalloutVariant,
  type IconName,
} from '@dev-spendesk/grapes';
import { type ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  type I18nKey,
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import type { EntityStatus } from '../types';

type Props = {
  state: EntityStatus;
  entityId: string;
};

export const EntityItemStatusCallout = ({ state, entityId }: Props) => {
  const { t } = useTranslation('global');

  const calloutAttributes = getCalloutAttributes(state, entityId, t);

  return (
    calloutAttributes && (
      <aside
        data-variant={calloutAttributes.variant}
        className="flex items-center gap-8"
      >
        <Icon
          name={calloutAttributes.iconName}
          size="m"
          color={calloutAttributes.color}
        />
        <p className="body-m" style={{ color: calloutAttributes.color }}>
          {calloutAttributes.title}
        </p>
      </aside>
    )
  );
};

const getCalloutAttributes = (
  state: Props['state'],
  entityId: Props['entityId'],
  t: TGlobalFunctionTyped,
):
  | {
      iconName: IconName;
      variant: CalloutVariant;
      color: string;
      title: string | ReactNode;
    }
  | undefined => {
  if (state === 'awaitingKycApproval') {
    return {
      iconName: 'clock-filled',
      variant: 'info',
      color: colors.contentInfoDefault,
      title: t(statusToI18nKey.awaitingKycApproval),
    };
  }
  if (state === 'kycInProgress') {
    return {
      iconName: 'triangle-warning',
      variant: 'warning',
      color: colors.contentWarningDefault,
      title: (
        <Trans i18nKey={statusToI18nKey[state] as string}>
          <Link
            to={routeFor(routes.ONBOARDING_HUB.path, {
              company: entityId,
            })}
            target="_blank"
            className="leading-[20px] text-warning-default underline title-m"
          >
            -
          </Link>
        </Trans>
      ),
    };
  }
  if (
    state &&
    [
      'churning',
      'churningWithRemainingFunds',
      'churned',
      'churnedWithRemainingFunds',
    ].includes(state)
  ) {
    const key = statusToI18nKey[state] as string;
    return {
      iconName: 'circle-information',
      variant: 'info',
      color: colors.contentInfoDefault,
      title: (
        <Trans i18nKey={key}>
          <Link
            to={routeFor(routes.CHURN.path, {
              company: entityId,
            })}
            target="_blank"
            className="leading-[20px] text-primary underline title-m"
          >
            -
          </Link>
        </Trans>
      ),
    };
  }

  return undefined;
};

const statusToI18nKey: Record<Exclude<EntityStatus, null>, I18nKey> = {
  awaitingKycApproval:
    'organisation.reporting.page.entities.entity.callout.awaitingKycApproval',
  kycInProgress:
    'organisation.reporting.page.entities.entity.callout.kycInProgress',
  churning: 'organisation.reporting.page.entities.entity.callout.churning',
  churningWithRemainingFunds:
    'organisation.reporting.page.entities.entity.callout.churningWithRemainingFunds',
  churned: 'organisation.reporting.page.entities.entity.callout.churned',
  churnedWithRemainingFunds:
    'organisation.reporting.page.entities.entity.callout.churnedWithRemainingFunds',
};
