import * as Money from 'ezmoney';
import React from 'react';

import {
  creditAvailableQueryErrorTranslations,
  useCreditAvailableQuery,
} from '@finance-review/apis/credit-note';
import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { type SupplierId } from '../models';

type Props = {
  supplierId: SupplierId;
  availableCreditCurrency: string;
};

export const SupplierAvailableCredit = ({
  supplierId,
  availableCreditCurrency,
}: Props) => {
  const { t } = useTranslation('global');
  const creditAvailableQueryState = useCreditAvailableQuery(
    { supplierId },
    { isEnabled: true },
  );

  return (
    <QuerySuspense
      queryState={creditAvailableQueryState}
      loading={null}
      fallback={(queryError) => (
        <QueryError
          queryError={queryError}
          componentType="Text"
          translations={creditAvailableQueryErrorTranslations}
        />
      )}
    >
      {({ availableCredit }) => {
        const availableCreditOfCurrency =
          availableCredit[availableCreditCurrency] ??
          Money.fromNumber(0, availableCreditCurrency, 2);

        return (
          <div className="flex justify-between">
            <span className="text-secondary-bg-primary body-m">
              {t('misc.availableCredit')}
            </span>
            <span className="text-primary title-m">
              {formatMonetaryValue(availableCreditOfCurrency)}
            </span>
          </div>
        );
      }}
    </QuerySuspense>
  );
};
