import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';

import { NavLink } from '../NavLink/NavLink';

type Props = Readonly<{
  company: {
    id: string;
  };
}>;

/**
 * Inbox entry
 * Used only by Requester
 */
export const InboxLink = ({ company }: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavLink
      href={routeFor(routes.INBOX_INVOICES.path, {
        company: company.id,
      })}
      iconName="inbox"
    >
      {t('requests.inboxInvoicesSection')}
    </NavLink>
  );
};
