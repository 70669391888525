import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useGetWalletAccountsHistoryQuery } from 'src/core/modules/company/wallet/hooks';

export function useOnboardedWithBankingProvider() {
  const getWalletAccountsHistoryQuery = useGetWalletAccountsHistoryQuery();

  // sort asc order to get bankingProvider from first item
  const getWalletAccountsHistory = unwrapQuery(
    getWalletAccountsHistoryQuery,
  )?.sort((a, b) => {
    if (a.createdAt > b.createdAt) {
      return 1;
    }
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    return 0;
  });

  return (
    getWalletAccountsHistory?.[0]?.bankingProvider
  );
}
