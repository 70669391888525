import {
  Button,
  Callout,
  FormField,
  Link,
  Modal,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { Switch, Route } from 'react-router';
import { useHistory } from 'react-router-dom';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import ApiAccessList from './components/ApiAccessList';
import ApiAccessView from './components/ApiAccessView';
import NewApiAccess from './components/NewApiAccess';
import { type ApiKeyListItem } from './domain';
import { type CreateApiKeyResponse } from './hooks';
import { ErrorState } from '../../../../../common/components/ErrorState';
import { InputCopy } from '../../../../../common/components/InputCopy';
import { useFeature } from '../../../../../common/hooks/useFeature';
import FEATURES from '../../../../../constants/features';
import { routeFor, routes } from '../../../../../constants/routes';
import { useGetPublicApiScopes } from '../../../hooks';

const ApiAccessManagementSection = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const history = useHistory();
  const scopesQueryResult = useGetPublicApiScopes();
  const mustDisplayExperimentalScopes = useFeature(
    FEATURES.PUBLIC_API_DISPLAY_EXPERIMENTAL_SCOPES,
  );
  const [createdKeyState, setCreatedKeyState] = useState<
    CreateApiKeyResponse | undefined
  >();
  const [duplicateKeySource, setDuplicateKeySource] = useState<
    ApiKeyListItem | undefined
  >();

  if (scopesQueryResult.status === 'loading') {
    return (
      <SkeletonTable
        columns={[
          {
            cell: <SkeletonText width="80%" />,
            header: <SkeletonText width="50%" />,
            width: '30%',
          },
          {
            cell: <SkeletonText width="80%" />,
            header: <SkeletonText width="50%" />,
            width: '50%',
          },
          {
            cell: <SkeletonText width="80%" />,
            header: <SkeletonText width="50%" />,
            width: '20%',
          },
        ]}
        withColumnSeparator
        withHeader
      />
    );
  }
  if (scopesQueryResult.status === 'error') {
    return <ErrorState title={t('misc.errorState.title')} />;
  }

  const availableScopes = mustDisplayExperimentalScopes
    ? scopesQueryResult.data
    : scopesQueryResult.data.filter(
        (scope) =>
          Object.hasOwn(scope, 'experimental') && scope.experimental === false,
      );

  return (
    <>
      <Switch>
        <Route path={routes.COMPANY_INTEGRATIONS_API_ACCESS_NEW.path}>
          <NewApiAccess
            scopes={availableScopes}
            onKeyCreated={setCreatedKeyState}
            duplicateKeySource={duplicateKeySource}
          />
        </Route>
        <Route path={routes.COMPANY_INTEGRATIONS_API_ACCESS_VIEW.path}>
          <ApiAccessView
            scopes={availableScopes}
            onGoToDuplicateForm={(sourceKey) => {
              setDuplicateKeySource(sourceKey);
              history.push(
                routeFor(routes.COMPANY_INTEGRATIONS_API_ACCESS_NEW.path, {
                  company: companyId,
                }),
              );
            }}
          />
        </Route>
        <Route>
          <div className="flex flex-col gap-24">
            <div className="flex flex-row items-end">
              <div className="flex-[3]">
                <h2 className="title-xl">
                  {t('publicApi.flowApiKey.main.title')}
                </h2>
                <p className="mt-8 body-m">
                  <Link
                    isExternal
                    href="https://helpcenter.spendesk.com/en/articles/9250246-create-api-credentials"
                    rel="noreferrer"
                  >
                    {t('publicApi.flowApiKey.main.description')}
                  </Link>
                </p>
              </div>
              <Button
                className="self-end"
                text={t('publicApi.flowApiKey.main.button')}
                onClick={() => {
                  setDuplicateKeySource(undefined);
                  history.push(
                    routeFor(routes.COMPANY_INTEGRATIONS_API_ACCESS_NEW.path, {
                      company: companyId,
                    }),
                  );
                }}
              />
            </div>
            <ApiAccessList />
          </div>
        </Route>
      </Switch>
      <Modal
        title={t('publicApi.flowApiKey.main.modal')}
        subtitle=""
        iconName="check"
        iconVariant="success"
        isOpen={!!createdKeyState}
        onClose={() => setCreatedKeyState(undefined)}
      >
        <>
          <Callout
            title={t('publicApi.flowApiKey.main.warning')}
            variant="warning"
            className="mb-24"
          >
            {t('publicApi.flowApiKey.main.callout')}
          </Callout>
          <FormField
            label={t('publicApi.flowApiKey.main.apiId')}
            htmlFor="create-api-key-result-id"
            className="mb-24"
          >
            <InputCopy value={createdKeyState?.apiId ?? ''} />
          </FormField>
          <FormField
            label={t('publicApi.flowApiKey.main.apiSecret')}
            htmlFor="create-api-key-result-secret"
            className="mb-24"
          >
            <InputCopy value={createdKeyState?.apiSecret ?? ''} />
          </FormField>
        </>
      </Modal>
    </>
  );
};

export default withErrorBoundary({
  scope: 'api-keys-content',
  team: 'api-integration',
})(ApiAccessManagementSection);
