import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import {
  type ForeignExchangeAccount,
  type ForeignExchangeAccountDifferenceType,
} from '../../accounting/foreignExchangeAccount';

type ForeignExchangeAccountUpdatePayload = {
  id?: string;
  capabilityKind: 'pullAndSelect';
  differenceType: ForeignExchangeAccountDifferenceType;
  newCode: string;
};

type ForeignExchangeAccountUpdateSuccess = {
  outcome: 'set';
  foreignExchangeAccount: ForeignExchangeAccount;
};

type ForeignExchangeAccountUpdateError = {
  outcome: 'notSet';
  reason: 'errorSavingForiegnExchangeAccount';
};

export const useUpdateForeignExchangeAccountsMutation = (): MutationState<
  ForeignExchangeAccountUpdatePayload,
  ForeignExchangeAccountUpdateSuccess,
  ForeignExchangeAccountUpdateError
> => {
  return useMutation<
    ForeignExchangeAccountUpdatePayload,
    ForeignExchangeAccountUpdateSuccess,
    ForeignExchangeAccountUpdateSuccess,
    ForeignExchangeAccountUpdateError
  >({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint:
        '/accounting-integration/chart-of-accounts/foreign-exchange-accounts',
    },
    reshapeData(data) {
      return data;
    },
    options: {
      throwOnError: true,
      onSuccess({ client }): void {
        client.invalidateQueries(['getForeignExchangeAccounts']);
        client.invalidateQueries(['useIntegrationStatusQuery']);
      },
    },
  });
};
