import { SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import { type I18nKey } from 'common/hooks/useTranslation';
import { useAccountingIntegrationStatusQuery } from 'modules/accounting-integration/apis';
import { useExpenseAccountQuery } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/hooks/useExpenseAccountQuery';
import { useExpenseAccountsQuery } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/hooks/useExpenseAccountsQuery';
import { type ItemLineExpenseAccount } from 'modules/bookkeep/prepare-payables/models';
import {
  type ExpenseAccountAutomation,
  type ExpenseAmountAutomation,
} from 'modules/payable/models';

import { PayableExpenseAccountField } from './PayableExpenseAccountField';

type Props = {
  values: ItemLineExpenseAccount;
  initialValues: ItemLineExpenseAccount | null;
  errors?: {
    accountId?: string;
    amount?: string;
  };
  hasAmountError: boolean;
  accountAutomation?: ExpenseAccountAutomation;
  amountAutomation?: ExpenseAmountAutomation;
  shouldDisplayAmountValue: boolean;
  currency: string;
  expenseAccountPlaceholder?: I18nKey;
  onChange: (value: ItemLineExpenseAccount) => void;
  className?: string;
};

export const PayableExpenseAccountFieldContainer = ({
  values,
  initialValues,
  errors,
  hasAmountError,
  currency,
  accountAutomation,
  amountAutomation,
  shouldDisplayAmountValue,
  expenseAccountPlaceholder,
  onChange,
  className,
}: Props) => {
  const expenseAccountsQuery = useExpenseAccountsQuery({
    includeArchived: false,
    isAvailable: true,
  });

  const isExpenseAccountQueryEnabled = Boolean(values.accountId);

  const expenseAccountQuery = useExpenseAccountQuery(
    values.accountId,
    isExpenseAccountQueryEnabled,
  );

  const integrationStatusQuery = useAccountingIntegrationStatusQuery();

  if (
    expenseAccountsQuery.status !== 'success' ||
    (isExpenseAccountQueryEnabled &&
      expenseAccountQuery.status !== 'success') ||
    integrationStatusQuery.status !== 'success'
  ) {
    return <SkeletonText className="mb-4 !h-[30px]" />;
  }

  const expenseAccount =
    isExpenseAccountQueryEnabled && expenseAccountQuery.status === 'success'
      ? expenseAccountQuery.data
      : undefined;

  return (
    <PayableExpenseAccountField
      values={values}
      initialValues={initialValues}
      errors={errors}
      hasAmountError={hasAmountError}
      expenseAccounts={expenseAccountsQuery.data}
      expenseAccount={expenseAccount}
      currency={currency}
      accountAutomation={accountAutomation}
      amountAutomation={amountAutomation}
      shouldDisplayAmountValue={shouldDisplayAmountValue}
      expenseAccountPlaceholder={expenseAccountPlaceholder}
      integrationStatus={integrationStatusQuery.data}
      onChange={onChange}
      className={className}
    />
  );
};
