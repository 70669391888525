import {
  CalendarRange,
  Popover,
  type DateRange,
  type PopoverProps,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { DateRangeActions } from 'src/core/common/components/DateRangeFormField';

type Props = {
  period: DateRange;
  setPeriod(period: DateRange): void;
  onReset?: () => void;
  renderTrigger: PopoverProps['renderTrigger'];
  placement?: PopoverProps['placement'];
};

export const PeriodFilter = ({
  period,
  setPeriod,
  onReset,
  renderTrigger,
  placement = 'bottom-start',
}: Props) => {
  const [range, setRange] = useState<DateRange>(period);

  const onRangeSelect = (newRange: DateRange, onFull: () => void) => {
    setRange(newRange);
    const isFull = newRange.every(Boolean);
    if (isFull) {
      setPeriod(newRange);
      onFull();
    }
  };

  return (
    <Popover placement={placement} renderTrigger={renderTrigger}>
      {(closeDropdown) => (
        <div aria-label="Calendar Event Date">
          <div className="px-16 pt-16">
            <CalendarRange
              value={range}
              onClick={(newRange) => {
                onRangeSelect(newRange, closeDropdown);
              }}
            />
          </div>
          <DateRangeActions
            className="border-0 border-t border-solid border-default"
            onChange={(newValue) => {
              if (newValue.every(Boolean)) {
                onRangeSelect(newValue, closeDropdown);
              } else if (onReset) {
                onReset();
                closeDropdown();
              } else {
                onRangeSelect(period, closeDropdown);
              }
            }}
          />
        </div>
      )}
    </Popover>
  );
};
