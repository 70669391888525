import {
  SkeletonAvatar,
  SkeletonButton,
  SkeletonText,
} from '@dev-spendesk/grapes';

export const Loading = () => {
  return (
    <div className="flex flex-col gap-8 pb-16">
      <SkeletonButton width="183px" />
      <div className="my-8 flex items-center gap-8">
        <SkeletonAvatar />
        <SkeletonText size="m" width="180px" />
      </div>
      <SkeletonButton width="142px" />
      <SkeletonButton width="100%" />
    </div>
  );
};
