import type { NavigationLinkProps } from 'src/core/common/components/NavigationLink/NavigationLink';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';

export const MembersKey = 'members';
export const CostCenterKey = 'cost-centers';
export const CompanyTeamsKey = 'company-teams';
export const CustomFieldsKey = 'custom-fields';

/**
 * Returns a list of accessible pages within the Organisation scope.
 * If the list is empty, then Organisation entry and associated pages shouldn't be display.
 * The order in the list corresponds to that displayed on the Organization header.
 */
export function useOrganisationLinks() {
  const { t } = useTranslation('global');
  const company = useCompany();
  const user = useUser();
  const isAoOrController = user.is_account_owner || user.is_controller;

  const isCostCentersSettingsEnabled = useFeature(
    FEATURES.SETTINGS_COST_CENTERS,
  );
  const isCostCentersFeatureEnabled = useFeature(FEATURES.COST_CENTERS);
  const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);
  const hasTeamsFeature = useFeature(FEATURES.TEAMS);

  const links: NavigationLinkProps[] = [];

  if (!company.churning_at && (user.is_account_owner || user.is_admin)) {
    links.push({
      key: MembersKey,
      path: routeFor(routes.COMPANY_MEMBERS.path, {
        company: company.id,
        id: '',
      }),
      text: t('settings.links.members'),
    });
  }

  if (
    isCostCentersFeatureEnabled &&
    isCostCentersSettingsEnabled &&
    (user.is_account_owner || user.is_admin)
  ) {
    links.push({
      key: CostCenterKey,
      path: routeFor(routes.COST_CENTERS.path, {
        company: company.id,
      }),
      text: t('settings.links.costCenters'),
    });
  }

  if (hasTeamsFeature) {
    links.push({
      key: CompanyTeamsKey,
      text: t('members.navLinks.teams'),
      path: routeFor(routes.COMPANY_TEAMS.path, {
        company: company.id,
      }),
    });
  }

  if (hasCustomFieldsFeature && (isAoOrController || user.is_admin)) {
    links.push({
      key: CustomFieldsKey,
      path: routeFor(routes.COMPANY_CATEGORIES.path, {
        company: company.id,
      }),
      text: t('settings.links.customFields'),
    });
  }

  return links;
}
