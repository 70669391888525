import {
  colors,
  Button,
  DropdownItem,
  Icon,
  Input,
} from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { FilteredListBySearchWrapper } from 'src/core/modules/organisation-reporting/components/common/FilteredListBySearchWrapper';

import { type CompanyWalletSummary } from '../../../companyWalletSummary';

type Props = {
  companies: CompanyWalletSummary[];
  multiEntityHubLink: React.ReactNode | null;
  onCompanyClick(company: CompanyWalletSummary): void;
  openNewEntityButton: { label: string; onClick: () => void } | undefined;
  closeDropdown(): void;
};

export const CompanyDropdownWithSearch = ({
  companies,
  multiEntityHubLink,
  onCompanyClick,
  openNewEntityButton,
  closeDropdown,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="flex w-[376px] flex-col content-stretch p-16 pb-8">
      {multiEntityHubLink && (
        <div className="mb-8 flex flex-col content-stretch">
          {multiEntityHubLink}
        </div>
      )}

      <FilteredListBySearchWrapper
        items={companies.sort((a, b) => a.name.localeCompare(b.name))}
        searchableFields={['name']}
        isSearchEnabled
      >
        {({ filteredItems, search, setSearch }) => (
          <div className="mb-8 flex flex-col gap-12">
            <Input
              className="min-w-[344px]"
              leftAddon={
                <Icon
                  color={colors.contentDecorativeIcon}
                  name="magnifying-glass"
                  className="ml-16 mr-8"
                />
              }
              placeholder={t(
                'organisation.reporting.page.entities.searchPlaceholder',
              )}
              onChange={(event) => setSearch(event.target.value)}
              value={search}
            />
            <hr className="separator" style={{ borderTop: 'none' }} />
            <div className="flex max-h-[60vh] flex-col content-stretch overflow-auto">
              {filteredItems.map((entity) => (
                <button
                  key={entity.id}
                  type="button"
                  className="border-none bg-primary-default outline-none"
                  onClick={() => {
                    onCompanyClick(entity);
                    closeDropdown();
                  }}
                >
                  <DropdownItem
                    label={entity.name}
                    className="rounded-8 p-8 text-left text-primary body-m"
                  />
                </button>
              ))}
            </div>
          </div>
        )}
      </FilteredListBySearchWrapper>
      {openNewEntityButton && (
        <div
          className="flex flex-col content-stretch gap-16 border-default"
          style={{
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
        >
          <Button
            variant="tertiaryBrand"
            text={openNewEntityButton.label}
            onClick={openNewEntityButton.onClick}
            className="mt-8"
          />
        </div>
      )}
    </div>
  );
};
