import {
  SkeletonAvatar,
  SkeletonCheckbox,
  SkeletonText,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import styles from '../../OperationalOnboarding.module.css';
import { OperationalOnboardingHeader } from '../OperationalOnboardingHeader';

const LOADER_ROWS = 8;

export const OperationalOnboardingSkeleton = () => {
  return (
    <div
      className={classNames(styles.OperationalOnboarding, 'w-full', 'mb-64')}
    >
      <OperationalOnboardingHeader />
      {Array.from({ length: LOADER_ROWS }, (_, row) => (
        <div className="flex items-center justify-between p-16" key={row}>
          <div className="flex items-center gap-16">
            <SkeletonAvatar size="s" />
            <SkeletonText width="166px" size="xl" />
          </div>
          <SkeletonCheckbox />
        </div>
      ))}
    </div>
  );
};
