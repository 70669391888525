import React from 'react';

import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { CancelTransfersButton } from './CancelTransfersButton';
import { ConfirmTransfersButton } from './ConfirmTransfersButton';
import { WalletBalance } from './WalletBalance';
import { selectors, useSelectorWithContext } from '../../../redux';

type Props = {
  canApproveTransfers: boolean;
};

export const WithTransfersSelected = ({ canApproveTransfers }: Props) => {
  const transfersSelectedAmountSum = useSelectorWithContext(
    selectors.selectTransfersSelectedAmountSum,
  );

  return (
    <section className="flex place-content-between items-center gap-16">
      <div className="basis-[45%] text-secondary-bg-primary body-s">
        <div className="text-primary body-xl">
          {formatMonetaryValue(transfersSelectedAmountSum)}
        </div>
        <WalletBalance canApproveTransfers={canApproveTransfers} />
      </div>
      <div>
        <CancelTransfersButton />
        {canApproveTransfers && <ConfirmTransfersButton />}
      </div>
    </section>
  );
};
