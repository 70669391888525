import { Skeleton } from '@dev-spendesk/grapes';
import { useMemo } from 'react';

import type { UserTask } from 'modules/onboarding/setup-hub/types/userTask';
import { type HubSection } from 'modules/onboarding/types/hubSection';
import type { HubTask } from 'modules/onboarding/types/hubTask';
import { LocalStorageKey } from 'src/core/constants/storage';
import { getLocalItem } from 'src/core/utils/storage';

import { HubSectionWrapper } from './components/HubSectionWrapper';

type Props = {
  tasksBySection: HubSection<HubTask | UserTask>[] | undefined;
};

export const TaskList = ({ tasksBySection }: Props) => {
  const sectionOpenByDefault = useMemo(() => {
    if (!tasksBySection) {
      return;
    }
    const lastSectionIdOpened = getLocalItem(
      LocalStorageKey.LastOnboardingSectionOpened,
      true,
    );
    if (lastSectionIdOpened) {
      const lastSectionOpened = tasksBySection.find(
        (section) => section.id === lastSectionIdOpened,
      );
      if (
        lastSectionOpened &&
        lastSectionOpened.tasks.some((task) => task.status === 'ongoing')
      ) {
        return lastSectionOpened;
      }
    }
    return tasksBySection.find((section) =>
      section.tasks.some((task) => task.status === 'ongoing'),
    );
  }, [tasksBySection]);

  if (!tasksBySection) {
    return (
      <div className="flex flex-col gap-24">
        <Skeleton height="112px" width="100%" />
        <Skeleton height="112px" width="100%" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-24">
      {tasksBySection.map((section) => {
        return (
          <HubSectionWrapper
            key={section.id}
            section={section}
            title={section.title}
            isOpen={sectionOpenByDefault?.id === section.id}
          />
        );
      })}
    </div>
  );
};
