import { Callout, type IconName } from '@dev-spendesk/grapes';
import React, { type ReactNode } from 'react';

import { ContactUs } from 'common/components/ContactUs';
import { PaymentMethod, type PaymentMethodsByIssuer } from 'modules/company';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { PaymentMethodOptions } from './PaymentMethodOptions';

export enum PayableType {
  ExpenseClaim = 'expense-claim',
  Invoice = 'invoice',
}

export interface PaymentMethodDescriptor {
  title: I18nKey;
  description: I18nKey;
  icon: IconName;
}

export type PaymentDescriptorMap = Map<PaymentMethod, PaymentMethodDescriptor>;

export const paymentMethodsDescriptorForExpenseClaim: PaymentDescriptorMap =
  new Map([
    [
      PaymentMethod.WireTransfer,
      {
        title: 'forms.paymentMethod.wireTransferTitleExpenseClaim',
        description: 'forms.paymentMethod.wireTransferDescriptionExpenseClaim',
        icon: 'spendesk',
      },
    ],
    [
      PaymentMethod.XmlSepa,
      {
        title: 'forms.paymentMethod.xmlSepaTitleExpenseClaim',
        description: 'forms.paymentMethod.xmlSepaDescriptionExpenseClaim',
        icon: 'building-bank',
      },
    ],
    [
      PaymentMethod.Csv,
      {
        title: 'forms.paymentMethod.csvTitleExpenseClaim',
        description: 'forms.paymentMethod.csvDescriptionExpenseClaim',
        icon: 'building-bank',
      },
    ],
  ]);

export const paymentMethodsDescriptorForInvoice: PaymentDescriptorMap = new Map(
  [
    [
      PaymentMethod.WireTransfer,
      {
        title: 'forms.paymentMethod.wireTransferTitleInvoice',
        description: 'forms.paymentMethod.wireTransferDescriptionInvoice',
        icon: 'spendesk',
      },
    ],
    [
      PaymentMethod.XmlSepa,
      {
        title: 'forms.paymentMethod.xmlSepaTitleInvoice',
        description: 'forms.paymentMethod.xmlSepaDescriptionInvoice',
        icon: 'building-bank',
      },
    ],
    [
      PaymentMethod.Csv,
      {
        title: 'forms.paymentMethod.csvTitleInvoice',
        description: 'forms.paymentMethod.csvDescriptionInvoice',
        icon: 'building-bank',
      },
    ],
  ],
);

const getDescriptorsMatchingPaymentMethods = (
  paymentMethods: PaymentMethod[],
  paymentDescriptors: PaymentDescriptorMap,
): PaymentDescriptorMap => {
  const validPaymentDescriptors: PaymentDescriptorMap = new Map();

  for (const [key, descriptor] of paymentDescriptors) {
    if (paymentMethods.includes(key)) {
      validPaymentDescriptors.set(key, descriptor);
    }
  }

  return validPaymentDescriptors;
};

export const getValidPaymentsDescriptorsForInvoices = ({
  paymentMethodsByIssuer,
  isWireTransferEnabled,
}: {
  paymentMethodsByIssuer: PaymentMethodsByIssuer;
  isWireTransferEnabled: boolean;
}): PaymentDescriptorMap => {
  const fromBankPaymentMethods: PaymentMethod[] = [
    ...paymentMethodsByIssuer.fromBank,
  ];
  const fromBankValidPaymentDescriptors = getDescriptorsMatchingPaymentMethods(
    fromBankPaymentMethods,
    paymentMethodsDescriptorForInvoice,
  );

  const getFromSpendeskValidPaymentDescriptors = (): PaymentDescriptorMap => {
    const fromSpendeskPaymentMethod = paymentMethodsByIssuer.fromSpendesk[0];
    return fromSpendeskPaymentMethod
      ? getDescriptorsMatchingPaymentMethods(
          [fromSpendeskPaymentMethod],
          paymentMethodsDescriptorForInvoice,
        )
      : new Map();
  };

  const fromSpendeskValidPaymentDescriptors = isWireTransferEnabled
    ? getFromSpendeskValidPaymentDescriptors()
    : new Map();

  const validPaymentMethodsDescriptors: PaymentDescriptorMap = new Map([
    ...fromSpendeskValidPaymentDescriptors,
    ...fromBankValidPaymentDescriptors,
  ]);

  return validPaymentMethodsDescriptors;
};

export const getValidPaymentsDescriptorsForExpenseClaims = ({
  paymentMethodsByIssuer,
  isWireTransferEnabled,
}: {
  paymentMethodsByIssuer: PaymentMethodsByIssuer;
  isWireTransferEnabled: boolean;
}): PaymentDescriptorMap => {
  const fromBankPaymentMethods: PaymentMethod[] = [
    ...paymentMethodsByIssuer.fromBank,
  ];
  const fromBankValidPaymentDescriptors = getDescriptorsMatchingPaymentMethods(
    fromBankPaymentMethods,
    paymentMethodsDescriptorForExpenseClaim,
  );

  const getFromSpendeskValidPaymentDescriptors = (): PaymentDescriptorMap => {
    const fromSpendeskPaymentMethod = paymentMethodsByIssuer.fromSpendesk[0];

    return fromSpendeskPaymentMethod
      ? getDescriptorsMatchingPaymentMethods(
          [fromSpendeskPaymentMethod],
          paymentMethodsDescriptorForExpenseClaim,
        )
      : new Map();
  };

  const fromSpendeskValidPaymentDescriptors = isWireTransferEnabled
    ? getFromSpendeskValidPaymentDescriptors()
    : new Map();

  const validPaymentMethodsDescriptors: PaymentDescriptorMap = new Map([
    ...fromSpendeskValidPaymentDescriptors,
    ...fromBankValidPaymentDescriptors,
  ]);

  return validPaymentMethodsDescriptors;
};

interface PaymentMethodDropdownProps {
  value: PaymentMethod;
  paymentMethodsByIssuer: PaymentMethodsByIssuer;
  type: PayableType;
  renderBefore?: React.ReactNode;
  onSelect: (paymentMethod: PaymentMethod) => void;
  getIsPaymentMethodDisabled?: (paymentMethod: PaymentMethod) => boolean;
  getPaymentMethodHintMessage?: (paymentMethod: PaymentMethod) => ReactNode;
}

export const PaymentMethodDropdown = ({
  value,
  paymentMethodsByIssuer,
  type,
  renderBefore,
  onSelect,
  ...rest
}: PaymentMethodDropdownProps) => {
  const { t } = useTranslation('global');

  const isWireTransferForExpenseClaimsEnabled = useFeature(
    FEATURES.WIRE_TRANSFER_FOR_EXPENSE_CLAIMS,
  );

  const isWireTransferForInvoicesEnabled = useFeature(
    FEATURES.WIRE_TRANSFER_FOR_INVOICES,
  );

  const validPaymentMethodsDescriptors =
    type === PayableType.Invoice
      ? getValidPaymentsDescriptorsForInvoices({
          paymentMethodsByIssuer,
          isWireTransferEnabled: isWireTransferForInvoicesEnabled,
        })
      : getValidPaymentsDescriptorsForExpenseClaims({
          paymentMethodsByIssuer,
          isWireTransferEnabled: isWireTransferForExpenseClaimsEnabled,
        });

  const paymentMethodLabelKey =
    type === PayableType.Invoice
      ? 'forms.paymentMethod.labelInvoice'
      : 'forms.paymentMethod.labelExpenseClaim';

  const selectedPaymentMethodDescriptor =
    validPaymentMethodsDescriptors.get(value);

  if (!selectedPaymentMethodDescriptor) {
    return (
      <Callout
        title={t('forms.paymentMethod.error.message', {
          paymentMethod: value,
        })}
        className="mt-8"
        variant="alert"
      >
        <div className="flex justify-center">
          <ContactUs
            buttonVariant="tertiaryNeutral"
            hasNegativeMargins
            text={t('forms.paymentMethod.error.button')}
          />
        </div>
      </Callout>
    );
  }

  return (
    <section className="PaymentMethod" aria-labelledby="paymentMethods">
      <h3 id="paymentMethods" className="mb-8 text-primary title-m">
        {t(paymentMethodLabelKey)}
      </h3>
      {renderBefore}
      <PaymentMethodOptions
        paymentMethodDescriptors={validPaymentMethodsDescriptors}
        paymentMethodSelected={value}
        onSelectPaymentMethod={onSelect}
        {...rest}
      />
    </section>
  );
};
