import { SkeletonAvatar, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const SupplierOverviewFormFieldSkeleton = () => {
  return (
    <div className="mb-16 flex h-[36px] items-center gap-x-8">
      <SkeletonAvatar variant="square" />
      <SkeletonText width="96px" size="xl" />
    </div>
  );
};
