import {
  track,
  AnalyticEventName,
  type AnalyticEventPayloadDefinition,
} from 'src/core/utils/analytics';

import {
  type MemberDetails,
  type DraftMember,
  getMemberRoleList,
} from '../models/member';

const getTrackingPayloadFromMember = (member: MemberDetails) => ({
  userId: member.id,
  additionalRights: [
    ...(member.canConfirmPayments ? ['canConfirmPayments'] : []),
  ],
  roles: getMemberRoleList(member),
});

const getTrackingPayloadFromDraftMember = (draftMember: DraftMember) => {
  return {
    additionalRights: [
      ...(draftMember.canConfirmPayments ? ['canConfirmPayments'] : []),
    ],
    roles: draftMember.roles,
  };
};

export const onMemberDetailsModalOpened = ({
  member,
}: {
  member: MemberDetails;
}): void => {
  track(
    AnalyticEventName.MEMBER_DETAILS_MODAL_OPENED,
    getTrackingPayloadFromMember(
      member,
    ) as AnalyticEventPayloadDefinition[AnalyticEventName.MEMBER_DETAILS_MODAL_OPENED],
  );
};

export const onEditMemberDetailsModalOpened = ({
  member,
}: {
  member: MemberDetails;
}): void => {
  track(
    AnalyticEventName.MEMBER_EDIT_DETAILS_MODAL_OPENED,
    getTrackingPayloadFromMember(
      member,
    ) as AnalyticEventPayloadDefinition[AnalyticEventName.MEMBER_EDIT_DETAILS_MODAL_OPENED],
  );
};

export const onEditMemberDetailsModalClosed = (): void => {
  track(AnalyticEventName.MEMBER_EDIT_DETAILS_MODAL_CLOSED);
};

export const onEditMemberDetailsSaved = ({
  member,
  draftMember,
}: {
  member: MemberDetails;
  draftMember: DraftMember;
}): void => {
  const payload = {
    userId: member.id,
    valueBefore: getTrackingPayloadFromMember(member),
    valueAfter: getTrackingPayloadFromDraftMember(draftMember),
  };
  track(AnalyticEventName.MEMBER_EDIT_DETAILS_SAVED, payload);
};

export const onEditMemberDetailsChangesApplied = ({
  member,
  draftMember,
}: {
  member: MemberDetails;
  draftMember: DraftMember;
}): void => {
  const payload = {
    userId: member.id,
    valueBefore: getTrackingPayloadFromMember(member),
    valueAfter: getTrackingPayloadFromDraftMember(draftMember),
  };
  track(AnalyticEventName.MEMBER_EDIT_DETAILS_CHANGES_APPLIED, payload);
};
