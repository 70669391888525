import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { InvoicePatternBuilder } from 'modules/bookkeep/settings/export-legacy/components/InvoicePatternBuilder';
import { useMarkHubTaskAsDoneMutation } from 'modules/onboarding/components/MarkAsDoneButton/hooks/useMarkHubTaskAsDoneMutation';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { useHubTaskByType } from 'modules/onboarding/setup-hub/hooks/useHubTaskByType';
import { useUpdateCachedTaskStatus } from 'modules/onboarding/setup-hub/pages/SetupHubPage/hooks/useUpdateCachedTaskStatus';
import { routes } from 'modules/onboarding/setup-hub/pages/routes';
import { routeFor } from 'src/core/constants/routes';

export const AccountingReceiptFileName = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const history = useHistory();
  const updateCachedTaskStatus = useUpdateCachedTaskStatus();
  const task = useHubTaskByType('receipt_file_name');
  const [markTaskAsDone] = useMarkHubTaskAsDoneMutation();

  const onNext = async () => {
    // Users could go through the task without setting anything
    // so we are manually marking it as done, as an extra safety net
    if (task) {
      try {
        await markTaskAsDone({ endpointParams: { taskId: task.id } });
      } catch {
        // Not doing anything, because this safety net is not critical
      }
    }
    updateCachedTaskStatus('receipt_file_name');
    openSetupHubTaskList();
  };

  const openSetupHubTaskList = () => {
    const previousPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(previousPath);
  };

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.receiptFileName.description')}
        isLastStep
        onBack={openSetupHubTaskList}
        onNext={onNext}
        title={t('setupHub.receiptFileName.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('misc.needHelp'),
        }}
      >
        <>
          <div className="mb-16 title-m">
            {t('setupHub.receiptFileName.questionLabel')}
          </div>
          <InvoicePatternBuilder />
        </>
      </TaskStepLayout>
    </div>
  );
};
