import { Skeleton } from '@dev-spendesk/grapes';
import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import {
  type CompanyBillingStatus,
  useFetchCompanyBillingStatus,
} from 'src/core/modules/billing/hooks/useFetchCompanyBillingStatus';
import { logger } from 'src/utils/datadog-log-wrapper';

import { ClosedDoor } from './ClosedDoor';
import { NavBar } from './components/NavBar';
import './ClosedDoor.css';
import { useCompany } from '../../app/hooks/useCompany';
import { useHasAccessToMultiEntityHub } from '../../organisation-reporting/hooks/useHasAccessToMultiEntityHub';

type ClosedDoorContentProps = {
  billingStatus: CompanyBillingStatus | undefined;
};

export const ClosedDoorLayoutContainer = () => {
  const { t, localeFormat } = useTranslation('global');
  const company = useCompany();

  const billingStatusQueryState = useFetchCompanyBillingStatus(company.id);

  const hasAccessToMultiEntityHub = useHasAccessToMultiEntityHub();

  const isBillingStatusInvalid = (
    billingStatus: CompanyBillingStatus,
  ): boolean => {
    if (billingStatus.status.billingInvoicePaymentStatus !== 'delinquent') {
      logger.error('Company is not delinquent', {
        scope: 'billing',
        team: 'monetisation',
      });
      return true;
    }

    const overdueInvoices = billingStatus.status.overdueInvoicesDetails.filter(
      (invoice) => invoice.paymentOverDueAt,
    );

    if (!overdueInvoices.length) {
      logger.error('No overdue invoices found', {
        scope: 'billing',
        team: 'monetisation',
      });
      return true;
    }

    return false;
  };

  const handleRedirect = () => {
    window.location.href = '/app';
  };

  const renderClosedDoorContent = ({
    billingStatus,
  }: ClosedDoorContentProps) => {
    if (!billingStatus) {
      logger.error('Billing status is undefined', {
        scope: 'billing',
        team: 'monetisation',
      });
      handleRedirect();
      return null;
    }

    if (isBillingStatusInvalid(billingStatus)) {
      handleRedirect();
      return null;
    }

    return (
      <ClosedDoor
        t={t}
        companyCurrency={company.currency}
        localeFormat={localeFormat}
        billingStatus={billingStatus.status}
      />
    );
  };

  return (
    <div className="page__container">
      <div className="ClosedDoorContainer bg-page-background flex flex-col gap-24 pb-[70px]">
        <NavBar
          t={t}
          hasAccessToMultiEntityHub={hasAccessToMultiEntityHub}
          companyId={company.id}
        />
        <div className="ClosedDoor size-full px-[80px] pt-[90px]">
          <QuerySuspense
            queryState={billingStatusQueryState}
            loading={<Skeleton height="115px" width="100%" />}
            fallback={(error) => (
              <QueryError
                componentType="ErrorState"
                queryError={error}
                translations={{
                  networkError: 'misc.errors.networkError',
                  serverError: 'misc.errors.serverError',
                  requestError: () => 'misc.errors.networkError',
                }}
              />
            )}
          >
            {(billingStatus: CompanyBillingStatus | undefined) =>
              renderClosedDoorContent({
                billingStatus,
              })
            }
          </QuerySuspense>
        </div>
      </div>
    </div>
  );
};
