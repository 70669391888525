import {
  Button,
  Callout,
  DropdownItem,
  DropdownMenu,
  Icon,
  IconButton,
  type IconName,
  Modal,
  Popover,
  Tooltip,
  type TriggerProps,
} from '@dev-spendesk/grapes';
import { type IconSize } from '@dev-spendesk/grapes/dist/components/Icon/Icon';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useModal } from 'common/hooks/useModalGrapes';
import { type QueryError } from 'src/core/api/queryError';
import { type QueryState } from 'src/core/api/queryState';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useNotifications } from 'src/core/modules/app/notifications';
import { logger } from 'src/utils/datadog-log-wrapper';

import {
  ActiveTriggerProductTour,
  DoneTriggerProductTour,
  EmptyTriggerProductTour,
} from './FilterBuilderFeaturePromotion';
import { FilterBuilderForm, useFilterBuilder } from './FilterBuilderForm';
import {
  type SavedFilter,
  type SelectedFilterWithId,
  type SubfilterValueWithId,
  type SelectedFilter,
  type Field,
  fromFilterUrlParameter,
  toFilterUrlParameter,
} from './FilterBuilderModel';
import { useQueryError } from '../QueryError';

export const FilterBuilder = ({
  fields,
  filterIdUrlParameterName,
  filterUrlParameterName,
  savedFiltersQuery,
  renderSubfilterValue,
  resetPath,
  ...props
}: {
  fields: Field[];
  filterIdUrlParameterName: string;
  filterUrlParameterName: string;
  savedFiltersQuery: QueryState<(SavedFilter & { disabledMessage?: string })[]>;
  createFilter(filter: SelectedFilter): Promise<SavedFilter>;
  getCreateFilterErrorMessage?(
    queryError: QueryError<unknown>,
    translationsParams?: TranslationParams,
  ): string;
  updateFilter(filter: SelectedFilter): Promise<SavedFilter>;
  getUpdateFilterErrorMessage?(
    queryError: QueryError<unknown>,
    translationsParams?: TranslationParams,
  ): string;
  deleteFilter(filterId: string): Promise<void>;
  getDeleteFilterErrorMessage?(
    queryError: QueryError<unknown>,
    translationsParams?: TranslationParams,
  ): string;
  resetPath?: string;
  renderSubfilterValue(params: {
    subfilterValue: SubfilterValueWithId;
    updateSubfilterValue(newSubfilterValue: SubfilterValueWithId): void;
    subfilterOperatorName: string;
    subfilterValueName: string;
  }): React.ReactNode;
}) => {
  const history = useHistory();

  const savedFilters = useMemo(
    () => unwrapQuery(savedFiltersQuery) ?? [],
    [
      savedFiltersQuery.status === 'success'
        ? savedFiltersQuery.data
            .map((f) => f.id + f.name + toFilterUrlParameter(f, fields))
            .join(',')
        : null,
    ],
  );

  const activeFilter = useActiveFilter({
    filterIdUrlParameterName,
    filterUrlParameterName,
    savedFilters,
    fields,
  });

  const [selectedFilter, dispatchFilterAction] = useFilterBuilder(activeFilter);

  const memoizedFields = useMemo(
    () => fields,
    [
      fields
        .flatMap((f) => f.options ?? f)
        .map((o) => o.key)
        .join(','),
    ],
  );

  // Keep selected filter in sync with fields
  useEffect(() => {
    const selectedFiltersCount = selectedFilter?.filter.subfilters.reduce(
      (accumulator, subfilterGroup) =>
        accumulator +
        subfilterGroup.subfilters.filter(({ field }) => field).length,
      0,
    );

    const activefiltersCount = activeFilter?.filter.subfilters.reduce(
      (accumulator, subfilterGroup) =>
        accumulator +
        subfilterGroup.subfilters.filter(({ field }) => field).length,
      0,
    );

    if (!selectedFiltersCount && activefiltersCount) {
      dispatchFilterAction({ type: 'reset', filter: activeFilter });
    }
  }, [memoizedFields]);

  // Update selected filter when active filter changes
  useEffect(() => {
    if (activeFilter?.id && activeFilter.id !== selectedFilter.id) {
      dispatchFilterAction({ type: 'reset', filter: activeFilter });
    }
  }, [activeFilter]);

  const applyFilter = async (filter: SelectedFilter | undefined) => {
    const newCurrentSearchParams = new URLSearchParams(history.location.search);
    newCurrentSearchParams.delete(filterUrlParameterName);
    newCurrentSearchParams.delete(filterIdUrlParameterName);
    let search = newCurrentSearchParams.toString();

    if (filter) {
      if (filter.id) {
        newCurrentSearchParams.set(filterIdUrlParameterName, filter.id);
      }
      // Not using "currentSearchParams.set()" because it automatically encodes the value and we don't want that
      // eg: "filterUrlParameterName=and[or[[payableType|is|subscription,creditNote]]]"
      search = `${newCurrentSearchParams.toString()}&${filterUrlParameterName}=${toFilterUrlParameter(filter, fields)}`;
    }

    history.push({
      ...history.location,
      search,
      pathname: resetPath ?? history.location.pathname,
    });

    dispatchFilterAction({ type: 'reset', filter });
  };

  const createFilterError = useCreatePayableFilterErrorMessage();

  const createFilter = async (filter: SelectedFilter) => {
    try {
      return await props.createFilter(filter);
    } catch (error) {
      throw new Error(
        props.getCreateFilterErrorMessage?.(error) ?? createFilterError(error),
      );
    }
  };

  const updateFilterError = useUpdatePayableFilterErrorMessage();

  const updateFilter = async (filter: SelectedFilter) => {
    try {
      return await props.updateFilter(filter);
    } catch (error) {
      throw new Error(
        props.getUpdateFilterErrorMessage?.(error) ?? updateFilterError(error),
      );
    }
  };

  const deleteFilterError = useDeletePayableFilterErrorMessage();

  const deleteFilter = async (filterId: string) => {
    try {
      return await props.deleteFilter(filterId);
    } catch (error) {
      throw new Error(
        props.getDeleteFilterErrorMessage?.(error) ?? deleteFilterError(error),
      );
    }
  };

  return (
    <Popover
      renderTrigger={(popoverTriggerProps, isPopoverOpen) => (
        <PopoverTrigger
          savedFiltersQuery={savedFiltersQuery}
          isPopoverOpen={isPopoverOpen}
          popoverTriggerProps={popoverTriggerProps}
          activeFilter={activeFilter}
          selectedFilter={selectedFilter}
          setSelectedFilter={(filter) => {
            dispatchFilterAction({ type: 'reset', filter });
          }}
          applyFilter={applyFilter}
          deleteFilter={deleteFilter}
        />
      )}
    >
      {(closePopover) => {
        return (
          <FilterBuilderForm
            savedFilters={savedFilters}
            fields={fields}
            activeFilter={activeFilter}
            applyFilter={(newFilter) => {
              closePopover();
              applyFilter(newFilter);
            }}
            createFilter={createFilter}
            updateFilter={updateFilter}
            renderSubfilterValue={renderSubfilterValue}
            selectedFilter={selectedFilter}
            dispatchFilterAction={dispatchFilterAction}
          />
        );
      }}
    </Popover>
  );
};

/**
 * Active filter hook
 */

export const useActiveFilter = ({
  filterIdUrlParameterName,
  filterUrlParameterName,
  savedFilters,
  fields,
}: {
  filterIdUrlParameterName: string;
  filterUrlParameterName: string;
  savedFilters: SavedFilter[];
  fields: Field[];
}) => {
  const history = useHistory();

  return useMemo(() => {
    const currentSearchParams = new URLSearchParams(history.location.search);

    const activeFilterId =
      currentSearchParams.get(filterIdUrlParameterName) ?? undefined;

    const activeFilter: SelectedFilter | undefined =
      // Get filter from saved filters by "filter id" from URL parameter
      savedFilters.find(({ id }) => id === activeFilterId) ||
      // Or get filter from "filter" URL parameter
      fromFilterUrlParameter(
        currentSearchParams.get(filterUrlParameterName) ?? '',
        fields,
      );

    const filtersCount = activeFilter?.filter.subfilters.reduce(
      (accumulator, subfilterGroup) =>
        accumulator +
        subfilterGroup.subfilters.filter(({ field }) => field).length,
      0,
    );

    if (!filtersCount) {
      return undefined;
    }

    return activeFilter;
  }, [
    history.location.search,
    filterIdUrlParameterName,
    filterUrlParameterName,
    savedFilters,
    fields,
  ]);
};

/**
 * Child components
 */

type BaseOption = {
  key: string;
  label: string;
  iconName?: IconName;
  iconSize?: IconSize;
  action?: () => void;
  suffixElement?: React.ReactNode;
  keepOpenOnSelect?: boolean;
  disabledMessage?: string;
};

type OptionGroup = BaseOption & {
  options: BaseOption[];
};

type Option = BaseOption | OptionGroup;

const PopoverTrigger = ({
  savedFiltersQuery,
  selectedFilter,
  setSelectedFilter,
  activeFilter,
  applyFilter,
  deleteFilter,
  isPopoverOpen,
  popoverTriggerProps: { isDropdown, ...popoverTriggerProps },
}: {
  savedFiltersQuery: QueryState<(SavedFilter & { disabledMessage?: string })[]>;
  selectedFilter: SelectedFilterWithId;
  setSelectedFilter(filter: SelectedFilter | undefined): void;
  activeFilter: SelectedFilter | undefined;
  applyFilter(filter: SelectedFilter | undefined): void;
  deleteFilter(filterId: string): void;
  isPopoverOpen: boolean;
  popoverTriggerProps: TriggerProps;
}) => {
  const { t } = useTranslation('global');
  const { successNotif } = useNotifications();

  const [dropdownView, setDropdownView] = useState<'main' | 'filter-actions'>(
    'main',
  );

  const savedFilters = unwrapQuery(savedFiltersQuery) ?? [];

  const triggerContainerClassName = `flex flex-row items-center rounded-8 bg-primary-disabled`;

  const togglePopover = () => {
    popoverTriggerProps.onClick();
  };

  const resetDropdownView = () => {
    setDropdownView('main');
    if (selectedFilter.id !== undefined) {
      setSelectedFilter(undefined);
    }
  };

  const [confirmDeleteFilterModal, showConfirmDeleteFilterModal] = useModal<{
    filterId: string;
    filterName: string;
  }>(({ isOpen, onClose, filterId, filterName }) => (
    <ConfirmDeleteFilterModal
      filterName={filterName}
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={async () => {
        await deleteFilter(filterId);
        successNotif(
          t('common.filterBuilder.deleteFilterSuccess', { filterName }),
        );
        onClose();
        setSelectedFilter(undefined);
        if (filterId === activeFilter?.id) {
          applyFilter(undefined);
        }
      }}
    />
  ));

  const getCommonTriggerProps = () => {
    const activeFilterCount = activeFilter?.filter.subfilters.reduce(
      (accumulator, subfilterGroup) =>
        accumulator +
        subfilterGroup.subfilters.filter(({ field }) => field).length,
      0,
    );

    return {
      text:
        activeFilter?.name ||
        t('common.filterBuilder.title') +
          (activeFilterCount ? ` (${activeFilterCount})` : ''),
      iconName: 'adjustments-horizontal' as const,
      variant: 'secondaryNeutral' as const,
      className: `[&_span]:truncate [&_svg]:min-w-16 max-w-[400px] ${
        activeFilter ? 'text-brand-default' : ''
      }`,
      fit: 'parent' as const,
      isDropdown: false,
      id: 'filter-builder-trigger',
    };
  };

  const options: Option[] = [];

  switch (dropdownView) {
    // Filter actions view
    case 'filter-actions':
      options.push(
        // Back
        {
          key: 'back',
          label: t('misc.back'),
          iconName: 'chevron-left',
          iconSize: 'm',
          keepOpenOnSelect: true,
          action: () => {
            resetDropdownView();
          },
        },
        // Edit, Duplicate, Delete
        {
          key: 'filter-actions',
          label: selectedFilter.name,
          options: [
            {
              key: 'edit',
              label: t('misc.edit'),
              iconName: 'pen',
              action: () => {
                togglePopover();
              },
            },
            {
              key: 'duplicate',
              label: t('misc.duplicate'),
              iconName: 'square-stack',
              action: () => {
                setSelectedFilter({
                  ...selectedFilter,
                  id: '',
                  name: `${t('common.filterBuilder.copyOf')}: ${selectedFilter.name}`,
                });
                togglePopover();
              },
            },
            {
              key: 'delete',
              label: t('misc.delete'),
              iconName: 'trash',
              action: async () => {
                if (selectedFilter.id) {
                  showConfirmDeleteFilterModal({
                    filterId: selectedFilter.id,
                    filterName: selectedFilter.name,
                  });
                }
              },
            },
          ],
        },
      );
      break;

    // Main view
    default:
      // Filter builder
      options.push({
        key: 'new-filter',
        label: t('common.filterBuilder.newFilter'),
        iconName: 'plus',
        iconSize: 'm',
        action: () => {
          if (activeFilter && !activeFilter.id) {
            setSelectedFilter(activeFilter);
          } else if (selectedFilter.id) {
            setSelectedFilter(undefined);
          }
          togglePopover();
        },
      });

      // Loading
      if (savedFiltersQuery.status === 'loading') {
        options.push({
          key: 'loading',
          label: `${t('misc.loading')}...`,
        });
      }

      // Saved filters
      if (savedFiltersQuery.status === 'success') {
        options.push({
          key: 'saved-filters',
          label: t('common.filterBuilder.savedFilters'),
          options: savedFilters.map((filter) => ({
            key: filter.id,
            label: filter.name,
            action: () => {
              applyFilter(filter);
            },
            suffixElement: filter.disabledMessage ? undefined : (
              <IconButton
                variant={
                  activeFilter?.id === filter.id
                    ? 'tertiaryBrand'
                    : 'tertiaryNeutral'
                }
                iconName="ellipsis-vertical"
                aria-label={t('common.filterBuilder.quickActions')}
                className="-m-8 rounded-[0] opacity-0 transition-opacity group-hover:opacity-100"
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedFilter(filter);
                  setDropdownView('filter-actions');
                }}
              />
            ),
            disabledMessage: filter.disabledMessage,
          })),
        });
      }
  }

  const resetActiveFilterButton = () =>
    activeFilter && (
      <IconButton
        variant="tertiaryNeutral"
        iconName="cross"
        aria-label={t('common.filterBuilder.clearFilter')}
        className="-ml-[1px] flex-shrink-0 text-secondary-bg-secondary hover:border hover:border-solid hover:border-default"
        onClick={() => {
          setDropdownView('main');
          applyFilter(undefined);
        }}
      />
    );

  if (
    (savedFiltersQuery.status === 'success' && !savedFilters.length) ||
    activeFilter
  ) {
    const commonTriggerProps = getCommonTriggerProps();

    return (
      <div className={triggerContainerClassName}>
        <Button {...popoverTriggerProps} {...commonTriggerProps} />
        {resetActiveFilterButton()}
        {!isPopoverOpen && !activeFilter && savedFilters.length === 0 && (
          <EmptyTriggerProductTour />
        )}
        {!isPopoverOpen && activeFilter && !activeFilter?.id && (
          <ActiveTriggerProductTour />
        )}
      </div>
    );
  }

  return (
    <>
      <DropdownMenu
        className="max-w-[400px]"
        dropdownContentMaxHeight="400px"
        renderButton={(getTriggerProps, isDropdownOpen) => {
          const commonTriggerProps = getCommonTriggerProps();
          const dropdownTriggerProps = getTriggerProps();
          return (
            <div className={triggerContainerClassName}>
              <Button
                {...(isPopoverOpen
                  ? popoverTriggerProps
                  : dropdownTriggerProps)}
                {...commonTriggerProps}
                onKeyDown={(event) => {
                  if (!isDropdownOpen) {
                    resetDropdownView();
                  }
                  dropdownTriggerProps.onKeyDown?.(event);
                }}
                onClick={(event) => {
                  if (isPopoverOpen) {
                    togglePopover();
                  }
                  resetDropdownView();
                  dropdownTriggerProps.onClick?.(event);
                }}
              />
              {!isDropdownOpen && !activeFilter && savedFilters.length > 0 && (
                <DoneTriggerProductTour />
              )}
            </div>
          );
        }}
        options={options}
        onSelect={(option) => !option.disabledMessage && option?.action?.()}
        renderOption={(option) => {
          const {
            key,
            label,
            iconName,
            iconSize = 's',
            suffixElement,
            keepOpenOnSelect,
            action,
          } = option;

          const prefixElement = iconName && (
            <Icon name={iconName} size={iconSize} className="w-16" />
          );

          return (
            // This hack prevent closing the dropdown when clicking on option with keepOpenOnSelect=true
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              onMouseDown={(event) => {
                if (keepOpenOnSelect && !option.disabledMessage) {
                  event.stopPropagation();
                }
              }}
              onClick={(event) => {
                if (keepOpenOnSelect && !option.disabledMessage) {
                  event.stopPropagation();
                  action?.();
                }
              }}
            >
              <DropdownItem
                label={
                  option.disabledMessage ? (
                    <div className="flex flex-row items-center gap-8 text-secondary-bg-primary">
                      {label}
                      <Tooltip
                        triggerAsChild
                        content={option.disabledMessage}
                        placement="right"
                      >
                        <Icon name="circle-information" />
                      </Tooltip>
                    </div>
                  ) : (
                    label
                  )
                }
                key={key}
                isDisabled={key === 'loading' || !!option.disabledMessage}
                className={`group min-w-[300px] [&_span]:max-w-[340px] [&_span]:truncate ${
                  key === 'loading' ? 'animate-pulse' : ''
                }`}
                prefix={prefixElement}
                suffix={suffixElement}
              />
            </div>
          );
        }}
        renderOptionGroup={(optionGroup: OptionGroup) => {
          return (
            <div
              key={optionGroup.key}
              className="flex min-h-32 flex-row items-center bg-secondary-default px-8 py-4 leading-4 text-primary"
            >
              <div className="shrink-0 uppercase text-secondary-bg-secondary title-s">
                {optionGroup.label}
              </div>
            </div>
          );
        }}
      />
      {confirmDeleteFilterModal}
    </>
  );
};

const ConfirmDeleteFilterModal = ({
  filterName,
  isOpen,
  onCancel,
  onConfirm,
}: {
  filterName: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}) => {
  const { t } = useTranslation('global');
  const [error, setError] = useState<Error | null>(null);

  return (
    <Modal
      isOpen={isOpen}
      title={t('common.filterBuilder.deleteFilterConfirmation.title')}
      iconName="trash"
      iconVariant="alert"
      onClose={() => {
        setError(null);
        onCancel();
      }}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={() => {
            setError(null);
            onCancel();
          }}
        />,
        <Button
          key="confirm"
          variant="primaryAlert"
          text={t('common.filterBuilder.deleteFilterConfirmation.confirm')}
          onClick={async () => {
            setError(null);
            try {
              await onConfirm();
            } catch (deleteFilterError) {
              logger.error(deleteFilterError, {
                team: 'none',
                scope: 'filter-builder',
              });
              setError(deleteFilterError);
            }
          }}
        />,
      ]}
    >
      <div>
        {t('common.filterBuilder.deleteFilterConfirmation.content', {
          name: filterName,
        })}

        {/* Filter delete errors if any */}
        {error && (
          <Callout title={error.message} variant="alert" className="mt-16" />
        )}
      </div>
    </Modal>
  );
};

/**
 * Error messages
 */

type TranslationParams = Record<string, unknown>;

const useCreatePayableFilterErrorMessage = () => {
  const getErrorMessage = useQueryError<unknown, TranslationParams>({
    requestError: () => 'common.filterBuilder.createFilterError.unknown',
    serverError: 'common.filterBuilder.createFilterError.unknown',
  });

  return (
    queryError: QueryError<unknown>,
    translationParams?: TranslationParams,
  ): string => getErrorMessage(queryError, translationParams);
};

const useUpdatePayableFilterErrorMessage = () => {
  const getErrorMessage = useQueryError<unknown, TranslationParams>({
    requestError: () => 'common.filterBuilder.updateFilterError.unknown',
    serverError: 'common.filterBuilder.updateFilterError.unknown',
  });

  return (
    queryError: QueryError<unknown>,
    translationParams?: TranslationParams,
  ): string => getErrorMessage(queryError, translationParams);
};

const useDeletePayableFilterErrorMessage = () => {
  const getErrorMessage = useQueryError<unknown, TranslationParams>({
    requestError: () => 'common.filterBuilder.deleteFilterError.unknown',
    serverError: 'common.filterBuilder.deleteFilterError.unknown',
  });

  return (
    queryError: QueryError<unknown>,
    translationParams?: TranslationParams,
  ): string => getErrorMessage(queryError, translationParams);
};
