import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

export const MAX_PAYMENTS_PER_BATCH = 100;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const TransferBatchLimitModal = ({
  isOpen,
  onClose,
  onConfirm,
}: Props) => {
  const { t } = useTranslation('global');
  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onClose}
          text={t('wiretransfer.confirmation.batchLimitModal.cancelButton')}
          variant="secondaryNeutral"
        />,
        <Button
          key="reject-transfers"
          text={t('wiretransfer.confirmation.batchLimitModal.continueButton')}
          variant="primaryBrand"
          onClick={onConfirm}
        />,
      ]}
      iconName="circle-information"
      iconVariant="info"
      title={t('wiretransfer.confirmation.batchLimitModal.title', {
        maxPayments: MAX_PAYMENTS_PER_BATCH,
      })}
      subtitle={t('wiretransfer.confirmation.batchLimitModal.subtitle', {
        maxPayments: MAX_PAYMENTS_PER_BATCH,
      })}
      isOpen={isOpen}
    />
  );
};
