import { Icon, type IconName, colors } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { SemiCircularSegmentedGauge } from './SemiCircularSegmentedGauge';

type Props = {
  incompletePaymentsLimit: number;
  latePaymentsCount: number;
  isLate: boolean;
  isOptimistic: boolean;
  className?: string;
};

export const LatePaymentAnalytics = ({
  incompletePaymentsLimit,
  latePaymentsCount,
  isLate,
  isOptimistic,
  className,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div
      className={classNames(className, 'box flex items-center gap-[80px]', {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'bg-secondary-alert-default': isLate,
      })}
    >
      <SemiCircularSegmentedGauge
        totalNumberOfSegments={incompletePaymentsLimit}
        filledNumberOfSegments={latePaymentsCount}
        isOptimistic={isOptimistic}
        text={
          isLate
            ? t('homepage.paymentsList.latePayments')
            : t('homepage.paymentsList.incompleteRequests')
        }
      />
      <div>
        <div
          className={classNames('mb-16 title-m', {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'text-primary': !isLate,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'text-alert-default': isLate,
          })}
        >
          {isLate
            ? t('homepage.paymentsList.lostAccess')
            : t('homepage.paymentsList.soonLooseAccess')}
        </div>
        <div className="flex gap-24">
          <AnalyticsItem
            name={t('homepage.paymentsList.plasticCard')}
            iconName="card"
            isLate={isLate}
          />
          <AnalyticsItem
            name={t('homepage.paymentsList.virtualCard')}
            iconName="card-lightning"
            isLate={isLate}
          />
        </div>
      </div>
    </div>
  );
};

const AnalyticsItem = ({
  name,
  iconName,
  isLate,
}: {
  name: string;
  iconName: IconName;
  isLate: boolean;
}) => {
  return (
    <div className="flex items-center gap-8 text-primary body-m">
      <div
        className={classNames(
          'flex h-32 w-32 items-center justify-center rounded-[50%]',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'bg-primary-default': isLate },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'bg-secondary-alert-default': !isLate },
        )}
      >
        <Icon color={colors.contentAlertDefault} name={iconName} />
      </div>
      {name}
    </div>
  );
};
