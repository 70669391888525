import { type MonetaryValue } from 'ezmoney';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type InvoiceDetail = {
  chargebeeId: string;
  functionalAmountToCollect: MonetaryValue;
  paymentOverDueAt: Date;
  delinquentAt: Date;
};

type BillingStatus = {
  companyId: string;
  billingInvoicePaymentStatus: 'good' | 'atRisk' | 'delinquent';
  atRiskStartDate: Date;
  delinquentStartDate: Date;
  overdueInvoicesDetails: InvoiceDetail[];
};

export type CompanyBillingStatus = {
  status: BillingStatus;
  organisationPlanOwner:
    | {
        exists: true;
        companyId: string;
        billingInvoicePaymentStatus: 'good' | 'atRisk' | 'delinquent';
      }
    | {
        exists: false;
      };
};

export const useFetchCompanyBillingStatus = (
  companyId: string,
): QueryState<CompanyBillingStatus | undefined> => {
  return useQuery({
    key: ['billingStatus', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/billing/status`,
    },
    reshapeData: (data) => data,
  });
};
