import cx from 'classnames';
import React, { type ReactNode } from 'react';

type Props = {
  title: ReactNode;
  subtitle?: ReactNode;
  leftContent?: ReactNode;
  className?: string;
};

export const GrapesSectionHeader = ({
  title,
  subtitle,
  leftContent,
  className,
}: Props) => {
  return (
    <div className={cx('mb-32 flex items-center justify-between', className)}>
      <div className="flex items-center gap-8">
        <h1 className="title-l">{title}</h1>
        <div className="mr-16 text-primary body-m">{subtitle}</div>
      </div>
      <div className="whitespace-nowrap">{leftContent}</div>
    </div>
  );
};
