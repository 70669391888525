import { GridContainer, GridItem } from '@dev-spendesk/grapes';
import React from 'react';

import { PaymentsToScheduleListSkeleton } from 'modules/invoices/components/PaymentsToScheduleList/PaymentsToScheduleListSkeleton';
import { SchedulePaymentsBlockLoader } from 'modules/invoices/components/SchedulePaymentsBlock/SchedulePaymentsBlockLoader';

export const InvoicesPayPageSkeleton = () => {
  return (
    <div className="h-full">
      <GridContainer className="mt-48">
        <GridItem columnSpan={6}>
          <PaymentsToScheduleListSkeleton numberOfRows={5} />
        </GridItem>
        <GridItem columnSpan={6}>
          <div className="box">
            <SchedulePaymentsBlockLoader />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};
