import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { useFeature } from 'common/hooks/useFeature';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { getIsMarqeta } from 'modules/company/redux/selectors';
import { actions as walletActions } from 'modules/company/wallet';
import { ExpenseEntityEducationalModal } from 'modules/homepage/ControllerHomepage/components/ExpenseEntityEducationalModal';
import { useHasAccessToKycStateRelatedFeatures } from 'modules/kyc/hooks/useHasAccessToKycStateRelatedFeatures';
import FEATURES from 'src/core/constants/features';
import { type AppState } from 'src/core/reducers';
import { getIsWelcomeDashboardDismissed } from 'src/core/selectors/globalSelectors';
import { getSelf } from 'src/core/selectors/users';

import { GraphDashboard } from './components/GraphDashboard';
import { HomepageWalletContainer } from './components/HomepageWallet';
import { KycWidget } from './components/KycWidget';
import {
  MissingReceipts,
  MissingReceiptsLoader,
} from './components/MissingReceipts';
import { OperationalOnboardingContainer } from './components/OperationalOnboarding';
import { DashboardTipBox } from './components/TipBox/DashboardTipBox';
import { useControlRulesActivelyUsedQuery } from './hooks/useControlRulesActivelyUsedQuery';
import { useMissingReceiptsLeaderboardQuery } from './hooks/useMissingReceiptsLeaderboardQuery';
import { getAccountLoads, getIsLoadingAccountLoads } from './redux/selectors';
import { type AccountLoad } from './wallet';
import { useCompany } from '../../app/hooks/useCompany';
import * as achSelectors from '../../company/wallet/ach/redux/selectors';
import * as achThunks from '../../company/wallet/ach/redux/thunks';
import { type GpaOrder } from '../../company/wallet/ach/types';

type Props = {
  accountLoads: AccountLoad[];
  fetchAccountLoads: () => void;
  getAchFundingSources: () => void;
  getGpaOrders: () => void;
  hasPendingAchSource: boolean;
  hasValidatedAchSource: boolean;
  isAccountLoadsLoading: boolean;
  isMarqeta: boolean;
  pendingGpaOrders: GpaOrder[];
  user: {
    is_admin: boolean;
  };
};

const ControllerHomepage = ({
  accountLoads,

  fetchAccountLoads,
  getAchFundingSources,
  getGpaOrders,
  hasPendingAchSource,
  hasValidatedAchSource,
  isAccountLoadsLoading,
  isMarqeta,
  pendingGpaOrders,
  user,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const company = useCompany();

  const [isLoadingAchFundingSources, setIsLoadingAchFundingSources] =
    useState(isMarqeta);
  const [isLoadingGpaOrders, setIsLoadingGpaOrders] = useState(isMarqeta);

  const hasPlayByRuleFeature = useFeature(FEATURES.PLAY_BY_RULES);
  const hasDashboardFeature = useFeature(FEATURES.DASHBOARD);
  const hasAccessToWallet = useFeature(FEATURES.WALLET_ACCESS);

  const isWelcomeDashboardDismissed = useSelector(
    getIsWelcomeDashboardDismissed,
  );

  const { isAccountOwner, isAdmin } = useUserRoles();

  useEffect(() => {
    fetchAccountLoads();
  }, []);

  useAsync(async () => {
    if (isLoadingAchFundingSources) {
      await getAchFundingSources();
      setIsLoadingAchFundingSources(false);
    }
  }, []);

  useAsync(async () => {
    if (isLoadingGpaOrders) {
      await getGpaOrders();
      setIsLoadingGpaOrders(false);
    }
  }, []);

  const leaderboardQueryState = useMissingReceiptsLeaderboardQuery();
  const controlRulesActivelyUsedQueryState = useControlRulesActivelyUsedQuery();

  const isControlRulesUsed =
    hasPlayByRuleFeature &&
    controlRulesActivelyUsedQueryState.status === 'success' &&
    controlRulesActivelyUsedQueryState.data.isActivelyUsed;
  const hasNoSpendYet = company.spending_total === 0;
  const isAccountLoaded = accountLoads.length > 0;

  const isLoading =
    isLoadingAchFundingSources || isAccountLoadsLoading || isLoadingGpaOrders;

  const showWelcomeDashboard =
    !isWelcomeDashboardDismissed && (isAccountOwner || isAdmin);

  const hasAccessToKycStateRelatedFeatures =
    useHasAccessToKycStateRelatedFeatures();

  return (
    <>
      <div className="flex flex-col gap-24">
        <KycWidget />
        {showWelcomeDashboard && <OperationalOnboardingContainer />}
        <div className="grid grid-cols-2 gap-24">
          {hasAccessToWallet && (
            <HomepageWalletContainer
              company={company}
              hasPendingAchSource={hasPendingAchSource}
              hasValidatedAchSource={hasValidatedAchSource}
              hasPendingGpaOrders={pendingGpaOrders.length > 0}
              isAccountLoaded={isAccountLoaded}
              isLoading={isLoading}
              isMarqeta={isMarqeta}
            />
          )}
          {hasDashboardFeature && company.type !== 'branch_currency' && (
            <DashboardTipBox />
          )}
        </div>
        {!hasNoSpendYet && (
          <>
            {leaderboardQueryState.status !== 'success' ? (
              <MissingReceiptsLoader />
            ) : (
              <MissingReceipts
                companyId={company.id}
                currency={company.currency}
                userRole={user.is_admin ? 'admin' : 'controller'}
                leaderboardItems={leaderboardQueryState.data}
                isControlRulesUsed={isControlRulesUsed}
              />
            )}
          </>
        )}
        {hasAccessToKycStateRelatedFeatures && <GraphDashboard />}
      </div>
      <ExpenseEntityEducationalModal />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  accountLoads: getAccountLoads(state),
  hasPendingAchSource: achSelectors.hasPendingAchSource(state),
  hasValidatedAchSource: achSelectors.hasValidatedAchSource(state),
  isAccountLoadsLoading: getIsLoadingAccountLoads(state),
  isMarqeta: getIsMarqeta(state),
  pendingGpaOrders: achSelectors.getPendingGpaOrders(state),
  user: getSelf(state),
});

const mapDispatchToProps = {
  fetchAccountLoads: walletActions.fetchAccountLoads,
  getAchFundingSources: achThunks.getAchFundingSources,
  getGpaOrders: achThunks.getGpaOrders,
};

const ConnectedControllerHomepage = withErrorBoundary({
  team: 'finance-controller',
  scope: 'controller-home-page',
})(connect(mapStateToProps, mapDispatchToProps)(ControllerHomepage));

export { ConnectedControllerHomepage as ControllerHomepage };
