import { SwitchField } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  isChecked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const AutoAssignSwitchField = ({ isChecked, onChange }: Props) => {
  const { t } = useTranslation('global');

  return (
    <SwitchField
      id="members-auto-assign"
      className="mt-24"
      label={<p>{t('controlRulesModal.autoAssignLabel')}</p>}
      helpText={t('controlRulesModal.autoAssignDesc')}
      isChecked={isChecked}
      onChange={onChange}
    />
  );
};
