import {
  Button,
  IconButton,
  DropdownMenu,
  DropdownItem,
  Avatar,
  Table,
  Tag,
} from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitStatus';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import type { CostCenter, CostCenterWithBudgets } from '../../costCenter';
import type { Member } from '../../member';
import { ApprovalByDimensionsMarketingBanner } from '../ApprovalByDimensionsMarketingBanner';
import { CostCenterEmptyState } from '../CostCenterEmptyState';
import { ReportingManagerMarketingBanner } from '../ReportingManagerMarketingBanner';

import './CostCentersPage.css';

type Props = {
  members: Member[];
  costCenters: CostCenterWithBudgets[];
  onClickFirstCreate(): void;
  onClickCreate(): void;
  onClickMoreInfo(): void;
  onClickEdit(costCenter: CostCenter): void;
  onClickDelete(costCenter: CostCenterWithBudgets): void;
  onClickImport(): void;
};

export const CostCentersPage = ({
  members,
  costCenters,
  onClickFirstCreate,
  onClickCreate,
  onClickMoreInfo,
  onClickEdit,
  onClickDelete,
  onClickImport,
}: Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();
  const isReportingManagerFeatureEnabled = useFeature(
    FEATURES.REPORTING_MANAGERS,
  );
  const isMultidimensionalApprovalWorkflowEnabled = useFeature(
    FEATURES.MULTIDIMENSIONAL_APPROVAL_WORKFLOW,
  );

  if (costCenters.length === 0) {
    return <CostCenterEmptyState onCreateClick={onClickFirstCreate} />;
  }

  const sortedCostCenters = costCenters.sort((cc1, cc2) => {
    return cc1.name.localeCompare(cc2.name, activeLanguage, {
      sensitivity: 'base',
    });
  });

  return (
    <div className="mx-auto w-[1000px]">
      <div className="mb-16 flex items-center justify-between">
        <div>
          <div>
            <div className="flex items-center gap-8">
              <div className="heading-l">{t('costCenters.costCenter')}</div>
              {isAnalyticalSplitActivated ? (
                <Tag className="ml-8" variant="purple">
                  {t('costCenters.splittable')}
                </Tag>
              ) : null}
            </div>
            <p className="mt-8 text-secondary-bg-primary">
              {isAnalyticalSplitActivated
                ? t('costCenters.splittableCostCenterDescription')
                : t('costCenters.costCenterDescription')}
            </p>
          </div>
          <button
            type="button"
            className="mt-0 cursor-pointer border-none !bg-primary-default p-0 text-secondary-bg-primary underline body-m"
            onClick={onClickMoreInfo}
          >
            {t('costCenters.moreInformation')}
          </button>
        </div>
        <DropdownMenu
          options={[
            {
              key: 'create',
              label: t('costCenters.addCostCenterDropDown.createLabel'),
            },
            {
              key: 'import',
              label: t('costCenters.addCostCenterDropDown.importLabel'),
            },
          ]}
          renderButton={(getToggleButtonProps) => (
            <Button
              {...getToggleButtonProps()}
              text={t('costCenters.addCostCenterDropDown.text')}
              variant="primaryBrand"
            />
          )}
          renderOption={(option) => <DropdownItem label={option.label} />}
          onSelect={(option) => {
            switch (option.key) {
              case 'import':
                onClickImport();
                break;
              case 'create':
                onClickCreate();
                break;
              default:
                break;
            }
          }}
        />
      </div>

      {isReportingManagerFeatureEnabled &&
        !isMultidimensionalApprovalWorkflowEnabled && (
          <ReportingManagerMarketingBanner
            variant="page"
            className="mb-16"
            title={t('costCenters.banner.title')}
            description={t('costCenters.banner.description')}
          />
        )}
      {isMultidimensionalApprovalWorkflowEnabled && (
        <ApprovalByDimensionsMarketingBanner className="mb-16" />
      )}
      <Table
        columns={[
          {
            id: 'costCenter',
            header: t('costCenters.costCenter'),
            width: '50%',
            renderCell: (row) => row.name,
          },
          {
            id: 'owner',
            header: t('costCenters.owner'),
            width: '50%',
            renderCell: (row, { isRowHovered }) => {
              const owner = members.find((member) => member.id === row.ownerId);
              return (
                <div className="CostCentersPage__owner-cell">
                  {owner && (
                    <>
                      <Avatar
                        src={owner.avatar}
                        text={owner.isPending ? owner.email : owner.fullname}
                        className="CostCentersPage__owner-cell-avatar"
                      />
                      <div className="CostCentersPage__owner-cell__text">
                        {owner.isPending ? owner.email : owner.fullname}
                      </div>
                    </>
                  )}
                  {isRowHovered && (
                    <div className="CostCentersPage__owner-cell__actions">
                      <IconButton
                        iconName="pen"
                        variant="tertiaryNeutral"
                        onClick={() => {
                          onClickEdit(row);
                        }}
                        hasNegativeMargins
                        aria-label={t('misc.edit')}
                      />
                      <IconButton
                        iconName="trash"
                        variant="tertiaryNeutral"
                        onClick={() => {
                          onClickDelete(row);
                        }}
                        hasNegativeMargins
                        aria-label={t('misc.delete')}
                      />
                    </div>
                  )}
                </div>
              );
            },
          },
        ]}
        data={sortedCostCenters}
        rowHeight="compact"
        getRowId={(row) => row.id}
      />
    </div>
  );
};
