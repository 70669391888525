import {
  Button,
  FormField,
  Modal,
  OptionGroup,
  TextArea,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { usePublishNoteToProductBoard } from 'src/core/common/hooks/usePublishNoteToProductBoard';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { getProductBoardContent } from '../../utils/getProductBoardContent';

type Props = {
  title: string;
  subtitle?: string;
  feedbackLabel: string;
  feedbackTags: string[];
  isOpen: boolean;
  cancelText: string;
  confirmText: string;
  analyticsContext?: string;
  onCancel(): void;
  onConfirm(): void;
};

export const FeedbackModalWithRating = ({
  title,
  subtitle,
  feedbackLabel,
  isOpen,
  feedbackTags,
  cancelText,
  confirmText,
  analyticsContext = '',
  onCancel,
  onConfirm,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const { activeLanguage: language } = useTranslation('global');
  const [publishNoteToProductBoard] = usePublishNoteToProductBoard();
  const [feedbackContent, setFeedbackContent] = useState('');
  const [ratingOption, setRatingOption] = useState('');
  const handleFeedbackRatingFormChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => setRatingOption(event.target.value);

  const onSubmitFeedback = () => {
    const content = getProductBoardContent(
      feedbackContent,
      ratingOption,
      analyticsContext,
    );

    track(
      AnalyticEventName.BETA_FEATURE_WITH_RATING_MODAL_SUBMIT_FEEDBACK_BUTTON_CLICKED,
      {
        context: analyticsContext,
        rating: Number.parseInt(ratingOption),
        // Looking at the Segment API docs (https://segment.com/docs/connections/sources/catalog/libraries/server/http-api/#max-request-size) a request should have a max size of 32KB. Since we're already sending other props to the request, we limit the feedback text to 4100 chars (~ 4KB)
        feedback: feedbackContent.slice(0, 4100),
        language,
      },
    );

    if (ratingOption) {
      try {
        publishNoteToProductBoard({
          title,
          content,
          tags: feedbackTags,
        });
        pushNotif({
          type: NotificationType.Success,
          message: t('betaFeature.feedbackModal.successNotification'),
        });
        onConfirm();
      } catch (error) {
        pushNotif({
          type: NotificationType.Danger,
          message: t('misc.errors.unknownError'),
          description: error,
        });
      }
    }
  };

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onCancel}
          text={cancelText}
          variant="secondaryNeutral"
        />,
        <Button
          key="confirm"
          text={confirmText}
          variant="primaryBrand"
          onClick={onSubmitFeedback}
        />,
      ]}
      iconName="circle-question-filled"
      title={title}
      subtitle={subtitle}
      iconVariant="info"
      isOpen={isOpen}
    >
      <FormField label={t('bookkeep.export.datev.bankJournal.feedback.grade')}>
        <OptionGroup
          options={Array.from({ length: 10 }, (_, index) => {
            const stringNumber = (index + 1).toString();
            return { label: stringNumber, value: stringNumber };
          })}
          value={ratingOption}
          name="optionGroupField"
          onChange={handleFeedbackRatingFormChange}
        />
      </FormField>
      <br />
      <FormField label={feedbackLabel} hint="Optional">
        <TextArea
          value={feedbackContent}
          onChange={(event) => setFeedbackContent(event.target.value)}
          rows={5}
          placeholder={t('betaFeature.feedbackModal.textPlaceholder')}
        />
      </FormField>
    </Modal>
  );
};
