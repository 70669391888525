import { gql } from 'graphql-tag';

const partialPayableFragment = gql`
  fragment partialPayable on PayableConnection {
    pageInfo {
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        state
        creationDate
        netAmount
        grossAmount
        functionalAmount
        type
        subtype
        description
        documentaryEvidence {
          type
          validity {
            valid
            reason
          }
        }
        member {
          ... on User {
            id
            givenName
            familyName
            email
          }
          ... on Invitee {
            id
            email
          }
          ... on SuspendedUser {
            id
            givenName
            familyName
          }
        }
        counterparty {
          ... on User {
            id
            givenName
            familyName
            email
          }
          ... on Invitee {
            id
            email
          }
          ... on SuspendedUser {
            id
            givenName
            familyName
          }
          ... on Supplier {
            id
            name
            thumbnailUrl
          }
        }
        ... on ClaimedBillExpensePayable {
          supplier {
            id
            name
            thumbnailUrl
          }
        }
        ... on SupplierExpensePayable {
          supplier {
            id
            name
            thumbnailUrl
          }
        }
      }
    }
  }
`;

export const GET_PAYABLES = gql`
  query GetPayables(
    $companyId: ID!
    $after: ID
    $filters: PayableFilter
    $filtersV2: JSON
    $textualSearch: String
  ) {
    company(id: $companyId) {
      id
      payables(
        first: 15
        after: $after
        filter: $filters
        filtersV2: $filtersV2
        textualSearch: $textualSearch
      ) {
        ...partialPayable
      }
    }
  }
  ${partialPayableFragment}
`;
