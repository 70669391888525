import { useQueryClient } from 'react-query';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { SEARCH_MEMBERS } from './queries';

type RawMember = {
  id: string;
  givenName?: string;
  familyName?: string;
  email?: string;
};

type Member = {
  id: string;
  fullName: string;
  givenName?: string;
  familyName?: string;
  email?: string;
};

export type SearchMembersRawResponse = {
  company: {
    members: {
      edges: {
        node: RawMember;
      }[];
    };
  };
};

export type SearchMembersVariables = {
  search: string;
};

export const useSearchMembers = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  const { t } = useTranslation('global');

  return async (search: string): Promise<Member[]> => {
    const data = await queryClient.fetchQuery(
      ['useSearchMembers', companyId, search],
      getFetcher<SearchMembersRawResponse>({
        companyId,
        getRequest: () => ({
          type: 'graphQL',
          target: 'v2',
          query: SEARCH_MEMBERS,
          variables: {
            search,
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return reshapeMemberValues(data.company.members.edges, t);
  };
};

/**
 * Reshapers
 */

const reshapeMember = (
  member: RawMember,
  translator: TGlobalFunctionTyped,
): Member => ({
  id: member.id,
  fullName:
    member.givenName && member.familyName
      ? `${member.givenName} ${member.familyName}`
      : member.email || translator('misc.unknownMember'),
  givenName: member.givenName,
  familyName: member.familyName,
  email: member.email,
});

const reshapeMemberValues = (
  edges: { node: RawMember }[],
  translator: TGlobalFunctionTyped,
): Member[] =>
  edges
    .map(({ node }) => reshapeMember(node, translator))
    .sort((a, b) => {
      const textA = a.fullName.toUpperCase();
      const textB = b.fullName.toUpperCase();
      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });
