import React, { useId } from 'react';

import { type Integration } from '../../../types';
import { IntegrationBox } from '../IntegrationBox';

type Props = {
  title: string;
  integrations: Integration[];
  extra?: JSX.Element[];
  activeIntegrations: boolean;
  availableIntegrations: boolean;
  selectIntegration: (integration: Integration) => void;
};
export const IntegrationsSection = (props: Props) => {
  const {
    title,
    integrations,
    extra,
    activeIntegrations,
    availableIntegrations,
    selectIntegration,
  } = props;
  const id = useId();

  return integrations.length > 0 || !!extra ? (
    <section className="grid grid-cols-2 gap-24" aria-labelledby={id}>
      <h2 className="col-span-2 text-primary heading-l" id={id}>
        {title}
      </h2>
      {integrations.map((integration) => (
        <IntegrationBox
          key={`integration_${integration.id}`}
          integration={{
            ...integration,
            active: activeIntegrations,
            available: availableIntegrations,
          }}
          selectIntegration={selectIntegration}
        />
      ))}
      {extra ? extra.map((child) => child) : null}
    </section>
  ) : null;
};
