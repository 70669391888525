import { Icon, Button, colors, DeprecatedPreview } from '@dev-spendesk/grapes';
import React from 'react';

import { ContactUs } from 'common/components/ContactUs';
import { useTranslation } from 'common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  title?: string;
  questions: {
    title: string;
    link: string;
  }[];
  helpCenterIndex: {
    link: string;
    title: string;
  };
  openFixIntercomCookiesModal?: () => void;
};

const HelpTooltip = ({
  title,
  questions,
  helpCenterIndex,
  openFixIntercomCookiesModal,
}: Props) => {
  const { t } = useTranslation('global');

  const handleChatClicked = () => {
    track(AnalyticEventName.HELP_CENTER_CLICKED_CHAT);
  };

  const handleLinkClicked = (link: string, linkTitle: string) => {
    track(AnalyticEventName.HELP_CENTER_CLICKED_LINK, {
      link,
      title: linkTitle,
    });
  };

  return (
    <div className="w-[482px] overflow-hidden rounded-12">
      <div className="flex items-center gap-16 p-16">
        <Icon
          name="circle-question-outline"
          size="l"
          color={colors.contentAlertDefault}
        />
        <div className="text-primary title-m">
          {title || t('misc.needHelp')}
        </div>
      </div>
      <div className="bg-primary-default p-16 pb-24">
        <div className="flex flex-col gap-4">
          {questions.map((question) => (
            <DeprecatedPreview
              key={question.title}
              fit="parent"
              primaryText={question.title}
              rightAddon={<Icon aria-hidden="true" name="chevron-right" />}
              onClick={() => {
                handleLinkClicked(question.link, question.title);
                window.open(question.link, '_blank');
              }}
            />
          ))}
        </div>
        <div className="mt-8 flex items-center justify-between">
          <a
            href={helpCenterIndex.link}
            className="flex items-center justify-center gap-4 text-primary title-m hover:text-primary"
            onClick={() =>
              handleLinkClicked(helpCenterIndex.link, helpCenterIndex.title)
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="arrow-right" size="s" />
            <span>{t('helpCenter.findOutMore')}</span>
          </a>

          <ContactUs openFixIntercomCookiesModal={openFixIntercomCookiesModal}>
            {({ handleContactUs }) => (
              <Button
                variant="tertiaryNeutral"
                text={t('helpCenter.contactUs')}
                onClick={() => {
                  handleContactUs();
                  handleChatClicked();
                }}
              />
            )}
          </ContactUs>
        </div>
      </div>
    </div>
  );
};

export default HelpTooltip;
