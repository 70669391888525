import { Button, FormField, Modal } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import React from 'react';

import { AutocompleteMultipleSearch } from 'common/components/AutocompleteSearch';
import { useTranslation } from 'common/hooks/useTranslation';
import { type TeamRaw, type TeamUserRaw } from 'modules/members/models/teams';

type FormValues = {
  selectedAdmins: { key: string; label: string }[];
};

type Props = {
  users: TeamUserRaw[];
  group: TeamRaw;
  onSave: (selectedAdmins: string[]) => Promise<void>;
  onCancel: () => void;
};

export const TeamManagerModal = ({ users, group, onSave, onCancel }: Props) => {
  const { t } = useTranslation('global');

  const formikProps = useFormik<FormValues>({
    initialValues: {
      selectedAdmins:
        users
          .filter((user) => group.admin_ids.includes(user.id))
          .map((user) => ({
            key: user.id,
            label: user.pending ? user.email : user.display_name,
          })) ?? [],
    },
    validateOnChange: false,
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.selectedAdmins.length) {
        errors.selectedAdmins = t('misc.requiredField');
      }
      return errors;
    },
    onSubmit: async (values) => {
      await onSave(
        values.selectedAdmins.map((selectedAdmin) => selectedAdmin.key),
      );
    },
  });

  return (
    <Modal
      isOpen
      iconName="person-group"
      iconVariant="info"
      title={t('members.teamManagers', {
        teamName: group.name,
      })}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={onCancel}
        />,
        <Button
          key="save"
          text={t('misc.save')}
          type="submit"
          form="team-manager"
          isLoading={formikProps.isSubmitting}
        />,
      ]}
      onClose={onCancel}
    >
      <form
        className="text-left"
        id="team-manager"
        onSubmit={formikProps.handleSubmit}
      >
        <FormField
          label={t('members.teamManagers')}
          alertMessage={
            typeof formikProps.errors.selectedAdmins === 'string'
              ? formikProps.errors.selectedAdmins
              : undefined
          }
        >
          <AutocompleteMultipleSearch
            fit="parent"
            values={formikProps.values.selectedAdmins}
            options={users.map((user) => ({
              key: user.id,
              label: user.pending ? user.email : user.display_name,
            }))}
            translations={{
              selectAll: t('misc.selectAll'),
              selected: t('approvalPolicy.members.selected', {
                count: formikProps.values.selectedAdmins.length,
              }),
            }}
            placeholder={t('teams.selectManagers')}
            onSelect={(selectedAdmins) =>
              formikProps.setFieldValue('selectedAdmins', selectedAdmins)
            }
          />
        </FormField>
      </form>
    </Modal>
  );
};
