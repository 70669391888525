import {
  Modal,
  Button,
  Upload,
  Callout,
  Badge,
  FileCard,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  onUpload(file: File): void;
  onCancel(): void;
  downloadLink: string;
  type: 'create' | 'edit';
};

export const ImportBudgetModal = ({
  downloadLink,
  onUpload,
  onCancel,
  type,
}: Props) => {
  const { t } = useTranslation('global');
  const [budgetFile, setBudgetFile] = useState<File>();
  const [errorMissingFile, setErrorMissingFile] = useState(false);

  const title =
    type === 'create'
      ? t('budget.budgetaryExercise.importModal.title.create')
      : t('budget.budgetaryExercise.importModal.title.edit');
  const subtitle =
    type === 'create'
      ? t('budget.budgetaryExercise.importModal.subtitle.create')
      : t('budget.budgetaryExercise.importModal.subtitle.edit');

  return (
    <Modal
      isOpen
      actions={[
        <Button
          key="no"
          onClick={onCancel}
          text={t('misc.cancel')}
          variant="secondaryNeutral"
        />,
        <Button
          key="yes"
          text={t(`budget.budgetaryExercise.importModal.uploadButton`)}
          variant="primaryBrand"
          onClick={() => {
            if (!budgetFile) {
              setErrorMissingFile(true);
              return;
            }
            track(
              AnalyticEventName.BUDGETS_UPDATE_BUDGET_START_IMPORT_BUTTON_CLICKED,
            );
            onUpload(budgetFile);
          }}
        />,
      ]}
      iconName="gear"
      iconVariant="info"
      title={title}
      subtitle={subtitle}
    >
      <div className="my-32">
        <div className="mb-8 flex items-center justify-start gap-8 text-left text-primary title-m">
          <Badge variant="primary">1</Badge>
          {t('budget.budgetaryExercise.importModal.instructionsDownload')}
        </div>
        <div className="mb-32 mt-24">
          <Button
            component="a"
            href={downloadLink}
            onClick={() => {
              track(
                AnalyticEventName.BUDGETS_UPDATE_BUDGET_DOWNLOAD_TEMPLATE_BUTTON_CLICKED,
              );
            }}
            text={t('budget.budgetaryExercise.importModal.downloadButton')}
            variant="secondaryNeutral"
          />
        </div>
        <div className="mb-8 flex items-center justify-start gap-8 text-left text-primary title-m">
          <Badge variant="primary">2</Badge>
          {t('budget.budgetaryExercise.importModal.instructionsUpload')}
        </div>
        {!budgetFile ? (
          <>
            <Upload
              data-testid="import-budget-file-upload"
              activeDragContent={
                <>{t('forms.uploadFiles.activeDragContent')}</>
              }
              content={
                <Trans i18nKey="forms.uploadFiles.content">
                  <br />-
                  <span className="bold font-semibold text-primary underline">
                    -
                  </span>
                </Trans>
              }
              onUpload={(files, uploadType) => {
                if (uploadType === 'selection') {
                  track(
                    AnalyticEventName.BUDGETS_UPDATE_BUDGET_UPLOAD_FILE_BUTTON_CLICKED,
                  );
                }
                if (uploadType === 'drag_and_drop') {
                  track(
                    AnalyticEventName.BUDGETS_UPDATE_BUDGET_UPLOAD_FILE_DRAG_N_DROPPED,
                  );
                }
                setErrorMissingFile(false);
                setBudgetFile(files[0]);
              }}
              isInvalid={errorMissingFile}
            />
            {errorMissingFile && (
              <Callout
                className="mt-8"
                title={t(
                  'budget.budgetaryExercise.importModal.errorMissingFile',
                )}
                variant="alert"
              />
            )}
          </>
        ) : (
          <FileCard
            data-testid="import-budget-file-preview"
            title={budgetFile.name}
            onDelete={() => setBudgetFile(undefined)}
          />
        )}
      </div>
    </Modal>
  );
};
