import { Select } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import {
  type Journal,
  type JournalMapping,
} from 'src/core/modules/bookkeep/settings/accounting';

export const BankJournalSelectField = ({
  availableJournals,
  initialJournal,
  onCreate,
}: {
  availableJournals: Journal[];
  initialJournal: Journal | undefined;
  onCreate: (journalMapping: JournalMapping) => void;
}) => {
  const { t } = useTranslation('global');

  const currentValue = initialJournal
    ? {
        key: initialJournal.id,
        label: `${initialJournal.externalCode} - ${initialJournal.externalName}`,
      }
    : undefined;

  const handleSelect = (selectedOption: { key: string; label: string }) => {
    const selectedJournal = availableJournals.find(
      (journal) => journal.id === selectedOption.key,
    );

    if (selectedJournal && selectedJournal !== initialJournal) {
      onCreate({
        mappingType: 'bank_journal',
        mappedJournal: selectedJournal,
      });
    }
  };

  return (
    <>
      <div className="mt-24">
        <h5 className="mb-8 text-primary body-m">
          {t('bookkeep.integrations.settings.chift.bankJournalExport.title')}
        </h5>
        <p className="mb-8 text-secondary-bg-primary body-m">
          {t(
            'bookkeep.integrations.settings.chift.bankJournalExport.description',
          )}
        </p>
      </div>
      <div className="bankJournalSelectorField w-6/12">
        <Select
          fit="parent"
          value={currentValue}
          onSelect={handleSelect}
          options={availableJournals.map((journal) => ({
            key: journal.id,
            label: `${journal.externalCode} - ${journal.externalName}`,
          }))}
          placeholder={t(
            'bookkeep.integrations.settings.chift.bankJournalExport.selectJournal',
          )}
        />
      </div>
    </>
  );
};
