import { Icon } from '@dev-spendesk/grapes';

import { useJiraLink } from './useJiraLink';

const UI_REFRESH_PORTAL_URL =
  'https://spendesk.atlassian.net/servicedesk/customer/portal/2/group/4/create/634';

export const UIRefreshReporter = () => {
  const uiRefreshLink = useJiraLink(UI_REFRESH_PORTAL_URL);

  if (uiRefreshLink === null) {
    return null;
  }

  return (
    <li>
      <button
        onClick={() => {
          window.open(uiRefreshLink, '_blank');
        }}
        className="navigation-item h-40"
        type="button"
      >
        <Icon
          name="megaphone"
          aria-hidden="true"
          size="m"
          className="shrink-0"
        />
        <span className="navigation-toggle-display grow">
          UI Refresh Feedback
        </span>
      </button>
    </li>
  );
};
