import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ExpenseAccount } from '../../accounting';

interface ExpenseAccounts {
  expenseAccounts: [{ account: ExpenseAccount }];
}
export const useAllExpenseAccountsQuery = (): QueryState<ExpenseAccount[]> => {
  const { company: companyId } = useParams();

  return useQuery<ExpenseAccount[], ExpenseAccounts>({
    key: ['getExpenseAccounts', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/chart-of-accounts/expense-accounts',
    },
    reshapeData(data) {
      return data.expenseAccounts.map(({ account }) => {
        return {
          ...account,
        };
      });
    },
  });
};
