import {
  Button,
  Callout,
  FormField,
  Link,
  DropdownItem,
} from '@dev-spendesk/grapes';
import { type Dispatch } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { openSupportChat } from 'src/core/utils/supportChat';

import { type ModalState } from './ModalState';
import { type AccountingSoftware } from '../../../../../../../integration/status';
import { type CustomField } from '../../../../../hooks/useCustomFieldsQuery';
import styles from '../AnalyticalFieldsSection.module.css';

interface Props {
  customFields: CustomField[];
  integration: AccountingSoftware;
  hasCustomFieldsActive: boolean;
  modalState: ModalState;
  setModalState: Dispatch<ModalState>;
}

export const CustomFieldSelectAndInfo = ({
  integration,
  customFields,
  hasCustomFieldsActive,
  modalState,
  setModalState,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  if (
    !modalState ||
    modalState.modalKind !== 'map' ||
    modalState.kind === 'notMapped' ||
    modalState.codeType !== 'customField'
  ) {
    return <></>;
  }

  const autoCompleteOptions = customFields.map((customField) => {
    return {
      key: customField.id,
      label: customField.name,
    };
  });

  if (!hasCustomFieldsActive) {
    return (
      <Callout
        className={styles.analyticalField}
        title={t(
          'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsWarning.header',
        )}
        variant="warning"
      >
        <div className={styles.analyticalField__content}>
          <p className={styles.analyticalField__text}>
            {t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsWarning.text',
            )}
            &nbsp;
            <span className={styles.uppercase}>
              {integration} {modalState.externalName}.
            </span>
            &nbsp;
            {t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsWarning.callout',
            )}
          </p>
          <Button
            variant="secondaryNeutral"
            onClick={() => {
              openSupportChat();
            }}
            text={t(
              'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsWarning.button',
            )}
          />
        </div>
      </Callout>
    );
  }

  return (
    <div className={styles.analyticalField}>
      <FormField
        label={t('bookkeep.integrations.settings.createCustomField')}
        htmlFor="set-mapping-autocomplete-input"
      >
        <AutocompleteSearch
          fit="parent"
          value={undefined}
          onAddOption={(label) => {
            return { key: uuidv4(), label };
          }}
          onSelect={(analyticalField) => {
            if (analyticalField) {
              setModalState({
                ...modalState,
                kind: 'mapped',
                codeType: 'customField',
                internalName: analyticalField.label,
                internalId: analyticalField.key,
              });
            }
          }}
          options={autoCompleteOptions}
          renderAddOption={(value) => (
            <DropdownItem
              label={
                <>
                  {t('misc.create')}{' '}
                  <span className="text-primary">{value}</span>
                </>
              }
            />
          )}
          showClearSelectionButton
        />
      </FormField>
      <Callout
        className={styles.analyticalField}
        title={
          <div className={styles.analyticalField__callout}>
            <p className={styles.analyticalField__text}>
              {t(
                'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsInfo.pullAndMapWithValues.text',
                {
                  externalName: modalState.externalName,
                },
              )}
            </p>
            <Link
              isExternal
              href={routeFor(routes.COMPANY_CATEGORIES.path, {
                company: companyId,
              })}
              className={styles.analyticalField__link}
            >
              {t(
                'bookkeep.integrations.settings.analyticalFieldsMappingsTable.customFieldsInfo.linkTrackingCategories',
              )}
            </Link>
          </div>
        }
        variant="info"
      />
    </div>
  );
};
