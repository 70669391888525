import React, { useEffect, useState } from 'react';
import Joyride, {
  ACTIONS,
  EVENTS,
  type CallBackProps,
  type Props as JoyrideProps,
  type Step,
} from 'react-joyride';

import { ProductTourTooltip } from './components/ProductTourTooltip';

type Props = {
  className?: string;
  onClose?: ({ index }: { index: number }) => void;
  onUnmountClose?: () => void;
  onDone?: () => void;
  onStart?: () => void;
  variant?: 'light' | 'dark';
  steps: Step[];
  withActionButtons?: boolean;
} & Partial<JoyrideProps>;

export const ProductTour = ({
  className,
  onClose,
  onUnmountClose,
  onDone,
  onStart,
  variant = 'light',
  steps,
  withActionButtons,
  ...props
}: Props) => {
  const [isRunning, setIsRunning] = useState(true);

  const enrichedSteps = steps.map((step) => ({
    ...step,
    disableBeacon: true,
  }));

  const handleJoyrideCallback = ({ action, index, type }: CallBackProps) => {
    const isStarting = action === ACTIONS.START && type === EVENTS.TOUR_START;
    if (isStarting) {
      onStart?.();
    }

    const isClosing = action === ACTIONS.CLOSE && type === EVENTS.STEP_AFTER;
    if (isClosing) {
      setIsRunning(false);
      onClose?.({ index });
    }

    const isCompleting = action === ACTIONS.NEXT && type === EVENTS.TOUR_END;
    if (isCompleting) {
      setIsRunning(false);
      onDone?.();
    }
  };

  // Trigger onClose when component unmounts and the tour is still running
  useEffect(
    () => () => {
      if (isRunning) {
        onUnmountClose?.();
      }
    },
    [],
  );

  return (
    <Joyride
      {...props}
      callback={handleJoyrideCallback}
      continuous
      disableOverlay
      disableScrollParentFix
      floaterProps={{
        hideArrow: true,
      }}
      run={isRunning}
      steps={enrichedSteps}
      styles={{
        options: {
          zIndex: 9001, // Grapes' --z-index-modal + 1
        },
      }}
      tooltipComponent={(tooltipProps) => (
        <ProductTourTooltip
          {...tooltipProps}
          className={className}
          variant={variant}
          withActionButtons={withActionButtons}
        />
      )}
    />
  );
};
