import { IconButton } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { type ReactNode } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './PanelLoader.module.css';

type Props = {
  header?: ReactNode;
  className?: string;
  withClose?: boolean;
  withNavigation?: boolean;
};

export function PanelLoader({
  header,
  className,
  withClose = true,
  withNavigation = false,
}: Props) {
  const { t } = useTranslation('global');
  return (
    <div className={cx(styles.panelLoader, className, 'shadow-xs')}>
      {withNavigation && (
        <div className={styles.panelLoaderNavigation}>
          <div className="text-primary title-m" />
          {withClose && (
            <IconButton
              iconName="cross"
              variant="tertiaryNeutral"
              onClick={() => null}
              hasNegativeMargins
              aria-label={t('misc.close')}
            />
          )}
        </div>
      )}
      {header && (
        <div
          className={cx(
            'border border-x-0 border-t-0 border-solid border-default px-24',
            withNavigation ? 'pt-8' : 'pt-24',
            'pb-24',
          )}
        >
          {header}
        </div>
      )}
    </div>
  );
}
