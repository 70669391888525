import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ForeignExchangeAccount } from '../../accounting/foreignExchangeAccount';

export const useForeignExchangeAccountsQuery = (): QueryState<
  ForeignExchangeAccount[]
> => {
  const { company: companyId } = useParams();

  return useQuery<ForeignExchangeAccount[]>({
    key: ['getForeignExchangeAccounts', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint:
        '/accounting-integration/chart-of-accounts/foreign-exchange-accounts',
    },
    reshapeData(foreignExchangeAccounts) {
      return foreignExchangeAccounts;
    },
  });
};
