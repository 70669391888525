import { Button, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useCompany } from 'modules/app/hooks/useCompany';
import { markOnboardingPopupAsSeen } from 'src/core/actions/global';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { getShowKybProcedureValidatedPopup } from 'src/core/selectors/globalSelectors';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import fullAccessImageUrl from './assets/fullAccess.svg';
import { type AppDispatch } from '../../../app/redux/store';

const MODAL_CODE = 'KYB_PROCEDURE_VALIDATED';

export const KybProcedureUpdatedModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { t } = useTranslation('global');

  const company = useCompany();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const showKybProcedureValidatedPopup = useSelector(
    getShowKybProcedureValidatedPopup,
  );

  const content = {
    imageSrc: fullAccessImageUrl,
    title: t('homepage.fullWalletAccessModal.title'),
    subtitle: t('homepage.fullWalletAccessModal.message'),
    skip: t('homepage.fullWalletAccessModal.skip'),
    cta: t('homepage.fullWalletAccessModal.cta'),
  };

  const markPopupAsSeen = () => {
    dispatch(markOnboardingPopupAsSeen(MODAL_CODE));
  };

  const onSkip = () => {
    markPopupAsSeen();
    track(AnalyticEventName.WELCOME_MODAL_LOAD_FUNDS_SKIPPED, {
      type: MODAL_CODE,
    });
    setIsOpen(false);
  };

  const onLoadFunds = () => {
    markPopupAsSeen();
    track(AnalyticEventName.WELCOME_MODAL_LOAD_FUNDS_CLICKED, {
      type: MODAL_CODE,
    });
    history.push(
      routeFor(routes.COMPANY_BANK_FUNDING_SOURCE.path, {
        company: company.id,
      }),
    );
  };

  if (!showKybProcedureValidatedPopup) {
    return null;
  }

  return (
    <Modal
      actions={
        <div className="flex flex-col gap-8">
          <Button
            key="loadFunds"
            onClick={onLoadFunds}
            text={content.cta}
            variant="primaryBrand"
          />
          <Button
            key="skip"
            onClick={onSkip}
            text={content.skip}
            variant="tertiaryNeutral"
            iconName="arrow-right"
            iconPosition="right"
          />
        </div>
      }
      illustration={<img src={content.imageSrc} alt="Limited access" />}
      illustrationHeight="241px"
      title={content.title}
      subtitle={content.subtitle}
      isOpen={isOpen}
    />
  );
};
