import { Button, FormField, Input, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';

import { AddressForm } from 'modules/company/general-settings';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type BillingInformationFormValues } from './form';

import './BillingInformationForm.css';

type Props = FormikProps<BillingInformationFormValues>;

export const BillingInformationForm = (props: Props) => {
  const { t } = useTranslation();

  const { dirty, errors, values, handleChange, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit} className="BillingInformationForm shadow-xs">
      <AddressForm
        areFieldsDisabled={values.isBillingInfoSameAsCompany}
        {...props}
      />
      <div className="BillingInformationForm__field-container">
        <FormField
          className="BillingInformationForm__form-field"
          label={t('billing.billingInformation.emailLabel')}
          alertMessage={errors.email ? t(errors.email) : undefined}
        >
          <TextInput
            name="email"
            value={values.email}
            placeholder={t('billing.billingInformation.emailPlaceholder')}
            onChange={handleChange}
          />
        </FormField>

        <FormField
          className="BillingInformationForm__form-field"
          label={t('billing.billingInformation.vatLabel')}
          alertMessage={errors.vat ? t(errors.vat) : undefined}
        >
          <Input
            name="vat"
            value={values.vat}
            placeholder={t('billing.billingInformation.vatPlaceholder')}
            onChange={handleChange}
            maskOptions={{ uppercase: true }}
          />
        </FormField>
      </div>
      <Button
        className="mt-24"
        variant="primaryBrand"
        type="submit"
        isDisabled={!dirty}
        text={t('billing.submitButton')}
      />
    </form>
  );
};
