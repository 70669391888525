import { Icon, type IconName } from '@dev-spendesk/grapes';
import React, { useId, type ReactNode } from 'react';
import { NavLink as RRNavLink, matchPath } from 'react-router-dom';

type Props = {
  href: string;
  iconName: IconName;
  children: ReactNode;
  activePaths?: string[];
  pulse?: boolean;
};

export const NavLink = ({
  href,
  iconName,
  children,
  activePaths,
  pulse = false,
}: Props) => {
  const textId = useId();
  return (
    <RRNavLink
      to={href}
      className="navigation-item h-40"
      aria-labelledby={textId}
      isActive={(_, location) => {
        const match = matchPath(location.pathname, {
          path: activePaths ?? href,
          exact: activePaths && activePaths.length > 0,
          strict: false,
        });
        return match !== null;
      }}
    >
      <div className="relative flex items-center">
        <Icon
          name={iconName}
          size="m"
          aria-hidden="true"
          className="shrink-0"
        />
        {pulse && (
          <div className="absolute -right-8 -top-8 h-8 w-8 animate-pulse rounded-8 bg-primary-alert-default" />
        )}
      </div>
      <span id={textId} className="navigation-toggle-display">
        {children}
      </span>
    </RRNavLink>
  );
};
