import {
  Button,
  CheckboxField,
  DeprecatedPreview,
  Modal,
} from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import { SuppressWarningsForBeneficiariesQueryFactory } from 'modules/invoices/transfer/hooks';
import { type Beneficiary } from 'modules/invoices/transfer/models';

import { VerificationErrorTag } from './VerificationErrorTag';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  unverifiedBeneficiaries: Beneficiary.VerificationResult[];
  billType: 'invoice' | 'expense';
};

export const VerificationOfBeneficiariesModal = ({
  isOpen,
  onCancel,
  onConfirm,
  billType,
  unverifiedBeneficiaries,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();
  const getSuppressBeneficiariesWarningErrorMessage =
    SuppressWarningsForBeneficiariesQueryFactory.useVerifyTransfersBeneficiariesErrorMessage();
  const suppressWarningsForBeneficiary =
    SuppressWarningsForBeneficiariesQueryFactory.useVerifyBeneficiariesQueryFactory();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsCheckboxChecked(false);
  }, [isOpen]);

  const actions = [
    <Button
      key="cancel"
      text={t(
        'wiretransfer.confirmation.verificationOfBeneficiariesModal.cancelButton',
      )}
      variant="secondaryNeutral"
      onClick={() => {
        onCancel();
        setIsCheckboxChecked(false);
      }}
    />,
    <Button
      key="continue"
      text={t(
        'wiretransfer.confirmation.verificationOfBeneficiariesModal.continueButton',
      )}
      variant="primaryBrand"
      onClick={async () => {
        try {
          await suppressWarningsForBeneficiary({
            paymentIds: unverifiedBeneficiaries.map(
              (unverifiedBeneficiary) => unverifiedBeneficiary.paymentId,
            ),
            type: billType,
          });
          onConfirm();
        } catch (error) {
          dangerNotif(getSuppressBeneficiariesWarningErrorMessage(error));
        }
      }}
      isDisabled={!isCheckboxChecked}
    />,
  ];

  return (
    <Modal
      actions={actions}
      iconName="triangle-warning"
      iconVariant="warning"
      title={t(
        'wiretransfer.confirmation.verificationOfBeneficiariesModal.title',
      )}
      subtitle={t(
        'wiretransfer.confirmation.verificationOfBeneficiariesModal.subtitle',
      )}
      isOpen={isOpen}
    >
      <div className="mb-8 text-left">
        <span className="font-semibold text-primary">
          {t(
            'wiretransfer.confirmation.verificationOfBeneficiariesModal.listTitle',
            {
              nbOfChecks: unverifiedBeneficiaries.length,
            },
          )}
        </span>
      </div>
      <div>
        {unverifiedBeneficiaries.map((unverifiedBeneficiary) => (
          <DeprecatedPreview
            className="mb-8 bg-secondary-default"
            key={unverifiedBeneficiary.paymentId}
            fit="parent"
            primaryText={unverifiedBeneficiary.beneficiaryName}
            secondaryText={unverifiedBeneficiary.responseAccountHolderFullName}
            rightAddon={
              <VerificationErrorTag error={unverifiedBeneficiary.response} />
            }
          />
        ))}
      </div>
      <CheckboxField
        className="mt-16"
        label={t(
          'wiretransfer.confirmation.verificationOfBeneficiariesModal.checkbox',
        )}
        isChecked={isCheckboxChecked}
        onChange={(e) => {
          setIsCheckboxChecked(e.target.checked);
        }}
      />
    </Modal>
  );
};
