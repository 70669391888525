import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import type { AppDispatch } from 'modules/app/redux/store';
import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split';
import { ActiveAccountingIntegrationApi } from 'modules/bookkeep/hooks';
import {
  type FormValues,
  getFormInitialValues,
  validate,
} from 'modules/bookkeep/settings/export/components/TemplateEditorForm/formValues';
import { getOpenedTemplate } from 'modules/bookkeep/settings/export/redux/selectors';
import { fetchTemplate } from 'modules/bookkeep/settings/export/redux/thunks';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { ExportSettingsForm } from 'modules/onboarding/setup-hub/pages/Accounting/AccountingExportsSettings/components/ExportSettingsForm';
import { routes } from 'modules/onboarding/setup-hub/pages/routes';
import { routeFor } from 'src/core/constants/routes';

export const AccountingExportsSettings = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const activeAccountingIntegration =
    ActiveAccountingIntegrationApi.useActiveAccountingIntegration();
  const isDoubleOrSingleEntry =
    activeAccountingIntegration.status === 'success' &&
    (activeAccountingIntegration.data.activeAccountingIntegration ===
      'SpendeskAccounting' ||
      activeAccountingIntegration.data.activeAccountingIntegration ===
        'SpendeskAccountingSingleEntry');
  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();
  const template = useSelector(getOpenedTemplate);

  useEffect(() => {
    if (!template) {
      dispatch(fetchTemplate('default', true));
    }
  }, []);

  const formikProps = useFormik<FormValues>({
    initialValues: getFormInitialValues({
      template,
      isDoubleOrSingleEntry,
      customFields: [],
      isAnalyticalSplitActivated,
      othersLabel: t('exports.others'),
    }),
    enableReinitialize: true,
    validateOnChange: true,
    validate: (values) => {
      return validate(values, t);
    },
    onSubmit: async () => {
      const nextPath = routeFor(routes.ACCOUNTING_EXPORT_COLUMNS.path, {
        company: companyId,
      });
      history.push(nextPath, { exportSettings: formikProps.values });
    },
  });

  const goBack = () => {
    const previousPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(previousPath);
  };

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.exportGeneralSettings.description')}
        onBack={goBack}
        onNext={formikProps.handleSubmit}
        title={t('setupHub.exportGeneralSettings.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('misc.needHelp'),
        }}
      >
        <ExportSettingsForm
          {...formikProps}
          activeAccountingIntegration={
            activeAccountingIntegration.status === 'success'
              ? activeAccountingIntegration.data.activeAccountingIntegration
              : undefined
          }
        />
      </TaskStepLayout>
    </div>
  );
};
