import {
  CalendarRange,
  Popover,
  DatePickerInput,
  type DateRange,
  Callout,
  SwitchField,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useEffect, useId, useRef } from 'react';

import { AutocompleteNoOptions } from 'src/core/common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type PayableAmortisationScheme } from '../../../accounting-integration/models';

type PayableAmortisation = {
  date?: { from: Date | undefined; to: Date | undefined } | null;
  schemeId?: string | null;
  schemeName?: string | null;
};

type Props = {
  calendarId?: string;
  initialValue?: PayableAmortisation | undefined | null;
  value: PayableAmortisation | undefined | null;
  onChange: (value: PayableAmortisation | undefined | null) => void;
  className?: string;
  alertMessage?: string;
  withAmortisationScheme?: boolean;
  amortisationSchemes: PayableAmortisationScheme[];
  payableDate: string | Date;
};

export const PayableAmortisationField = ({
  className = '',
  value,
  initialValue,
  onChange,
  calendarId = 'payable-amortisation',
  alertMessage,
  withAmortisationScheme,
  amortisationSchemes,
  payableDate,
  ...props
}: Props) => {
  const { t } = useTranslation('global');

  const [from, to]: DateRange = [value?.date?.from, value?.date?.to];

  const amortisationSchemeOptions = amortisationSchemes.map(({ id, name }) => ({
    key: id,
    label: name,
  }));

  const openCalendar = (onClick: () => void, isOpen: boolean) => {
    if (!isOpen) {
      onClick();
      setTimeout(() => {
        if (typeof calendarReference.current?.scrollIntoView === 'function') {
          calendarReference.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      }, 0);
    }
  };

  const closeCalendar = (onClick: () => void, isOpen: boolean) => {
    if (isOpen) {
      onClick();
    }
  };

  const id = useId();

  const scollToReference = useRef<HTMLDivElement | null>(null);

  const calendarReference = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      value &&
      typeof calendarReference.current?.scrollIntoView === 'function'
    ) {
      scollToReference.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [value]);

  const isAmortisationSchemeDeleted =
    !!value?.schemeId &&
    !amortisationSchemes?.find((a) => a.id === value.schemeId);

  if (!value) {
    return (
      <div className={className}>
        <SwitchField
          label={t('payables.panel.amortisePayable')}
          fit="parent"
          isChecked={false}
          onChange={() => {
            onChange(initialValue || { date: undefined, schemeId: undefined });
          }}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <SwitchField
        label={t('payables.panel.amortisePayable')}
        fit="parent"
        isChecked
        onChange={() => {
          onChange(null);
        }}
      />
      <Popover
        className="mt-8 w-auto"
        renderTrigger={({ onClick, ...triggerProps }, isOpen) => (
          <div ref={scollToReference}>
            <div className={classNames('flex flex-row gap-8', className)}>
              <div className="w-[calc((100%-8px)/2)]">
                <DatePickerInput
                  placeholder={t('payables.panel.amortisationStartDate')}
                  fit="parent"
                  calendarId={calendarId}
                  isInvalid={!!alertMessage && !from}
                  {...triggerProps}
                  {...props}
                  {...{ id: `from-${id}` }}
                  openCalendar={() => openCalendar(onClick, isOpen)}
                  closeCalendar={() => closeCalendar(onClick, isOpen)}
                  isCalendarOpen={isOpen}
                  value={from}
                  onChange={(date) => {
                    onChange({
                      date: { from: date, to: to },
                      schemeId: value?.schemeId,
                    });
                  }}
                />
              </div>

              <div className="w-[calc((100%-8px)/2)]">
                <DatePickerInput
                  placeholder={t('payables.panel.amortisationEndDate')}
                  fit="parent"
                  calendarId={calendarId}
                  isInvalid={!!alertMessage && !to}
                  {...triggerProps}
                  {...props}
                  {...{ id: `to-${id}` }}
                  openCalendar={() => openCalendar(onClick, isOpen)}
                  closeCalendar={() => closeCalendar(onClick, isOpen)}
                  isCalendarOpen={isOpen}
                  value={to}
                  onChange={(date) => {
                    onChange({
                      date: { from, to: date },
                      schemeId: value?.schemeId,
                    });
                  }}
                />
              </div>
            </div>

            {alertMessage && (
              <Callout variant="alert" className="mt-8" title={alertMessage} />
            )}
          </div>
        )}
      >
        {(closeDropdown) => (
          <>
            <div
              className="flex flex-col items-center p-8"
              id={calendarId}
              ref={calendarReference}
            >
              <CalendarRange
                numberOfCalendars={1}
                value={
                  !value.date
                    ? [new Date(payableDate), new Date(payableDate)]
                    : [from, to]
                }
                onClick={(newValue) => {
                  onChange({
                    date: {
                      from: newValue[0] as Date,
                      to: newValue[1] as Date,
                    },
                    schemeId: value?.schemeId,
                  });
                  if (newValue.every(Boolean)) {
                    closeDropdown();
                  }
                }}
              />
            </div>
          </>
        )}
      </Popover>
      {withAmortisationScheme && (
        <div className="mt-16">
          <AutocompleteSearch
            isDisabled={!amortisationSchemeOptions.length}
            placeholder={`${t('payables.panel.amortisationTemplate')} (${t('misc.optional')})`}
            fit="parent"
            isInvalid={isAmortisationSchemeDeleted}
            options={amortisationSchemeOptions}
            value={
              amortisationSchemeOptions.find(
                ({ key }) => key === value?.schemeId,
              ) ||
              (value?.schemeId
                ? {
                    key: value?.schemeId,
                    label:
                      value?.schemeName ??
                      t('payables.panel.unknownAmortisationTemplate'),
                  }
                : undefined)
            }
            onSelect={(amortisationScheme) => {
              onChange({
                date: value.date,
                schemeId: amortisationScheme?.key ?? null,
                schemeName: amortisationScheme?.label ?? null,
              });
            }}
            renderNoOptions={(rawValue) => (
              <AutocompleteNoOptions value={rawValue} />
            )}
          />

          {isAmortisationSchemeDeleted && (
            <Callout
              variant="alert"
              className="mt-8"
              title={t('payables.panel.amortisationSchemeDeleted')}
            />
          )}
        </div>
      )}
    </div>
  );
};
