import { type getCountryFromCode } from 'src/core/config/country';
import { type CurrenciesKey } from 'src/core/config/money';
import { type CompanyType } from 'src/core/reducers/global';

export type EntityCurrency = CurrenciesKey;

type EntityCountryCode = Parameters<typeof getCountryFromCode>[0];

type BreakdownTrend = 'positive' | 'neutral' | 'warning' | 'group';

type WalletSummaryBreakdown = {
  scheduledTransfers: number;
  cardsLimits: number;
  available: number;
  shortfall: number;
};

export type EntityWalletSummaryBreakdown = WalletSummaryBreakdown & {
  trend: Extract<BreakdownTrend, 'positive' | 'warning'>;
};

export type OrganisationWalletSummaryBreakdown = WalletSummaryBreakdown & {
  availableCount: number;
  shortfallCount: number;
  trend: Extract<BreakdownTrend, 'group'>;
};

export type EntityBasicInformation = {
  id: string;
  name: string;
  country: EntityCountryCode;
  currency: EntityCurrency;
  type: CompanyType;
  hasChurned: boolean;
  isChurning: boolean;
};

export type EntityActions = {
  inboxInvoices: {
    toAssign: number;
    awaiting: number;
  };
  requests: {
    all: number | undefined;
    toApprove: number | undefined;
  };
  invoices: {
    toReview: number | undefined;
    toSchedule: number | undefined;
    toConfirm: number | undefined;
  };
  expenseClaims: {
    toReview: number | undefined;
    toReimburse: number | undefined;
    toConfirm: number | undefined;
  };
  receipts: {
    late: number | undefined;
    missing: number | undefined;
  };
  payables: {
    toPrepare: number | undefined;
    toExport: number | undefined;
  };
};

type EntityWalletSummaryDetails = {
  walletBalance: number;
  walletBalanceByCurrency: Record<EntityCurrency, number> | undefined;
  breakdown: EntityWalletSummaryBreakdown;
};

type EntityKycStatus = {
  isKycInProgress: boolean;
  isKycAwaitingApproval: boolean;
};

export type EntityDetailedInformation = EntityWalletSummaryDetails &
  EntityKycStatus;

export type EnrichedEntityInformation = EntityBasicInformation &
  EntityDetailedInformation;

export const isEntityInformationEnriched = (
  entityData: EntityBasicInformation,
): entityData is EnrichedEntityInformation => {
  return 'walletBalance' in entityData && 'isKycInProgress' in entityData;
};
