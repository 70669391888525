import { Callout, FormField, Input, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { DayDropdown } from '../DayDropdown/DayDropdown';
import { PbrRuleTimeline } from '../PbrRuleTimeline';

type FormValues = {
  name: string;
  limit: number;
  delay: number;
  isDefault: boolean;
  memberIds: string[];
};

export const CreateControlRule = ({
  // Formik props
  values,
  errors,
  setFieldValue,
  handleSubmit,
}: FormikProps<FormValues>) => {
  const { t } = useTranslation('global');
  const hasPlayByTheRulesReceiptAndFieldsFeature = useFeature(
    FEATURES.PLAY_BY_THE_RULES_RECEIPT_AND_FIELDS,
  );

  return (
    <div className="mt-16 flex flex-col gap-32 text-left">
      <form
        className="flex flex-col content-stretch gap-24"
        onSubmit={handleSubmit}
      >
        <FormField
          label={t('controlRulesModal.nameLabel')}
          alertMessage={errors.name}
        >
          <TextInput
            value={values.name}
            placeholder={t('controlRulesModal.namePlaceholder')}
            onChange={(event) => setFieldValue('name', event.target.value)}
          />
        </FormField>
        <div className="grid grid-flow-col grid-cols-2 gap-24">
          <FormField
            label={
              hasPlayByTheRulesReceiptAndFieldsFeature
                ? t('controlRules.mandatoryFields.create.maxLate')
                : t('controlRulesModal.limitLabel')
            }
            alertMessage={errors.limit}
          >
            <Input
              type="number"
              min={1}
              value={values.limit}
              onChange={(event) => {
                const value = event.target.valueAsNumber;
                if (!Number.isNaN(value)) {
                  setFieldValue('limit', value);
                } else {
                  setFieldValue('limit', undefined);
                }
              }}
            />
          </FormField>
          <FormField
            className="relative"
            label={
              hasPlayByTheRulesReceiptAndFieldsFeature
                ? t('controlRules.mandatoryFields.create.delay')
                : t('controlRulesModal.delayLabel')
            }
          >
            <DayDropdown
              value={values.delay}
              onSelect={(days) => setFieldValue('delay', days)}
            />
          </FormField>
        </div>
        {hasPlayByTheRulesReceiptAndFieldsFeature && (
          <Callout
            className="mt-[-8px]"
            title={t('controlRules.mandatoryFields.create.callout.title')}
          >
            {t('controlRules.mandatoryFields.create.callout.description')}
          </Callout>
        )}
      </form>
      <hr className="separator" style={{ borderTop: 'none' }} />
      <PbrRuleTimeline
        completionDeadline={values.delay}
        incompletePaymentsLimit={values.limit}
        mode="admin"
        hasReceiptAndFieldsFeature={hasPlayByTheRulesReceiptAndFieldsFeature}
      />
    </div>
  );
};
