import React from 'react';

import { useNotifications, NotificationType } from 'modules/app/notifications';
import {
  useCostCentersQuery,
  useInvalidateCostCentersQuery,
} from 'modules/budgets/apis';
import { toApiFormat as toApiApprovalRules } from 'modules/company/structure/approval-flows/models';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { createApprovalScheme } from './hooks/createApprovalScheme';
import { editApprovalScheme } from './hooks/editApprovalSchemeQuery';
import { editCostCenter } from './hooks/editCostCenterQuery';
import { CostCenterFormModal } from '../../components/CostCenterFormModal';
import type { CostCenter } from '../../costCenter';
import {
  useApprovalSchemeByCostCenterIdQuery,
  useInvalidateApprovalSchemeByCostCenterIdQuery,
} from '../../hooks/useApprovalSchemeByCostCenterIdQuery';
import type { Member } from '../../member';

type Props = {
  costCenter: CostCenter;
  members: Member[];
  isOpen: boolean;
  onComplete(): void;
  onCancel(): void;
};

export const CostCenterEditionFormModalContainer = ({
  costCenter,
  members,
  isOpen,
  onComplete,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const isMultidimensionalApprovalWorkflowEnabled = useFeature(
    FEATURES.MULTIDIMENSIONAL_APPROVAL_WORKFLOW,
  );
  const invalidateCostCentersQuery = useInvalidateCostCentersQuery();
  const invalidateApprovalSchemeQuery =
    useInvalidateApprovalSchemeByCostCenterIdQuery();
  const companyId = useCompanyId();

  const queryStates = useQueryStates({
    states: {
      costCenters: useCostCentersQuery(),
      approvalScheme: useApprovalSchemeByCostCenterIdQuery(costCenter.id),
    },
  });

  if (!isOpen) {
    return null;
  }

  return (
    <QuerySuspense
      queryState={queryStates}
      loading={<div />}
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
    >
      {({ costCenters, approvalScheme }) => (
        <CostCenterFormModal
          costCenter={costCenter}
          members={members}
          approvalScheme={approvalScheme}
          costCenters={costCenters}
          onSubmit={async (updatedCostCenter) => {
            track(AnalyticEventName.APPROVALS_WORKFLOW_SETUP_SUBMIT);
            try {
              await editCostCenter({
                id: costCenter.id,
                payload: {
                  ...updatedCostCenter,
                  approvalRules: toApiApprovalRules(
                    updatedCostCenter.approvalRules,
                  ),
                },
                companyId,
              });
              // If the approval workflow is not enabled, we need to update the approval scheme as modal has two steps with cost center edit and approval scheme edit
              if (!isMultidimensionalApprovalWorkflowEnabled) {
                if (approvalScheme.id) {
                  await editApprovalScheme({
                    companyId,
                    payload: {
                      rules: toApiApprovalRules(
                        updatedCostCenter.approvalRules,
                      ),
                    },
                    approvalSchemeId: approvalScheme.id,
                  });
                } else {
                  // We can have a case where a given cost cost has no approval scheme defined, in this case we need to create one
                  await createApprovalScheme(
                    {
                      owner: {
                        type: 'costCenter',
                        id: costCenter.id,
                      },
                      rules: toApiApprovalRules(
                        updatedCostCenter.approvalRules,
                      ),
                    },
                    companyId,
                  );
                }
                invalidateApprovalSchemeQuery(costCenter.id);
              }
              invalidateCostCentersQuery();
              pushNotif({
                type: NotificationType.Success,
                message: t('costCenters.edition.success', {
                  name: updatedCostCenter.name,
                }),
              });
              onComplete();
            } catch {
              pushNotif({
                type: NotificationType.Danger,
                message: t('costCenters.edition.error'),
              });
            }
          }}
          onCancel={onCancel}
        />
      )}
    </QuerySuspense>
  );
};
