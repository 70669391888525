import { Button, CheckboxField, Modal } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import './AccountingBaseSwitchInformationModal.css';
import { type AccountingSoftware } from 'src/core/modules/bookkeep/integration/status';

type Props = {
  accountingIntegration:
    | AccountingSoftware
    | 'noIntegration'
    | 'switchInProgress';
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function AccountingBaseBetaWarningModal({
  accountingIntegration,
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const { t } = useTranslation('global');

  const [isConfirmInitiallyDisabled, setIsConfirmInitiallyDisabled] =
    useState(true);

  return (
    <Modal
      isOpen={isOpen}
      iconName="plug"
      iconVariant="info"
      title={t('bookkeep.integrations.settings.betaModal.title', {
        integration: accountingIntegration,
      })}
      subtitle={t('bookkeep.integrations.settings.betaModal.subtitle')}
      actions={[
        <Button
          key="back"
          variant="secondaryNeutral"
          onClick={() => onClose()}
          text={t('misc.cancel')}
        />,
        <Button
          key="confirm"
          text={t('misc.continue')}
          variant="primaryBrand"
          onClick={() => onConfirm()}
          isDisabled={isConfirmInitiallyDisabled}
        />,
      ]}
    >
      <div className="text-left text-primary">
        <h3 className="title-l">
          {t('bookkeep.integrations.settings.betaModal.expectHeader')}
        </h3>
        <ul className="mb-8 ml-24 mt-8 list-disc text-primary">
          <li>{t('bookkeep.integrations.settings.betaModal.expectPoint1')}</li>
          <li>{t('bookkeep.integrations.settings.betaModal.expectPoint2')}</li>
        </ul>

        <h3 className="title-l">
          {t('bookkeep.integrations.settings.betaModal.feedbackHeader')}
        </h3>
        <p className="mt-8 text-primary">
          {t('bookkeep.integrations.settings.betaModal.feedbackInfo')}
        </p>
        <ul className="ml-24 list-disc text-primary">
          <li>
            {t('bookkeep.integrations.settings.betaModal.feedbackPoint1')}
          </li>
          <li>
            {t('bookkeep.integrations.settings.betaModal.feedbackPoint2')}
          </li>
        </ul>

        <CheckboxField
          className="mt-16 hover:bg-[transparent]"
          isChecked={!isConfirmInitiallyDisabled}
          label={
            <Trans
              values={{ accountingIntegration }}
              i18nKey="bookkeep.integrations.settings.betaModal.checkbox"
            />
          }
          onChange={() =>
            setIsConfirmInitiallyDisabled(!isConfirmInitiallyDisabled)
          }
        />
      </div>
    </Modal>
  );
}
