import { Button, Modal } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { NotificationType, useNotifications } from 'modules/app/notifications';

import { type CardStatus } from '../../card';
import { doCardAction } from '../../utils/card';

type ReportType = 'lost' | 'damaged' | 'stolen' | 'cancelled';

type Props = {
  card: {
    id: string;
    status: CardStatus;
  };
  isModalMode?: boolean;
  withExplanation?: boolean;
  onToggleConfirm?: (confirm: boolean) => void; // call when the confirmation message is displayed or disappeared (true if displayed, false if disappeared)
  onCardReported?: () => void; // call when the card has been reported successfully
};

export const CardReport = ({
  card,
  isModalMode = false,
  withExplanation = false,
  onToggleConfirm = () => {},
  onCardReported = () => {},
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const company = useCompany();

  const [selectedReportType, setSelectedReportType] =
    useState<ReportType | null>(null);
  const [reportLoading, setReportLoading] = useState(false);

  const proceedToReport = () => {
    const allowedReportingTypes = ['lost', 'damaged', 'stolen', 'cancelled'];

    if (
      selectedReportType &&
      !reportLoading &&
      allowedReportingTypes.includes(selectedReportType)
    ) {
      setReportLoading(true);
      doCardAction(company.id, card.id, selectedReportType, null, (error) => {
        stopReportCard();

        if (!error) {
          pushNotif({
            message: t('cards.actions.successReport'),
          });
          onCardReported();
        } else {
          pushNotif({
            message: t('misc.somethingWrong'),
            type: NotificationType.Danger,
          });
        }
      });
    }
  };

  const reportCard = (reportType: ReportType) => {
    onToggleConfirm(true);
    setSelectedReportType(reportType);
    setReportLoading(false);
  };

  const stopReportCard = () => {
    onToggleConfirm(false);
    setSelectedReportType(null);
    setReportLoading(false);
  };

  const getReportConfirmTitle = (reportType: ReportType | null) => {
    if (reportType === 'lost') {
      return t('cards.reportTitles.lost');
    }
    if (reportType === 'stolen') {
      return t('cards.reportTitles.stolen');
    }
    if (reportType === 'damaged') {
      return t('cards.reportTitles.damaged');
    }
    if (reportType === 'cancelled') {
      return t('cards.reportTitles.cancelled');
    }
  };

  const getReportConfirmDescription = (reportType: ReportType | null) => {
    if (reportType && ['damaged', 'cancelled'].includes(reportType)) {
      return t('cards.reportDescriptions.warning');
    }
    return `${t('cards.reportDescriptions.explanation')} ${t(
      'cards.reportDescriptions.warning',
    )}`;
  };

  const renderReportButtons = () => {
    const reportButtons = [
      {
        text: t('cards.reportDescriptions.stolen'),
        action: () => reportCard('stolen'),
      },
      {
        text: t('cards.reportDescriptions.lost'),
        action: () => reportCard('lost'),
      },
      {
        text: t('cards.reportDescriptions.damaged'),
        action: () => reportCard('damaged'),
      },
      {
        text: t('cards.reportDescriptions.cancelled'),
        action: () => reportCard('cancelled'),
      },
    ];

    return (
      <div className="mt-8">
        {withExplanation && (
          <p className="mb-8 text-primary title-m">{t('cards.reportTitle')}</p>
        )}
        {withExplanation && (
          <p className="mb-8 text-secondary-bg-primary body-m">
            {t('cards.reportDescription')}
          </p>
        )}
        <div className="flex flex-col gap-8">
          {reportButtons.map((reportButton) => (
            <Button
              key={reportButton.text}
              fit="parent"
              variant="secondaryNeutral"
              text={reportButton.text}
              onClick={reportButton.action}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderConfirmReportPanel = () => {
    return (
      <div>
        <p className="mb-8 text-primary title-m">
          {getReportConfirmTitle(selectedReportType)}
        </p>
        <p className="text-primary body-m">
          {getReportConfirmDescription(selectedReportType)}
        </p>
        <Button
          fit="parent"
          className="mt-8"
          variant="primaryAlert"
          text={
            reportLoading ? t('cards.loadingReport') : t('cards.actions.report')
          }
          isLoading={reportLoading}
          isDisabled={reportLoading}
          onClick={proceedToReport}
        />
        <Button
          fit="parent"
          className="mt-8"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={stopReportCard}
        />
      </div>
    );
  };

  const renderConfirmReportModal = () => {
    return (
      <Modal
        isOpen
        iconName="triangle-warning"
        iconVariant="alert"
        title={getReportConfirmTitle(selectedReportType) ?? ''}
        onClose={stopReportCard}
        actions={[
          <Button
            key="cancel"
            variant="secondaryNeutral"
            text={t('misc.cancel')}
            onClick={stopReportCard}
          />,
          <Button
            key="report"
            text={
              reportLoading
                ? t('cards.loadingReport')
                : t('cards.actions.report')
            }
            variant="primaryAlert"
            isLoading={reportLoading}
            onClick={proceedToReport}
          />,
        ]}
      >
        {t('cards.reportDescriptions.warning')}
      </Modal>
    );
  };

  return (
    <>
      {(!selectedReportType || isModalMode) && renderReportButtons()}
      {selectedReportType && !isModalMode && renderConfirmReportPanel()}
      {selectedReportType && isModalMode && renderConfirmReportModal()}
    </>
  );
};
