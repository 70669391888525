import { Button } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  ctaText: string;
  ctaLink: string;
  formatedAmount: string;
  formatedAmountDescription: string;
  hasWarning: boolean;
};

export const HomepageWalletDetailPrimaryAmount = ({
  ctaText,
  ctaLink,
  formatedAmount,
  formatedAmountDescription,
  hasWarning,
}: Props) => {
  return (
    <div className="flex flex-col gap-20 rounded-4">
      <div className="flex flex-col content-stretch gap-8">
        <h2
          className={cx(
            'title-xxl',
            hasWarning ? 'text-warning-default' : 'text-primary',
          )}
        >
          {formatedAmount}
        </h2>
        <p className="text-secondary-bg-secondary body-m">
          {formatedAmountDescription}
        </p>
      </div>
      <Link
        to={ctaLink}
        onClick={() => {
          track(AnalyticEventName.HOMEPAGE_WALLET_DETAILS_BUTTON_CLICKED);
        }}
      >
        <Button
          text={ctaText}
          variant={hasWarning ? 'secondaryWarning' : 'secondaryNeutral'}
        />
      </Link>
    </div>
  );
};
