import { Panel } from '@dev-spendesk/grapes';
import React from 'react';

import { PanelTextSection } from 'common/components/Panel';
import { useTranslation } from 'common/hooks/useTranslation';
import { InvoiceLineItemsPanelSectionContainer } from 'modules/requests/invoice/creation/InvoiceLineItemsPanelSection';

import { DetailsSection } from './DetailsSection';
import { HeaderSection } from './HeaderSection';
import { ReceiptSection } from './ReceiptSection';
import { SchedulingProcessSection } from './SchedulingProcessSection';
import { TimelineSection } from './TimelineSection';
import { isInvoiceBill } from '../../../models';
import { type TransferDetails } from '../../models';

type Props = {
  transfer: TransferDetails;
  onClose: () => void;
};

export const TransferPanel = ({ transfer, onClose }: Props) => {
  const { t } = useTranslation('global');
  const { bill } = transfer;

  return (
    <Panel
      title={t('invoices.transfer.panel.header.title')}
      header={<HeaderSection transfer={transfer} />}
      onClose={onClose}
    >
      <PanelTextSection title={t('invoices.transfer.panel.description')}>
        {bill.spendingCommitment.description}
      </PanelTextSection>
      {isInvoiceBill(bill) && (
        <InvoiceLineItemsPanelSectionContainer
          requestId={transfer.request.id}
        />
      )}
      <ReceiptSection transfer={transfer} />
      <DetailsSection transfer={transfer} />
      <SchedulingProcessSection transfer={transfer} />
      <TimelineSection transfer={transfer} />
    </Panel>
  );
};
