import {
  AutocompleteMultiple,
  type AutocompleteMultipleProps,
  AutocompleteNoOptions,
  Skeleton,
} from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

export type Option = {
  key: string;
  label: string;
};

export type AutocompleteAsyncMultipleProps<T extends Option> = Omit<
  AutocompleteMultipleProps<T>,
  'onSearch' | 'renderNoOptions' | 'translations' | 'options' | 'values'
> & {
  selectedKeys: string[];
  onSearch: (search: string) => Promise<T[]>;
  onGetByKeys: (keys: string[]) => Promise<T[]>;
  renderPrefix?: (option: T) => React.ReactNode;
  maxOptions?: number;
  totalOptions?: number;
};

export const AutocompleteAsyncMultiple = <T extends Option>({
  selectedKeys,
  onSearch,
  onSelect,
  onGetByKeys,
  fit = 'parent',
  renderPrefix,
  maxOptions = 20,
  totalOptions,
  ...props
}: AutocompleteAsyncMultipleProps<T>) => {
  const { t } = useTranslation('global');

  const [search, setSearch] = useState<string>();

  const [filteredOptions, setFilteredOptions] = useState<T[]>([]);

  const [isLoading, setIsLoading] = useState(
    !!selectedKeys.length && !search && !filteredOptions.length,
  );

  const [hiddenSelectedOptions, setHiddenSelectedOptions] = useState<T[]>([]);

  const filteredOptionKeys = filteredOptions.map(({ key }) => key);

  const visibleSelectedOptions = filteredOptions.filter((value) =>
    selectedKeys.includes(value.key),
  );

  const hiddenSelectedKeys = selectedKeys.filter(
    (key) => !filteredOptionKeys.includes(key),
  );

  useEffect(() => {
    async function fetchHiddenSelectedOptions(keys: string[]) {
      const hiddenOptions = await onGetByKeys(keys);
      setHiddenSelectedOptions(hiddenOptions);
      setIsLoading(false);
    }

    if (!hiddenSelectedKeys.length) {
      setHiddenSelectedOptions([]);
      setIsLoading(false);
      return;
    }

    fetchHiddenSelectedOptions(hiddenSelectedKeys);
  }, [JSON.stringify(hiddenSelectedKeys)]);

  useEffect(() => {
    if (!selectedKeys.length && !search && !filteredOptions.length) {
      setIsLoading(true);
      // eslint-disable-next-line promise/prefer-await-to-then, promise/catch-or-return, promise/always-return
      onSearch('').then((options) => {
        setFilteredOptions(options.slice(0, maxOptions));
        setIsLoading(false);
      });
    }
  }, [selectedKeys, search, filteredOptions]);

  const values = Array.from(
    new Map<string, T>(
      [...visibleSelectedOptions, ...hiddenSelectedOptions].map((option) => [
        option.key,
        option,
      ]),
    ).values(),
  ) as T[];

  let options: T[] = [];

  const addPrefixToOption = (option: T) => ({
    ...option,
    label: (
      <div className="flex flex-row items-center gap-4">
        {renderPrefix?.(option) ?? ''} {option.label}
      </div>
    ),
  });

  if (!search && values.length) {
    options = [
      {
        key: 'selection',
        label: t('misc.selection', { count: selectedKeys.length }),
        options: values.map(addPrefixToOption),
      } as unknown as T,
    ];
  } else if (!search && filteredOptions.length) {
    if (filteredOptions.length >= maxOptions && totalOptions) {
      options = [
        {
          key: 'firstOptions',
          label: t('misc.firstOptions', {
            count: maxOptions,
            total: totalOptions,
          }),
          options: filteredOptions.map(addPrefixToOption),
        } as unknown as T,
      ];
    } else {
      options = filteredOptions.map(addPrefixToOption);
    }
  } else if (search && filteredOptions.length) {
    options = [
      {
        key: 'results',
        label: t('misc.searchResults'),
        options: filteredOptions.map(addPrefixToOption),
      } as unknown as T,
    ];
  }

  const handleSelect = (newVisibleSelectedOptions: T[]) => {
    // Keep selected keys that are out of current filtering
    const selectedOptions = Array.from(
      new Map<string, T>([
        ...newVisibleSelectedOptions.map<[string, T]>((option) => [
          option.key,
          option,
        ]),
        ...(search
          ? hiddenSelectedOptions.map<[string, T]>((option) => [
              option.key,
              option,
            ])
          : []),
      ]).values(),
    );
    onSelect(selectedOptions);
  };

  if (isLoading && selectedKeys.length) {
    return <Skeleton height="40px" width="100%" />;
  }

  return (
    <AutocompleteMultiple
      {...props}
      isLoading={isLoading}
      // @ts-expect-error AutocompleteMultiple is missing `dropdownMenuContentMaxHeight` prop definition
      dropdownMenuContentMaxHeight="250px"
      placeholder={t('misc.search')}
      fit={fit}
      values={values}
      options={options}
      translations={{
        selected: (
          <div className="flex flex-row items-center gap-4" key="selected">
            {values.map((option, index) => (
              <div key={option.label} className="flex flex-row items-center">
                {addPrefixToOption(option).label}
                {index < values.length - 1 && ', '}
              </div>
            ))}
          </div>
        ) as unknown as string, // Warning: hack to avoid TS error
      }}
      onSearch={async (value) => {
        setSearch(value);

        if (value === undefined || value.length === 0) {
          setFilteredOptions(values);

          return;
        }

        const newOptions = await onSearch(value);
        setFilteredOptions(newOptions.slice(0, maxOptions));
      }}
      onSelect={handleSelect}
      renderNoOptions={(rawValue) => (
        <AutocompleteNoOptions>
          {rawValue === '' ? (
            t('misc.startTyping')
          ) : (
            <div className="inline">
              <Trans
                i18nKey="misc.noResults"
                values={{ value: rawValue }}
                components={[<span key="noResults" />]}
              />
            </div>
          )}
        </AutocompleteNoOptions>
      )}
    />
  );
};
