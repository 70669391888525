import {
  Modal,
  SkeletonAvatar,
  SkeletonButton,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

export const AccountingIntegrationSwitchInProgressFeedback = () => {
  const { t } = useTranslation('global');

  return (
    <div className="box m-auto flex h-1/2 w-[512px] flex-col items-center">
      <SkeletonAvatar variant="circle" size="l" />
      <SkeletonText size="xl" className="my-16" />
      <SkeletonText className="text-primary" />
      <SkeletonButton className="mt-32" />
      <Modal
        isOpen
        title={t('bookkeep.integrations.settings.baseSwitch.switchingTitle')}
        subtitle={t(
          'bookkeep.integrations.settings.baseSwitch.switchingSubtitleFileBased',
        )}
        iconName="arrow-path"
        iconVariant="info"
        actions={[]}
      />
    </div>
  );
};
