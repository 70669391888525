import {
  SkeletonAvatar,
  SkeletonTable,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

import { CenteredLayout } from 'common/components/layout';

export const CostCenterPageLoader = () => {
  return (
    <CenteredLayout width="medium">
      <div className="mb-16 mt-40">
        <SkeletonText size="xl" width="150px" className="mb-8" />
        <SkeletonText />
      </div>
      <SkeletonTable
        numberOfRows={3}
        columns={[
          {
            header: <SkeletonText width="30%" />,
            cell: <SkeletonText width="30%" />,
            width: '50%',
          },
          {
            header: <SkeletonText width="30%" />,
            cell: (
              <div className="flex w-full items-center gap-8">
                <SkeletonAvatar variant="circle" />
                <SkeletonText width="30%" />
              </div>
            ),
            width: '50%',
          },
        ]}
        rowHeight="compact"
        withHeader
        withColumnSeparator
      />
    </CenteredLayout>
  );
};
