import { useQueryClient } from 'react-query';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

type RawMember = {
  id: string;
  fullname?: string;
};

type Member = {
  id: string;
  fullname: string;
};

/**
 * @public
 */
export type LoadMembersByIdsRawResponse = RawMember[];

export const useLoadMembersByIds = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  const { t } = useTranslation('global');

  return async (ids: string[]): Promise<Member[]> => {
    if (!ids.length) {
      return [];
    }

    const data = await queryClient.fetchQuery(
      ['useLoadMembersByIds', companyId, ids],
      getFetcher<RawMember[]>({
        companyId,
        getRequest: () => ({
          type: 'rest',
          target: 'companyAPI',
          endpoint: '/users',
          params: {
            ids: ids.join(','),
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return reshapeMemberValues(data, t);
  };
};

/**
 * Reshapers
 */

const reshapeMember = (
  member: RawMember,
  translator: TGlobalFunctionTyped,
): Member => ({
  id: member.id,
  fullname: member.fullname || translator('misc.unknownMember'),
});

const reshapeMemberValues = (
  edges: RawMember[],
  translator: TGlobalFunctionTyped,
): Member[] =>
  edges
    .map((node) => reshapeMember(node, translator))
    .sort((a, b) => {
      const textA = a.fullname.toUpperCase();
      const textB = b.fullname.toUpperCase();
      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });
