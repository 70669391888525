import { Button, Callout } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routeFor, routes } from 'src/core/constants/routes';

import { getDescTranslationKey } from './utils';

type Props = {
  context: 'expense-claim' | 'invoice';
};

export const MissingCompanyBankInfoWarning = ({ context }: Props) => {
  const { t } = useTranslation('global');

  const companyId = useCompanyId();
  const history = useHistory();

  return (
    <Callout
      className="mb-24"
      variant="warning"
      title={t('company.missingBankInfoTitle')}
    >
      <p>{t(getDescTranslationKey(context))}</p>
      <Button
        className="mt-16"
        variant="primaryWarning"
        text={t('company.missingBankInfoAction')}
        onClick={() => {
          history.push({
            pathname: routeFor(
              routes.COMPANY_GENERAL_SETTINGS_BANK_INFORMATION.path,
              {
                company: companyId,
              },
            ),
            hash: 'bank-info',
          });
        }}
      />
    </Callout>
  );
};
