import map from 'lodash/map';
import size from 'lodash/size';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { type PayableType } from 'modules/bookkeep/types';

import { payableTypeToTranslationKey } from '../../../../../../utils';
import {
  type CustomFieldScope,
  type CustomFieldEligibleEntity,
} from '../../../../../models';

export type FieldValue = {
  id: string;
  name: string;
  archiveDate?: string | null;
};

type RawFieldValue = {
  id: string;
  name: string;
  archiveDate?: string | null;
};

type RawCustomField = {
  id: string;
  name: string;
  optional: boolean;
  isSplittable: boolean;
  canCreateValue: boolean;
  scopes: CustomFieldScope[];
  eligibleEntities: CustomFieldEligibleEntity[];
  archiveDate: string | null;
  values: {
    totalCount: number;
    edges: {
      node: RawFieldValue;
    }[];
  };
  booleanValues: RawFieldValue[];
  __typename: 'CustomFieldList' | 'CustomFieldBoolean';
};

type RawData = {
  company: {
    teams: {
      totalCount: number;
      edges: { node: RawFieldValue }[];
    };
    members: {
      totalCount: number;
      edges: { node: RawFieldValue }[];
    };
    suppliers: {
      totalCount: number;
      edges: { node: RawFieldValue }[];
    };
    chartOfAccounts: {
      expenseAccounts: {
        totalCount: number;
        edges: {
          node: RawSupplierAccount;
        }[];
      };
    };
    customFields: RawCustomField[];
  };
  payableTypes: {
    values: { id: PayableType }[];
  };
};

const reshapeFilterValues = (
  edges: {
    node: RawFieldValue;
  }[],
): { id: string; name: string }[] => {
  return <{ id: string; name: string }[]>edges
    .map(({ node }) =>
      node.archiveDate
        ? null
        : {
            id: node.id,
            name: node.name,
          },
    )
    .filter((item) => item !== null);
};

type RawPayer = {
  id: string;
  givenName?: string;
  familyName?: string;
  email?: string;
};

const reshapePayer = (
  payer: RawPayer,
  translator: TGlobalFunctionTyped,
): FieldValue => ({
  id: payer.id,
  name:
    payer.givenName && payer.familyName
      ? `${payer.givenName} ${payer.familyName}`
      : payer.email || translator('misc.unknownMember'),
});

const reshapePayerValues = (
  edges: { node: RawPayer }[],
  translator: TGlobalFunctionTyped,
): FieldValue[] =>
  map(edges, ({ node }) => reshapePayer(node, translator)).sort((a, b) => {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    if (textA < textB) {
      return -1;
    }
    if (textA > textB) {
      return 1;
    }
    return 0;
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reshapeExpenseAccountValues = (edges: any) =>
  map(edges, ({ node }) => ({
    id: node.id,
    code: node.code,
    name: node.name,
  }));

const reshapePayableTypesValues = (
  values: { id: PayableType }[],
  translator: TGlobalFunctionTyped,
): { name: string; id: PayableType }[] =>
  values
    .map(({ id }: { id: PayableType }) => {
      const name = translator(payableTypeToTranslationKey(id));
      if (name) {
        return { id, name };
      }
      return null;
    })
    .filter((item): item is { name: string; id: PayableType } => item !== null);

const reshapeCustomFieldsType = (
  typename: string,
): 'CustomBoolean' | 'CustomList' => {
  if (typename === 'CustomFieldBoolean') {
    return 'CustomBoolean';
  }
  return 'CustomList';
};

const reshapeTeamsResultData = (
  data: RawData,
): {
  values: FieldValue[];
  valuesTotalCount: number;
} => {
  return {
    values: reshapeFilterValues(data.company.teams.edges),
    valuesTotalCount: data.company.teams.totalCount,
  };
};

const reshapePayersResultData = (
  data: RawData,
  translator: TGlobalFunctionTyped,
): {
  values: FieldValue[];
  valuesTotalCount: number;
} => ({
  values: reshapePayerValues(data.company.members.edges, translator),
  valuesTotalCount: data.company.members.totalCount,
});

const reshapeSuppliersResultData = (
  data: RawData,
): {
  values: FieldValue[];
  valuesTotalCount: number;
} => ({
  values: reshapeFilterValues(data.company.suppliers.edges),
  valuesTotalCount: data.company.suppliers.totalCount,
});

type RawSupplierAccount = {
  id: string;
  generalAccountCode: string;
};

const reshapeExpenseAccountsResultData = (
  data: RawData,
): {
  values: FieldValue[];
  valuesTotalCount: number;
} => ({
  values: reshapeExpenseAccountValues(
    data.company.chartOfAccounts.expenseAccounts.edges,
  ),
  valuesTotalCount: data.company.chartOfAccounts.expenseAccounts.totalCount,
});

const reshapeExpenseTypesResultData = (
  data: RawData,
  translator: TGlobalFunctionTyped,
): {
  values: FieldValue[];
  valuesTotalCount: number;
} => ({
  values: reshapePayableTypesValues(data.payableTypes.values, translator),
  valuesTotalCount: size(data.payableTypes.values),
});

const reshapeCustomFieldsResultData = (
  data: RawData,
): {
  id: string;
  name: string;
  type: 'CustomBoolean' | 'CustomList';
  isSplittable: boolean;
  values: { id: string; name: string }[];
  valuesTotalCount: number;
}[] =>
  data.company.customFields
    .filter(
      (customField) =>
        customField.archiveDate === null &&
        customField.eligibleEntities.includes('expense'),
    )
    .map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ({ id, name, values, booleanValues, isSplittable, __typename }: any) => {
        const type = reshapeCustomFieldsType(__typename);
        let customFieldValues = null;
        if (values && type === 'CustomList') {
          customFieldValues = reshapeFilterValues(values.edges);
        }
        if (booleanValues && type === 'CustomBoolean') {
          customFieldValues = booleanValues.map((value: { name: string }) => ({
            ...value,
            name: value.name ? 'true' : 'false',
          }));
        }

        return {
          id,
          name,
          type,
          values: customFieldValues,
          valuesTotalCount: values ? values.totalCount : null,
          isSplittable,
        };
      },
    );

export const reshapeFiltersValuesResultData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  translator: TGlobalFunctionTyped,
) => ({
  teams: reshapeTeamsResultData(data),
  payers: reshapePayersResultData(data, translator),
  suppliers: reshapeSuppliersResultData(data),
  expenseAccounts: reshapeExpenseAccountsResultData(data),
  expenseTypes: reshapeExpenseTypesResultData(data, translator),
  customFields: reshapeCustomFieldsResultData(data),
});
