import { createSelector } from '@reduxjs/toolkit';
import { type MonetaryValue } from 'ezmoney';
import isNil from 'lodash/isNil';

import * as paginatedCursor from 'common/redux/paginateCursor';
import { RequestState } from 'common/redux/requestState';
import { PaymentMethod, type PaymentMethodsByIssuer } from 'modules/company';
import { type AppState } from 'src/core/reducers';
import { getCompanyCurrency } from 'src/core/selectors/globalSelectors';

import {
  hasSomeWithActivePaymentMethod,
  isUpcomingPaymentToSchedule,
  isUrgentPaymentToSchedule,
  type PaymentToSchedule,
  sumAmountsOfPaymentMatchingCurrency,
} from '../models';
import { buildCountsAndAmountsPerCurrency } from '../utils/countsAndAmountsPerCurrency';

export const getInvoiceToReviewFilters = (state: AppState) =>
  state.invoices.filters;

export const getInvoiceReviewFiltersCount = createSelector(
  getInvoiceToReviewFilters,
  (filters) => {
    const customFields = filters.custom_fields
      ? JSON.parse(filters.custom_fields as string)
      : {};
    const customFieldsCount = Object.values(customFields).filter(
      (value) => !isNil(value),
    ).length;
    const hasSearch = (filters.search ?? '').length > 0;
    const hasSupplierReviewed = filters.supplier_reviewed !== undefined;
    return (
      customFieldsCount + (hasSearch ? 1 : 0) + (hasSupplierReviewed ? 1 : 0)
    );
  },
);

export const getSelectedPaymentsToSchedule = (state: AppState) =>
  state.invoices.paymentsScheduling.selectedPaymentsToSchedule;

export const getPaymentComplianceResults = (state: AppState) =>
  state.invoices.paymentsScheduling.paymentCompliance;

export const getInvoicesCounts = (state: AppState) =>
  state.invoices.counts.data;

export const getIsInvoicesCountsLoading = (state: AppState) =>
  state.invoices.counts.requestState === RequestState.Loading;

export const getPaymentsToSchedulePaginationLimit = (state: AppState) =>
  paginatedCursor.getLimit<PaymentToSchedule>(
    state.invoices.paymentsToSchedule,
  );

export const getPaymentsToSchedulePaginationNextCursor = (state: AppState) =>
  paginatedCursor.getNextCursor<PaymentToSchedule>(
    state.invoices.paymentsToSchedule,
  );

export const getPaymentsToScheduleRequestState = (state: AppState) =>
  paginatedCursor.getRequestState<PaymentToSchedule>(
    state.invoices.paymentsToSchedule,
  );

export const getHasMorePaymentsToSchedule = (state: AppState) =>
  paginatedCursor.getHasMoreItems<PaymentToSchedule>(
    state.invoices.paymentsToSchedule,
  );

const getPaymentsToSchedule = (state: AppState) =>
  paginatedCursor.getItems<PaymentToSchedule>(
    state.invoices.paymentsToSchedule,
  );

export const getPaymentToScheduleDetails = (state: AppState) =>
  state.invoices.paymentToScheduleDetails;

export const getUrgentPaymentsToSchedule = (state: AppState, now: Date) => {
  const paymentsToSchedule = getPaymentsToSchedule(state);
  return paymentsToSchedule.filter((paymentToSchedule) =>
    isUrgentPaymentToSchedule(paymentToSchedule, now),
  );
};

export const getUpcomingPaymentsToSchedule = (state: AppState, now: Date) => {
  const paymentsToSchedule = getPaymentsToSchedule(state);
  return paymentsToSchedule.filter((paymentToSchedule) =>
    isUpcomingPaymentToSchedule(paymentToSchedule, now),
  );
};

export const getSelectedUrgentPaymentsToSchedule = createSelector(
  getSelectedPaymentsToSchedule,
  getUrgentPaymentsToSchedule,
  (selectedPaymentsToSchedule, urgentPaymentsToSchedule) =>
    selectedPaymentsToSchedule.filter((selectedPaymentToSchedule) =>
      urgentPaymentsToSchedule.some(
        (urgentPaymentToSchedule) =>
          urgentPaymentToSchedule.id === selectedPaymentToSchedule.id,
      ),
    ),
);

export const getSelectedUpcomingPaymentsToSchedule = createSelector(
  getSelectedPaymentsToSchedule,
  getUpcomingPaymentsToSchedule,
  (selectedPaymentsToSchedule, upcomingPaymentsToSchedule) =>
    selectedPaymentsToSchedule.filter((selectedPaymentToSchedule) =>
      upcomingPaymentsToSchedule.some(
        (upcomingPaymentToSchedule) =>
          upcomingPaymentToSchedule.id === selectedPaymentToSchedule.id,
      ),
    ),
);

export const getIsSelectionEmpty = createSelector(
  getSelectedPaymentsToSchedule,
  (selectedPaymentsToSchedule) => {
    return selectedPaymentsToSchedule.length === 0;
  },
);

export const getCompanyPaymentMethods = createSelector(
  getSelectedPaymentsToSchedule,
  (selectedPaymentsToSchedule) => {
    const isXmlSepaAvailable = hasSomeWithActivePaymentMethod(
      selectedPaymentsToSchedule,
      PaymentMethod.XmlSepa,
    );

    const isWireTransferAvailable = hasSomeWithActivePaymentMethod(
      selectedPaymentsToSchedule,
      PaymentMethod.WireTransfer,
    );

    return {
      fromSpendesk: isWireTransferAvailable ? [PaymentMethod.WireTransfer] : [],
      fromBank: isXmlSepaAvailable
        ? [PaymentMethod.XmlSepa, PaymentMethod.Csv]
        : [PaymentMethod.Csv],
    } as PaymentMethodsByIssuer;
  },
);

export const getSelectedPaymentMethod = (state: AppState) =>
  state.invoices.paymentsScheduling.selectedPaymentMethod;

export const getSelectedPaymentsCountsAndAmountsPerCurrency = createSelector(
  getSelectedPaymentsToSchedule,
  buildCountsAndAmountsPerCurrency,
);

export const getSelectedPaymentsToPayFromWallet = createSelector(
  getSelectedPaymentsToSchedule,
  getSelectedPaymentMethod,
  (selectedPaymentsToSchedule, selectedPaymentMethod): PaymentToSchedule[] =>
    selectedPaymentMethod === PaymentMethod.WireTransfer
      ? selectedPaymentsToSchedule
      : [],
);

export const getTotalAmountOfSelectedPaymentsToPayFromWallet = createSelector(
  getCompanyCurrency,
  getSelectedPaymentsToPayFromWallet,
  (companyCurrency, selectedPaymentsToPayFromWallet): MonetaryValue =>
    sumAmountsOfPaymentMatchingCurrency(
      selectedPaymentsToPayFromWallet,
      companyCurrency,
    ),
);

export const getShouldDisplayMissingCompanyBankInfoWarning = createSelector(
  getPaymentsToSchedule,
  (paymentsToSchedule) =>
    paymentsToSchedule.some((paymentToSchedule) => {
      const xmlSepa = paymentToSchedule.paymentMethods[PaymentMethod.XmlSepa];
      const wireTransfer =
        paymentToSchedule.paymentMethods[PaymentMethod.WireTransfer];

      const missingBankInfoForXMLSepa =
        !xmlSepa.active &&
        (xmlSepa.reasons.company.includes('missingBic') ||
          xmlSepa.reasons.company.includes('missingIban')) &&
        !xmlSepa.reasons.company.includes('currencyNotSupported') &&
        !xmlSepa.reasons.company.includes('countryNotSupported');

      const missingBankInfoForWireTransfer = wireTransfer
        ? !wireTransfer.active &&
          wireTransfer.reasons.company.includes('missingCompanyBankInfo')
        : false;

      return missingBankInfoForWireTransfer || missingBankInfoForXMLSepa;
    }),
);

export const getPaymentDetails = (state: AppState) =>
  state.invoices.paymentDetails;

/* FIXME: This selector is used to display a detailed error message when the user cannot pay invoices by XML because suppliers have invalid bank information. 
If the call to pay invoices fails, the backend only returns the `supplierName` and `bankInfo` that caused the error. But we don't have the `supplierId` and we need this information to redirect to the right supplier on Invoices > Suppliers (better user experience). 
We did this logic on the frontend because we didn't have any backend resources available for this improvement at that time.
This selector uses an existing selector to get all the selected payments to schedule and extract a map of supplier by supplier name.
The error returned by the backend will then be enriched with the `supplierId` for the given `supplierName` using this selector (cf SchedulePaymentsInvalidSupplierBankInformationsModalContainer component). */
export const getSelectedSupplierBySupplierName = createSelector(
  getSelectedPaymentsToSchedule,
  (selectedPaymentsToSchedule) => {
    const selectedSuppliers = selectedPaymentsToSchedule.flatMap(
      (selectedPaymentToSchedule: PaymentToSchedule) => {
        return selectedPaymentToSchedule.bill.counterparty;
      },
    );
    return new Map(
      selectedSuppliers.map((supplier) => [supplier.name, supplier]),
    );
  },
);
