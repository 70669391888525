import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { GraphQLProvider } from 'src/core/modules/bookkeep';

import { BookkeepingEditPayablesAfterExportSection } from './BookkeepingEditPayablesAfterExportSection';
import { BookkeepingStartDateSection } from './BookkeepingStartDateSection';

export const BookkeepingSetupSectionContainer = () => {
  const { t } = useTranslation('global');

  return (
    <GraphQLProvider>
      <div className="flex flex-col gap-24">
        <h3 className="text-primary heading-l">
          {t('bookkeep.integrations.settings.sectionBookkeepingSetup')}
        </h3>

        <BookkeepingStartDateSection />

        <BookkeepingEditPayablesAfterExportSection />
      </div>
    </GraphQLProvider>
  );
};
