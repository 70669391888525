import { useCompany } from 'modules/app/hooks/useCompany';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { CompanyInformationFormContainer } from './components/CompanyInformationForm';

export const CompanyInformationPage = () => {
  const { t } = useTranslation('global');
  const company = useCompany();

  return (
    <section
      aria-labelledby="companyInformation"
      className="GeneralSettings__section"
    >
      <h1 id="companyInformation" className="text-completementary heading-l">
        {t('generalSettings.companyInformation.title')}
      </h1>
      <p className="mt-8 text-secondary-bg-primary body-m">
        {t('generalSettings.companyInformation.description')}
      </p>
      <CompanyInformationFormContainer
        companyInformation={{
          address: company.address,
          address2: company.address2,
          city: company.city,
          country: company.country,
          name: company.name,
          zipcode: company.zipcode,
        }}
      />
    </section>
  );
};
