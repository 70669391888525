import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useModal } from 'src/core/common/hooks/useModalGrapes';

import { DimensionApprovalFlowPanelContent } from './DimensionApprovalFlowPanelContent';
import { NodeDeleteConfirmationModal } from './NodeDeleteConfirmationModal';
import { useWorkflowContext } from '../../WorkflowContext';
import { useApprovalSchemeQuery } from '../../hooks/useApprovalScheme';
import { type CustomNode } from '../../node';
import { FloatingPanel } from '../FloatingPanel/FloatingPanel';
import { StandardApprovalFlowPanelContent } from '../StandardApprovalFlowPanelContent';

export const NodeSettingsPanel = () => {
  const { selectedNodeId, nodes, setSelectedNodeId, deleteNode } =
    useWorkflowContext();
  const currentNode = nodes.find((node) => node.id === selectedNodeId);
  const isOpen = Boolean(selectedNodeId && currentNode);
  const approvalSchemeId = currentNode ? getSchemeId(currentNode) : undefined;
  const panelType = getPanelType(currentNode?.type);

  const approvalSchemeQueryState = useApprovalSchemeQuery(approvalSchemeId);
  const approvalScheme = unwrapQuery(approvalSchemeQueryState);

  const handleClosePanel = () => {
    setSelectedNodeId(null);
  };

  const handleDeleteNode = () => {
    if (selectedNodeId) {
      deleteNode(selectedNodeId);
      hideNodeDeleteConfirmationModal();
    }
  };

  const [
    nodeDeleteConfirmationModal,
    showNodeDeleteConfirmationModal,
    hideNodeDeleteConfirmationModal,
  ] = useModal(({ isOpen: isModalOpened }) => (
    <NodeDeleteConfirmationModal
      isOpen={isModalOpened}
      onConfirm={handleDeleteNode}
      onCloseModal={() => hideNodeDeleteConfirmationModal()}
    />
  ));

  return (
    <>
      {nodeDeleteConfirmationModal}
      <FloatingPanel
        title={approvalSchemeId ? 'Edit' : 'Create'}
        isOpen={isOpen}
        onClose={handleClosePanel}
        onDelete={
          currentNode && currentNode.type === 'baseNode'
            ? showNodeDeleteConfirmationModal
            : undefined
        }
      >
        {panelType === 'standardApprovalFlow' && (
          <StandardApprovalFlowPanelContent
            approvalSchemeId={approvalSchemeId}
            approvalRules={approvalScheme?.rules ?? []}
            onClose={handleClosePanel}
          />
        )}
        {panelType === 'dimensionApprovalFlow' && (
          <DimensionApprovalFlowPanelContent
            approvalRules={approvalScheme?.rules ?? undefined}
            approvalSchemeId={approvalSchemeId}
            selectedValues={
              currentNode?.type === 'baseNode'
                ? currentNode.data.values
                : undefined
            }
            dimension={
              currentNode?.type === 'baseNode'
                ? currentNode.data.dimensionName
                : undefined
            }
            mode={approvalSchemeId ? 'edit' : 'create'} // TODO: approval scheme can still be undefined, rely on something else to determine mode
            onCancel={handleClosePanel}
          />
        )}
      </FloatingPanel>
    </>
  );
};

const getSchemeId = (node: CustomNode) => {
  if (node.type === 'standardApprovalFlowNode' || node.type === 'baseNode') {
    return node.data.schemeId;
  }
  return undefined;
};

const getPanelType = (
  nodeType: 'labelNode' | 'standardApprovalFlowNode' | 'baseNode' | undefined,
) => {
  if (nodeType === 'standardApprovalFlowNode') {
    return 'standardApprovalFlow';
  }
  return 'dimensionApprovalFlow';
};
