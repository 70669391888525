import {
  type UserTask,
  type UserCompanyTask,
} from 'modules/onboarding/setup-hub/types/userTask';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { useCompany } from '../../../app/hooks/useCompany';

export const useGetOnboardingUserTasksQuery = (): QueryState<
  UserTask[],
  UserCompanyTask[]
> => {
  const company = useCompany();

  return useQuery<UserTask[], UserCompanyTask[]>({
    key: ['onboardingUserTasks'],
    isEnabled: company.type !== 'branch_expense_entity',
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/user-tasks`,
    },
    reshapeData: (data) => data.map((task) => task.userTask),
  });
};
