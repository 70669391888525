import React from 'react';

import { useIsAnalyticalSplitAvailable } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitAvailability';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { AnalyticalSplitSettings } from './AnalyticalSplitSettings';

export const FileBasedAccountingIntegrationSettings = () => {
  const { t } = useTranslation('global');

  const isAnalyticalSplitAvailable = useIsAnalyticalSplitAvailable();

  if (!isAnalyticalSplitAvailable) {
    return null;
  }

  return (
    <>
      <div className="separator my-24" />
      <p className="mb-16 text-primary title-m">
        {t('bookkeep.integrations.settings.integrationSettings')}
      </p>
      {isAnalyticalSplitAvailable && <AnalyticalSplitSettings />}
    </>
  );
};
