import { colors, Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { SETUP_HUB_TASKS } from 'modules/onboarding/setup-hub/constants/taskSections';
import { type UserTask } from 'modules/onboarding/setup-hub/types/userTask';
import { type HubTask } from 'modules/onboarding/types/hubTask';

import styles from './HubTaskWrapper.module.css';

type Props = {
  title: string;
  description: string;
  children?: React.ReactNode;
  task: HubTask | UserTask;
  duration?: string;
  disabledMessage?: string;
  actions?: React.ReactNode[];
};

export const HubTaskWrapper = ({
  title,
  description,
  children,
  task,
  duration,
  disabledMessage,
  actions,
}: Props) => {
  const { t } = useTranslation('global');
  const isDisabled = task.status === 'disabled';
  const showCompletedTitle =
    SETUP_HUB_TASKS.includes(task.type) && task.status === 'completed';

  return (
    <div className={styles.task}>
      <h3
        className={cx('flex items-center gap-8 title-l', {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'text-secondary-bg-secondary': isDisabled || showCompletedTitle,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'line-through': showCompletedTitle,
        })}
      >
        {showCompletedTitle && (
          <Icon name="circle-check" color={colors.contentSuccessDefault} />
        )}
        {title}
      </h3>
      {isDisabled && !!disabledMessage && (
        <span className="mt-8 flex items-center title-m">
          <Icon size="m" name="lock-close" className="mr-8" />
          <span>{disabledMessage}</span>
        </span>
      )}
      {duration && (
        <span className="mt-8 flex items-center text-secondary-bg-primary body-s">
          <Icon size="m" name="clock-filled" className="mr-8" />
          {t('onboardingHub.misc.timeNeeded', { minutes: duration })}
        </span>
      )}
      <p className="mt-16 text-secondary-bg-secondary">{description}</p>
      {children}
      <div className="mt-16 flex gap-16 empty:hidden">{actions}</div>
    </div>
  );
};
