import { Button, Modal } from '@dev-spendesk/grapes';
import { differenceInMinutes } from 'date-fns';
import { useState } from 'react';

import { useHasAccountingIntegrationCapability } from 'modules/accounting-integration/apis';
import { getBrandName } from 'modules/bookkeep/integration/name';
import {
  type AccountingSoftware,
  getConnectionStatus,
  hasExternalConnection,
  type IntegrationStatusWithIntegration,
  isIntegrationsPlanEnabled,
  type NativeAccountingIntegration,
} from 'modules/bookkeep/integration/status';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import {
  LAST_ACCOUNTING_SETTINGS_REFRESH,
  LAST_SERVER_STATUS_REFRESH,
} from 'src/core/constants/storage';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import styles from './AuthSection.module.css';
import { JournalsSection } from './JournalsSection';
import { PreloginModal } from './PreloginModal';
import { MfaXeroModalStepsManager } from '../../../../../../../StrongCustomerAuthentication/components/MfaXeroModalStepsManager/MfaXeroModalStepsManager';
import { type Oauth1TokenSet } from '../../../../../accounting/graphql/hooks';
import { useDisconnectMutation } from '../../../../hooks/useDisconnectMutation';
import { useExternalAuthDisconnectMutation } from '../../../../hooks/useExternalAuthDisconnectMutation';
import { useGetOauth1ConsentUrlMutation } from '../../../../hooks/useGetOauth1ConsentUrlMutation';
import {
  useGetUserMfaStatusQuery,
  useGetUserMfaStatusFetcher,
} from '../../../../hooks/useGetUserMfaStatusQuery';
import { useNotConnectedModalsActions } from '../../../../hooks/useNotConnectedModalsActions';
import { useOauth1DisconnectMutation } from '../../../../hooks/useOauth1DisconnectMutation';
import { useRefreshAccountingSettingsMutation } from '../../../../hooks/useRefreshAccountingSettingsMutation';
import { useRefreshServerStatusFetcher } from '../../../../hooks/useRefreshServerStatusFetcher';

export const StatusActions = ({
  status,
}: {
  status: IntegrationStatusWithIntegration;
}) => {
  const connectionStatus = getConnectionStatus(status);

  switch (connectionStatus) {
    case 'notConnected':
      return <NotConnectedActions status={status} />;
    case 'connectionError':
    case 'subsidiariesError':
      return <NotConnectedActions status={status} hasConnectionError />;
    case 'connected':
      return <ConnectedActions status={status} />;
    case 'noAuthorization':
    case 'fetchingTenants':
    case 'selectingTenant':
    case 'selectingSubsidiaries':
    case 'fetchingSubsidiaries':
      return <NotConnectedActions status={status} isDisabled />;
    case 'needServerUp':
      return <NeedServerUpActions />;
    case 'noIntegration':
    case 'switchInProgress':
      return null;
    default:
      rejectUnexpectedValue('connectionStatus', connectionStatus);
  }
};

const NeedServerUpActions = () => {
  const { t } = useTranslation('global');
  const refreshServerStatus = useRefreshServerStatusFetcher();
  const [hasAskedToRefreshServerStatus, setHasAskedToRefreshServerStatus] =
    useState(!shouldAllowServerStatusRefresh());

  const handleServerStatusRefresh = async () => {
    await refreshServerStatus();
    setHasAskedToRefreshServerStatus(true);
    localStorage.setItem(LAST_SERVER_STATUS_REFRESH, new Date().toISOString());
  };

  return (
    <>
      <div className={styles.actionsContainer}>
        <Button
          text={t('bookkeep.integrations.settings.refreshServerStatus')}
          variant="secondaryNeutral"
          onClick={handleServerStatusRefresh}
          isDisabled={hasAskedToRefreshServerStatus}
        />
      </div>
    </>
  );
};

const NotConnectedActions = ({
  status,
  isDisabled = false,
  hasConnectionError = false,
}: {
  status: IntegrationStatusWithIntegration;
  isDisabled?: boolean;
  hasConnectionError?: boolean;
}) => {
  const { t } = useTranslation('global');
  const [buildOauth1ConsentUrl] = useGetOauth1ConsentUrlMutation();
  const hasXeroMfaCheckEnabled =
    useHasAccountingIntegrationCapability('mfaCheckEnabled');
  const [hasMfaUserError, setHasMfaUserError] = useState(false);
  const userMfaQueryState = useGetUserMfaStatusQuery(hasXeroMfaCheckEnabled);
  const fetchUserHasValidMfa = useGetUserMfaStatusFetcher();

  const isNetsuiteEnterpriseEnabled = useFeature(
    FEATURES.NETSUITE_ACCOUNTING_INTEGRATION,
  );
  const isNetsuiteBetaEnabled = useFeature(FEATURES.TMP_NETSUITE_BETA);

  const userHasValidMfa =
    userMfaQueryState.status === 'success' && userMfaQueryState.data;

  const allowActions = isIntegrationsPlanEnabled({
    integrationName: status.integration,
    featureFlags: {
      netsuite: isNetsuiteEnterpriseEnabled || isNetsuiteBetaEnabled,
    },
  });

  const [isPreloginModalOpen, setIsPreloginModalOpen] = useState(false);
  const [isMfaModalOpen, setIsMfaModalOpen] = useState(false);

  const [oAuth1TokenSet, setOauth1TokenSet] = useState<Oauth1TokenSet>({
    accountId: '',
    consumerId: '',
    consumerSecret: '',
  });

  const handleOAuth1 = async () => {
    try {
      const result = await buildOauth1ConsentUrl(oAuth1TokenSet);
      window.location.assign(result.url);
    } catch {
      setIsPreloginModalOpen(false);
    }
  };

  const { actions } = useNotConnectedModalsActions({
    hasExternalConnection,
    hasXeroMfaCheckEnabled,
    userHasValidMfa,
    setIsPreloginModalOpen,
    setIsMfaModalOpen,
    fetchUserHasValidMfa,
    setHasMfaUserError,
    handleOAuth1,
    status,
  });

  const modalDisplay = () => {
    if (hasExternalConnection(status.integration)) {
      if (hasXeroMfaCheckEnabled && !userHasValidMfa) {
        return (
          <MfaXeroModalStepsManager
            isOpen={isMfaModalOpen}
            hasMfaUserError={hasMfaUserError}
            onClose={() => setIsMfaModalOpen(false)}
            onSetup={actions.handleMfaSetup}
          />
        );
      }

      return (
        <PreloginModal
          integration={status.integration as NativeAccountingIntegration}
          isAccountingSwitching={false}
          isOpen={isPreloginModalOpen}
          onCancel={() => setIsPreloginModalOpen(false)}
          onConfirm={actions.handleConnect}
          tokenSet={oAuth1TokenSet}
          setTokenSet={setOauth1TokenSet}
        />
      );
    }
  };

  return (
    <>
      <Button
        text={t(
          hasConnectionError
            ? 'bookkeep.integrations.settings.reconnect'
            : 'bookkeep.integrations.settings.connect',
          {
            brandName: getBrandName(t, status.integration),
          },
        )}
        isDisabled={isDisabled || !allowActions}
        variant="primaryBrand"
        onClick={actions.handleClick}
      />
      {modalDisplay()}
    </>
  );
};

const shouldAllowAccountingSettingsRefresh = () => {
  const lastSettingRefreshDateTimeString = localStorage.getItem(
    LAST_ACCOUNTING_SETTINGS_REFRESH,
  );
  if (lastSettingRefreshDateTimeString) {
    const diffInMinutes = differenceInMinutes(
      new Date(),
      new Date(lastSettingRefreshDateTimeString),
    );
    return Math.abs(diffInMinutes) >= 3;
  }
  return true;
};

const shouldAllowServerStatusRefresh = () => {
  const lastServerStatusRefreshDateTimeString = localStorage.getItem(
    LAST_SERVER_STATUS_REFRESH,
  );

  if (lastServerStatusRefreshDateTimeString) {
    const diffInMinutes = differenceInMinutes(
      new Date(),
      new Date(lastServerStatusRefreshDateTimeString),
    );
    return Math.abs(diffInMinutes) >= 1;
  }
  return true;
};

const ConnectedActions = ({
  status,
}: {
  status: IntegrationStatusWithIntegration;
}) => {
  const { t } = useTranslation('global');
  const [oAuth2Disconnect] = useDisconnectMutation();
  const [oAuth1Disconnect] = useOauth1DisconnectMutation();
  const [externalAuthDisconnect] = useExternalAuthDisconnectMutation();
  const [refreshAccountingSettings] = useRefreshAccountingSettingsMutation();
  const [hasAskedToRefreshSettings, setHasAskedToRefreshSettings] = useState(
    !shouldAllowAccountingSettingsRefresh(),
  );
  const [isDisconnectConfirmOpen, setDisconnectConfirmOpen] = useState(false);

  const isNetsuiteEnterpriseEnabled = useFeature(
    FEATURES.NETSUITE_ACCOUNTING_INTEGRATION,
  );
  const isNetsuiteBetaEnabled = useFeature(FEATURES.TMP_NETSUITE_BETA);

  const handleDisconnect = () => {
    if (
      status.authorization.kind === 'oauth2OpenId' ||
      status.authorization.kind === 'oauth2ScopedOpenId'
    ) {
      return oAuth2Disconnect();
    }

    if (status.authorization.kind === 'oauth1') {
      return oAuth1Disconnect();
    }

    if (status.authorization.kind === 'external') {
      return externalAuthDisconnect();
    }

    setDisconnectConfirmOpen(false);
  };

  const handleSettingsRefresh = async () => {
    await refreshAccountingSettings();
    setHasAskedToRefreshSettings(true);
    localStorage.setItem(
      LAST_ACCOUNTING_SETTINGS_REFRESH,
      new Date().toISOString(),
    );
  };

  const brandName = getBrandName(t, status.integration);

  const allowActions = isIntegrationsPlanEnabled({
    integrationName: status.integration,
    featureFlags: {
      netsuite: isNetsuiteEnterpriseEnabled || isNetsuiteBetaEnabled,
    },
  });

  return (
    <>
      {status.capabilities.journals && <JournalsSection />}
      <div className={styles.actionsContainer}>
        {status.settingsRefresh.kind === 'canRefresh' ? (
          <Button
            text={
              hasAskedToRefreshSettings
                ? t('bookkeep.integrations.settings.settingsWillRefresh')
                : t('bookkeep.integrations.settings.refreshSettings')
            }
            variant="secondaryNeutral"
            onClick={handleSettingsRefresh}
            isDisabled={!allowActions || hasAskedToRefreshSettings}
          />
        ) : null}
        {status.descriptor.settingsAccountingHeaderLinks?.map((link) => (
          <Button
            key={link.linkUrl}
            variant="secondaryNeutral"
            component="a"
            text={renderLinkName(link.linkName, status.integration, t)}
            target="_blank"
            rel="noopener noreferrer"
            href={link.linkUrl}
          />
        ))}
        <div className={styles.actionsSpace} />
        <Button
          text={t('bookkeep.integrations.settings.disconnect', {
            brandName,
          })}
          variant="primaryAlert"
          onClick={() => setDisconnectConfirmOpen(true)}
        />
        <Modal
          iconName="triangle-warning"
          iconVariant="alert"
          title={t('bookkeep.integrations.settings.disconnectWarningTitle', {
            brandName,
          })}
          isOpen={isDisconnectConfirmOpen}
          onClose={() => setDisconnectConfirmOpen(false)}
          actions={[
            <Button
              key="no"
              onClick={() => setDisconnectConfirmOpen(false)}
              text={t('misc.cancel')}
              variant="secondaryNeutral"
            />,
            <Button
              key="yes"
              onClick={handleDisconnect}
              text={t('bookkeep.integrations.settings.disconnectConfirm')}
              variant="primaryAlert"
            />,
          ]}
        >
          {t('bookkeep.integrations.settings.disconnectWarningContent', {
            brandName,
          })}
        </Modal>
      </div>
    </>
  );
};

const renderLinkName = (
  linkName: string,
  integrationName: AccountingSoftware,
  t: TGlobalFunctionTyped,
): string => {
  switch (integrationName) {
    case 'Datev':
      if (linkName === 'manageTokens') {
        return t('bookkeep.integrations.datev.linkNames.manageTokens');
      }
      return '';
    case 'Netsuite':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Xero':
    case 'Sage':
    case 'Cegid':
    case 'Quickbooks':
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return '';
    default:
      rejectUnexpectedValue('integrationName', integrationName);
  }
};
