import { Icon, type IconName, colors } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { PieChart } from '../PieChart';

type Props = {
  incompletePaymentsLimit: number;
  latePaymentsCount: number;
  // The list of incomplete payments is paginated,
  // So we can't assume the count involves all late payments
  isOptimistic: boolean;
};

export const OverdueSection = ({
  incompletePaymentsLimit,
  latePaymentsCount,
  isOptimistic: isPaymentListFullyLoaded,
}: Props) => {
  const { t } = useTranslation('global');
  const isRestricted = latePaymentsCount >= incompletePaymentsLimit;

  const lateReceiptsLabel = [
    isPaymentListFullyLoaded
      ? t('homepage.overdue.title', {
          count: latePaymentsCount,
        })
      : t('homepage.overdue.titleWithOptimisticCount', {
          count: latePaymentsCount,
        }),

    t('homepage.overdue.desc', { count: incompletePaymentsLimit }),
  ].join(' ');

  const restrictedFeatures = [
    {
      key: 'plasticCard',
      iconName: 'card',
      label: t('forms.paymentType.plasticCard'),
    },
    {
      key: 'singlePurchase',
      iconName: 'card-lightning',
      label: t('forms.paymentType.singlePurchase'),
    },
  ];

  return (
    <aside className="flex w-full items-stretch gap-40">
      <div className="flex flex-1 flex-col items-center gap-16 rounded-8 bg-secondary-alert-default px-16 py-24">
        <div className="ml-24">
          <h2 className="text-alert-default title-m">{lateReceiptsLabel}</h2>
        </div>
        <PieChart
          size={88}
          strokeWidth={12}
          sectionsCount={incompletePaymentsLimit}
          activeSectionsCount={latePaymentsCount}
          color={colors.backgroundPrimaryAlertDefault}
          icon="triangle-warning"
        />
      </div>
      <div className="flex flex-1 flex-col content-stretch justify-center gap-24">
        <h3 className="title-m">
          {isRestricted
            ? t('homepage.overdue.restrictedTitle')
            : t('homepage.overdue.carefulTitle')}
        </h3>
        <ul className="flex items-center gap-24">
          {restrictedFeatures.map(({ key, iconName, label }) => (
            <li className="flex items-center gap-8" key={key}>
              <Icon
                name={iconName as IconName}
                color={colors.backgroundPrimaryAlertDefault}
              />
              <p className="body-m">{label}</p>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};
