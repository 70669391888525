import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import type { NavigationLinkProps } from 'src/core/common/components/NavigationLink';
import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import {
  GraphQLProvider,
  useIntegrationStatusQuery,
} from 'src/core/modules/bookkeep';
import {
  canAccessBankAccountsSettings,
  canAccessEmployeeAccountsSettings,
  canAccessExpenseAccountsSettings,
  canAccessSupplierAccountsSettings,
  canAccessTaxAccountsSettings,
} from 'src/core/modules/bookkeep/settings/integrations/helper/settings-access';
import { IntegrationAccountingPageSkeleton } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/components/IntegrationAccountingPageSkeleton';
import { BankAccountsSection } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/BankAccountsSection';
import { EmployeeAccountsSection } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/EmployeeAccountsSection';
import { ExpenseAccountsSection } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/ExpenseAccountsSection';
import { SupplierAccountsSection } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/SupplierAccountsSection';
import { TaxAccountsSection } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection';

const useGetLinks = () => {
  const { t } = useTranslation('global');
  const accountingIntegrationStatusQueryResult = useIntegrationStatusQuery();
  const company = useCompany();

  const status =
    accountingIntegrationStatusQueryResult.status === 'success'
      ? accountingIntegrationStatusQueryResult.data
      : null;

  const links: NavigationLinkProps[] = [];

  if (status && canAccessBankAccountsSettings(status, company.type)) {
    links.push({
      text: t('bookkeep.integrations.settings.sectionBankAccounts'),
      key: 'bank-accounts',
      path: routeFor(routes.COMPANY_ACCOUNTING_BANK_ACCOUNTS.path, {
        company: company.id,
      }),
    });
  }

  if (status && canAccessExpenseAccountsSettings(status)) {
    links.push({
      text: t('bookkeep.integrations.settings.sectionExpenseAccounts'),
      key: 'expense-accounts',
      path: routeFor(routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path, {
        company: company.id,
      }),
    });
  }

  if (status && canAccessTaxAccountsSettings(status)) {
    links.push({
      text: t('bookkeep.integrations.settings.sectionTaxAccounts'),
      key: 'tax-accounts',
      path: routeFor(routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path, {
        company: company.id,
      }),
    });
  }

  if (status && canAccessSupplierAccountsSettings(status)) {
    links.push({
      text: t('bookkeep.integrations.settings.sectionSupplierAccounts'),
      key: 'supplier-accounts',
      path: routeFor(routes.COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS.path, {
        company: company.id,
      }),
    });
  }

  if (status && canAccessEmployeeAccountsSettings(status)) {
    links.push({
      text: t('bookkeep.integrations.settings.sectionEmployeeAccounts'),
      key: 'employee-accounts',
      path: routeFor(routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path, {
        company: company.id,
      }),
    });
  }

  return links;
};

const SettingsChartOfAccountsPageContent = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const company = useCompany();

  const links: NavigationLinkProps[] = useGetLinks();

  const integrationStatusQueryState = useIntegrationStatusQuery();

  if (
    integrationStatusQueryState.status === 'loading' ||
    integrationStatusQueryState.status === 'error'
  ) {
    // There is already a loading skeleton in the withIntegrationAccounting HOC
    return (
      <div className="page__container">
        <HeaderWithNavigation links={links}>
          {t('navigation.chartOfAccounts')}
        </HeaderWithNavigation>
        <div className="grow bg-primary-default p-24">
          <div className="flex max-w-[760px] flex-col gap-48">
            <IntegrationAccountingPageSkeleton />
          </div>
        </div>
      </div>
    );
  }

  const status = integrationStatusQueryState.data;

  return (
    <div className="page__container">
      <HeaderWithNavigation links={links}>
        {t('navigation.chartOfAccounts')}
      </HeaderWithNavigation>
      <div className="grow bg-primary-default p-40">
        <Switch>
          {canAccessBankAccountsSettings(status, company.type) && (
            <Route exact path={routes.COMPANY_ACCOUNTING_BANK_ACCOUNTS.path}>
              <div className="flex max-w-[760px] flex-col">
                <BankAccountsSection
                  integrationStatus={status}
                  spendeskBankAccountCapability={
                    status.capabilities.spendeskBankAccount
                  }
                  bankFeesAccountCapability={
                    status.capabilities.bankFeesAccount
                  }
                  fundingBankAccountCapability={
                    status.capabilities.fundingBankAccount
                  }
                  settingsValidation={status.settingsValidation}
                />
              </div>
            </Route>
          )}
          {canAccessExpenseAccountsSettings(status) &&
            status.capabilities.expenseAccounts && (
              <Route
                exact
                path={routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path}
              >
                <div className="flex max-w-[760px] flex-col gap-48">
                  <ExpenseAccountsSection
                    integrationStatus={status}
                    expenseAccountsCapability={
                      status.capabilities.expenseAccounts
                    }
                  />
                </div>
              </Route>
            )}
          {canAccessTaxAccountsSettings(status) &&
            status.capabilities.taxAccounts && (
              <Route exact path={routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path}>
                <div className="flex max-w-[760px] flex-col gap-48">
                  <TaxAccountsSection
                    integrationStatus={status}
                    taxAccountsCapability={status.capabilities.taxAccounts}
                  />
                </div>
              </Route>
            )}
          {canAccessSupplierAccountsSettings(status) &&
            status.capabilities.supplierAccounts && (
              <Route
                exact
                path={routes.COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS.path}
              >
                <div className="flex max-w-[760px] flex-col gap-48">
                  <SupplierAccountsSection
                    companyId={companyId}
                    integrationStatus={status}
                    supplierAccountsCapability={
                      status.capabilities.supplierAccounts
                    }
                  />
                </div>
              </Route>
            )}

          {canAccessEmployeeAccountsSettings(status) &&
            status.capabilities.employeeAccounts && (
              <Route
                exact
                path={routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path}
              >
                <div className="flex max-w-[760px] flex-col gap-48">
                  <EmployeeAccountsSection
                    integrationStatus={status}
                    employeeAccountsCapability={
                      status.capabilities.employeeAccounts
                    }
                  />
                </div>
              </Route>
            )}

          {/* Fallback to first available page if trying to access a page that doesn't match its conditions */}
          {links.length > 0 && <Redirect to={links[0].path} />}

          {/* Fallback when trying to access the page without having an active integration */}
          {links.length === 0 && (
            <Redirect to={routes.COMPANY_ACCOUNTING.path} />
          )}
        </Switch>
      </div>
    </div>
  );
};

export const SettingsChartOfAccountsPage = () => {
  return (
    <ErrorBoundary
      context={{
        scope: 'integration-accounting-page',
        team: 'accounting-integration',
      }}
    >
      <GraphQLProvider>
        <SettingsChartOfAccountsPageContent />
      </GraphQLProvider>
    </ErrorBoundary>
  );
};
