import {
  AutocompleteNoOptions,
  Button,
  Callout,
  DropdownItem,
  FormField,
  Modal,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { SupplierAccountAdvice } from 'modules/bookkeep/components/AccountAdvice';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch/AutocompleteSearch';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';

import { getCodeWithAuxiliaryAccounts } from '../../../../../../utils/accountPayable';
import { useHasAuxiliaryAccountsEnabled } from '../../../../hooks/useHasAuxiliaryAccountsEnabled';
import {
  type SupplierAccount,
  type ExpenseAccount,
  type AccountingFormValues,
} from '../../../types';

type Props = {
  expenseAccounts: ExpenseAccount[];
  accountsPayable: SupplierAccount[];
  mustDisplayAccountsPayable: boolean;
  onAddOption(newOptionLabel: string): Promise<{
    key: string;
    label: string;
  }>;
  integrationStatus: IntegrationStatusWithIntegration;
  failedAccount: string | undefined;
} & FormikProps<AccountingFormValues>;

const getModalTitle = (
  initialValues: AccountingFormValues,
  values: AccountingFormValues,
): I18nKey => {
  if (
    initialValues.accountPayableId !== values.accountPayableId &&
    initialValues.expenseAccountId !== values.expenseAccountId
  ) {
    return 'bookkeep.accountsPayable.panel.accountingSection.modal.titleBoth';
  }

  if (initialValues.accountPayableId !== values.accountPayableId) {
    return 'bookkeep.accountsPayable.panel.accountingSection.modal.titleAccountPayable';
  }

  return 'bookkeep.accountsPayable.panel.accountingSection.modal.titleExpenseAccount';
};

export const AccountPayableSuppliersPanelAccountingForm = ({
  expenseAccounts,
  accountsPayable,
  mustDisplayAccountsPayable,
  onAddOption,
  integrationStatus,
  failedAccount,
  // Formik props
  values,
  initialValues,
  isSubmitting,
  setFieldValue,
  submitForm,
  errors,
  setErrors,
}: Props) => {
  const { t } = useTranslation('global');
  const { company: companyId } = useParams();
  const hasAutocatFeature = useFeature(FEATURES.AUTO_CAT);
  // TODO@integrations understand why we need DATEV-specific behaviour here
  const isDatev = integrationStatus.integration === 'Datev';
  const invalidSupplierAccounts =
    integrationStatus.settingsValidation.supplierAccounts
      .filter((error) => error.id && error.error === 'invalidAccount')
      .map((error) => error.id);

  const isLocalOnlyIntegration =
    integrationStatus.capabilities.supplierAccounts !==
    'pullWithDefaultAccounts';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();

  const isAccountPayableArchived = accountsPayable.some(
    (accountPayable) =>
      accountPayable.id === values.accountPayableId &&
      accountPayable.isArchived === true,
  );

  const isExpenseAccountArchived = expenseAccounts.some(
    (expenseAccount) =>
      expenseAccount.id === values.expenseAccountId &&
      expenseAccount.isArchived === true,
  );

  const reshapedAccountPayables = accountsPayable
    .filter(
      (accountPayable) =>
        !accountPayable.isArchived ||
        accountPayable.id === values.accountPayableId,
    )
    .map((accountPayable) => ({
      key: accountPayable.id,
      label: auxiliaryAccountsEnabled
        ? getCodeWithAuxiliaryAccounts(accountPayable)
        : accountPayable.generalAccountCode,
    }));
  const selectedAccountPayable = reshapedAccountPayables.find(
    (accountPayable) => accountPayable.key === values.accountPayableId,
  );
  const reshapedExpenseAccounts = expenseAccounts
    .filter(
      (expenseAccount) =>
        !expenseAccount.isArchived ||
        expenseAccount.id === values.expenseAccountId,
    )
    .map((expenseAccount) => ({
      key: expenseAccount.id,
      label: expenseAccount.name,
    }));
  const selectedExpenseAccount = reshapedExpenseAccounts.find(
    (expenseAccount) => expenseAccount.key === values.expenseAccountId,
  );

  return (
    <form className="AccountPayableSuppliersPanelAccountingForm">
      {mustDisplayAccountsPayable && (
        <FormField
          className="my-16"
          label={t(
            'bookkeep.accountsPayable.panel.accountingSection.accountPayableLabel',
          )}
          infoTipContent={t(
            'bookkeep.accountsPayable.panel.accountingSection.accountPayableInformationTooltip',
          )}
          hint={t('bookkeep.accountsPayable.panel.optionalField')}
        >
          <>
            <AutocompleteSearch
              isInvalid={
                (!!selectedAccountPayable &&
                  invalidSupplierAccounts.includes(
                    selectedAccountPayable.key,
                  )) ||
                !!errors.accountPayableId
              }
              name="accountPayableId"
              fit="parent"
              data-testid="account-payable-select"
              options={reshapedAccountPayables}
              value={selectedAccountPayable}
              onSelect={(selectedOption) => {
                if (selectedOption?.key) {
                  setErrors({});
                }
                setFieldValue('accountPayableId', selectedOption?.key);
              }}
              onInputChanged={(value) => {
                if (value && errors.accountPayableId) {
                  setErrors({});
                }
              }}
              {...(isLocalOnlyIntegration
                ? {
                    onAddOption,
                    renderAddOption: (rawValue) => (
                      <DropdownItem
                        label={
                          <Trans
                            i18nKey="bookkeep.accountsPayable.panel.accountingSection.addOption"
                            values={{ account: rawValue }}
                            components={[
                              <span
                                key="complementary"
                                className="text-primary"
                              />,
                            ]}
                          />
                        }
                      />
                    ),
                  }
                : {
                    renderNoOptions: (rawValue) => (
                      <AutocompleteNoOptions className="text-center body-m">
                        <div>
                          <Trans
                            i18nKey="misc.noOptions"
                            values={{ account: rawValue }}
                            components={[
                              <span
                                key="complementary"
                                className="text-primary"
                              />,
                            ]}
                          />
                        </div>
                      </AutocompleteNoOptions>
                    ),
                  })}
            />
            {isAccountPayableArchived && (
              <Callout
                className="mt-8"
                title={t(
                  'bookkeep.accountsPayable.panel.accountingSection.accountPayableDeleted',
                )}
                variant="warning"
              />
            )}
          </>
        </FormField>
      )}
      {errors.accountPayableId !== 'codeAlreadyExists' && (
        <SupplierAccountAdvice
          className="-mt-8"
          integrationStatus={integrationStatus}
          showError={
            (selectedAccountPayable &&
              invalidSupplierAccounts.includes(selectedAccountPayable.key)) ||
            errors.accountPayableId === 'integrationValidationFailed'
          }
        />
      )}
      {isDatev &&
        errors.accountPayableId &&
        errors.accountPayableId === 'codeAlreadyExists' && (
          <Callout
            variant="alert"
            title={t(`bookkeep.integrations.datev.duplicatedCode.title`)}
            className="-mt-8"
          >
            <>
              <p className="mb-16">
                {t('bookkeep.integrations.datev.duplicatedCode.text')} &nbsp;
                {t(
                  'bookkeep.integrations.settings.supplierAccountsTable.employeeAccount',
                )}{' '}
                - {failedAccount}
                <br />
              </p>
              <Button
                variant="secondaryNeutral"
                text="View Account"
                href={routeFor(
                  routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path,
                  {
                    company: companyId,
                  },
                )}
                rel="noopener noreferrer"
                target="_blank"
                component="a"
              />
            </>
          </Callout>
        )}
      {hasAutocatFeature && (
        <FormField
          className="my-16"
          label={t(
            'bookkeep.accountsPayable.panel.accountingSection.expenseAccountLabel',
          )}
          infoTipContent={t(
            'bookkeep.accountsPayable.panel.accountingSection.expenseAccountInformationTooltip',
          )}
          hint={t('bookkeep.accountsPayable.panel.optionalField')}
        >
          <>
            <AutocompleteSearch
              name="expenseAccountId"
              fit="parent"
              options={reshapedExpenseAccounts}
              value={selectedExpenseAccount}
              onSelect={(selectedOption) =>
                setFieldValue('expenseAccountId', selectedOption?.key)
              }
              // TODO: remove code once Grapes autocomplete is fixed
              // and we can select undefined
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                if (e.target.value === '') {
                  setFieldValue('expenseAccountId', undefined);
                }
              }}
              renderNoOptions={(rawValue) => (
                <AutocompleteNoOptions className="text-center body-m">
                  <>
                    <div>
                      <Trans
                        i18nKey="bookkeep.accountsPayable.panel.accountingSection.noOptions"
                        values={{ account: rawValue }}
                        components={[
                          <span key="complementary" className="text-primary" />,
                        ]}
                      />
                    </div>
                    <div className="mt-8">
                      <Trans i18nKey="bookkeep.accountsPayable.panel.accountingSection.noOptionsAccountingLink">
                        <Link
                          to={{
                            pathname: routeFor(routes.COMPANY_ACCOUNTING.path, {
                              company: companyId,
                            }),
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          -
                        </Link>
                      </Trans>
                    </div>
                  </>
                </AutocompleteNoOptions>
              )}
            />
            {isExpenseAccountArchived && (
              <Callout
                className="mt-8"
                title={t(
                  'bookkeep.accountsPayable.panel.accountingSection.expenseAccountDeleted',
                )}
                variant="warning"
              />
            )}
          </>
        </FormField>
      )}
      <Modal
        isOpen={isModalOpen}
        iconName="triangle-warning"
        iconVariant="warning"
        title={t(getModalTitle(initialValues, values))}
        actions={[
          <Button
            key="no"
            variant="secondaryNeutral"
            text={t('bookkeep.accountsPayable.panel.cancel')}
            onClick={() => setIsModalOpen(false)}
          />,
          <Button
            key="yes"
            variant="primaryWarning"
            text={t(
              'bookkeep.accountsPayable.panel.accountingSection.modal.validateChanges',
            )}
            onClick={async () => {
              await submitForm();
            }}
            isDisabled={isSubmitting}
          />,
        ]}
      >
        <div>
          {t(
            'bookkeep.accountsPayable.panel.accountingSection.modal.description',
          )}
        </div>
      </Modal>
    </form>
  );
};
