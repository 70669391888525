import { Icon, Tooltip, colors } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import './BudgetOverviewPlaceholder.css';

type Props = {
  budgetName: string;
};

export const BudgetOverviewPlaceholder = ({ budgetName }: Props) => {
  const { t } = useTranslation('global');
  const currency = useCompanyCurrency();
  const zero = formatMonetaryValue({ amount: 0, currency, precision: 2 });
  const ninetyNine = formatMonetaryValue({
    amount: 9_900_000,
    currency,
    precision: 2,
  });
  return (
    <div className="box">
      <div className="BudgetOverviewPlaceholder__title title-l">
        {budgetName}&nbsp;
        <Tooltip
          triggerAsChild
          maxWidth={550}
          content={t('budget.createBudgetThroughImportTooltip')}
        >
          <Icon
            name="circle-information"
            size="m"
            color={colors.contentDecorativeIcon}
          />
        </Tooltip>
      </div>
      <div className="BudgetOverviewPlaceholder__nums">
        <span className="BudgetOverviewPlaceholder__nums__zero">{zero}</span>
        <span className="BudgetOverviewPlaceholder__nums__ninetyNine">
          &nbsp;{t('budget.gauge.impactedOn')} {ninetyNine}
        </span>
      </div>
      <div className="BudgetOverviewPlaceholder__gauge" />
      <div className="BudgetOverviewPlaceholder__gaugeLegend">
        <div className="BudgetOverviewPlaceholder__gaugeLegend__content">
          <div className="BudgetOverviewPlaceholder__gaugeLegend__item BudgetOverviewPlaceholder__nums__zero">
            <label className="BudgetOverviewPlaceholder__gaugeLegend__item__label body-m">
              <div className="BudgetOverviewPlaceholder__gaugeLegend__item__circle BudgetOverviewPlaceholder__gaugeLegend__item__circle--used" />
              <div className="BudgetOverviewPlaceholder__gaugeLegend__item__label__name">
                {t('budget.gauge.legend.used')}
              </div>
            </label>
            <div className="BudgetOverviewPlaceholder__gaugeLegend__item__label__amount body-m">
              {zero}
            </div>
          </div>
          <div className="BudgetOverviewPlaceholder__gaugeLegend__item">
            <label className="BudgetOverviewPlaceholder__gaugeLegend__item__label body-m">
              <div className="BudgetOverviewPlaceholder__gaugeLegend__item__circle BudgetOverviewPlaceholder__gaugeLegend__item__circle--available" />
              <div className="BudgetOverviewPlaceholder__gaugeLegend__item__label__name">
                {t('budget.gauge.legend.available')}
              </div>
            </label>
            <div className="BudgetOverviewPlaceholder__gaugeLegend__item__label__amount body-m">
              {zero}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
