import { Button } from '@dev-spendesk/grapes';
import { type MonetaryValue, toNumber } from 'ezmoney';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { BudgetOverviewHeader } from './BudgetOverviewHeader';
import { type Breakdown } from '../../models/breakdown';
import { BudgetGauge } from '../BudgetGauge/BudgetGauge';

type Props = {
  budget: {
    id: string;
    name: string;
    amount: MonetaryValue;
    breakdown: Breakdown;
  };
  requestType?: 'po' | 'invoice';
  addon?: React.ReactNode;
  periodicity: 'monthly' | 'quarterly' | 'yearly' | undefined;
  expenseCategory:
    | {
        name: string;
        spent: MonetaryValue;
        total: MonetaryValue;
      }
    | undefined;
  date: Date;
};

export const BudgetOverviewReview = ({
  budget,
  expenseCategory,
  requestType,
  addon,
  periodicity,
  date,
}: Props) => {
  const companyId = useCompanyId();
  const history = useHistory();
  const { t } = useTranslation('global');

  return (
    <div>
      <div className="flex flex-col items-start rounded-8 border border-solid border-default bg-primary-default p-16 leading-6 shadow-xs">
        <BudgetOverviewHeader
          title={budget.name}
          withDate={periodicity === 'monthly'}
          mode="compact"
          name={budget.name}
          amount={budget.amount}
          breakdown={budget.breakdown}
          date={date}
        />
        <BudgetGauge
          budgetBreakdown={budget.breakdown}
          gaugeConfiguration={{
            tooltip: {
              mode: 'compact',
            },
          }}
        />
        {addon}
        {expenseCategory && (
          <div className="mt-16 flex w-full items-center justify-between rounded bg-secondary-default p-8">
            <span className="text-primary body-m">{expenseCategory.name}</span>
            <span className="body-m">
              {formatMonetaryValue(expenseCategory.spent)}
              {toNumber(expenseCategory.total) > 0
                ? `/ ${formatMonetaryValue(expenseCategory.total)}`
                : null}
            </span>
          </div>
        )}
      </div>
      <Button
        text={t('budget.reviewPanel.seeBudgetButton')}
        variant="secondaryNeutral"
        fit="parent"
        className="mt-8"
        onClick={() => {
          const budgetPageRoute = `${routeFor(routes.BUDGET_OVERVIEW.path, {
            id: budget.id,
            company: companyId,
          })}`;
          if (requestType === 'po') {
            track(
              AnalyticEventName.BUDGETS_UPDATE_BUDGET_START_IMPORT_BUTTON_CLICKED,
            );
          }
          history.push(budgetPageRoute);
        }}
      />
    </div>
  );
};
