import { Modal, Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useGoToCompanyWalletFunding } from 'modules/company';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';

type Props = {
  onContinue: () => void;
};

export const AchSuccessModal = ({ onContinue }: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();

  const goToCompanyWalletFunding = useGoToCompanyWalletFunding();
  const clickHandler = () => {
    onContinue();
    goToCompanyWalletFunding();
  };
  return (
    <Modal
      actions={[
        // eslint-disable-next-line react/jsx-key
        <Button
          type="button"
          variant="primaryBrand"
          text={t('ach.achSuccessCTA')}
          onClick={clickHandler}
        />,
      ]}
      iconName="building-bank"
      title={t('ach.achSuccessTitle')}
      subtitle={t(
        // eslint-disable-next-line sonarjs/no-all-duplicated-branches
        company.is_kyb_validated ? 'ach.achSuccessDesc' : 'ach.achSuccessDesc',
      )}
      iconVariant="success"
      isOpen
      onClose={onContinue}
    />
  );
};
