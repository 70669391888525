import { FormField, TextArea } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { type FormikProps } from 'formik';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import {
  CustomFieldsSelector,
  type EligibleType,
} from 'common/components/CustomFieldsSelector/CustomFieldsSelector';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import {
  partialUpdateCustomFieldAssociation,
  type CustomFieldAssociation,
} from 'modules/budgets/models/customFieldAssociation';
import { type RawCustomField } from 'modules/custom-fields/models/customField';
import { SupplierAsyncAutocomplete } from 'modules/suppliers';
import FEATURES from 'src/core/constants/features';

type Props = {
  className?: string;
  groups: { id: string; name: string }[];
  customFields: RawCustomField[];
  customFieldTypes: EligibleType[];
  teamIds: string[];
  canEditDescription: boolean;
  canEditSupplier: boolean;
  canEditTeam: boolean;
  canEditCustomFields: boolean;
} & FormikProps<{
  description: string;
  supplier: { key: string; label: string } | undefined;
  group: { key: string; label: string } | undefined;
  customFields: CustomFieldAssociation[];
}>;

export const BulkEditPayments = ({
  className,
  groups,
  customFields,
  customFieldTypes,
  teamIds,
  canEditDescription,
  canEditSupplier,
  canEditTeam,
  canEditCustomFields,
  // formikProps
  initialValues,
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation('global');

  const hasTeamsFeature = useFeature(FEATURES.TEAMS);
  const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);

  return (
    <form
      className={cx('flex grow flex-col gap-16', className)}
      id="bulk-edit-payments"
      onSubmit={handleSubmit}
    >
      <FormField label={t('payments.bulk.supplierLabel')}>
        <SupplierAsyncAutocomplete
          data-testid="panel-bullk-payment-supplier"
          isInvoicePayable={false}
          name="supplier"
          fit="parent"
          placeholder={t('payments.bulkSupplierFieldPlaceholder')}
          value={values.supplier}
          isDisabled={!canEditSupplier}
          // placeholder={t('forms.supplier.placeholder')}
          onSelect={async (newSupplier) => {
            // idempotency: do not update if supplier is the same
            if (
              (newSupplier === null && initialValues.supplier === null) ||
              !newSupplier ||
              newSupplier.key === initialValues.supplier?.key
            ) {
              return;
            }
            setFieldValue('supplier', newSupplier);
          }}
          showWarningWhenIsArchived
        />
      </FormField>
      <FormField label={t('payments.bulk.descriptionLabel')}>
        <TextArea
          name="description"
          value={values.description}
          placeholder={t('payments.bulkDescriptionFieldPlaceholder')}
          onChange={handleChange}
          isDisabled={!canEditDescription}
        />
      </FormField>
      {hasTeamsFeature && (
        <FormField label={t('forms.team.label')}>
          <AutocompleteSearch
            fit="parent"
            placeholder={t('payments.bulkCustomfieldPlaceholder')}
            options={groups.map((group) => ({
              key: group.id,
              label: group.name,
            }))}
            value={values.group}
            onSelect={(newValue) => {
              if (newValue) {
                setFieldValue('group', newValue);
              }
            }}
            renderNoOptions={(rawValue) => (
              <AutocompleteNoOptions value={rawValue} />
            )}
            isDisabled={!canEditTeam}
          />
        </FormField>
      )}
      {hasCustomFieldsFeature && (
        <CustomFieldsSelector
          fit="parent"
          teamIds={teamIds}
          types={customFieldTypes}
          customFields={customFields}
          customFieldsValues={reshapeCustomFieldsAssociationForSelector(
            values.customFields,
          )}
          onChange={(
            updatedCustomFieldAssociations: CustomFieldAssociation[],
          ): void => {
            setFieldValue(
              'customFields',
              partialUpdateCustomFieldAssociation(
                values.customFields,
                updatedCustomFieldAssociations,
              ),
            );
          }}
          isDisabled={!canEditCustomFields}
        />
      )}
    </form>
  );
};

// Reshape the values for `CustomFieldsSelector` because it accepts a specific format as `customFieldsValues` props
const reshapeCustomFieldsAssociationForSelector = (
  customFieldAssociations: CustomFieldAssociation[],
) => {
  return customFieldAssociations.map((customFieldAssociation) => {
    return {
      field: {
        id: customFieldAssociation.customFieldId,
      },
      value: {
        id: customFieldAssociation.customFieldValueId,
        value: customFieldAssociation.value,
      },
    };
  });
};
