import { DATE_FORMAT, Tag } from '@dev-spendesk/grapes';
import { toNumber } from 'ezmoney';
import React from 'react';

import {
  type LocaleFormat,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';
import { formatMoney } from 'src/core/utils/money';

import { type InvoiceDetail } from '../types';
import { getTotalAmountFromInvoices } from '../utils';

type Props = {
  overdueInvoices: InvoiceDetail[];
  localeFormat: LocaleFormat;
  t: TGlobalFunctionTyped;
  companyCurrency: string;
};

export const OutstandingInvoices = ({
  overdueInvoices,
  t,
  localeFormat,
  companyCurrency,
}: Props) => {
  const invoicesReshaped = overdueInvoices.map((invoice) => ({
    name: `Invoice ${invoice.chargebeeId}`,
    dueDate: localeFormat(
      new Date(invoice.paymentOverDueAt),
      DATE_FORMAT.SHORT,
    ),
    amount: formatMoney(
      toNumber(invoice.functionalAmountToCollect),
      invoice.functionalAmountToCollect.currency,
    ),
  }));

  const totalAmount = getTotalAmountFromInvoices(
    overdueInvoices,
    companyCurrency,
  );

  const formattedTotalAmountDue = formatMoney(
    toNumber(totalAmount),
    overdueInvoices[0].functionalAmountToCollect.currency,
  );

  return (
    <div className="flex flex-col gap-16">
      <h2 className="title-xl">
        {t('overdueInvoice.closedDoor.outstandingInvoices.title')}
      </h2>
      {invoicesReshaped.map((invoice, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="InvoiceRow flex flex-col gap-16 pb-16">
          <div className="flex flex-col gap-[6px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-[8px]">
                <span className="title-l">{invoice.name}</span>
                <Tag className="h-m title-s" variant="alert">
                  {t('overdueInvoice.closedDoor.outstandingInvoices.overdue')}
                </Tag>
              </div>
              <span className="title-l">{invoice.amount}</span>
            </div>
            <span className="text-secondary-bg-secondary body-s">
              {t('overdueInvoice.closedDoor.outstandingInvoices.dueDate', {
                dueDate: invoice.dueDate,
              })}
            </span>
          </div>
        </div>
      ))}
      <div className="flex justify-end">
        <div className="flex gap-[20px] title-l">
          <span>
            {t('overdueInvoice.closedDoor.outstandingInvoices.totalLabel')}:
          </span>
          <span>{formattedTotalAmountDue}</span>
        </div>
      </div>
    </div>
  );
};
