import { colors, Icon, Tag } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';

import styles from './OnboardingTaskAccordionHeader.module.css';
import { type OperationalOnboardingTask } from '../../../../models';

type Props = {
  taskName: string;
  task: OperationalOnboardingTask;
};

export const OnboardingTaskAccordionHeader = ({ taskName, task }: Props) => {
  const company = useCompany();
  const { is_kyb_validated: isKybValidated } = company;

  const { t } = useTranslation('global');

  const isOngoing = task.status === 'ongoing';

  if (task.status === 'disabled') {
    return (
      <div className="flex items-center gap-16">
        <div className={cx(styles.circle, styles.circlePending)} />
        <span>{taskName}</span>
        {task.disabledReason === 'kycNotApproved' && (
          <Tag variant="info">{t('homepage.welcomeDashboard.pendingKyc')}</Tag>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center gap-16">
      {isOngoing ? (
        <div className={styles.circle} />
      ) : (
        <Icon
          name="circle-check"
          size="l"
          color={
            isKybValidated
              ? colors.backgroundSecondarySuccessDefault
              : colors.contentSuccessDefault
          }
        />
      )}
      <span className={cx({ [styles.done]: !isOngoing })}>{taskName}</span>
    </div>
  );
};
