import i18next, { type i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import enAuth from 'src/auth/locales/en/auth.json';
import enCard from 'src/core/locales/en/card.json';
import enCountries from 'src/core/locales/en/countries.json';
import enErrors from 'src/core/locales/en/errors.json';
import enGlobal from 'src/core/locales/en/global.json';
import enNotif from 'src/core/locales/en/notifications.json';
import enKyc from 'src/kyc/locales/en/kyc.json';
import enOnboardingV2 from 'src/onboarding/locales/en/onboardingV2.json';

import { LANG_LOCAL_STORAGE_KEY } from '../constants/storage';

export const languages = <const>['fr', 'en', 'de', 'es'];
export type Language = (typeof languages)[number];

const namespaces = <const>[
  'auth',
  'card',
  'countries',
  'errors',
  'global',
  'notifications',
  'onboardingV2',
  'kyc',
];
export type Namespace = (typeof namespaces)[number];

export const initializeI18next = (namespacesToLoad: Namespace[]): void => {
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      returnNull: false,
      lng: process.env.NODE_ENV === 'test' ? 'cimode' : undefined,
      fallbackLng: 'en',
      compatibilityJSON: 'v3',
      supportedLngs: languages,
      backend: {
        loadPath: (
          langs: string[],
          localNamespaces: string[],
        ): string | undefined => {
          /** There is only one item in these arrays since we don't use i18n-multiload-backend-adapter
          see https://github.com/i18next/i18next-http-backend?tab=readme-ov-file#backend-options
          */
          const [lang, ns] = [langs[0], localNamespaces[0]] as const;

          const regex = new RegExp(
            `\\/static\\/lang\\/${lang}\\/${ns}\\.(.*?)\\.json`,
          );

          return Object.keys(window.assetsMetadata ?? {}).find((key) =>
            regex.test(key),
          );
        },
      },
      // For testing purpose, to avoid having to wrap every component with <Suspense>
      // Without resources, there is an async loading attempt triggered
      ...(process.env.NODE_ENV === 'test' && { resources: {} }),
      ns: namespacesToLoad,
      defaultNS: 'global',
      fallbackNS: 'global',
      detection: {
        // The app language can be forced by passing a `locale` URL query param.
        // This is used by the webview rendering from the mobile apps.
        order: ['querystring', 'localStorage', 'navigator'],
        lookupQuerystring: 'locale',
        lookupLocalStorage: LANG_LOCAL_STORAGE_KEY,
        caches: ['localStorage'],
      },
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false,
        format(value_, formatting, lang, options = {}): string {
          const value = typeof value_ !== 'undefined' ? value_ : options.value;
          switch (formatting) {
            case 'number':
              return new Intl.NumberFormat(lang, {
                ...options,
                style: 'decimal',
              }).format(value);
            case 'percent':
              return new Intl.NumberFormat(lang, {
                maximumFractionDigits: 4,
                ...options,
                style: 'percent',
              }).format(value);
            case 'currency':
              return new Intl.NumberFormat(lang, {
                ...options,
                style: 'currency',
              }).format(value);
            case 'dateShort':
              return new Intl.DateTimeFormat(lang, {
                year: '2-digit',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              }).format(value);
            default:
              return value;
          }
        },
      },
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'],
      },
      saveMissing: true,
    });
};

i18next.on('initialized', () => {
  // In dev env, append the local translations to the fetched ones
  // as some keys may not have been pushed yet
  if (process.env.NODE_ENV === 'development') {
    addLocalResourceBundle();
  }
});

function addLocalResourceBundle(): void {
  const localResource: { [ns in Namespace]: object } = {
    global: enGlobal,
    errors: enErrors,
    notifications: enNotif,
    onboardingV2: enOnboardingV2,
    kyc: enKyc,
    auth: enAuth,
    card: enCard,
    countries: enCountries,
  };

  for (const [ns, res] of Object.entries(localResource)) {
    i18next.addResourceBundle('en', ns, res, true, false);
  }
}

/**
 * Goal of window.toggleI18n: Show the translation keys inside the application instead of the translated strings (to help translators fix wordings).
 *
 * How to use:
 * - call `window.toggleI18n()` in the dev console
 * - click on the toggle button through the chrome plugin
 *
 */

const originalTFunction = i18next.t;

// Replace with '::' to match the lokalise format
const displayKeysTFunction = (key: string) => key.replaceAll('.', '::');

let isI18nDisabled = false;
window.toggleI18n = () => {
  if (isI18nDisabled) {
    i18next.t = originalTFunction;
    isI18nDisabled = false;
  } else {
    // @ts-expect-error: Not an helpful comment
    i18next.t = displayKeysTFunction;
    isI18nDisabled = true;
  }
  i18next.changeLanguage(i18next.language);
};

// Handle the event coming from the "Toggle translation keys" button in the extension
window.addEventListener(
  'message',
  (event) => {
    if (event.source !== window) {
      return;
    }
    if (event.data.type && event.data.type === 'translation:toggle') {
      window.toggleI18n();
    }
  },
  false,
);

/**
 * This function returns the language currently in use by the i18next instance.
 * @see https://github.com/i18next/react-i18next/issues/475#issuecomment-404543041
 */
export const getActiveLanguage = (instance: i18n): Language => {
  // FIXME: resolvedLanguage is typed as string
  return (instance.resolvedLanguage as Language) || 'en';
};

export const getRegionalizedLocale = (instance: i18n): string =>
  qualifyLocaleWithRegion(getActiveLanguage(instance));

export const qualifyLocaleWithRegion = (locale: string): string => {
  const browserLocale = navigator.language.toLowerCase();

  switch (locale) {
    case 'en':
      return browserLocale === 'en' || browserLocale === 'en-us'
        ? 'en-us'
        : 'en-gb';
    default:
      return locale;
  }
};
/**
 * This function update the current language respecting the i18next detection order mechanism (query string still have the priority)
 * @see https://www.i18next.com/overview/api#changelanguage
 */
export const updateCurrentLanguage = (language: Language): void => {
  localStorage.setItem(LANG_LOCAL_STORAGE_KEY, language);
  i18next.changeLanguage();
};

export { default } from 'i18next';
