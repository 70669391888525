import { Callout, Icon, Tag } from '@dev-spendesk/grapes';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import type { AppDispatch } from 'modules/app/redux/store';
import { updateInvoicePattern } from 'modules/bookkeep/settings/export-legacy/redux/actions';
import { useCustomFieldsQuery } from 'modules/custom-fields/hooks/useCustomFieldsQuery';
import FEATURES from 'src/core/constants/features';

type Props = {
  showSuccess?: boolean;
};

const isFileNamePatternValid = (pattern: string[]) => {
  return pattern.some((item) => item === 'payableId' || item === 'receiptId');
};

export const InvoicePatternBuilder = ({ showSuccess }: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const { pushNotif } = useNotifications();
  const dispatch = useDispatch<AppDispatch>();

  const [hasError, setHasError] = useState(false);
  const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);

  const [choices, setChoices] = useState<Record<string, string>>({
    date: t('accounting.filenameChoices.payableDate'),
    supplier: t('accounting.filenameChoices.supplier'),
    amount: t('accounting.filenameChoices.amount'),
    payer: t('accounting.filenameChoices.payer'),
    team: t('accounting.filenameChoices.team'),
    payableId: t('accounting.filenameChoices.payableReference'),
    receiptId: t('accounting.filenameChoices.receiptId'),
  });
  const customFieldsQuery = useCustomFieldsQuery({
    isEnabled: hasCustomFieldsFeature,
  });

  useEffect(() => {
    if (hasCustomFieldsFeature && customFieldsQuery.status === 'success') {
      const cfChoices = customFieldsQuery.data.reduce(
        (all, cf) => ({
          ...all,
          [`cf:${cf.id}`]: cf.name,
        }),
        {},
      );

      // Append custom fields to available invoice patterns
      setChoices({ ...choices, ...cfChoices });
    }
  }, [customFieldsQuery.status]);

  const fileNamePreview = useMemo(() => {
    const staticSuffix = '1'; // invoice ID
    const samples: { [key: string]: string } = {
      date: '2017_02_14',
      supplier: 'apple',
      amount: '123',
      payer: 'laura_ford',
      team: 'marketing',
      payableId: '2c794c7b',
      receiptId: '000001',
    };

    if (hasCustomFieldsFeature && customFieldsQuery.status === 'success') {
      // Append custom fields preview values
      customFieldsQuery.data.forEach((cf) => {
        let value;
        if (cf.type === 'boolean') {
          value = t('misc.yes');
        } else {
          const [head] = cf.values;
          value = head ? head.name : 'foo';
        }
        samples[`cf:${cf.id}`] = value;
      });
    }

    return `${company.invoices?.map((tag) => samples[tag]).join('-')}${
      company.invoices.length === 0 ? '' : '-'
    }${staticSuffix}.pdf`;
  }, [customFieldsQuery.status, company.invoices]);

  const displayAvailableChoices = () => {
    const available = Object.keys(choices).filter(
      (key) => !company.invoices.includes(key),
    );

    return available.map((item: keyof typeof choices) => (
      <Tag key={item} variant="info">
        <span
          role="button"
          onClick={() => changePatternItem(item, true)}
          className="flex cursor-pointer items-center gap-8"
        >
          {choices[item]}
        </span>
      </Tag>
    ));
  };

  const displayChosenPattern = () => {
    const readOnlyDefaults: Record<string, string> = {
      invoiceId: t('accounting.filenameChoices.invoiceId'),
    };
    const readOnlyChoices = Object.keys(readOnlyDefaults);
    const allChoices = (company.invoices ?? []).concat(readOnlyChoices);

    return allChoices.map((key: string, index: number) => {
      const isReadOnly = index >= allChoices.length - readOnlyChoices.length;
      return (
        <Tag key={key} variant={isReadOnly ? 'neutral' : 'info'}>
          {isReadOnly ? (
            <span>{readOnlyDefaults[key]}</span>
          ) : (
            <span
              role="button"
              onClick={() => changePatternItem(key, false)}
              className="flex cursor-pointer items-center gap-8"
            >
              {choices[key]}
              <Icon name="cross" size="s" />
            </span>
          )}
        </Tag>
      );
    });
  };

  const changePatternItem = async (item: string, chosen = true) => {
    const newSelected = chosen
      ? (company.invoices ?? []).concat(item) // add item to pattern
      : company.invoices.filter((tag) => tag !== item); // remove item to pattern

    if (!isFileNamePatternValid(newSelected)) {
      setHasError(true);
      return;
    }
    setHasError(false);
    const invoicePattern = newSelected.filter((tag) => !!tag);
    // Save pattern remotely & locally
    try {
      await dispatch(updateInvoicePattern(invoicePattern));
      if (showSuccess) {
        pushNotif({
          message: t('accounting.successUpdateInvoicePattern'),
        });
      }
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('misc.somethingWrong'),
      });
    }
  };

  return (
    <div>
      <div className="mb-16 flex flex-wrap gap-16">
        {displayAvailableChoices()}
      </div>
      <div className="flex min-h-48 flex-wrap gap-16 rounded-4 border border-solid border-default p-16">
        {displayChosenPattern()}
      </div>
      <div className="mt-16 flex flex-col rounded-4 bg-secondary-default p-16 text-primary">
        <span className="uppercase body-s">{t('exports.preview')}</span>
        <span className="mt-8 font-semibold">{fileNamePreview}</span>
      </div>
      {hasError && (
        <Callout
          title={t('exports.receiptsFileNamesError')}
          variant="alert"
          className="mt-16"
        />
      )}
    </div>
  );
};
