import {
  FETCH_INVOICES_COUNTS_FAILURE,
  FETCH_INVOICES_COUNTS_REQUEST,
  FETCH_INVOICES_COUNTS_SUCCESS,
  FETCH_PAYMENT_DETAILS_SUCCESS,
  FETCH_PAYMENT_TO_SCHEDULE_DETAILS_SUCCESS,
  FETCH_PAYMENTS_TO_SCHEDULE_FAILURE,
  FETCH_PAYMENTS_TO_SCHEDULE_REQUEST,
  FETCH_PAYMENTS_TO_SCHEDULE_SUCCESS,
  type FetchInvoicesCountsFailure,
  type FetchInvoicesCountsRequest,
  type FetchInvoicesCountsSuccess,
  type FetchPaymentDetailsSuccess,
  type FetchPaymentsToScheduleFailure,
  type FetchPaymentsToScheduleRequest,
  type FetchPaymentsToScheduleSuccess,
  type FetchPaymentToScheduleDetailsSuccess,
  RESET_PAYMENT_DETAILS,
  RESET_PAYMENT_TO_SCHEDULE_DETAILS,
  RESET_PAYMENTS_TO_SCHEDULE,
  type ResetPaymentDetails,
  type ResetPaymentsToSchedule,
  type ResetPaymentToScheduleDetails,
  SCHEDULE_PAYMENTS_FAILURE,
  SCHEDULE_PAYMENTS_REQUEST,
  SCHEDULE_PAYMENTS_SUCCESS,
  type SchedulePaymentsFailure,
  type SchedulePaymentsRequest,
  type SchedulePaymentsSuccess,
  SET_INVOICE_REVIEW_FILTERS,
  SET_SELECTED_PAYMENT_METHOD,
  SET_SELECTED_PAYMENTS_TO_SCHEDULE,
  type SetInvoicesReviewFilters,
  type SetSelectedPaymentMethod,
  type SetSelectedPaymentsToSchedule,
  type SetPaymentCompliance,
  SET_PAYMENT_COMPLIANCE,
  RESET_PAYMENT_COMPLIANCE,
  type ResetPaymentCompliance,
} from './actionTypes';

export const fetchInvoicesCountsRequest = (): FetchInvoicesCountsRequest => ({
  type: FETCH_INVOICES_COUNTS_REQUEST,
});

export const fetchInvoicesCountsSuccess = (
  payload: FetchInvoicesCountsSuccess['payload'],
): FetchInvoicesCountsSuccess => ({
  type: FETCH_INVOICES_COUNTS_SUCCESS,
  payload,
});

export const fetchInvoicesCountsFailure = (): FetchInvoicesCountsFailure => ({
  type: FETCH_INVOICES_COUNTS_FAILURE,
});

export const fetchPaymentsToScheduleRequest =
  (): FetchPaymentsToScheduleRequest => ({
    type: FETCH_PAYMENTS_TO_SCHEDULE_REQUEST,
  });

export const fetchPaymentsToScheduleSuccess = (
  payload: FetchPaymentsToScheduleSuccess['payload'],
): FetchPaymentsToScheduleSuccess => ({
  type: FETCH_PAYMENTS_TO_SCHEDULE_SUCCESS,
  payload,
});

export const fetchPaymentsToScheduleFailure =
  (): FetchPaymentsToScheduleFailure => ({
    type: FETCH_PAYMENTS_TO_SCHEDULE_FAILURE,
  });

export const resetPaymentsToSchedule = (): ResetPaymentsToSchedule => ({
  type: RESET_PAYMENTS_TO_SCHEDULE,
});

export const setPaymentCompliance = (
  payload: SetPaymentCompliance['payload'],
): SetPaymentCompliance => ({
  type: SET_PAYMENT_COMPLIANCE,
  payload,
});

export const resetPaymentCompliance = (): ResetPaymentCompliance => ({
  type: RESET_PAYMENT_COMPLIANCE,
});

export const fetchPaymentToScheduleDetailsSuccess = (
  payload: FetchPaymentToScheduleDetailsSuccess['payload'],
): FetchPaymentToScheduleDetailsSuccess => ({
  type: FETCH_PAYMENT_TO_SCHEDULE_DETAILS_SUCCESS,
  payload,
});

export const resetPaymentToScheduleDetails =
  (): ResetPaymentToScheduleDetails => ({
    type: RESET_PAYMENT_TO_SCHEDULE_DETAILS,
  });

export const setSelectedPaymentsToSchedule = (
  payload: SetSelectedPaymentsToSchedule['payload'],
): SetSelectedPaymentsToSchedule => ({
  type: SET_SELECTED_PAYMENTS_TO_SCHEDULE,
  payload,
});

export const schedulePaymentsRequest = (): SchedulePaymentsRequest => ({
  type: SCHEDULE_PAYMENTS_REQUEST,
});

export const schedulePaymentsSuccess = (): SchedulePaymentsSuccess => ({
  type: SCHEDULE_PAYMENTS_SUCCESS,
});

export const schedulePaymentsFailure = (): SchedulePaymentsFailure => ({
  type: SCHEDULE_PAYMENTS_FAILURE,
});

export const setSelectedPaymentMethod = (
  payload: SetSelectedPaymentMethod['payload'],
): SetSelectedPaymentMethod => ({
  type: SET_SELECTED_PAYMENT_METHOD,
  payload,
});

export const fetchPaymentDetailsSuccess = (
  payload: FetchPaymentDetailsSuccess['payload'],
): FetchPaymentDetailsSuccess => ({
  type: FETCH_PAYMENT_DETAILS_SUCCESS,
  payload,
});

export const resetPaymentDetails = (): ResetPaymentDetails => ({
  type: RESET_PAYMENT_DETAILS,
});

export const setInvoiceReviewsFilters = (
  payload: SetInvoicesReviewFilters['payload'],
): SetInvoicesReviewFilters => ({
  type: SET_INVOICE_REVIEW_FILTERS,
  payload,
});
