import shortid from 'shortid';

import { logger } from 'src/utils/datadog-log-wrapper';

/**
 * @public
 */
export type FieldKind = 'id' | 'string' | 'amount' | 'date';

/**
 * @public
 */
export type Field = {
  key: string;
  label: string;
  options?: { key: string; label: string }[];
  kind: FieldKind;
};

/**
 * @public
 */
export type SavedFilter = {
  id: string;
  name: string;
  filter: Filter;
};

/**
 * @public
 */
export type Filter = { operator: 'and' | 'or'; subfilters: SubfilterGroup[] };

/**
 * @public
 */
export type SubfilterGroup = {
  operator: 'and' | 'or';
  subfilters: SubfilterValue[];
};

/**
 * @public
 */
export type SubfilterValue = {
  field: string;
  operator: string;
  value: [string, ...string[]] | 'all' | null | undefined | unknown;
};

/**
 * @public
 */
export function getNonEmptyArraySubfilterValue(
  subfilterValue: string[] | 'all' | null | undefined | unknown,
): [string, ...string[]] | undefined {
  if (!Array.isArray(subfilterValue)) {
    return undefined;
  }
  return subfilterValue.length
    ? (subfilterValue as [string, ...string[]])
    : undefined;
}

/**
 * Types with optional field values, used for builder that need to keep track of selected filters
 */

/**
 * @public
 */
export type FilterWithOptionalField = Omit<Filter, 'subfilters'> & {
  subfilters: SubfilterGroupWithOptionalField[];
};

/**
 * @public
 */
export type SubfilterGroupWithOptionalField = Omit<
  SubfilterGroup,
  'subfilters'
> & {
  subfilters: SubfilterValueWithOptionalField[];
};

/**
 * @public
 */
export type SubfilterValueWithOptionalField = Omit<
  SubfilterValue,
  'field' | 'operator'
> & {
  field: string | undefined;
  operator: string | undefined;
};

/**
 * @public
 */
export type SelectedFilter = Omit<SavedFilter, 'filter' | 'id'> & {
  id: string | undefined;
  filter: FilterWithOptionalField;
};

/**
 * Types with `id` fields, used for builder that need to keep track of keys in array of components
 */

/**
 * @public
 */
export type FilterWithId = Omit<Filter, 'subfilters'> & {
  subfilters: SubfilterGroupWithId[];
};

/**
 * @public
 */
export type SubfilterGroupWithId = Omit<SubfilterGroup, 'subfilters'> & {
  id: string;
  subfilters: SubfilterValueWithId[];
};

/**
 * @public
 */
export type SubfilterValueWithId = Omit<
  SubfilterValue,
  'field' | 'operator'
> & {
  id: string;
  field: string | undefined;
  operator: string | undefined;
};

/**
 * @public
 */
export type SelectedFilterWithId = Omit<SelectedFilter, 'filter'> & {
  filter: FilterWithId;
};

/**
 * Helpers
 */

export const toFilterWithId = (
  filter: SelectedFilter | SavedFilter,
): SelectedFilterWithId => {
  const subfilterGroups = filter.filter.subfilters.length
    ? filter.filter.subfilters
    : [emptySubfilter()];

  return {
    ...filter,
    filter: {
      ...filter.filter,
      subfilters: subfilterGroups.map((subfilterGroup) => {
        const subfilters = subfilterGroup.subfilters.length
          ? subfilterGroup.subfilters
          : [emptySubfilterValue()];

        return {
          ...subfilterGroup,
          id: shortid.generate(),
          subfilters: subfilters.map((subfilterValue) => ({
            ...subfilterValue,
            id: shortid.generate(),
          })),
        };
      }),
    },
  };
};

export const emptySubfilterValue = (): SubfilterValueWithId => ({
  id: shortid.generate(),
  field: undefined,
  operator: undefined,
  value: undefined,
});

export const emptySubfilter = (): SubfilterGroupWithId => ({
  id: shortid.generate(),
  operator: 'and',
  subfilters: [emptySubfilterValue()],
});

export const emptyFilter = (): FilterWithId => ({
  operator: 'and',
  subfilters: [emptySubfilter()],
});

export const emptySelectedFilter = (): SelectedFilterWithId => ({
  id: undefined,
  name: '',
  filter: emptyFilter(),
});

const filterUrlParameterSeparator = '|';

export const toFilterUrlParameter = (
  { filter }: SelectedFilter,
  fields: Field[],
): string => {
  const fieldDefinitions = fields.flatMap((option) =>
    'options' in option
      ? option.options?.map((o) => ({ ...o, kind: option.kind }))
      : option,
  ) as Field[];

  const transformValue = ({
    field,
    value,
  }: SubfilterValueWithOptionalField) => {
    let fieldDefinition = fieldDefinitions.find(({ key }) => key === field);

    if (!fieldDefinition) {
      fieldDefinition = field
        ? fieldDefinitions.find(({ key }) => key.startsWith(field))
        : undefined;
    }

    switch (fieldDefinition?.kind) {
      case 'id': {
        return value;
      }
      case 'string': {
        return value;
      }
      case 'date': {
        const toUtcString = (date: Date) => {
          const utc = new Date(
            date.getTime() - date.getTimezoneOffset() * 60_000,
          );
          return utc.toISOString().slice(0, 10);
        };
        if (value instanceof Date) {
          return toUtcString(value);
        }
        if (
          value &&
          typeof value === 'object' &&
          'from' in value &&
          'to' in value &&
          value.from instanceof Date &&
          value.to instanceof Date
        ) {
          return `from:${toUtcString(value.from)};to:${toUtcString(value.to)}`;
        }
        if (
          value &&
          typeof value === 'object' &&
          'timeUnit' in value &&
          'amount' in value
        ) {
          return `${value.amount}${value.timeUnit}`;
        }
        return value;
      }
      case 'amount': {
        if (
          value &&
          typeof value === 'object' &&
          'from' in value &&
          'to' in value
        ) {
          return `from:${value.from};to:${value.to}`;
        }
        return value;
      }
      default:
        return '';
    }
  };

  return `${filter.operator}[${filter.subfilters.map(
    (subfilterGroup) =>
      `${subfilterGroup.operator}[${subfilterGroup.subfilters.map(
        (subfilterValue) =>
          `[${[
            subfilterValue.field,
            subfilterValue.operator,
            ...(subfilterValue.value !== undefined &&
            subfilterValue.value !== null
              ? [transformValue(subfilterValue)]
              : []),
          ].join(filterUrlParameterSeparator)}]`,
      )}]`,
  )}]`;
};

export const fromFilterUrlParameter = (
  filterString: string,
  fields: Field[],
): SelectedFilter | undefined => {
  try {
    const [, filterOperator, rawSubfilters] =
      filterString.match(/([and|or]+)\[(.+)\]/) ?? [];

    const fieldDefinitions = fields.flatMap((option) =>
      'options' in option
        ? option.options?.map((o) => ({ ...o, kind: option.kind }))
        : option,
    ) as Field[];

    const subfilters: SubfilterGroupWithOptionalField[] = Array.from(
      rawSubfilters
        ?.matchAll(/([and|or]+)\[(\[.+?\])\]/g)
        .map(([, filterGoupOperator, filterGoup]) => {
          return {
            operator: filterGoupOperator as SubfilterGroup['operator'],
            subfilters: Array.from(
              // eslint-disable-next-line sonarjs/cognitive-complexity
              filterGoup
                .matchAll(/\[(.+?)\]/g)
                // eslint-disable-next-line sonarjs/cognitive-complexity
                .flatMap(([, subfilterValue]) => {
                  const [field, operator, ...rawValue] = subfilterValue.split(
                    filterUrlParameterSeparator,
                  );

                  const fieldDefinition = fieldDefinitions.find(
                    ({ key }) => key === field,
                  );

                  let value: unknown = rawValue
                    ? rawValue.join(filterUrlParameterSeparator)
                    : null;

                  switch (fieldDefinition?.kind) {
                    case 'id': {
                      if (typeof value === 'string') {
                        if (value?.match(',')) {
                          value = value?.split(',');
                        }
                        break;
                      }
                      value = null;
                      break;
                    }
                    case 'string': {
                      break;
                    }
                    case 'date': {
                      if (typeof value === 'string') {
                        const rangeMatch = value?.match(
                          new RegExp('from:(.+);to:(.+)'),
                        );

                        const relativeDateMatch = value?.match(
                          new RegExp('(.+)(days|months|years)'),
                        );

                        if (rangeMatch) {
                          const [, rawFrom, rawTo] = rangeMatch;
                          let from: Date | null = new Date(rawFrom);
                          from = Number.isNaN(from) ? null : from;
                          let to: Date | null = new Date(rawTo);
                          to = Number.isNaN(to) ? null : to;
                          if (from !== null && to !== null) {
                            value = { from, to };
                            break;
                          }
                        } else if (relativeDateMatch) {
                          const [, rawAmount, timeUnit] = relativeDateMatch;
                          const amount: number | null =
                            Number.parseInt(rawAmount);

                          if (amount !== null && timeUnit) {
                            value = { amount, timeUnit };
                            break;
                          }
                        } else {
                          value = new Date(value);
                          break;
                        }
                      }
                      value = null;
                      break;
                    }
                    case 'amount': {
                      if (typeof value === 'string') {
                        const rangeMatch = value?.match(
                          new RegExp('from:(.+);to:(.+)'),
                        );
                        if (rangeMatch) {
                          const [, rawFrom, rawTo] = rangeMatch;
                          let from: number | null = Number.parseFloat(rawFrom);
                          from = Number.isNaN(from) ? null : from;
                          let to: number | null = Number.parseFloat(rawTo);
                          to = Number.isNaN(to) ? null : to;
                          if (from !== null && to !== null) {
                            value = { from, to };
                            break;
                          }
                        } else if (!Number.isNaN(Number.parseFloat(value))) {
                          value = Number.parseFloat(value);
                          break;
                        }
                      }
                      value = null;
                      break;
                    }
                    default:
                      return [];
                  }

                  return [
                    {
                      field: field || undefined,
                      operator: operator || undefined,
                      value,
                    },
                  ];
                }),
            ),
          };
        }) ?? [],
    );

    if (!subfilters.length) {
      return undefined;
    }

    return {
      id: undefined,
      name: '',
      filter: {
        operator: filterOperator as FilterWithOptionalField['operator'],
        subfilters,
      },
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error parsing filter string', error);
    logger.error('Error parsing filter string', error);
    return undefined;
  }
};

export const withoutOptionalFields = (
  filterWithOptionalField: FilterWithOptionalField,
): Filter => {
  return {
    operator: filterWithOptionalField.operator,
    subfilters: filterWithOptionalField.subfilters.map((subfilterGroup) => ({
      operator: subfilterGroup.operator,
      subfilters: subfilterGroup.subfilters.filter(
        (subfilterValue) => !!subfilterValue.field && !!subfilterValue.operator,
      ) as SubfilterValue[],
    })),
  };
};
