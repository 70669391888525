import { SkeletonAvatar, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import styles from './EmptyStateGraph.module.css';

export const EmptyStateGraph = () => {
  return (
    <div className={styles.container}>
      <div className="mb-40 flex justify-between">
        <div className="flex">
          <SkeletonAvatar variant="square" />
          <div className="flex flex-col">
            <SkeletonText className="m-0 mb-4 ml-8" width="86px" size="m" />
            <SkeletonText className="m-0 ml-8" width="143px" size="m" />
          </div>
        </div>
        <div className="flex items-end">
          <SkeletonText className="m-0 ml-8" width="96px" size="l" />
        </div>
      </div>
      <SkeletonText className="mb-64 ml-48" width="30px" size="m" />
      <SkeletonText className="mb-64 ml-48" width="30px" size="m" />
      <SkeletonText className="mb-64 ml-48" width="30px" size="m" />
      <SkeletonText className="mb-64 ml-48" width="30px" size="m" />
    </div>
  );
};
