import { Button, Callout, SkeletonText } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense/';
import { useTranslation } from 'common/hooks/useTranslation';

import styles from './ExportValidationSection.module.css';
import {
  type ExportValidationStatus,
  type ExportValidationStatusItem,
  useGetExportValidationStatusQuery,
} from '../../../../hooks/useGetExportValidationStatusQuery';
import { useValidateExportMutation } from '../../../../hooks/useValidateExportMutation';

const prepareItemText = (
  statusItem: ExportValidationStatusItem,
  index: number,
) => {
  const { kind, error } = statusItem;
  return `${index + 1}. ${kind}: ${error}`;
};

export const ExportValidationSection = () => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const exportValidationStatusQueryState = useGetExportValidationStatusQuery();
  const [validateExport] = useValidateExportMutation();
  const { t } = useTranslation('global');

  const validateExportClick = async () => {
    setButtonDisabled(true);
    await validateExport();
  };

  return (
    <>
      <QuerySuspense
        queryState={exportValidationStatusQueryState}
        loading={<SkeletonText />}
        fallback={(error) => (
          <QueryError queryError={error} componentType="Callout" />
        )}
      >
        {(exportValidationStatus: ExportValidationStatus) => (
          <>
            {!!exportValidationStatus.length && (
              <>
                <Callout
                  title={t(
                    'bookkeep.integrations.settings.exportValidation.title',
                  )}
                  variant="warning"
                  className={styles.exportValidationCallout}
                >
                  <>
                    {exportValidationStatus.map((status, index) => (
                      <>
                        <span>{prepareItemText(status, index)}</span>
                        {index !== exportValidationStatus.length - 1 ? (
                          <br />
                        ) : null}
                      </>
                    ))}
                  </>
                </Callout>
                <div className={styles.exportValidationErrorActions}>
                  <div className={styles.filler} />
                  <Button
                    isDisabled={buttonDisabled}
                    onClick={validateExportClick}
                    text={t(
                      'bookkeep.integrations.settings.exportValidation.triggerButtonText',
                    )}
                    variant="secondaryNeutral"
                  />
                </div>
                <div className="separator" />
              </>
            )}
          </>
        )}
      </QuerySuspense>
    </>
  );
};
