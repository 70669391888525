import React from 'react';

import { PbrRuleTimeline } from 'modules/company/members/components/ControlRules/components/PbrRuleTimeline';

type Props = {
  incompletePaymentsLimit: number;
  completionDeadline: number;
};

export const SpenderRulesModal = ({
  incompletePaymentsLimit,
  completionDeadline,
}: Props) => {
  return (
    <PbrRuleTimeline
      className="mb-40"
      completionDeadline={completionDeadline}
      incompletePaymentsLimit={incompletePaymentsLimit}
      mode="requester"
    />
  );
};
