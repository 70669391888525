import {
  IconButton,
  colors,
  type IconName,
  HighlightIcon,
} from '@dev-spendesk/grapes';
import { Handle, Position, type NodeProps } from '@xyflow/react';
import classNames from 'classnames';
import { type ReactElement } from 'react';

import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useCostCentersQuery } from 'src/core/modules/budgets/apis';

import { useWorkflowContext } from '../../WorkflowContext';
import { type Dimension } from '../../approvalWorkflow';

export type BaseNodeData = {
  schemeId?: string;
  dimensionName?: Dimension;
  values?: string[];
};

type Props = NodeProps & {
  data: BaseNodeData;
};

const handleStyle = {
  background: 'transparent',
  border: 'none',
  width: 0,
  height: 0,
};

export const BaseNode = ({ id, data }: Props) => {
  const { createAndConnectNode, setSelectedNodeId, selectedNodeId } =
    useWorkflowContext();
  const isSelected = selectedNodeId === id;

  return (
    <div className="flex items-center">
      <div
        className={classNames(
          'w-[320px] rounded border border-solid p-16',
          isSelected
            ? 'border-transparent border-solid bg-secondary-brand-default'
            : 'border-default bg-primary-default',
        )}
      >
        <div className="flex items-center justify-between">
          {data.values === undefined && data.dimensionName === undefined ? (
            <div className="flex h-[60px] items-center">
              <button
                type="button"
                className={classNames(
                  'h-full w-[240px] cursor-pointer border-none text-left text-disabled title-m',
                  isSelected
                    ? 'bg-secondary-brand-default'
                    : 'bg-background-primary',
                )}
                onClick={() => setSelectedNodeId(id)}
              >
                Select value
              </button>
            </div>
          ) : (
            <div>
              <div className="flex items-center gap-8">
                {data.dimensionName && (
                  <HighlightIcon
                    variant={
                      data.dimensionName === 'costCenter' ? 'peach' : 'purple'
                    }
                    name={getDimensionIconName(data.dimensionName)}
                    size="s"
                  />
                )}
                <div className="text-primary body-s">
                  {data.dimensionName && getDimensionHeader(data.dimensionName)}
                </div>
              </div>
              <div className="mt-16 max-w-[240px] text-primary title-m">
                {data.dimensionName && data.values && (
                  <EllipsisTooltip
                    text={getValuesText(data.dimensionName, data.values)}
                  />
                )}
              </div>
            </div>
          )}
          <div className="flex">
            <IconButton
              aria-label=""
              iconColor={colors.contentPrimary}
              onClick={() => {
                setSelectedNodeId(id);
              }}
              iconName="pen"
            />
          </div>
        </div>
      </div>
      <IconButton
        variant="tertiaryNeutral"
        onClick={() => {
          createAndConnectNode({
            sourceNodeId: id,
          });
        }}
        iconName="circle-plus"
        aria-label="plus"
      />
      <Handle
        style={handleStyle}
        type="target"
        id="leftHandle"
        position={Position.Left}
      />
      <Handle
        style={handleStyle}
        type="source"
        id="rightHandle"
        position={Position.Right}
      />
    </div>
  );
};

const getValuesText = (
  dimension: Dimension,
  values: string[] | null, // TODO: null is for the 'Others' option
): ReactElement | string | null => {
  if (dimension === 'costCenter') {
    return <CostCentersText values={values} />;
  }
  if (dimension === 'spendType') {
    return values ? values?.join(', ') : '';
  }
  return null;
};

const CostCentersText = ({ values }: { values: string[] | null }) => {
  const costCentersQueryState = useCostCentersQuery();
  return (
    <QuerySuspense
      queryState={costCentersQueryState}
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
      loading={null}
    >
      {(costCenters) => {
        if (values === null) {
          return <>Others</>;
        }
        return (
          <>
            {values
              ?.map((value) => {
                const costCenter = costCenters.find((cc) => cc.id === value);
                return costCenter?.name || '';
              })
              .join(', ')}
          </>
        );
      }}
    </QuerySuspense>
  );
};

const getDimensionHeader = (dimension: Dimension) => {
  switch (dimension) {
    case 'costCenter':
      return 'Cost center is';
    case 'expenseCategory':
      return 'Expense category is';
    case 'spendType':
      return 'Spend type is';
    default:
      return '';
  }
};

const getDimensionIconName = (dimension: Dimension): IconName => {
  switch (dimension) {
    case 'costCenter':
      return 'building-bank';
    case 'expenseCategory':
      return 'receipt-checked';
    case 'spendType':
      return 'dollar-circle-arrow';
    default:
      return 'dollar-circle-arrow';
  }
};
