import gql from 'graphql-tag';
import { useQueryClient } from 'react-query';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

type RawSupplier = {
  id: string;
  name?: string;
  thumbnailUrl?: string;
};

type Supplier = {
  id: string;
  name: string;
  thumbnailUrl?: string;
};

export type LoadSuppliersByIdsRawResponse = {
  company: {
    suppliers: {
      edges: {
        node: RawSupplier;
      }[];
    };
  };
};

export type LoadSuppliersByIdsVariables = {
  ids: string[];
};

const query = gql`
  query SuppliersByIds($companyId: ID!, $ids: [ID!]!) {
    company(id: $companyId) {
      suppliers(filter: { ids: $ids }) {
        totalCount
        edges {
          node {
            id
            name
            thumbnailUrl
          }
        }
      }
    }
  }
`;

export const useLoadSuppliersByIds = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  const { t } = useTranslation('global');

  return async (ids: string[]): Promise<Supplier[]> => {
    if (!ids.length) {
      return [];
    }

    const data = await queryClient.fetchQuery(
      ['useLoadSuppliersByIds', companyId, ids],
      getFetcher<LoadSuppliersByIdsRawResponse>({
        companyId,
        getRequest: () => ({
          type: 'graphQL',
          target: 'v2',
          query,
          variables: { ids },
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return reshapeSupplierValues(data.company.suppliers.edges, t);
  };
};

/**
 * Reshapers
 */

const reshapeSupplier = (
  supplier: RawSupplier,
  translator: TGlobalFunctionTyped,
): Supplier => ({
  id: supplier.id,
  name: supplier.name || translator('misc.unknownSupplier'),
  thumbnailUrl: supplier.thumbnailUrl,
});

const reshapeSupplierValues = (
  edges: { node: RawSupplier }[],
  translator: TGlobalFunctionTyped,
): Supplier[] =>
  edges
    .map(({ node }) => reshapeSupplier(node, translator))
    .sort((a, b) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });
