import { type ThunkDispatch } from '@reduxjs/toolkit';
import { type MonetaryValue } from 'ezmoney';
import omit from 'lodash/omit';

import { addNotification, NotificationType } from 'modules/app/notifications';
import { type ExportMethod, type PaymentMethod } from 'modules/company';
import { companyAPI } from 'src/core/api/axios';
import i18n from 'src/core/config/i18n';
import { routeFor, routes } from 'src/core/constants/routes';
import history from 'src/core/history';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import { downloadFromUrl } from 'src/core/utils/fileDownloader';

import { type InvoicesActions } from './actionTypes';
import * as invoicesActions from './actions';
import { selectors as filtersSelectors } from './schedulePaymentsFiltersSlice';
import * as invoicesSelectors from './selectors';
import {
  type InvoicesCounts,
  isUrgentPaymentToSchedule,
  type PaymentsBatchPaymentDetails,
  type PaymentToSchedule,
  type PaymentToScheduleDetails,
  type SchedulingProcess,
  type InvoicesPayFiltersValues,
  type InvoicesFiltersApi,
  type InvoicesPayFiltersCustomFieldValue,
} from '../models';
import { type Payment } from '../payment/models';

export const fetchInvoicesCounts =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, InvoicesActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    // This isn't proper request cancellation but a simple way to avoid serial
    // fetches of the same data.
    if (invoicesSelectors.getIsInvoicesCountsLoading(state)) {
      return;
    }

    dispatch(invoicesActions.fetchInvoicesCountsRequest());

    let counts;
    try {
      const res = await companyAPI.get<InvoicesCounts>(
        '/transfer-scheduling/counts?type=invoice',
        {
          companyId,
        },
      );
      counts = res.data;
    } catch (error) {
      dispatch(invoicesActions.fetchInvoicesCountsFailure());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('invoices.fetchCountsError'),
        }),
      );

      throw error;
    }

    dispatch(invoicesActions.fetchInvoicesCountsSuccess(counts));
  };

function reshapeFetchPaymentsToScheduleQueryParams(
  filters: InvoicesPayFiltersValues,
): InvoicesFiltersApi {
  const customFieldsValues = Object.values(filters.customFields)
    .flat()
    .filter(Boolean) as InvoicesPayFiltersCustomFieldValue[];
  return {
    ...omit(filters, 'teams'),
    groups: filters.teams,
    customFieldsValues,
    from: filters.period?.from,
    to: filters.period?.to,
  };
}
let lastFetchPaymentsToScheduleRequestDate = null;
export const fetchPaymentsToSchedule =
  (now: Date) =>
  async (
    dispatch: ThunkDispatch<AppState, null, InvoicesActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const filters = reshapeFetchPaymentsToScheduleQueryParams(
      filtersSelectors.selectFilters(getState()),
    );

    lastFetchPaymentsToScheduleRequestDate = now;
    const state = getState();
    const companyId = getCompanyId(state);
    const startCursor =
      invoicesSelectors.getPaymentsToSchedulePaginationNextCursor(state);
    let cursor = startCursor;
    const limit = invoicesSelectors.getPaymentsToSchedulePaginationLimit(state);

    dispatch(invoicesActions.fetchPaymentsToScheduleRequest());

    let items: PaymentToSchedule[] = [];
    try {
      let hasNoMoreUrgentPaymentsOrReachedEnd = false;

      // We keep on fetching pages until we have retrieved all the urgent payments
      while (
        !hasNoMoreUrgentPaymentsOrReachedEnd &&
        lastFetchPaymentsToScheduleRequestDate === now
      ) {
        const { data } = await companyAPI.get<{
          paymentsToSchedule: PaymentToSchedule[];
          nextCursor: string | number | null;
        }>('/transfer-scheduling/payments_to_schedule', {
          params: {
            type: 'invoice',
            limit,
            cursor,
            groups: filters.groups?.map((group) => group.key),
            suppliers: filters.suppliers?.map((supplier) => supplier.key),
            costCenters: filters.costCenters?.map(
              (costCenter) => costCenter.key,
            ),
            customFieldsValues: filters.customFieldsValues?.map(
              (customFieldValues) => customFieldValues.key,
            ),
            from: filters.from,
            to: filters.to,
          },
          companyId,
        });
        const hasNotOnlyUrgentPaymentsToSchedule = data.paymentsToSchedule.some(
          (paymentToSchedule) =>
            !isUrgentPaymentToSchedule(paymentToSchedule, now),
        );
        // We consider we reached the end of the pagination once the nextCursor
        // becomes a falsy value again.
        const hasReachedEnd = !data.nextCursor;

        items = items.concat(data.paymentsToSchedule);
        cursor = data.nextCursor;
        hasNoMoreUrgentPaymentsOrReachedEnd =
          hasReachedEnd || hasNotOnlyUrgentPaymentsToSchedule;
      }
    } catch (error) {
      dispatch(invoicesActions.fetchPaymentsToScheduleFailure());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('invoices.pay.fetchPaymentsToScheduleError'),
        }),
      );

      throw error;
    }

    // prevents race condition
    if (lastFetchPaymentsToScheduleRequestDate !== now) {
      return;
    }

    dispatch(
      invoicesActions.fetchPaymentsToScheduleSuccess({
        items,
        nextCursor: cursor,
      }),
    );

    const isFirstLoad = !startCursor;

    if (isFirstLoad) {
      // TODO: ideally we shouldn't wait for the list fetching to resolve before
      // fetching the counters. We will be able to leverage `Promise.allSettled()`
      // for that soon.
      dispatch(fetchInvoicesCounts());
    }
  };

export const setSelectedPaymentsToSchedule =
  (
    selectedPaymentsToSchedule: PaymentToSchedule[],
    { isPaymentListFullySelected }: { isPaymentListFullySelected: boolean },
  ) =>
  (dispatch: ThunkDispatch<AppState, null, InvoicesActions>): void => {
    dispatch(
      invoicesActions.setSelectedPaymentsToSchedule({
        paymentsToSchedule: selectedPaymentsToSchedule,
        isPaymentListFullySelected,
      }),
    );
  };

export const fetchPaymentToScheduleDetails =
  (id: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, InvoicesActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    let paymentToScheduleDetails: PaymentToScheduleDetails;
    try {
      const res = await companyAPI.get<PaymentToScheduleDetails>(
        `/transfer-scheduling/payments_to_schedule/${id}`,
        {
          companyId,
        },
      );
      paymentToScheduleDetails = res.data;
    } catch (error) {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('invoices.pay.fetchPaymentToScheduleDetailsError'),
        }),
      );

      throw error;
    }

    dispatch(
      invoicesActions.fetchPaymentToScheduleDetailsSuccess({
        paymentToScheduleDetails: {
          ...paymentToScheduleDetails,
          bill: {
            ...paymentToScheduleDetails.bill,
            requestId: paymentToScheduleDetails.request.id,
          },
        },
      }),
    );
  };

export type UpdatePaymentScheduleOptions = {
  billId: string;
  payments: { date: string; amount: MonetaryValue }[];
};

enum UpdatePaymentScheduleFailureReason {
  MissingExecutedOrScheduledPayments = 'missingExecutedOrScheduledPayments',
  TooMuchCommitted = 'tooMuchCommitted',
  NotEnoughCommitted = 'notEnoughCommitted',
}

type UpdatePaymentScheduleResponse =
  | UpdatePaymentScheduleResponseSuccess
  | UpdatePaymentScheduleResponseError;

type UpdatePaymentScheduleResponseError = {
  outcome: 'notEdited';
  reason: UpdatePaymentScheduleFailureReason;
};

type UpdatePaymentScheduleResponseSuccess = {
  outcome: 'edited';
  paymentsToSchedule: SchedulingProcess['paymentsToSchedule'];
};

class UnknownUpdatePaymentScheduleFailureReasonError extends Error {
  constructor(reason: never) {
    super(`Unknown UpdatePaymentScheduleFailureReason: ${reason}`);
  }
}

const isUpdatePaymentScheduleResponseError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
): data is UpdatePaymentScheduleResponseError => {
  return data?.outcome === 'notEdited';
};

const isUpdatePaymentScheduleResponseSuccess = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
): data is UpdatePaymentScheduleResponseSuccess => {
  return data?.outcome === 'edited';
};

export const updatePaymentSchedule =
  ({ billId, payments }: UpdatePaymentScheduleOptions) =>
  async (
    dispatch: ThunkDispatch<AppState, null, InvoicesActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    let nextPaymentsToSchedule;
    try {
      const res = await companyAPI.put<UpdatePaymentScheduleResponse>(
        `/transfer-scheduling/bills/${billId}/payment-schedule`,
        payments,
        {
          companyId,
        },
      );

      // NOTE: `outcome` is always supposed to be `edited` here because the server
      // will send a non-success HTTP status code if it's not, causing the API
      // client to reject the promise above.
      // But TS can't know that so we have to check for the `outcome` here and
      // handle the case where it's not what we expect, even if that shouldn't
      // happen.
      if (!isUpdatePaymentScheduleResponseSuccess(res.data)) {
        const error = new Error('Unknown outcome');
        // @ts-expect-error: Not an helpful comment
        error.response = res;
        throw error;
      }

      nextPaymentsToSchedule = res.data.paymentsToSchedule;
    } catch (error) {
      const data = error?.response?.data;
      let message;

      if (isUpdatePaymentScheduleResponseError(data)) {
        switch (data.reason) {
          case UpdatePaymentScheduleFailureReason.MissingExecutedOrScheduledPayments:
            message = i18n.t(
              'invoices.schedulingProcessForm.updatePaymentScheduleError',
            );
            break;
          case UpdatePaymentScheduleFailureReason.NotEnoughCommitted:
            message = i18n.t(
              'invoices.schedulingProcessForm.updatePaymentScheduleError',
              {
                context: UpdatePaymentScheduleFailureReason.NotEnoughCommitted,
              },
            );
            break;
          case UpdatePaymentScheduleFailureReason.TooMuchCommitted:
            message = i18n.t(
              'invoices.schedulingProcessForm.updatePaymentScheduleError',
              {
                context: UpdatePaymentScheduleFailureReason.TooMuchCommitted,
              },
            );
            break;
          default:
            throw new UnknownUpdatePaymentScheduleFailureReasonError(
              data.reason,
            );
        }

        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message,
          }),
        );
        return;
      }

      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t(
            'invoices.schedulingProcessForm.updatePaymentScheduleError',
          ),
        }),
      );

      throw error;
    }

    dispatch(
      addNotification({
        type: NotificationType.Success,
        message: i18n.t(
          'invoices.schedulingProcessForm.updatePaymentScheduleSuccess',
        ),
      }),
    );

    dispatch(invoicesActions.resetPaymentsToSchedule());
    dispatch(fetchPaymentsToSchedule(new Date()));

    history.push(
      routeFor(routes.INVOICES_PAY.path, {
        company: companyId,
        paymentToScheduleId: nextPaymentsToSchedule[0].id,
      }),
    );
  };

export type SchedulePaymentsOptions = {
  paymentMethod: PaymentMethod;
  payments: Payment[];
};

type SchedulePaymentsApiResponse = {
  outcome: 'scheduledAndExecuted' | 'scheduled' | 'notScheduled';
  fileLocation?: string;
  reasons?: {
    paymentToScheduleId: string;
    reason: string;
  }[];
};

export const schedulePayments =
  ({ paymentMethod, payments }: SchedulePaymentsOptions) =>
  async (
    dispatch: ThunkDispatch<AppState, null, InvoicesActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(invoicesActions.schedulePaymentsRequest());

    try {
      const res = await companyAPI.post<SchedulePaymentsApiResponse>(
        '/transfer-scheduling/schedule_payments',
        {
          paymentMethod,
          pay: payments,
        },
        { companyId },
      );

      if (
        res.data.outcome === 'scheduledAndExecuted' &&
        res.data.fileLocation
      ) {
        downloadFromUrl(res.data.fileLocation);
      } else if (res.data.outcome !== 'scheduled') {
        throw new Error('Unhandled payments scheduling.');
      }
    } catch (error) {
      dispatch(invoicesActions.schedulePaymentsFailure());
      throw error;
    }

    dispatch(invoicesActions.schedulePaymentsSuccess());
  };

export const resetPayments =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, InvoicesActions>,
  ): Promise<void> => {
    dispatch(invoicesActions.resetPaymentsToSchedule());
    await dispatch(fetchPaymentsToSchedule(new Date()));
  };

type ExportPaymentsBatchApiResponse =
  | {
      outcome: 'readyToDownload';
      fileLocation: string;
    }
  | { outcome: 'notExported'; reason: string };

export const exportPaymentsBatch =
  (batchId: string, exportMethod: ExportMethod) =>
  async (
    dispatch: ThunkDispatch<AppState, null, InvoicesActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    try {
      const { data } = await companyAPI.post<ExportPaymentsBatchApiResponse>(
        `/transfer-scheduling/batches/${batchId}/export`,
        { paymentMethod: exportMethod },
        { companyId },
      );

      if (data.outcome === 'readyToDownload') {
        downloadFromUrl(data.fileLocation, 'batch.csv');
      } else {
        throw new Error(`Unknown outcome: ${data.outcome}`);
      }
    } catch (error) {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('invoices.pay.exportPaymentsBatchError'),
        }),
      );
      throw error;
    }
  };

export const downloadInvoicesToPay =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, InvoicesActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    try {
      const { data } = await companyAPI.get<ExportPaymentsBatchApiResponse>(
        '/transfer-scheduling/download-bills',
        { companyId },
      );

      if (data.outcome === 'readyToDownload') {
        downloadFromUrl(data.fileLocation, 'Invoice-export.zip');
      } else {
        throw new Error(`Unknown outcome: ${data.outcome}`);
      }
    } catch (error) {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('invoices.pay.downloadInvoicesToPayError'),
        }),
      );
      throw error;
    }
  };

export const fetchPaymentDetails =
  (id: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, InvoicesActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    let paymentDetails: PaymentsBatchPaymentDetails;
    try {
      const res = await companyAPI.get<PaymentsBatchPaymentDetails>(
        `/transfer-scheduling/payments/${id}`,
        {
          companyId,
        },
      );
      paymentDetails = res.data;
    } catch (error) {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('invoices.pay.fetchPaymentDetailsError'),
        }),
      );

      throw error;
    }

    dispatch(
      invoicesActions.fetchPaymentDetailsSuccess({
        paymentDetails,
      }),
    );
  };
