import {
  Button,
  CheckboxInput,
  DropdownItem,
  DropdownMenu,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { useTranslation } from 'common/hooks/useTranslation';

import { PayableType } from '../../../../types';
import { payableTypeToTranslationKey } from '../../../../utils';

import './PayableTypeFilter.css';

type Props = {
  selectedPayableTypes: string[] | undefined;
  setPayableTypes(payableTypes: string[] | undefined): void;
};

export const PayableTypeFilter = ({
  selectedPayableTypes,
  setPayableTypes,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <DropdownMenu
      keepOpenOnSelect
      placement="bottom-start"
      renderButton={(getToggleButtonProps, isOpen) => (
        <Button
          {...getToggleButtonProps()}
          className={classNames('PayableTypeFilter__button', {
            'PayableTypeFilter__button--open': isOpen,
            'PayableTypeFilter__button--active': selectedPayableTypes?.length,
          })}
          text={t('payables.filters.payableType')}
          variant="secondaryNeutral"
        />
      )}
      options={
        [
          PayableType.cardPurchase,
          PayableType.cardReversal,
          PayableType.invoicePurchase,
          PayableType.creditNote,
          PayableType.claimedBill,
          PayableType.mileageExpense,
          PayableType.perDiemExpense,
        ]
          .map((key) => ({ key, label: t(payableTypeToTranslationKey(key)) }))
          .filter(Boolean) as { label: string; key: string }[]
      }
      onSelect={(option) => {
        if (selectedPayableTypes?.includes(option.key)) {
          const filteredPayableTypes = selectedPayableTypes?.filter(
            (s) => s !== option.key,
          );
          setPayableTypes(
            filteredPayableTypes.length ? filteredPayableTypes : undefined,
          );
        } else {
          setPayableTypes([...(selectedPayableTypes ?? []), option.key]);
        }
      }}
      renderOption={(option) => {
        const isSelected = selectedPayableTypes
          ? selectedPayableTypes.includes(option.key)
          : false;
        return (
          <DropdownItem
            key={option.key}
            label={option.label}
            isSelected={isSelected}
            isHighlighted={isSelected}
            // TODO: we shouldn't use the checkbox component only for visual clue (but a simple icon)
            prefix={
              <CheckboxInput isChecked={isSelected} onChange={() => {}} />
            }
          />
        );
      }}
    />
  );
};
