import { FormField, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type BankInformations } from '../WireTransferBankInfoModal';

export const WireTransferBankInfoForm = ({
  values,
  touched,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
}: FormikProps<BankInformations>) => {
  const { t } = useTranslation('global');

  return (
    <form onSubmit={handleSubmit}>
      <FormField
        className="mb-16"
        label={t('bankInfoForm.accountHolderName')}
        alertMessage={
          errors.accountHolderName && touched.accountHolderName
            ? errors.accountHolderName
            : undefined
        }
      >
        <TextInput
          id="accountHolderName"
          name="accountHolderName"
          value={values.accountHolderName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="HELLO LLC"
        />
      </FormField>
      <FormField
        className="mb-16"
        label={t('bankInfoForm.accountNumber')}
        alertMessage={
          errors.accountNumber && touched.accountNumber
            ? errors.accountNumber
            : undefined
        }
      >
        <TextInput
          id="accountNumber"
          name="accountNumber"
          value={values.accountNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="658877003"
        />
      </FormField>
      <FormField
        className="mb-16"
        label={t('bankInfoForm.routingNumber')}
        alertMessage={
          errors.routingNumber && touched.routingNumber
            ? errors.routingNumber
            : undefined
        }
      >
        <TextInput
          id="routingNumber"
          name="routingNumber"
          value={values.routingNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="021000322"
          maxLength={9}
        />
      </FormField>
    </form>
  );
};
