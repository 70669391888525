import { Tag, type TagProps } from '@dev-spendesk/grapes';
import React, { type ReactNode } from 'react';

type Props = {
  leftImage?: ReactNode;
  rightImage?: ReactNode;
  firstLineLeft: string;
  firstLineRight: string;
  firstLineRightSecondary?: string;
  secondLineLeftItems: (ReactNode | string)[];
  secondLineRight: string;
  tag?: TagProps;
};

export const PayableListItem = ({
  leftImage,
  rightImage,
  firstLineLeft,
  firstLineRight,
  firstLineRightSecondary,
  secondLineLeftItems,
  secondLineRight,
  tag,
}: Props) => {
  return (
    <div className="flex w-full py-8">
      {leftImage && <div className="mr-16 flex items-center">{leftImage}</div>}
      <div
        className="TableRow__content w-full flex-1" /* Do not delete TableRow__content, used in PreparePayables */
      >
        <div className="flex justify-between gap-x-16 body-m">
          <div className="max-w-[640px] truncate">{firstLineLeft}</div>
          <div className="flex">
            {firstLineRightSecondary && (
              <div className="text-secondary-bg-primary body-m after:mx-4 after:content-['·']">
                {firstLineRightSecondary}
              </div>
            )}
            <div className="whitespace-nowrap title-m">{firstLineRight}</div>
          </div>
        </div>
        <div className="relative flex justify-between text-secondary-bg-primary body-s before:inline-block before:content-['']">
          <div className="absolute left-0 flex w-[76%]">
            {secondLineLeftItems.filter(Boolean).map((item, index: number) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={String(index)}
                className="min-w-24 truncate [&:not(:first-child)]:before:px-8 [&:not(:first-child)]:before:content-['·']"
                data-testid="payable-list-item-separator"
              >
                {item}
              </span>
            ))}
          </div>
          <span>{secondLineRight}</span>
        </div>
        {tag && <Tag className="TableRow__tag" {...tag} />}
      </div>
      {rightImage && (
        <div className="ml-16 flex items-center">{rightImage}</div>
      )}
    </div>
  );
};
