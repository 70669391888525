import { Toast } from '@dev-spendesk/grapes';
import React, { useEffect } from 'react';

import { type Notification as TypedNotification } from '../notification';

type Props = Readonly<{
  notification: TypedNotification;
  removeNotification: (notificationId: number) => void;
}>;

export const Notification = ({ notification, removeNotification }: Props) => {
  const handleClose = () => {
    if (notification.onClose) {
      notification.onClose();
    }
    removeNotification(notification.id);
  };

  const canExpire = notification.canExpire;

  useEffect(() => {
    let timeoutId: undefined | NodeJS.Timeout;
    if (canExpire) {
      timeoutId = setTimeout(() => {
        if (canExpire) {
          handleClose();
        }
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [canExpire]);

  const handleActionClicked = () => {
    if (notification.action) {
      notification.action.onClick(notification);
    }
    removeNotification(notification.id);
  };

  const variant = notification.type === 'success' ? 'success' : 'alert';
  const hasAction = notification.action !== undefined;

  return (
    <Toast
      variant={variant}
      onClose={handleClose}
      title={notification.message}
      action={
        hasAction
          ? {
              text: notification.action?.text || ' ',
              onClick: handleActionClicked,
            }
          : undefined
      }
    >
      {notification.description}
    </Toast>
  );
};
