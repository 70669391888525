import { Button, SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import {
  filterIntegrationReverseChargeAccounts,
  useIntegrationStatusQuery,
} from 'modules/bookkeep';
import { canAccessTaxAccountsSettings } from 'modules/bookkeep/settings/integrations/helper/settings-access';
import { useTaxAccountsQuery } from 'modules/bookkeep/settings/integrations/hooks/useTaxAccountsQuery';
import { type ModalState } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/ModalState';
import { DatevReverseChargeTable } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/datev/DatevReverseChargeTable';
import { DoubleEntryReverseChargeTable } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/file-based/DoubleEntryReverseChargeTable';
import { SingleEntryReverseChargeTable } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/file-based/SingleEntryReverseChargeTable';
import { ReverseChargeAddModal } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/modals/ReverseChargeAddModal';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { ReverseChargeDeleteModal } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/modals/ReverseChargeDeleteModal';
import { logger } from 'src/utils/datadog-log-wrapper';

export const ReverseChargeAccountsTable = () => {
  const { t } = useTranslation('global');

  const [modalState, setModalState] = useState<ModalState>({
    kind: 'closed',
  });

  const integrationStatusQueryState = useIntegrationStatusQuery();
  const integrationStatus = unwrapQuery(integrationStatusQueryState);

  const taxAccountsQueryState = useTaxAccountsQuery();
  const taxAccounts = unwrapQuery(taxAccountsQueryState) || [];
  const reverseChargeAccounts =
    filterIntegrationReverseChargeAccounts(taxAccounts);

  const isLoaded =
    taxAccountsQueryState.status === 'success' &&
    integrationStatusQueryState.status === 'success';

  if (
    !isLoaded ||
    !canAccessTaxAccountsSettings(integrationStatusQueryState.data)
  ) {
    const numberOfColumns =
      integrationStatus?.integration &&
      ['Cegid', 'Sage', 'SpendeskAccounting'].includes(
        integrationStatus.integration,
      )
        ? 3
        : 2;
    const columns = Array.from({ length: numberOfColumns }, () => ({
      cell: <SkeletonText width="80%" />,
      header: <SkeletonText width="50%" />,
    }));
    return (
      <SkeletonTable
        numberOfRows={2}
        columns={columns}
        withColumnSeparator
        withHeader
      />
    );
  }

  const renderTable = () => {
    switch (integrationStatus?.integration) {
      case 'Datev':
        return (
          <DatevReverseChargeTable
            integrationStatus={integrationStatus}
            reverseChargeAccounts={reverseChargeAccounts}
            setModalState={setModalState}
          />
        );
      case 'SpendeskAccounting':
      case 'Sage':
      case 'Cegid':
        return (
          <DoubleEntryReverseChargeTable
            integrationStatus={integrationStatus}
            reverseChargeAccounts={reverseChargeAccounts}
            setModalState={setModalState}
          />
        );
      case 'SpendeskAccountingSingleEntry':
        return (
          <SingleEntryReverseChargeTable
            integrationStatus={integrationStatus}
            reverseChargeAccounts={reverseChargeAccounts}
            setModalState={setModalState}
          />
        );
      // Other integrations do not support reverse charge accounts
      default:
        logger.error(
          "The given user's accounting integration shouldn't have access to /setup-hub/accounting/reverse-charge-accounts",
          {
            scope: 'accounting_hub',
            team: 'accounting-integration',
            integration: integrationStatus?.integration,
          },
        );
        return null;
    }
  };

  return (
    <>
      <div className="flex flex-col gap-16">
        {renderTable()}
        <Button
          className="self-start"
          iconName="plus"
          iconPosition="left"
          onClick={() =>
            setModalState({
              kind: 'addReverseCharge',
              id: '',
              name: '',
              codeDebit: '',
              codeCredit: '',
              inputChanged: false,
            })
          }
          text={t(
            'bookkeep.integrations.settings.vatAccountsTable.addVatAccount',
          )}
          variant="tertiaryNeutral"
        />
      </div>
      <ReverseChargeAddModal
        setFormState={setModalState}
        reverseChargeAccounts={reverseChargeAccounts}
        formState={modalState}
        integration={integrationStatusQueryState.data.integration}
        hideErrorLink
      />
      <ReverseChargeDeleteModal
        setFormState={setModalState}
        formState={modalState}
        integration={integrationStatusQueryState.data.integration}
        hideSubtitle
      />
    </>
  );
};
