import { Button, FormField, Modal, TextInput } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';

import { CountryAutocomplete } from 'common/components/CountryAutocomplete';
import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import { useBulkUnarchiveSupplierMutation } from 'modules/bookkeep/accounts-payable/hooks/useBulkUnarchiveSupplierMutation';
import { useCreateSupplierMutation } from 'modules/bookkeep/accounts-payable/hooks/useCreateSupplierMutation';
import { useGetSupplierLowerCaseNamesQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetSupplierLowerCaseNamesQuery';
import { useSetSupplierAccountToSupplierMutation } from 'modules/bookkeep/accounts-payable/hooks/useSetSupplierAccountToSupplierMutation';
import { useUpdateSupplierDetailsMutation } from 'modules/bookkeep/accounts-payable/hooks/useUpdateSupplierDetailsMutation';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

import { validate, type FormValues } from './form';
import { SupplierAccountAutocomplete } from '../SupplierAccountAutocomplete/SupplierAccountAutocomplete';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateSupplierAccountModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const suppliersQueryState = useGetSupplierLowerCaseNamesQuery();
  const suppliers = unwrapQuery(suppliersQueryState) ?? [];

  const [createSupplierMutation] = useCreateSupplierMutation();
  const [setSupplierAccountToSupplier] =
    useSetSupplierAccountToSupplierMutation();
  const [updateSupplierDetail] = useUpdateSupplierDetailsMutation();
  const [bulkUnarchiveSupplier] = useBulkUnarchiveSupplierMutation();

  const errorNotif = t(
    'bookkeep.accountsPayable.createSupplier.createErrorNotification',
  );

  const createNewSupplier = async (newValues: FormValues) => {
    const payload = {
      supplier: {
        name: newValues.name,
        accountHolderName: '',
        bankDetails: { bankCountry: newValues.bankCountry },
        legalDetails: { legalName: newValues.legalName },
      },
    };
    const createSupplierResult = await createSupplierMutation(payload);
    return createSupplierResult.createSupplier.id;
  };

  const unarchiveAndUpdateSupplier = async (
    supplierId: string,
    newValues: FormValues,
  ) => {
    const payload = {
      supplierId,
      bankDetails: { bankCountry: newValues.bankCountry },
      legalDetails: { legalName: newValues.legalName },
    };
    await bulkUnarchiveSupplier({ supplierIds: [supplierId] });
    await updateSupplierDetail(payload);
  };

  const {
    errors,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldError,
    setFieldValue,
    submitCount,
    values,
  } = useFormik<FormValues>({
    initialValues: {
      accountPayableId: '',
      bankCountry: '',
      legalName: '',
      name: '',
    },
    validate: (newValues) => validate(newValues, suppliers, t),
    onSubmit: async (newValues) => {
      let supplierId: string | undefined;
      const alreadyArchivedSupplier = suppliers.find(
        (supplier) => supplier.name === newValues.name.toLowerCase().trim(),
      );

      try {
        // Thanks to the Formik validation, we know that the name isn't used by an active supplier.
        // If it is used by an archived supplier, we unarchive it and update its details.
        // Otherwise, we create a new supplier.
        if (alreadyArchivedSupplier) {
          supplierId = alreadyArchivedSupplier.id;
          await unarchiveAndUpdateSupplier(supplierId, newValues);
        } else {
          supplierId = await createNewSupplier(newValues);
          if (!supplierId) {
            dangerNotif(errorNotif);
            return;
          }
        }

        const setSupplierAccountToSupplierResult =
          await setSupplierAccountToSupplier({
            supplierId,
            supplierAccountId: values.accountPayableId,
          });
        if (
          setSupplierAccountToSupplierResult.setSupplierAccountToSupplier.reason
        ) {
          dangerNotif(errorNotif);
          return;
        }

        closeModal();
      } catch {
        dangerNotif(t('misc.errors.unknownError'));
      }
    },
  });

  const closeModal = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      iconName="plus"
      title={t('setupHub.supplierAccounts.createSupplier.title')}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          onClick={closeModal}
          text={t('misc.cancel')}
        />,
        <Button
          key="confirm"
          form="create-accounts-payable-form"
          type="submit"
          text={t('misc.confirm')}
        />,
      ]}
    >
      <form
        id="create-accounts-payable-form"
        className="grid grid-cols-2 gap-16"
        onSubmit={handleSubmit}
      >
        <FormField
          label={t('bookkeep.accountsPayable.createSupplier.nameLabel')}
          alertMessage={submitCount > 0 ? errors.name : undefined}
        >
          <TextInput
            name="name"
            onChange={handleChange}
            placeholder={t(
              'bookkeep.accountsPayable.createSupplier.namePlaceholder',
            )}
            value={values.name}
          />
        </FormField>
        <FormField
          label={t('bookkeep.accountsPayable.createSupplier.legalNameLabel')}
          alertMessage={submitCount > 0 ? errors.legalName : undefined}
        >
          <TextInput
            name="legalName"
            onChange={handleChange}
            placeholder={t(
              'bookkeep.accountsPayable.createSupplier.legalNamePlaceholder',
            )}
            value={values.legalName}
          />
        </FormField>
        <FormField
          label={t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.bankCountryLabel',
          )}
          alertMessage={submitCount > 0 ? errors.bankCountry : undefined}
        >
          <CountryAutocomplete
            countryCode={values.bankCountry}
            fit="parent"
            name="bankCountry"
            onSelect={(selectedKey: string | undefined) =>
              setFieldValue('bankCountry', selectedKey || '')
            }
            placeholder={t(
              'bookkeep.accountsPayable.panel.bankDetailsSection.bankCountryPlaceholder',
            )}
          />
        </FormField>
        <FormField
          label={t(
            'bookkeep.accountsPayable.createSupplier.accountPayableLabel',
          )}
          alertMessage={submitCount > 0 ? errors.accountPayableId : undefined}
        >
          <SupplierAccountAutocomplete
            error={errors.accountPayableId}
            onChange={(value: string) =>
              setFieldValue('accountPayableId', value)
            }
            setError={(newError: string | undefined) =>
              setFieldError('accountPayableId', newError)
            }
            value={values.accountPayableId}
          />
        </FormField>
      </form>
    </Modal>
  );
};
