import type { I18nKey } from 'common/hooks/useTranslation';
import {
  type Column,
  dateFormats,
  type RowGroup,
} from 'modules/bookkeep/settings/export';
import AccountingSoftwaresValues from 'modules/bookkeep/settings/export/components/TemplateEditorForm/accountingSoftwareValues.json';
import type { CustomField } from 'modules/bookkeep/settings/export/customField';
import type { RawCustomField } from 'modules/custom-fields/models/customField';
import { languages } from 'src/core/config/i18n';

export const getRequiredAnalyticalFields = (
  columns: Column[],
  customFields: CustomField[] | RawCustomField[],
  isAnalyticalSplitActivated: boolean,
) =>
  columns.map((column) => {
    if (column.type === 'defined' && column.reference === 'costCenter') {
      return {
        ...column,
        isRequired: isAnalyticalSplitActivated ? true : column.isRequired,
      };
    }
    if (column.type === 'customField') {
      const isSplittableAnalyticalField =
        customFields.find(({ id }) => id === column.customFieldId)
          ?.is_splittable === true;

      return {
        ...column,
        isRequired: isAnalyticalSplitActivated
          ? isSplittableAnalyticalField
          : column.isRequired,
        isReorderable: isAnalyticalSplitActivated
          ? !isSplittableAnalyticalField
          : column.isReorderable,
      };
    }
    return column;
  });

export const getAvailableAccountingSoftwares: (
  othersLabel: string,
) => { key: string; label: string }[] = (othersLabel) => [
  ...AccountingSoftwaresValues.map((v) => ({ key: v, label: v })),
  { key: 'Others', label: othersLabel },
];

export const getDateFormatOptions = (dateDelimiter: string) =>
  dateFormats.map((format) => ({
    key: format.join(dateDelimiter),
    label: format.join(dateDelimiter),
  }));

export const getLanguageOptions = () =>
  languages
    // TODO: temporary fix to avoid selecting IT & ES languages, since the backend is not ready yet
    .filter(
      (language) => language === 'en' || language === 'fr' || language === 'de',
    );

export const rowGroupToI18nKey: Record<RowGroup, I18nKey> = {
  expense: 'bookkeep.settings.export.rowDisplayOptions.expense',
  analytical: 'bookkeep.settings.export.rowDisplayOptions.analytical',
  vat: 'bookkeep.settings.export.rowDisplayOptions.vat',
  supplier: 'bookkeep.settings.export.rowDisplayOptions.supplier',
};
