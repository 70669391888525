import {
  Button,
  Callout,
  DropdownItem,
  FormField,
  Icon,
  type IconName,
  Select,
  TextInput,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import { fromNumber } from 'ezmoney';
import { type FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';

import { AutocompleteMultipleSearch } from 'common/components/AutocompleteSearch';
import { useFeature } from 'common/hooks/useFeature';
import { useModal } from 'common/hooks/useModal';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { type CompanyRole } from 'modules/members/models/roles';
import FEATURES from 'src/core/constants/features';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  CustomApprovalPolicyModal,
  getReadableSpendingTypes,
} from '../../../company/members/components/ApprovalPolicies';
import { type ControlRule } from '../../../company/members/components/ControlRules';
import { type Member } from '../../models/member';
import { type Policy } from '../../models/policy';
import { translateControlRuleName } from '../../utils/translateControlRuleName';
import { type MemberEditFormikValues } from '../MemberEditModal/validate';

type Team = {
  id: string;
  name: string;
};

type CostCenter = {
  id: string;
  name: string;
};

type Props = {
  member: Member;
  teams: Team[];
  costCenters: CostCenter[];
  controlRules: ControlRule[];
  policies: Policy[];
  formikProps: FormikProps<MemberEditFormikValues>;
};

const defaultCustomControlRule = {
  isCustom: true,
  completionDeadline: 5,
  incompletePaymentsLimit: 7,
};

export const MemberEditTabSettings = ({
  member,
  teams,
  costCenters,
  controlRules,
  policies,
  formikProps,
}: Props) => {
  const { t } = useTranslation('global');
  const companyCurrency = useCompanyCurrency();
  const isTeamsFeatureEnabled = useFeature(FEATURES.TEAMS);
  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  const suggestedCostCenter: {
    key: string;
    label: string;
    value: null | CostCenter;
    iconName: IconName;
  } = {
    key: 'suggested',
    label: t('costCenters.suggestedCostCenter'),
    value: null,
    iconName: 'lightning',
  };

  const teamsById = new Map(teams.map((team) => [team.id, team]));
  const controlRulesById = new Map(
    controlRules.map((controlRule) => [controlRule.id, controlRule]),
  );
  const approvalPoliciesById = new Map(
    policies.map((policy) => [policy.id, policy]),
  );

  const {
    teams: currentTeams,
    costCenter: currentCostCenter,
    controlRule: currentControlRule,
    approvalPolicy: currentApprovalPolicy,
  } = formikProps.values;

  const [
    customApprovalPolicyModal,
    showCustomApprovalPolicyModal,
    hideCustomApprovalPolicyModal,
  ] = useModal(() => {
    if (!currentApprovalPolicy) {
      return null;
    }
    return (
      <CustomApprovalPolicyModal
        values={formikProps.values.approvalPolicy?.params}
        onCancel={() => toggleCustomApprovalPolicyModal()}
        onSuccess={(values) => {
          formikProps.setFieldValue('approvalPolicy', {
            isDefault: false,
            isCustom: true,
            params: values,
          });
          toggleCustomApprovalPolicyModal();
        }}
      />
    );
  });

  useEffect(() => {
    const isDisplayed = Boolean(customApprovalPolicyModal);
    handleParentModalOpacity(isDisplayed);
  }, [Boolean(customApprovalPolicyModal)]);

  const toggleCustomApprovalPolicyModal = () => {
    const isDisplayed = Boolean(customApprovalPolicyModal);
    if (isDisplayed) {
      hideCustomApprovalPolicyModal();
    } else {
      showCustomApprovalPolicyModal();
    }
  };

  const policyOnlyForRequesterOption = {
    key: 'policyOnlyForRequester',
    label: t('approvalPolicy.settings.policyOnlyForRequesters'),
  };

  const customPolicyOption = {
    key: 'custom',
    label: t('approvalPolicy.settings.approvalPolicyCustom'),
  };

  const selectedPolicyOption = (() => {
    if (currentApprovalPolicy) {
      if (currentApprovalPolicy.isCustom) {
        return customPolicyOption;
      }
      return {
        key: currentApprovalPolicy.id,
        label: currentApprovalPolicy.name || '',
      };
    }
  })();

  const customControlRuleOption = {
    key: 'custom',
    label: t('approvalPolicy.settings.controlRuleCustom'),
  };
  const noControlRuleOption = {
    key: 'noControlRule',
    label: t('approvalPolicy.settings.noControlRule'),
  };
  const controlRuleOnlyForRequesterOption = {
    key: 'controlRuleOnlyForRequester',
    label: t('approvalPolicy.settings.controlRuleOnlyForRequesters'),
  };
  const selectedControlRuleOption = (() => {
    if (currentControlRule) {
      if (currentControlRule.isCustom) {
        return customControlRuleOption;
      }
      return {
        key: currentControlRule.id,
        label: translateControlRuleName(currentControlRule, t),
      };
    }
    return noControlRuleOption;
  })();

  const isMemberARequester = hasRequesterRole(formikProps.values.roles);

  return (
    <>
      {customApprovalPolicyModal}
      <div className="flex flex-col content-stretch gap-24">
        {!member.isAccountOwner && (
          <FormField label={t('approvalPolicy.settings.approvalPolicy')}>
            <div className="flex flex-col content-stretch gap-8">
              <Select
                fit="parent"
                value={
                  !isMemberARequester
                    ? policyOnlyForRequesterOption
                    : selectedPolicyOption
                }
                isDisabled={!isMemberARequester}
                options={
                  !isMemberARequester
                    ? [policyOnlyForRequesterOption]
                    : [
                        ...policies.map((policy) => ({
                          key: policy.id,
                          label: policy.name ?? '',
                        })),
                        customPolicyOption,
                      ]
                }
                onSelect={(policyOption) => {
                  if (policyOption.key === customPolicyOption.key) {
                    toggleCustomApprovalPolicyModal();
                    return;
                  }
                  formikProps.setFieldValue(
                    'approvalPolicy',
                    approvalPoliciesById.get(policyOption.key),
                  );
                }}
              />
              {isMemberARequester &&
                currentApprovalPolicy &&
                currentApprovalPolicy.params.approvalNeeded && (
                  <>
                    {currentApprovalPolicy.params.approvalNeeded ===
                      'always' && (
                      <Callout
                        variant="info"
                        title={t(
                          'approvalPolicy.settings.approvalPolicyAlways',
                          {
                            name: member.displayName,
                          },
                        )}
                      />
                    )}
                    {currentApprovalPolicy.params.approvalNeeded ===
                      'sometimes' && (
                      <Callout
                        variant="info"
                        title={
                          <Trans
                            i18nKey={t(
                              'members.editModal.approvalPolicySometimes',
                            )}
                            values={{
                              name: member.displayName,
                              amountPerTransaction: formatMonetaryValue(
                                fromNumber(
                                  currentApprovalPolicy.params
                                    .amountPerTransaction,
                                  companyCurrency,
                                  2,
                                ),
                              ),
                              amountPerMonth: formatMonetaryValue(
                                fromNumber(
                                  currentApprovalPolicy.params.amountPerMonth,
                                  companyCurrency,
                                  2,
                                ),
                              ),
                              spendingTypes: getReadableSpendingTypes(
                                currentApprovalPolicy.params.spendingTypes,
                                t,
                              ),
                            }}
                          />
                        }
                      />
                    )}
                    {currentApprovalPolicy.params.approvalNeeded ===
                      'never' && (
                      <Callout
                        variant="info"
                        title={t(
                          'approvalPolicy.settings.approvalPolicyNever',
                          {
                            name: member.displayName,
                          },
                        )}
                      />
                    )}
                    {currentApprovalPolicy.isCustom && (
                      <Button
                        variant="secondaryNeutral"
                        text={t('approvalPolicy.settings.approvalPolicyEdit')}
                        onClick={toggleCustomApprovalPolicyModal}
                      />
                    )}
                  </>
                )}
            </div>
          </FormField>
        )}
        <FormField
          label={t('approvalPolicy.settings.controlRule')}
          className="flex flex-col content-stretch gap-8"
        >
          <>
            <Select
              fit="parent"
              value={
                !isMemberARequester
                  ? controlRuleOnlyForRequesterOption
                  : selectedControlRuleOption
              }
              isDisabled={!isMemberARequester}
              options={
                !isMemberARequester
                  ? [controlRuleOnlyForRequesterOption]
                  : [
                      noControlRuleOption,
                      ...controlRules.map((controlRule) => ({
                        key: controlRule.id,
                        label: translateControlRuleName(controlRule, t),
                      })),
                      customControlRuleOption,
                    ]
              }
              onSelect={(controlRuleOption) => {
                let controlRule;
                switch (controlRuleOption.key) {
                  case 'custom':
                    controlRule = defaultCustomControlRule;
                    break;
                  case 'noControlRule':
                    controlRule = null;
                    break;
                  default:
                    controlRule = controlRulesById.get(controlRuleOption.key);
                }

                formikProps.setFieldValue('controlRule', controlRule);
              }}
            />
            {currentControlRule?.isCustom && (
              <div className="grid grid-cols-2 gap-24">
                <FormField
                  label={t('approvalPolicy.settings.controlRuleLimit')}
                  alertMessage={formikProps.errors.controlRule}
                >
                  <TextInput
                    name="controlRuleLimit"
                    value={currentControlRule.incompletePaymentsLimit.toString()}
                    placeholder={t('approvalPolicy.settings.controlRuleLimit')}
                    onChange={(event) => {
                      const { value } = event.target;
                      const incompletePaymentsLimit =
                        value === '' ? '' : Number.parseInt(value);
                      if (!Number.isNaN(incompletePaymentsLimit)) {
                        formikProps.setFieldValue('controlRule', {
                          ...currentControlRule,
                          incompletePaymentsLimit,
                        });
                      }
                    }}
                  />
                </FormField>
                <FormField
                  label={t('approvalPolicy.settings.controlRuleDeadline')}
                >
                  <Select
                    fit="parent"
                    value={{
                      key: currentControlRule.completionDeadline.toString(),
                      label: `${currentControlRule.completionDeadline} ${t(
                        'approvalPolicy.settings.controlRuleDays',
                      )}`,
                    }}
                    options={new Array(30).fill(undefined).map((_, index) => {
                      const day = index + 2;
                      return {
                        key: day.toString(),
                        label: `${day} ${t(
                          'approvalPolicy.settings.controlRuleDays',
                        )}`,
                      };
                    })}
                    onSelect={(deadlineOption) => {
                      formikProps.setFieldValue('controlRule', {
                        ...currentControlRule,
                        completionDeadline: Number(deadlineOption.key),
                      });
                    }}
                  />
                </FormField>
              </div>
            )}
          </>
        </FormField>
        <div
          className={cx(
            isTeamsFeatureEnabled &&
              isCostCentersFeatureEnabled &&
              'grid grid-cols-2 gap-24',
          )}
        >
          {isTeamsFeatureEnabled && (
            <FormField label={t('approvalPolicy.settings.teams')}>
              <AutocompleteMultipleSearch
                fit="parent"
                options={teams.map((team) => ({
                  key: team.id,
                  label: team.name,
                }))}
                values={currentTeams.map((team) => ({
                  key: team.id,
                  label: team.name,
                }))}
                translations={{
                  selectAll: t('approvalPolicy.settings.teamsSelectAll'),
                  selected: t('approvalPolicy.settings.teamsSelected', {
                    count: currentTeams.length,
                  }),
                }}
                onSelect={(teamsOptions) => {
                  formikProps.setFieldValue(
                    'teams',
                    teamsOptions.map((teamsOption) =>
                      teamsById.get(teamsOption.key),
                    ),
                  );
                }}
              />
            </FormField>
          )}
          {isCostCentersFeatureEnabled && (
            <FormField label={t('approvalPolicy.settings.costCenter')}>
              <Select
                fit="parent"
                value={
                  currentCostCenter &&
                  currentCostCenter.selectionMode !== 'suggested'
                    ? {
                        key: currentCostCenter.id,
                        label: currentCostCenter.name,
                        value: currentCostCenter,
                        iconName: null,
                      }
                    : suggestedCostCenter
                }
                options={[
                  suggestedCostCenter,
                  ...costCenters.map((costCenter) => ({
                    key: `cost-center:${costCenter.id}`,
                    label: costCenter.name,
                    value: costCenter,
                    iconName: null,
                  })),
                ]}
                renderOption={(option, optionState) =>
                  option.iconName ? (
                    <DropdownItem
                      label={
                        <div className="flex items-center gap-4">
                          {option.label}
                          <Icon
                            name={option.iconName}
                            className={
                              optionState?.isSelected
                                ? 'text-brand-default'
                                : 'text-secondary-bg-secondary'
                            }
                          />
                        </div>
                      }
                      {...optionState}
                    />
                  ) : (
                    <DropdownItem label={option.label} {...optionState} />
                  )
                }
                placeholder={t('approvalPolicy.settings.costCenterSelect')}
                onSelect={(costCenterOption) => {
                  formikProps.setFieldValue(
                    'costCenter',
                    costCenterOption.value,
                  );
                }}
              />
            </FormField>
          )}
        </div>
      </div>
    </>
  );
};

const handleParentModalOpacity = (isDisplayed: boolean) => {
  const parentModal =
    document.querySelector<HTMLDivElement>('.MemberEditModal');
  if (parentModal?.parentElement?.parentElement) {
    parentModal.parentElement.parentElement.style.opacity = isDisplayed
      ? '0'
      : '1';
  }
};

export const hasRequesterRole = (selectedRoles: CompanyRole[]) =>
  selectedRoles.some((role) => ['requester', 'accountOwner'].includes(role));
