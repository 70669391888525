import {
  Button,
  Icon,
  Modal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { useInvalidateCostCentersQuery } from 'modules/budgets/apis';
import { useUpdateRolesMutation } from 'modules/members/hooks/useUpdateRolesMutation';
import * as MemberTrackingEvents from 'modules/members/tracking';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import {
  useOptimisticallyApprovedWtConfirmationQuery,
  type Delegation,
} from 'src/core/modules/delegation';
import { useHasWireTransferDelegationFeature } from 'src/core/modules/delegation/hooks/useHasWireTransferDelegationFeature';
import { useIsUserManagedByKombo } from 'src/core/modules/integrations/hooks';

import { type MemberEditFormikValues, validate } from './validate';
import { type ApprovalPolicy } from '../../../company/members/components/ApprovalPolicies';
import { postUpdateManagerAssociation } from '../../api/post-update-manager-association';
import { ROLE_NAMES_TO_ASSIGN } from '../../constants/roles';
import { useEditApprovalPolicyMutation } from '../../containers/MemberEditModalContainer/hooks/useEditApprovalPolicyMutation';
import { useEditBankInfoMutation } from '../../containers/MemberEditModalContainer/hooks/useEditBankInfoMutation';
import { useEditControlRuleMutation } from '../../containers/MemberEditModalContainer/hooks/useEditControlRuleMutation';
import { useEditDelegationMutation } from '../../containers/MemberEditModalContainer/hooks/useEditDelegationMutation';
import { useEditTeamMutation } from '../../containers/MemberEditModalContainer/hooks/useEditTeamMutation';
import { useEditUserMutation } from '../../containers/MemberEditModalContainer/hooks/useEditUserMutation';
import { MemberEditTabRolesContainer } from '../../containers/MemberEditTabRolesContainer/MemberEditTabRolesContainer';
import { MemberEditTabSettingsContainer } from '../../containers/MemberEditTabSettingsContainer/MemberEditTabSettingsContainer';
import { useCanEditUserBankInfo } from '../../hooks/useCanEditUserBankInfo';
import { useGetCompanyManagedByKomboHrIntegrationQuery } from '../../hooks/useGetCompanyManagedByKomboHrIntegrationQuery';
import { useHasCompanyUserBankInfos } from '../../hooks/useHasCompanyUserBankInfos';
import {
  getMemberName,
  getMemberRoleList,
  type Member,
  type MemberDetails,
} from '../../models/member';
import { MemberEditTabAdditionalRights } from '../MemberEditTabAdditionalRights';
import { MemberEditTabInformation } from '../MemberEditTabInformation/MemberEditTabInformation';
import { hasRequesterRole } from '../MemberEditTabSettings/MemberEditTabSettings';

type Props = {
  member: MemberDetails;
  onClose: () => void;
  onEditDelegation: (role: Delegation, onComplete: () => void) => void;
};

export const MemberEditModal = ({
  member,
  onClose,
  onEditDelegation,
}: Props) => {
  useEffect(() => {
    MemberTrackingEvents.onEditMemberDetailsModalOpened({ member });
  }, []);

  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const queryClient = useQueryClient();
  const canEditUserBankInfo = useCanEditUserBankInfo(member);
  const hasCompanyUserBankInfos = useHasCompanyUserBankInfos();
  const userPolicy = member.policy;
  const userApprovalPolicy = userPolicy
    ? reshapeToApprovalPolicy(userPolicy)
    : undefined;

  const hasReportingManagerFeature = useFeature(FEATURES.REPORTING_MANAGERS);

  const optimisticallyApproveWtConfirmation =
    useOptimisticallyApprovedWtConfirmationQuery();
  const memberId = member.id;
  const companyId = useCompanyId();
  const [editUser] = useEditUserMutation(memberId);
  const [editApprovalPolicy] = useEditApprovalPolicyMutation(memberId);
  const [editTeam] = useEditTeamMutation(memberId);
  const [editRoles] = useUpdateRolesMutation();
  const [removeDelegation] = useEditDelegationMutation(memberId);
  const [editControlRule] = useEditControlRuleMutation();
  const [editBankInfo] = useEditBankInfoMutation(memberId);
  const [tabIndex, setTabIndex] = useState(0);
  const isManagedByKombo = useIsUserManagedByKombo({ userId: member.id });
  const getCompanyManagedByKombo =
    useGetCompanyManagedByKomboHrIntegrationQuery();
  const companyHrFeatures =
    unwrapQuery(getCompanyManagedByKombo)?.featureSet ?? [];
  const hasBankAccountInfoInHrTool = companyHrFeatures.includes(
    'includesBankDetails',
  );
  const hasReportingManagerSyncEnabled = companyHrFeatures.includes(
    'includesReportingManagers',
  );

  const hasWireTransferDelegationFeature =
    useHasWireTransferDelegationFeature();

  const invalidateCostCentersQuery = useInvalidateCostCentersQuery();

  // If user is managed by Kombo & has required information in the tool, we don't display the buttons on the first tab (information tab) as all fields are disabled
  const isEditingDisabled =
    isManagedByKombo &&
    hasBankAccountInfoInHrTool &&
    hasReportingManagerSyncEnabled &&
    !!member.manager;
  const hasActionButtons = isEditingDisabled ? tabIndex > 0 : true;

  const onSubmit = async (values: MemberEditFormikValues) => {
    await editUser(member, values); // Edit cost center, firstName, lastName & manager

    if (hasReportingManagerFeature && values.managerId !== member.manager?.id) {
      await postUpdateManagerAssociation({
        companyId,
        userId: member.id,
        managerId: values.managerId,
      });
    }

    if (hasCompanyUserBankInfos) {
      await editBankInfo(member.bankInfo, values.bankInfo);
    }

    await editRoles({
      userId: member.id,
      roleUpdates: ROLE_NAMES_TO_ASSIGN.map((role) => ({
        role,
        enabled: values.roles.includes(role),
      })),
    });
    await editTeam(
      member.teams.map((team) => team.id),
      values.teams.map((team) => team.id),
    );
    if (hasRequesterRole(values.roles)) {
      await editApprovalPolicy(userApprovalPolicy ?? undefined, values);
      await editControlRule(values.controlRule, member.controlRule, member.id);
    }

    const isWireTransferDelegationRemoved =
      hasWireTransferDelegationFeature &&
      values.canConfirmPayments !== member.canConfirmPayments &&
      values.canConfirmPayments === false;

    if (isWireTransferDelegationRemoved) {
      await removeDelegation('wt_confirmation');
    }

    await Promise.all([
      queryClient.invalidateQueries(['users', member.id]),
      queryClient.invalidateQueries(['users'], { exact: true }),
      queryClient.invalidateQueries(['control-rules']),
      invalidateCostCentersQuery(),
    ]);

    MemberTrackingEvents.onEditMemberDetailsChangesApplied({
      member,
      draftMember: values,
    });
    pushNotif({
      type: NotificationType.Success,
      message: t('members.editModal.updateSuccess'),
    });
    onClose();
  };

  const formikProps = useFormik<MemberEditFormikValues>({
    initialValues: {
      // Profile tab
      firstName: member.firstName,
      lastName: member.lastName,
      managerId: member.manager?.id ?? null,
      bankInfo: member.bankInfo ?? {},
      // Roles tab
      roles: getMemberRoleList(member),
      // additional rights tab
      canConfirmPayments: member.canConfirmPayments,
      // Settings tab
      teams: member.teams,
      costCenter: member.costCenter,
      controlRule: member.controlRule,
      approvalPolicy: userApprovalPolicy,
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validate: (values) => validate(member, t, values, canEditUserBankInfo),
    onSubmit: async (values, actions) => {
      try {
        MemberTrackingEvents.onEditMemberDetailsSaved({
          member,
          draftMember: values,
        });
        if (
          isUpdatingDelegation(member, values, hasWireTransferDelegationFeature)
        ) {
          onEditDelegation('wt_confirmation', async () => {
            await onSubmit(values);
            optimisticallyApproveWtConfirmation(member.id);
          });
        } else {
          await onSubmit(values);
        }
      } catch (error) {
        if (error?.status === 400 && error?.url?.endsWith('bank-info')) {
          actions.setFieldError(
            'bankInfo.apiError',
            t('bankInfoForm.errors.invalid_bank_info'),
          );
        }
        pushNotif({
          type: NotificationType.Danger,
          message: t('members.editModal.updateFailed'),
        });
      }
    },
  });

  const hasErrorInInfoTab =
    formikProps.errors.firstName ||
    formikProps.errors.lastName ||
    !isEmpty(formikProps.errors.bankInfo);
  const hasErrorInRoleTab = formikProps.errors.roles;

  return (
    <Modal
      className="MemberEditModal"
      isOpen
      iconName="pen"
      iconVariant="purple"
      title={t('members.editUserProfile', {
        userName: getMemberName(member),
      })}
      onClose={onClose}
      actions={
        hasActionButtons && (
          <>
            <Button
              text={t('misc.cancel')}
              variant="secondaryNeutral"
              onClick={onClose}
            />
            <Button
              text={t('misc.saveChanges')}
              variant="primaryBrand"
              isLoading={formikProps.isSubmitting}
              onClick={() => formikProps.submitForm()}
            />
          </>
        )
      }
    >
      <div>
        <Tabs tabIndex={tabIndex} onChange={setTabIndex}>
          <TabList isFitted className="mb-24">
            <Tab className="gap-8">
              {hasErrorInInfoTab && <Icon name="triangle-warning" />}
              {t('members.editModal.infoTabTitle')}
            </Tab>
            <Tab className="gap-8">
              {hasErrorInRoleTab && <Icon name="triangle-warning" />}
              {t('members.editModal.roleTabTitle')}
            </Tab>
            {hasWireTransferDelegationFeature && (
              <Tab>{t('members.editModal.additionalRightsTabTitle')}</Tab>
            )}
            <Tab>{t('members.editModal.settingsTabTitle')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <MemberEditTabInformation
                user={member}
                formikProps={formikProps}
              />
            </TabPanel>
            <TabPanel>
              <MemberEditTabRolesContainer
                member={member}
                formikProps={formikProps}
              />
            </TabPanel>
            {hasWireTransferDelegationFeature && (
              <TabPanel>
                <MemberEditTabAdditionalRights
                  member={member}
                  formikProps={formikProps}
                />
              </TabPanel>
            )}
            <TabPanel>
              <MemberEditTabSettingsContainer
                member={member}
                formikProps={formikProps}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </Modal>
  );
};

const isUpdatingDelegation = (
  member: MemberDetails,
  newValues: { canConfirmPayments?: boolean },
  hasWireTransferDelegationFeature: boolean,
) => {
  return (
    hasWireTransferDelegationFeature &&
    newValues.canConfirmPayments &&
    newValues.canConfirmPayments !== member.canConfirmPayments
  );
};

const reshapeToApprovalPolicy = (
  policy: NonNullable<Member['policy']>,
): ApprovalPolicy => {
  return {
    id: policy.id,
    name: policy.name ?? '',
    isDefault: policy.isDefault,
    isCustom: policy.isCustom,
    params: {
      spendingTypes: policy.params?.spendingTypes ?? [],
      approvalNeeded: policy.params?.approvalNeeded ?? 'always',
      amountPerMonth: policy.params?.amountPerMonth ?? 0,
      amountPerTransaction: policy.params?.amountPerTransaction ?? 0,
    },
  };
};
