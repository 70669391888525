import {
  type I18nKey,
  useTranslation,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { EntityItemActionItem } from './EntityItemActionItem';
import {
  EntityItemActionsGroup,
  EntityStatisticBlockSkeleton,
} from './EntityItemActionsGroup';
import { type EntityItemAction } from './types';
import { useGetEntityActions } from '../../../../hooks/data/useGetEntityActions';
import {
  type OrganisationFeatures,
  useOrganisationFeatures,
} from '../../../../hooks/useOrganisationFeatures';
import type { EntityBasicInformation, EntityActions } from '../../../../types';
import { type getEntityStatus } from '../EntityListItem';
import { isEntityInactive } from '../types';

type Props = {
  entity: EntityBasicInformation;
  entityStatus: ReturnType<typeof getEntityStatus>;
  isDataVisible: boolean;
};

export const EntityItemActions = ({
  entity,
  entityStatus,
  isDataVisible,
}: Props) => {
  const { t } = useTranslation('global');
  const features = useOrganisationFeatures();

  const entityActionsQueryState = useGetEntityActions(entity, features);

  if (entityActionsQueryState.status !== 'success' || !isDataVisible) {
    const availableGroups = [
      features.invoicesInbox,
      features.requests,
      features.invoices,
      features.expenseClaims,
      features.receipts.lateReceipts || features.receipts.missingReceipts,
      features.payables,
    ].filter(Boolean);

    return (
      <div className="flex items-stretch gap-8">
        {[...Array(availableGroups.length).keys()].map((x) => (
          <EntityStatisticBlockSkeleton key={x} />
        ))}
      </div>
    );
  }

  // TODO: Remove this when we want to display churned entities
  // See https://spendesk.atlassian.net/browse/ECO-395
  if (entity.hasChurned) {
    return null;
  }

  const availableEntityActions = getEntityAvailableActions(
    entity,
    entityActionsQueryState.data,
    features,
    t,
  ).filter(({ isAvailable }) => isAvailable);

  const entityActionGroups = [
    ...new Set(availableEntityActions.map(({ group }) => group)),
  ];

  const isInactive = isEntityInactive(entityStatus);

  return (
    <div className="flex items-stretch gap-8 overflow-scroll">
      {entityActionGroups.map((group) => (
        <EntityItemActionsGroup
          key={group}
          title={t(groupToI18nKey[group])}
          contents={[
            availableEntityActions
              .filter(({ group: actionGroup }) => actionGroup === group)
              .map(({ key, count, label, href, trackingEventName }) => (
                <EntityItemActionItem
                  key={key}
                  label={label}
                  count={
                    (isInactive || entityStatus === 'churning' ? 0 : count) ?? 0
                  }
                  href={href}
                  callback={
                    trackingEventName &&
                    (() => track(trackingEventName, { value: key }))
                  }
                  isInactive={
                    isInactive || entityStatus === 'churning' || count === 0
                  }
                />
              )),
          ]}
        />
      ))}
    </div>
  );
};

const DEFAULT_VALUE = 0;
const getEntityAvailableActions = (
  entity: EntityBasicInformation,
  actionsData: EntityActions,
  features: OrganisationFeatures,
  t: TGlobalFunctionTyped,
): EntityItemAction[] => [
  // Inbox
  {
    group: 'inbox',
    key: 'inbox-toAssign',
    count: actionsData.inboxInvoices.toAssign ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.inbox.groups.toAssign',
    ),
    href: routeFor(routes.INBOX_INVOICES.path, {
      company: entity.id,
    }),
    isAvailable: features.invoicesInbox,
  },
  {
    group: 'inbox',
    key: 'inbox-awaiting',
    count: actionsData.inboxInvoices.awaiting ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.inbox.groups.awaiting',
    ),
    href: routeFor(routes.INBOX_INVOICES.path, {
      company: entity.id,
    }),
    isAvailable: features.invoicesInbox,
  },
  // Requests
  {
    group: 'requests',
    key: 'requests-toApprove',
    count: actionsData.requests.toApprove ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.requests.groups.toApprove',
    ),
    href: routeFor(routes.REQUESTS.path, {
      company: entity.id,
      type: 'to-approve',
    }),
    trackingEventName:
      AnalyticEventName.ORGANISATION_REPORTING_REQUESTS_TO_APPROVE_BUTTON_CLICKED,
    isAvailable: features.requests,
  },
  {
    group: 'requests',
    key: 'requests-total',
    count: actionsData.requests.all ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.requests.groups.total',
    ),
    href: routeFor(routes.REQUESTS.path, {
      company: entity.id,
      type: 'all',
    }),
    isAvailable: features.requests,
  },
  // Invoices
  {
    group: 'invoices',
    key: 'invoices-toReview',
    count: actionsData.invoices.toReview ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.invoices.groups.toReview',
    ),
    href: routeFor(routes.INVOICES_REVIEW.path, {
      company: entity.id,
    }),
    isAvailable: features.invoices,
  },
  {
    group: 'invoices',
    key: 'invoices-toSchedule',
    count: actionsData.invoices.toSchedule ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.invoices.groups.toSchedule',
    ),
    href: routeFor(routes.INVOICES_PAY.path, {
      company: entity.id,
    }),
    isAvailable: features.invoices,
  },
  {
    group: 'invoices',
    key: 'invoices-toConfirm',
    count: actionsData.invoices.toConfirm ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.invoices.groups.toConfirm',
    ),
    href: routeFor(routes.INVOICES_CONFIRM.path, {
      company: entity.id,
    }),
    trackingEventName:
      AnalyticEventName.ORGANISATION_REPORTING_INVOICES_TO_PAY_BUTTON_CLICKED,
    isAvailable: features.invoices,
  },
  // Expense claims
  {
    group: 'expenseClaims',
    key: 'expenseClaims-toReview',
    count: actionsData.expenseClaims.toReview ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.expenseClaims.groups.toReview',
    ),
    href: routeFor(routes.EXPENSE_CLAIMS_REVIEW.path, {
      company: entity.id,
    }),
    isAvailable: features.expenseClaims,
  },
  {
    group: 'expenseClaims',
    key: 'expenseClaims-toReimburse',
    count: actionsData.expenseClaims.toReimburse ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.expenseClaims.groups.toReimburse',
    ),
    href: routeFor(routes.EXPENSE_CLAIMS_PAY.path, {
      company: entity.id,
    }),
    isAvailable: features.expenseClaims,
  },
  {
    group: 'expenseClaims',
    key: 'expenseClaims-toConfirm',
    count: actionsData.expenseClaims.toConfirm ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.expenseClaims.groups.toConfirm',
    ),
    href: routeFor(routes.EXPENSE_CLAIMS_CONFIRM.path, {
      company: entity.id,
    }),
    isAvailable: features.expenseClaims,
  },
  // Receipts
  {
    group: 'receipts',
    key: 'receipts-late',
    count: actionsData.receipts.late ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.receipts.groups.late',
    ),
    href: `${routeFor(routes.PAYMENTS_ALL.path, {
      company: entity.id,
    })}?completionDeadline=late`,
    trackingEventName:
      AnalyticEventName.ORGANISATION_REPORTING_LATE_RECEIPTS_BUTTON_CLICKED,
    isAvailable: features.receipts.lateReceipts,
  },
  {
    group: 'receipts',
    key: 'receipts-missing',
    count: actionsData.receipts.missing ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.receipts.groups.missing',
    ),
    href: `${routeFor(routes.PAYMENTS_ALL.path, {
      company: entity.id,
    })}?invoice=missing`,
    isAvailable: features.receipts.missingReceipts,
  },
  // Payables
  {
    group: 'payables',
    key: 'payables-toPrepare',
    count: actionsData.payables.toPrepare ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.payables.groups.toPrepare',
    ),
    href: routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
      company: entity.id,
    }),
    trackingEventName:
      AnalyticEventName.ORGANISATION_REPORTING_PAYABLES_TO_REVIEW_BUTTON_CLICKED,
    isAvailable: features.payables,
  },
  {
    group: 'payables',
    key: 'payables-toExport',
    count: actionsData.payables.toExport ?? DEFAULT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.payables.groups.toExport',
    ),
    href: routeFor(routes.EXPENSE_INBOX_EXPORT.path, {
      company: entity.id,
    }),
    isAvailable: features.payables,
  },
];

const groupToI18nKey: Record<EntityItemAction['group'], I18nKey> = {
  inbox: 'organisation.reporting.page.entities.entity.statistics.inbox.title',
  requests:
    'organisation.reporting.page.entities.entity.statistics.requests.title',
  invoices:
    'organisation.reporting.page.entities.entity.statistics.invoices.title',
  expenseClaims:
    'organisation.reporting.page.entities.entity.statistics.expenseClaims.title',
  receipts:
    'organisation.reporting.page.entities.entity.statistics.receipts.title',
  payables:
    'organisation.reporting.page.entities.entity.statistics.payables.title',
};
