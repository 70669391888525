import { Map } from 'src/core/common/components/Map';
import { PanelCard, PanelItemsSection } from 'src/core/common/components/Panel';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { MileageRequestComputationContentSchemeExplanation } from 'src/core/modules/requests/mileage/shared/MileageRequestComputationContentSchemeExplanation';

import { getMileageItems } from '../../../prepare-payables/components/PreparePayablesInbox/components/PreparePayablesEditorForm/helpers';
import type { Payable } from '../../containers/PayablePanelContainer';
import { type PayableType } from '../../models/payable';

type Props = {
  payable: Payable;
};

export const MileageSection = ({ payable }: Props) => {
  const { t, activeLanguage, localeFormat } = useTranslation('global');

  const isMileagePayable = getIsMileagePayable(payable.type);

  if (!isMileagePayable || !payable.mileageDetails) {
    return <></>;
  }

  return (
    <>
      <PanelCard
        content={
          <Map
            lang={activeLanguage}
            path={payable.mileageDetails.journey}
            isInteractive={false}
          />
        }
        items={[
          {
            label: payable.mileageDetails.departureAddress,
            icon: 'map-pin',
          },
          {
            label: payable.mileageDetails.arrivalAddress,
            icon: 'flag-checkered',
          },
        ]}
      />
      <PanelItemsSection
        title={t('payables.panel.mileage.title')}
        items={[
          ...getMileageItems({
            vehicle: payable.mileageDetails?.vehicle,
            scheme: payable.mileageDetails?.scheme,
            travelDate: payable.mileageDetails?.travelDate,
            totalDistanceOverYear:
              payable.mileageDetails?.vehicle?.totalDistanceOverYear,
            passengers: payable.mileageDetails?.passengers,
            t,
            localeFormat,
          }),
        ]}
        footer={
          <MileageRequestComputationContentSchemeExplanation
            className="-mt-8"
            schemeType={reshapePayableMileageSchemeType(
              payable.mileageDetails?.scheme.type,
            )}
          />
        }
      />
    </>
  );
};

export const reshapePayableMileageSchemeType = (
  schemeType: 'french' | 'german' | 'uk' | 'custom' | undefined,
) => {
  switch (schemeType) {
    case 'french':
      return 'FR';
    case 'german':
      return 'DE';
    case 'uk':
      return 'UK';
    case 'custom':
      return 'FX';
    default:
      return undefined;
  }
};

const getIsMileagePayable = (type: PayableType) => {
  return type === 'mileageAllowance';
};
