/* eslint-disable unicorn/no-await-expression-member */
import { Skeleton } from '@dev-spendesk/grapes';

import { EmptyStateError, lazyLoad } from 'src/core/common/components/LazyLoad';

export const LazySFSUKRevealPinContent = lazyLoad({
  loader: async () => ({
    default: (
      await import(
        /* webpackChunkName: "SFSUKRevealPinContent", webpackPrefetch: true */ './SFSUKRevealPinContent'
      )
    ).SFSUKRevealPinContent,
  }),
  loading: (
    <div className="relative flex h-full w-full flex-col items-center justify-center text-[56px] text-brand-default">
      <Skeleton height="56px" width="100px" />
    </div>
  ),
  fallback: (props) => <EmptyStateError {...props} />,
});
