import { Avatar, Button, Label, ListBox, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { useModal } from 'common/hooks/useModal';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';

import {
  type ApprovalPolicy,
  getReadableApprovalPolicy,
} from '../../approvalPolicy';
import { getMemberLabel, type Member } from '../../member';
import { getReadableSpendingTypes } from '../../spendingTypes';

import './PreviewApprovalPolicyModal.css';

type Props = {
  policy: ApprovalPolicy;
  policyMembers: Member[];
  onEditPolicy(): void;
  onDelete(): void;
  onCancel(): void;
};

export const PreviewApprovalPolicyModal = ({
  policy,
  policyMembers,
  onEditPolicy,
  onDelete,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const [isPolicyModalOpened, setIsPolicyModalOpened] = useState(true);
  const companyCurrency = useCompanyCurrency();

  const [
    deletionConfirmationModal,
    showDeletionConfirmationModal,
    hideDeletionConfirmationModal,
  ] = useModal(() => (
    <Modal
      isOpen
      iconName="cross"
      iconVariant="alert"
      title={t('approvalPolicy.modal.deleteTitle')}
      actions={[
        <Button
          key="cancel-button"
          className="PreviewApprovalPolicyModal__button"
          variant="secondaryNeutral"
          text={t('approvalPolicy.modal.cancel')}
          onClick={() => {
            hideDeletionConfirmationModal();
            setIsPolicyModalOpened(true);
          }}
        />,
        <Button
          key="delete-button"
          className="PreviewApprovalPolicyModal__button"
          variant="primaryAlert"
          text={t('approvalPolicy.modal.deleteAnyways')}
          onClick={() => onDelete()}
        />,
      ]}
    >
      {t('approvalPolicy.modal.deleteDesc', { policyName: policy.name })}
    </Modal>
  ));

  return (
    <>
      {deletionConfirmationModal}
      <Modal
        iconName="shield-star"
        iconVariant="info"
        isOpen={isPolicyModalOpened}
        title={t('approvalPolicy.modal.policyName', {
          policyName: policy.name,
        })}
        onClose={onCancel}
      >
        <div className="PreviewApprovalPolicyModal__field">
          <Label label={t('approvalPolicy.modal.details')} />
          <div className="PreviewApprovalPolicyModal__field__policy body-m">
            {getReadableApprovalPolicy({
              approvalNeeded: policy.params.approvalNeeded,
              transactionMax: policy.params.amountPerTransaction ?? 0,
              spendingLimit: policy.params.amountPerMonth ?? 0,
              companyCurrency,
              translator: t,
            })}
            .
            {policy.params.approvalNeeded === 'sometimes' && (
              <>
                {' '}
                <Trans
                  i18nKey={t('approvalPolicy.modal.applyOnSpendingTypes')}
                  values={{
                    spendingTypes: getReadableSpendingTypes(
                      policy.params.spendingTypes,
                      t,
                    ),
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className="mt-24 text-left">
          <Label
            className="mb-8"
            label={t('approvalPolicy.members.assigned', {
              count: policyMembers.length,
            })}
          />
          <ListBox
            className="max-h-[270px] overflow-y-auto"
            rowHeight="compact"
            emptyState={{ title: t('approvalPolicy.members.noMembers') }}
            getOptionId={(option) => option.id}
            options={policyMembers}
          >
            {(policyMember) => (
              <div className="flex items-center gap-8">
                <Avatar
                  src={policyMember.avatar}
                  text={
                    policyMember.isPending
                      ? policyMember.email
                      : (policyMember.fullname ?? '')
                  }
                />
                <div>{getMemberLabel(policyMember)}</div>
              </div>
            )}
          </ListBox>
        </div>
        <div className="mt-24">
          <Button
            fit="parent"
            className="PreviewApprovalPolicyModal__button"
            variant="primaryBrand"
            text={t('approvalPolicy.modal.editPolicy')}
            onClick={onEditPolicy}
          />
          {policy.isDefault ? (
            <div className="mt-16 text-primary body-m">
              {t('approvalPolicy.modal.cantDelete')}
            </div>
          ) : (
            <Button
              fit="parent"
              iconPosition="left"
              iconName="trash"
              variant="tertiaryNeutral"
              text={t('approvalPolicy.modal.deletePolicy', {
                policyName: policy.name,
              })}
              onClick={() => {
                setIsPolicyModalOpened(false);
                showDeletionConfirmationModal();
              }}
              className="mt-16"
            />
          )}
        </div>
      </Modal>
    </>
  );
};
