import { Modal, Button, ModalFooter } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { type I18nKey, useTranslation } from 'common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import { ReceiptImage } from './ReceiptImage';
import styles from './ValidReceiptModal.module.css';
import checkIconSource from './check-icon.svg';

type Step = 'one' | 'two';

type Props = {
  company: {
    id: string;
  };
  firstUserPaymentId?: string | null;
};

const CHECKBOXES: I18nKey[] = [
  'payments.validReceipt.steps.one',
  'payments.validReceipt.steps.two',
  'payments.validReceipt.steps.three',
  'payments.validReceipt.steps.four',
  'payments.validReceipt.steps.five',
  'payments.validReceipt.steps.six',
];

export const ValidReceiptModal = ({
  company,
  firstUserPaymentId = null,
}: Props) => {
  const { t } = useTranslation('global');

  const [step, setStep] = useState<Step>('one');
  const [isVisible, setIsVisible] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);

  const renderStep = (stepToRender: Step) => {
    switch (stepToRender) {
      case 'one':
        return renderStepOne();
      case 'two':
        return renderStepTwo();
      default:
        throw new Error('Unable to find a valid step:', stepToRender);
    }
  };

  const renderStepOne = () => (
    <ModalFooter>
      <Button text={t('payments.validReceipt.btnNext')} onClick={goToStepTwo} />
    </ModalFooter>
  );

  const renderStepTwo = () => {
    const fakeCheckboxElt = (
      <span className={styles.fakeCheckbox}>
        <img
          className={styles.fakeCheckboxImg}
          src={checkIconSource}
          alt="check-icon"
        />
      </span>
    );

    const link = firstUserPaymentId
      ? routeFor(routes.PAYMENTS_ALL.path, {
          company: company.id,
          id: firstUserPaymentId,
        })
      : routeFor(routes.PAYMENTS_ALL.path, { company: company.id });

    return (
      <>
        <div className="mt-24 flex items-center gap-16 text-left">
          <div
            className={cx('flex-1', {
              [styles.animatedReceipt]: isAnimating,
            })}
          >
            <ReceiptImage isAnimating={isAnimating} />
          </div>
          <ul
            className={cx('flex flex-1 flex-col gap-16', {
              [styles.animatedChecklist]: isAnimating,
            })}
          >
            {CHECKBOXES.map((checkbox) => (
              <li className="relative pl-4" key={checkbox}>
                {fakeCheckboxElt} {t(checkbox)}
              </li>
            ))}
          </ul>
        </div>
        <button
          className={styles.replayButton}
          type="button"
          onClick={() => {
            setIsAnimating(false);
            setTimeout(() => {
              setIsAnimating(true);
            }, 600);
          }}
        >
          {t('payments.validReceipt.btnReplayAnimation')}
        </button>
        <ModalFooter>
          <Link to={link} onClick={close}>
            <Button text={t('payments.validReceipt.btnRedirect')} />
          </Link>
        </ModalFooter>
      </>
    );
  };

  const goToStepTwo = () => {
    setStep('two');
    setTimeout(() => {
      setIsAnimating(true);
    }, 600);
  };

  const close = () => {
    setIsVisible(false);
  };

  return (
    <Modal
      isOpen={isVisible}
      title={
        step === 'one'
          ? t('payments.validReceipt.title')
          : t('payments.validReceipt.subtitle')
      }
      subtitle={
        step === 'one'
          ? `${t('payments.validReceipt.description1')} ${t(
              'payments.validReceipt.description2',
            )}`
          : undefined
      }
      iconName="sparkle"
      iconVariant="success"
      onClose={close}
    >
      {renderStep(step)}
    </Modal>
  );
};
