import { Button, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import downloadqrcode from './assets/downloadqrcode.png';

export const HELP_CENTER_SPANSIH_DPR_LINK =
  'https://helpcenter.spendesk.com/articles/9540095';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SpanishDPRModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      illustration={<img src={downloadqrcode} alt="" className="h-[336px]" />}
      illustrationHeight="336px"
      isOpen={isOpen}
      onClose={onClose}
      title={t('requests.panel.spanishDPR.modalTitle')}
      actions={
        <>
          <Button
            component="a"
            // TODO: Update the article link
            href={HELP_CENTER_SPANSIH_DPR_LINK}
            rel="noreferrer noopener"
            target="_blank"
            text={t('requests.panel.spanishDPR.modalCta')}
            variant="primaryBrand"
          />
        </>
      }
    >
      {t('requests.panel.spanishDPR.modalContent')}
    </Modal>
  );
};
