import { FormField, Input, TextInput } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { type AppDispatch } from 'modules/app/redux/store';
import { useUpdateCachedTaskStatus } from 'modules/onboarding/setup-hub/pages/SetupHubPage/hooks/useUpdateCachedTaskStatus';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import {
  NotificationType,
  useNotifications,
} from 'src/core/modules/app/notifications';
import { DayDropdown } from 'src/core/modules/company/members/components/ControlRules/components/DayDropdown/DayDropdown';
import { createControlRule } from 'src/core/modules/company/members/components/ControlRules/redux/thunks';

import { ControlRulePreview } from './components/ControlRulePreview';
import { MultipleMemberAutocomplete } from './components/MultipleMemberAutocomplete';
import { validate } from './form';
import { type FormValues } from './types';
import { SetupHubLayout } from '../../../components/SetupHubLayout';
import { routes } from '../../routes';

export const ControlSettingsControlRules = () => {
  const { t } = useTranslation('global');
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { pushNotif } = useNotifications();
  const updateCachedTaskStatus = useUpdateCachedTaskStatus();

  const companyId = useCompanyId();

  const [hasSubmittedOnce, setHasSubmittedOnce] = useState<boolean>(false);

  const goBackToHub = () => {
    const hubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(hubPath);
  };

  const { errors, values, handleChange, handleSubmit, setFieldValue } =
    useFormik<FormValues>({
      initialValues: {
        name: '',
        incompletePaymentsLimit: 3,
        completionDeadline: 7,
        userIds: [],
      },
      validate: (valuesToValidate) => validate(valuesToValidate, t),
      validateOnChange: hasSubmittedOnce,
      onSubmit: async (controlRuleValues) => {
        try {
          await dispatch(createControlRule(controlRuleValues));
          updateCachedTaskStatus('control_rules');
          goBackToHub();
        } catch {
          pushNotif({
            type: NotificationType.Danger,
            message: t('misc.errors.unknownError'),
          });
        }
      },
    });

  const onContinue = async () => {
    if (!hasSubmittedOnce) {
      setHasSubmittedOnce(true);
    }
    handleSubmit();
  };

  return (
    <SetupHubLayout
      onBack={goBackToHub}
      onNext={onContinue}
      rightContent={
        <ControlRulePreview
          completionDeadline={values.completionDeadline}
          incompletePaymentsLimit={values.incompletePaymentsLimit}
        />
      }
      scope="control-rules"
      title={t('setupHub.controlRules.title')}
    >
      <>
        <p className="mb-40 leading-normal text-secondary-bg-primary body-l">
          <Trans i18nKey="setupHub.controlRules.subtitle">
            <a
              className="text-secondary-bg-primary underline"
              href="https://helpcenter.spendesk.com/articles/4240924#h_c635b9a932"
              target="_blank"
              rel="noreferrer"
            >
              -
            </a>
          </Trans>
        </p>
        <form
          className="grid grid-cols-2 gap-x-16 gap-y-24"
          onSubmit={handleSubmit}
        >
          <FormField
            className="col-span-2"
            label={t('controlRulesModal.nameLabel')}
            alertMessage={errors.name}
          >
            <TextInput
              name="name"
              onChange={handleChange}
              placeholder={t('controlRulesModal.namePlaceholder')}
              value={values.name}
            />
          </FormField>
          <FormField
            label={t('controlRulesModal.limitLabel')}
            alertMessage={errors.incompletePaymentsLimit}
          >
            <Input
              type="number"
              min={1}
              value={values.incompletePaymentsLimit}
              onChange={(event) => {
                const value = event.target.valueAsNumber;
                if (!Number.isNaN(value)) {
                  setFieldValue('incompletePaymentsLimit', value);
                } else {
                  setFieldValue('incompletePaymentsLimit', undefined);
                }
              }}
            />
          </FormField>
          <FormField label={t('controlRulesModal.delayLabel')}>
            <DayDropdown
              value={values.completionDeadline}
              onSelect={(days) => setFieldValue('completionDeadline', days)}
            />
          </FormField>
          <FormField
            className="col-span-2"
            label={t('controlRulesModal.assignMembersToControlRule')}
            alertMessage={errors.userIds as string | undefined}
          >
            <MultipleMemberAutocomplete
              onChange={(ids) => setFieldValue('userIds', ids)}
              values={values.userIds}
            />
          </FormField>
        </form>
      </>
    </SetupHubLayout>
  );
};
