import { Button } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSessionStorage } from 'react-use';

import { type AppDispatch } from 'modules/app/redux/store';
import {
  useInvalidateScreeningInformationStatusQuery,
  useScreeningInformationStatusQuery,
} from 'modules/members/hooks/useScreeningInformationStatusQuery';
import { useUpdateProfileMutation } from 'modules/profile/hooks';
import { updateMeLocally } from 'src/core/actions/users';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import { ScreeningForm } from './ScreeningForm';
import tomtalker from './tomtalker.svg';

export const MemberScreeningModal = () => {
  const { t } = useTranslation('global');
  const user = useUser();
  const company = useCompany();
  const [isOpen, setIsOpen] = useState(false);
  const [remindMeLater, setRemindMeLater] = useSessionStorage(
    'screeningRemind',
    false,
  );

  const dispatch = useDispatch<AppDispatch>();
  const [updateProfileMutation] = useUpdateProfileMutation();
  const screeningInformationStatusQueryState =
    useScreeningInformationStatusQuery({
      userId: user.id,
    });

  const invalidateScreeningInformationStatusQuery =
    useInvalidateScreeningInformationStatusQuery(user.id);

  useEffect(() => {
    if (
      screeningInformationStatusQueryState.status === 'success' &&
      screeningInformationStatusQueryState.data.status === 'pending' &&
      company.banking_provider === 'sfs' &&
      user.has_plastic_card
    ) {
      setIsOpen(true);
    }
  }, [screeningInformationStatusQueryState]);

  if (!isOpen || remindMeLater) {
    return null;
  }

  return createPortal(
    <div className="fixed inset-0 z-[9001] bg-primary-default">
      <div className="flex h-full">
        <div className="flex h-full w-[50%] min-w-[450px] flex-col justify-center overflow-auto p-48 min-[1440px]:w-[40%]">
          <div className="mb-40 flex min-h-0 items-center">
            <img height="24" src="/static/img/logo.svg" alt="spendeskLogo" />
            <span className="ml-16 text-primary body-m">
              {t('members.screeningModal.security')}
            </span>
          </div>
          <h1 className="text-[36px] font-semibold leading-10 text-primary">
            {t('members.screeningModal.title')}
          </h1>
          <p className="mb-40 mt-24 text-primary body-l">
            <Trans
              i18nKey="members.screeningModal.subtitle"
              components={{
                a: (
                  <a
                    href="https://helpcenter.spendesk.com/articles/8866182"
                    target="_blank"
                    rel="noreferrer"
                  >
                    -
                  </a>
                ),
              }}
            />
          </p>
          <ScreeningForm
            user={user}
            onSuccess={async (data) => {
              await updateProfileMutation(data);
              dispatch(updateMeLocally(data));
              invalidateScreeningInformationStatusQuery();
              setIsOpen(false);
            }}
            onError={() => {
              setIsOpen(false);
            }}
            renderButtons={(isLoading, onClick) => (
              <>
                <Button
                  variant="secondaryNeutral"
                  text={t('misc.remindMeLater')}
                  onClick={() => {
                    setRemindMeLater(true);
                  }}
                  isDisabled={isLoading}
                />
                <Button
                  text={t('misc.saveAndContinue')}
                  onClick={onClick}
                  isLoading={isLoading}
                />
              </>
            )}
          />
        </div>
        <div className="flex h-full w-[50%] items-center justify-center bg-[#F1F3FF] min-[1440px]:w-[60%]">
          <img
            src={tomtalker}
            alt=""
            className="max-h-[80%] min-h-0 w-[60%] min-w-0"
          />
        </div>
      </div>
    </div>,
    document.body,
  );
};
