import { GrapesProvider } from '@dev-spendesk/grapes';
import { useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useSupervisor } from 'modules/app/hooks/useSupervisor';
import { useUser } from 'modules/app/hooks/useUser';
import { OnboardingHubLayoutContainer } from 'modules/app/layout/OnboardingHubLayoutContainer';
import {
  addNotification,
  type NotificationPayload,
  NotificationsContextProvider,
  NotificationsStack,
} from 'modules/app/notifications';
import { useIsOnboardingHubPath } from 'modules/onboarding/onboarding-hub/hooks/useIsOnboardingHubPath';
import { CookieBanner } from 'src/core/common/components/Cookies';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { mapboxgl } from 'src/core/config';
import { getRegionalizedLocale } from 'src/core/config/i18n';
import { routes } from 'src/core/constants/routes';
import type { AppDispatch } from 'src/core/modules/app/redux/store';
import { trackPage } from 'src/core/utils/analytics';
import { reportCoreWebVitals } from 'src/core/utils/analytics/performances';
import { findMatchingRoute } from 'src/core/utils/urlParser';

import { PaidLayoutContainer } from './PaidLayoutContainer';
import { ClosedDoorLayoutContainer } from '../../billing/ClosedDoorPage/ClosedDoorLayoutContainer';
import { useIsClosedDoorPath } from '../../billing/hooks/useIsClosedDoorPath';

import './LayoutContainer.css';

export const LayoutContainer = () => {
  const company = useCompany();
  const user = useUser();
  const supervisor = useSupervisor();
  const dispatch = useDispatch<AppDispatch>();
  const pushNotif = (notification: NotificationPayload) =>
    dispatch(addNotification(notification));

  const { t, i18n, activeLanguage } = useTranslation('global');
  const location = useLocation();

  const isOnboardingHubPath = useIsOnboardingHubPath();
  const isClosedDoorPath = useIsClosedDoorPath();

  useLayoutEffect(() => {
    document.getElementById('global-loader')?.classList.remove('active');
  }, []);

  useLayoutEffect(() => {
    document.documentElement.lang = activeLanguage;
  }, [activeLanguage]);

  const previousLocation = usePrevious(location);

  useEffect(() => {
    if (previousLocation?.pathname !== location.pathname) {
      trackPage({
        location,
        prevLocation: previousLocation,
        company,
        project: 'App',
      });
    }
  }, [location.pathname, previousLocation]);

  useEffect(() => {
    const routePath = findMatchingRoute({
      location,
      routes,
    })?.path;

    if (routePath) {
      reportCoreWebVitals({
        path: routePath,
        companyId: company.id,
      });
    }
  }, []);

  const getLayoutContainer = () => {
    if (isOnboardingHubPath) {
      return <OnboardingHubLayoutContainer />;
    }
    return <PaidLayoutContainer />;
  };

  return (
    <NotificationsContextProvider value={pushNotif}>
      <GrapesProvider
        locale={getRegionalizedLocale(i18n)}
        mapboxAccessToken={mapboxgl.accessToken}
        localesDefinition={{
          fallbackLocale: 'i18n',
          locales: {
            i18n: {
              cancel: t('misc.cancel'),
              close: t('misc.close'),
              nextMonth: t('misc.next'),
              previousMonth: t('misc.back'),
              openCalendar: t('misc.view'),
              hide: t('misc.hide'),
              show: t('misc.show'),
              showOptions: t('misc.showOptions'),
              edit: t('misc.edit'),
              clearSelection: t('misc.clear'),
              selectCurrency: t('misc.selectCurrency'),
              deleteWithName: ({ name }) => t('misc.deleteWithName', { name }),
            },
          },
        }}
      >
        {isClosedDoorPath ? (
          <ClosedDoorLayoutContainer />
        ) : (
          <div className="LayoutContainer">
            <NotificationsStack />
            {getLayoutContainer()}
            <CookieBanner
              user={user}
              company={company}
              supervisor={supervisor}
            />
          </div>
        )}
      </GrapesProvider>
    </NotificationsContextProvider>
  );
};
