import { type Node, type Edge } from '@xyflow/react';
import React, { useState, useCallback, type ReactNode } from 'react';
import { v4 as uuid } from 'uuid';

import { WorkflowContext } from './WorkflowContext';
import { makeTreeLayout } from './helpers/makeTreeLayout';
import { type CustomNode } from './node';

export const WorkflowProvider = ({ children }: { children: ReactNode }) => {
  const [nodes, setNodesState] = useState<CustomNode[]>([]);
  const [edges, setEdgesState] = useState<Edge[]>([]);
  const [selectedNodeId, setSelectedNodeIdState] = useState<string | null>(
    null,
  );
  const [selectedNodeLevel, setSelectedNodeLevel] = useState<number | null>(
    null,
  );

  const setNodes = useCallback((ns: Node[]) => {
    setNodesState(ns as CustomNode[]);
  }, []);

  const setEdges = useCallback((es: Edge[]) => {
    setEdgesState(es);
  }, []);

  const setSelectedNodeId = useCallback(
    (nodeId: string | null) => {
      setSelectedNodeIdState(nodeId);
      if (nodeId) {
        const level = getNodeLevel(nodeId, edges);
        setSelectedNodeLevel(level);
      }
    },
    [edges],
  );

  const createAndConnectNode = useCallback(
    ({
      sourceNodeId,
      shouldSelectNewNode = true,
    }: {
      sourceNodeId: string;
      shouldSelectNewNode?: boolean;
    }) => {
      const newNode: CustomNode = {
        id: `node-${uuid()}`,
        type: 'baseNode',
        data: {
          schemeId: undefined,
          dimensionName: undefined,
          values: undefined,
        },
        position: { x: 0, y: 0 },
      };

      setNodesState((nds) => {
        const updatedNodes = [...nds, newNode];
        setEdgesState((eds) => [
          ...eds,
          {
            id: `edge-${uuid()}`,
            source: sourceNodeId,
            target: newNode.id,
            type: 'labelEdge',
            data: {
              labelText: 'If',
              labelVariant: 'purple',
            },
          },
        ]);
        return updatedNodes;
      });

      if (shouldSelectNewNode) {
        setSelectedNodeIdState(newNode.id);
      }
    },
    [],
  );

  const deleteNode = useCallback(
    (nodeId: string) => {
      const descendantNodeIds = findDescendantNodes(nodeId, nodes, edges);
      setNodesState((nds) =>
        nds.filter((node) => !descendantNodeIds.includes(node.id)),
      );
      setEdgesState((eds) =>
        eds.filter(
          (edge) =>
            !descendantNodeIds.includes(edge.source) &&
            !descendantNodeIds.includes(edge.target),
        ),
      );
    },
    [nodes, edges],
  );

  const { layoutedEdges, layoutedNodes } = makeTreeLayout(nodes, edges);

  return (
    <WorkflowContext.Provider
      value={{
        nodes: layoutedNodes,
        edges: layoutedEdges,
        selectedNodeId,
        selectedNodeLevel,
        setNodes,
        setEdges,
        setSelectedNodeId,
        deleteNode,
        createAndConnectNode,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};

const getNodeLevel = (nodeId: string, edges: Edge[]): number => {
  const parentEdge = edges.find((edge) => edge.target === nodeId);
  return parentEdge ? 1 + getNodeLevel(parentEdge.source, edges) : 0;
};

const findDescendantNodes = (
  nodeId: string,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  nodes: CustomNode[],
  // eslint-disable-next-line @typescript-eslint/no-shadow
  edges: Edge[],
): string[] => {
  const childEdges = edges.filter((edge) => edge.source === nodeId);
  const childNodeIds = childEdges.map((edge) => edge.target);
  const allDescendantNodeIds = childNodeIds.flatMap((childNodeId) =>
    findDescendantNodes(childNodeId, nodes, edges),
  );
  return [nodeId, ...allDescendantNodeIds];
};
