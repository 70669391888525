import { AutocompleteMultiple, SkeletonText } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useCostCentersQuery } from 'src/core/modules/budgets/apis';

export const CostCenterSelect = ({
  selectedValues,
  onSelect,
}: {
  selectedValues: string[] | undefined;
  onSelect: (values: string[]) => void;
}) => {
  const costCentersQueryState = useCostCentersQuery();
  const [options, setOptions] = useState(
    costCentersQueryState.status === 'success'
      ? getCostCenterOptions(costCentersQueryState.data)
      : [],
  );

  return (
    <QuerySuspense
      queryState={costCentersQueryState}
      loading={<SkeletonText size="xxl" />}
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
    >
      {(costCenters) => {
        const costCenterOptions = getCostCenterOptions(costCenters);
        return (
          <AutocompleteMultiple
            fit="content"
            className="w-full"
            options={options}
            values={costCenterOptions.filter((option) =>
              selectedValues?.includes(option.key),
            )}
            translations={{
              selectAll: `Select all (${options.length} cost centers)`,
              selected: selectedValues
                ? selectedValues
                    .map(
                      (value) =>
                        costCenterOptions.find((option) => option.key === value)
                          ?.label,
                    )
                    .join(', ')
                : '',
            }}
            onSearch={(value) => {
              if (!value) {
                setOptions(costCenterOptions);
                return;
              }
              setOptions(
                costCenterOptions.filter((option) =>
                  option.label.toLowerCase().includes(value.toLowerCase()),
                ),
              );
            }}
            onSelect={(values) => {
              onSelect(values.map((value) => value.key));
            }}
            placeholder="Select a cost center"
          />
        );
      }}
    </QuerySuspense>
  );
};

const getCostCenterOptions = (costCenters: { id: string; name: string }[]) => {
  return costCenters.map((costCenter) => ({
    key: costCenter.id,
    label: costCenter.name,
  }));
};
