import { Button } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { type I18nKey, useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { HubTaskWrapper } from 'modules/onboarding/components/HubTaskWrapper';
import { type HubTask } from 'modules/onboarding/types/hubTask';
import { LocalStorageKey } from 'src/core/constants/storage';
import { MarkAsDoneButton } from 'src/core/modules/onboarding/components/MarkAsDoneButton';
import { setLocalItem } from 'src/core/utils/storage';

import { getContentByTaskType, getTaskUrl } from './utils';

type Props = {
  task: HubTask;
  sectionId: string;
};

export const HubTaskItem = ({ task, sectionId }: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const history = useHistory();

  const content = getContentByTaskType(task.type);
  const openTaskCta: I18nKey =
    task.status === 'completed'
      ? 'setupHub.sections.controlSettings.reviewCta'
      : 'setupHub.sections.controlSettings.startCta';

  if (!content) {
    return;
  }

  const openTask = () => {
    setLocalItem(LocalStorageKey.LastOnboardingSectionOpened, sectionId, true);
    const taskUrl = getTaskUrl(task, company.id);
    history.push(
      task.status === 'completed' ? taskUrl.completedUrl : taskUrl.ongoingUrl,
    );
  };

  return (
    <HubTaskWrapper
      title={t(content.title)}
      description={t(content.description)}
      task={task}
      actions={
        task.status !== 'disabled'
          ? [
              <MarkAsDoneButton
                task={task}
                key="mark-as-done"
                text={t('misc.skip')}
              />,
              <Button
                variant={
                  task.status === 'ongoing'
                    ? 'primaryBrand'
                    : 'secondaryNeutral'
                }
                onClick={openTask}
                text={t(openTaskCta)}
                key="open"
              />,
            ]
          : []
      }
    />
  );
};
