import { Avatar, Button, Icon, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type Integration } from '../../../types';
import {
  getIntegrationLogo,
  getSpendeskLogo,
  getIntegrationName,
} from '../../../utils';
import { IntegrationBenefits } from '../IntegrationBenefits';
import { IntegrationHelpCenter } from '../IntegrationHelpCenter';

type Props = {
  integration: Integration;
  closeModal: () => void;
};

export const PreRedirectionModal = ({ integration, closeModal }: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      illustrationHeight="90px"
      illustration={
        <div className="mt-32 flex flex-row items-center gap-16">
          <Avatar
            variant="square"
            className="flex"
            src={getIntegrationLogo(integration.id)}
            text="Title (to trad)"
            size="xl"
          />
          <Icon className="flex" size="l" name="arrow-left-right" />
          <Avatar
            variant="square"
            className="flex"
            src={getSpendeskLogo()}
            text="Title (to trad)"
            size="xl"
          />
        </div>
      }
      title={t('integration.connection.title', {
        name: getIntegrationName(t, integration.id),
      })}
      onClose={closeModal}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={closeModal}
        />,
        <Button
          key="continue"
          variant="primaryBrand"
          text={t('misc.continue')}
          onClick={() => {
            window.location.href = integration.redirectionUrl;
          }}
        />,
      ]}
    >
      <div className="flex flex-col gap-32">
        <span className="text-secondary-bg-primary">
          {t('integration.connection.subtitle', {
            name: getIntegrationName(t, integration.id),
          })}
        </span>
        <div className="flex flex-col gap-32">
          <IntegrationBenefits integration={integration} sizeTitle="title-m" />
          <IntegrationHelpCenter integration={integration} />
        </div>
      </div>
    </Modal>
  );
};
