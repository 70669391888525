import { Badge } from '@dev-spendesk/grapes';
import classnames from 'classnames';

import { useTranslation } from 'common/hooks/useTranslation';

export type Props = {
  value: string | null;
  strike?: boolean;
  className?: string;
};

export const SupplierChangeFieldValue = ({
  className,
  value,
  strike = false,
}: Props) => {
  const { t } = useTranslation('global');

  const fieldValue =
    value && value !== ''
      ? value
      : t('suppliers.latestUpdater.historyModal.emptyValue');

  return strike ? (
    <Badge
      variant="secondary"
      className={classnames('p-8 line-through', className)}
    >
      {fieldValue}
    </Badge>
  ) : (
    <Badge
      variant="primary"
      className={classnames('p-8 text-primary', className)}
    >
      {fieldValue}
    </Badge>
  );
};
