import React from 'react';

import { PaymentsBatchesLoader } from 'modules/invoices/components/PaymentsBatchesList/PaymentsBatchesLoader';

export const HistoryPageSkeleton = () => {
  return (
    <div className="mt-48 flex justify-center">
      <PaymentsBatchesLoader count={2} />
    </div>
  );
};
