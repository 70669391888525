import { PageModal } from '@dev-spendesk/grapes';

import { type VideoPageModalProps } from './types';

export const VideoPageModal = ({
  isOpen,
  onClose,
  title,
  url,
}: VideoPageModalProps) => {
  return (
    <PageModal isOpen={isOpen} onClose={onClose} title={title}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        className="h-[90%] w-full"
        controls
        playsInline
        disablePictureInPicture
      >
        <source src={url} type="video/mp4" />
      </video>
    </PageModal>
  );
};
