import { Button, Link, Modal } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import { useState } from 'react';
import { connect } from 'react-redux';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import FEATURES from 'src/core/constants/features';
import { type AppState } from 'src/core/reducers';

import { useValidateControlRuleMutation } from './useValidateControlRuleMutation';
import { CreateControlRule } from '../../components/CreateControlRule';
import { RuleMembers } from '../../components/RuleMembers';
import { type ControlRuleRequest } from '../../controlRule';
import { getEmailPreviewLink } from '../../email';
import { type Member } from '../../member';
import { getMembers } from '../../redux/membersSelector';
import * as thunks from '../../redux/thunks';
import { setErrors, validateForm } from '../../validator';

type Props = {
  members: Member[];
  isOpen: boolean;
  onSuccess(): void;
  onCancel(): void;
  createControlRule(request: ControlRuleRequest): void;
};

type FormValues = {
  name: string;
  limit: number;
  delay: number;
  isDefault: boolean;
  memberIds: string[];
};

type UiState = 'configureDetails' | 'assignMembers';

const Container = ({
  members,
  onSuccess,
  onCancel,
  createControlRule,
  isOpen,
}: Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const [uiState, setUiState] = useState<UiState>('configureDetails');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasPlayByTheRulesReceiptAndFieldsFeature = useFeature(
    FEATURES.PLAY_BY_THE_RULES_RECEIPT_AND_FIELDS,
  );
  const [validateControlRule] = useValidateControlRuleMutation();
  const { pushNotif } = useNotifications();
  const initialValues = {
    name: '',
    limit: 5,
    delay: 5,
    isDefault: false,
    memberIds: [],
  };

  const formikProps = useFormik<FormValues>({
    initialValues,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    validate: (values) => validateForm({ values }, t),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      try {
        await validateControlRule({
          name: values.name,
          completionDeadline: values.delay,
          incompletePaymentsLimit: values.limit,
        });
        setUiState('assignMembers');
      } catch (error) {
        const errorMessage = error.response?.data;
        if (errorMessage) {
          setErrors(setFieldError, errorMessage, t);
        } else {
          pushNotif({
            type: NotificationType.Danger,
            message: t('controlRules.create.validationError'),
          });
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleOnSubmit = async () => {
    const { values } = formikProps;
    // the values have already been validated by the Formik validation during
    // the first step of the creation flow
    await createControlRule({
      name: values.name,
      completionDeadline: values.delay,
      incompletePaymentsLimit: values.limit,
      userIds: values.memberIds,
    });
    onSuccess();
    pushNotif({
      type: NotificationType.Success,
      message: t('controlRules.create.success'),
    });
  };

  if (uiState === 'configureDetails') {
    return (
      <Modal
        isOpen={isOpen}
        title={
          hasPlayByTheRulesReceiptAndFieldsFeature
            ? t('controlRules.mandatoryFields.create.title')
            : t('controlRules.create.title')
        }
        subtitle={
          hasPlayByTheRulesReceiptAndFieldsFeature
            ? t('controlRules.mandatoryFields.create.description')
            : t('controlRulesModal.disclaimer')
        }
        iconName="card-lock-close"
        iconVariant="purple"
        actions={[
          <Button
            key="cancel"
            text={t('misc.cancel')}
            variant="secondaryNeutral"
            onClick={onCancel}
          />,
          <Button
            key="next"
            text={t('misc.next')}
            variant="primaryBrand"
            iconName="chevron-right"
            iconPosition="right"
            isDisabled={formikProps.isSubmitting}
            onClick={formikProps.submitForm}
          />,
        ]}
      >
        <CreateControlRule {...formikProps} />
      </Modal>
    );
  }

  if (uiState === 'assignMembers') {
    return (
      <Modal
        isOpen={isOpen}
        className="text-right"
        title={t('controlRules.create.title')}
        subtitle={t('controlRules.editDescription')}
        iconName="card-lock-close"
        iconVariant="info"
        actions={[
          <Button
            key="back"
            variant="secondaryNeutral"
            iconName="chevron-left"
            iconPosition="left"
            text={t('controlRulesModal.back')}
            onClick={() => setUiState('configureDetails')}
          />,
          <Button
            key="submit"
            variant="primaryBrand"
            text={t('controlRulesModal.submit')}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={async () => {
              setIsSubmitting(true);
              try {
                await handleOnSubmit();
                setUiState('configureDetails');
              } catch {
                pushNotif({
                  message: t('controlRulesModal.submitError'),
                  type: NotificationType.Danger,
                });
              } finally {
                setIsSubmitting(false);
              }
            }}
          />,
        ]}
      >
        <RuleMembers
          selectedMemberIds={formikProps.values.memberIds}
          members={members}
          warningContent={
            formikProps.values.memberIds.length > 0 ? (
              <>
                {t('controlRulesModal.warningCreation', {
                  count: formikProps.values.memberIds.length,
                })}{' '}
                <Link
                  href={getEmailPreviewLink({
                    language: activeLanguage,
                    isRuleCreationMode: true,
                  })}
                  isExternal
                >
                  {t('controlRulesModal.emailPreviewLink')}
                </Link>
              </>
            ) : undefined
          }
          onChange={(memberIds) =>
            formikProps.setFieldValue('memberIds', memberIds)
          }
        />
      </Modal>
    );
  }

  return null;
};

const mapStateToProps = (state: AppState) => ({
  members: getMembers(state),
});

const mapDispatchToProps = {
  createControlRule: thunks.createControlRule,
};

export const CreateControlRuleModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
