import { Button, FormField, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ControlRule } from '../../controlRule';
import { type Member } from '../../member';
import { ListMembers } from '../ListMembers/ListMembers';

type Props = {
  controlRule: ControlRule;
  members: Member[];
  onEditDetailsClick(): void;
  onDelete(): void;
  onClose(): void;
};

export const ControlRuleSummary = ({
  controlRule,
  members,
  onEditDetailsClick,
  onDelete,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      iconName="card"
      iconVariant="purple"
      isOpen
      title={
        controlRule.isInitial
          ? t('controlRules.initialRuleName')
          : controlRule.name
      }
      actions={[]}
      onClose={onClose}
    >
      <div className="flex flex-col gap-24">
        <div className="flex flex-col gap-16">
          <FormField label={t('controlRulesModal.details.labelForRuleDetails')}>
            <div className="flex flex-col content-stretch gap-16 rounded-8 bg-secondary-default p-16">
              <SettingDisplay
                label={t('controlRulesModal.details.limit')}
                current={String(controlRule.incompletePaymentsLimit)}
              />
              <SettingDisplay
                label={t('controlRulesModal.details.deadline')}
                current={t('controlRulesModal.details.deadlineNumber', {
                  count: controlRule.completionDeadline,
                })}
              />
              {controlRule.isInitial ? (
                <SettingDisplay
                  label={t('controlRulesModal.details.autoAssign')}
                  current={controlRule.isDefault ? t('misc.yes') : t('misc.no')}
                />
              ) : null}
            </div>
          </FormField>
          <ListMembers members={members} />
        </div>

        <div className="flex flex-col items-center gap-16">
          <div className="flex items-center gap-16">
            <Button
              variant="secondaryNeutral"
              text={t('misc.cancel')}
              onClick={onClose}
            />
            <Button
              variant="primaryBrand"
              text={t('controlRulesModal.details.editThePolicy')}
              onClick={onEditDetailsClick}
            />
          </div>
          {controlRule.isInitial ? (
            <p className="text-secondary-bg-primary body-s">
              {t('controlRulesModal.details.cannotRemove')}
            </p>
          ) : (
            <Button
              text={t('controlRulesModal.details.delete')}
              variant="primaryAlert"
              onClick={onDelete}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

const SettingDisplay = ({
  label,
  current,
}: {
  label: string;
  current: string;
}) => {
  return (
    <div className="flex items-center">
      <p className="text-secondary-bg-primary body-m">{label}</p>
      <p className="ml-auto text-primary title-m">{current}</p>
    </div>
  );
};
