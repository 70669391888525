import { Button, Modal } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import {
  ApprovalRulesContainer,
  selectors as approvalSelectors,
  type ApprovalRule,
} from 'modules/company/structure/approval-flows';
import { validate as validateRules } from 'modules/company/structure/approval-flows/models';
import { type AppState } from 'src/core/reducers';
import { getUsers } from 'src/core/selectors/users';

type Props = {
  selectedGroupId: string;
  rules: ApprovalRule[];
  members: {
    id: string;
    email: string;
    avatar: string | null;
    fullname: string;
    pending: boolean;
    is_account_owner: boolean;
  }[];
  onSave(
    teamId: string,
    rules: ApprovalRule[],
    successMessage: string,
    errorMessage: string,
  ): void;
  onCancel(): void;
};

const Container = ({
  selectedGroupId,
  rules,
  members,
  onSave,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const [internalRules, setInternalRules] = useState(rules);
  const [erroredRulesIds, setErroredRulesIds] = useState(validateRules(rules));

  useEffect(() => {
    setInternalRules(rules);
    setErroredRulesIds(validateRules(rules));
  }, [rules]);

  const handleSubmit = () => {
    const errors = validateRules(internalRules);
    setErroredRulesIds(errors);
    if (errors.length === 0) {
      onSave(
        selectedGroupId,
        internalRules,
        t('teams.approvalFlows.saveSuccess'),
        t('errors:somethingWrong'),
      );
    }
  };

  return (
    <Modal
      isOpen
      iconName="circle-information"
      iconVariant="info"
      title={t('members.managerApprovers')}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={onCancel}
        />,
        <Button
          key="save"
          text={t('teams.approvalFlows.save')}
          onClick={handleSubmit}
        />,
      ]}
      onClose={onCancel}
    >
      <ApprovalRulesContainer
        rules={internalRules}
        members={members.map((member) => {
          return {
            id: member.id,
            email: member.email,
            avatar: member.avatar,
            fullname: member.fullname,
            isPending: member.pending,
            isAccountOwner: member.is_account_owner,
          };
        })}
        erroredRulesIds={erroredRulesIds}
        onChange={(updatedRules) => {
          setInternalRules(updatedRules);
        }}
      />
    </Modal>
  );
};

const mapStateToProps = (
  state: AppState,
  { selectedGroupId }: Pick<Props, 'selectedGroupId'>,
) => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  rules: approvalSelectors.getRulesByTeamId(state, selectedGroupId),
  members: getUsers(state),
});

export const TeamApprovalRulesModal = connect(mapStateToProps)(Container);
