import { type Filter } from '@spendesk/bookkeeping-core-types';

import { toCoreTypeFilter } from 'modules/payable/hooks/api/payable-filter/payableFilterTransformer';
import { type Filter as DomainFilter } from 'src/core/common/components/FilterBuilder';

import { type BucketType } from './buckets';
import {
  type PayableState,
  unpreparedPayableStates,
} from '../../graphql/mappers/payableState';

export type GroupFilter =
  | 'expenseType'
  | 'supplier'
  | 'expenseAccount'
  | 'taxAccount';

export type SortFilter = 'smart' | 'natural';

export const defaultSortFilter: SortFilter = 'smart';

export type PeriodFilter = { startDate: string | null; endDate: string | null };

type ISOString = string;

type DefaultAdditionalFilters = {
  id:
    | 'supplier'
    | 'expenseAccount'
    | 'expenseType'
    | 'team'
    | 'payer'
    | 'documentaryEvidenceStatus'
    | 'costCenter';
  type: 'List';
  values: string[];
};

export type EnabledAdditionalFilters = {
  customFields: boolean;
  teams: boolean;
  costCenters: boolean;
};

export type AdditionalFilter =
  | DefaultAdditionalFilters
  | {
      id: string;
      type: 'CustomBoolean';
      values: string[];
    }
  | {
      id: string;
      type: 'CustomList';
      values: string[];
    };

enum AdditionalFilterIds {
  expenseAccount = 'expenseAccount',
  supplier = 'supplier',
  payer = 'payer',
  team = 'team',
  costCenter = 'costCenter',
  expenseType = 'expenseType',
  documentaryEvidenceStatus = 'documentaryEvidenceStatus',
}

export type Filters = {
  sort: SortFilter;
  group?: GroupFilter;
  period?: {
    startDate: ISOString | null;
    endDate: ISOString | null;
  };
  search?: string;
  additional: AdditionalFilter[];
};

export type PayableFilters = {
  state: PayableState[];
  documentaryEvidenceStatus?:
    | 'not_provided'
    | 'provided_and_dpr'
    | 'provided'
    | 'missing';
  creationDate?: { from?: string; to?: string };
  firstExportedAt?: { from?: string; to?: string }[];
  withBookkeepingStartDate?: boolean;
  payers?: {
    ids: string[];
  };
  expenseAccounts?: {
    ids: string[];
  };
  suppliers?: {
    ids: string[];
  };
  teams?: {
    ids: string[];
  };
  costCenters?: string[];
  types?: string[];
  search?: string;
  customFields?: {
    ids: string[] | boolean[];
  }[];
};

const toGroupFilterPayload = (
  groupFilter: GroupFilter,
  groupId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (groupFilter === 'expenseType') {
    return [groupId];
  }
  if (groupId === 'default') {
    return { includeNull: true };
  }
  if (groupId !== 'default') {
    return { ids: [groupId] };
  }
};

export const toAPIPayableFiltersV2 = ({
  filters,
  bucketType,
  groupFilter,
  groupId,
  withBookkeepingStartDate,
}: {
  filters: DomainFilter | undefined;
  bucketType?: BucketType;
  groupFilter?: GroupFilter | undefined;
  groupId?: string | null;
  withBookkeepingStartDate?: boolean;
}): Filter | undefined => {
  const subfilters = filters ? [toCoreTypeFilter(filters)] : [];

  subfilters.push({
    field: 'bookkeepingStatus',
    operator: '=',
    value: ['toPrepare'],
  });

  // Proof filter
  switch (bucketType) {
    case 'withoutProof':
      subfilters.push({
        field: 'documentaryEvidenceStatus',
        operator: '=',
        value: ['missing'],
      });
      break;
    case 'withProof':
      subfilters.push({
        field: 'documentaryEvidenceStatus',
        operator: '=',
        value: ['provided'],
      });
      break;
    case 'missingProof':
      subfilters.push({
        field: 'documentaryEvidenceStatus',
        operator: '=',
        value: ['cannotBeProvided', 'declaredAsInvalid'],
      });
      break;
    default:
      break;
  }

  // Grouping filter
  if (groupFilter && groupId) {
    if (groupFilter === 'expenseType') {
      subfilters.push({
        field: 'payableType',
        operator: '=',
        value: (() => {
          if (groupId === 'cardPurchase') {
            return [
              'singleCardPurchase',
              'physicalCardPurchase',
              'cardRefund',
              'subscription',
            ];
          }

          if (groupId === 'claimedBill') {
            return ['expenseClaim'];
          }

          if (groupId === 'mileageExpense') {
            return ['mileageAllowance'];
          }

          if (groupId === 'perDiemExpense') {
            return ['perDiemAllowance'];
          }

          if (groupId === 'creditNote' || groupId === 'invoicePurchase') {
            return [groupId];
          }

          return null;
        })(),
      });
    }

    if (groupFilter === 'supplier') {
      subfilters.push({
        field: 'supplier',
        operator: '=',
        value: [groupId],
      });
    }
  }

  if (withBookkeepingStartDate === true) {
    subfilters.push({
      field: 'withBookkeepingStartDate',
      operator: '=',
      value: true,
    });
  }

  return {
    operator: 'and',
    subfilters,
  };
};

export const toAPIPayableFilters = ({
  filters,
  bucketType,
  groupId,
  withBookkeepingStartDate,
}: {
  filters: Filters;
  bucketType?: BucketType;
  groupId?: string | null;
  withBookkeepingStartDate?: boolean;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): PayableFilters | undefined => {
  const queryFilters: PayableFilters = {
    state: unpreparedPayableStates,
    withBookkeepingStartDate,
    customFields: [],
  };

  // Search filter
  if (filters.search) {
    queryFilters.search = filters.search;
  }

  // Period filter
  if (filters.period?.startDate && filters.period?.endDate) {
    queryFilters.creationDate = {
      from: filters.period.startDate,
      to: filters.period.endDate,
    };
  }

  // Additional filters
  if (filters.additional.length > 0) {
    filters.additional.forEach((filter: AdditionalFilter) => {
      if (
        filter.id === AdditionalFilterIds.expenseAccount &&
        filter.values.length
      ) {
        queryFilters.expenseAccounts = {
          ids: filter.values,
        };
      }
      if (filter.id === AdditionalFilterIds.supplier && filter.values.length) {
        queryFilters.suppliers = {
          ids: filter.values,
        };
        return;
      }
      if (filter.id === AdditionalFilterIds.payer && filter.values.length) {
        queryFilters.payers = {
          ids: filter.values,
        };
        return;
      }
      if (filter.id === AdditionalFilterIds.team && filter.values.length) {
        queryFilters.teams = {
          ids: filter.values,
        };
        return;
      }
      if (
        filter.id === AdditionalFilterIds.costCenter &&
        filter.values.length
      ) {
        queryFilters.costCenters = filter.values;
        return;
      }
      if (
        filter.id === AdditionalFilterIds.expenseType &&
        filter.values.length
      ) {
        queryFilters.types = filter.values;
        return;
      }

      if (filter.type === 'CustomBoolean' || filter.type === 'CustomList') {
        queryFilters.customFields?.push({
          ids: filter.values,
        });
      }
    });
  }

  // Proof filter
  switch (bucketType) {
    case 'withoutProof':
      queryFilters.documentaryEvidenceStatus = 'not_provided';
      break;
    case 'withProof':
      queryFilters.documentaryEvidenceStatus = 'provided';
      break;
    case 'missingProof':
      queryFilters.documentaryEvidenceStatus = 'missing';
      break;
    default:
      break;
  }

  // Additional filter "Receipt" is more prio than the bucket type
  const receiptFilter = filters.additional.find(
    (filter) => filter.id === AdditionalFilterIds.documentaryEvidenceStatus,
  );
  if (receiptFilter !== undefined && receiptFilter.values.length === 1) {
    const value = receiptFilter.values[0];
    if (
      value === 'not_provided' ||
      value === 'provided_and_dpr' ||
      value === 'provided' ||
      value === 'missing'
    ) {
      queryFilters.documentaryEvidenceStatus = value;
    }

    // if the filter is incompatible with the bucket proof, we return undefined

    if (
      (bucketType === 'missingProof' && value !== 'missing') ||
      (bucketType === 'withProof' &&
        !['provided', 'provided_and_dpr'].includes(value)) ||
      (bucketType === 'withoutProof' && value !== 'not_provided')
    ) {
      return undefined;
    }
  }

  // Grouping filter
  if (filters.group && groupId) {
    const groupFilterPayload = toGroupFilterPayload(filters.group, groupId);

    if (filters.group === 'expenseType') {
      queryFilters.types = groupFilterPayload;
    }

    if (filters.group === 'supplier') {
      queryFilters.suppliers = groupFilterPayload;
    }
  }

  return queryFilters;
};
