import {
  useCustomFieldsQuery,
  useCustomFieldsQueryPromise,
  type CustomFieldsQueryResult,
} from 'modules/budgets/apis';
import {
  useIsExpenseCategoryEnabled,
  useIsCustomFieldFeatureEnabled,
} from 'modules/budgets/features';
import { type QueryState, reshapeQueryState } from 'src/core/api/queryState';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

import { useCostCenterData } from './useCostCenterData';
import { useRequiredCustomFields } from './useRequiredCustomFields';
import { type CostCenterId } from '../../models/costCenter';
import { type CustomFieldEligibleType } from '../../models/customFieldAssociation';
import type {
  CustomFieldDefinition,
  CustomFieldId,
} from '../../models/customFieldDefinition';
import { shouldDisplayExpenseCategoryField } from '../../models/expenseCategory';

/**
 * @public
 */
export type CustomFieldData = {
  customFields: CustomFieldDefinition[];
  // that includes `expenseCategoryField` if available
  getRequiredCustomFields: (
    teamIds: string[] | string | undefined,
  ) => CustomFieldId[];
} & (
  | {
      isExpenseCategoryEnabled: true;
      isExpenseCategoryRequired: boolean;
      expenseCategoryField: CustomFieldDefinition;
    }
  | {
      isExpenseCategoryEnabled: false;
    }
);

export type CustomFieldDataGetter = (
  eligibleType: CustomFieldEligibleType,
  selectedCostCenterId: CostCenterId | undefined,
) => CustomFieldData;

type UseCustomFieldsData =
  | {
      customFieldsQueryPromise: () => Promise<CustomFieldDataGetter>;
      customFieldsQueryState: QueryState<CustomFieldDataGetter>;
      isCustomFieldsEnabled: true;
    }
  | {
      isCustomFieldsEnabled: false;
    };

export const useCustomFieldsData = (): UseCustomFieldsData => {
  const isCustomFieldsEnabled = useIsCustomFieldFeatureEnabled();
  const customFieldsQueryState = useCustomFieldsQuery();
  const customFieldsQueryPromise = useCustomFieldsQueryPromise();
  const toCustomFieldData = useToCustomFieldData();

  return isCustomFieldsEnabled
    ? {
        isCustomFieldsEnabled,
        customFieldsQueryPromise: () =>
          // eslint-disable-next-line promise/prefer-await-to-then
          customFieldsQueryPromise().then(toCustomFieldData),
        customFieldsQueryState: reshapeQueryState(
          customFieldsQueryState,
          toCustomFieldData,
        ),
      }
    : {
        isCustomFieldsEnabled,
      };
};

const useToCustomFieldData = () => {
  const getAllRequiredCustomField = useRequiredCustomFields();
  const getRequiredCustomFields = (
    eligibleType: CustomFieldEligibleType,
    customFields: CustomFieldDefinition[],
    teamIds: string[] | string | undefined = [],
  ): CustomFieldId[] =>
    getAllRequiredCustomField(
      customFields,
      Array.isArray(teamIds) ? teamIds : [teamIds],
      [eligibleType],
    );

  const getShouldDisplayExpenseCategoryField =
    useShouldDisplayExpenseCategoryField();

  return (
      customFieldsQueryResult: CustomFieldsQueryResult,
    ): CustomFieldDataGetter =>
    (
      eligibleType: CustomFieldEligibleType,
      selectedCostCenterId: CostCenterId | undefined,
    ) => {
      const { customFields, expenseCategoryField } = customFieldsQueryResult;

      const isExpenseCategoryEnabled =
        expenseCategoryField !== undefined &&
        getShouldDisplayExpenseCategoryField(
          expenseCategoryField,
          eligibleType,
          selectedCostCenterId,
        );

      return isExpenseCategoryEnabled
        ? {
            customFields,
            isExpenseCategoryEnabled,
            expenseCategoryField,
            getRequiredCustomFields: (teamIds) =>
              getRequiredCustomFields(
                eligibleType,
                [...customFields, expenseCategoryField],
                teamIds,
              ),
            isExpenseCategoryRequired: getRequiredCustomFields(eligibleType, [
              expenseCategoryField,
            ]).includes(expenseCategoryField.id),
          }
        : {
            customFields,
            isExpenseCategoryEnabled: false,
            getRequiredCustomFields: (teamIds) =>
              getRequiredCustomFields(eligibleType, customFields, teamIds),
          };
    };
};

const useShouldDisplayExpenseCategoryField = () => {
  const isExpenseCategoriesEnabled = useIsExpenseCategoryEnabled();
  const costCenterData = useCostCenterData();

  const costCenters =
    (costCenterData.isCostCenterEnabled &&
      unwrapQuery(costCenterData.costCentersQueryState)?.costCenters) ||
    [];

  return (
    expenseCategoryField: CustomFieldDefinition | undefined,
    eligibleType: CustomFieldEligibleType,
    selectedCostCenterId: CostCenterId | undefined,
  ): boolean =>
    expenseCategoryField !== undefined &&
    shouldDisplayExpenseCategoryField({
      isCostCentersFeatureEnabled: costCenterData.isCostCenterEnabled,
      isExpenseCategoriesFeatureEnabled: isExpenseCategoriesEnabled,
      costCenters,
      selectedCostCenter: selectedCostCenterId,
      eligibleTypes: expenseCategoryField?.eligible_types || [],
      requestType: eligibleType,
    });
};
