import { Button } from '@dev-spendesk/grapes';
import React, { useId } from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import DashboardIllustration from './assets/dashboard-illustration.svg';
import { useExternalUrlsQuery } from '../../hooks/useExternalUrlsQuery';
import { PlaceholderBox } from '../PlaceholderBox';

export const DashboardTipBox = () => {
  const titleId = useId();
  const externalUrlsQueryState = useExternalUrlsQuery();

  const { t } = useTranslation('global');

  return (
    <QuerySuspense
      queryState={externalUrlsQueryState}
      loading={<PlaceholderBox />}
      fallback={(queryError) => (
        <QueryError componentType="Callout" queryError={queryError} />
      )}
    >
      {(externalUrls) => {
        return (
          <section
            aria-labelledby={titleId}
            className="full-h relative flex flex-wrap items-stretch gap-24 overflow-hidden rounded-12 bg-secondary-default px-24 py-32"
          >
            <div className="flex max-w-[60%] shrink flex-col content-stretch gap-8">
              <h3 className="text-primary title-l" id={titleId}>
                {t('homepage.tips.dataDashboardTitle')}
              </h3>
              <p className="mt-4 text-secondary-bg-secondary body-m">
                {t('homepage.tips.dataDashboard.text')}
              </p>
              <Button
                className="mt-auto self-start truncate"
                variant="secondaryNeutral"
                onClick={() =>
                  track(AnalyticEventName.HOMEPAGE_TIPS_CLICKED, {
                    tip: 'data_dashboard',
                  })
                }
                component="a"
                href={externalUrls.controlDashboard}
                text={t('homepage.tips.dataDashboard.cta')}
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
            <img
              width="174"
              height="164"
              alt=""
              src={DashboardIllustration}
              className="absolute right-[0] top-[50%] translate-y-[-50%]"
            />
          </section>
        );
      }}
    </QuerySuspense>
  );
};
