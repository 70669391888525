import { PanelSection, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const InvoiceLineItemsPanelSectionSkeleton = () => {
  return (
    <PanelSection>
      <SkeletonText size="m" />
      <SkeletonText size="m" />
    </PanelSection>
  );
};
