import { useEffect, useState } from 'react';

export function useNotificationAnimation(
  ref: React.RefObject<HTMLUListElement>,
) {
  const [offsetArray, setOffsetArray] = useState<number[]>([]);

  useEffect(() => {
    if (ref.current) {
      const config = { attributes: false, childList: true, subtree: true };

      // Callback function to execute when mutations are observed
      // eslint-disable-next-line sonarjs/cognitive-complexity
      const callback = (mutationList: MutationRecord[]) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            // Node added
            if (mutation.addedNodes.length > 0) {
              const el = mutation.addedNodes[0] as HTMLLIElement;
              if (el.dataset.index === '-1') {
                // ignore cloned element
                return;
              }
              const height = el.offsetHeight;
              setOffsetArray((o) => [height].concat(o));
            }
            // Node removed
            if (mutation.removedNodes.length > 0) {
              const el = mutation.removedNodes[0] as HTMLLIElement;
              const index = Number(el.dataset.index);

              if (index === -1) {
                // ignore cloned element
                return;
              }
              setOffsetArray((a) => a.filter((_, i) => i !== index));

              const cloned = el.cloneNode(true) as HTMLLIElement;
              cloned.dataset.index = '-1';
              cloned.addEventListener('transitionend', function (event) {
                // Multiple transition so only listen to opacity
                if (event.propertyName === 'opacity') {
                  this.remove();
                }
              });

              ref.current?.append(cloned);
            }
          }
        }
      };

      const observer = new MutationObserver(callback);
      observer.observe(ref.current, config);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return function getOffsetFromIndex(index: number) {
    return offsetArray.slice(0, index).reduce((acc, o) => acc + o, 0);
  };
}
