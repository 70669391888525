import * as R from '@dev-spendesk/general-type-helpers/Result';
import { parsePhoneNumber } from 'libphonenumber-js';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { translateRoleName } from 'common/utils/translateRoleName';
import { type UserRoles } from 'modules/app/hooks/useUserRoles';
import { type ApprovalPolicy } from 'modules/company/members/components/ApprovalPolicies';
import {
  type Gender,
  type GenderForDisplay,
} from 'modules/profile/models/gender';
import { type Lang } from 'modules/profile/models/lang';
import { type BankingProvider } from 'src/core/reducers/global';

import { type CardsAccess } from './cardsAccess';
import { type ControlRule } from './controlRule';
import { type ApprovalNeeded, type Policy } from './policy';
import { type CompanyRole } from './roles';
import { type Team } from './teams';
import { type Invoice } from '../../company/billing/models';
import { type ApiCard } from '../../physical-cards/card';

export type RequiredInfoTypeForEmployeeBankAddress =
  | 'iban'
  | 'bic'
  | 'cuc'
  | 'sortCode'
  | 'accountNumber'
  | 'accountHolderName'
  | 'routingNumber';

/**
 * we keep both camelCase and snake_case for legacy reasons.
 * Snake case should not be used in new code
 * */
export type Member = {
  id: string;
  email: string;
  avatar: string | undefined;
  fullname: string;
  displayName: string;
  firstName: string | undefined;
  lastName: string | undefined;
  isOrganisationOwner: boolean;
  mobileExt: string | undefined;
  mobileNo: string | undefined;
  /**
   * @deprecated
   * use isPending instead
   */
  pending: boolean;
  isPending: boolean;
  inviteId: string | undefined;
  gender: Gender;
  lang: Lang;
  createdAt: string;
  isDeleted: boolean;
  hasSlackLinked: boolean;
  hasSlackEnabled: boolean;
  hasPlasticCard: boolean;

  teams: Team[];
  costCenter?: CostCenter | null;
  controlRule: ControlRule | undefined;

  policy?: Policy | null;
  spendingPolicy:
    | {
        transactionMax: number;
        spendingLimit: number;
      }
    | undefined;

  cardsAccess?: CardsAccess;
  plasticCard?: ApiCard | null;
  subscriptionsCount: number;

  bankInfo?: {
    accountHolderName?: string;
    iban?: string;
    bic?: string;
    cuc?: string;
    sortCode?: string;
    accountNumber?: string;
    routingNumber?: string;
  };
  requiredInfoTypesForEmployeeBankAddress: RequiredInfoTypeForEmployeeBankAddress[];

  isAccountOwner: boolean;
  isAdmin: boolean;
  isController: boolean;
  isRequester: boolean;
  isGroupAdmin: boolean;

  manager?: {
    id: string;
    pending: boolean;
    fullNameOrEmail: string;
    avatar: string | undefined;
  } | null;
};

export type MemberDetails = Member & {
  canConfirmPayments: boolean;
};

export type DraftMember = {
  firstName?: string;
  lastName?: string;
  bankInfo: NonNullable<Member['bankInfo']>;
  roles: CompanyRole[];
  teams: Team[];
  costCenter: Member['costCenter'];
  controlRule: Member['controlRule'];
  approvalPolicy: ApprovalPolicy | undefined;
  canConfirmPayments: MemberDetails['canConfirmPayments'];
  managerId: string | null;
};

export type MemberTableRow = {
  id: string;
  email: string;
  avatar: string | undefined;
  status: MemberStatus;
  firstName: string | undefined;
  lastName: string | undefined;
  deletedEmail: string | undefined;
};

export type MemberStatus = 'active' | 'pending' | 'archived';

export type RawMember = {
  fullname: string;
  nb_companies: number;
  company_ids: string[];
  last_selected_company_id: string;
  is_organisation_owner: boolean;
  avatar: string | undefined;
  token?: string;
  id: string;
  index: number;
  organisation_id: string;
  inviter_id: string | null | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  gender: Gender;
  email: string;
  deleted_email: string | null | undefined;
  mobile_ext: string | undefined;
  mobile_no: string | undefined;
  agreement_date: string | undefined;
  signup_date: string | undefined;
  mail_checked: boolean;
  lang: Lang;
  display_gender: GenderForDisplay;
  google_id: string | undefined;
  microsoft_id: string | null | undefined;
  pending: boolean;
  threeds_phone_validated_at: string | null | undefined;
  created_at: string;
  updated_at: string | null | undefined;
  deleted_at: string | null | undefined;
  organisation: { id: string; name: string };
  data_by_company: { [keyof: string]: RawDataByCompany };
  notification_provider_users: {
    company_id: string;
    created_at: string;
    deleted_at: string | null;
    id: string;
    provider_id: string;
    provider_user_id: string;
    updated_at: string;
    user_id: string;
  }[];
  costCenter?: CostCenter | null;
  canConfirmPayments?: boolean;
  manager?: { id: string; pending: boolean; fullNameOrEmail: string } | null;
  legacy_spending_policy?: {
    id: string;
    organisation_id: string;
    company_id: string;
    code: string;
    name: string;
    is_default: boolean;
    is_user_custom: boolean;
    allow: boolean;
    params: {
      approval_needed: ApprovalNeeded;
      spending_limit: number;
      spending_types: string;
      transaction_max: number;
    };
  };
};

export type RawDataByCompany = {
  id: string;
  name: string;
  name_alias: string;
  has_public_banking_address: boolean;
  plastic_card?: ApiCard | null;
  subscriptions?: number;
  groups_names?: string[];
  groups_ids?: string[];
  bank_info?: {
    accountHolderName: string;
    bic: string;
    iban: string;
  };
  invoices: Invoice[];
  suspended_at: string | null;
  last_used_at: string | null;
  validated_at: string | null;
  churning_at: string | null;
  churn_at: string | null;
  transaction_max: number;
  spending_limit: number;
  has_account: boolean;
  gatekeeper: {
    companyId: string;
    featureSet: Record<string, boolean>;
  };
  currency: string;
  address: string;
  city: string;
  zipcode: string;
  country: string;
  vat: string;
  groups: number;
  groups_admins: string[];
  accounts: { balance: number; currency: string }[];
  issuer: string;
  banking_provider: BankingProvider;
  accounting_setting: {
    are_expense_accounts_required: boolean;
    are_vat_accounts_enabled: boolean;
    are_vat_accounts_required: boolean;
    bank_account_id: string | null;
    company_id: string;
    connection_failure_reason: string | null;
    connection_status: null;
    created_at: '2021-12-07T14:23:42.000Z';
    deleted_at: null;
    deletion_token: 'NA';
    fees_account_id: null;
    id: 'ury250nif8jx36';
    organisation_code: null;
    provider_id: null;
    should_push_vat_rates: null;
    sync_tracking_codes: false;
    updated_at: '2021-12-07T14:23:42.000Z';
    use_default_employee_account_code: boolean;
  };
  kyb_version: number;
  is_kyb_validated: boolean;
  balance_available_all_accounts: number;
  balance_pending_cards: number;
  balance_loaded_cards: number;
  nb_custom_fields: number;
  invitations: number;
  spending_total: number;
  mileage_scheme: {
    id: string;
    currency: string;
    distanceUnit: string;
    schemeType: string;
    companyId: string;
    createdAt: string;
    fixedRates: {
      scheme_id: string;
      vehicle_type: string;
      rate: number;
    }[];
  };
  isWireTransferFeatureActivated: boolean;
  provider_integration_statuses: {
    has_slack_enabled: boolean;
    has_slack_linked: boolean;
    has_xero_linked: boolean;
  };
  is_delegating: boolean;
  requiredInfoTypesForEmployeeBankAddress?: (
    | 'iban'
    | 'bic'
    | 'cuc'
    | 'sortCode'
    | 'accountNumber'
    | 'accountHolderName'
    | 'routingNumber'
  )[];
  seen_popups: {
    has_seen_slack_annoucement: boolean;
    has_seen_what_a_valid_receipt: boolean;
    has_seen_expense_inbox_onboarding: boolean;
    has_recorded_with_expense_inbox: boolean;
    has_seen_employee_account_info: boolean;
    has_seen_kyb_procedure_validated: boolean;
  };
  is_account_owner: boolean;
  is_controller: boolean;
  is_admin: boolean;
  is_requester: boolean;
  legacy_spending_policy?: {
    id: string;
    organisation_id: string;
    company_id: string;
    code: string;
    name: string;
    is_default: boolean;
    is_user_custom: boolean;
    allow: boolean;
    params: {
      approval_needed: ApprovalNeeded;
      spending_limit: number;
      spending_types: string;
      transaction_max: number;
    };
  };
};

export type CostCenter = {
  id: string;
  name: string;
  selectionMode?: 'suggested' | 'default';
};

export const getMemberName = (member: {
  isPending: boolean;
  email: string;
  fullname?: string;
  firstName?: string;
}): string => {
  return member.isPending
    ? member.email
    : (member.fullname ?? member.firstName ?? member.email);
};

export const getMemberPhoneNumber = (member: Member): string => {
  if (!member.mobileExt) {
    return '';
  }

  const number = `+${member.mobileExt} ${member.mobileNo}`;
  try {
    const parsedNumber = parsePhoneNumber(number);

    if (parsedNumber.country) {
      return parsedNumber.formatInternational();
    }
  } catch {
    return number;
  }

  return number;
};

export const getMemberRoleList = (member: Member): CompanyRole[] => {
  const roles: CompanyRole[] = [];
  if (member.isAccountOwner) {
    roles.push('accountOwner');
  }
  if (member.isAdmin) {
    roles.push('admin');
  }
  if (member.isController) {
    roles.push('controller');
  }
  if (member.isRequester) {
    roles.push('requester');
  }
  return roles;
};

export const getMemberRoleNamesForDisplay = (
  member: Member,
  t: TGlobalFunctionTyped,
): string[] => {
  const roles = getMemberRoleList(member);
  // if a member has the accountOwner role, we don't need to display the other roles, they are implied
  if (roles.includes('accountOwner')) {
    return [t('misc.accountOwner')];
  }
  return roles.flatMap((role) => {
    const translatedRoleName = translateRoleName(role, t);
    if (translatedRoleName === role) {
      return [];
    }
    return translatedRoleName;
  });
};

export const getMemberTeams = (member: Member): Team[] => {
  return member.teams;
};

export const canEditAdditionalRights = (
  currentUserRoles: UserRoles,
): boolean => {
  return currentUserRoles.isAccountOwner;
};

export const getEligibilityToWireTransferDelegation = (
  member: MemberDetails,
  draftIsControllerValue: boolean = false,
): R.Result<{ reason: 'notController' }, true> => {
  if (!draftIsControllerValue || !member.isController) {
    return R.toFailure({ reason: 'notController' });
  }
  return R.toSuccess(true);
};

export const getDraftMemberRoles = (
  draftMember: DraftMember,
): { isController: boolean; isRequester: boolean; isAdmin: boolean } => {
  return {
    isController: draftMember.roles.includes('controller'),
    isRequester: draftMember.roles.includes('requester'),
    isAdmin: draftMember.roles.includes('admin'),
  };
};
