import { Button, Label, ListItem, Modal } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { useState } from 'react';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUser } from 'modules/app/hooks/useUser';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import {
  type SlackProviderSettings,
  getSlackAuthorizeUrl,
} from '../../../../models';
import { useUnlinkSlackQuery } from '../../hooks/useUnlinkSlackQuery';

import './NotificationSlackSetting.css';

type Props = {
  slackProviderSettings: SlackProviderSettings;
};

export const NotificationSlackSetting = ({ slackProviderSettings }: Props) => {
  const { t } = useTranslation('global');
  const user = useUser();
  const companyId = useCompanyId();
  const [unlinkSlackQuery] = useUnlinkSlackQuery();

  const [isSlackUnlinked, setIsSlackedUnlinked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isSlackEnabled = !isSlackUnlinked && user.has_slack_enabled;

  const oauthUrl = getSlackAuthorizeUrl({
    clientId: slackProviderSettings.clientId,
    companyId,
    scope: slackProviderSettings.permissions,
  });

  if (!oauthUrl) {
    return null;
  }

  return (
    <ListItem>
      <div
        className={classNames('NotificationSlackSetting', {
          'NotificationSlackSetting--slack-enabled': isSlackEnabled,
        })}
      >
        <div>
          <Label
            className="text-primary"
            label={t('generalSettings.notifications.slack.title')}
          />

          <div className="NotificationSlackSetting__description body-m">
            {t('generalSettings.notifications.slack.description')}
          </div>
        </div>
        <div>
          {isSlackEnabled ? (
            <div className="mt-16 flex">
              <Button
                variant="secondaryNeutral"
                text={t('generalSettings.notifications.slack.changeChannel')}
                component="a"
                href={oauthUrl}
                iconName="slack"
              />
              <Button
                className="ml-8"
                variant="tertiaryNeutral"
                text={t('generalSettings.notifications.slack.unlinkFromSlack')}
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          ) : (
            <Button
              className="ml-24 whitespace-nowrap"
              variant="secondaryNeutral"
              text={t('generalSettings.notifications.slack.linkToSlack')}
              component="a"
              href={oauthUrl}
              iconName="slack"
            />
          )}
        </div>
      </div>
      <Modal
        iconName="triangle-warning"
        iconVariant="warning"
        isOpen={isModalOpen}
        actions={[
          <Button
            key="cancel"
            variant="secondaryNeutral"
            text={t('misc.cancel')}
            onClick={() => setIsModalOpen(false)}
          />,
          <Button
            key="confirm"
            variant="primaryBrand"
            text={t(
              'generalSettings.notifications.slack.confirmationModalSave',
            )}
            onClick={async () => {
              await unlinkSlackQuery();
              setIsSlackedUnlinked(true);
              setIsModalOpen(false);
            }}
          />,
        ]}
        title={t('generalSettings.notifications.slack.confirmationModalTitle')}
      >
        {t('generalSettings.notifications.slack.confirmationModalDescription')}
      </Modal>
    </ListItem>
  );
};
