import { Button } from '@dev-spendesk/grapes';
import React, { useRef } from 'react';
import { useVirtual } from 'react-virtual';

import { useTranslation } from 'common/hooks/useTranslation';

import { CustomFieldRowActions } from '../../components/CustomFieldValuesList/CustomFieldRowActions';
import { useCustomFieldValuesQuery } from '../CustomFieldValuesListContainer/hooks';

type Props = {
  customFieldId: string;
  showUpdateCustomFieldValueModal: () => void;
  showDeleteCustomFieldValueModal: () => void;
};

export const CustomFieldLimitlessListContainer = ({
  customFieldId,
  showUpdateCustomFieldValueModal,
  showDeleteCustomFieldValueModal,
}: Props) => {
  const { t } = useTranslation('global');
  const customFieldValuesQueryState = useCustomFieldValuesQuery(customFieldId);
  const parentReference = useRef(null);
  const rowVirtualizer = useVirtual({
    size:
      customFieldValuesQueryState.status === 'success'
        ? customFieldValuesQueryState.data.length
        : 0,
    parentRef: parentReference,
  });

  if (customFieldValuesQueryState.status !== 'success') {
    return null;
  }

  return (
    <div ref={parentReference} className="CustomFieldValuesList__list">
      <div
        className="CustomFieldValuesList__list__virtual"
        style={{
          height: `${rowVirtualizer.totalSize}px`,
        }}
      >
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          const value = customFieldValuesQueryState.data[virtualRow.index];
          if (!value) {
            return;
          }
          return (
            <div
              key={virtualRow.index}
              className="CustomFieldValuesList__row CustomFieldValuesList__row--virtual"
              style={{
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
              ref={virtualRow.measureRef}
            >
              <span className="CustomFieldValuesList__row__name truncate">
                {value.name}
              </span>
              <CustomFieldRowActions
                customFieldValueId={value.id}
                showUpdateCustomFieldValueModal={
                  showUpdateCustomFieldValueModal
                }
                showDeleteCustomFieldValueModal={
                  showDeleteCustomFieldValueModal
                }
                customFieldValueName={value.name}
              />
            </div>
          );
        })}
      </div>
      {customFieldValuesQueryState.hasNextPage && (
        <div className="flex justify-center p-16">
          <Button
            onClick={customFieldValuesQueryState.fetchNextPage}
            text={t('misc.loadMore')}
            variant="secondaryNeutral"
          />
        </div>
      )}
    </div>
  );
};
