import { useMemo } from 'react';

import { QueryError } from 'common/components/QueryError';
import { TaskList } from 'modules/onboarding/components/TaskList';
import { WelcomeBanner } from 'modules/onboarding/components/WelcomeBanner';
import {
  READY_TO_SPEND_TASKS,
  CONTROL_SETTINGS_TASKS,
  CHART_OF_ACCOUNTS_TASKS,
  EXPORTS_SETTINGS_TASKS,
  INIT_ACCOUNTING_TASKS,
} from 'modules/onboarding/setup-hub/constants/taskSections';
import { useGetOnboardingUserTasksQuery } from 'modules/onboarding/setup-hub/hooks/useGetOnboardingUserTasksQuery';
import type {
  UserTask,
  UserTaskType,
} from 'modules/onboarding/setup-hub/types/userTask';
import type {
  HubSection,
  SectionDefinition,
} from 'modules/onboarding/types/hubSection';
import {
  type HubTask,
  type HubTaskType,
} from 'modules/onboarding/types/hubTask';

import { useSetupHubTasksQuery } from './hooks/useSetupHubTasksQuery';

const READY_TO_SPEND_SECTION: SectionDefinition<UserTaskType> = {
  id: 'ready_to_spend',
  tasks: READY_TO_SPEND_TASKS,
  title: 'setupHub.sections.readyToSpend.title',
  isTransferable: false,
};

const CONTROL_SETTINGS_SECTION: SectionDefinition<HubTaskType> = {
  id: 'control_settings',
  tasks: CONTROL_SETTINGS_TASKS,
  title: 'setupHub.sections.controlSettings.title',
  isTransferable: false,
};

const INIT_ACCOUNTING_SECTION: SectionDefinition<HubTaskType> = {
  id: 'init_accounting',
  tasks: INIT_ACCOUNTING_TASKS,
  title: 'setupHub.sections.accounting.initAccounting.title',
  isTransferable: false,
};

const ACCOUNTS_CHART_SECTION: SectionDefinition<HubTaskType> = {
  id: 'accounts_chart',
  tasks: CHART_OF_ACCOUNTS_TASKS,
  title: 'setupHub.sections.accounting.accountsChart.title',
  isTransferable: false,
};

const EXPORTS_SETTINGS_SECTION: SectionDefinition<HubTaskType> = {
  id: 'exports_settings',
  tasks: EXPORTS_SETTINGS_TASKS,
  title: 'setupHub.sections.accounting.exportsSettings.title',
  isTransferable: false,
};

export const SetupHubPage = () => {
  const setupHubTasksQueryState = useSetupHubTasksQuery();
  const onboardingUserTasksQueryState = useGetOnboardingUserTasksQuery();

  const sections: HubSection<HubTask | UserTask>[] = useMemo(() => {
    const result = [];
    if (
      onboardingUserTasksQueryState.status === 'success' &&
      onboardingUserTasksQueryState.data.length
    ) {
      const tasks = READY_TO_SPEND_TASKS.map((taskType) =>
        onboardingUserTasksQueryState.data.find(
          (task) => task.type === taskType,
        ),
      ).filter((task) => task !== undefined);
      result.push({ ...READY_TO_SPEND_SECTION, tasks });
    }
    if (
      setupHubTasksQueryState.status === 'success' &&
      setupHubTasksQueryState.data.length
    ) {
      const definedSections: SectionDefinition<HubTaskType>[] = [
        CONTROL_SETTINGS_SECTION,
        INIT_ACCOUNTING_SECTION,
        ACCOUNTS_CHART_SECTION,
        EXPORTS_SETTINGS_SECTION,
      ];
      definedSections.forEach((section) => {
        const sectionTasks = section.tasks
          .map((taskType) =>
            setupHubTasksQueryState.data.find((task) => task.type === taskType),
          )
          .filter((task) => task !== undefined);
        if (sectionTasks.length) {
          result.push({ ...section, tasks: sectionTasks });
        }
      });
    }
    return result;
  }, [onboardingUserTasksQueryState, setupHubTasksQueryState]);

  if (setupHubTasksQueryState.status === 'error') {
    return (
      <QueryError
        componentType="ErrorState"
        queryError={setupHubTasksQueryState.error}
        translations={{
          networkError: 'misc.errors.networkError',
          serverError: 'misc.errors.serverError',
          requestError: () => 'misc.errors.networkError',
        }}
      />
    );
  }

  return (
    <div className="page__container h-full overflow-auto bg-primary-default">
      <div className="mx-auto my-64 flex w-[60%] max-w-[822px] flex-col gap-24 px-16">
        <WelcomeBanner />
        <TaskList tasksBySection={sections} />
      </div>
    </div>
  );
};
