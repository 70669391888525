import { Supervision } from 'src/core/common/components/Supervision/Supervision';
import { OverdueInvoiceNotification } from 'src/core/modules/billing/OverdueInvoiceNotification/OverdueInvoiceNotification';

import styles from './TopBanners.module.css';
import { FundsInEscrowNotification } from '../../../components/Notifications/FundInEscrowNotification';
import { DelegationHeader } from '../DelegationHeader/DelegationHeader';
import { HeaderLoadNotif } from '../HeaderLoadNotif/HeaderLoadNotif';

export const TopBanners = () => {
  return (
    <div className={styles.container}>
      <OverdueInvoiceNotification />
      <Supervision />
      <HeaderLoadNotif />
      <DelegationHeader />
      <FundsInEscrowNotification />
    </div>
  );
};
