import { Button, Link, Modal } from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onNext: () => void;
  trackingLink: string;
};

export const ReorderNotReceivedContinueModal = ({
  isOpen,
  onClose,
  onNext,
  trackingLink,
}: Props) => {
  const { t } = useTranslation('global');
  return (
    <Modal
      iconVariant="warning"
      iconName="triangle-warning"
      isOpen={isOpen}
      title={t('myCardOrderBanner.continueModalTitle')}
      onClose={onClose}
      actions={
        <>
          <Button
            variant="secondaryNeutral"
            text={t('misc.cancel')}
            onClick={onClose}
          />
          <Button
            variant="primaryBrand"
            text={t('misc.continue')}
            onClick={onNext}
          />
        </>
      }
    >
      {trackingLink ? (
        <Trans
          i18nKey="myCardOrderBanner.continueModalSubtitle"
          components={{
            a: (
              <Link
                href={trackingLink}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  track(AnalyticEventName.CARD_TRACKING_LINK_CLICKED);
                }}
              >
                -
              </Link>
            ),
          }}
        />
      ) : (
        t('myCardOrderBanner.continueModalSubtitleWithoutTracking')
      )}
    </Modal>
  );
};
