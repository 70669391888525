import {
  Button,
  Callout,
  FormField,
  IconButton,
  Input,
  Link,
  Select,
  Skeleton,
  SwitchField,
  Tag,
} from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { useNotifications } from 'modules/app/notifications';
import {
  type BookkeepingLockAfterUnit,
  useBookkeepingSettings,
} from 'modules/bookkeep/apis/useBookkeepingSettings';
import { useSetBookkeepingSettings } from 'modules/bookkeep/apis/useSetBookkeepingSettings';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { isEditAfterExportPromoActive } from 'src/core/modules/bookkeep/payables/containers/PayableEditAfterExportPromoContainer/edit-after-export-promo';

export function BookkeepingEditPayablesAfterExportSection() {
  const { t } = useTranslation('global');

  const [config, setConfig] = useState<{
    value: number | undefined;
    unit: BookkeepingLockAfterUnit | undefined;
    hasOptedIn: boolean;
  }>({
    value: undefined,
    unit: undefined,
    hasOptedIn: false,
  });

  const bookkeepingSettingsQueryResult = useBookkeepingSettings({
    isEnabled: true,
  });

  const bookkeepingSettings = unwrapQuery(bookkeepingSettingsQueryResult);

  useEffect(() => {
    if (
      bookkeepingSettings?.editPayablesAfterExport &&
      (bookkeepingSettings.editPayablesAfterExport.lockAfter !== config.value ||
        bookkeepingSettings.editPayablesAfterExport.lockAfterUnit !==
          config.unit ||
        bookkeepingSettings.editPayablesAfterExport.hasOptedIn !==
          config.hasOptedIn)
    ) {
      setConfig({
        value:
          bookkeepingSettings?.editPayablesAfterExport.lockAfter ?? undefined,
        unit:
          bookkeepingSettings?.editPayablesAfterExport.lockAfterUnit ??
          undefined,
        hasOptedIn: !!bookkeepingSettings?.editPayablesAfterExport.hasOptedIn,
      });
    }
  }, [bookkeepingSettings]);

  const [setBookkeepingSettings] = useSetBookkeepingSettings();

  const { successNotif, dangerNotif } = useNotifications();

  const onConfirm = async () => {
    try {
      await setBookkeepingSettings({
        editPayablesAfterExport: {
          hasOptedIn: config.hasOptedIn,
          lockAfter: config.value,
          lockAfterUnit: config.unit,
        },
      });
      successNotif(t('accounting.editPayablesAfterExport.successNotif'));
    } catch {
      dangerNotif(t('accounting.editPayablesAfterExport.failureNotif'));
    }
  };

  const unitOptions = [
    {
      key: 'day',
      label: t('accounting.editPayablesAfterExport.unit.day'),
    },
    {
      key: 'week',
      label: t('accounting.editPayablesAfterExport.unit.week'),
    },
    {
      key: 'month',
      label: t('accounting.editPayablesAfterExport.unit.month'),
    },
    {
      key: 'quarter',
      label: t('accounting.editPayablesAfterExport.unit.quarter'),
    },
  ];

  if (bookkeepingSettingsQueryResult.status === 'loading') {
    return (
      <div className="box">
        <Skeleton width="75%" height="20px" className="my-4" />
      </div>
    );
  }

  const hasInitialValues =
    bookkeepingSettings?.editPayablesAfterExport.lockAfter === config.value &&
    bookkeepingSettings?.editPayablesAfterExport.lockAfterUnit === config.unit;

  const hasRequiredValues =
    (!config.unit && !config.value) || (!!config.unit && !!config.value);

  const isDisabled = hasInitialValues || !hasRequiredValues;

  return (
    <>
      <div className="box">
        <SwitchField
          label={
            <>
              {t('accounting.editPayablesAfterExport.title')}{' '}
              {isEditAfterExportPromoActive(new Date()) && (
                <Tag variant="purple" className="ml-4">
                  {t('misc.new')}
                </Tag>
              )}
            </>
          }
          helpText={
            <Trans
              i18nKey="accounting.editPayablesAfterExport.content"
              components={[
                <Link
                  key="link"
                  href={t('accounting.editPayablesAfterExport.helpCenterUrl')}
                  isExternal
                />,
              ]}
            />
          }
          fit="parent"
          isChecked={config.hasOptedIn}
          onChange={async () => {
            setConfig((state) => ({
              ...state,
              hasOptedIn: !config.hasOptedIn,
            }));
            await setBookkeepingSettings({
              editPayablesAfterExport: {
                hasOptedIn: !config.hasOptedIn,
              },
            });
          }}
        />

        {bookkeepingSettingsQueryResult.status === 'error' && (
          <Callout
            title={t('accounting.editPayablesAfterExport.error')}
            variant="alert"
            className="mt-24"
          />
        )}

        {config.hasOptedIn && (
          <div className="mt-24 flex flex-col gap-16">
            <FormField label={t('accounting.editPayablesAfterExport.field')}>
              <div className="flex flex-col gap-16">
                <p className="leading-5 text-primary">
                  {t('accounting.editPayablesAfterExport.fieldHelp')}
                </p>

                <div className="flex flex-row items-center gap-8">
                  <Input
                    placeholder="30"
                    type="number"
                    className="w-[140px]"
                    value={config.value ?? ''}
                    min={1}
                    max={999}
                    onChange={(event) => {
                      const value = event.target.valueAsNumber;
                      setConfig((state) => ({
                        ...state,
                        value,
                      }));
                    }}
                  />

                  <Select
                    className="w-[140px]"
                    placeholder={t(
                      'accounting.editPayablesAfterExport.unit.day',
                    )}
                    options={unitOptions}
                    value={unitOptions.find(
                      (option) => option.key === config.unit,
                    )}
                    onSelect={({ key }) => {
                      setConfig((state) => ({
                        ...state,
                        unit: key as BookkeepingLockAfterUnit,
                      }));
                    }}
                  />

                  {config.unit && config.value && (
                    <IconButton
                      iconName="cross"
                      variant="tertiaryNeutral"
                      aria-label={t('misc.delete')}
                      onClick={() => {
                        setConfig((state) => ({
                          ...state,
                          value: undefined,
                          unit: undefined,
                        }));
                      }}
                    />
                  )}
                </div>
              </div>
            </FormField>

            <Button
              isDisabled={isDisabled}
              text={t('accounting.editPayablesAfterExport.save')}
              variant="primaryBrand"
              className="w-[fit-content]"
              onClick={async () => {
                await onConfirm();
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
