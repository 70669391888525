import { enrichUser } from 'src/core/utils/entities/user';

import { type CardsAccess } from '../models/cardsAccess';
import { type ControlRule } from '../models/controlRule';
import { type MembersCostCenter } from '../models/costCenter';
import { type Invite } from '../models/invite';
import {
  type Member,
  type MemberDetails,
  type RawMember,
} from '../models/member';
import { type Policy, reshapeToSpendingTypes } from '../models/policy';
import { type Team } from '../models/teams';

/**
 * Return both camelCase and snake_case, deeply
 * snake_case is used for legacy reasons and should not be called in new code
 */
export const reshapeMember = (
  data: RawMember,
  {
    company,
    teams,
    controlRules,
    policies,
    subscriptionCount,
    invites,
    membersCostCenter,
    cardsAccess,
  }: {
    company: { id: string };
    teams: Team[];
    controlRules: ControlRule[];
    policies: Policy[];
    subscriptionCount?: { count: number };
    invites: Invite[];
    membersCostCenter: MembersCostCenter;
    cardsAccess?: CardsAccess;
  },
): Member => {
  const computedProperties = enrichUser(data, company);

  const controlRule = controlRules.find((rule) =>
    rule.userIds.includes(data.id),
  );
  const userPolicy = getUserPolicy(computedProperties, company.id, policies);

  return {
    id: data.id,
    email: data.email,
    firstName: data.first_name,
    lastName: data.last_name,
    fullname: data.fullname,
    isOrganisationOwner: data.is_organisation_owner,
    mobileExt: data.mobile_ext,
    mobileNo: data.mobile_no,
    pending: data.pending,
    isPending: data.pending,
    inviteId: invites.find((invite) => invite.invitedUserId === data.id)?.id,
    costCenter: membersCostCenter[data.id],
    lang: data.lang,
    gender: data.gender,
    createdAt: data.created_at,
    avatar: data.avatar,
    bankInfo: data.data_by_company[company.id].bank_info,
    hasPlasticCard: Boolean(data.data_by_company[company.id].plastic_card),
    displayName: getMemberName({
      email: data.email,
      firstName: data.first_name,
      fullname: data.fullname,
      pending: data.pending,
    }),
    hasSlackEnabled: computedProperties.has_slack_enabled,
    hasSlackLinked: computedProperties.has_slack_linked,
    isAccountOwner: computedProperties.is_account_owner,
    isAdmin: computedProperties.is_admin,
    isController: computedProperties.is_controller,
    isDeleted: computedProperties.is_deleted,
    isGroupAdmin: computedProperties.is_group_admin,
    isRequester: computedProperties.is_requester,
    // @ts-expect-error spending policy are not all typed properly
    spendingPolicy: computedProperties.spending_policy,
    requiredInfoTypesForEmployeeBankAddress:
      data.data_by_company[company.id]
        .requiredInfoTypesForEmployeeBankAddress ?? [],
    teams: teams.filter(({ id }) =>
      data.data_by_company[company.id].groups_ids?.includes(id),
    ),
    plasticCard: data.data_by_company[company.id].plastic_card,
    subscriptionsCount: subscriptionCount?.count ?? 0,
    controlRule,
    policy: userPolicy,
    cardsAccess,
    manager: data.manager
      ? {
          pending: data.manager.pending,
          fullNameOrEmail: data.manager.fullNameOrEmail,
          avatar: undefined,
          id: data.manager.id,
        }
      : null,
  };
};

export const reshapeMemberDetails = (
  data: RawMember,
  params: {
    company: { id: string };
    teams: Team[];
    controlRules: ControlRule[];
    policies: Policy[];
    subscriptionCount?: { count: number };
    invites: Invite[];
    membersCostCenter: MembersCostCenter;
    cardsAccess?: CardsAccess;
    canConfirmPayments: boolean;
  },
): MemberDetails => {
  const member = reshapeMember(data, params);

  return {
    ...member,
    canConfirmPayments: params.canConfirmPayments,
  };
};

type RawPermission = {
  id: string;
  name: string;
  code: string;
  allow: boolean;
  is_default: boolean;
  is_user_custom: boolean;
  params?: {
    approval_needed: 'always' | 'sometimes' | 'never';
    spending_limit: number;
    spending_types: string;
    transaction_max: number;
  };
};

const reshapePolicy = (permission: RawPermission): Policy => {
  if (!permission.params) {
    throw new Error('Policy needs to have params');
  }
  return {
    id: permission.id,
    name: permission.name,
    isDefault: permission.is_default,
    isCustom: permission.is_user_custom,
    params: {
      approvalNeeded: permission.params.approval_needed,
      amountPerTransaction: permission.params.transaction_max,
      amountPerMonth: permission.params.spending_limit,
      spendingTypes: reshapeToSpendingTypes(permission.params.spending_types),
    },
  };
};

const getUserPolicy = (
  user: RawMember,
  companyId: string,
  policies: Policy[],
): Policy | null => {
  const isRequester = user?.data_by_company?.[companyId]?.is_requester;
  const isAccountOwner = user?.data_by_company?.[companyId]?.is_account_owner;
  const canUserHavePolicy = isRequester && !isAccountOwner;

  if (!canUserHavePolicy) {
    return null;
  }

  const userPolicy =
    user?.data_by_company?.[companyId].legacy_spending_policy || null;

  if (userPolicy === null) {
    return null;
  }

  return userPolicy.is_user_custom
    ? reshapePolicy(userPolicy)
    : policies.find((policy) => policy.id === userPolicy?.id) || null;
};

const getMemberName = (
  member: Pick<Member, 'pending' | 'email' | 'fullname' | 'firstName'>,
): string => {
  return member.pending
    ? member.email
    : (member.fullname ?? member.firstName ?? member.email);
};
