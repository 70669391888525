import { useContext } from 'react';
import { useQueryClient } from 'react-query';

import { type Group } from 'modules/bookkeep/types';
import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';
import { withoutOptionalFields } from 'src/core/common/components/FilterBuilder';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useActivePayableFilter } from 'src/core/modules/payable/components';
import { getToPreparePayableGroupsQueryKey } from 'src/core/modules/payable/hooks';

import { GET_PAYABLES_GROUPS } from './queries';
import { reshapeGroup, toAPIPayableGroupType, type RawGroup } from './reshaper';
import { PreparePayablesFiltersContext } from '../../../../contexts';
import {
  type BucketType,
  toAPIPayableFilters,
  type PayableFilters,
  toAPIPayableFiltersV2,
} from '../../../../models';

/**
 * Query and cache config
 */

type RawData = {
  company: {
    payablesGrouped: {
      totalCount: number;
      pageInfo: {
        hasNextPage: boolean;
      };
      edges: {
        cursor: string;
        node: RawGroup;
      }[];
    };
  };
};

export const useInvalidatePayableGroupsQueryCache = () => {
  const queryClient = useQueryClient();

  return async (
    companyId: string,
    groupBy?: string | undefined,
    reshapedFilters?: PayableFilters,
  ): Promise<void> => {
    await queryClient.invalidateQueries(
      getToPreparePayableGroupsQueryKey(companyId, groupBy, reshapedFilters),
    );
  };
};

/**
 * GraphQL query hook
 */

export const usePayableGroupsQuery = (
  companyId: string,
  bucketType: BucketType,
): InfiniteQueryState<Group[]> => {
  const filtersContext = useContext(PreparePayablesFiltersContext);
  const filtersV2 = useActivePayableFilter()?.filter;
  const filtersV2Enabled = useFeature(FEATURES.TMP_PAYABLE_FILTERS);

  const { t } = useTranslation('global');
  const filters = filtersContext.state;
  const { group } = filters;
  const queryClient = useQueryClient();

  const groupBy = toAPIPayableGroupType(group);
  const reshapedFilters = toAPIPayableFilters({
    filters,
    bucketType,
    withBookkeepingStartDate: true,
  });

  const filtersV2ForAPI = filtersV2Enabled
    ? toAPIPayableFiltersV2({
        filters: filtersV2 ? withoutOptionalFields(filtersV2) : undefined,
        bucketType,
        withBookkeepingStartDate: true,
      })
    : undefined;

  return useInfiniteQuery<Group, RawData>({
    key: getToPreparePayableGroupsQueryKey(
      companyId,
      groupBy,
      reshapedFilters,
      filtersV2ForAPI,
      filters.search,
    ),
    getRequest: (cursor) => ({
      type: 'graphQL',
      target: 'v2',
      query: GET_PAYABLES_GROUPS,
      variables: {
        groupBy,
        filters: reshapedFilters,
        filtersV2: filtersV2ForAPI,
        after: cursor,
        textualSearch: filters.search,
      },
    }),
    getNextPageParam: (data) => {
      const { edges, pageInfo } = data.company.payablesGrouped;
      const last = edges.at(-1);

      if (pageInfo.hasNextPage && last) {
        return last.cursor;
      }
      return undefined;
    },
    reshapeData: (data) => {
      const { edges } = data.company.payablesGrouped;

      return edges.map(({ node }) => {
        const reshapedGroup = reshapeGroup(node, group, t);
        queryClient.setQueryData<Group>(
          ['payableGroup', reshapedGroup.id, bucketType],
          reshapedGroup,
        );
        return reshapedGroup;
      });
    },
  });
};
