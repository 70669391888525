import { Callout, FormField, TextArea } from '@dev-spendesk/grapes';
import { useMemo } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import {
  MAX_CHAR_PER_CUSTOM_FIELDS,
  MAX_VALUES_PER_CUSTOM_FIELD,
  validate,
} from 'modules/custom-fields/components/CustomFieldCreationModal/validate';
import { convertValues } from 'modules/custom-fields/utils/convertValues';

type Props = {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  showErrors: boolean;
  values: string;
};

export const CustomFieldValuesFormField = ({
  onChange,
  showErrors,
  values,
}: Props) => {
  const { t } = useTranslation('global');

  const convertedCustomFieldValues = convertValues(values);

  const errors = useMemo(() => {
    if (!showErrors) {
      return {};
    }
    return validate({ customFieldValues: values });
  }, [showErrors, values]);

  return (
    <div>
      <FormField label={t('customFields.creation.valuesLabel')}>
        <TextArea
          name="customFieldValues"
          isInvalid={Object.keys(errors).length > 0}
          onChange={onChange}
          placeholder={t('customFields.creation.valuesPlaceholder')}
          rows={7}
          value={values}
        />
      </FormField>
      {/* Grapes hacking time: custom error callouts instead of alertMessage prop because we can have multiple */}
      <div className="mt-8 flex flex-col gap-8 empty:hidden">
        {errors.tooManyCustomFieldValues && (
          <Callout
            variant="alert"
            title={t('customFields.creation.tooManyValuesCallout', {
              max: MAX_VALUES_PER_CUSTOM_FIELD,
              current: convertedCustomFieldValues.length,
            })}
          />
        )}
        {errors.tooManyCharacters && (
          <Callout
            variant="alert"
            title={t('customFields.creation.tooManyCharactersCallout', {
              max: MAX_CHAR_PER_CUSTOM_FIELDS,
            })}
          />
        )}
        {errors.oneCustomFieldValueRequired && (
          <Callout
            variant="alert"
            title={t('customFields.creation.notEnoughValues')}
          />
        )}
      </div>
    </div>
  );
};
