import { Button, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { BulkImportExpenseAccountForm } from './BulkImportExpenseAccountForm';

type Props = {
  onUpload(file: File, prefixWithCode: boolean): Promise<void>;
  onCancel(): void;

  isOpen: boolean;
};

export const BulkImportExpenseAccountModal = ({
  onUpload,
  onCancel,
  isOpen,
}: Props) => {
  const { t } = useTranslation('global');
  const [expenseAccountFile, setExpenseAccountFile] = useState<File>();
  const [errorMissingFile, setErrorMissingFile] = useState(false);
  const [prefixWithCode, setPrefixWithCode] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      iconName="circle-plus"
      iconVariant="info"
      title={t(
        'bookkeep.integrations.settings.expenseAccountsTable.import.title',
      )}
      subtitle={t(
        'bookkeep.integrations.settings.expenseAccountsTable.import.subtitle',
      )}
      actions={[
        <Button
          key="no"
          onClick={onCancel}
          text={t('misc.cancel')}
          variant="secondaryNeutral"
        />,
        <Button
          key="yes"
          text={t(
            `bookkeep.integrations.settings.expenseAccountsTable.import.uploadButton`,
          )}
          variant="primaryBrand"
          onClick={async () => {
            if (!expenseAccountFile) {
              setErrorMissingFile(true);
              return;
            }
            await onUpload(expenseAccountFile, prefixWithCode);
          }}
        />,
      ]}
    >
      <div className="my-32">
        <BulkImportExpenseAccountForm
          file={expenseAccountFile}
          hasMissingFileError={errorMissingFile}
          prefixNameWithCode={prefixWithCode}
          setFile={setExpenseAccountFile}
          setHasMissingFileError={setErrorMissingFile}
          setPrefixNameWithCode={setPrefixWithCode}
        />
      </div>
    </Modal>
  );
};
