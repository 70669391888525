import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName } from 'src/core/utils/analytics';

type ActionConfig = {
  key: string;
  link: string;
  title: string;
  analyticEvent: AnalyticEventName;
};

export const useGetNewAccountActionsConfig = (
  companyId: string,
  user: {
    is_admin: boolean;
    is_account_owner: boolean;
  },
): ActionConfig[] => {
  const { t } = useTranslation('global');

  return [
    ...(user.is_account_owner || user.is_admin
      ? [
          {
            key: 'inviteTeammates',
            title: t('homepage.todo.inviteTeammates'),
            link: `${routeFor(routes.COMPANY_MEMBERS.path, {
              company: companyId,
            })}`,
            analyticEvent:
              AnalyticEventName.HOMEPAGE_INVITE_TEAMMATES_BUTTON_CLICKED,
          },
        ]
      : []),
    {
      key: 'setupAccounting',
      title: t('homepage.todo.setupAccounting'),
      link: `${routeFor(routes.COMPANY_ACCOUNTING.path, {
        company: companyId,
      })}`,
      analyticEvent: AnalyticEventName.HOMEPAGE_SETUP_ACCOUNTING_BUTTON_CLICKED,
    },
  ];
};

export const useGetActionsConfig = (
  actions: {
    requestsToReview: number;
    invoicesToPay: number;
    invoiceTransfersToConfirm?: number;
    expenseTransfersToConfirm?: number;
    paymentsWithoutReceipts: number;
    paymentsToPrepare: number;
  },
  companyId: string,
): ActionConfig[] => {
  const { t } = useTranslation('global');

  return [
    {
      key: 'requestsToReview',
      title: t('homepage.todo.requestsToReview'),
      link: `${routeFor(routes.REQUESTS.path, {
        company: companyId,
        type: 'all',
      })}?status=pending&toApprove=true`,
      analyticEvent:
        AnalyticEventName.HOMEPAGE_REQUESTS_TO_REVIEW_BUTTON_CLICKED,
    },
    {
      key: 'invoicesToPay',
      title: t('homepage.todo.invoicesToPay'),
      link: `${routeFor(routes.INVOICES_PAY.path, {
        company: companyId,
      })}`,
      analyticEvent: AnalyticEventName.HOMEPAGE_INVOICES_TO_PAY_BUTTON_CLICKED,
    },
    {
      key: 'invoiceTransfersToConfirm',
      title: t('homepage.todo.invoiceTransfersToConfirm'),
      link: `${routeFor(routes.INVOICES_CONFIRM.path, {
        company: companyId,
      })}`,
      analyticEvent:
        AnalyticEventName.HOMEPAGE_INVOICE_TRANSFERS_TO_CONFIRM_BUTTON_CLICKED,
    },
    {
      key: 'expenseTransfersToConfirm',
      title: t('homepage.todo.expenseTransfersToConfirm'),
      link: `${routeFor(routes.EXPENSE_CLAIMS_CONFIRM.path, {
        company: companyId,
      })}`,
      analyticEvent:
        AnalyticEventName.HOMEPAGE_EXPENSE_TRANSFERS_TO_CONFIRM_BUTTON_CLICKED,
    },
    {
      key: 'paymentsWithoutReceipts',
      title: t('homepage.todo.paymentsWithoutReceipts'),
      link: `${routeFor(routes.PAYMENTS_ALL.path, {
        company: companyId,
      })}?invoice=missing`,
      analyticEvent: AnalyticEventName.HOMEPAGE_MISSING_RECEIPTS_BUTTON_CLICKED,
    },
    {
      key: 'paymentsToPrepare',
      title: t('homepage.todo.paymentsToPrepare'),
      link: `${routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
        company: companyId,
      })}`,
      analyticEvent:
        AnalyticEventName.HOMEPAGE_PAYMENTS_TO_PREPARE_BUTTON_CLICKED,
    },
  ].filter((actionConfig) => {
    const action = actions[actionConfig.key as keyof typeof actions];
    return action && action > 0;
  });
};
