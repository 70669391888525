/* eslint-disable @typescript-eslint/naming-convention */
import cx from 'classnames';
import { type MonetaryValue, add } from 'ezmoney';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  type Breakdown,
  getExceededAmount,
  getSpentAmount,
  hasExceededAmounts,
} from '../../models/breakdown';

type Props = {
  amount: MonetaryValue;
  breakdown: Breakdown;
  mode: 'compact' | 'medium' | 'full';
};

export const BudgetAmountEstimation = ({ breakdown, amount, mode }: Props) => {
  const { t } = useTranslation('global');

  const isCompact = mode === 'compact';
  const isMedium = mode === 'medium';

  const isExceeded = hasExceededAmounts(breakdown);
  const middleText = isExceeded
    ? t('budget.gauge.on')
    : t('budget.gauge.impactedOn');
  const sum = isExceeded
    ? add(amount, getExceededAmount(breakdown))
    : getSpentAmount(breakdown, false);

  return (
    <div className="mb-16 flex items-baseline gap-4 text-primary body-l">
      <span
        className={cx('text-primary body-xl', {
          'text-warning-default': isExceeded,
        })}
      >
        {formatMonetaryValue(sum)}
      </span>
      <span
        className={cx({
          'body-l': isMedium,
          'body-s': isCompact,
        })}
      >
        {middleText}
      </span>
      <span
        className={cx({
          'body-l': isMedium,
          'body-s': isCompact,
        })}
      >
        {formatMonetaryValue(amount)}
      </span>
    </div>
  );
};
