import { Button, Callout } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import { isIntegrationStatusWithIntegration } from '../../bookkeep/integration/status';
import { useAccountingIntegrationStatusQuery } from '../apis';
import { haveSettingsAtLeastOneValidationError } from '../models';

export const InvalidAccountingIntegrationSettingsFeedback = ({
  className = '',
}: {
  className?: string;
}) => {
  const { t } = useTranslation('global');

  const companyId = useCompanyId();

  const history = useHistory();

  const goToAccountingIntegrationSettings = () =>
    history.push({
      pathname: routeFor(routes.COMPANY_ACCOUNTING.path, {
        company: companyId,
      }),
    });

  const integrationStatusQuery = useAccountingIntegrationStatusQuery();

  const integrationStatus = unwrapQuery(integrationStatusQuery);

  if (
    integrationStatus &&
    isIntegrationStatusWithIntegration(integrationStatus) &&
    haveSettingsAtLeastOneValidationError(integrationStatus.settingsValidation)
  ) {
    return (
      <Callout
        variant="alert"
        title={t('accounting-integration.invalidSettingsFeedback.title')}
        className={className}
      >
        <p>{t('accounting-integration.invalidSettingsFeedback.description')}</p>
        <Button
          className="mt-24"
          variant="secondaryNeutral"
          text={t('accounting-integration.invalidSettingsFeedback.link')}
          onClick={goToAccountingIntegrationSettings}
        />
      </Callout>
    );
  }
};
