import { type DataSources, type Settings } from '../../models/invite';

export const getDefaultSettings = (dataSources: DataSources): Settings => {
  const { policies } = dataSources;

  return {
    role: 'requester',
    // In practice, there should be always at least the standard company policy.
    policy: policies.find((policy) => policy.isDefault),
    costCenter: undefined,
  };
};
