import { Button, Callout } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { BulkEditManagersModal } from './BulkEditManagersModal';
import { useGetCompanyManagedByKomboHrIntegrationQuery } from '../../hooks/useGetCompanyManagedByKomboHrIntegrationQuery';
import { type Member } from '../../models/member';

type Props = {
  membersWithMissingManagers: Member[];
};

export const ManagersWarningCallout = ({
  membersWithMissingManagers,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation('global');
  const isCompanyManagedByIntegrationQueryState =
    useGetCompanyManagedByKomboHrIntegrationQuery();

  const isCompanyManagedByIntegrationResult = unwrapQuery(
    isCompanyManagedByIntegrationQueryState,
  );
  const isCompanyManagedByIntegration =
    isCompanyManagedByIntegrationResult?.isManagedByKombo ?? false;
  const featureSet = isCompanyManagedByIntegrationResult?.featureSet ?? [];
  const includesReportingManagers = featureSet.includes(
    'includesReportingManagers',
  );

  const getDescription = () => {
    if (!isCompanyManagedByIntegration) {
      return t(
        'members.reportingManagers.missingManagersCallout.description.default',
      );
    }

    return includesReportingManagers
      ? t(
          'members.reportingManagers.missingManagersCallout.description.managerSyncEnabled',
        )
      : t(
          'members.reportingManagers.missingManagersCallout.description.managerSyncDisabled',
        );
  };

  return (
    <Callout
      data-testid="MissingManagersCallout"
      className="mb-16"
      variant="warning"
      title={t('members.reportingManagers.missingManagersCallout.title', {
        count: membersWithMissingManagers.length,
      })}
    >
      {getDescription()}
      {!includesReportingManagers && (
        <Button
          className="mt-16 flex"
          text={t('members.reportingManagers.missingManagersCallout.cta')}
          variant="primaryWarning"
          onClick={() => setIsModalOpen(true)}
        />
      )}
      <BulkEditManagersModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        membersWithMissingManagers={membersWithMissingManagers}
      />
    </Callout>
  );
};
