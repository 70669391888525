import {
  Avatar,
  DATE_FORMAT,
  Tooltip,
  useDateFormatter,
} from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';

import { type User } from '../../models';

type Props = {
  lastUpdater?: User;
  updatedAt: string;
};

export const SupplierBankInfoLastUpdater = ({
  lastUpdater,
  updatedAt,
}: Props) => {
  const { t } = useTranslation('global');

  const dateFormatter = useDateFormatter();

  const formattedUpdatedAt = updatedAt
    ? dateFormatter(new Date(updatedAt), DATE_FORMAT.MEDIUM)
    : '';

  return lastUpdater ? (
    <Tooltip content={lastUpdater.name} triggerAsChild placement="right">
      <div className="flex items-center">
        <Avatar
          size="s"
          className="mr-8"
          src={lastUpdater.avatar}
          text={lastUpdater.name}
        />
        <span className="text-secondary-bg-primary">
          {t('suppliers.latestUpdater.edited')} {formattedUpdatedAt}{' '}
        </span>
      </div>
    </Tooltip>
  ) : (
    <span className="text-secondary-bg-primary">
      {t('suppliers.latestUpdater.edited')} {formattedUpdatedAt}{' '}
    </span>
  );
};
