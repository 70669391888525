// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-hot-loader';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles.css';

// Register HTTP interceptors + auto-disconnect after period of inactivity
import './utils/interceptors';
import '../utils/datadog-rum-init';
import '../utils/datadog-log-init';
import { assignVersionToWindow } from './common/utils/debugVersion';
import { App } from './modules/app';

import '../css/app.css';

if (!window.analytics) {
  window.analytics = null;
}

assignVersionToWindow();

if (document) {
  const root = createRoot(document.body);
  root.render(<App />);
}
