import { AmountInput, Button, FormField, Modal } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { formatMoney, getCurrencyOptionByKey } from 'src/core/utils/money';

import { type ApiCard } from '../../card';
import { useUnloadCardMutation } from '../../hooks/useUnloadCardMutation';

type Props = {
  card: ApiCard;
  onProcessed: (card: ApiCard) => void;
  onCancel: () => void;
};

// The modal that asks for proper data, sync w/ API and send back status
export const UnloadModal = ({ card, onProcessed, onCancel }: Props) => {
  const { t } = useTranslation('global');

  const company = useCompany();

  const [unloadCard] = useUnloadCardMutation(card.id);

  const formikProps = useFormik<{ amount: number | null }>({
    initialValues: {
      amount: null,
    },
    validateOnChange: false,
    validate: (values) => {
      const errors: FormikErrors<{ amount: number | null }> = {};

      if (
        !values.amount ||
        Number.isNaN(values.amount) ||
        values.amount < 0 ||
        !/^\d+(\.|,)?\d*$/.test(String(values.amount))
      ) {
        errors.amount = t('cards.actions.errorUnloadInvalidAmount');
      }

      if (values.amount && values.amount > card.available) {
        errors.amount = t('cards.actions.errorUnloadNoFundsWithAmount', {
          amount: formatMoney(values.amount, company.currency),
        });
      }

      return errors;
    },
    onSubmit: async (values) => {
      if (values.amount) {
        const updatedCard = await unloadCard({ amount: values.amount });
        onProcessed(updatedCard);
      }
    },
  });

  return (
    <Modal
      isOpen
      iconName="card-arrow-down"
      title={t('cards.actions.decreaseCardModalTitle', {
        name: card.user.first_name,
      })}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={onCancel}
          isDisabled={formikProps.isSubmitting}
        />,
        <Button
          key="submit"
          type="submit"
          text={t('cards.actions.decreaseCardLimit')}
          form="unload-card"
          isLoading={formikProps.isSubmitting}
        />,
      ]}
    >
      <form id="unload-card" onSubmit={formikProps.handleSubmit}>
        <FormField
          label={t('forms.unloadCard.limitLabel')}
          alertMessage={formikProps.errors.amount}
        >
          <AmountInput
            fit="parent"
            name="amount"
            placeholder={t('forms.unloadCard.placeholder')}
            value={formikProps.values.amount}
            currency={getCurrencyOptionByKey(company.currency)}
            onChange={(_, newValue) => {
              formikProps.setFieldValue('amount', newValue);
            }}
          />
        </FormField>
      </form>
    </Modal>
  );
};
