/* eslint-disable @typescript-eslint/naming-convention */

import { getRoutes } from 'src/core/constants/routes';

export const routes = getRoutes({
  SETUP_HUB_TASK_LIST: {
    path: '/app/:company/setup-hub',
    analytics: {
      category: 'Setup Hub',
      name: 'task_list',
    },
  },
  CONTROL_SETTINGS_NEW_EXPENSE_CATEGORY: {
    path: '/app/:company/setup-hub/control-settings/expense-categories/new',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_new_expense_category',
    },
  },
  CONTROL_SETTINGS_EXPENSE_CATEGORY_LIST: {
    path: '/app/:company/setup-hub/control-settings/expense-categories/list',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_expense_category_list',
    },
  },
  CONTROL_SETTINGS_CUSTOM_FIELD_NAME: {
    path: '/app/:company/setup-hub/control-settings/analytical-fields/name',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_custom_field_name',
    },
  },
  CONTROL_SETTINGS_CUSTOM_FIELD_VALUES: {
    path: '/app/:company/setup-hub/control-settings/analytical-fields/values',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_custom_field_values',
    },
  },
  CONTROL_SETTINGS_CUSTOM_FIELD_LIST: {
    path: '/app/:company/setup-hub/control-settings/analytical-fields/list',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_custom_field_list',
    },
  },
  CONTROL_SETTINGS_CONTROL_RULES: {
    path: '/app/:company/setup-hub/control-settings/control-rules',
    analytics: {
      category: 'Setup Hub',
      name: 'control_settings_control_rules',
    },
  },
  ACCOUNTING_SOFTWARE: {
    path: '/app/:company/setup-hub/accounting/software',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_software',
    },
  },
  ACCOUNTING_BANK_ACCOUNT: {
    path: '/app/:company/setup-hub/accounting/bank-accounts',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_bank_accounts',
    },
  },
  ACCOUNTING_EXPENSE_ACCOUNTS: {
    path: '/app/:company/setup-hub/accounting/expense-accounts',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_expense_accounts',
    },
  },
  ACCOUNTING_EXPENSE_ACCOUNTS_LIST: {
    path: '/app/:company/setup-hub/accounting/expense-accounts/list',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_expense_accounts_list',
    },
  },
  ACCOUNTING_VAT_ACCOUNTS: {
    path: '/app/:company/setup-hub/accounting/vat-accounts',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_vat_accounts',
    },
  },
  ACCOUNTING_REVERSE_CHARGE_ACCOUNTS: {
    path: '/app/:company/setup-hub/accounting/reverse-charge-accounts',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_reverse_charge_accounts',
    },
  },
  ACCOUNTING_EMPLOYEE_ACCOUNTS: {
    path: '/app/:company/setup-hub/accounting/employee-accounts',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_employee_accounts',
    },
  },
  ACCOUNTING_SUPPLIER_ACCOUNTS: {
    path: '/app/:company/setup-hub/accounting/supplier-accounts',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_supplier_accounts',
    },
  },
  ACCOUNTING_SUPPLIER_ACCOUNTS_LIST: {
    path: '/app/:company/setup-hub/accounting/supplier-accounts/list',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_supplier_accounts_list',
    },
  },
  ACCOUNTING_SUPPLIER_ACCOUNTS_DEFAULT: {
    path: '/app/:company/setup-hub/accounting/supplier-accounts/default',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_supplier_accounts_default',
    },
  },
  ACCOUNTING_EXPORT_SETTINGS: {
    path: '/app/:company/setup-hub/accounting/export/settings',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_export_settings',
    },
  },
  ACCOUNTING_EXPORT_COLUMNS: {
    path: '/app/:company/setup-hub/accounting/export/columns',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_export_columns',
    },
  },
  ACCOUNTING_RECEIPT_FILE_NAME: {
    path: '/app/:company/setup-hub/accounting/receipt-file-name',
    analytics: {
      category: 'Setup Hub',
      name: 'accounting_receipt_file_name',
    },
  },
});
