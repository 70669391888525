import { Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { SimplePanelItem, type SimpleItem } from './SimplePanelItem';

export type ExpandableItem = {
  label: string;
  value?: never;
  deleted?: boolean;
  values: SimpleItem[];
};

export const ExpandablePanelItem = ({ item }: { item: ExpandableItem }) => {
  const { t } = useTranslation('global');

  return (
    <li>
      {[
        <>
          <div
            className={cx(
              'w-full',
              item.deleted &&
                'my-8 border-0 border-l border-solid border-warning',
            )}
          >
            <div
              className={cx(
                item.deleted && 'mx-8',
                'flex flex-row items-center justify-between pr-12',
              )}
            >
              <div className="text-secondary-bg-primary body-m">
                {item.label}
              </div>
            </div>
            {item.deleted && (
              <div className="mx-8 flex flex-row items-center space-x-4 text-warning-default body-m">
                <Icon name="triangle-warning" size="s" />
                <span>
                  {t('common.panelItemsSection.removed', {
                    item: item.label,
                  })}
                </span>
              </div>
            )}
          </div>

          <div
            className={cx(
              'ml-16 mt-8 flex flex-col border-0 border-l border-solid border-default pl-16 text-primary body-m',
              item.deleted ? 'mb-4' : 'mb-16',
            )}
          >
            {item.values.map((values) => (
              <SimplePanelItem
                item={values}
                key={values.label}
                className="my-4"
              />
            ))}
          </div>
        </>,
      ]}
    </li>
  );
};
