import { Button, Tooltip } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import './CustomFieldsPageHeader.css';

type Props = {
  isButtonDisabled: boolean;
  onButtonClick: () => void;
};

export const CustomFieldsPageHeader = ({
  isButtonDisabled,
  onButtonClick,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="CustomFieldsPageHeader">
      <div className="CustomFieldsPageHeader__title">
        <div className="CustomFieldsPageHeader__title__main heading-l">
          {t('expenseCategories.analyticalFields')}
        </div>
        <div className="CustomFieldsPageHeader__title__secondary body-m">
          {t('expenseCategories.analyticalFieldsDescription')}
        </div>
      </div>
      <div className="CustomFieldsPageHeader__button">
        <Tooltip
          isDisabled={!isButtonDisabled}
          content={t('expenseCategories.newAnalyticalFieldTooltip')}
        >
          <Button
            isDisabled={isButtonDisabled}
            onClick={onButtonClick}
            text={t('expenseCategories.newAnalyticalField')}
          />
        </Tooltip>
      </div>
    </div>
  );
};
