import { colors } from '@dev-spendesk/grapes';
import React from 'react';

type Props = {
  totalNumberOfSegments: number;
  filledNumberOfSegments: number;
  isOptimistic: boolean;
  text: string;
};

export const SemiCircularSegmentedGauge = ({
  totalNumberOfSegments,
  filledNumberOfSegments,
  isOptimistic,
  text,
}: Props) => {
  const sweepAngle = 180 / totalNumberOfSegments;
  const gapAngle = 9;
  const actualSweepAngle = sweepAngle - gapAngle;
  const radius = 104;
  const svgWidth = 216;
  const svgHeight = 104;
  const centerX = svgWidth / 2;
  const centerY = svgHeight;
  const strokeWidth = 13;

  const generateSegmentPath = (index: number) => {
    const startAngle = sweepAngle * index - 180 + gapAngle / 2;
    const endAngle = startAngle + actualSweepAngle;

    const theta1Rad = (startAngle * Math.PI) / 180;
    const theta2Rad = (endAngle * Math.PI) / 180;

    const startX = centerX + radius * Math.cos(theta1Rad);
    const startY = centerY + radius * Math.sin(theta1Rad);

    const endX = centerX + radius * Math.cos(theta2Rad);
    const endY = centerY + radius * Math.sin(theta2Rad);

    const largeArcFlag = sweepAngle <= 180 ? '0' : '1';

    return `M ${startX},${startY} A ${radius},${radius}  0 ${largeArcFlag} ,1 ${endX},${endY}`;
  };

  const segments = Array.from({ length: totalNumberOfSegments }, (_, index) => (
    <path
      key={Math.random()}
      d={generateSegmentPath(index)}
      stroke={
        index < filledNumberOfSegments
          ? colors.contentAlertDefault
          : colors.backgroundSecondaryAlertDefault
      }
      strokeWidth={strokeWidth}
      fill="none"
      strokeLinecap="round"
    />
  ));
  const viewBox = `-${strokeWidth / 2} -${strokeWidth} ${
    svgWidth + strokeWidth
  } ${svgHeight + strokeWidth}`;
  return (
    <div className="relative" style={{ width: svgWidth }}>
      <div className="absolute bottom-8 left-0 flex w-full justify-center">
        <div className="flex flex-col items-center">
          <div className="text-32 leading-[34px] text-alert-default">
            {`${filledNumberOfSegments}${isOptimistic ? '+' : ''}/${totalNumberOfSegments}`}
          </div>
          <div className="neutral-darker body-m">{text}</div>
        </div>
      </div>
      <svg width={svgWidth} height={svgHeight} viewBox={viewBox}>
        {segments}
      </svg>
    </div>
  );
};
