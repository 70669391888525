import { Icon, Link } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

export const SpanishDPRCheckmarkFileCard = ({
  state,
}: {
  state: 'ongoing' | 'done' | 'not-applicable';
}) => {
  const { t } = useTranslation('global');

  const isDone = state === 'done';
  const iconName = isDone ? 'circle-check' : 'clock-filled';

  return (
    <div className="flex flex-row items-center rounded-b bg-secondary-default px-16 py-4 text-primary body-m">
      <Icon name={iconName} size="s" className="mr-4" />
      <span className="mr-4">
        {state === 'ongoing'
          ? t('payables.spanishDPR.ongoingTitle')
          : t('payables.spanishDPR.doneTitle')}
      </span>
      <Link
        className="text-primary body-m"
        href={t('payables.spanishDPR.readMoreLink')}
        isExternal
      >
        {t('payables.spanishDPR.readMoreCta')}
      </Link>
    </div>
  );
};
