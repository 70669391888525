import { Table, type TableMiniColumn } from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { BankFields } from 'src/core/utils/bankInfoFormats';
import { useBankInfoFieldsCombo } from 'src/core/utils/useBankInfo';

import { type SupplierBankInfos } from '../../models';
import { mask, IBANCountriesMasks } from '../SupplierBankInfoForm/helper';

type Props = {
  className?: string;
  filter?: (keyof SupplierBankInfos)[];
  bankInfos: SupplierBankInfos;
};

type TableField = {
  id: string;
  bankField: string;
  bankFieldValue: string;
};

const columns: TableMiniColumn<TableField>[] = [
  {
    id: 'bankField',
    width: '80px',
    renderCell: ({ bankField }) => <div className="truncate">{bankField}</div>,
  },
  {
    id: 'bankFieldValue',
    renderCell: ({ bankFieldValue }) => bankFieldValue,
  },
];

const mappingToBankInfosFields = {
  [BankFields.Iban]: 'iban',
  [BankFields.BicSwift]: 'bic',
  [BankFields.AccountNumber]: 'accountNumber',
  [BankFields.AccountCode]: 'accountCode',
  [BankFields.SortCode]: 'sortCode',
  [BankFields.RoutingNumber]: 'routingNumber',
  [BankFields.AccountHolderName]: 'accountHolderName',
};

export const SupplierBankInfoPreview = ({
  className,
  filter = ['accountCode', 'accountNumber', 'bic', 'iban', 'sortCode'],
  bankInfos,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: Props) => {
  const fields: TableField[] = [];

  const { t } = useTranslation('global');
  const company = useCompany();

  const undefinedValue = t('submitMyInvoice.fields.undefined');

  const bankInfoFields = useBankInfoFieldsCombo()(
    bankInfos.bankCountry ?? company.country,
  );

  const mappedBankInfoFields = bankInfoFields.map(
    (field) => mappingToBankInfosFields[field],
  );

  const getFieldValue = (value: string | undefined) => {
    return value && value !== '' ? value : undefinedValue;
  };

  if (filter.includes('iban') && mappedBankInfoFields.includes('iban')) {
    const IbanMask = bankInfos.bankCountry
      ? IBANCountriesMasks[bankInfos.bankCountry]
      : undefined;
    const formattedIban =
      IbanMask && bankInfos.iban
        ? mask(bankInfos.iban, IbanMask)
        : (bankInfos.iban ?? undefined);

    fields.push({
      id: 'iban',
      bankField: t('submitMyInvoice.fields.iban.label'),
      bankFieldValue: getFieldValue(formattedIban),
    });
  }

  if (filter.includes('bic') && mappedBankInfoFields.includes('bic')) {
    fields.push({
      id: 'bic',
      bankField: t('submitMyInvoice.fields.bic.label'),
      bankFieldValue: getFieldValue(bankInfos.bic),
    });
  }

  if (
    filter.includes('accountNumber') &&
    mappedBankInfoFields.includes('accountNumber')
  ) {
    fields.push({
      id: 'accountNumber',
      bankField: t('submitMyInvoice.fields.accountNumber.label'),
      bankFieldValue: getFieldValue(bankInfos.accountNumber),
    });
  }

  if (
    filter.includes('accountCode') &&
    mappedBankInfoFields.includes('accountCode')
  ) {
    fields.push({
      id: 'accountCode',
      bankField: t('submitMyInvoice.fields.accountCode.label'),
      bankFieldValue: getFieldValue(bankInfos.accountCode),
    });
  }

  if (
    filter.includes('sortCode') &&
    mappedBankInfoFields.includes('sortCode')
  ) {
    fields.push({
      id: 'sortCode',
      bankField: t('submitMyInvoice.fields.sortCode.label'),
      bankFieldValue: getFieldValue(bankInfos.sortCode),
    });
  }

  return fields.length ? (
    <Table.Mini
      getRowId={(row) => row.id}
      className={classNames('bg-primary-default', className)}
      data={fields}
      columns={columns}
    />
  ) : null;
};
