import { Button, SkeletonButton } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense/';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { SupplierBankInfoLastUpdater } from './SupplierBankInfoLastUpdater';
import { SupplierBankInfoPreview } from './SupplierBankInfoPreview';
import { useFetchSupplier } from '../../hooks';
import { useFetchSupplierBankInfosHistory } from '../../hooks/api/useFetchSupplierBankInfosHistory';
import { type SupplierBankInfos, type SupplierId } from '../../models';
import { SupplierHistoryModal } from '../SupplierHistoryModal/SupplierHistoryModal';

type Props = {
  className?: string;
  filter?: (keyof SupplierBankInfos)[];
  supplierId: SupplierId;
};

export const SupplierBankInfoPreviewContainer = ({
  className,
  filter,
  supplierId,
}: Props) => {
  const supplierQueryState = useFetchSupplier(supplierId);

  const supplierBankInfosHistoryQueryState =
    useFetchSupplierBankInfosHistory(supplierId);

  const queryStates = useQueryStates({
    states: {
      supplierData: supplierQueryState,
      supplierBankInfosHistory: supplierBankInfosHistoryQueryState,
    },
    reshapeData: ({ supplierData, supplierBankInfosHistory }) => ({
      supplierDetails: supplierData.supplierDetails,
      lastBankInfosUpdate:
        supplierBankInfosHistory && supplierBankInfosHistory.length
          ? supplierBankInfosHistory[0]
          : null,
    }),
  });

  const { t } = useTranslation('global');

  const [isSupplierHistoryModalOpen, setIsSupplierHistoryModalOpen] =
    useState<boolean>(false);

  return (
    <QuerySuspense
      queryState={queryStates}
      loading={<SkeletonButton width="100%" />}
      fallback={(error) => (
        <QueryError queryError={error} componentType="Notification" />
      )}
    >
      {({ supplierDetails, lastBankInfosUpdate }) => (
        <>
          {!!supplierDetails.bankInfos && (
            <SupplierBankInfoPreview
              className={className}
              filter={filter}
              bankInfos={supplierDetails.bankInfos}
            />
          )}

          {lastBankInfosUpdate && (
            <div className="flex items-center justify-between pt-16">
              <SupplierBankInfoLastUpdater
                lastUpdater={lastBankInfosUpdate.updater}
                updatedAt={lastBankInfosUpdate.updatedAt}
              />

              <Button
                onClick={() => {
                  setIsSupplierHistoryModalOpen(true);
                  track(
                    AnalyticEventName.SUPPLIER_BANK_INFOS_HISTORY_BUTTON_CLICKED,
                    {},
                  );
                }}
                variant="tertiaryBrand"
                text={t('suppliers.latestUpdater.historyModal.cta')}
                hasNoHorizontalPadding
              />
            </div>
          )}

          <SupplierHistoryModal
            isOpen={isSupplierHistoryModalOpen}
            supplierId={supplierDetails.id}
            onClose={() => setIsSupplierHistoryModalOpen(false)}
          />
        </>
      )}
    </QuerySuspense>
  );
};
