import { HighlightIcon } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import type {
  BankDetailsSuggestion,
  DraftSupplier,
  SupplierDetails,
} from '../../models';
import { SupplierBankInfoForm } from '../SupplierBankInfoForm';

type Props = {
  className: string;
  bankDetailsSuggestion?: BankDetailsSuggestion;
  draftSupplier: DraftSupplier;
  onCreate(): void;
  onCreateSupplierSuccess(supplier: SupplierDetails): void;
};

export const NewSupplierCreationWarning = ({
  className,
  bankDetailsSuggestion,
  draftSupplier,
  onCreate,
  onCreateSupplierSuccess,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <section
      className={classnames('rounded-12 bg-secondary-default p-16', className)}
    >
      <h3 className="title-l">
        <HighlightIcon name="pen" size="m" className="mr-8" variant="purple" />
        {t('submitMyInvoice.supplier.newSupplierCreated', {
          supplierName: draftSupplier.name,
        })}
      </h3>
      <SupplierBankInfoForm
        bankDetailsSuggestion={bankDetailsSuggestion}
        draftSupplier={draftSupplier}
        onSupplierCreationOrUpdateSuccess={onCreateSupplierSuccess}
        onCreate={onCreate}
      />
    </section>
  );
};
