import { FormField, TextInput } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { useIntegrationStatusQuery } from 'modules/bookkeep';
import { hasIntegrationFileBasedExport } from 'modules/bookkeep/integration/status';
import { useBankAccountsQuery } from 'modules/bookkeep/settings/integrations/hooks/useBankAccountsQuery';
import { useSetBankAccountsMutation } from 'modules/bookkeep/settings/integrations/hooks/useSetBankAccountsMutation';
import { useUpdateBankAccountsMutation } from 'modules/bookkeep/settings/integrations/hooks/useUpdateBankAccountsMutation';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { useUpdateCachedTaskStatus } from 'modules/onboarding/setup-hub/pages/SetupHubPage/hooks/useUpdateCachedTaskStatus';
import { routes } from 'modules/onboarding/setup-hub/pages/routes';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { routeFor } from 'src/core/constants/routes';

import type { FormValues } from './form';

type BankAccountPurpose = 'spendeskFs' | 'bankFees' | 'company';

export const AccountingBankAccounts = () => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();
  const companyId = useCompanyId();
  const history = useHistory();
  const updateCachedTaskStatus = useUpdateCachedTaskStatus();

  const bankAccountsQueryState = useBankAccountsQuery();
  const bankAccounts = unwrapQuery(bankAccountsQueryState);
  const [setBankAccounts] = useSetBankAccountsMutation(); // used for file based integrations
  const [updateBankAccounts] = useUpdateBankAccountsMutation(); // used for others

  const integrationStatusQueryState = useIntegrationStatusQuery();
  const isFileBasedIntegration =
    integrationStatusQueryState.status === 'success'
      ? hasIntegrationFileBasedExport(
          integrationStatusQueryState.data.integration,
        )
      : false;

  const getBankAccountCode = (purpose: BankAccountPurpose) => {
    if (!bankAccounts) {
      return null;
    }
    const bankAccountsForPurpose = bankAccounts[purpose];
    if (bankAccountsForPurpose) {
      return bankAccountsForPurpose.find((b) => b.accountPurpose === purpose);
    }
    return null;
  };

  const { values, errors, handleChange, handleSubmit, submitCount } =
    useFormik<FormValues>({
      initialValues: {
        spendeskFs: getBankAccountCode('spendeskFs')?.code || '',
        company: getBankAccountCode('company')?.code || '',
        bankFees: getBankAccountCode('bankFees')?.code || '',
      },
      enableReinitialize: true,
      validateOnChange: true,
      validate: (newValues) => {
        const errorsResult: FormikErrors<FormValues> = {};
        if (!newValues.spendeskFs.trim()) {
          errorsResult.spendeskFs = t('misc.requiredField');
        }
        if (!newValues.company.trim()) {
          errorsResult.company = t('misc.requiredField');
        }
        if (!newValues.bankFees.trim()) {
          errorsResult.bankFees = t('misc.requiredField');
        }
        return errorsResult;
      },
      onSubmit: async (newValues) => {
        try {
          if (isFileBasedIntegration) {
            const accountsUpdates = [];
            for (const purpose of [
              'spendeskFs',
              'company',
              'bankFees',
            ] as BankAccountPurpose[]) {
              const editedAccount = getBankAccountCode(purpose);
              if (editedAccount) {
                accountsUpdates.push({
                  id: editedAccount.id,
                  code: newValues[purpose].trim(),
                  currency: editedAccount.currency,
                  accountPurpose: purpose,
                });
              }
            }

            await setBankAccounts({
              accountsUpdates,
            });
          } else {
            await Promise.all([
              updateBankAccounts({
                capabilityKind: 'localOnly',
                purpose: 'spendeskFs',
                newCode: newValues.spendeskFs.trim(),
              }),
              updateBankAccounts({
                capabilityKind: 'localOnly',
                purpose: 'bankFees',
                newCode: newValues.bankFees.trim(),
              }),
              updateBankAccounts({
                capabilityKind: 'localOnly',
                purpose: 'company',
                newCode: newValues.company.trim(),
              }),
            ]);
          }
          updateCachedTaskStatus('bank_account_codes');
          const nextPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
            company: companyId,
          });
          history.push(nextPath);
        } catch {
          return dangerNotif(t('setupHub.bankAccounts.error'));
        }
      },
    });

  const onBack = () => {
    const previousPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(previousPath);
  };

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.bankAccounts.description')}
        isLastStep
        onBack={onBack}
        onNext={handleSubmit}
        title={t('setupHub.bankAccounts.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('setupHub.expenseAccounts.helpTitle'),
        }}
      >
        <form onSubmit={handleSubmit} className="flex flex-col gap-24">
          <FormField
            label={t(
              'bookkeep.integrations.settings.spendeskFsBankAccountTitle',
            )}
            description={t('setupHub.bankAccounts.spendeskFsDescription')}
            alertMessage={submitCount > 0 ? errors.spendeskFs : undefined}
          >
            <TextInput
              name="spendeskFs"
              value={values.spendeskFs}
              onChange={handleChange}
            />
          </FormField>
          <FormField
            label={t('bookkeep.integrations.settings.companyBankAccountTitle')}
            description={t('setupHub.bankAccounts.companyDescription')}
            alertMessage={submitCount > 0 ? errors.company : undefined}
          >
            <TextInput
              name="company"
              value={values.company}
              onChange={handleChange}
            />
          </FormField>
          <FormField
            label={t('bookkeep.integrations.settings.bankFeesAccountTitle')}
            description={t('setupHub.bankAccounts.bankFeesDescription')}
            alertMessage={submitCount > 0 ? errors.bankFees : undefined}
          >
            <TextInput
              name="bankFees"
              value={values.bankFees}
              onChange={handleChange}
            />
          </FormField>
        </form>
      </TaskStepLayout>
    </div>
  );
};
