import { SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const MemberEditTabSettingsLoader = () => {
  return (
    <>
      <SkeletonText size="m" className="mb-24" />
      <SkeletonText size="m" className="mb-24" />
      <SkeletonText size="m" className="mb-24" />
    </>
  );
};
