import {
  colors,
  Callout,
  Icon,
  DeprecatedPreview,
  SkeletonButton,
} from '@dev-spendesk/grapes';
import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense/';
import { useTranslation } from 'common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { usePurchaseOrderQuery } from '../../detail/hooks';
import { useGoToPurchaseOrder } from '../../hooks';

export type Props = {
  className?: string;
  purchaseOrderId: string;
};

export const PurchaseOrderPreview = ({
  purchaseOrderId,
  ...restProps
}: Props) => {
  const { t } = useTranslation('global');
  const purchaseOrderQueryState = usePurchaseOrderQuery(purchaseOrderId);
  const goToPurchaseOrder = useGoToPurchaseOrder();

  return (
    <QuerySuspense
      queryState={purchaseOrderQueryState}
      loading={<SkeletonButton width="100%" />}
      fallback={() => (
        <Callout
          title={t('forms.purchaseOrder.errorLoading')}
          variant="alert"
        />
      )}
    >
      {(purchaseOrder) => (
        <DeprecatedPreview
          fit="parent"
          primaryText={purchaseOrder.purchaseOrderNumber}
          secondaryText={formatMonetaryValue(purchaseOrder.amount)}
          onClick={() => goToPurchaseOrder(purchaseOrder)}
          rightAddon={
            <Icon name="chevron-right" color={colors.contentDecorativeIcon} />
          }
          {...restProps}
        />
      )}
    </QuerySuspense>
  );
};
