import { type Edge } from '@xyflow/react';

import { type EdgeData } from '../components/node-components/LabelEdge';
import { type CustomNode } from '../node';

export const getInitialEdge = (
  sourceId: string,
  targetId: string,
): Edge<EdgeData> => ({
  id: 'initialEdge',
  source: sourceId,
  target: targetId,
  type: 'labelEdge',
  data: {
    labelText: 'Or else',
    labelVariant: 'ocean',
    icon: 'circle-information',
  },
});

export const getStandardApprovalFlowNode = (
  nodeId: string,
  schemeId: string,
): CustomNode => {
  return {
    id: nodeId,
    position: { x: 0, y: 0 },
    draggable: false,
    type: 'standardApprovalFlowNode',
    data: {
      schemeId,
    },
  };
};

export const initialLabelNode: CustomNode = {
  id: 'initialLabelNode',
  position: { x: 0, y: 0 },
  draggable: false,
  type: 'labelNode',
  data: {
    nodeType: 'labelNode',
  },
};
