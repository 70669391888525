import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
};

export const SupplierFormAccountPayableModalWarning = ({
  onCancel,
  onConfirm,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      iconName="triangle-warning"
      iconVariant="warning"
      title={t('submitMyInvoice.payableUpdateModal.title')}
      onClose={onCancel}
      portalContainer={
        document.getElementById('react__grapes-modal-higher-z-index') ||
        undefined
      }
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('submitMyInvoice.payableUpdateModal.cancelActionText')}
          onClick={onCancel}
        />,
        <Button
          key="confirm"
          variant="primaryWarning"
          text={t('submitMyInvoice.payableUpdateModal.saveActionText')}
          onClick={onConfirm}
        />,
      ]}
    >
      {t('submitMyInvoice.payableUpdateModal.contentFoundation')}
    </Modal>
  );
};
