import { Button, Callout } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type EntityBasicInformation } from '../../../types';

export const EntityListErrorCallout = ({
  failedEntities,
  total,
}: {
  failedEntities: EntityBasicInformation[];
  total: number;
}) => {
  const { t } = useTranslation('global');
  const queryClient = useQueryClient();

  const [isRefreshingFailedEntities, setIsRefreshingFailedEntities] =
    useState(false);

  if (failedEntities.length === 0) {
    return null;
  }

  const entityIds = failedEntities.map(({ id }) => id);
  const refreshFailedEntities = async () => {
    setIsRefreshingFailedEntities(true);

    try {
      await Promise.all(
        entityIds.map((entityId) =>
          queryClient.invalidateQueries(['organisation-reporting', entityId]),
        ),
      );
    } finally {
      setIsRefreshingFailedEntities(false);
    }
  };

  return (
    <Callout
      variant="alert"
      title={t('organisation.reporting.page.entities.dataFailureCallout.title')}
    >
      <div className="flex flex-col items-start gap-16">
        <p className="text-primary body-m">
          {t(
            'organisation.reporting.page.entities.dataFailureCallout.description',
            {
              count: failedEntities.length,
              total,
            },
          )}
        </p>
        <Button
          variant="primaryAlert"
          isDisabled={isRefreshingFailedEntities}
          text={t(
            'organisation.reporting.page.entities.dataFailureCallout.cta',
          )}
          className="w-[157px]"
          iconName="arrow-turn-right"
          iconPosition="left"
          onClick={refreshFailedEntities}
        />
      </div>
    </Callout>
  );
};
