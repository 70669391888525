import {
  AutocompleteNoOptions,
  Button,
  DropdownItem,
  FormField,
  Modal,
} from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import InputTagsSelect from 'common/components/legacy/InputTagsSelect/InputTagsSelect';
import { useTranslation } from 'common/hooks/useTranslation';
import { type AppDispatch } from 'modules/app/redux/store';
import {
  customContentValueToCompositeOption,
  customContentValueToCompositePatternPart,
  getCompositePatternPartOptions,
  isCompositeColumn,
  searchAvailableColumns,
  type Column,
} from 'modules/bookkeep/settings/export';
import {
  getAvailableTemplateColumns,
  getCustomFields,
} from 'modules/bookkeep/settings/export/redux/selectors';
import { fetchCustomFields } from 'src/core/actions/customFields';

import { type EnrichedColumn } from '../../AccountingExportsColumns';

type Props = {
  columns: EnrichedColumn[];
  isOpen: boolean;
  onClose: () => void;
  onSave: (column: Column) => void;
};

export const AddColumnModal = ({ columns, isOpen, onClose, onSave }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation('global');

  const allTemplateColumns = useSelector(getAvailableTemplateColumns);
  const customFields = useSelector(getCustomFields);

  const availableNewColumns = searchAvailableColumns({
    columnsInUse: columns,
    availableTemplateColumns: allTemplateColumns,
    customFields: customFields.filter((customField) => !customField.deleted_at),
    query: '',
    t,
  });

  const columnOptions = availableNewColumns.map(
    (availableNewColumn, index) => ({
      key: index.toString(),
      label: availableNewColumn.name,
    }),
  );

  const customContentOptions = getCompositePatternPartOptions({
    customFields,
    availableColumns: allTemplateColumns,
    t,
  });

  const [selectedColumnIndex, setSelectedColumnIndex] = useState<number | null>(
    null,
  );
  const [customContent, setCustomContent] = useState<string[]>([]);

  const selectedColumn =
    selectedColumnIndex !== null
      ? columnOptions[selectedColumnIndex]
      : undefined;
  const isCustomColumn =
    selectedColumnIndex !== null
      ? isCompositeColumn(availableNewColumns[selectedColumnIndex])
      : false;

  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  // Because of availableNewColumns's typing,
  // we cannot use useCustomFieldsQuery to fetch the custom fields
  useEffect(() => {
    dispatch(fetchCustomFields());
  }, []);

  const onSubmit = () => {
    // Not checking customContent because custom columns can be empty
    if (selectedColumnIndex === null) {
      setHasSubmitted(true);
      return;
    }

    const newColumn = availableNewColumns[selectedColumnIndex];
    if (isCompositeColumn(newColumn)) {
      newColumn.compositePattern = customContent.map((value) =>
        customContentValueToCompositePatternPart(customContentOptions, value),
      );
    }

    onSave(availableNewColumns[selectedColumnIndex]);
    closeModal();
  };

  const closeModal = () => {
    onClose();
    setHasSubmitted(false);
    setSelectedColumnIndex(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('setupHub.exportGeneralSettings.columns.addColumnTitle')}
      onClose={closeModal}
      iconName="plus"
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('misc.cancel')}
          onClick={closeModal}
        />,
        <Button
          key="saveChanges"
          text={t('misc.saveChanges')}
          onClick={onSubmit}
        />,
      ]}
    >
      <FormField
        label={t('setupHub.exportGeneralSettings.columns.addColumnLabel')}
        alertMessage={
          hasSubmitted && selectedColumnIndex === null
            ? t('misc.requiredField')
            : undefined
        }
      >
        <AutocompleteSearch
          fit="parent"
          onSelect={(option) =>
            setSelectedColumnIndex(option ? Number.parseInt(option.key) : null)
          }
          options={columnOptions}
          renderOption={(option) => <DropdownItem label={option.label} />}
          renderNoOptions={(rawValue) => (
            <AutocompleteNoOptions>
              <div>
                <Trans
                  i18nKey="misc.noOptions"
                  values={{ account: rawValue }}
                  components={[
                    <span key="noOptions" className="text-primary" />,
                  ]}
                />
              </div>
            </AutocompleteNoOptions>
          )}
          showClearSelectionButton
          value={selectedColumn}
        />
      </FormField>
      {isCustomColumn && (
        <FormField
          className="mt-24 flex flex-col"
          label={t('exports.changeCustomColumnContent')}
        >
          <InputTagsSelect
            selectedOptions={customContent}
            options={customContentOptions}
            getTagDisplayValue={(value) => {
              const option = customContentValueToCompositeOption(
                customContentOptions,
                value,
                t,
              );
              return option ? option.name : value;
            }}
            getTagDisplayTheme={(value) =>
              customContentValueToCompositeOption(
                customContentOptions,
                value,
                t,
              ) === undefined
                ? 'success'
                : 'info'
            }
            onChange={(content) => setCustomContent(content || [])}
            allowCreateNewItem
            createNewItemText={t('exports.entryId.createStaticValue')}
          />
        </FormField>
      )}
    </Modal>
  );
};
