import { InfoTip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import * as Ezmoney from 'ezmoney';
import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { getImpersonator } from 'src/core/selectors/globalSelectors';
import { isOrganisationOwner } from 'src/core/selectors/users';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  type ChargebeeSubscription,
  getAddonsBySlug,
} from '../../../../models';
import { CompanyPlanAddons } from '../CompanyPlanAddons';
import { CompanyPlanNewWallet } from '../CompanyPlanNewWallet';

import './CompanyPlanSubscription.css';

type Props = {
  companySubscription: ChargebeeSubscription;
  numberOfTransactions: number;
};

export const CompanyPlanSubscription = ({
  companySubscription,
  numberOfTransactions,
}: Props) => {
  const { t } = useTranslation('global');
  const hasBranchCreationFeature = useFeature(FEATURES.ORGANISATION_BRANCHES);
  const isUserOrganisationOwner = useSelector(isOrganisationOwner);
  const impersonator = useSelector(getImpersonator);
  const { addons, amount, coupons } = companySubscription;

  const {
    supplementaryWalletsAddon,
    samlSsoAddon,
    cardInsuranceAddon,
    includedExpenses,
    extraExpenses,
  } = getAddonsBySlug(addons);

  const hasAddons =
    addons.length &&
    ((supplementaryWalletsAddon && supplementaryWalletsAddon.quantity > 0) ||
      samlSsoAddon ||
      cardInsuranceAddon);

  if (!extraExpenses) {
    return null;
  }

  const { formattedPriceBeforeDiscount, formattedDiscountAmount, hasDiscount } =
    formatPriceBeforeDiscount(amount, coupons);

  return (
    <div className="box mt-24 flex p-16 body-m">
      <div className="CompanyPlanSubscription__section-container">
        <div className="CompanyPlanSubscription__section CompanyPlanSubscription__amount-section">
          {hasDiscount && (
            <div className="CompanyPlanSubscription__amount__before-discount body-m">
              <span className="CompanyPlanSubscription__amount__before-discount__amount">
                {formattedPriceBeforeDiscount}
              </span>
              <InfoTip
                content={t('billing.companyPlan.discountTooltip', {
                  amount: formattedDiscountAmount,
                  date: '31/12/2022',
                })}
              />
            </div>
          )}
          <div className="CompanyPlanSubscription__amount body-xxl">
            {formatMonetaryValue(amount)}
          </div>
          <>{t('billing.companyPlan.thisMonth')}</>
        </div>
        <div className="CompanyPlanSubscription__section CompanyPlanSubscription__expenses-section">
          <h2 className="CompanyPlanSubscription__title title-m">
            {t('billing.companyPlan.expenses.title')}
          </h2>
          {includedExpenses ? (
            <Trans
              i18nKey="billing.companyPlan.expenses.description"
              values={{
                countExpenses: numberOfTransactions,
                totalExpenses: includedExpenses.quantity,
              }}
              components={[
                <strong
                  key="description"
                  className={cx('CompanyPlanSubscription__expense-count', {
                    'CompanyPlanSubscription__expense-count-exceeded':
                      includedExpenses.quantity < numberOfTransactions,
                  })}
                >
                  -
                </strong>,
                // eslint-disable-next-line react/jsx-key
                <br />,
              ]}
            />
          ) : (
            <Trans
              i18nKey="billing.companyPlan.expenses.descriptionWithoutIncludedExpenses"
              values={{
                countExpenses: numberOfTransactions,
              }}
              components={[
                <strong
                  key="descriptionWithoutIncludedExpenses"
                  className={cx('CompanyPlanSubscription__expense-count')}
                >
                  -
                </strong>,
                // eslint-disable-next-line react/jsx-key
                <br />,
              ]}
            />
          )}
          <InfoTip
            className="ml-8"
            content={t('billing.companyPlan.expenses.infoExtraExpenses', {
              amount: formatMonetaryValue(extraExpenses.pricing.price),
            })}
          />
        </div>
        {hasAddons && (
          <div className="CompanyPlanSubscription__section">
            <h2 className="CompanyPlanSubscription__title title-m">
              {t('billing.companyPlan.addons.title')}
            </h2>
            <CompanyPlanAddons addons={addons} />
          </div>
        )}
      </div>
      {hasBranchCreationFeature && isUserOrganisationOwner && !impersonator && (
        <div className="CompanyPlanSubscription__new-wallet-section">
          <CompanyPlanNewWallet />
        </div>
      )}
    </div>
  );
};

const formatPriceBeforeDiscount = (
  price: Ezmoney.MonetaryValue,
  couponIds: string[] | undefined,
): {
  formattedPriceBeforeDiscount: string;
  formattedDiscountAmount: string;
  hasDiscount: boolean;
} => {
  let discountAmount = Ezmoney.create(0, price.currency, 2);

  if (couponIds?.includes(`UPGRADE_100_${price.currency}_2022`)) {
    const coupon100 = Ezmoney.create(10_000, price.currency, 2);
    discountAmount = Ezmoney.add(discountAmount, coupon100);
  }

  if (couponIds?.includes(`UPGRADE_50_${price.currency}_2022`)) {
    const coupon50 = Ezmoney.create(5000, price.currency, 2);
    discountAmount = Ezmoney.add(discountAmount, coupon50);
  }

  return {
    formattedPriceBeforeDiscount: formatMonetaryValue(
      Ezmoney.add(price, discountAmount),
      {
        precision: 0,
      },
    ),
    formattedDiscountAmount: formatMonetaryValue(discountAmount, {
      precision: 0,
    }),
    hasDiscount: Ezmoney.toNumber(discountAmount) > 0,
  };
};
