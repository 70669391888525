import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { useIsCostCenterFeatureEnabled } from 'modules/budgets/features';
import { sortCostCenters } from 'modules/company/cost-centers/utils';
import { useLazyQuery } from 'src/core/api/hooks/useLazyQuery';
import { type LazyState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { GET_FILTERS_VALUES } from './queries';
import { reshapeFiltersValuesResultData } from './reshaper';

export type FiltersValues = ReturnType<
  typeof reshapeFiltersValuesResultData
> & {
  costCenters?: {
    values: { id: string; name: string }[];
    valuesTotalCount: number;
  };
};

// TODO: handles multiple queries with useQueryStates when the hook
// is able to handles disabled queries
// (right now a disabled query has always isLoading status)

export const useFiltersValuesQuery = (): LazyState<FiltersValues> => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const isCostCentersEnabled = useIsCostCenterFeatureEnabled();

  const costCentersQuery = useCostCentersQuery();

  const [fetchFilters, filtersValuesQuery] = useLazyQuery({
    key: ['useFiltersValuesQuery', companyId],
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_FILTERS_VALUES,
    },
    reshapeData: (data) => reshapeFiltersValuesResultData(data, t),
  });

  if (filtersValuesQuery.status === 'error') {
    return [fetchFilters, filtersValuesQuery];
  }

  if (costCentersQuery.status === 'error') {
    return [fetchFilters, costCentersQuery];
  }

  if (
    filtersValuesQuery.status === 'loading' ||
    filtersValuesQuery.status === 'idle' ||
    costCentersQuery.status === 'loading'
  ) {
    return [fetchFilters, { status: 'loading' }];
  }

  if (isCostCentersEnabled) {
    filtersValuesQuery.data = <FiltersValues>{
      ...filtersValuesQuery.data,
      costCenters: {
        values: sortCostCenters(costCentersQuery.data),
        valuesTotalCount: costCentersQuery.data.length,
      },
    };
  }

  return [fetchFilters, filtersValuesQuery];
};
