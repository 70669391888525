import {
  AutocompleteNoOptions,
  DropdownItem,
  FormField,
  Label,
  TextInput,
  Avatar,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import {
  AutocompleteMultipleSearch,
  AutocompleteSearch,
} from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyCurrency } from 'src/core/modules/app/hooks/useCompanyCurrency';

import type { FormValues } from './formValues';
import { createInitialApprovalRule } from '../../costCenter';
import type { Member } from '../../member';

type Props = {
  members: Member[];
} & FormikProps<FormValues>;

type MemberOption = {
  key: string;
  label: string;
  avatar: string;
};

export const CostCenterFormDetailsContent = ({
  members,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setValues,
}: Props) => {
  const { t } = useTranslation('global');
  const companyCurrency = useCompanyCurrency();

  const reshapedMembers = members.map((member) => ({
    key: member.id,
    label: member.isPending ? member.email : member.fullname,
    avatar: member.avatar,
  }));
  const selectedOwner = reshapedMembers.find(
    (member) => member.key === values.ownerId,
  );
  const selectedViewers = reshapedMembers
    .filter((member) => values.viewerIds.includes(member.key))
    .filter((viewer) => viewer.key !== selectedOwner?.key);

  const handleOwnerChange = (ownerId: string) => {
    const shouldInitializeApprovalRules =
      values.approvalRules.length === 0 && !touched.approvalRules;

    if (shouldInitializeApprovalRules) {
      setValues(
        {
          ...values,
          ownerId,
          approvalRules: createInitialApprovalRule(companyCurrency),
        },
        true,
      );
      setFieldTouched('ownerId', true, false);
      return;
    }

    setValues(
      {
        ...values,
        ownerId,
      },
      true,
    );
    setFieldTouched('ownerId', true, false);
  };

  return (
    <div className="my-32 flex flex-col gap-24 text-left">
      <FormField
        label={t('costCenters.form.name')}
        alertMessage={errors.name && touched.name ? errors.name : undefined}
      >
        <TextInput
          name="name"
          value={values.name}
          placeholder={t('costCenters.form.namePlaceholder')}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormField>
      <div>
        <Label className="mb-8" label={t('costCenters.form.owner')} />
        <AutocompleteSearch
          name="ownerId"
          fit="parent"
          options={reshapedMembers}
          value={selectedOwner}
          placeholder={t('costCenters.form.owner')}
          renderPrefix={(option) =>
            option && (
              <Avatar size="s" src={option.avatar} text={option.label} />
            )
          }
          renderOption={(option, state) => (
            <DropdownItem
              {...option}
              {...state}
              prefix={
                <Avatar size="s" src={option.avatar} text={option.label} />
              }
            />
          )}
          onSelect={(owner) => {
            if (owner) {
              handleOwnerChange(owner.key);
            }
          }}
          renderNoOptions={(rawValue) => (
            <AutocompleteNoOptions className="text-center body-m">
              <div>
                <Trans
                  i18nKey="misc.noOptions"
                  values={{ account: rawValue }}
                  components={[
                    <span key="complementary" className="text-primary" />,
                  ]}
                />
              </div>
            </AutocompleteNoOptions>
          )}
        />
      </div>
      <ViewersField
        viewerOptions={reshapedMembers.filter(
          (member) => member.key !== selectedOwner?.key,
        )}
        selectedViewerIds={selectedViewers.map((viewer) => viewer.key)}
        onSelectViewers={(viewerIds) => setFieldValue('viewerIds', viewerIds)}
      />
    </div>
  );
};

const ViewersField = ({
  viewerOptions,
  selectedViewerIds,
  onSelectViewers,
}: {
  viewerOptions: MemberOption[];
  selectedViewerIds: string[];
  onSelectViewers: (viewerIds: string[]) => void;
}) => {
  const { t } = useTranslation('global');
  const [sortedViewerOptions, setSortedViewerOptions] =
    useState<MemberOption[]>(viewerOptions);
  useEffect(() => {
    setSortedViewerOptions(
      sortViewersBySelected(viewerOptions, selectedViewerIds),
    );
  }, []);

  return (
    <div>
      <Label
        infoTipContent={t('costCenters.form.viewersInfoText')}
        label={t('costCenters.form.viewers')}
        className="mb-8"
      />
      <AutocompleteMultipleSearch
        name="viewerIds"
        fit="parent"
        options={sortedViewerOptions}
        values={viewerOptions.filter((option) =>
          selectedViewerIds.includes(option.key),
        )}
        placeholder={t('costCenters.form.viewersPlaceholder')}
        translations={{
          selected: t('costCenters.form.selectedMember', {
            count: selectedViewerIds.length,
          }),
        }}
        onSelect={(viewers) =>
          onSelectViewers(viewers.map((viewer) => viewer.key))
        }
        onFocus={() => {
          setSortedViewerOptions(
            sortViewersBySelected(viewerOptions, selectedViewerIds),
          );
        }}
      />
    </div>
  );
};

const sortViewersBySelected = (
  memberOptions: MemberOption[],
  viewerIds: string[],
) => {
  return [...memberOptions].sort((a) => {
    if (viewerIds.includes(a.key)) {
      return -1;
    }
    return 1;
  });
};
