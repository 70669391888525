import {
  Button,
  Callout,
  FormField,
  Modal,
  TextInput,
} from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import {
  type FormValues,
  validateCustomFieldsModalForm,
} from '../../validators';

type Props = {
  isOpen: boolean;
  value: string;
  customFieldValues: string[];
  onClose: () => void;
  onSubmit: (value: string) => void;
};

export const UpdateCustomFieldValueModal = ({
  isOpen,
  onClose,
  onSubmit,
  value,
  customFieldValues,
}: Props) => {
  const { t } = useTranslation('global');
  const formikProps = useFormik<FormValues>({
    initialValues: {
      value,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validate: (values) => {
      return validateCustomFieldsModalForm(values, customFieldValues, t);
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit(values.value);
      setSubmitting(false);
      onClose();
    },
  });

  return (
    <Modal
      iconName="circle-information"
      iconVariant="info"
      isOpen={isOpen}
      actions={[
        <Button
          variant="secondaryNeutral"
          text={t('customFields.settings.modals.edition.cancel')}
          onClick={() => onClose()}
          key="cancel"
        />,
        <Button
          variant="primaryBrand"
          text={t('customFields.settings.modals.edition.submit')}
          onClick={async () => {
            await formikProps.handleSubmit();
          }}
          isLoading={formikProps.isSubmitting}
          key="update"
        />,
      ]}
      title={t('customFields.settings.modals.edition.title')}
    >
      <FormField
        label={t('customFields.settings.modals.edition.label')}
        alertMessage={formikProps.errors.value}
      >
        <TextInput
          value={formikProps.values.value}
          placeholder={t('customFields.settings.modals.edition.placeholder')}
          onChange={(event) => {
            formikProps.setFieldValue('value', event.target.value);
          }}
        />
      </FormField>
      <Callout
        className="mt-24 text-left"
        variant="info"
        title={t('customFields.settings.modals.edition.callout')}
      />
    </Modal>
  );
};
