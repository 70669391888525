import { colors, Icon, Tooltip } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { NewBranch } from 'src/core/modules/app/layout/components/NewBranch';
import { isNonNegativeNumber } from 'src/core/utils/isNonNegativeNumber';

import { useGetOrganisationEntities } from '../../hooks/useGetOrganisationEntities';

import './CompanyPlanNewWallet.css';

export const CompanyPlanNewWallet = () => {
  const { t } = useTranslation('global');

  const [isNewWalletOpen, setIsNewWalletOpen] = useState(false);

  const { activeEntities, availableEntities, isAllowedToOpenEntity } =
    useGetOrganisationEntities();
  const displayEntityUsage =
    isNonNegativeNumber(activeEntities) &&
    isNonNegativeNumber(availableEntities);
  const remainingEntities =
    displayEntityUsage && Math.max(availableEntities - activeEntities, 0);

  return (
    <>
      {isAllowedToOpenEntity ? (
        <button
          className="CompanyPlanNewWallet"
          type="button"
          onClick={() => setIsNewWalletOpen(true)}
        >
          <Icon
            name="circle-plus"
            color={colors.contentBrandDefault}
            size="l"
          />
          <div className="CompanyPlanNewWallet__text title-m">
            {t('billing.companyPlan.addNewWallet')}
          </div>
          {displayEntityUsage ? (
            <div className="CompanyPlanNewWallet__text body-m">
              {t('billing.companyPlan.entityUsage', {
                remainingEntities,
                availableEntities,
              })}
            </div>
          ) : null}
        </button>
      ) : (
        <Tooltip
          content={t('billing.companyPlan.notAllowedToCreateEntityTooltip')}
        >
          <button
            className="CompanyPlanNewWallet isDisabled"
            type="button"
            disabled
          >
            <div className="CompanyPlanNewWallet__inner">
              <Icon
                name="circle-plus"
                color={colors.contentDecorativeIcon}
                size="l"
              />
              <div className="CompanyPlanNewWallet__text isDisabled title-m">
                {t('billing.companyPlan.addNewWallet')}
              </div>
              {displayEntityUsage ? (
                <div className="CompanyPlanNewWallet__text isDisabled body-m">
                  {t('billing.companyPlan.entityUsage', {
                    remainingEntities,
                    availableEntities,
                  })}
                </div>
              ) : null}
            </div>
          </button>
        </Tooltip>
      )}
      {isNewWalletOpen && (
        <NewBranch onClose={() => setIsNewWalletOpen(false)} />
      )}
    </>
  );
};
