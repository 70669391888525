import { Button, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type Language } from 'src/core/config/i18n';

import adoptSmoothSwitchUrlDe from '../assets/adopt-smooth-switch-de.png';
import adoptSmoothSwitchUrlEn from '../assets/adopt-smooth-switch-en.png';
import adoptSmoothSwitchUrlFr from '../assets/adopt-smooth-switch-fr.png';

import './AccountingBaseSwitchInformationModal.css';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const getImageUrl = (activeLanguage: Language): string => {
  switch (activeLanguage) {
    case 'fr':
      return adoptSmoothSwitchUrlFr;
    case 'de':
      return adoptSmoothSwitchUrlDe;
    default:
      return adoptSmoothSwitchUrlEn;
  }
};

export function AccountingBaseSwitchSmoothTransitionModal({
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const { t, activeLanguage } = useTranslation('global');

  return (
    <Modal
      isOpen={isOpen}
      title={t(
        'bookkeep.integrations.settings.baseSwitch.smoothTransitionTitle',
      )}
      subtitle={t(
        'bookkeep.integrations.settings.baseSwitch.smoothTransitionSubtitle',
      )}
      illustration={
        <div
          data-testid="accounting-base-switch-information-modal-image-container"
          className="AccountingBaseSwitchInformationModal__image-container"
        >
          <img
            className="AccountingBaseSwitchInformationModal__image"
            src={getImageUrl(activeLanguage)}
            alt=""
          />
        </div>
      }
      actions={[
        <Button
          key="back"
          variant="secondaryNeutral"
          onClick={() => onClose()}
          text={t('misc.cancel')}
        />,
        <Button
          key="confirm"
          text={t('misc.continue')}
          variant="primaryBrand"
          onClick={() => onConfirm()}
          iconName="chevron-right"
          iconPosition="right"
        />,
      ]}
    />
  );
}
