import {
  Button,
  ListItem,
  ListView,
  SkeletonTable,
  SkeletonText,
  SwitchField,
} from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { type AppDispatch } from 'modules/app/redux/store';
import { type Column } from 'modules/bookkeep/settings/export';
import { type FormValues } from 'modules/bookkeep/settings/export/components/TemplateEditorForm/formValues';
import { getOpenedTemplate } from 'modules/bookkeep/settings/export/redux/selectors';
import {
  fetchTemplate,
  saveTemplate,
} from 'modules/bookkeep/settings/export/redux/thunks';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { routes } from 'modules/onboarding/setup-hub/pages/routes';
import { routeFor } from 'src/core/constants/routes';

import { AddColumnModal } from './components/AddColumnModal';
import { SuccessModal } from './components/SuccessModal';
import { UpdateColumnNameModal } from './components/UpdateColumnNameModal';
import { useUpdateCachedTaskStatus } from '../../SetupHubPage/hooks/useUpdateCachedTaskStatus';

export type EnrichedColumn = Column & { isSelected: boolean };

const mapColumn = (column: Column) => ({
  ...column,
  isSelected: true,
});

export const AccountingExportsColumns = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();
  const companyId = useCompanyId();

  const history = useHistory();
  const exportSettings = history.location.state?.exportSettings as
    | Omit<FormValues, 'columns'>
    | undefined;

  const template = useSelector(getOpenedTemplate);
  const [columns, setColumns] = useState<EnrichedColumn[]>(
    template?.columns.map(mapColumn) || [],
  );

  const [isAddColumnModalOpen, setIsAddColumnModalOpen] =
    useState<boolean>(false);
  const [editingColumnIndex, setEditingColumnIndex] = useState<number | null>(
    null,
  );
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  const updateCachedTaskStatus = useUpdateCachedTaskStatus();

  const addColumn = (newColumn: Column) => {
    setColumns((previousColumns) => [
      ...previousColumns,
      { ...newColumn, isSelected: true },
    ]);
  };

  const updateColumn = (newColumn: Partial<EnrichedColumn>, index: number) => {
    setColumns((previousColumns) =>
      previousColumns.map((previousColumn, previousIndex) =>
        previousIndex === index
          ? ({ ...previousColumn, ...newColumn } as EnrichedColumn)
          : previousColumn,
      ),
    );
  };

  useEffect(() => {
    if (!exportSettings) {
      const previousPath = routeFor(routes.ACCOUNTING_EXPORT_SETTINGS.path, {
        company: companyId,
      });
      history.replace(previousPath);
    }
  }, []);

  useEffect(() => {
    if (!template && exportSettings) {
      dispatch(fetchTemplate('default', true));
    }
  }, []);

  useEffect(() => {
    if (columns.length === 0 && template) {
      setColumns(template.columns.map(mapColumn));
    }
  }, [columns, template, setColumns]);

  const handleSubmit = async () => {
    // Shouldn't happen as a useEffect redirects to the previous page
    // if the export settings are not set
    if (!exportSettings) {
      return;
    }

    try {
      const finalColumns = columns
        .filter((column) => column.isSelected)
        .map(({ isSelected, ...column }) => column);
      const values = {
        ...exportSettings,
        columns: finalColumns,
      } as FormValues;
      await dispatch(saveTemplate(values));

      updateCachedTaskStatus('journal_export');

      setIsSuccessModalOpen(true);
    } catch {
      dangerNotif(t('misc.errors.unknownError'));
    }
  };

  const goBack = () => {
    const previousPath = routeFor(routes.ACCOUNTING_EXPORT_SETTINGS.path, {
      company: companyId,
    });
    history.push(previousPath);
  };

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.exportGeneralSettings.description')}
        isLastStep
        onBack={goBack}
        onNext={handleSubmit}
        title={t('setupHub.exportGeneralSettings.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('misc.needHelp'),
        }}
      >
        {columns.length > 0 ? (
          <div>
            <ListView aria-label="export-columns">
              {columns.map((column, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={`${column.name}-${index}`} className="flex-1">
                  <SwitchField
                    className="w-full"
                    label={column.name}
                    helpText={column.description}
                    isChecked={column.isSelected}
                    onChange={(event) => {
                      updateColumn({ isSelected: event.target.checked }, index);
                    }}
                  />
                  <Button
                    className="my-8"
                    fit="parent"
                    iconName="pen"
                    iconPosition="left"
                    isDisabled={!column.isSelected}
                    onClick={() => setEditingColumnIndex(index)}
                    text={t(
                      'setupHub.exportGeneralSettings.columns.updateName',
                    )}
                    variant="secondaryNeutral"
                  />
                </ListItem>
              ))}
            </ListView>
            <Button
              className="mt-16"
              onClick={() => setIsAddColumnModalOpen(true)}
              text={t('setupHub.exportGeneralSettings.columns.addColumnTitle')}
              variant="tertiaryNeutral"
              iconPosition="left"
              iconName="plus"
            />
          </div>
        ) : (
          <SkeletonTable
            columns={[
              {
                cell: (
                  <div className="flex flex-1 flex-col gap-8">
                    <SkeletonText size="m" width="50%" />
                    <SkeletonText size="xl" />
                  </div>
                ),
              },
            ]}
            numberOfRows={10}
          />
        )}
      </TaskStepLayout>
      <AddColumnModal
        columns={columns}
        isOpen={isAddColumnModalOpen}
        onClose={() => setIsAddColumnModalOpen(false)}
        onSave={addColumn}
      />
      <UpdateColumnNameModal
        isOpen={editingColumnIndex !== null}
        column={
          editingColumnIndex !== null ? columns[editingColumnIndex] : undefined
        }
        onClose={() => setEditingColumnIndex(null)}
        onSave={(name) => {
          if (editingColumnIndex === null) {
            return;
          }
          updateColumn({ name }, editingColumnIndex);
        }}
      />
      <SuccessModal isOpen={isSuccessModalOpen} />
    </div>
  );
};
