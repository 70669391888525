import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { type CompanyRole } from 'modules/members/models/roles';

export const translateRoleName = (
  role: CompanyRole,
  translator: TGlobalFunctionTyped,
): string => {
  switch (role) {
    case 'admin':
      return translator('misc.administrator');
    case 'controller':
      return translator('misc.controller');
    case 'requester':
      return translator('misc.requester');
    case 'accountOwner':
      return translator('misc.accountOwner');
    default:
      return role;
  }
};
