import { SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

const LOADER_ROWS = 3;

export const AccountPayableSuppliersSubListLoader = () => {
  return (
    <SkeletonTable
      className="ml-24 h-fit w-[368px]"
      numberOfRows={LOADER_ROWS}
      withHeader
      columns={[
        {
          cell: (
            <div className="flex w-full items-center gap-16">
              <SkeletonText size="xl" width="var(--unit-24)" />
              <SkeletonText size="l" width="60%" />
            </div>
          ),
          header: <SkeletonText size="l" width="60%" />,
        },
      ]}
    />
  );
};
