export type Dimension = 'spendType' | 'costCenter' | 'expenseCategory';
export const DIMENSIONS = [
  'spendType',
  'costCenter',
  // 'expenseCategory',
] as const satisfies Dimension[];

type ApprovalWorkflowOutcome = {
  approvalSchemeId: string;
};

export interface ApprovalWorkflowNode {
  values: string[] | null;
  outcome?: ApprovalWorkflowOutcome;
  branch?: ApprovalWorkflowBranch;
}

export type ApprovalWorkflowBranch = {
  dimension: Dimension;
  nodes: ApprovalWorkflowNode[];
};

interface WorkflowRootNode extends ApprovalWorkflowNode {
  values: null;
  outcome: ApprovalWorkflowOutcome;
}

export type ApprovalWorkflowDefinition = WorkflowRootNode;

export type ApprovalWorkflow = {
  id: string;
  definition: ApprovalWorkflowDefinition;
};
