import { differenceInMonths } from 'date-fns';
import * as Money from 'ezmoney';

import { getMonthsBetweenDates } from 'common/utils/getMonthsBetweenDates';
import { type ExportMethodsStatus, PaymentMethod } from 'modules/company';
import i18n from 'src/core/config/i18n';

import { type Bill } from './bill';
import { type PaymentToScheduleDetails } from './paymentToSchedule';
import {
  type Beneficiary,
  computeSchedulingProcessAmount,
  type SchedulingProcess,
} from './schedulingProcess';
import { type WireTransferError } from './wireTransferError';

export enum PaymentsBatchPaymentStatus {
  Scheduled = 'scheduled',
  Executing = 'executing',
  Executed = 'executed',
  Failed = 'failed',
}

export enum PaymentsBatchStatus {
  Pending = 'pending',
  Successful = 'successful',
  Failed = 'failed',
}

export interface PaymentsBatchPayment {
  id: string;
  status: PaymentsBatchPaymentStatus;
  scheduledForDate: string;
  amount: Money.MonetaryValue;
  bill: Bill;
  schedulingProcess: SchedulingProcess;
  failureReason?: WireTransferError;
}

export const isPaymentsBatchPayment = (
  payment: PaymentsBatchPaymentDetails | PaymentToScheduleDetails,
): payment is PaymentsBatchPaymentDetails =>
  Object.hasOwn(payment, 'scheduledForDate');

export interface PaymentsBatchPaymentDetails {
  id: string;
  status: PaymentsBatchPaymentStatus;
  paymentMethod: PaymentMethod;
  scheduledForDate: string;
  date: string;
  amount: Money.MonetaryValue;
  failureReason?: WireTransferError;
  bill: Bill;
  schedulingProcess: SchedulingProcess;
  beneficiary?: Beneficiary;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timeline: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: any;
}

export type PaymentsBatch = {
  id: string;
  status: PaymentsBatchStatus;
  user: {
    id: string;
    displayName: string;
    avatar: string;
  };
  batchedAt: string;
  paymentMethod: PaymentMethod;
  payments: PaymentsBatchPayment[];
  exportMethods: ExportMethodsStatus;
};

export type PaymentsBatchesByMonth = Map<string, PaymentsBatch[]>;

export const getPaymentsBatchCurrency = (
  paymentsBatch: PaymentsBatch,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
): string => paymentsBatch.payments[0]!.amount.currency;

export const isMultiCurrencyPaymentsBatch = (
  paymentsBatch: PaymentsBatch,
): boolean => {
  const currency = getPaymentsBatchCurrency(paymentsBatch);

  return paymentsBatch.payments.some(
    (payment) => payment.amount.currency !== currency,
  );
};

export const getPaymentsBatchAmount = (
  paymentsBatch: PaymentsBatch,
): Money.MonetaryValue => {
  const currency = getPaymentsBatchCurrency(paymentsBatch);

  return paymentsBatch.payments.reduce(
    (total, payment) => Money.add(total, payment.amount),
    Money.fromNumber(0, currency, 0),
  );
};

export const isPartialBillPayment = (payment: PaymentsBatchPayment): boolean =>
  Money.compare(
    payment.amount,
    computeSchedulingProcessAmount(
      payment.schedulingProcess,
      payment.bill.amount,
    ),
  ) === -1;

export const parseBatchDate = (dateString: string): Date => {
  const [year, month] = dateString.split('-');
  return new Date(Date.UTC(Number.parseInt(year), Number.parseInt(month) - 1));
};

export const formatBatchDate = (date: Date, locale: string): string =>
  date.toLocaleDateString(locale, { month: 'long', year: 'numeric' });

export const isPaymentCancellable = (
  paymentsBatchPayment: PaymentsBatchPayment,
  paymentMethod: PaymentMethod,
): boolean => {
  const cancellableStatuses = [
    PaymentsBatchPaymentStatus.Executing,
    PaymentsBatchPaymentStatus.Scheduled,
  ];

  const isScheduledWireTransfer =
    cancellableStatuses.includes(paymentsBatchPayment.status) &&
    paymentMethod === PaymentMethod.WireTransfer;

  const isScheduledInTheFuture =
    new Date(paymentsBatchPayment.scheduledForDate) > new Date();

  return isScheduledWireTransfer && isScheduledInTheFuture;
};

export const getIsEligibleToProofOfPayment = (
  payment: PaymentsBatchPaymentDetails,
): boolean =>
  payment.status === PaymentsBatchPaymentStatus.Executed &&
  payment.paymentMethod === PaymentMethod.WireTransfer;

export const getPaymentsBatchesByMonth = (paymentsBatches: PaymentsBatch[]) => {
  const batchesByMonth = new Map<string, PaymentsBatch[]>();
  let lastMonth;

  for (const batch of paymentsBatches) {
    const month = parseBatchDate(batch.batchedAt);
    const formattedMonth = formatBatchDate(month, i18n.language);

    if (!lastMonth) {
      lastMonth = month;
    }

    if (differenceInMonths(lastMonth, month)) {
      for (const emptyMonth of getMonthsBetweenDates(month, lastMonth)) {
        batchesByMonth.set(formatBatchDate(emptyMonth, i18n.language), []);
      }
    }

    lastMonth = month;

    const batchesInMonth = batchesByMonth.get(formattedMonth) || [];

    batchesByMonth.set(formattedMonth, [...batchesInMonth, batch]);
  }

  return batchesByMonth;
};
