import { FormField, SwitchField, TextInput } from '@dev-spendesk/grapes';
import type { FormikProps } from 'formik';

import { useTranslation } from 'common/hooks/useTranslation';

import { type FormValues } from '../../form';

type Props = Pick<FormikProps<FormValues>, 'values' | 'handleChange'>;

export const DefaultEmployeeAccount = ({ values, handleChange }: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="flex flex-col justify-between gap-16 rounded-8 bg-secondary-default px-24 py-16">
      <SwitchField
        helpText={t('setupHub.employeeAccounts.defaultAccountDescription')}
        label={t('setupHub.employeeAccounts.defaultAccountTitle')}
        name="useDefaultValue"
        isChecked={values.useDefaultValue}
        onChange={handleChange}
      />
      {values.useDefaultValue && (
        <FormField
          label={t('setupHub.employeeAccounts.defaultAccount')}
          visuallyHideLabel
          className="w-1/2"
        >
          <TextInput
            name="defaultEmployeeAccount"
            value={values.defaultEmployeeAccount}
            onChange={handleChange}
          />
        </FormField>
      )}
    </div>
  );
};
