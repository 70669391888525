import { Button, Panel, PanelSection, Popover } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SearchFilter } from 'common/components/SearchFilter';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { type CostCenter } from 'modules/budgets/models/costCenter';
import { type Team } from 'modules/budgets/models/team';
import { CustomFieldFilters } from 'modules/custom-fields';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

import { PaymentFiltersForm } from './PaymentFiltersForm';
import {
  type Filters,
  type FiltersPayload,
  convertFiltersToPayload,
  convertPayloadToFilters,
  getActiveFiltersCount,
} from './types';

type Props = {
  users: { id: string; fullname: string }[];
  teams: Team[];
  setPaymentFilters: (filters: FiltersPayload) => void;
};

export const PaymentFilters = ({ users, teams, setPaymentFilters }: Props) => {
  const { t } = useTranslation('global');
  const history = useHistory();

  const queryParams = new URLSearchParams(history.location?.search);

  const initialFilters = convertPayloadToFilters(
    Array.from(queryParams.entries()).map(([type, value]) => ({ type, value })),
  );

  const [filters, setFilters] = useState<Filters>(initialFilters);

  const applyFilters = (filtersToApply: Filters) => {
    const payload = convertFiltersToPayload(filtersToApply);
    setPaymentFilters(payload);
  };

  const countFilters = getActiveFiltersCount(initialFilters);

  const costCentersQuery = useCostCentersQuery();
  const costCenters = unwrapQuery(costCentersQuery) ?? [];

  return (
    <div className="relative z-[9] my-24 flex items-center gap-8 bg-primary-default">
      <SearchFilter
        search={initialFilters.textSearch ?? ''}
        setSearch={(newSearch) => {
          const newFilters: Filters = {
            ...initialFilters,
            textSearch: newSearch,
          };
          if (!newFilters.textSearch) {
            delete newFilters.textSearch;
          }
          applyFilters(newFilters);
        }}
      />

      <Popover
        renderTrigger={({ isDropdown, ...triggerProps }) => (
          <Button
            variant={countFilters > 0 ? 'secondaryBrand' : 'secondaryNeutral'}
            iconName="adjustments-horizontal"
            text={
              t('misc.filter') + (countFilters > 0 ? ` (${countFilters})` : '')
            }
            {...triggerProps}
          />
        )}
      >
        {(close) => (
          <FiltersPanel
            close={close}
            applyFilters={applyFilters}
            filters={filters}
            initialFilters={initialFilters}
            setFilters={setFilters}
            users={users}
            teams={teams}
            costCenters={costCenters}
          />
        )}
      </Popover>
    </div>
  );
};

/**
 * Children components
 */

const FiltersPanel = ({
  close,
  applyFilters,
  initialFilters,
  filters,
  setFilters,
  users,
  teams,
  costCenters,
}: {
  close: () => void;
  applyFilters: (filters: Filters) => void;
  setFilters: (filters: Filters) => void;
  initialFilters: Filters;
  filters: Filters;
  users: { id: string; fullname: string }[];
  teams: Team[];
  costCenters: CostCenter[];
}) => {
  const { t } = useTranslation('global');

  // Reset non-applied filters when the panel is closed
  useEffect(() => {
    setFilters(initialFilters);
  }, []);

  return (
    <Panel
      className="max-h-[500px] w-[336px] border-none"
      title={t('misc.selectAndApplyFilters')}
      onClose={() => {
        close();
      }}
      footer={
        <div className="grid w-full grid-cols-2 gap-8">
          <Button
            variant="secondaryNeutral"
            text={t('misc.resetFilters')}
            onClick={() => {
              setFilters({});
              applyFilters({});
              close();
            }}
          />
          <Button
            text={t('misc.apply')}
            onClick={() => {
              applyFilters(filters);
              close();
            }}
          />
        </div>
      }
    >
      <PanelSection>
        <PaymentFiltersForm
          users={users}
          teams={teams}
          costCenters={costCenters}
          filters={filters}
          setFilters={setFilters}
        />
        <CustomFieldFilters
          eligibleTypes={['payment', 'expense']}
          filters={filters.customFields ?? {}}
          setFilters={(customFields) => {
            const nonNullCustomFields = Object.fromEntries(
              Object.entries(customFields).filter(
                ([_, value]) => value !== null,
              ),
            );
            if (Object.keys(nonNullCustomFields).length === 0) {
              const { customFields: customFieldsUnused, ...rest } = filters;
              setFilters(rest);
            } else {
              setFilters({
                ...filters,
                customFields: nonNullCustomFields,
              });
            }
          }}
          disableSelectUndefined={false}
        />
      </PanelSection>
    </Panel>
  );
};
