import { type UserTaskType } from 'modules/onboarding/setup-hub/types/userTask';
import { type I18nKey } from 'src/core/common/hooks/useTranslation';

type ReadyToStepContent = {
  title: I18nKey;
  description: I18nKey;
  cta: I18nKey;
};

export const getContentByTaskType = (
  type: UserTaskType,
): ReadyToStepContent | null => {
  switch (type) {
    case 'subscription':
      return {
        title: 'readyToSpend.items.subscription.title',
        description: 'readyToSpend.items.subscription.description',
        cta: 'readyToSpend.items.ctas.discover',
      };
    case 'single_use':
      return {
        title: 'readyToSpend.items.singleUse.title',
        description: 'readyToSpend.items.singleUse.description',
        cta: 'readyToSpend.items.ctas.discover',
      };
    case 'expense_claim':
      return {
        title: 'readyToSpend.items.expenseClaim.title',
        description: 'readyToSpend.items.expenseClaim.description',
        cta: 'readyToSpend.items.ctas.discover',
      };
    case 'learn_to_approve':
      return {
        title: 'readyToSpend.items.learnToApprove.title',
        description: 'readyToSpend.items.learnToApprove.description',
        cta: 'readyToSpend.items.ctas.discover',
      };
    case 'upload_receipt':
      return {
        title: 'readyToSpend.items.uploadReceipt.title',
        description: 'readyToSpend.items.uploadReceipt.description',
        cta: 'readyToSpend.items.ctas.discover',
      };
    case 'download_app':
      return {
        title: 'readyToSpend.items.downloadApp.title',
        description: 'readyToSpend.items.downloadApp.description',
        cta: 'readyToSpend.items.ctas.download',
      };
    default:
      return null;
  }
};
