import { gql } from 'graphql-tag';

export const GET_PAYABLES_GROUPS = gql`
  query GetPayableGroups(
    $companyId: ID!
    $after: ID
    $groupBy: PayableGroupBy!
    $filters: PayableFilter
    $filtersV2: JSON
    $textualSearch: String
  ) {
    company(id: $companyId) {
      id
      payablesGrouped(
        first: 10
        after: $after
        groupBy: $groupBy
        filter: $filters
        filtersV2: $filtersV2
        textualSearch: $textualSearch
      ) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            name
            thumbnailUrl
            payables(first: 1) {
              connectionId: id
              totalCount
              aggregate {
                sum {
                  functionalAmount
                }
              }
            }
          }
        }
      }
    }
  }
`;
