import * as React from 'react';

export interface Props {
  children: React.ReactNode;
}

export const WalletSectionSubHeader = ({ children }: Props) => {
  return (
    <h4 className="mb-24 mt-8 text-secondary-bg-primary body-m">{children}</h4>
  );
};
