import { InfoTip, Panel, Skeleton } from '@dev-spendesk/grapes';

import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { useGetOrganisationStats } from 'src/core/modules/organisation-reporting/hooks/useGetOrganisationStats';
import { type EnrichedEntityInformation } from 'src/core/modules/organisation-reporting/types';

import { GroupBreakdownBlock } from './GroupBreakdownBlock';
import { BreakdownGaugeSkeleton } from '../../../common/BreakdownGauge/BreakdownGaugeSkeleton';

type Props = {
  entityDetails: EnrichedEntityInformation[];
  isDataFullyLoaded: boolean;
};

export const EntityListGroupSection = ({
  entityDetails,
  isDataFullyLoaded,
}: Props) => {
  const { t } = useTranslation('global');

  const groupStatsQuery = useGetOrganisationStats(
    entityDetails,
    isDataFullyLoaded,
  );

  return (
    <Panel className="w-full pt-24 elevation-xs">
      <QuerySuspense
        queryState={groupStatsQuery}
        loading={
          <div className="flex flex-col gap-8">
            <div className="flex items-center gap-4">
              <h2 className="text-pretty text-primary body-l">
                {t('organisation.reporting.page.group.totalWalletBalance')}
              </h2>
            </div>
            <div className="flex flex-col gap-20">
              <Skeleton width="180px" height="40px" />
              <BreakdownGaugeSkeleton legendMatrix={[2, 2, 3, 3]} />
            </div>
          </div>
        }
        fallback={() => null}
      >
        {({
          totalWalletBalance,
          breakdownTotals,
          currencyRates,
          currencyTimestamp,
          mainCurrency,
        }) => (
          <div className="flex flex-col gap-8">
            <div className="flex items-center gap-8">
              <h2 className="text-pretty text-primary body-l">
                {t('organisation.reporting.page.group.totalWalletBalance')}
              </h2>
              {currencyRates.length > 0 && (
                <InfoTip
                  className="h-16 w-16"
                  content={
                    <div className="leading-normal body-s">
                      {t(
                        'organisation.reporting.page.group.currencyRates.tooltip',
                        {
                          rates: formatCurrencyRates(
                            currencyRates,
                            mainCurrency,
                            t,
                          ),
                          date: currencyTimestamp,
                        },
                      )}
                    </div>
                  }
                />
              )}
            </div>
            <div className="flex flex-col gap-20">
              <div className="text-primary title-xxl">{totalWalletBalance}</div>
              <GroupBreakdownBlock
                currency={mainCurrency}
                breakdown={breakdownTotals}
              />
            </div>
          </div>
        )}
      </QuerySuspense>
    </Panel>
  );
};

const formatCurrencyRates = (
  currencyRates: { sourceCurrency: string; rate: number }[],
  mainCurrency: string,
  t: TGlobalFunctionTyped,
) => {
  if (currencyRates.length <= 2) {
    return currencyRates
      .map((rate) =>
        t('organisation.reporting.page.group.currencyRates.rateFormat', {
          sourceCurrency: rate.sourceCurrency,
          conversionCurrency: mainCurrency,
          rate: rate.rate,
        }),
      )
      .join(` ${t('misc.and')} `);
  }
  return currencyRates
    .map((rate, index) => {
      if (index === currencyRates.length - 1) {
        return `${t('misc.and')} ${t(
          'organisation.reporting.page.group.currencyRates.rateFormat',
          {
            sourceCurrency: rate.sourceCurrency,
            conversionCurrency: mainCurrency,
            rate: rate.rate,
          },
        )}`;
      }
      return t('organisation.reporting.page.group.currencyRates.rateFormat', {
        sourceCurrency: rate.sourceCurrency,
        conversionCurrency: mainCurrency,
        rate: rate.rate,
      });
    })
    .join(', ');
};
