import { Button, Modal } from '@dev-spendesk/grapes';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import illustration from '../assets/caroleCooperModal.png';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SfsUkPhoneNumberModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const history = useHistory();

  return (
    <Modal
      illustration={<img alt="" src={illustration} className="h-[317px]" />}
      illustrationHeight="317px"
      isOpen={isOpen}
      onClose={onClose}
      title={t('requests.sfsUkModal.titlePhone')}
      subtitle={t('requests.sfsUkModal.descriptionPhone')}
      actions={
        <Button
          onClick={() => {
            const securityAccountRoute = routeFor(
              routes.ACCOUNT_ME_SECURITY.path,
              {
                company: companyId,
              },
            );
            history.push(securityAccountRoute);
          }}
          text={t('requests.sfsUkModal.catPhone')}
          variant="primaryBrand"
        />
      }
    />
  );
};
