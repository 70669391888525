import { useQueryClient } from 'react-query';

import { CONTROL_SETTINGS_TASKS } from 'modules/onboarding/setup-hub/constants/taskSections';
import {
  type HubTask,
  type HubTaskType,
} from 'modules/onboarding/types/hubTask';

export const useUpdateCachedTaskStatus = () => {
  const queryClient = useQueryClient();

  return (taskType: HubTaskType) =>
    queryClient.setQueryData<HubTask[]>(
      ['onboarding-hub', 'tasks', 'setup_hub'],
      (tasks) => {
        if (!tasks) {
          return [];
        }
        const result: HubTask[] = tasks.map((task) => {
          if (task.type === taskType) {
            return {
              ...task,
              status: 'completed',
            };
          }
          return task;
        });
        // when all control settings tasks are completed, we enable accounting software task
        if (CONTROL_SETTINGS_TASKS.includes(taskType)) {
          const controlSettingsTasks = result.filter(
            (task) => task.category === 'control_settings',
          );
          const accountingSoftwareTask = result.find(
            (task) => task.type === 'accounting_software',
          );
          if (
            controlSettingsTasks.every((task) => task.status === 'completed') &&
            accountingSoftwareTask?.status === 'disabled'
          ) {
            accountingSoftwareTask.status = 'ongoing';
          }
        } else if (taskType === 'accounting_software') {
          // when accounting_software task is completed, we enable all accounting tasks
          result.forEach((task) => {
            if (task.category === 'accounting' && task.status === 'disabled') {
              task.status = 'ongoing';
            }
          });
        }
        return result;
      },
    );
};
