import { Callout, FormField, Input } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  vatNumber?: string;
  error: boolean;
  isDisabled?: boolean;
  showLabel?: boolean;
  className?: string;
  onChange(vatNumber?: string): void;
};

export const VatNumberFormField = ({
  vatNumber,
  error,
  isDisabled = false,
  showLabel = true,
  className = 'my-16',
  onChange,
}: Props) => {
  const { t } = useTranslation('global');

  const input = (
    <Input
      name="vatNumber"
      value={vatNumber}
      placeholder={t(
        'suppliers.spanishDprCompliance.missingSupplierDetailsCallout.vatNumber.placeholder',
      )}
      onChange={(event) => {
        if (event.target.checkValidity()) {
          onChange(event.target.value);
        }
      }}
      isInvalid={error}
      isDisabled={isDisabled}
      fit="parent"
    />
  );

  return showLabel ? (
    <FormField
      className={className}
      alertMessage={error ? t('misc.requiredField') : undefined}
      label={t(
        'suppliers.spanishDprCompliance.missingSupplierDetailsCallout.vatNumber.label',
      )}
      infoTipContent={t(
        'suppliers.spanishDprCompliance.missingSupplierDetailsCallout.vatNumber.hint',
      )}
    >
      {input}
    </FormField>
  ) : (
    <div className={className}>
      {input}
      {error && (
        <Callout
          variant="alert"
          className="mt-8"
          title={t('misc.requiredField')}
        />
      )}
    </div>
  );
};
