import { Button, type DateRange } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import {
  subMonths,
  endOfMonth,
  startOfMonth,
  subDays,
  startOfISOWeek,
  endOfISOWeek,
  subWeeks,
} from 'date-fns';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

export type Props = {
  onChange: (value: DateRange) => void;
  className?: string;
};

export const DateRangeActions = ({ onChange, className = '' }: Props) => {
  const { t } = useTranslation('global');
  const today = new Date();

  return (
    <div className={classNames('flex flex-row gap-4 p-4', className)}>
      <Button
        variant="tertiaryNeutral"
        text={t('misc.periods.lastWeek')}
        onClick={() => {
          const lastWeek = subWeeks(today, 1);
          onChange([startOfISOWeek(lastWeek), endOfISOWeek(lastWeek)]);
        }}
      />
      <Button
        variant="tertiaryNeutral"
        text={t('misc.periods.last30Days')}
        onClick={() => {
          onChange([subDays(today, 30), today]);
        }}
      />
      <Button
        variant="tertiaryNeutral"
        text={t('misc.periods.lastMonth')}
        onClick={() => {
          const lastMonth = subMonths(today, 1);
          onChange([startOfMonth(lastMonth), endOfMonth(lastMonth)]);
        }}
      />
      <Button
        variant="tertiaryNeutral"
        text={t('misc.periods.thisMonth')}
        onClick={() => {
          onChange([startOfMonth(today), today]);
        }}
      />
      <Button
        variant="tertiaryNeutral"
        text={t('misc.reset')}
        className="ml-auto"
        iconPosition="right"
        iconName="arrow-path"
        onClick={() => {
          onChange([undefined, undefined]);
        }}
      />
    </div>
  );
};
