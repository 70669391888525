import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

type Props = Readonly<{
  onCancel: () => void;
  onContinue: () => void;
}>;

export const AffidavitStartModal = ({ onCancel, onContinue }: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      iconName="receipt-question"
      onClose={onCancel}
      title={t('payments.affidavitModal.startDeclarationModal.title')}
      actions={[
        <Button
          key="continue"
          text={t('payments.affidavitModal.startDeclarationModal.startButton')}
          onClick={onContinue}
        />,
      ]}
    >
      <div>{t('payments.affidavitModal.startDeclarationModal.content1')}</div>
      <div>{t('payments.affidavitModal.startDeclarationModal.content2')}</div>
    </Modal>
  );
};
