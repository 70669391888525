import { Callout, FormField } from '@dev-spendesk/grapes';

import { CountryAutocomplete } from 'common/components/CountryAutocomplete';
import { useTranslation } from 'common/hooks/useTranslation';

type Props = {
  country?: string;
  error: boolean;
  isDisabled?: boolean;
  showLabel?: boolean;
  className?: string;
  onChange(country?: string): void;
};

export const CountryFormField = ({
  country,
  error,
  isDisabled = false,
  showLabel = true,
  className = 'my-16',
  onChange,
}: Props) => {
  const { t } = useTranslation('global');

  const selector = (
    <CountryAutocomplete
      name="country"
      fit="parent"
      placeholder={t('forms.country.placeholder')}
      countryCode={country ?? ''}
      onSelect={(selectedCountry: string | undefined) =>
        onChange(selectedCountry)
      }
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
          onChange(undefined);
        }
      }}
      isDisabled={isDisabled}
      isInvalid={error}
    />
  );

  return showLabel ? (
    <FormField
      className={className}
      alertMessage={error ? t('misc.requiredField') : undefined}
      label={
        showLabel
          ? t(
              'suppliers.spanishDprCompliance.missingSupplierDetailsCallout.country.label',
            )
          : ''
      }
    >
      {selector}
    </FormField>
  ) : (
    <div className={className}>
      {selector}
      {error && (
        <Callout
          variant="alert"
          className="mt-8"
          title={t('misc.requiredField')}
        />
      )}
    </div>
  );
};
