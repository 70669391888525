import { Button, Callout } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { type Dispatch, type SetStateAction, useState } from 'react';

import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import {
  type TaxAccount,
  type IntegrationReverseChargeAccount,
  filterIntegrationVatAccounts,
  filterIntegrationReverseChargeAccounts,
  type TaxAccountUpdate,
} from 'modules/bookkeep/settings/accounting/taxAccount';
import { useDisplayReverseChargeQuery } from 'modules/bookkeep/settings/integrations/hooks/useDisplayReverseChargeQuery';
import {
  type MutationQueryState,
  type QueryState,
} from 'src/core/api/queryState';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { DefaultTaxAccountForm } from './DefaultTaxAccountForm';
import { type ModalState } from './ModalState';
import styles from './TaxAccountLocalOnlySection.module.css';
import { VatAccountsTable } from './VatAccountsTable';
import { DatevReverseChargeTable } from './datev/DatevReverseChargeTable';
import { DoubleEntryReverseChargeTable } from './file-based/DoubleEntryReverseChargeTable';
import { SingleEntryReverseChargeTable } from './file-based/SingleEntryReverseChargeTable';
import { ReverseChargeAddModal } from './modals/ReverseChargeAddModal';
import { ReverseChargeDeleteModal } from './modals/ReverseChargeDeleteModal';
import { VatAddModal } from './modals/VatAddModal';
import { VatDeleteModal } from './modals/VatDeleteModal';
import { type Result as GetDefaultTaxAccountQueryResult } from '../../../../../hooks/useGetDefaultTaxAccountQuery';
import { type Result as SetDefaultTaxAccountMutationResult } from '../../../../../hooks/useSetDefaultTaxAccountMutation';
import { TaxAccountErrorWarning } from '../TaxAccountErrorWarning';
import { TaxAccountSkeletonTable } from '../TaxAccountSkeletonTable';
import {
  getVatAccountsHelpCenterLink,
  getVatAccountsDescriptionI18nKey,
  getReverseChargeAccountsDescriptionI18nKey,
  getReverseChargeAccountsHelpCenterLink,
} from '../translations';

interface Props {
  taxAccountQueryState: QueryState<TaxAccount[]>;
  integrationStatus: IntegrationStatusWithIntegration;
}

type PropsWithDefaultAccounts = Props & {
  setDefaultTaxAccountQueryState: MutationQueryState<SetDefaultTaxAccountMutationResult>;
  getDefaultTaxAccountQueryState: QueryState<GetDefaultTaxAccountQueryResult>;
  onDefaultChange: (expenseAccount: TaxAccountUpdate) => Promise<void>;
};

export const TaxAccountLocalOnlyWithDefaultAccountsSection = (
  props: PropsWithDefaultAccounts,
) => {
  return <TaxAccountSection {...props} withDefaultAccount />;
};

export const TaxAccountLocalOnlySection = (props: Props) => {
  return (
    <TaxAccountSection
      {...props}
      withDefaultAccount={false}
      onDefaultChange={async () => {}}
      setDefaultTaxAccountQueryState={{ status: 'idle' }}
      getDefaultTaxAccountQueryState={{ status: 'loading' }}
    />
  );
};

const TaxAccountSection = ({
  withDefaultAccount,
  setDefaultTaxAccountQueryState,
  getDefaultTaxAccountQueryState,
  onDefaultChange,
  integrationStatus,
  taxAccountQueryState,
}: PropsWithDefaultAccounts & { withDefaultAccount: boolean }) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<ModalState>({
    kind: 'closed',
  });
  const displayReverseChargeQuery = useDisplayReverseChargeQuery();

  const { integration } = integrationStatus;
  const taxAccountsErrorsList =
    integrationStatus.settingsValidation.taxAccounts.flatMap(({ id }) => id);

  const defaultTaxAccount =
    getDefaultTaxAccountQueryState.status === 'success'
      ? getDefaultTaxAccountQueryState.data
      : undefined;

  const isDefaultTaxAccountToggleChecked = Boolean(
    defaultTaxAccount?.id && !defaultTaxAccount.isArchived,
  );

  return (
    <>
      <div id="vat-accounts">
        <div className={styles.header}>
          <div className={styles.headerText}>
            <h3 className="IntegrationAccountingPage__section-title heading-l">
              {t('bookkeep.integrations.settings.sectionTaxAccounts')}
            </h3>
            <p className={classNames(styles.headerTextDescription, 'body-m')}>
              {t(getVatAccountsDescriptionI18nKey(integration))}&nbsp;|&nbsp;
              <a
                href={getVatAccountsHelpCenterLink(integration)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('misc.helpCenterArticle')}
              </a>
            </p>
          </div>
          <Button
            className={styles.headerBtn}
            text={t(
              'bookkeep.integrations.settings.vatAccountsTable.addVatAccount',
            )}
            variant="primaryBrand"
            onClick={() =>
              setModalState({
                kind: 'addVat',
                id: '',
                rate: '',
                name: '',
                code: '',
                inputChanged: false,
              })
            }
          />
        </div>

        <QuerySuspense
          queryState={taxAccountQueryState}
          loading={<TaxAccountSkeletonTable numberOfRows={2} />}
          fallback={() => <TaxAccountErrorWarning />}
        >
          {(taxAccounts) => {
            const vatAccounts = filterIntegrationVatAccounts(taxAccounts);

            const emptyCallout =
              vatAccounts.length === 0 &&
              (withDefaultAccount
                ? !defaultTaxAccount || defaultTaxAccount.isArchived === true
                : true) ? (
                <Callout
                  className={styles.noVatAccountWarning}
                  variant="warning"
                  title={t(
                    'bookkeep.integrations.settings.noVatAccountWarning',
                  )}
                />
              ) : null;

            const vatAccountsErrorsList = vatAccounts.filter(({ id }) =>
              taxAccountsErrorsList.includes(id),
            );
            const errorCallout = !!vatAccountsErrorsList.length && (
              <Callout
                variant="alert"
                title={t(
                  'bookkeep.integrations.datev.accountCodeAdvice.taxAccounts.title',
                  {
                    count: vatAccountsErrorsList.length,
                  },
                )}
                className={styles.errorCallout}
              />
            );

            return (
              <>
                {emptyCallout}
                {errorCallout}
                <VatAccountsTable
                  integrationStatus={integrationStatus}
                  vatAccounts={vatAccounts}
                  setModalState={setModalState}
                />

                <VatAddModal
                  setFormState={setModalState}
                  vatAccounts={vatAccounts}
                  formState={modalState}
                  integration={integration}
                />
                <VatDeleteModal
                  setFormState={setModalState}
                  formState={modalState}
                  integration={integrationStatus.integration}
                />
              </>
            );
          }}
        </QuerySuspense>
      </div>
      {displayReverseChargeQuery && (
        <div
          id="reverse-charge-accounts"
          className={styles.reverseChargeSection}
        >
          <div className={styles.header}>
            <div className={styles.headerText}>
              <h3 className="IntegrationAccountingPage__section-title heading-l">
                {t(
                  'bookkeep.integrations.settings.sectionReverseChargeAccounts',
                )}
              </h3>
              <p className={classNames(styles.headerTextDescription, 'body-m')}>
                {t(getReverseChargeAccountsDescriptionI18nKey(integration))}
                &nbsp;|&nbsp;
                <a
                  href={getReverseChargeAccountsHelpCenterLink(integration)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('misc.helpCenterArticle')}
                </a>
              </p>
            </div>
            <Button
              className={styles.headerBtn}
              text={t(
                'bookkeep.integrations.settings.reverseChargeAccountsTable.addReverseChargeAccount',
              )}
              variant="primaryBrand"
              onClick={() =>
                setModalState({
                  kind: 'addReverseCharge',
                  id: '',
                  name: '',
                  codeDebit: '',
                  codeCredit: '',
                  inputChanged: false,
                })
              }
            />
          </div>

          <QuerySuspense
            queryState={taxAccountQueryState}
            loading={<TaxAccountSkeletonTable numberOfRows={2} />}
            fallback={() => <TaxAccountErrorWarning />}
          >
            {(taxAccounts) => {
              const reverseChargeAccounts =
                filterIntegrationReverseChargeAccounts(taxAccounts);
              const emptyCallout = reverseChargeAccounts.length === 0 && (
                <Callout
                  className={styles.noVatAccountWarning}
                  variant="warning"
                  title={t(
                    'bookkeep.integrations.settings.noReverseChargeAccountWarning',
                  )}
                />
              );

              const reverseChargeAccountsErrorsList =
                reverseChargeAccounts.filter(({ id }) =>
                  taxAccountsErrorsList.includes(id),
                );
              const errorCallout = !!reverseChargeAccountsErrorsList.length && (
                <Callout
                  variant="alert"
                  title={t(
                    'bookkeep.integrations.datev.accountCodeAdvice.reverseChargeAccounts.title',
                    {
                      count: reverseChargeAccountsErrorsList.length,
                    },
                  )}
                  className={styles.errorCallout}
                />
              );

              return (
                <>
                  {emptyCallout}
                  {errorCallout}
                  {renderReverseChargeTable(
                    integrationStatus,
                    reverseChargeAccounts,
                    setModalState,
                  )}

                  <ReverseChargeAddModal
                    setFormState={setModalState}
                    reverseChargeAccounts={reverseChargeAccounts}
                    formState={modalState}
                    integration={integration}
                  />
                  <ReverseChargeDeleteModal
                    integration={integrationStatus.integration}
                    setFormState={setModalState}
                    formState={modalState}
                  />
                </>
              );
            }}
          </QuerySuspense>

          {withDefaultAccount ? (
            <DefaultTaxAccountForm
              integrationStatus={integrationStatus}
              getDefaultTaxAccountQueryState={getDefaultTaxAccountQueryState}
              setDefaultTaxAccountQueryState={setDefaultTaxAccountQueryState}
              onDefaultChange={onDefaultChange}
              defaultTaxAccount={defaultTaxAccount}
              isInitialToggleChecked={isDefaultTaxAccountToggleChecked}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

function renderReverseChargeTable(
  integrationStatus: IntegrationStatusWithIntegration,
  reverseChargeAccounts: IntegrationReverseChargeAccount[],
  setModalState: Dispatch<SetStateAction<ModalState>>,
) {
  switch (integrationStatus.integration) {
    case 'Xero':
      return <>TO DO</>;
    case 'Datev':
      return (
        <DatevReverseChargeTable
          integrationStatus={integrationStatus}
          reverseChargeAccounts={reverseChargeAccounts}
          setModalState={setModalState}
        />
      );
    case 'SpendeskAccounting':
    case 'Sage':
    case 'Cegid':
      return (
        <DoubleEntryReverseChargeTable
          integrationStatus={integrationStatus}
          reverseChargeAccounts={reverseChargeAccounts}
          setModalState={setModalState}
        />
      );
    case 'SpendeskAccountingSingleEntry':
      return (
        <SingleEntryReverseChargeTable
          integrationStatus={integrationStatus}
          reverseChargeAccounts={reverseChargeAccounts}
          setModalState={setModalState}
        />
      );
    case 'Netsuite':
    case 'Quickbooks':
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return <>TO DO</>;
    default:
      rejectUnexpectedValue(
        'render reverse charge table',
        integrationStatus.integration,
      );
  }
}
