import {
  AutocompleteNoOptions,
  FormField,
  TextInput,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import type { FormikProps } from 'formik';
import { Trans } from 'react-i18next';

import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { useTranslation } from 'common/hooks/useTranslation';
import type { FormValues } from 'modules/bookkeep/settings/export/components/TemplateEditorForm/formValues';
import { getAvailableAccountingSoftwares } from 'modules/bookkeep/settings/export/components/TemplateEditorForm/utils';

type Props = Pick<
  FormikProps<FormValues>,
  'values' | 'errors' | 'handleChange' | 'setFieldValue'
> & {
  visuallyHideLabel?: boolean;
};

export const AccountingSoftwareField = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  visuallyHideLabel,
}: Props) => {
  const { t } = useTranslation('global');
  const othersLabel = t('exports.others');
  const availableAccountingSoftwares =
    getAvailableAccountingSoftwares(othersLabel);

  return (
    <div className={cx(visuallyHideLabel && 'flex-col', 'flex gap-x-24')}>
      <FormField
        label={t('exports.accountingSoftwareNameLabel')}
        className="mb-16 w-[436px]"
        visuallyHideLabel={visuallyHideLabel}
        alertMessage={errors.accountingSoftware}
      >
        <AutocompleteSearch
          showClearSelectionButton
          value={
            values.accountingSoftware
              ? {
                  key: values.accountingSoftware,
                  label: values.accountingSoftware,
                }
              : undefined
          }
          fit="parent"
          options={availableAccountingSoftwares}
          isInvalid={!!errors.accountingSoftware}
          placeholder={t('exports.accountingSoftwareNamePlaceholder')}
          onSelect={(accountingSoftware) => {
            setFieldValue('accountingSoftware', accountingSoftware?.label);
          }}
          renderNoOptions={(search) => (
            <AutocompleteNoOptions>
              <>
                <p>
                  <Trans
                    i18nKey="exports.errors.unknownAccountingSoftware.noResults"
                    values={{ search }}
                    components={{
                      em: <strong className="title-m" />,
                    }}
                  />
                </p>
                <p>
                  <Trans
                    i18nKey="exports.errors.unknownAccountingSoftware.tooltip"
                    values={{ others: t('exports.others') }}
                    components={{
                      em: <strong className="title-m" />,
                    }}
                  />
                </p>
              </>
            </AutocompleteNoOptions>
          )}
        />
      </FormField>
      {values.accountingSoftware === othersLabel && (
        <FormField
          label={t('bookkeep.settings.export.customSoftwareName')}
          className="mb-16 w-[436px]"
          alertMessage={errors.customSoftwareName}
        >
          <TextInput
            placeholder={t(
              'bookkeep.settings.export.customSoftwareNamePlaceholder',
            )}
            name="customSoftwareName"
            onChange={handleChange}
            value={values.customSoftwareName ?? ''}
            isInvalid={!!errors.customSoftwareName}
          />
        </FormField>
      )}
    </div>
  );
};
