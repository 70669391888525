import { type Node, type Edge } from '@xyflow/react';
import { createContext, useContext } from 'react';

import { type CustomNode } from './node';

type WorkflowContextType = {
  nodes: CustomNode[];
  edges: Edge[];
  selectedNodeId: string | null;
  selectedNodeLevel: number | null;
  setNodes: (nodes: Node[]) => void;
  setEdges: (edges: Edge[]) => void;
  setSelectedNodeId: (nodeId: string | null) => void;
  createAndConnectNode: ({
    sourceNodeId,
    shouldSelectNewNode,
  }: {
    sourceNodeId: string;
    shouldSelectNewNode?: boolean;
  }) => void;
  deleteNode: (nodeId: string) => void;
};

export const WorkflowContext = createContext<WorkflowContextType | undefined>(
  undefined,
);

export const useWorkflowContext = () => {
  const context = useContext(WorkflowContext);
  if (!context) {
    throw new Error(
      'useWorkflowContext must be used within a WorkflowProvider',
    );
  }
  return context;
};
