import {
  Avatar,
  Callout,
  IconButton,
  Label,
  ListBox,
} from '@dev-spendesk/grapes';
import { type FormikErrors } from 'formik';
import React from 'react';

import { AutocompleteMultipleSearch } from 'common/components/AutocompleteSearch';
import { useTranslation } from 'common/hooks/useTranslation';

import { type ApprovalPolicyFormValues } from '../../formValues';
import { getMemberLabel, type Member } from '../../member';

type Props = {
  members: Member[];
  values: ApprovalPolicyFormValues;
  errors: FormikErrors<ApprovalPolicyFormValues>;
  isPolicyDefault?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue(field: string, value: any): void;
};

export const ApprovalPolicyMembersContent = ({
  members,
  values,
  errors,
  isPolicyDefault,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation('global');
  const membersById = new Map(members.map((member) => [member.id, member]));
  const selectedMembers = values.membersIds
    .map((memberId) => membersById.get(memberId))
    .filter(
      (member: Member | undefined): member is Member => member !== undefined,
    );

  return (
    <div>
      <p>{t('approvalPolicy.members.title')}</p>
      <div className="mt-24 text-left">
        <Label
          className="mb-8 block"
          label={t('approvalPolicy.members.label')}
        />

        <AutocompleteMultipleSearch
          fit="parent"
          placeholder={t('approvalPolicy.members.autocompleteLabel')}
          values={selectedMembers.map((member) => ({
            key: member.id,
            label: getMemberLabel(member),
          }))}
          options={members
            .filter((member) => !member.isAccountOwner)
            .map((member) => ({
              key: member.id,
              label: getMemberLabel(member),
            }))}
          translations={{
            selectAll: t('approvalPolicy.members.selectAll'),
            selected: t('approvalPolicy.members.selected', {
              count: selectedMembers.length,
            }),
          }}
          onSelect={(selectedOptions) => {
            setFieldValue(
              'membersIds',
              selectedOptions.map((option) => option.key),
            );
          }}
        />
      </div>
      {selectedMembers.length > 0 && (
        <div className="mt-24 text-left">
          <Label
            className="mb-8"
            label={t('approvalPolicy.members.assigned', {
              count: selectedMembers.length,
            })}
          />
          <ListBox
            className="max-h-[270px] overflow-y-auto"
            rowHeight="compact"
            emptyState={{ title: t('approvalPolicy.members.noMembers') }}
            getOptionId={(option) => option.id}
            options={selectedMembers}
          >
            {(selectedMember) => (
              <div className="group flex items-center justify-between">
                <div className="flex items-center gap-8">
                  <Avatar
                    src={selectedMember.avatar}
                    text={
                      selectedMember.isPending
                        ? selectedMember.email
                        : (selectedMember.fullname ?? '')
                    }
                  />
                  <div>{getMemberLabel(selectedMember)}</div>
                </div>
                <div className="-mr-8 opacity-0 group-hover:opacity-100 has-[:focus]:opacity-100">
                  <IconButton
                    iconName="trash"
                    aria-label={t('misc.delete')}
                    onClick={() => {
                      if (!isPolicyDefault) {
                        setFieldValue(
                          'membersIds',
                          values.membersIds.filter(
                            (memberId) => memberId !== selectedMember.id,
                          ),
                        );
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </ListBox>
        </div>
      )}
      {errors.membersIds && (
        <Callout
          variant="alert"
          title={errors.membersIds as string}
          className="mt-8"
        />
      )}
    </div>
  );
};
