import * as NEA from '@dev-spendesk/general-type-helpers/NonEmptyArray';
import { startOfToday } from 'date-fns';

import type { InvoiceRequestData } from '@finance-review/models/invoice';
import type { LineItem } from '@finance-review/models/invoice/lineItem';
import type { BankDetailsSuggestion, RawSupplier } from 'modules/suppliers';

import { type RawDocumentaryEvidence } from './documentaryEvidence';
import { type RawDuplicate, transformDuplicates } from './duplicateTransformer';
import {
  type RawDraftInvoice,
  transformInvoiceRequest,
} from './invoiceTransformer';
import { type RawTimeline, transformTimeline } from './timelineTransformer';
import { type RawUser, transformUser } from './userTransformer';

type InvoiceSupplier = RawSupplier & {
  supplier_details_suggestion: BankDetailsSuggestion | null;
};

export type RawInvoiceData = RawDraftInvoice & {
  supplier: InvoiceSupplier;
  duplicates: RawDuplicate[] | null;
  timeline?: RawTimeline;
  user: RawUser;
  invoices: RawDocumentaryEvidence[];
  lineItems?: LineItem[];
};

export const transformInvoiceData = (
  rawData: RawInvoiceData,
): InvoiceRequestData => ({
  bankDetailsSuggestion:
    rawData.supplier.supplier_details_suggestion ?? undefined,
  draftInvoiceRequest: transformInvoiceRequest(
    rawData,
    rawData.supplier,
    startOfToday(),
  ),
  documentaryEvidences: NEA.fromArray(rawData.invoices ?? []),
  duplicates: rawData.duplicates ? transformDuplicates(rawData.duplicates) : [],
  timeline: rawData.timeline ? transformTimeline(rawData.timeline) : undefined,
  requester: transformUser(rawData.user),
  lineItems: rawData.lineItems,
});
