import {
  SkeletonText,
  SkeletonAvatar,
  SkeletonButton,
  SkeletonTag,
} from '@dev-spendesk/grapes';
import React from 'react';

type Props = {
  active: boolean;
};

export function IntegrationBoxSkeleton(props: Props) {
  const { active } = props;
  return (
    <div className="box flex flex-col gap-16 p-24">
      <div className="flex flex-row items-center gap-8">
        <SkeletonAvatar variant="square" />
        <SkeletonText size="m" className="max-w-[25%]" />
        {active ? <SkeletonTag /> : null}
      </div>
      <div className="flex flex-col gap-8">
        <SkeletonText size="m" />
        <SkeletonText size="m" />
        <SkeletonText size="m" width="66%" />
      </div>
      <SkeletonButton width="100%" />
    </div>
  );
}
