import { Button, FormField, Modal } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import { useSetSupplierAccountToSupplierMutation } from 'modules/bookkeep/accounts-payable/hooks/useSetSupplierAccountToSupplierMutation';

import { type FormValues } from './form';
import { type SupplierAndAccountPayable } from '../../AccountingSupplierAccountsList';
import { SupplierAccountAutocomplete } from '../SupplierAccountAutocomplete';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  supplierAndAccountPayable: SupplierAndAccountPayable | null;
};

export const UpdateSupplierAccountModal = ({
  isOpen,
  onClose,
  supplierAndAccountPayable,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const [setSupplierAccountToSupplier] =
    useSetSupplierAccountToSupplierMutation();

  const {
    errors,
    handleSubmit,
    resetForm,
    setFieldError,
    setFieldValue,
    submitCount,
    values,
  } = useFormik<FormValues>({
    initialValues: {
      accountPayableId: supplierAndAccountPayable?.accountPayableId ?? '',
    },
    enableReinitialize: true,
    validate: (newValues) => {
      if (!newValues.accountPayableId) {
        return { accountPayableId: t('misc.requiredField') };
      }
    },
    onSubmit: async (newValues) => {
      if (!supplierAndAccountPayable) {
        return;
      }

      try {
        const setSupplierAccountToSupplierResult =
          await setSupplierAccountToSupplier({
            supplierId: supplierAndAccountPayable.supplierId,
            supplierAccountId: newValues.accountPayableId,
          });

        if (
          setSupplierAccountToSupplierResult.setSupplierAccountToSupplier.reason
        ) {
          dangerNotif(
            t(
              'bookkeep.accountsPayable.createSupplier.createErrorNotification',
            ),
          );
          return;
        }

        closeModal();
      } catch {
        dangerNotif(t('misc.errors.unknownError'));
      }
    },
  });

  const closeModal = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      iconName="plus"
      title={t('setupHub.supplierAccounts.updateSupplier.title', {
        name: supplierAndAccountPayable?.supplierName ?? '',
      })}
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          onClick={closeModal}
          text={t('misc.cancel')}
        />,
        <Button
          key="confirm"
          form="update-accounts-payable-form"
          type="submit"
          text={t('misc.confirm')}
        />,
      ]}
    >
      <form id="update-accounts-payable-form" onSubmit={handleSubmit}>
        <FormField
          label={t(
            'bookkeep.accountsPayable.createSupplier.accountPayableLabel',
          )}
          alertMessage={submitCount > 0 ? errors.accountPayableId : undefined}
        >
          <SupplierAccountAutocomplete
            error={errors.accountPayableId}
            onChange={(value: string) =>
              setFieldValue('accountPayableId', value)
            }
            setError={(newError: string | undefined) =>
              setFieldError('accountPayableId', newError)
            }
            value={values.accountPayableId}
          />
        </FormField>
      </form>
    </Modal>
  );
};
