import { Button, Modal } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import React from 'react';

import { useBlockNavigation } from 'common/hooks/useBlockNavigation';
import { useTranslation } from 'common/hooks/useTranslation';
import { type CurrenciesKey } from 'src/core/config/money';
import { useGetSupplierBankFields } from 'src/core/modules/suppliers/hooks';
import { fileToBase64 } from 'src/core/utils/files';

import { SupplierForm, type FormMode, type FormValues } from './SupplierForm';
import {
  getIbanSuggestion,
  getBicSuggestion,
  getShouldUseSuggestion,
  getCountryValue,
} from './helpers';
import { validateForm } from './validator';
import { type Supplier, type UpdatableSupplierDetails } from '../supplier';

type Props = {
  mode: FormMode;
  company: {
    id: string;
    currency: CurrenciesKey;
    country: string;
  };
  supplier?: Supplier;
  isBankProofFileDisplayed: boolean;
  warnForUnsavedChanges: boolean;
  onShowBankProofFile: (
    bankProofFile?: Supplier['details']['bankProofFile'],
  ) => void;
  onSaveSupplier: (
    supplierAccountCode: string | null,
    supplierDetails: UpdatableSupplierDetails,
    bankProofFile?:
      | {
          action: 'upload';
          base64: string;
        }
      | { action: 'delete' },
  ) => Promise<{
    outcome: 'success' | 'ibanError' | 'bankProofError' | 'unknownError';
  }>;
  onTrackSupplierSave?: (suggestionContext: {
    isIbanDisplayed: boolean;
    suggestedIban: string | null;
    isBicDisplayed: boolean;
    suggestedBic: string | null;
  }) => void;
  showBankProofFileInline: boolean;
};

export const SupplierFormContainer = ({
  mode,
  company,
  supplier,
  warnForUnsavedChanges,
  onTrackSupplierSave,
  onSaveSupplier,
  ...rest
}: Props) => {
  const { t } = useTranslation('global');

  const getSupplierBankFields = useGetSupplierBankFields();
  const initialValues = getInitialValues(company, supplier);

  const formikProps = useFormik({
    initialValues,
    initialErrors:
      mode === 'edition'
        ? validateForm(initialValues, {
            supplierBankFields: getSupplierBankFields({
              bankCountry: initialValues.bankCountry ?? undefined,
              iban: initialValues.iban ?? undefined,
              bic: initialValues.bic ?? undefined,
              sortCode: initialValues.sortCode ?? undefined,
              accountCode: initialValues.accountCode ?? undefined,
              accountNumber: initialValues.accountNumber ?? undefined,
              routingNumber: initialValues.routingNumber ?? undefined,
              accountHolderName: initialValues.accountHolderName ?? undefined,
            }),
          })
        : undefined,
    enableReinitialize: true,
    validateOnChange: false,
    validate: (values: FormValues) => {
      return validateForm(values, {
        supplierBankFields: getSupplierBankFields({
          bankCountry: values.bankCountry ?? undefined,
          iban: values.iban ?? undefined,
          bic: values.bic ?? undefined,
          sortCode: values.sortCode ?? undefined,
          accountCode: values.accountCode ?? undefined,
          accountNumber: values.accountNumber ?? undefined,
          routingNumber: values.routingNumber ?? undefined,
          accountHolderName: values.accountHolderName ?? undefined,
        }),
      });
    },
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);

      const supplierDetails = {
        accountHolderName: values.accountHolderName,
        legalName: values.legalName,
        registrationNumber: values.registrationNumber,
        vatNumber: values.vatNumber,
        address: values.address,
        city: values.city,
        zipcode: values.zipcode,
        country: values.country,
        bankDetails: {
          country: values.bankCountry,
          iban: values.iban,
          bic: values.bic,
          sortCode: values.sortCode,
          accountNumber: values.accountNumber,
          accountCode: values.accountCode,
          routingNumber: values.routingNumber,
        },
      };

      const handleResult = ({
        outcome,
      }: {
        outcome: 'success' | 'ibanError' | 'bankProofError' | 'unknownError';
      }) => {
        if (outcome === 'ibanError') {
          setFieldError('iban', 'invalid');
        }
        // track supplier creation success
        if (outcome === 'success' && onTrackSupplierSave) {
          const shouldUseSuggestion = getShouldUseSuggestion({
            companyCountry: company.country,
            bankCountry: values.bankCountry,
            companyCurrency: company.currency,
          });
          // if we used suggestion in the form
          if (shouldUseSuggestion) {
            onTrackSupplierSave({
              isIbanDisplayed: Boolean(values.bankInfoSuggestion.iban),
              suggestedIban:
                values.bankInfoSuggestion.iban &&
                values.bankInfoSuggestion.iban.text,
              isBicDisplayed: Boolean(values.bankInfoSuggestion.bic),
              suggestedBic:
                values.bankInfoSuggestion.bic &&
                values.bankInfoSuggestion.bic.text,
            });
          }
        }
        setSubmitting(false);
      };

      // Save supplier details + upload new file
      if (values.bankProofFile && values.bankProofFile.fileToUpload) {
        const fileContent = await fileToBase64(
          values.bankProofFile.fileToUpload,
        );
        const result = await onSaveSupplier(
          values.supplierAccountCode,
          supplierDetails,
          {
            action: 'upload',
            base64: fileContent,
          },
        );
        return handleResult(result);
      }

      // Save supplier details + delete existing file
      if (!values.bankProofFile && supplier && supplier.details.bankProofFile) {
        const result = await onSaveSupplier(
          values.supplierAccountCode,
          supplierDetails,
          {
            action: 'delete',
          },
        );
        return handleResult(result);
      }

      // Save supplier details + delete existing file
      const result = await onSaveSupplier(
        values.supplierAccountCode,
        supplierDetails,
      );
      handleResult(result);
    },
  });

  const { shouldConfirmNavigation, confirmNavigation, cancelNavigation } =
    useBlockNavigation(warnForUnsavedChanges && formikProps.dirty);

  return (
    <>
      <SupplierForm
        mode={mode}
        supplier={supplier}
        {...formikProps}
        {...rest}
      />
      <Modal
        isOpen={shouldConfirmNavigation}
        iconName="triangle-warning"
        iconVariant="warning"
        title={t('submitMyInvoice.confirmModal.title')}
        portalContainer={
          document.getElementById('react__grapes-modal-higher-z-index') ||
          undefined
        }
        actions={[
          <Button
            key="no"
            variant="secondaryNeutral"
            text={t('submitMyInvoice.confirmModal.continueActionText')}
            onClick={() => confirmNavigation()}
          />,
          <Button
            key="yes"
            variant="primaryWarning"
            text={t('submitMyInvoice.confirmModal.saveActionText')}
            onClick={async () => {
              await formikProps.submitForm();
              if (formikProps.isValid) {
                confirmNavigation();
              } else {
                cancelNavigation();
              }
            }}
          />,
        ]}
      >
        {t('submitMyInvoice.confirmModal.content')}
      </Modal>
    </>
  );
};

const emptyFormValues: FormValues = {
  accountHolderName: null,
  supplier: null,
  legalName: null,
  supplierAccountCode: null,
  registrationNumber: null,
  vatNumber: null,
  address: null,
  zipcode: null,
  city: null,
  country: null,
  bankCountry: null,
  iban: null,
  bic: null,
  sortCode: null,
  routingNumber: null,
  accountNumber: null,
  accountCode: null,
  bankInfoSuggestion: { iban: null, bic: null },
  bankProofFile: null,
};

const getInitialValues = (
  company: {
    id: string;
    currency: CurrenciesKey;
    country: string;
  },
  supplier: Supplier | undefined,
) => {
  if (!supplier) {
    // we don't have a supplier yet (creation)
    return emptyFormValues;
  }

  const {
    id: supplierId,
    name: supplierName,
    supplierAccountCode,
    details: supplierDetails,
  } = supplier;
  const bankDetailsSuggestion =
    supplierDetails.bankDetailsSuggestion &&
    supplierDetails.bankDetailsSuggestion.status === 'ready'
      ? supplierDetails.bankDetailsSuggestion
      : null;
  const bankCountryValue = getCountryValue({
    initialCountryValue: supplierDetails.bankDetails.country,
    countrySuggestion: bankDetailsSuggestion
      ? bankDetailsSuggestion.country
      : null,
  });
  const ibanSuggestion = getIbanSuggestion({
    initialIbanValue: supplierDetails.bankDetails.iban,
    ibanSuggestion: bankDetailsSuggestion ? bankDetailsSuggestion.iban : null,
  });
  const bicSuggestion = getBicSuggestion({
    initialBicValue: supplierDetails.bankDetails.bic,
    bicSuggestion: bankDetailsSuggestion ? bankDetailsSuggestion.bic : null,
  });
  const shouldUseSuggestion = getShouldUseSuggestion({
    companyCountry: company.country,
    bankCountry: bankCountryValue,
    companyCurrency: company.currency,
  });

  return {
    supplier: {
      id: supplierId,
      name: supplierName,
    },
    accountHolderName: supplierDetails.accountHolderName,
    legalName: supplierDetails.legalName || supplierName,
    supplierAccountCode,
    registrationNumber: supplierDetails.registrationNumber,
    vatNumber: supplierDetails.vatNumber,
    address: supplierDetails.address,
    zipcode: supplierDetails.zipcode,
    city: supplierDetails.city,
    country: supplierDetails.country,
    bankCountry: bankCountryValue,
    iban:
      shouldUseSuggestion && ibanSuggestion
        ? ibanSuggestion.text
        : supplierDetails.bankDetails.iban,
    bic:
      shouldUseSuggestion && bicSuggestion
        ? bicSuggestion.text
        : supplierDetails.bankDetails.bic,
    sortCode: supplierDetails.bankDetails.sortCode,
    routingNumber: supplierDetails.bankDetails.routingNumber,
    accountNumber: supplierDetails.bankDetails.accountNumber,
    accountCode: supplierDetails.bankDetails.accountCode,
    bankInfoSuggestion: {
      iban: ibanSuggestion,
      bic: bicSuggestion,
    },
    bankProofFile: supplierDetails.bankProofFile
      ? {
          ...supplierDetails.bankProofFile,
          isUploaded: true,
        }
      : null,
  };
};
