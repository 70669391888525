import { Skeleton, SkeletonText } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useFeature } from 'common/hooks/useFeature';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useCardAddressQuery } from 'src/core/common/hooks/useCardAddressQuery';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { SessionStorageKey } from 'src/core/constants/storage';
import { setSessionItem as setItem } from 'src/core/utils/storage';

import { CardActivation } from './CardActivation/CardActivation';
import { PlasticCard } from './PlasticCard';
import type { ApiCard } from '../../card';
import { useCardsAccessQuery } from '../../hooks/useCardAccessQuery';
import { useCardActivityQuery } from '../../hooks/useCardActivityQuery';
import { useCardLoadsQuery } from '../../hooks/useCardLoadsQuery';
import { useCardPaymentsQuery } from '../../hooks/useCardPaymentsQuery';
import { useCardQuery } from '../../hooks/useCardQuery';
import { useCardSettingsQuery } from '../../hooks/useCardSettingsQuery';
import { usePlasticCard } from '../hooks/usePlasticCard';

type Props = {
  cardId: string;
};

const CardContainer = ({ cardId }: Props) => {
  const queryClient = useQueryClient();
  const [isCardActivationStep1Open, setIsCardActivationStep1Open] =
    useState(false);

  const cardQueryState = useCardQuery(cardId);
  const cardAddressQueryState = useCardAddressQuery(cardId);
  const cardActivityQueryState = useCardActivityQuery(cardId);
  const cardLoadsQueryState = useCardLoadsQuery(cardId);
  const cardPaymentsQueryState = useCardPaymentsQuery(cardId);
  const cardSettingsQueryState = useCardSettingsQuery(cardId);
  const cardsAccessQueryState = useCardsAccessQuery(
    cardQueryState.status === 'success'
      ? cardQueryState.data.user_id
      : undefined,
  );

  return (
    <QuerySuspense
      queryState={cardQueryState}
      loading={
        <div className="page__container">
          <div className="mx-auto my-48 flex w-[980px] gap-32 rounded-12 bg-secondary-default p-48">
            <Skeleton width="300px" height="190px" />
            <div className="flex flex-col">
              <SkeletonText className="mb-8" size="xxl" width="140px" />
              <SkeletonText className="mb-16" width="140px" />
              <SkeletonText className="mb-8" size="xxl" width="140px" />
              <SkeletonText className="mb-16" width="140px" />
            </div>
          </div>
        </div>
      }
      fallback={(error) => (
        <QueryError componentType="Callout" queryError={error} />
      )}
    >
      {(card) => {
        if (
          (!getIsCardActivated(card) && card.banking_provider === 'marqeta') ||
          isCardActivationStep1Open
        ) {
          return (
            <div className="page__container">
              <CardActivation
                card={card}
                onFinish={() => {
                  setItem(SessionStorageKey.CardActivated, '1');
                  queryClient.invalidateQueries(['cards', cardId], {
                    exact: true,
                  });

                  window.location.reload();
                }}
                startStep={isCardActivationStep1Open ? 1 : 0}
              />
            </div>
          );
        }

        return (
          <div className="page__container">
            <PlasticCard
              card={card}
              cardAddress={unwrapQuery(cardAddressQueryState)}
              cardActivity={unwrapQuery(cardActivityQueryState)}
              cardLoads={unwrapQuery(cardLoadsQueryState)}
              cardPayments={unwrapQuery(cardPaymentsQueryState)}
              cardSettings={unwrapQuery(cardSettingsQueryState)}
              cardsAccess={unwrapQuery(cardsAccessQueryState)}
              refreshCard={() => {
                queryClient.invalidateQueries(['cards', cardId], {
                  exact: true,
                });
                queryClient.invalidateQueries(['cards', cardId, 'activity']);
                queryClient.invalidateQueries(['cards', cardId, 'loads']);
              }}
              onCardReceptionConfirmationClick={() =>
                setIsCardActivationStep1Open(true)
              }
            />
          </div>
        );
      }}
    </QuerySuspense>
  );
};

export const MyCardContainer = () => {
  const company = useCompany();
  const history = useHistory();
  const isPageAccessible = useIsPageAccessible();
  const card = usePlasticCard();

  useEffect(() => {
    if (!isPageAccessible || !card?.id) {
      history.push(routeFor(routes.NOT_FOUND.path, { company: company.id }));
    }
  }, [isPageAccessible]);

  if (!isPageAccessible || !card?.id) {
    return null;
  }

  return (
    <ErrorBoundary context={{ scope: 'my-card', team: 'capture' }}>
      <CardContainer cardId={card?.id} />
    </ErrorBoundary>
  );
};

const getIsCardActivated = (card: ApiCard): boolean => {
  return Boolean(card.last_pin_action) || Boolean(card.date_first_activation);
};

const useIsPageAccessible = (): boolean => {
  const user = useUser();
  const company = useCompany();
  const hasPlasticCardsFeature = useFeature(FEATURES.PLASTIC_CARDS);
  return (
    hasPlasticCardsFeature && user.has_plastic_card && !company.churning_at
  );
};
