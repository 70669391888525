import { Button, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type WireTransfersSentToPaymentModalProps = {
  count: number;
  isOpen: boolean;
  onComplete: () => void;
};

export const WireTransfersSentToPaymentModal = ({
  count,
  isOpen,
  onComplete,
}: WireTransfersSentToPaymentModalProps) => {
  const { t } = useTranslation('global');

  const titleLabel = t(
    'submitMyInvoice.scheduled.wireTransfer.sentToPaymentModalTitle',
    {
      count,
    },
  );

  const descriptionLabel = t(
    'submitMyInvoice.scheduled.wireTransfer.sentToPaymentModalDescription',
    { count },
  );

  return (
    <Modal
      actions={[
        <Button
          key="continue"
          onClick={onComplete}
          text={t('submitMyInvoice.scheduled.wireTransfer.continue')}
          variant="primaryBrand"
        />,
      ]}
      iconName="clock-outline"
      iconVariant="info"
      title={titleLabel}
      isOpen={isOpen}
    >
      <div>{descriptionLabel}</div>
    </Modal>
  );
};
