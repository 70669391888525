import {
  Skeleton,
  SkeletonButton,
  SkeletonTag,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

type Props = {
  withBoxes?: boolean;
};

export const BudgetPageLoader = ({ withBoxes = true }: Props) => {
  return (
    <div className="px-24">
      <div className="mt-48">
        <SkeletonText size="xxl" width="200px" />
        <div className="flex items-center justify-between">
          <SkeletonTag width="250px" />
          <div className="flex items-center gap-16">
            <SkeletonButton />
            <Skeleton height="36px" width="36px" />
          </div>
        </div>
        {withBoxes && (
          <div className="mt-24 flex items-center justify-between gap-32">
            <div className="box w-full">
              <SkeletonText size="l" width="100px" className="mb-64" />
              <Skeleton height="16px" width="100%" />
            </div>
            <div className="box w-full">
              <SkeletonText size="l" width="100px" className="mb-64" />
              <Skeleton height="16px" width="100%" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
