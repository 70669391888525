import { type Route } from 'src/core/constants/routes';

export const routes: Record<string, Route> = {
  ROOT: {
    path: '/',
    analytics: {
      category: 'Auth',
      name: 'root',
    },
  },
  VERIFY_EMAIL: {
    path: '/auth/login/verify',
    analytics: {
      category: 'Auth',
      name: 'verify_email',
    },
  },
  LOGIN: {
    path: '/auth/login',
    analytics: {
      category: 'Auth',
      name: 'login',
    },
  },
  SAML: {
    path: '/auth/login/saml',
    analytics: {
      category: 'Auth',
      name: 'saml',
    },
  },
  FORGET: {
    path: '/auth/password/forget',
    analytics: {
      category: 'Auth',
      name: 'password_forget',
    },
  },
  RESET: {
    path: '/auth/password/reset/:token',
    analytics: {
      category: 'Auth',
      name: 'password_reset',
    },
  },
  SIGN_UP: {
    path: '/auth/invites',
    analytics: {
      category: 'Auth',
      name: 'invites',
    },
  },
  SIGN_UP_LINK: {
    path: '/auth/invites/link',
    analytics: {
      category: 'Auth',
      name: 'invites_link',
    },
  },
  SIGN_UP_JOIN_COMPANY: {
    path: '/auth/invites/join-company',
    analytics: {
      category: 'Auth',
      name: 'join_company',
    },
  },
  SIGN_UP_DECLINE: {
    path: '/auth/invites/decline',
    analytics: {
      category: 'Auth',
      name: 'invites_decline',
    },
  },
  LOGIN_SCA: {
    path: '/auth/login/sca',
    analytics: {
      category: 'Auth',
      name: 'login_sca',
    },
  },
  PASSWORD_RESET_SCA: {
    path: '/auth/password-reset/sca',
    analytics: {
      category: 'Auth',
      name: 'password_reset_sca',
    },
  },
  COMPANY_SELECT: {
    path: '/auth/login/select',
    analytics: {
      category: 'Auth',
      name: 'company_select',
    },
  },
  REGISTER: {
    path: '/auth/register',
    analytics: {
      category: 'Auth',
      name: 'register',
    },
  },
};
