/**
 * Converts a distance in meters to the given unit (km or mi)
 *
 *  Do note @precision only works when @shouldRound is true
 */
export const metersToDistanceUnit = ({
  distanceMeters,
  distanceUnit,
  precision = 0,
  shouldRound = true,
}: {
  distanceMeters: number;
  distanceUnit: 'km' | 'mi';
  precision?: number;
  shouldRound?: boolean;
}) => {
  const kilometerConversion = distanceMeters / 1000;
  const mileConversion = distanceMeters / 1609.34;

  const convertedDistance =
    distanceUnit === 'km' ? kilometerConversion : mileConversion;

  if (shouldRound) {
    const modifier = 10 ** precision;
    return Math.round(convertedDistance * modifier) / modifier;
  }
  // Does not make sense to use precision here
  // as in `convertedDistance.toFixed(precision)` for example because a call
  // to toFixed rounds floating point numbers which we want to avoid
  return convertedDistance;
};

/**
 * Converts a distance in the given unit to meters to the given unit (km or mi)
 *
 *  Do note @precision only works when @shouldRound is true
 */
export const distanceUnitToMeters = ({
  distance,
  distanceUnit,
  shouldRound = true,
}: {
  distance: number;
  distanceUnit: 'km' | 'mi';
  shouldRound?: boolean;
}) => {
  const kilometerConversion = distance * 1000;
  const mileConversion = distance * 1609.34;

  const convertedDistance =
    distanceUnit === 'km' ? kilometerConversion : mileConversion;

  return shouldRound ? Math.round(convertedDistance) : convertedDistance;
};
