import { Button, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { openSupportChat } from 'src/core/utils/supportChat';

type Props = {
  isOpen: boolean;
  onCloseModal(): void;
};
export const CostCenterActivationErrorModal = ({
  isOpen,
  onCloseModal,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      title={t('costCenters.activation.modal.error.title')}
      iconName="cross"
      iconVariant="alert"
      actions={[
        <Button
          key="button"
          type="button"
          variant="secondaryNeutral"
          text={t('costCenters.activation.modal.error.button')}
          onClick={() => {
            openSupportChat();
            onCloseModal();
          }}
        />,
      ]}
      isOpen={isOpen}
      onClose={() => onCloseModal()}
    >
      {t('costCenters.activation.modal.error.content')}
    </Modal>
  );
};
