import { colors, Icon, Tooltip } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  isPinned: boolean;
  togglePinnedEntity: () => void;
};

export const EntityItemHeaderPin = ({
  isPinned,
  togglePinnedEntity,
}: Props) => {
  const { t } = useTranslation('global');
  const [isHovered, setIsHovered] = useState(false);
  const isPinnedOrHovered = isHovered || isPinned;

  return (
    <Tooltip
      content={
        isPinned
          ? t('organisation.reporting.page.entities.favorites.remove')
          : t('organisation.reporting.page.entities.favorites.add')
      }
      triggerAsChild
    >
      <button
        type="button"
        className="rounded-none text-inherit flex cursor-pointer items-center border-none no-underline outline-none"
        style={{ background: 'inherit' }}
        onClick={togglePinnedEntity}
        onMouseOver={() => setIsHovered(true)}
        onFocus={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        aria-label={
          isPinned
            ? t('organisation.reporting.page.entities.favorites.remove')
            : t('organisation.reporting.page.entities.favorites.add')
        }
      >
        <Icon
          name={isPinnedOrHovered ? 'star-filled' : 'star-outline'}
          color={
            isPinnedOrHovered
              ? colors.contentSelected
              : colors.contentDecorativeIcon
          }
          className="max-h-[20px] min-h-[20px] min-w-[20px] max-w-[20px]"
        />
      </button>
    </Tooltip>
  );
};
