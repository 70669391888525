import { type PaymentMethod } from 'modules/company';

import {
  type InvoicesCounts,
  type PaymentsBatchPaymentDetails,
  type PaymentToSchedule,
  type PaymentToScheduleDetails,
} from '../models';
import { type PaymentComplianceResult } from '../payment/models';

export const FETCH_INVOICES_COUNTS_REQUEST =
  'invoices/FETCH_INVOICES_COUNTS_REQUEST';
export const FETCH_INVOICES_COUNTS_SUCCESS =
  'invoices/FETCH_INVOICES_COUNTS_SUCCESS';
export const FETCH_INVOICES_COUNTS_FAILURE =
  'invoices/FETCH_INVOICES_COUNTS_FAILURE';
export const FETCH_PAYMENTS_TO_SCHEDULE_REQUEST =
  'invoices/FETCH_PAYMENTS_TO_SCHEDULE_REQUEST';
export const FETCH_PAYMENTS_TO_SCHEDULE_SUCCESS =
  'invoices/FETCH_PAYMENTS_TO_SCHEDULE_SUCCESS';
export const FETCH_PAYMENTS_TO_SCHEDULE_FAILURE =
  'invoices/FETCH_PAYMENTS_TO_SCHEDULE_FAILURE';
export const RESET_PAYMENTS_TO_SCHEDULE = 'invoices/RESET_PAYMENTS_TO_SCHEDULE';
export const FETCH_PAYMENT_TO_SCHEDULE_DETAILS_SUCCESS =
  'invoices/FETCH_PAYMENT_TO_SCHEDULE_DETAILS_SUCCESS';
export const RESET_PAYMENT_TO_SCHEDULE_DETAILS =
  'invoices/RESET_PAYMENT_TO_SCHEDULE_DETAILS';
export const SET_SELECTED_PAYMENTS_TO_SCHEDULE =
  'invoices/SET_SELECTED_PAYMENTS_TO_SCHEDULE';
export const SCHEDULE_PAYMENTS_REQUEST = 'invoices/SCHEDULE_PAYMENTS_REQUEST';
export const SCHEDULE_PAYMENTS_SUCCESS = 'invoices/SCHEDULE_PAYMENTS_SUCCESS';
export const SCHEDULE_PAYMENTS_FAILURE = 'invoices/SCHEDULE_PAYMENTS_FAILURE';
export const SET_SELECTED_PAYMENT_METHOD =
  'invoices/SET_SELECTED_PAYMENT_METHOD';
export const SET_PAYMENT_COMPLIANCE = 'invoices/SET_PAYMENT_COMPLIANCE';
export const RESET_PAYMENT_COMPLIANCE = 'invoices/RESET_PAYMENT_COMPLIANCE';
export const FETCH_PAYMENT_DETAILS_SUCCESS =
  'invoices/FETCH_PAYMENT_DETAILS_SUCCESS';
export const RESET_PAYMENT_DETAILS = 'invoices/RESET_PAYMENT_DETAILS';
export const SET_INVOICE_REVIEW_FILTERS =
  'invoices/SET_INVOICE_REVIEWS_FILTERS';

export type FetchInvoicesCountsRequest = Readonly<{
  type: typeof FETCH_INVOICES_COUNTS_REQUEST;
}>;

export type FetchInvoicesCountsSuccess = Readonly<{
  type: typeof FETCH_INVOICES_COUNTS_SUCCESS;
  payload: InvoicesCounts;
}>;

export type FetchInvoicesCountsFailure = Readonly<{
  type: typeof FETCH_INVOICES_COUNTS_FAILURE;
}>;

export type FetchPaymentsToScheduleRequest = Readonly<{
  type: typeof FETCH_PAYMENTS_TO_SCHEDULE_REQUEST;
}>;

export type FetchPaymentsToScheduleSuccess = Readonly<{
  type: typeof FETCH_PAYMENTS_TO_SCHEDULE_SUCCESS;
  payload: Readonly<{
    nextCursor: string | number | null;
    skipSelectionReducerPopulate?: boolean;
    items: PaymentToSchedule[];
  }>;
}>;

export type FetchPaymentsToScheduleFailure = Readonly<{
  type: typeof FETCH_PAYMENTS_TO_SCHEDULE_FAILURE;
}>;

export type ResetPaymentsToSchedule = Readonly<{
  type: typeof RESET_PAYMENTS_TO_SCHEDULE;
}>;

export type FetchPaymentToScheduleDetailsSuccess = Readonly<{
  type: typeof FETCH_PAYMENT_TO_SCHEDULE_DETAILS_SUCCESS;
  payload: {
    paymentToScheduleDetails: PaymentToScheduleDetails;
  };
}>;

export type ResetPaymentToScheduleDetails = Readonly<{
  type: typeof RESET_PAYMENT_TO_SCHEDULE_DETAILS;
}>;

export type SetSelectedPaymentsToSchedule = Readonly<{
  type: typeof SET_SELECTED_PAYMENTS_TO_SCHEDULE;
  payload: Readonly<{
    paymentsToSchedule: PaymentToSchedule[];
    isPaymentListFullySelected: boolean;
  }>;
}>;
export type SetPaymentCompliance = Readonly<{
  type: typeof SET_PAYMENT_COMPLIANCE;
  payload: PaymentComplianceResult[];
}>;

export type ResetPaymentCompliance = Readonly<{
  type: typeof RESET_PAYMENT_COMPLIANCE;
}>;

export type SchedulePaymentsRequest = Readonly<{
  type: typeof SCHEDULE_PAYMENTS_REQUEST;
}>;

export type SchedulePaymentsSuccess = Readonly<{
  type: typeof SCHEDULE_PAYMENTS_SUCCESS;
}>;

export type SchedulePaymentsFailure = Readonly<{
  type: typeof SCHEDULE_PAYMENTS_FAILURE;
}>;

export type SetSelectedPaymentMethod = Readonly<{
  type: typeof SET_SELECTED_PAYMENT_METHOD;
  payload: Readonly<{
    paymentMethod: PaymentMethod;
  }>;
}>;

export type FetchPaymentDetailsSuccess = Readonly<{
  type: typeof FETCH_PAYMENT_DETAILS_SUCCESS;
  payload: {
    paymentDetails: PaymentsBatchPaymentDetails;
  };
}>;

export type ResetPaymentDetails = Readonly<{
  type: typeof RESET_PAYMENT_DETAILS;
}>;

export type SetInvoicesReviewFilters = Readonly<{
  type: typeof SET_INVOICE_REVIEW_FILTERS;
  payload: Readonly<{
    order_by?: string;
    status?: string;
    [key: string]: unknown;
  }>;
}>;

export type InvoicesActions =
  | FetchInvoicesCountsRequest
  | FetchInvoicesCountsSuccess
  | FetchInvoicesCountsFailure
  | FetchPaymentsToScheduleRequest
  | FetchPaymentsToScheduleSuccess
  | FetchPaymentsToScheduleFailure
  | ResetPaymentsToSchedule
  | FetchPaymentToScheduleDetailsSuccess
  | ResetPaymentToScheduleDetails
  | SchedulePaymentsRequest
  | SchedulePaymentsSuccess
  | SchedulePaymentsFailure
  | SetSelectedPaymentMethod
  | FetchPaymentDetailsSuccess
  | ResetPaymentDetails
  | SetInvoicesReviewFilters
  | SetPaymentCompliance
  | ResetPaymentCompliance
  | SetSelectedPaymentsToSchedule;
