import {
  Button,
  FormField,
  SwitchField,
  TextInput,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  type DefaultEmployeeAccount,
  type EmployeeAccountCode,
  getDefaultEmployeeAccountName,
} from 'modules/bookkeep';
import { type SupplierAccount } from 'modules/bookkeep/accounts-payable/types';
import { useHasAuxiliaryAccountsEnabled } from 'modules/bookkeep/hooks';
import { type QueryState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { EmployeeAccountDefaultDeleteConfirmationModal } from './modals/EmployeeAccountDefaultDeleteConfirmationModal';
import { EmployeeAccountDefaultEditConfirmationModal } from './modals/EmployeeAccountDefaultEditConfirmationModal';
import { EmployeeAccountAdvice } from '../../../../../../../components/AccountAdvice';
import {
  hasIntegrationFileBasedExport,
  type IntegrationStatusWithIntegration,
} from '../../../../../../../integration/status';
import { CodeInUseCallout } from '../../../components/CodeInUseCallout/CodeInUseCallout';
import {
  getCodeInUseLinkForAccountsPayable,
  validateAccountPayable,
} from '../../../utils';
import styles from '../EmployeeAccountsSection.module.css';

type ErrorState = {
  error: 'codeAlreadyInUse' | 'invalidPattern' | 'required';
  codeInUseAccount?:
    | {
        kind: 'supplierAccount';
        account: SupplierAccount;
      }
    | {
        kind: 'employeeAccount';
        account: EmployeeAccountCode;
      };
};

function getErrorTranslation(
  errorState: ErrorState,
  t: TGlobalFunctionTyped,
): string | undefined {
  switch (errorState.error) {
    case 'required':
      return t(
        'bookkeep.integrations.settings.defaultEmployeeAccountForm.requiredError',
      );
    default:
      return undefined;
  }
}

interface Props {
  onSetDefault: (
    defaultEmployeeAccount: DefaultEmployeeAccount,
  ) => Promise<void>;
  defaultEmployeeAccount: DefaultEmployeeAccount | undefined;
  getSupplierAccountsQueryState: QueryState<SupplierAccount[], unknown>;
  getEmployeeAccountCodesQueryState: QueryState<EmployeeAccountCode[], unknown>;
  accountLength: number;
  integrationStatus: IntegrationStatusWithIntegration;
  isInitialToggleChecked: boolean;
}

export const DefaultEmployeeAccountFormDeprecated = ({
  onSetDefault,
  defaultEmployeeAccount,
  getSupplierAccountsQueryState,
  getEmployeeAccountCodesQueryState,
  accountLength,
  integrationStatus,
  isInitialToggleChecked,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  const [newDefaultEmployeeAccount, setNewDefaultEmployeeAccount] = useState(
    getInitialDefaultAccount(integrationStatus, defaultEmployeeAccount),
  );
  const [errorState, setErrorState] = useState<ErrorState | undefined>();
  const [inputChanged, setInputChanged] = useState(false);
  const [isToggleChecked, setIsToggleChecked] = useState(
    isInitialToggleChecked,
  );
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();

  useEffect(() => {
    setIsToggleChecked(isInitialToggleChecked);
  }, [isInitialToggleChecked]);

  const [modalState, setModalState] = useState<{
    kind: 'closed' | 'confirmEdit' | 'confirmDelete';
  }>({ kind: 'closed' });

  useEffect(() => {
    if (
      defaultEmployeeAccount &&
      !!integrationStatus?.settingsValidation?.employeeAccounts
    ) {
      const defaultAccountServerSideValidationErrors = (
        integrationStatus?.settingsValidation?.employeeAccounts || []
      ).filter(
        (error) =>
          error.error === 'invalidAccount' &&
          error.id &&
          defaultEmployeeAccount.id &&
          error.id === defaultEmployeeAccount.id,
      );

      if (defaultAccountServerSideValidationErrors.length === 1) {
        setErrorState({
          error: 'invalidPattern',
          codeInUseAccount: {
            kind: 'employeeAccount',
            account: {
              id: defaultEmployeeAccount.id ?? '',
              generalAccountCode: defaultEmployeeAccount.generalAccountCode,
              auxiliaryAccountCode: defaultEmployeeAccount.auxiliaryAccountCode,
            },
          },
        });
      } else {
        setErrorState(undefined);
      }
    }
  }, [
    defaultEmployeeAccount,
    integrationStatus.integration,
    integrationStatus.settingsValidation.employeeAccounts,
  ]);

  const hasFileBasedIntegration = hasIntegrationFileBasedExport(
    integrationStatus.integration,
  );

  return (
    <div className={classNames(styles.defaultForm__deprecated, 'box')}>
      {hasFileBasedIntegration ? (
        <SwitchField
          fit="parent"
          id="useDefaultAccount"
          name="useDefaultAccount"
          label={t(
            'bookkeep.integrations.settings.defaultEmployeeAccountForm.title',
          )}
          helpText={t(
            'bookkeep.integrations.settings.defaultEmployeeAccountForm.description',
          )}
          isChecked={isToggleChecked}
          onChange={() => {
            setIsToggleChecked(!isToggleChecked);
            if (isToggleChecked && defaultEmployeeAccount) {
              setNewDefaultEmployeeAccount({
                ...defaultEmployeeAccount,
                isArchived: true,
              });
            } else if (defaultEmployeeAccount) {
              setNewDefaultEmployeeAccount({
                ...defaultEmployeeAccount,
                isArchived: false,
              });
            }
            setIsToggleChecked(!isToggleChecked);
          }}
        />
      ) : (
        <>
          <h4 className="text-primary title-m">
            {t(
              'bookkeep.integrations.settings.defaultEmployeeAccountForm.title',
            )}
          </h4>
          <p className={classNames(styles.defaultForm__description, 'body-m')}>
            {t(
              'bookkeep.integrations.settings.defaultEmployeeAccountForm.description',
            )}
          </p>
        </>
      )}
      {(!hasFileBasedIntegration || isToggleChecked) && (
        <FormField
          className={styles.defaultForm__input__deprecated}
          label={t(
            'bookkeep.integrations.settings.defaultEmployeeAccountForm.name',
          )}
          htmlFor="set-account-input"
          alertMessage={
            !!errorState && !inputChanged
              ? getErrorTranslation(errorState, t)
              : undefined
          }
        >
          <TextInput
            placeholder={t(
              'bookkeep.integrations.settings.defaultEmployeeAccountForm.placeholder',
            )}
            isInvalid={!!errorState && !inputChanged}
            value={newDefaultEmployeeAccount?.generalAccountCode ?? ''}
            onChange={(e) => {
              setNewDefaultEmployeeAccount({
                ...newDefaultEmployeeAccount,
                generalAccountCode: e.target.value,
                isArchived: false,
              });
              setInputChanged(true);
              setErrorState(undefined);
            }}
            id="set-account-input"
          />
        </FormField>
      )}
      {(!errorState ||
        errorState.error !== 'codeAlreadyInUse' ||
        inputChanged) && (
        <EmployeeAccountAdvice
          className={styles.formCallout}
          integrationStatus={integrationStatus}
          showError={!!errorState && !inputChanged}
          title={
            !!errorState && !inputChanged
              ? t(
                  'bookkeep.integrations.datev.accountCodeAdvice.defaultEmployeeAccount.title.warning',
                )
              : t(
                  'bookkeep.integrations.datev.accountCodeAdvice.defaultEmployeeAccount.title.info',
                )
          }
        />
      )}
      {errorState &&
        errorState.error === 'codeAlreadyInUse' &&
        errorState.codeInUseAccount &&
        !inputChanged && (
          <CodeInUseCallout
            className={styles.formCallout}
            accountInfo={`${t(
              `bookkeep.integrations.settings.supplierAccountsTable.${errorState.codeInUseAccount.kind}`,
            )} - ${errorState.codeInUseAccount.account.generalAccountCode}`}
            linkTo={getCodeInUseLinkForAccountsPayable(
              {
                error: 'codeAlreadyExists',
                existingAccount: {
                  kind: errorState.codeInUseAccount.kind,
                  ...errorState.codeInUseAccount.account,
                },
              },
              companyId,
            )}
          />
        )}
      <Button
        variant="primaryBrand"
        text={t('misc.saveChanges')}
        className={styles.saveMisc}
        isDisabled={!inputChanged && !!errorState}
        onClick={() => {
          if (newDefaultEmployeeAccount.isArchived) {
            onSetDefault(newDefaultEmployeeAccount);
            return;
          }

          setInputChanged(false);

          setErrorState(undefined);
          // don't do anything if the employee account hasn't changed
          if (
            defaultEmployeeAccount?.generalAccountCode ===
              newDefaultEmployeeAccount.generalAccountCode &&
            isInitialToggleChecked === isToggleChecked
          ) {
            return;
          }

          const result = validateAccountPayable(
            newDefaultEmployeeAccount.generalAccountCode,
            newDefaultEmployeeAccount.auxiliaryAccountCode,
            accountLength,
            getSupplierAccountsQueryState,
            getEmployeeAccountCodesQueryState,
            integrationStatus.integration,
            auxiliaryAccountsEnabled,
          );

          if (result.outcome === 'valid') {
            setErrorState(undefined);

            if (!defaultEmployeeAccount) {
              onSetDefault(newDefaultEmployeeAccount);
              return;
            }

            // if the input is empty, delete current account
            if (!newDefaultEmployeeAccount.generalAccountCode) {
              setModalState({ kind: 'confirmDelete' });
              return;
            }

            // don't do anything if the employee account hasn't change
            if (
              defaultEmployeeAccount.generalAccountCode !==
              newDefaultEmployeeAccount.generalAccountCode
            ) {
              setModalState({ kind: 'confirmEdit' });
            }
            return;
          }

          switch (result.reason) {
            case 'required':
            case 'invalidPattern': {
              setErrorState({
                error: result.reason,
              });
              return;
            }
            case 'codeAlreadyExists': {
              setErrorState({
                error: 'codeAlreadyInUse',
                codeInUseAccount:
                  result.existingAccount.kind === 'employeeAccount'
                    ? {
                        kind: 'employeeAccount',
                        account: {
                          isArchived: false,
                          ...result.existingAccount,
                        },
                      }
                    : {
                        kind: 'supplierAccount',
                        account: {
                          isArchived: false,
                          isDefault: false /* Not needed */,
                          ...result.existingAccount,
                        },
                      },
              });
              return;
            }
            default:
              rejectUnexpectedValue('defaultEmployeeAccountValidation', result);
          }
        }}
      />
      <EmployeeAccountDefaultEditConfirmationModal
        integration={integrationStatus.integration}
        isOpen={modalState.kind === 'confirmEdit'}
        setModalState={setModalState}
        onSetDefault={onSetDefault}
        defaultEmployeeAccount={defaultEmployeeAccount}
        newDefaultEmployeeAccount={newDefaultEmployeeAccount}
      />
      <EmployeeAccountDefaultDeleteConfirmationModal
        integration={integrationStatus.integration}
        isOpen={modalState.kind === 'confirmDelete'}
        setModalState={setModalState}
        onSetDefault={onSetDefault}
        defaultEmployeeAccount={defaultEmployeeAccount}
      />
    </div>
  );
};

function getInitialDefaultAccount(
  integrationStatus: IntegrationStatusWithIntegration,
  defaultTaxAccount: DefaultEmployeeAccount | undefined,
): DefaultEmployeeAccount {
  return (
    defaultTaxAccount ?? {
      name: getDefaultEmployeeAccountName(integrationStatus.accountingCountry),
      generalAccountCode: '',
      auxiliaryAccountCode: undefined,
      isArchived: false,
    }
  );
}
