import {
  Button,
  Callout,
  FormField,
  Modal,
  TextArea,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import { type FormikErrors, type FormikProps, getIn, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitStatus';

import {
  MAX_CHAR_PER_CUSTOM_FIELDS,
  MAX_VALUES_PER_CUSTOM_FIELD,
  validate,
} from './validate';
import { type CustomFieldCreationForm } from '../../containers/CustomFieldCreationModalContainer';
import { convertValues } from '../../utils/convertValues';
import { removeNullValues } from '../../utils/removeNullValues';
import { CustomFieldInfoStep } from '../CustomFieldModalInfo/CustomFieldModalInfo';

import './CustomFieldCreationModal.css';

type CreationSteps = 'info' | 'values';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(values: CustomFieldCreationForm): void;
};

export const CustomFieldCreationModal = ({
  isOpen,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation('global');
  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();

  const [step, setStep] = useState<CreationSteps>('info');

  const formikPropsInfo = useFormik<CustomFieldCreationForm>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      name: '',
      type: 'mandatory',
      isSplittable: isAnalyticalSplitActivated,
    },
    validate: (values) => {
      const errors: FormikErrors<CustomFieldCreationForm> = {};
      if (isEmpty(values.name)) {
        errors.name = t('customFields.creation.nameRequired');
      }
      return errors;
    },
    onSubmit: () => {
      setStep('values');
    },
  });

  const formikPropsValues = useFormik<Parameters<typeof validate>[0]>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      customFieldValues: undefined,
    },
    validate: (values: { customFieldValues?: string }) => {
      return validate(values);
    },
    onSubmit: async (values) => {
      await onSubmit({
        ...formikPropsInfo.values,
        values: values.customFieldValues,
      });
    },
  });

  useEffect(() => {
    // clear input manually as the modal is "always opened" due to the isOpen state
    formikPropsInfo.resetForm();
    formikPropsValues.resetForm();
    setStep('info');
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={t('customFields.creation.title')}
      iconName="circle-plus"
      actions={
        step === 'info'
          ? [
              <Button
                key="cancel-button"
                text={t('misc.cancel')}
                variant="secondaryNeutral"
                onClick={onClose}
              />,
              <Button
                key="continue-button"
                iconName="chevron-right"
                iconPosition="right"
                text={t('misc.continue')}
                onClick={() => formikPropsInfo.handleSubmit()}
              />,
            ]
          : [
              <Button
                text={t('misc.back')}
                key="back-button"
                iconName="chevron-left"
                iconPosition="left"
                variant="secondaryNeutral"
                onClick={() => setStep('info')}
              />,
              <Button
                key="submit-button"
                text={t('customFields.creation.submit')}
                isDisabled={formikPropsValues.isSubmitting}
                onClick={() => formikPropsValues.handleSubmit()}
              />,
            ]
      }
    >
      {step === 'info' ? (
        <>
          <CustomFieldInfoStep formikProps={formikPropsInfo} />
        </>
      ) : (
        <CustomFieldValuesStep formikProps={formikPropsValues} />
      )}
    </Modal>
  );
};

export const CustomFieldValuesStep = ({
  formikProps,
  existingValuesCount = 0,
}: {
  formikProps: FormikProps<{ customFieldValues?: string }>;
  existingValuesCount?: number;
}) => {
  const { t } = useTranslation('global');

  const customFieldValues = formikProps.values.customFieldValues ?? '';
  const customFieldValuesWithoutNulls = removeNullValues(customFieldValues);

  const maxValuesPerCustomField =
    MAX_VALUES_PER_CUSTOM_FIELD - existingValuesCount;
  const currentNumberOfValues =
    convertValues(formikProps.values.customFieldValues)?.length ?? 0;

  const valuesErrors: ReturnType<typeof validate> =
    getIn(formikProps, 'errors') ?? {};

  return (
    <div>
      <FormField
        className="CustomFieldCreationModal__values"
        label={t('customFields.creation.valuesLabel')}
      >
        <>
          <TextArea
            placeholder={t('customFields.creation.valuesPlaceholder')}
            value={customFieldValuesWithoutNulls}
            onChange={(e) =>
              formikProps.setFieldValue('customFieldValues', e.target.value)
            }
            rows={7}
            isInvalid={!isEmpty(valuesErrors)}
          />
          <span
            className={cx('CustomFieldCreationModal__values__counter body-s', {
              CustomFieldCreationModal__values__counter__tooMany:
                valuesErrors.oneCustomFieldValueRequired ||
                valuesErrors.tooManyCustomFieldValues,
            })}
          >
            {`${currentNumberOfValues} / ${maxValuesPerCustomField}`}
          </span>
        </>
      </FormField>
      <div className="CustomFieldCreationModal__values__callouts">
        {valuesErrors.tooManyCustomFieldValues && (
          <Callout
            className="CustomFieldCreationModal__values__callout"
            variant="alert"
            title={t('customFields.creation.tooManyValuesCallout', {
              max: maxValuesPerCustomField,
              current: currentNumberOfValues,
            })}
          />
        )}
        {valuesErrors.tooManyCharacters && (
          <Callout
            className="CustomFieldCreationModal__values__callout"
            variant="alert"
            title={t('customFields.creation.tooManyCharactersCallout', {
              max: MAX_CHAR_PER_CUSTOM_FIELDS,
            })}
          />
        )}
        {valuesErrors.oneCustomFieldValueRequired && (
          <Callout
            className="CustomFieldCreationModal__values__callout"
            variant="alert"
            title={t('customFields.creation.notEnoughValues')}
          />
        )}
      </div>
    </div>
  );
};
