import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

// TODO: move from redux to react-query
import {
  saveBankInfo,
  fetchWireTransferActivationStatus,
} from 'modules/company/billing-legacy/redux';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import type { AppDispatch } from 'src/core/modules/app/redux/store';
import {
  isAccountCodeValid,
  isRoutingNumberValid,
} from 'src/core/utils/bankInfoFormats';
import {
  getValidationErrors,
  isRequiredValid,
} from 'src/core/utils/validators';

import { WireTransferBankInfoForm } from '../WireTransferBankInfoForm';

export type BankInformations = {
  accountNumber: string;
  accountHolderName: string;
  routingNumber: string;
};

type Props = {
  confirm?: boolean;
  accountConfirmation?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  hasPendingFundingSource?: boolean;
};

const reshapeDataBeforeSending = (values: BankInformations) => ({
  account_holder_name: values.accountHolderName,
  account_number: values.accountNumber,
  routing_number: values.routingNumber,
});

/**
 * Temporary component used to gather the missing bank info from US customers migrating to Dwolla
 */
export const WireTransferBankInfoModal = ({
  confirm,
  accountConfirmation,
  isOpen,
  onClose,
  onSuccess,
  hasPendingFundingSource,
}: Props) => {
  const { t } = useTranslation('global');
  const dispatch = useDispatch<AppDispatch>();
  const [hasSaveBankInfoFailed, setHasSaveBankInfoFailed] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const saveBankInfoCallback = (error: Error | Object) => {
    if (error instanceof Error) {
      setHasSaveBankInfoFailed(true);
      setIsConfirmationModalOpen(false);
    } else {
      setHasSaveBankInfoFailed(false);
      setIsConfirmationModalOpen(false);
      dispatch(fetchWireTransferActivationStatus());
      if (onSuccess) {
        onSuccess();
      }
      onClose();
    }
  };
  const formikProps = useFormik({
    initialValues: {
      accountNumber: '',
      accountHolderName: '',
      routingNumber: '',
    },
    validate: (values) => {
      const errors: FormikErrors<BankInformations> = getValidationErrors(
        values,
        isRequiredValid,
        t('misc.requiredField'),
      );

      if (!isAccountCodeValid(values.accountNumber)) {
        errors.accountNumber = t('bankInfoForm.accountNumberInvalid');
      }

      if (!isRoutingNumberValid(values.routingNumber)) {
        errors.routingNumber = t('bankInfoForm.routingNumberInvalid');
      }
      setIsFormValid(
        !errors.accountHolderName &&
          !errors.accountNumber &&
          !errors.routingNumber,
      );
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await dispatch(
        saveBankInfo(reshapeDataBeforeSending(values), saveBankInfoCallback),
      );
      setSubmitting(false);
    },
  });

  const onConfirmSave = () => {
    formikProps.submitForm();
  };
  const onSave = () => {
    if (confirm) {
      setIsConfirmationModalOpen(true);
    } else {
      onConfirmSave();
    }
  };
  const onConfirmClose = () => {
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      {confirm && (
        <Modal
          actions={[
            <Button
              key="save"
              isDisabled={false}
              text={t('bankInfoMigrationModal.confirm')}
              variant="primaryBrand"
              type="submit"
              onClick={onConfirmSave}
            />,
          ]}
          onClose={onConfirmClose}
          iconName="building-bank"
          iconVariant="warning"
          title={t('bankInfoMigrationModal.confirmTitle')}
          isOpen={isConfirmationModalOpen}
        >
          <Callout
            title={t('bankInfoMigrationModal.confirmWarning')}
            variant="warning"
            className="mb-16"
          />
          <div>{t('bankInfoMigrationModal.confirmMessageLine1')}</div>
          <div className="mt-16">
            {t('bankInfoMigrationModal.confirmMessageLine2')}
          </div>
        </Modal>
      )}
      <Modal
        actions={[
          <Button
            key="cancel"
            onClick={onClose}
            text={t('misc.cancel')}
            variant="secondaryNeutral"
          />,
          <Button
            key="save"
            isDisabled={!isFormValid}
            text={t('bankInfoMigrationModal.cta')}
            variant="primaryBrand"
            type="submit"
            onClick={onSave}
          />,
        ]}
        iconName="building-bank"
        iconVariant="info"
        title={t('bankInfoMigrationModal.title')}
        isOpen={isOpen}
      >
        {hasPendingFundingSource && (
          <Callout
            title={t('bankInfoMigrationModal.pendingAchTitle')}
            variant="info"
            className="text-left"
          >
            {t('bankInfoMigrationModal.pendingAchDescription')}
          </Callout>
        )}
        {accountConfirmation && (
          <Callout
            title={t('bankInfoMigrationModal.explanationMessage')}
            variant="info"
            className="mb-16"
          />
        )}

        {hasSaveBankInfoFailed && (
          <Callout
            title={t('wiretransfer.modal.bankInfo.saveBankInfoFailed')}
            variant="alert"
            className="mb-16"
          />
        )}
        <WireTransferBankInfoForm {...formikProps} />
      </Modal>
    </>
  );
};
