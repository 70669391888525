import { FormField, OptionGroup } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { getDefaultSupplierAccountName } from 'modules/bookkeep';
import { useGetDefaultSupplierAccountQuery } from 'modules/bookkeep/settings/integrations/hooks/useGetDefaultSupplierAccountQuery';
import { useSetDefaultSupplierAccountMutation } from 'modules/bookkeep/settings/integrations/hooks/useSetDefaultSupplierAccountMutation';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import {
  useBulkImportSuppliers,
  type BulkImportSuppliersErrorResponse,
} from 'modules/suppliers/hooks/api/useBulkImportSuppliers';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { routeFor } from 'src/core/constants/routes';

import { BulkImportSupplierAccountsForm } from './components/BulkImportSupplierAccountsForm';
import { type FormValues } from './form';
import { useMarkHubTaskAsDoneMutation } from '../../../../components/MarkAsDoneButton/hooks/useMarkHubTaskAsDoneMutation';
import { useHubTaskByType } from '../../../hooks/useHubTaskByType';
import { routes } from '../../routes';
import { DefaultSupplierAccountsForm } from '../components/DefaultSupplierAccountsForm';

export const AccountingSupplierAccounts = () => {
  const history = useHistory();

  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const companyId = useCompanyId();

  const defaultSupplierAccountQueryState = useGetDefaultSupplierAccountQuery();
  const defaultSupplierAccount = unwrapQuery(defaultSupplierAccountQueryState);
  const defaultCardSupplierCode =
    defaultSupplierAccount?.supplierAccountForCard?.generalAccountCode ?? '';
  const defaultInvoiceSupplierCode =
    defaultSupplierAccount?.supplierAccountForInvoice?.generalAccountCode ?? '';

  const [bulkImportSuppliers] = useBulkImportSuppliers();
  const [setDefaultSupplierAccount] = useSetDefaultSupplierAccountMutation();

  const task = useHubTaskByType('account_payable_codes');
  const [markTaskAsDoneMutation] = useMarkHubTaskAsDoneMutation();

  const goToHub = () => {
    const setupHubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(setupHubPath);
  };

  const {
    errors,
    handleSubmit,
    setFieldError,
    setFieldValue,
    submitCount,
    values,
  } = useFormik<FormValues>({
    initialValues: {
      cardSupplierCode: defaultCardSupplierCode,
      invoiceSupplierCode: defaultInvoiceSupplierCode,
      useSeparateCodes: null,
      file: undefined,
    },
    enableReinitialize: true,
    validate: (newValues) => {
      const newErrors: FormikErrors<FormValues> = {};
      if (newValues.useSeparateCodes === null) {
        newErrors.useSeparateCodes = t('misc.requiredField');
      }
      if (newValues.useSeparateCodes && !newValues.file) {
        newErrors.file = t(
          'setupHub.supplierAccounts.import.uploadTemplateMissingFileError',
        );
      }
      return newErrors;
    },
    validateOnChange: true,
    onSubmit: async (newValues) => {
      if (newValues.useSeparateCodes) {
        if (!newValues.file) {
          return;
        }
        await uploadFile(newValues.file);
        return;
      }

      try {
        if (newValues.cardSupplierCode !== defaultCardSupplierCode) {
          await setDefaultSupplierAccount({
            generalAccountCode: newValues.cardSupplierCode,
            name: getDefaultSupplierAccountName('cardSupplier'),
            defaultFor: 'cardSupplier',
            isArchived: false,
          });
        }

        if (newValues.invoiceSupplierCode !== defaultInvoiceSupplierCode) {
          await setDefaultSupplierAccount({
            generalAccountCode: newValues.invoiceSupplierCode,
            name: getDefaultSupplierAccountName('invoiceSupplier'),
            defaultFor: 'invoiceSupplier',
            isArchived: false,
          });
        }

        if (task) {
          await markTaskAsDoneMutation({
            endpointParams: { taskId: task.id },
          });
        }

        goToHub();
      } catch {
        dangerNotif(
          t(
            'bookkeep.integrations.settings.supplierAccountsTable.errorMessage',
          ),
        );
      }
    },
  });

  const uploadFile = async (file: File) => {
    try {
      const fileReader = new Blob([file]);
      const csv = await fileReader.text();
      const bulkImportSuppliersResult = await bulkImportSuppliers({ csv });

      const failedLineIndexes: number[] = [];
      if (bulkImportSuppliersResult.outcome === 'partialSuccess') {
        const failedLines = bulkImportSuppliersResult.results.filter(
          (result) => result.outcome === 'error',
        );
        failedLineIndexes.push(...failedLines.map((_, index) => index + 2));
      }

      const nextPath = routeFor(routes.ACCOUNTING_SUPPLIER_ACCOUNTS_LIST.path, {
        company: companyId,
      });
      history.push(nextPath, { failedLineIndexes });
    } catch (error) {
      if (error.type !== 'RequestError') {
        dangerNotif(t('misc.errors.unknownError'));
        return;
      }

      const data = error.data as BulkImportSuppliersErrorResponse;
      switch (data.error.outcome) {
        case 'tooManySuppliers': {
          const message = t(
            'setupHub.supplierAccounts.import.tooManyLinesError',
            { max: data.error.maxNumSuppliers },
          );
          setFieldError('file', message);
          break;
        }
        case 'parseError':
        case 'validationError': {
          // Adding 2 to the row number because the first row is the header and index starts at 0
          const lines = data.error.lineErrors
            .map(({ row }) => row + 2)
            .join(', ');
          const message = t(
            'setupHub.supplierAccounts.import.invalidLinesError',
            { lines, count: lines.length },
          );
          setFieldError('file', message);
          break;
        }
        default:
          dangerNotif(t('misc.errors.unknownError'));
          break;
      }
    }
  };

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.supplierAccounts.description')}
        isLastStep={values.useSeparateCodes === false}
        onBack={goToHub}
        onNext={handleSubmit}
        title={t('setupHub.supplierAccounts.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('setupHub.supplierAccounts.helpTitle'),
        }}
      >
        <FormField
          className="mb-16"
          label={t('setupHub.supplierAccounts.useSeparateCodes.label')}
          description={t(
            'setupHub.supplierAccounts.useSeparateCodes.description',
          )}
          alertMessage={submitCount > 0 ? errors.useSeparateCodes : undefined}
        >
          <OptionGroup
            name="useSeparateCodes"
            onChange={(event) =>
              setFieldValue('useSeparateCodes', event.target.value === 'true')
            }
            options={[
              { value: true, label: t('misc.yes') },
              { value: false, label: t('misc.no') },
            ]}
            value={values.useSeparateCodes}
          />
        </FormField>
        {values.useSeparateCodes === true && (
          <div className="rounded-8 bg-primary-default px-24 py-16">
            <BulkImportSupplierAccountsForm
              error={submitCount > 0 ? errors.file : undefined}
              file={values.file}
              setFile={(file) => setFieldValue('file', file)}
            />
          </div>
        )}
        {values.useSeparateCodes === false && (
          <DefaultSupplierAccountsForm
            cardSupplierCode={values.cardSupplierCode}
            invoiceSupplierCode={values.invoiceSupplierCode}
            onCardSupplierCodeChange={(code: string) =>
              setFieldValue('cardSupplierCode', code)
            }
            onInvoiceSupplierCodeChange={(code: string) =>
              setFieldValue('invoiceSupplierCode', code)
            }
          />
        )}
      </TaskStepLayout>
    </div>
  );
};
