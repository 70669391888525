import { IconButton, Tooltip } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useModal } from 'common/hooks/useModalGrapes';
import { useTranslation } from 'common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { useNotifications } from '../../../app/notifications';
import {
  type ExportBudgetsInput,
  useExportBudgetsMutation,
} from '../../hooks/useExportBudgetsMutation';
import { ExportBudgetModal } from '../ExportBudgetModal';

type Props = {
  budgetIds: string[];
};

export const ExportBudgetButton = ({ budgetIds }: Props) => {
  const { t } = useTranslation('global');

  const { dangerNotif } = useNotifications();

  const [exportModal, showExportModal] = useModal(({ isOpen, onClose }) => (
    <ExportBudgetModal isOpen={isOpen} onClose={onClose} />
  ));

  const [isLaunchingExport, setIsLaunchingExport] = useState(false);
  const [exportBudgets] = useExportBudgetsMutation();
  const exportButtonHandler = async () => {
    setIsLaunchingExport(true);
    trackExportEvent();

    try {
      const payload: ExportBudgetsInput = { budgetIds };
      await exportBudgets(payload);
      showExportModal();
    } catch {
      dangerNotif(<p>{t('budget.exportBudget.notifFailure')}</p>);
    } finally {
      setIsLaunchingExport(false);
    }
  };

  const trackExportEvent = () => {
    const eventName =
      budgetIds.length > 1
        ? AnalyticEventName.BUDGETS_EXPORT_BUDGETS_EXPORT_ALL_BUDGETS_CLICKED
        : AnalyticEventName.BUDGETS_EXPORT_BUDGETS_EXPORT_SINGLE_BUDGET_CLICKED;

    track(eventName);
  };

  return budgetIds.length === 0 ? null : (
    <>
      <Tooltip
        content={t('budget.exportBudget.tooltipContent', {
          count: budgetIds.length,
        })}
      >
        <IconButton
          key="export"
          onClick={exportButtonHandler}
          isDisabled={isLaunchingExport}
          iconName="arrow-down-tray"
          variant="secondaryNeutral"
          aria-label={t('budget.exportBudget.tooltipContent', {
            count: budgetIds.length,
          })}
        />
      </Tooltip>
      {exportModal}
    </>
  );
};
