import { Button, Tooltip } from '@dev-spendesk/grapes';
import React, { useEffect, useRef, useState } from 'react';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import CONFIG from 'src/core/config';
import FEATURES from 'src/core/constants/features';
import {
  isSupportChatAvailable,
  openSupportChat,
} from 'src/core/utils/supportChat';

type Props = {
  buttonVariant?: 'tertiaryNeutral' | 'secondaryNeutral';
  hasNegativeMargins?: boolean;
  openFixIntercomCookiesModal?: () => void;
} & (
  | {
      text?: string;
      children?: never;
      component?: 'button' | 'a';
    }
  | {
      text?: never;
      component?: never;
      children: ({
        handleContactUs,
      }: {
        handleContactUs: (params?: { message?: string }) => void;
      }) => React.ReactElement;
    }
);

/**
 * @example
 * // default UI with default text
 * <ContactUs />
 * @example
 * // default UI with custom text
 * <ContactUs text="Contact us to deactivate the feature" />
 * @example
 * // custom everything
 * <ContactUs>
 *   {({ handleContactUs }) => (
 *     <p>
 *       If you have an issue, please{' '}
 *       <a
 *         href=""
 *         onClick={() =>
 *           handleContactUs({ message: 'Hello I have an issue' })
 *         }
 *       >
 *         contact us
 *       </a>
 *     </p>
 *   )}
 * </ContactUs>
 *
 */
export const ContactUs = ({
  text,
  component = 'button',
  children,
  buttonVariant = 'secondaryNeutral',
  hasNegativeMargins = false,
  openFixIntercomCookiesModal,
}: Props) => {
  const { t } = useTranslation('global');
  const hasLiveChatSupportFeature = useFeature(FEATURES.LIVE_CHAT_SUPPORT);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipTimeoutReference = useRef<NodeJS.Timeout | undefined>(undefined);

  const copyEmailAddress = () => {
    navigator.clipboard.writeText(CONFIG.supportEmail);
    setIsTooltipVisible(true);
    tooltipTimeoutReference.current = setTimeout(() => {
      setIsTooltipVisible(false);
    }, 3000);
  };

  useEffect(() => {
    return () => {
      if (tooltipTimeoutReference.current) {
        clearTimeout(tooltipTimeoutReference.current);
      }
    };
  }, []);

  if (hasLiveChatSupportFeature && isSupportChatAvailable()) {
    if (children) {
      return children({ handleContactUs: openSupportChat });
    }

    return renderDefaultContactUsButton({
      text: text ?? t('helpCenter.chatWithUs'),
      component,
      handleContactUs: openSupportChat,
      buttonVariant,
      hasNegativeMargins,
    });
  }

  return (
    <Tooltip
      content={t('helpCenter.emailCopiedToClipboard')}
      isOpen={isTooltipVisible}
    >
      {children
        ? children({
            handleContactUs:
              hasLiveChatSupportFeature && openFixIntercomCookiesModal
                ? openFixIntercomCookiesModal
                : copyEmailAddress,
          })
        : renderDefaultContactUsButton({
            text: text ?? t('helpCenter.contactUs'),
            component,
            handleContactUs:
              hasLiveChatSupportFeature && openFixIntercomCookiesModal
                ? openFixIntercomCookiesModal
                : copyEmailAddress,
            buttonVariant,
            hasNegativeMargins,
          })}
    </Tooltip>
  );
};

const renderDefaultContactUsButton = ({
  text,
  component,
  handleContactUs,
  buttonVariant,
  hasNegativeMargins,
}: {
  text: string;
  component: 'button' | 'a';
  handleContactUs: (params?: { message?: string }) => void;
  buttonVariant: 'tertiaryNeutral' | 'secondaryNeutral';
  hasNegativeMargins: boolean;
}) =>
  component === 'button' ? (
    <Button
      text={text}
      onClick={() => handleContactUs()}
      variant={buttonVariant}
      hasNegativeMargins={hasNegativeMargins}
    />
  ) : (
    <a
      href=""
      onClick={(event) => {
        event.preventDefault();
        handleContactUs();
      }}
    >
      {text}
    </a>
  );
