import { Icon, colors } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { type KybStatusCode } from 'modules/kyc/models/kybStatus';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './KycTimeline.module.css';
import { KybStatusContainer } from '../KybStatus';

type Props = {
  kybStatusCode: KybStatusCode;
};

export const KycTimeline = ({ kybStatusCode }: Props) => {
  const { t } = useTranslation('global');

  const isActionRequired = [
    'information-needed',
    'signature-requested',
  ].includes(kybStatusCode);

  return (
    <ErrorBoundary context={{ scope: 'kyc-timeline', team: 'kyc' }}>
      <div className="box p-0">
        <h2 className={cx('p-24 text-primary title-xl', styles.header)}>
          {t('homepage.kycStatusProgression.titles.inReview')}
        </h2>

        <div className="flex flex-col gap-24 p-24">
          <div className="flex flex-col gap-8">
            <div className="flex align-bottom">
              <p className="flex-1 text-left text-primary body-s">
                {t('homepage.kycStatusProgression.steps.submitted')}
              </p>
              <p className="flex-1 text-center text-primary body-s">
                {t('homepage.kycStatusProgression.steps.inReview')}
              </p>
              <p className="flex-1 text-right text-primary body-s">
                {t('homepage.kycStatusProgression.steps.completed')}
              </p>
            </div>
            <div className="flex items-center gap-4">
              <Icon
                name="circle-check"
                size="m"
                color={
                  isActionRequired
                    ? colors.contentWarningDefault
                    : colors.contentSuccessDefault
                }
              />
              <div
                className={cx(styles.line, {
                  [styles.lineWarning]: isActionRequired,
                })}
              />
              <Icon
                name={isActionRequired ? 'triangle-warning' : 'circle-check'}
                size="m"
                color={
                  isActionRequired
                    ? colors.contentWarningDefault
                    : colors.contentSuccessDefault
                }
              />
              <div className={cx(styles.line, styles.linePending)} />
              <Icon
                name="circle-check"
                size="m"
                color={colors.backgroundSecondaryDefault}
              />
            </div>
          </div>
          <KybStatusContainer />
        </div>
      </div>
    </ErrorBoundary>
  );
};
