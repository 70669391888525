import { Button, Modal, SkeletonText, Timeline } from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { SupplierChangeTimelineItem } from './SupplierChangeTimelineItem';
import { useFetchSupplierBankInfosHistory } from '../../hooks/api/useFetchSupplierBankInfosHistory';

export type Props = {
  isOpen: boolean;
  supplierId: string;
  onClose: () => void;
};

export const SupplierHistoryModal = ({
  isOpen,
  supplierId,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');

  const supplierBankInfosHistoryQueryState =
    useFetchSupplierBankInfosHistory(supplierId);

  const companyId = useCompanyId();

  return (
    <Modal
      isOpen={isOpen}
      title={t('suppliers.latestUpdater.historyModal.title')}
      subtitle={t('suppliers.latestUpdater.historyModal.subtitle')}
      iconName="clock-filled"
      iconVariant="purple"
      onClose={onClose}
      actions={[
        <Button
          key="download"
          component="a"
          onClick={() => {
            track(
              AnalyticEventName.SUPPLIER_BANK_INFOS_HISTORY_DOWNLOAD_BUTTON_CLICKED,
              {},
            );
          }}
          href={`${
            appConfig.apiUrls.api
          }/${companyId}/suppliers/${supplierId}/supplier-details-history-export`}
          target="_blank"
          variant="primaryBrand"
          iconName="arrow-down-tray"
          iconPosition="left"
          text={t('suppliers.latestUpdater.historyModal.downloadButton')}
        />,
      ]}
    >
      <QuerySuspense
        queryState={supplierBankInfosHistoryQueryState}
        fallback={(error) => (
          <QueryError
            queryError={error}
            componentType="Callout"
            logContext={{
              team: 'finance-operations',
            }}
          />
        )}
        loading={
          <>
            <SkeletonText size="l" />
            <SkeletonText size="l" />
            <SkeletonText size="l" />
          </>
        }
      >
        {(supplierDetailsChanges) => (
          <Timeline className="max-h-[340px] w-full overflow-auto">
            {supplierDetailsChanges.map((change) => (
              <SupplierChangeTimelineItem
                supplierChange={change}
                key={`${change.field}${change.updatedAt}`}
              />
            ))}
          </Timeline>
        )}
      </QuerySuspense>
    </Modal>
  );
};
