import { Button, EmptyState } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  className?: string;
  title?: string;
  subtitle?: string;
  actionText?: string;
  retry(): void;
};

export const EmptyStateError = ({
  className,
  title,
  subtitle,
  actionText,
  retry,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <EmptyState
      className={className}
      title={title ?? t('lazyLoading.error.page.title')}
      subtitle={subtitle ?? t('lazyLoading.error.page.subtitle')}
      iconName="triangle-warning"
      iconVariant="warning"
      actions={
        <Button
          text={actionText ?? t('lazyLoading.error.retry')}
          onClick={retry}
        />
      }
    />
  );
};
