import { Button, FormField, Modal, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type NewBranchEntityModalForm } from '../../form';

type Props = {
  onCancel: () => void;
  onClose: () => void;
} & Pick<
  FormikProps<NewBranchEntityModalForm>,
  'values' | 'handleChange' | 'isSubmitting' | 'handleSubmit'
>;

export const EntityNameStep = ({
  onCancel,
  onClose,
  values,
  handleSubmit,
  isSubmitting,
  handleChange,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      iconName="plus"
      iconVariant="purple"
      title={t('branch.entityNameStep.title')}
      subtitle={t('branch.entityNameStep.subtitle')}
      onClose={onClose}
      actions={[
        <Button
          key="cancel"
          onClick={onCancel}
          text={t('misc.back')}
          variant="secondaryNeutral"
        />,
        <Button
          key="confirm"
          type="submit"
          form="entity-name"
          isLoading={isSubmitting}
          isDisabled={!values.name}
          text={t('misc.continue')}
        />,
      ]}
    >
      <form id="entity-name" onSubmit={handleSubmit}>
        <FormField label={t('branch.entityNameStep.inputLabel')}>
          <TextInput
            fit="parent"
            name="name"
            onChange={handleChange}
            placeholder={t('branch.entityNameStep.inputPlaceholder')}
            value={values.name}
          />
        </FormField>
      </form>
    </Modal>
  );
};
