import { PageModal, Button } from '@dev-spendesk/grapes';
import { type MouseEventHandler, useMemo } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { type PartnerOnboardingError } from 'modules/kyc/hooks/usePartnerOnboardingErrorsQuery';
import { BASE_PATH, routes } from 'src/kyc/common/constants/routes';

type Props = {
  isOpen: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  partnerOnboardingErrors: PartnerOnboardingError[];
};

const sortByCode = (a: PartnerOnboardingError, b: PartnerOnboardingError) => {
  if (a.partnerErrorCode < b.partnerErrorCode) {
    return -1;
  }
  if (a.partnerErrorCode > b.partnerErrorCode) {
    return 1;
  }
  return 0;
};

export const MissingInformationModal = ({
  isOpen,
  onClose,
  partnerOnboardingErrors,
}: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();

  const companyErrors = useMemo(
    () =>
      partnerOnboardingErrors
        .filter(({ entityType }) => entityType === 'ORGANISATION')
        .sort(sortByCode),
    [partnerOnboardingErrors],
  );

  const getOnboardingErrorSection = (
    onboardingErrors: PartnerOnboardingError[],
    title: string,
  ) => {
    if (!onboardingErrors.length) {
      return null;
    }

    return (
      <div className="w-full rounded-8 bg-secondary-warning-default p-16">
        <h3 className="text-l">{title}</h3>
        <ul>
          {onboardingErrors.map((error) => (
            <li key={error.partnerErrorCode}>{error.errorDescription}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <PageModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('onboardingHub.kycTask.adyen.missingInformationList', {
        count: companyErrors.length,
        companyName: company.name,
      })}
    >
      <div className="mx-auto my-32 max-w-[822px]">
        <div className="mt-24 flex flex-col gap-16">
          {getOnboardingErrorSection(
            companyErrors,
            t('onboardingHub.kycTask.adyen.companyErrors'),
          )}

          <p className="mt-16">{t('onboardingHub.kycTask.adyen.updateNote')}</p>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 w-full border-[1px] border-x-0 border-b-0 border-solid border-default">
        <Button
          key="open-adyen"
          component="a"
          className="mx-auto my-16 flex w-fit"
          variant="primaryBrand"
          text={t('onboardingHub.kycTask.adyen.updateOnAdyen')}
          href={`${BASE_PATH}${routes.ADYEN_REDIRECT.path.replace(':companyId', company.id)}`}
          target="_blank"
        />
      </div>
    </PageModal>
  );
};
