import type { RequestAPI } from 'modules/requests/types';

import {
  canCancelPurchaseOrder,
  isPurchaseOrderRequestApprovable,
  type PurchaseOrderRequest,
} from './purchaseOrderRequest';

export type Request = PurchaseOrderRequest | RequestAPI;
export type RequestId = string;

export const isLegacyRequest = (request: Request): request is RequestAPI =>
  request.type !== 'purchase_order';

export const isPurchaseOrderRequest = (
  request: Request,
): request is PurchaseOrderRequest => request.type === 'purchase_order';

export const isRequestApprovable = (request: Request): boolean =>
  isLegacyRequest(request)
    ? request.state === 'pending' && request.can_approve
    : isPurchaseOrderRequestApprovable(request);

const isLegacyRequestValidated = (
  request: Pick<RequestAPI, 'state' | 'validation'>,
): boolean =>
  request.state === 'approved' &&
  request.validation?.validation_type === 'validation';

const canCancelLegacyRequest = (
  request: RequestAPI,
  user: { id: string; is_admin: boolean },
): boolean => {
  const { state, user: requester } = request;

  return (
    ['approved', 'pending', 'rejected_by_controller'].includes(state) &&
    !isLegacyRequestValidated(request) &&
    (user.id === requester?.id || user.is_admin)
  );
};

export const canCancelRequest = (
  request: Request,
  user: { id: string; is_admin: boolean },
): boolean =>
  ['refused', 'expired', 'cancelled', 'rejected_by_controller'].includes(
    request.state,
  ) ||
  (isLegacyRequest(request)
    ? canCancelLegacyRequest(request, user)
    : canCancelPurchaseOrder(request, user));

const isRequestOwner = (request: Request, userId: string): boolean => {
  if (isPurchaseOrderRequest(request)) {
    return request.requester.id === userId;
  }
  return request.user.id === userId;
};

const extractRequestApproverIds = (
  request: Pick<RequestAPI, 'approval_state'>,
) => {
  return request.approval_state?.rules?.flatMap((rule) => {
    return rule.steps.map((step) => step.actingApproverId);
  });
};

const isRequestApprover = (
  request: Pick<RequestAPI, 'approval_state'>,
  userId: string,
): boolean => {
  const approversIds = extractRequestApproverIds(request);
  return approversIds?.includes(userId);
};

export const canEditRequest = (request: Pick<Request, 'state'>): boolean =>
  ['draft', 'incomplete', 'pending'].includes(request.state);

export const canEditAnalyticalFields = (
  request: Request,
  user: { id: string; is_account_owner: boolean },
): boolean => {
  // request reviewed by the finance team cannot be edited anymore
  if (!isPurchaseOrderRequest(request) && request.validation) {
    return false;
  }

  // account owner can edit all requests before their validation
  if (user.is_account_owner) {
    return true;
  }

  // requester can edit its own request before it is approved
  const isUserRequestOwner = isRequestOwner(request, user.id);
  if (request.state === 'pending' && isUserRequestOwner) {
    return true;
  }

  // PO requests can be edited for approvers
  if (isPurchaseOrderRequest(request) && request.userPermission.canApprove) {
    return true;
  }

  if (!isPurchaseOrderRequest(request)) {
    // all requests can be edited for approvers
    if (request.can_approve) {
      return true;
    }

    // request can be edited by its approver
    if (request.state === 'approved' && isRequestApprover(request, user.id)) {
      return true;
    }
  }

  return false;
};

export const maxDescriptionLength = 255;

export const getRequestValidationDate = (
  request: Pick<RequestAPI, 'timeline'>,
): Date | undefined => {
  const validationDate = request.timeline?.find(
    (timelineEvent) =>
      timelineEvent.action_type === 'request.validate_by_controller',
  )?.occured_at;
  return validationDate ? new Date(validationDate) : undefined;
};

export const getManagerRejectionReason = (
  request: Pick<RequestAPI, 'appraisals'>,
): string | undefined => {
  return (
    request.appraisals.find(
      (appraisal) => appraisal.appraisal_type === 'denial',
    )?.reason ?? undefined
  );
};

export const getFinanceReviewRejectionReason = (
  request: Pick<RequestAPI, 'validation'>,
): string | undefined => {
  return request.validation?.reason ?? undefined;
};

export const isRequestInterrupted = (
  request: Pick<RequestAPI, 'state'>,
): boolean =>
  ['rejected_by_controller', 'refused', 'cancelled', 'expired'].includes(
    request.state,
  );
