import { Banner, Tag } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { ReactComponent as BannerIllustration } from './bannerIllustration.svg';

type Props = {
  className?: string;
};

export const ApprovalByDimensionsMarketingBanner = ({ className }: Props) => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const companyId = useCompanyId();

  return (
    <div>
      <Banner
        illustration={<BannerIllustration className="shrink-0" />}
        variant="brand"
        title=""
        actionText={t('costCenters.dimensionsBanner.actionText')}
        onClick={() => {
          history.push(
            routeFor(routes.APPROVAL_WORKFLOWS.path, {
              company: companyId,
            }),
          );
        }}
        className={classNames('text-left', className)}
      >
        <div>
          <div className="-mt-8 mb-8 flex items-center gap-8 text-primary title-xl">
            {t('costCenters.dimensionsBanner.title')}
            <Tag variant="purple">{t('misc.new')}</Tag>
          </div>
          {t('costCenters.dimensionsBanner.description')}
        </div>
      </Banner>
    </div>
  );
};
