import { type PropsWithChildren } from 'react';

import { BetaFeatureNotice } from 'src/core/common/components/BetaFeatureNotice';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useUser } from 'src/core/modules/app/hooks/useUser';

export const MultiEntityHubOverviewPageHeader = ({
  children,
}: PropsWithChildren) => {
  const user = useUser();
  const { t } = useTranslation('global');

  return (
    <div className="flex flex-col content-stretch gap-16">
      <div className="flex items-center gap-24">
        <h1 className="leading-tight text-primary heading-xl">
          {t('organisation.reporting.page.greeting', {
            firstName: user.first_name,
          })}
        </h1>
        <div className="ml-auto">
          <div className="flex flex-col items-end gap-8">
            <BetaFeatureNotice
              featureName={FEATURES.ORGANISATION_REPORTING_PAGE}
              feedbackTags={['organisation-reporting-page']}
              showOnlyFeedbackModal
              tag={<></>}
            />
            {children}
          </div>
        </div>
      </div>
      <p className="text-secondary-bg-primary body-l">
        {t('organisation.reporting.page.subgreeting')}
      </p>
    </div>
  );
};
