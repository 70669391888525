import { GridContainer, GridItem, PageModal } from '@dev-spendesk/grapes';
import React from 'react';

import { DangerConfirmationModal } from 'src/core/common/components/DangerConfirmationModal';
import { useModal } from 'src/core/common/hooks/useModal';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type CustomField } from '../../customField';
import { TemplateEditorFormContainer } from '../TemplateEditorForm/TemplateEditorFormContainer';

type Props = {
  isOpen: boolean;
  templateId?: string;
  customFields: CustomField[];
  onExit: () => void;
};

export const TemplateEditorFormModal = ({
  isOpen,
  templateId,
  customFields,
  onExit,
}: Props) => {
  const { t } = useTranslation('global');
  const [cancelConfirmModal, showCancelConfirmModal, hideCancelConfirmModal] =
    useModal(() => (
      <DangerConfirmationModal
        isOpen
        title={t('bookkeep.settings.export.leaveWithouSavingModalTitle')}
        description={t('bookkeep.settings.export.leaveWithouSavingModalDesc')}
        actionText={t('bookkeep.settings.export.leaveWithouSavingModalCta')}
        withRejectionMessage={false}
        onClose={() => hideCancelConfirmModal()}
        onConfirm={() => {
          hideCancelConfirmModal();
          onExit();
        }}
      />
    ));

  return (
    <PageModal
      isOpen={isOpen}
      onClose={showCancelConfirmModal}
      portalContainer={
        document.getElementById('react__template-editor-modal') || undefined
      }
      title=""
    >
      <GridContainer>
        <GridItem columnSpan={12}>
          <TemplateEditorFormContainer
            templateId={templateId}
            customFields={customFields}
            onSave={onExit}
            onCancel={showCancelConfirmModal}
          />
        </GridItem>
      </GridContainer>
      {cancelConfirmModal}
    </PageModal>
  );
};
