import { Button, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
};
export const CancelRequestsModal = ({ isOpen, onClose, onConfirm }: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onClose}
          text={t('misc.cancel')}
          variant="secondaryNeutral"
        />,
        <Button
          key="confirm"
          text={t('misc.yesDelete')}
          variant="primaryWarning"
          onClick={onConfirm}
        />,
      ]}
      iconName="triangle-warning"
      title={t('requests.actions.cancelTitleBulk')}
      iconVariant="warning"
      isOpen={isOpen}
    />
  );
};
