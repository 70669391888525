import { Panel, SkeletonText, SkeletonButton } from '@dev-spendesk/grapes';
import React from 'react';

import styles from '@finance-review/components/form.module.css';
import { SupplierOverviewFormFieldSkeleton } from 'src/core/modules/suppliers/components/SupplierOverviewFormField/SupplierOverviewFormFieldSkeleton';

const skeletonButtonCount = 5;

export const InvoiceReviewFormSkeleton = () => {
  return (
    <Panel
      className={styles.form}
      header={<SupplierOverviewFormFieldSkeleton />}
      footer={
        <div className="flex justify-between gap-16">
          <SkeletonButton className="flex-1" />
          <SkeletonButton className="flex-1" />
        </div>
      }
    >
      <div>
        {Array.from({ length: skeletonButtonCount }, (_, key) => (
          <div className="mb-16" key={key}>
            <SkeletonText width="96px" size="l" />
            <SkeletonButton width="100%" />
          </div>
        ))}
      </div>
    </Panel>
  );
};
