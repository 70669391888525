import { Button, FormField, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';

import {
  type BankAccountDetailsFile,
  type CreateSupplierFormValues,
  type SupplierAccount,
} from 'modules/bookkeep/accounts-payable/types';
import { SupplierAccountAdvice } from 'modules/bookkeep/components/AccountAdvice';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { CodeInUseCallout } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/components/CodeInUseCallout/CodeInUseCallout';
import { srcFromFile } from 'src/core/utils/files';

import { AccountPayableAutocompleteSearch } from '../../../components/AccountPayableAutocompleteSearch';
import { AccountPayableSuppliersFile } from '../../../components/AccountPayableSupplierFile';
import { AccountPayableSuppliersBankFields } from '../../../components/AccountPayableSuppliersBankFields';

import './AccountPayableSuppliersCreateSupplierForm.css';

type Props = {
  bankAccountDetailsFile: BankAccountDetailsFile;
  suppliers: Set<string>;
  accountPayables: SupplierAccount[];
  mustDisplayAccountsPayable: boolean;
  onAddOption(newOptionLabel: string): Promise<{
    key: string;
    label: string;
  }>;
  onCancel: () => void;
  setBankAccountDetailsFile: (
    bankAccountDetailsFile: BankAccountDetailsFile,
  ) => void;
  companyId: string;
  // TODO@integrations understand why we need DATEV-specific behaviour here
  isDatev: boolean;
  failedAccount: string | undefined;
  integrationStatus: IntegrationStatusWithIntegration;
} & FormikProps<CreateSupplierFormValues>;

export const AccountPayableSuppliersCreateSupplierForm = ({
  bankAccountDetailsFile,
  suppliers,
  accountPayables,
  mustDisplayAccountsPayable,
  onAddOption,
  onCancel,
  setBankAccountDetailsFile,
  companyId,
  isDatev,
  failedAccount,
  // Formik props
  errors,
  values,
  handleChange,
  setFieldError,
  setFieldValue,
  submitForm,
  integrationStatus,
}: Props) => {
  const { t } = useTranslation();

  const getFileUrl = () => {
    if (bankAccountDetailsFile !== null) {
      if (bankAccountDetailsFile.action === 'delete') {
        return null;
      }
      if (bankAccountDetailsFile.action === 'upload') {
        return bankAccountDetailsFile.fileUrl;
      }
    }
    return null;
  };

  const getFileMediaType = () => {
    if (bankAccountDetailsFile !== null) {
      if (bankAccountDetailsFile.action === 'delete') {
        return null;
      }
      if (bankAccountDetailsFile.action === 'upload') {
        return bankAccountDetailsFile.fileMediaType;
      }
    }
    return null;
  };

  const handleUploadBankAccountDetailsFile = (file: File) => {
    if (!file) {
      return;
    }
    setBankAccountDetailsFile({
      file,
      fileUrl: srcFromFile(file) ?? '',
      fileMediaType: file.type,
      action: 'upload',
    });
  };

  const handleDeleteBankAccountDetailsFile = () => {
    setBankAccountDetailsFile({
      action: 'delete',
    });
  };

  return (
    <form className="AccountPayableSuppliersCreateSupplierForm">
      <FormField
        className="AccountPayableSuppliersCreateSupplierForm__field"
        label={t('bookkeep.accountsPayable.createSupplier.nameLabel')}
        alertMessage={errors.name ? t(errors.name) : undefined}
      >
        <TextInput
          name="name"
          value={values.name}
          placeholder={t(
            'bookkeep.accountsPayable.createSupplier.namePlaceholder',
          )}
          onChange={(event) => {
            const { value } = event.target;
            const supplierName = value.trim().toLowerCase();
            const existingSupplierError = suppliers.has(supplierName)
              ? 'bookkeep.accountsPayable.createSupplier.nameExistsError'
              : '';
            setFieldError('name', existingSupplierError);
            handleChange(event);
            setFieldValue('accountHolderName', value);
          }}
        />
      </FormField>
      <div className="AccountPayableSuppliersCreateSupplierForm__fieldWrapper">
        <FormField
          className="AccountPayableSuppliersCreateSupplierForm__field"
          label={t('bookkeep.accountsPayable.createSupplier.legalNameLabel')}
          alertMessage={errors.legalName ? t(errors.legalName) : undefined}
        >
          <TextInput
            name="legalName"
            value={values.legalName}
            placeholder={t(
              'bookkeep.accountsPayable.createSupplier.legalNamePlaceholder',
            )}
            onChange={handleChange}
          />
        </FormField>

        {mustDisplayAccountsPayable && (
          <FormField
            className="AccountPayableSuppliersCreateSupplierForm__field"
            label={t(
              'bookkeep.accountsPayable.createSupplier.accountPayableLabel',
            )}
            infoTipContent={t(
              'bookkeep.accountsPayable.createSupplier.accountPayableInformationTooltip',
            )}
            hint={t('bookkeep.accountsPayable.panel.optionalField')}
          >
            <AccountPayableAutocompleteSearch
              accountPayables={accountPayables}
              error={errors.accountPayableId}
              onAddOption={onAddOption}
              onChange={(value) => setFieldValue('accountPayableId', value)}
              setError={(newError) =>
                setFieldError('accountPayableId', newError)
              }
              value={values.accountPayableId}
            />
          </FormField>
        )}
      </div>
      {errors.accountPayableId !== 'codeAlreadyExists' && (
        <SupplierAccountAdvice
          className="-mt-8"
          integrationStatus={integrationStatus}
          showError={errors.accountPayableId === 'integrationValidationFailed'}
        />
      )}
      {isDatev &&
        errors.accountPayableId &&
        errors.accountPayableId === 'codeAlreadyExists' && (
          <CodeInUseCallout
            accountInfo={`${t(
              'bookkeep.integrations.settings.supplierAccountsTable.employeeAccount',
            )} - ${failedAccount}`}
            className="-mt-8"
            linkTo={routeFor(routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path, {
              company: companyId,
            })}
          />
        )}
      <AccountPayableSuppliersBankFields
        errors={errors}
        values={values}
        setFieldValue={setFieldValue}
      />
      <AccountPayableSuppliersFile
        isEditing
        previewName={t('bookkeep.accountsPayable.panel.bankProofSection.title')}
        fileUrl={getFileUrl()}
        fileMediaType={getFileMediaType()}
        withLabel
        onUpload={(files: File[]) =>
          handleUploadBankAccountDetailsFile(files[0])
        }
        onDelete={handleDeleteBankAccountDetailsFile}
      />
      <div className="AccountPayableSuppliersCreateSupplierForm__actions">
        <Button
          variant="secondaryNeutral"
          text={t('bookkeep.accountsPayable.panel.cancel')}
          onClick={onCancel}
        />
        <Button
          variant="primaryBrand"
          text={t('bookkeep.accountsPayable.panel.saveChanges')}
          onClick={async () => {
            await submitForm();
          }}
        />
      </div>
    </form>
  );
};
