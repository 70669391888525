import { Button, Callout, Tag } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { TypeGuardError } from 'src/core/utils/switchGuard';

import { type IntegrationId, type Integration } from '../../../types';
import { integrationNameToI18nKey, isKomboIntegration } from '../../../utils';

type Props = {
  integration: Integration;
  sizeTitle?: string;
};

export const IntegrationBenefits = (props: Props) => {
  const { integration, sizeTitle } = props;
  const { t } = useTranslation('global');

  if (integration.id === 'travelperk') {
    return (
      <div className="flex w-full flex-col gap-16 text-left">
        <span
          className={classNames('flex text-primary', sizeTitle ?? 'title-l')}
        >
          {t('integration.travelperk.details.benefits.title')}
        </span>
        <div className="flex gap-8 text-14">
          <ul className="flex list-inside flex-col gap-8 pl-16">
            {[
              t('integration.travelperk.details.benefits.list.one'),
              t('integration.travelperk.details.benefits.list.two'),
              t('integration.travelperk.details.benefits.list.three'),
            ].map((line) => (
              <li key={line} className="list-disc">
                <span className="text-secondary-bg-primary">{line}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
  if (isKomboIntegration(integration.id)) {
    const integrationName = t(integrationNameToI18nKey[integration.id]);
    return (
      <div className="flex w-full flex-col gap-16 text-left">
        <Callout title={t('integration.kombo.settings.callout.label')}>
          <ul className="list-disc pb-8 pl-16 text-primary">
            <li>
              <Trans
                i18nKey="integration.kombo.integration.calloutList.add"
                components={{
                  strong: <strong className="font-bold">-</strong>,
                }}
              />
            </li>
            <li>
              <Trans
                i18nKey="integration.kombo.integration.calloutList.update"
                components={{
                  strong: <strong className="font-bold">-</strong>,
                }}
                values={{
                  name: integrationName,
                  info: (
                    [
                      'alexishr',
                      'breathehr',
                      'cezannehr',
                      'charliehr',
                      'deel',
                      'factorial',
                      'hibob',
                      'humaans',
                      'lucca',
                      'payfitpartner',
                      'personio',
                      'remotecom',
                      'successfactors',
                      'workday',
                    ] as IntegrationId[]
                  ).includes(integration.id)
                    ? t('integration.kombo.integration.bankAndName')
                    : t('integration.kombo.integration.justTheName'),
                }}
              />
            </li>
            <li>
              <Trans
                i18nKey="integration.kombo.integration.calloutList.remove"
                components={{
                  strong: <strong className="font-bold">-</strong>,
                }}
                values={{
                  name: integrationName,
                }}
              />
            </li>
          </ul>
          <Button
            name="control"
            text={t('integration.kombo.settings.callout.button.label')}
            variant="secondaryNeutral"
            component="a"
            target="_blank"
            href={t('integration.kombo.settings.callout.button.helpCenterUrl')}
          />
        </Callout>
        <span className="text-14">
          <Trans
            i18nKey="integration.kombo.integration.details.description"
            components={{
              strong: <strong className="font-bold">-</strong>,
            }}
            values={{
              name: integrationName,
            }}
          />
        </span>
        <div className="flex gap-8">
          <Tag iconName="circle-check" variant="neutral">
            {t('misc.active')}
          </Tag>
          <Tag iconName="hexagone-cross" variant="neutral">
            {t('misc.leave')}
          </Tag>
        </div>
      </div>
    );
  }

  if (integration.id === 'spendesk-oauth2') {
    return null;
  }

  throw new TypeGuardError(
    integration.id,
    'IntegrationBenefits: Unknown integrationId',
  );
};
