import { Tag } from '@dev-spendesk/grapes';
import { useEffect } from 'react';

import { LocalStorageKey } from 'src/core/constants/storage';

import { useTranslation } from '../../hooks/useTranslation';
import { ProductTour } from '../ProductTour';

export const EmptyTriggerProductTour = () => {
  const { t } = useTranslation('global');
  const key = LocalStorageKey.FilterBuilerEmptyTriggerProductTour;
  const hasSeen = localStorage.getItem(key);
  return (
    !hasSeen && (
      <ProductTour
        className="-mt-12"
        withActionButtons={false}
        steps={[
          {
            target: '#filter-builder-trigger',
            title: t('common.filterBuilder.filterPromotion.trigger.title'),
            content: t('common.filterBuilder.filterPromotion.trigger.content'),
            placement: 'bottom-start',
          },
        ]}
        variant="dark"
        onUnmountClose={() => {
          localStorage.setItem(key, 'true');
        }}
        onClose={() => {
          localStorage.setItem(key, 'true');
        }}
      />
    )
  );
};

export const ActiveTriggerProductTour = () => {
  const { t } = useTranslation('global');
  const key = LocalStorageKey.FilterBuilderActiveTriggerProductTour;
  const hasSeen = localStorage.getItem(key);
  return (
    !hasSeen && (
      <ProductTour
        className="-mt-12"
        withActionButtons={false}
        steps={[
          {
            target: '#filter-builder-trigger',
            title: t(
              'common.filterBuilder.filterPromotion.activeTrigger.step1.title',
            ),
            content: t(
              'common.filterBuilder.filterPromotion.activeTrigger.step1.content',
            ),
            placement: 'bottom-start',
          },
        ]}
        variant="dark"
        onUnmountClose={() => {
          localStorage.setItem(key, 'true');
        }}
        onClose={() => {
          localStorage.setItem(key, 'true');
          // Ensure all next steps are marked as seen
          localStorage.setItem(
            LocalStorageKey.FilterBuilderSaveTriggerProductTour,
            'true',
          );
          localStorage.setItem(
            LocalStorageKey.FilterBuilderFilterNameProductTour,
            'true',
          );
          localStorage.setItem(
            LocalStorageKey.FilterBuilderApplyTriggerProductTour,
            'true',
          );
          localStorage.setItem(
            LocalStorageKey.FilterBuilderDoneTriggerProductTour,
            'true',
          );
        }}
      />
    )
  );
};

const SaveTriggerTag = () => {
  const { t } = useTranslation('global');

  useEffect(
    () => () => {
      localStorage.setItem(LocalStorageKey.FilterBuilderSaveTriggerTag, 'true');
    },
    [],
  );

  return (
    <Tag variant="lemon">
      {t('common.filterBuilder.filterPromotion.saveTag')}
    </Tag>
  );
};

export const SaveTriggerProductTour = () => {
  const { t } = useTranslation('global');
  const key = LocalStorageKey.FilterBuilderSaveTriggerProductTour;
  const hasSeen = localStorage.getItem(key);

  const hasSeenTag = localStorage.getItem(
    LocalStorageKey.FilterBuilderSaveTriggerTag,
  );

  if (!hasSeenTag) {
    return <SaveTriggerTag />;
  }

  return (
    !hasSeen && (
      <ProductTour
        className="-mt-12"
        withActionButtons={false}
        steps={[
          {
            target: '#filter-builder-save-trigger',
            title: t(
              'common.filterBuilder.filterPromotion.activeTrigger.step2.title',
            ),
            content: t(
              'common.filterBuilder.filterPromotion.activeTrigger.step2.content',
            ),
            placement: 'bottom-start',
          },
        ]}
        variant="dark"
        onUnmountClose={() => {
          localStorage.setItem(key, 'true');
        }}
        onClose={() => {
          localStorage.setItem(key, 'true');
          // Ensure all next steps are marked as seen
          localStorage.setItem(
            LocalStorageKey.FilterBuilderFilterNameProductTour,
            'true',
          );
          localStorage.setItem(
            LocalStorageKey.FilterBuilderApplyTriggerProductTour,
            'true',
          );
          localStorage.setItem(
            LocalStorageKey.FilterBuilderDoneTriggerProductTour,
            'true',
          );
        }}
      />
    )
  );
};

export const FilterNameProductTour = () => {
  const { t } = useTranslation('global');
  const key = LocalStorageKey.FilterBuilderFilterNameProductTour;
  const hasSeen = localStorage.getItem(key);
  return (
    !hasSeen && (
      <ProductTour
        className="-mt-12"
        withActionButtons={false}
        steps={[
          {
            target: '#filter-builder-filter-name',
            title: t(
              'common.filterBuilder.filterPromotion.activeTrigger.step3.title',
            ),
            content: t(
              'common.filterBuilder.filterPromotion.activeTrigger.step3.content',
            ),
            placement: 'bottom-start',
          },
        ]}
        variant="dark"
        onUnmountClose={() => {
          localStorage.setItem(key, 'true');
        }}
        onClose={() => {
          localStorage.setItem(key, 'true');
          // Ensure all next steps are marked as seen
          localStorage.setItem(
            LocalStorageKey.FilterBuilderApplyTriggerProductTour,
            'true',
          );
          localStorage.setItem(
            LocalStorageKey.FilterBuilderDoneTriggerProductTour,
            'true',
          );
        }}
      />
    )
  );
};

export const ApplyTriggerProductTour = () => {
  const { t } = useTranslation('global');
  const key = LocalStorageKey.FilterBuilderApplyTriggerProductTour;
  const hasSeen = localStorage.getItem(key);
  return (
    !hasSeen && (
      <ProductTour
        className="-mt-12"
        withActionButtons={false}
        steps={[
          {
            target: '#filter-builder-apply-trigger',
            title: t(
              'common.filterBuilder.filterPromotion.activeTrigger.step4.title',
            ),
            content: t(
              'common.filterBuilder.filterPromotion.activeTrigger.step4.content',
            ),
            placement: 'bottom-start',
          },
        ]}
        variant="dark"
        onUnmountClose={() => {
          localStorage.setItem(key, 'true');
        }}
        onClose={() => {
          localStorage.setItem(key, 'true');
          // Ensure all next steps are marked as seen
          localStorage.setItem(
            LocalStorageKey.FilterBuilderDoneTriggerProductTour,
            'true',
          );
        }}
      />
    )
  );
};

export const DoneTriggerProductTour = () => {
  const { t } = useTranslation('global');
  const key = LocalStorageKey.FilterBuilderDoneTriggerProductTour;
  const hasSeen = localStorage.getItem(key);
  return (
    !hasSeen && (
      <ProductTour
        className="-mt-12"
        withActionButtons={false}
        steps={[
          {
            target: '#filter-builder-trigger',
            title: t(
              'common.filterBuilder.filterPromotion.activeTrigger.step5.title',
            ),
            content: t(
              'common.filterBuilder.filterPromotion.activeTrigger.step5.content',
            ),
            placement: 'bottom-start',
          },
        ]}
        variant="dark"
        onUnmountClose={() => {
          localStorage.setItem(key, 'true');
        }}
        onClose={() => {
          localStorage.setItem(key, 'true');
        }}
      />
    )
  );
};
