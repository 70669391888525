import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';

import {
  useControlRulesQuery,
  useInvitesQuery,
  useMembersQuery,
} from '../../../hooks';
import { useTeamsQuery } from '../../../hooks/useTeamsQuery';
import { type ControlRule } from '../../../models/controlRule';
import { type Invite } from '../../../models/invite';
import { type Member } from '../../../models/member';
import { type Team } from '../../../models/teams';

export const useMembersTableDataQuery = (): QueryState<{
  members: Member[];
  teams: Team[];
  invites: Invite[];
  controlRules: ControlRule[];
}> => {
  return useQueryStates({
    states: {
      members: useMembersQuery(),
      teams: useTeamsQuery(),
      invites: useInvitesQuery(),
      controlRules: useControlRulesQuery(),
    },
  });
};
