/* eslint-disable unicorn/consistent-function-scoping */
import { Button } from '@dev-spendesk/grapes';

import { useModal } from 'common/hooks/useModalGrapes';
import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';

import { CustomFieldEditValuesModalContainer } from '../../containers/CustomFieldEditValuesModalContainer';
import { CustomFieldLimitlessListContainer } from '../../containers/CustomFieldLimitlessListContainer';
import { type CustomField } from '../../models/customField';
import { type Team } from '../../models/team';
import { CustomFieldPanelHeader } from '../CustomFieldPanelHeader/CustomFieldPanelHeader';
import {
  CreateCustomFieldValueModal,
  DeleteCustomFieldValueModal,
  UpdateCustomFieldValueModal,
} from '../CustomFieldValueModals';
import { TeamsSelection } from '../fields/TeamsSelection';

import './CustomFieldValuesList.css';

type Props = {
  customField: CustomField;
  teams: Team[];
  onUpdateCustomFieldValue: ({
    value,
    fieldValueId,
  }: {
    value: string;
    fieldValueId: string;
  }) => void;
  onCreateCustomFieldValue: ({
    value,
    customFieldId,
  }: {
    value: string;
    customFieldId: string;
  }) => void;
  onDeleteCustomFieldValue: ({
    fieldValueId,
  }: {
    fieldValueId: string;
  }) => void;
};

export const CustomFieldValuesList = ({
  customField,
  teams,
  onUpdateCustomFieldValue,
  onCreateCustomFieldValue,
  onDeleteCustomFieldValue,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  const customFieldValues = customField.values.map(({ name }) => name);
  const [
    updateCustomFieldValueModal,
    showUpdateCustomFieldValueModal,
    hideUpdateCustomFieldValueModal,
  ] = useModal<{ customFieldValueId: string; customFieldValueName: string }>(
    ({ isOpen, customFieldValueId, customFieldValueName }) => {
      const handleUpdateCustomFieldValue = async (createdValue: {
        value: string;
        fieldValueId: string;
      }) => {
        try {
          await onUpdateCustomFieldValue(createdValue);
          pushNotif({
            type: NotificationType.Success,
            message: t('customFields.settings.modals.edition.success'),
          });
        } catch {
          pushNotif({
            type: NotificationType.Danger,
            message: t('customFields.settings.modals.edition.error'),
          });
        }
      };

      return (
        <UpdateCustomFieldValueModal
          isOpen={isOpen}
          customFieldValues={customFieldValues}
          value={customFieldValueName}
          onClose={() => hideUpdateCustomFieldValueModal()}
          onSubmit={(value: string) => {
            handleUpdateCustomFieldValue({
              value,
              fieldValueId: customFieldValueId,
            });
          }}
        />
      );
    },
  );

  const [createCustomFieldValueModal, , hideCreateCustomFieldValueModal] =
    useModal<{ customFieldValueId: string }>(({ isOpen }) => {
      const handleCreateCustomFieldValue = async (updatedValue: {
        value: string;
        customFieldId: string;
      }) => {
        try {
          await onCreateCustomFieldValue(updatedValue);
          pushNotif({
            type: NotificationType.Success,
            message: t('customFields.settings.modals.creation.success'),
          });
          hideCreateCustomFieldValueModal();
        } catch {
          pushNotif({
            type: NotificationType.Danger,
            message: t('customFields.settings.modals.creation.error'),
          });
        }
      };

      return (
        <CreateCustomFieldValueModal
          isOpen={isOpen}
          customFieldValues={customFieldValues}
          onClose={() => hideCreateCustomFieldValueModal()}
          onSubmit={(value: string) => {
            handleCreateCustomFieldValue({
              value,
              customFieldId: customField.id,
            });
          }}
        />
      );
    });

  const [
    deleteCustomFieldValueModal,
    showDeleteCustomFieldValueModal,
    hideDeleteCustomFieldValueModal,
  ] = useModal<{ customFieldValueId: string; customFieldValueName: string }>(
    ({ isOpen, customFieldValueId, customFieldValueName }) => {
      const handleDeleteCustomFieldValue = async (deletedValue: {
        fieldValueId: string;
      }) => {
        try {
          await onDeleteCustomFieldValue(deletedValue);
          pushNotif({
            type: NotificationType.Success,
            message: t('customFields.settings.modals.deletion.success'),
          });
          hideDeleteCustomFieldValueModal();
        } catch {
          pushNotif({
            type: NotificationType.Danger,
            message: t('customFields.settings.modals.deletion.error'),
          });
        }
      };

      return (
        <DeleteCustomFieldValueModal
          isOpen={isOpen}
          onClose={() => hideDeleteCustomFieldValueModal()}
          value={customFieldValueName}
          onSubmit={() => {
            handleDeleteCustomFieldValue({
              fieldValueId: customFieldValueId,
            });
          }}
        />
      );
    },
  );

  const [customFieldEditValuesModal, showCustomFieldEditValuesModal] = useModal(
    ({ onClose, isOpen }) => {
      return (
        <CustomFieldEditValuesModalContainer
          isOpen={isOpen}
          customField={customField}
          onClose={() => onClose()}
        />
      );
    },
  );

  return (
    <>
      <div className="mb-16 mt-40 rounded-8 border border-solid border-default bg-primary-default">
        <CustomFieldPanelHeader customField={customField}>
          <Button
            text={t('customFields.settings.headerButtonValues')}
            variant="primaryBrand"
            onClick={() => showCustomFieldEditValuesModal()}
          />
        </CustomFieldPanelHeader>
        <div className="separator" />
        <CustomFieldLimitlessListContainer
          customFieldId={customField.id}
          showDeleteCustomFieldValueModal={showDeleteCustomFieldValueModal}
          showUpdateCustomFieldValueModal={showUpdateCustomFieldValueModal}
        />
      </div>
      {customField.isScopable && (
        <TeamsSelection
          cfId={customField.id}
          groups={teams ?? []}
          isAllScopes={customField.isAllScopes}
        />
      )}
      {updateCustomFieldValueModal}
      {createCustomFieldValueModal}
      {deleteCustomFieldValueModal}
      {customFieldEditValuesModal}
    </>
  );
};
