import { useTranslation } from 'src/core/common/hooks/useTranslation';

import {
  SupplierAccountsLocalOnlySection,
  SupplierAccountsLocalOnlyWithDefaultAccountsSection,
} from './SupplierAccountsLocalOnlySection/SupplierAccountsLocalOnlySection';
import { SupplierAccountsPullWithDefaultAccountsSection } from './SupplierAccountsPullSection/SupplierAccountsPullWithDefaultAccountsSection';
import { getSupplierAccountsDescriptionI18nKey } from './translations';
import { rejectUnexpectedValue } from '../../../../../../../../utils/switchGuard';
import { GraphQLProvider } from '../../../../../../components/GraphQLProvider';
import {
  type IntegrationStatusWithIntegration,
  type SupplierAccountsCapability,
} from '../../../../../../integration/status';
import { getSupplierHelpCenterLink } from '../../utils';

interface Props {
  integrationStatus: IntegrationStatusWithIntegration;
  companyId: string;
  supplierAccountsCapability: SupplierAccountsCapability;
}

export const SupplierAccountsSection = (props: Props) => {
  const { integrationStatus } = props;
  const { t } = useTranslation();

  // TODO@integrations understand why we need DATEV-specific behaviour here
  return (
    <div id="supplier-accounts">
      <h3 className="IntegrationAccountingPage__section-title heading-l">
        {t('bookkeep.integrations.settings.sectionSupplierAccounts')}
      </h3>
      <p className="mb-16 mt-8 text-secondary-bg-primary body-m">
        {t(
          getSupplierAccountsDescriptionI18nKey(integrationStatus.integration),
        )}
        {integrationStatus.integration === 'Datev' && (
          <>
            &nbsp;|&nbsp;
            <a
              href={getSupplierHelpCenterLink(integrationStatus.integration)}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary underline"
            >
              {t('misc.helpCenterArticle')}
            </a>
          </>
        )}
      </p>
      <SupplierAccountsSectionContent {...props} hasDefaultPayableAccounts />
    </div>
  );
};

function SupplierAccountsSectionContent(
  props: Props & { hasDefaultPayableAccounts: boolean },
) {
  const { supplierAccountsCapability, hasDefaultPayableAccounts } = props;

  switch (supplierAccountsCapability) {
    case 'pullWithDefaultAccounts':
      if (hasDefaultPayableAccounts) {
        return <SupplierAccountsPullWithDefaultAccountsSection {...props} />;
      }
    // eslint-ignore no-fallthrough
    case 'localOnlyWithDefaultAccounts':
      if (hasDefaultPayableAccounts) {
        return (
          // TODO: remove GraphQLProvider
          <GraphQLProvider>
            <SupplierAccountsLocalOnlyWithDefaultAccountsSection {...props} />
          </GraphQLProvider>
        );
      }
    // eslint-ignore no-fallthrough
    case 'localOnly':
      return (
        <GraphQLProvider>
          <SupplierAccountsLocalOnlySection {...props} />
        </GraphQLProvider>
      );
    default:
      rejectUnexpectedValue(
        'supplierAccountsCapability',
        supplierAccountsCapability,
      );
  }
}
