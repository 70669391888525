import { Callout, Button } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  title: string;
  message: string;
  button: string;
  route: string;
  className?: string;
};

export const InvalidAccountCallout = ({
  title,
  message,
  button,
  route,
  className,
}: Props) => {
  const history = useHistory();

  return (
    <Callout
      className={classNames('mb-8', className)}
      title={title}
      variant="alert"
    >
      <div>{message}</div>
      <Button
        className="mt-16"
        text={button}
        variant="secondaryNeutral"
        onClick={() => history.push(route)}
      />
    </Callout>
  );
};
