import React from 'react';

import { ScheduledPaymentsBatchesLoader } from 'modules/reimbursements/components/ScheduledPaymentsBatchesList/ScheduledPaymentsBatchesLoader';

export const ExpenseClaimsConfirmPageSkeleton = () => {
  return (
    <div className="mt-48 flex justify-center">
      <ScheduledPaymentsBatchesLoader count={2} />
    </div>
  );
};
