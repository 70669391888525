import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { getFetcher } from 'src/core/api/fetcher';

import { SEARCH_TEAMS } from './queries';

type RawTeam = {
  id: string;
  name: string;
  archiveDate?: string | null;
};

type Team = {
  id: string;
  name: string;
};

export type SearchTeamsRawResponse = {
  company: { teams: { edges: { node: RawTeam }[] } };
};

export type SearchTeamsVariables = {
  search: string;
};

export const useSearchTeams = () => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();

  return async (search: string): Promise<Team[]> => {
    const data = await queryClient.fetchQuery(
      ['useSearchTeams', companyId, search],
      getFetcher<SearchTeamsRawResponse>({
        companyId,
        getRequest: () => ({
          type: 'graphQL',
          target: 'v2',
          query: SEARCH_TEAMS,
          variables: {
            search,
          },
          method: 'get',
        }),
      }),
      {
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10,
      },
    );

    return reshapeFilterValues(data.company.teams.edges);
  };
};

/**
 * Reshapers
 */

const reshapeFilterValues = (
  edges: SearchTeamsRawResponse['company']['teams']['edges'],
): Team[] => {
  return edges
    .map(({ node }) =>
      node.archiveDate
        ? null
        : {
            id: node.id,
            name: node.name,
          },
    )
    .filter((item): item is Team => item !== null);
};
