import { Tag } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { type ReactNode } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import type FEATURES from 'src/core/constants/features';

import { BetaFeatureFeedbackPopover } from './BetaFeatureFeedbackPopover';
import type { FeedbackModalVariant } from './BetaFeatureFeedbackPopover';
import { BetaFeatureFeedbackModal } from './BetaFeatureFeedbackPopover/BetaFeatureFeedbackModal';
import styles from './BetaFeatureNotice.module.css';
import { BetaFeaturePopover } from './BetaFeaturePopover';
import {
  useInitialiseBetaFeatureNotice,
  useBetaFeatureNoticeTracking,
} from './hooks';

/* eslint-disable @typescript-eslint/naming-convention */
/** @knipignore */
export enum EXTRA_FEATURES {
  INTEGRATIONS_KOMBO = 'integrations_kombo',
}
/* eslint-enable @typescript-eslint/naming-convention */

type Props = {
  feedbackTags: string[];
  featureName: FEATURES | EXTRA_FEATURES;
  disableSwitchToPreviousVersion?: boolean;
  noticePopoverTitle?: ReactNode;
  noticePopoverContent?: ReactNode;
  feedbackPopoverTitle?: ReactNode;
  feedbackPopoverContent?: ReactNode;
  label?: string;
  tag?: ReactNode;
  buttonText?: string;
  setMandatoryFeedback?: boolean;
  feedbackModalVariant?: FeedbackModalVariant;
  analyticsContext?: string;
  className?: string;
  showOnlyFeedbackModal?: boolean;
};

export const BetaFeatureNotice = ({
  featureName,
  feedbackTags,
  feedbackModalVariant,
  feedbackPopoverTitle,
  feedbackPopoverContent,
  disableSwitchToPreviousVersion = false,
  setMandatoryFeedback = false,
  showOnlyFeedbackModal = false,
  noticePopoverTitle,
  noticePopoverContent,
  label,
  tag,
  analyticsContext,
  className = '',
  ...feedbackPopoverProps
}: Props) => {
  const { t } = useTranslation('global');
  const betaFeatureNoticeTracking = useBetaFeatureNoticeTracking({
    featureName,
  });
  const {
    isBetaFeaturePopoverOpen,
    hideBetaFeaturePopover,
    isBetaFeatureFeedbackPopoverOpen,
    showBetaFeatureFeedbackPopover,
    hideBetaFeatureFeedbackPopover,
    onFeedbackSent,
    isFeedbackMandatory,
  } = useInitialiseBetaFeatureNotice({
    featureName,
    isMandatoryFeedbackSet: setMandatoryFeedback,
  });

  return (
    <div className={classNames('flex items-center gap-8', className)}>
      <div>
        {tag ?? <Tag variant="purple">{label ?? t('betaFeature.beta')}</Tag>}
        {isBetaFeaturePopoverOpen && !showOnlyFeedbackModal && (
          <BetaFeaturePopover
            isFeedbackMandatory={isFeedbackMandatory}
            title={noticePopoverTitle}
            content={noticePopoverContent}
            onClose={hideBetaFeaturePopover}
          />
        )}
      </div>
      <div>
        <button
          type="button"
          className={classNames(
            styles['BetaFeatureNotice__give-feedback'],
            'body-m',
          )}
          onClick={() => {
            betaFeatureNoticeTracking.onGiveFeedbackButtonClick();
            showBetaFeatureFeedbackPopover();
          }}
        >
          {t('betaFeature.giveFeedback')}
        </button>

        {isBetaFeatureFeedbackPopoverOpen && !showOnlyFeedbackModal && (
          <BetaFeatureFeedbackPopover
            {...feedbackPopoverProps}
            featureName={featureName}
            onClose={hideBetaFeatureFeedbackPopover}
            feedbackTags={feedbackTags}
            disableSwitchToPreviousVersion={disableSwitchToPreviousVersion}
            isFeedbackMandatory={isFeedbackMandatory}
            onFeedbackSent={onFeedbackSent}
            modalVariant={feedbackModalVariant}
            title={feedbackPopoverTitle}
            content={feedbackPopoverContent}
            analyticsContext={analyticsContext}
          />
        )}

        <BetaFeatureFeedbackModal
          isOpen={isBetaFeatureFeedbackPopoverOpen && showOnlyFeedbackModal}
          modalVariant={feedbackModalVariant}
          onCancel={() => hideBetaFeatureFeedbackPopover()}
          onConfirm={() => {
            betaFeatureNoticeTracking.onSubmitFeedbackButtonClicked();
            if (onFeedbackSent) {
              onFeedbackSent();
            }
            hideBetaFeatureFeedbackPopover();
          }}
          feedbackTags={feedbackTags}
          analyticsContext={analyticsContext}
        />
      </div>
    </div>
  );
};
