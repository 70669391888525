import {
  Avatar,
  Button,
  Callout,
  Icon,
  Modal,
  Select,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorState } from 'common/components/ErrorState';
import { fallbackSupplierLogoSrc as fallbackSupplierLogoSource } from 'common/components/SupplierLogo';
import withErrorBoundary from 'common/components/withErrorBoundary';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import logoSpendesk from '../../../assets/spendesk.svg';
import {
  type PublicApiScopeListEntry,
  getPublicApiScopeTranslation,
} from '../../../domain/public-api/scopes';
import { useGetPublicApiScopes } from '../../../hooks/useGetPublicApiScopes';
import { type ApiPartner } from '../domain/oauth2-token';
import { useAssociateOAuth2Token } from '../hooks';
import { useGetOAuth2ApiPartnerByTokenData } from '../hooks/getOAuth2ApiPartnerByTokenData';

import './ApiOAuth2AuthorizeModal.css';

const illustrationHeight = '120px';

// FIXME: this page should be under SCA

const ApiOAuth2AuthorizeModal = () => {
  const { t } = useTranslation('global');
  // ************* Global hooks ************
  const history = useHistory();
  const companyId = useCompanyId();
  const urlQueryParams = new URLSearchParams(
    new URL(window.location.href).search,
  );

  const oauth2Authorize = urlQueryParams.get('oauth2Authorize');
  const tokenId = urlQueryParams.get('id');
  const authCode = urlQueryParams.get('code');

  // ************* Server interaction hooks ************
  const apiPartnerResult = useGetOAuth2ApiPartnerByTokenData(tokenId, authCode);
  const [associateOAuth2Token, associateOAuth2TokenMutationState] =
    useAssociateOAuth2Token();
  const scopesQueryResult = useGetPublicApiScopes();

  // Handling redirect on associate success
  useEffect(() => {
    if (associateOAuth2TokenMutationState.status === 'success') {
      setTimeout(() => {
        // TODO make safe, maybe?
        window.location.href =
          associateOAuth2TokenMutationState.data.fullRedirectUrl;
      }, 750);
    }
  }, [associateOAuth2TokenMutationState.status]);

  // ************* Local state hooks ************
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    string | undefined
  >(undefined);
  const [isCompanySelectErrorDisplayed, setIsCompanySelectErrorDisplayed] =
    useState(false);

  // ************* Handling different view states ************
  // We're not doing the OAuth2 redirect: short-circuit
  if (!oauth2Authorize) {
    return <></>;
  }

  const onCancel = () => {
    history.push(
      routeFor(routes.COMPANY_INTEGRATIONS_ALL.path, { company: companyId }),
    );
  };

  if (
    apiPartnerResult.status === 'loading' ||
    scopesQueryResult.status === 'loading'
  ) {
    return (
      <Modal
        isOpen
        title=""
        illustration={<Illustration />}
        illustrationHeight={illustrationHeight}
        className="api-oauth-authorize-modal"
        actions={
          <div className="flex w-full flex-row">
            <Button
              onClick={() => {}}
              text={t('publicApi.flowOAuth2.partner.reject')}
              variant="secondaryNeutral"
              fit="parent"
              className="mr-24"
              disabled
            />
            <Button
              onClick={() => {}}
              text={t('publicApi.flowOAuth2.partner.authorize')}
              variant="secondaryNeutral"
              fit="parent"
              disabled
            />
          </div>
        }
      >
        <ModalContentSkeleton />
      </Modal>
    );
  }

  if (
    apiPartnerResult.status === 'error' ||
    !tokenId ||
    !authCode ||
    associateOAuth2TokenMutationState.status === 'error' ||
    scopesQueryResult.status === 'error'
  ) {
    return (
      <Modal
        isOpen
        title={t('misc.loadingError')}
        iconName="cross"
        iconVariant="alert"
        onClose={onCancel}
      >
        <ErrorState title={t('misc.errorState.title')} />
      </Modal>
    );
  }

  if (associateOAuth2TokenMutationState.status === 'success') {
    return (
      <Modal
        isOpen
        title={t('publicApi.flowOAuth2.partner.approved')}
        iconName="check"
        iconVariant="success"
      >
        <>
          {t('publicApi.flowOAuth2.partner.message')}:{' '}
          <b>{apiPartnerResult.data.name}</b>
        </>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen
      title={`${apiPartnerResult.data.name} ${t(
        'publicApi.flowOAuth2.partner.connect',
      )}`}
      onClose={onCancel}
      illustration={<Illustration partnerLogo={apiPartnerResult.data.logo} />}
      illustrationHeight={illustrationHeight}
      className="api-oauth-authorize-modal"
      actions={
        <div className="flex w-full flex-row">
          <Button
            onClick={onCancel}
            text={t('publicApi.flowOAuth2.partner.reject')}
            variant="primaryBrand"
            fit="parent"
            className="mr-24"
          />
          <Button
            onClick={() => {
              if (selectedCompanyId) {
                setIsCompanySelectErrorDisplayed(false);
                associateOAuth2Token({
                  tokenId,
                  companyId: selectedCompanyId,
                  authCode,
                });
              } else {
                setIsCompanySelectErrorDisplayed(true);
              }
            }}
            text={t('publicApi.flowOAuth2.partner.authorize')}
            variant="primaryBrand"
            fit="parent"
          />
        </div>
      }
    >
      <ModalContent
        partner={apiPartnerResult.data}
        selectedCompanyId={selectedCompanyId}
        scopeEntries={scopesQueryResult.data}
        onSelectCompanyId={setSelectedCompanyId}
        isCompanySelectErrorDisplayed={isCompanySelectErrorDisplayed}
      />
    </Modal>
  );
};

const Illustration = ({ partnerLogo }: { partnerLogo?: string }) => (
  <div className="flex h-full flex-row items-center">
    <Avatar
      src={partnerLogo}
      fallbackSrc={fallbackSupplierLogoSource}
      size="xl"
      text="Spendesk"
      variant="square"
      className="mr-24"
    />
    <Icon name="arrow-left-right" size="l" className="mr-24" />
    <Avatar src={logoSpendesk} size="xl" text="Spendesk" variant="square" />
  </div>
);

type ModalContentProps = {
  partner: ApiPartner;
  selectedCompanyId: string | undefined;
  scopeEntries: PublicApiScopeListEntry[];
  onSelectCompanyId: (newCompanyId: string) => void;
  isCompanySelectErrorDisplayed: boolean;
};

const ModalContent = ({
  partner,
  selectedCompanyId,
  scopeEntries,
  onSelectCompanyId,
  isCompanySelectErrorDisplayed,
}: ModalContentProps) => {
  const { t } = useTranslation('global');

  const user = useUser();

  const companies = Object.values(user.data_by_company);
  const options = companies.map(({ id: key, name: label }) => ({
    key,
    label,
  }));

  const getScopeTitle = getPublicApiScopeTranslation({
    t,
    scopeEntries,
    type: 'title',
  });

  const scopesWithTitles = partner.scope.split(' ').map((scope) => ({
    id: scope,
    title: getScopeTitle(scope),
  }));

  return (
    <div className="text-left text-primary">
      <p className="mb-8">
        {t('publicApi.flowOAuth2.partner.select')}
        <b> {partner.name}</b>:
      </p>
      <Select
        value={options.find(({ key }) => key === selectedCompanyId)}
        onSelect={({ key }) => onSelectCompanyId(key)}
        options={options}
        fit="parent"
        className="mb-16"
      />
      {isCompanySelectErrorDisplayed && (
        <Callout
          variant="alert"
          title={t('publicApi.flowOAuth2.partner.callout')}
          className="mb-16"
        />
      )}
      <p className="mb-8">{t('publicApi.flowOAuth2.partner.allow')}</p>
      <ul className="pl-16">
        {scopesWithTitles.map(({ id, title }) => (
          <li key={id}>{title}</li>
        ))}
      </ul>
    </div>
  );
};

const ModalContentSkeleton = () => {
  return (
    <>
      <p className="text-left">
        <SkeletonText width="190px" />
      </p>
      <p className="my-16">
        <SkeletonText size="xxl" />
      </p>
      <p className="mb-8 text-left">
        <SkeletonText width="130px" />
      </p>
      <ul className="pl-16 text-left">
        <li>
          <SkeletonText width="150px" />
        </li>
        <li>
          <SkeletonText width="250px" />
        </li>
        <li>
          <SkeletonText width="180px" />
        </li>
        <li>
          <SkeletonText width="160px" />
        </li>
      </ul>
    </>
  );
};

export default withErrorBoundary({
  team: 'api-integration',
  scope: 'api-oauth-authorize',
})((props) => <ApiOAuth2AuthorizeModal {...props} />);
