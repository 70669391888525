import { useTranslation } from 'src/core/common/hooks/useTranslation';

export const UploadReceiptSlideTwo = () => {
  const { t } = useTranslation('global');
  return (
    <div>
      <p className="mb-24">
        {t('readyToSpend.slideshows.uploadReceipt.two.content')}
      </p>
      <ul className="flex list-inside list-decimal flex-col gap-16">
        <li className="title-m">
          {t('readyToSpend.slideshows.uploadReceipt.two.items.one')}
        </li>
        <li className="title-m">
          {t('readyToSpend.slideshows.uploadReceipt.two.items.two')}
        </li>
        <li className="title-m">
          {t('readyToSpend.slideshows.uploadReceipt.two.items.three')}
        </li>
        <li className="title-m">
          {t('readyToSpend.slideshows.uploadReceipt.two.items.four')}
        </li>
        <li className="title-m">
          {t('readyToSpend.slideshows.uploadReceipt.two.items.five')}
        </li>
      </ul>
    </div>
  );
};
