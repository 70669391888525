import { Button, Modal } from '@dev-spendesk/grapes';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type AppState } from 'src/core/reducers';

import { RuleMembers } from '../../components/RuleMembers';
import { type ControlRule, type ControlRuleRequest } from '../../controlRule';
import { type Member } from '../../member';
import * as actions from '../../redux/actions';
import { getMembers } from '../../redux/membersSelector';
import * as thunks from '../../redux/thunks';

type Props = {
  controlRule: ControlRule;
  members: Member[];
  fetchControlRules(): Promise<void>;
  updateControlRule(controlRule: Partial<ControlRuleRequest>): Promise<void>;
  resetSelectedControlRule(): void;
  onClose(): void;
};

const Container = ({
  controlRule,
  members,
  fetchControlRules,
  updateControlRule,
  resetSelectedControlRule,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedMemberIds, setSelectedMemberIds] = useState(
    controlRule.userIds,
  );
  const isEmptyInitialRule =
    controlRule.isInitial && controlRule.userIds.length === 0;

  useEffect(() => {
    setSelectedMemberIds(controlRule.userIds);
  }, [controlRule.id]);

  const handleUpdateMembers = async () => {
    await updateControlRule({ userIds: selectedMemberIds });
    await fetchControlRules();
    pushNotif({
      type: NotificationType.Success,
      message: t('controlRulesModal.editSuccess'),
    });
    resetSelectedControlRule();
  };

  return (
    <Modal
      title={t('controlRules.editControlRule', { name: controlRule.name })}
      subtitle={t('controlRules.memberDescription')}
      iconName="card-lock-close"
      iconVariant="info"
      isOpen
      actions={[
        <Button
          key="back"
          variant="secondaryNeutral"
          iconName="chevron-left"
          iconPosition="left"
          text={t('controlRulesModal.back')}
          onClick={onClose}
        />,
        <Button
          key="submit"
          variant="primaryBrand"
          text={t('controlRulesModal.members.submit')}
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={async () => {
            setIsSubmitting(true);
            try {
              await handleUpdateMembers();
              onClose();
            } catch {
              pushNotif({
                message: t('controlRulesModal.members.submitError'),
                type: NotificationType.Danger,
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />,
      ]}
    >
      <RuleMembers
        selectedMemberIds={selectedMemberIds}
        members={members}
        warningContent={
          selectedMemberIds.length > 0
            ? t(
                isEmptyInitialRule
                  ? 'controlRulesModal.warningCreation'
                  : 'controlRulesModal.warningEdition',
                { count: selectedMemberIds.length },
              )
            : undefined
        }
        onChange={setSelectedMemberIds}
      />
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  members: getMembers(state),
});

const mapDispatchToProps = {
  fetchControlRules: thunks.fetchControlRules,
  updateControlRule: thunks.updateControlRule,
  resetSelectedControlRule: actions.resetSelectedControlRule,
};

export const EditControlRuleMembersContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
