import { ListItem, ListView, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import { ErrorState } from 'src/core/common/components/ErrorState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { NotificationSettings } from './NotificationSettings';
import { useGetNotificationsQuery } from './hooks/useGetNotificationsQuery';

const TABLE_LOADER_ROWS = 4;

export const NotificationSettingsContainer = () => {
  const { t } = useTranslation('global');
  const notificationsQuery = useGetNotificationsQuery();

  if (notificationsQuery.status === 'error') {
    return (
      <ErrorState
        className="ml-48 justify-start"
        title={t('generalSettings.notifications.loadErrorTitle')}
        description={t('generalSettings.notifications.loadErrorDescription')}
      />
    );
  }

  return (
    <section aria-labelledby="notificationSettings">
      <h1 id="notificationSettings" className="text-primary heading-l">
        {t('generalSettings.notifications.title')}
      </h1>
      <p className="mt-8 text-secondary-bg-primary body-m">
        {t('generalSettings.notifications.description')}
      </p>
      {notificationsQuery.status === 'loading' && (
        <ListView className="mt-24">
          {Array.from({ length: TABLE_LOADER_ROWS }, (_, row) => (
            <ListItem
              key={row}
              className="column flex w-full flex-col gap-8 py-16"
            >
              <SkeletonText width="20%" size="l" />
              <SkeletonText width="70%" size="s" />
            </ListItem>
          ))}
        </ListView>
      )}
      {notificationsQuery.status === 'success' && (
        <NotificationSettings notifications={notificationsQuery.data} />
      )}
    </section>
  );
};
