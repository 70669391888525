import { Skeleton, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import styles from './IntegrationAccountingPageSkeleton.module.css';

export function IntegrationAccountingPageSkeleton() {
  return (
    <div className={styles.page}>
      <div className={styles.section}>
        <div className={styles.sectionDescriptionLine} />
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <Skeleton
              className="mr-16"
              width="var(--unit-32)"
              height="var(--unit-32)"
            />
            <Skeleton className="mr-8" width="150px" height="27px" />
            <Skeleton width="51px" height="var(--unit-24)" />
          </div>
          <SkeletonText size="xl" className="mb-4" />
          <SkeletonText size="xl" className="mb-24" />
          <div className={styles.actions}>
            <SkeletonText width="175px" />
          </div>
        </div>
      </div>
    </div>
  );
}
