/* eslint-disable @typescript-eslint/naming-convention */

import { Button, type DateRange } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { format } from 'date-fns';
import React from 'react';

import { SearchFilter } from 'common/components/SearchFilter';
import { useTranslation } from 'common/hooks/useTranslation';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { PayableFilterBuilder } from 'src/core/modules/payable/components';

import { CostCenterFilter } from './CostCenterFilter';
import { PayableStateFilter } from './PayableStateFilter';
import { PayableTypeFilter } from './PayableTypeFilter';
import { ReceiptFilter } from './ReceiptFilter';
import { SettlementStateFilter } from './SettlementStateFilter';
import { PeriodFilter } from '../../../components/PeriodFilter';
import {
  type FiltersState,
  type FilterActions,
} from '../../containers/PayablesFiltersContainer/hooks/useFiltersContext';

type Props = {
  state: FiltersState;
  actions: FilterActions;
};

export const PayablesFilters = ({ state, actions }: Props) => {
  const { t } = useTranslation('global');
  const hasCostCentersFeature = useFeature(FEATURES.COST_CENTERS_ACTIVATED);
  const hasPayableFilterFeature = useFeature(FEATURES.TMP_PAYABLE_FILTERS);

  return (
    <div className="relative z-[9] my-24 flex items-center gap-8 bg-primary-default">
      <SearchFilter
        search={state.search ?? ''}
        setSearch={actions.setSearch}
        placeholder={t('payables.filters.searchPlaceholder')}
      />

      {hasPayableFilterFeature ? (
        <PayableFilterBuilder variant="all-payables" />
      ) : (
        <>
          <div>
            <ReceiptFilter
              receiptStatus={state.documentaryEvidenceStatus}
              setReceiptStatus={actions.setReceiptStatus}
            />
          </div>
          <div>
            <PeriodFilter
              period={[
                state.creationDate?.from
                  ? new Date(state.creationDate.from)
                  : undefined,
                state.creationDate?.to
                  ? new Date(state.creationDate?.to)
                  : undefined,
              ]}
              setPeriod={(newPeriod: DateRange) => {
                const range = {
                  from: newPeriod[0] ? format(newPeriod[0], 'yyyy-MM-dd') : '',
                  to: newPeriod[1] ? format(newPeriod[1], 'yyyy-MM-dd') : '',
                };
                actions.setCreationDate(range);
              }}
              onReset={() => actions.setCreationDate(undefined)}
              renderTrigger={(triggerProps, isOpen) => (
                <Button
                  {...triggerProps}
                  className={classNames({
                    'bg-secondary-default': isOpen,
                    'font-semibold text-brand-default': state.creationDate,
                    'font-medium': !state.creationDate,
                  })}
                  text={t('payables.filters.period')}
                  variant="secondaryNeutral"
                  isDropdown={false}
                />
              )}
            />
          </div>
          <div>
            <PayableTypeFilter
              selectedPayableTypes={state.payableTypes}
              setPayableTypes={actions.setPayableTypes}
            />
          </div>
          <div>
            <SettlementStateFilter
              settlementState={state.settlementState}
              setSettlementState={actions.setSettlementState}
            />
          </div>
          <div>
            <PayableStateFilter
              selectedPayableStates={state.payableStates}
              setPayableStates={actions.setPayableStates}
            />
          </div>
          {hasCostCentersFeature && (
            <div>
              <CostCenterFilter
                costCenterId={state.costCenter}
                setCostcenter={actions.setCostCenter}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
