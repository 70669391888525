import { IconButton, Table } from '@dev-spendesk/grapes';
import { type Dispatch, type SetStateAction } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { type IntegrationReverseChargeAccount } from 'modules/bookkeep/settings/accounting/taxAccount';

import { type ModalState } from './ChiftReverseChargeSetSection';
import { TableFooter } from './TableFooter';
import { CellWithButton } from '../../../../components/CellWithIconButton/CellWithIconButton';

interface Props {
  setModalState: Dispatch<SetStateAction<ModalState>>;
  reverseChargeAccounts: IntegrationReverseChargeAccount[];
}

export const PullAndSetReverseChargeTable = ({
  setModalState,
  reverseChargeAccounts,
}: Props) => {
  const { t } = useTranslation('global');
  // TODO: Update when working on settings validation

  return (
    <Table
      rowHeight="compact"
      data={reverseChargeAccounts}
      columns={[
        {
          id: 'reverse-charge-account-name',
          header: t(
            'bookkeep.integrations.settings.reverseChargeAccountsTable.accountName',
          ),
          width: '33%',
          renderCell(row) {
            return row.name;
          },
        },
        {
          id: 'reverse-charge-debit-code',
          header: t(
            'bookkeep.integrations.settings.reverseChargeAccountsTable.debitCode',
          ),
          width: '33%',
          renderCell(row) {
            return row.codeDebit;
          },
        },
        {
          id: 'reverse-charge-account-bu-code',
          header: t(
            'bookkeep.integrations.settings.reverseChargeAccountsTable.creditCode',
          ),
          width: '33%',
          renderCell(row, { isRowHovered }) {
            return (
              <CellWithButton
                isButtonVisible={isRowHovered}
                button={
                  <span className="CellWithButton__button">
                    <IconButton
                      iconName="trash"
                      variant="tertiaryNeutral"
                      onClick={() =>
                        setModalState({
                          kind: 'confirmDeleteReverseCharge',
                          id: row.id,
                          name: row.name,
                          codeDebit: row.codeDebit,
                          codeCredit: row.codeCredit,
                        })
                      }
                      aria-label={t('misc.delete')}
                    />
                  </span>
                }
              >
                {row.codeCredit}
              </CellWithButton>
            );
          },
        },
      ]}
      footer={reverseChargeAccounts.length === 0 ? <TableFooter /> : undefined}
      getRowId={(row) => row.id}
    />
  );
};
