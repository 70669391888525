import { Skeleton, Tag } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { MultiEntityHubOverviewPage } from '../components/overview-page/MultiEntityHubOverviewPage';
import { MultiEntityHubOverviewPageHeader } from '../components/overview-page/MultiEntityHubOverviewPageHeader';

export const MultiEntityHubOverviewPageContainer = () => {
  const { t } = useTranslation('global');

  const [updatedAt, setUpdatedAt] = useState<Date | null>(null);

  return (
    <div className="page__container bg-background-primary grow p-40">
      <div className="flex flex-col gap-24">
        <MultiEntityHubOverviewPageHeader>
          <div className="text-primary body-l">
            <div className="flex items-center gap-8">
              {updatedAt ? (
                <Tag
                  iconName="clock-filled"
                  className="flex align-middle"
                  variant="carbon"
                >
                  {t('organisation.reporting.page.updatedAt', {
                    date: updatedAt,
                  })}
                </Tag>
              ) : (
                <Skeleton width="260px" height="24px" />
              )}
            </div>
          </div>
        </MultiEntityHubOverviewPageHeader>

        <MultiEntityHubOverviewPage
          onDataLoaded={() => setUpdatedAt(new Date())}
        />
      </div>
    </div>
  );
};
