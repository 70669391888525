import { Icon } from '@dev-spendesk/grapes';
import { Link } from 'react-router-dom';

type OptionProps = {
  isSelected: boolean;
  onOptionClick: () => void;
  label: string;
  url: string;
  category: string;
  isExternal?: boolean;
};

export function Option({
  url,
  label,
  isSelected,
  onOptionClick,
  isExternal,
  category,
}: OptionProps) {
  const linkLabel = (category ? `${category} - ` : '') + label;
  if (isExternal) {
    return (
      <a href={url} className="search-result" onClick={onOptionClick}>
        <li role="option" aria-selected={isSelected}>
          {linkLabel}
          <Icon name="arrow-top-right-square" size="m" />
        </li>
      </a>
    );
  }
  return (
    <Link to={url} className="search-result" onClick={onOptionClick}>
      <li role="option" aria-selected={isSelected}>
        {linkLabel}
        <Icon name="chevron-right" size="m" />
      </li>
    </Link>
  );
}
