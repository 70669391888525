import { Button, Callout } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { AllocationPeriodFieldDate } from './AllocationPeriodFieldDate';
import { AllocationPeriodFieldPeriod } from './AllocationPeriodFieldPeriod';
import { validate } from './validate';
import type { AllocationPeriod } from '../../containers/AllocationPeriodContainer/AllocationPeriodContainer';

type Props = {
  allocationPeriod: AllocationPeriod;
  onSubmitAllocationPeriod: (allocationPeriod: AllocationPeriod) => void;
  onOpenAllocationPeriodModal?: () => void;
};

export const AllocationPeriodField = ({
  allocationPeriod,
  onSubmitAllocationPeriod,
  onOpenAllocationPeriodModal,
}: Props) => {
  const { t } = useTranslation('global');

  const formik = useFormik<AllocationPeriod>({
    initialValues: getInitialValues(allocationPeriod),
    enableReinitialize: true,
    validate: (values) => validate(values, t),
    onSubmit: (values) => {
      if (values.type === 'date') {
        onSubmitAllocationPeriod({
          type: 'date',
          date: values.date,
        });
      } else {
        onSubmitAllocationPeriod({
          type: 'period',
          startDate: values.startDate,
          endDate: values.endDate,
        });
      }
    },
  });

  return (
    <div>
      {formik.values.type === 'date' && (
        <AllocationPeriodFieldDate
          date={formik.values.date}
          errorDate={
            'date' in formik.errors && typeof formik.errors.date === 'string'
              ? formik.errors.date
              : undefined
          }
          onDateChange={async (date) => {
            const errors = await formik.setValues({ type: 'date', date });
            if (errors && Object.keys(errors).length === 0) {
              formik.handleSubmit();
            }
          }}
          onChangeMode={() => {
            formik.setFieldValue('type', 'period');
            formik.setFieldValue(
              'startDate',
              formik.values.type === 'date' ? formik.values.date : undefined,
            );
          }}
        />
      )}
      {formik.values.type === 'period' && (
        <>
          <AllocationPeriodFieldPeriod
            startDate={formik.values.startDate}
            endDate={formik.values.endDate}
            errorStartDate={
              'startDate' in formik.errors &&
              typeof formik.errors.startDate === 'string'
                ? formik.errors.startDate
                : undefined
            }
            errorEndDate={
              'endDate' in formik.errors &&
              typeof formik.errors.endDate === 'string'
                ? formik.errors.endDate
                : undefined
            }
            onDatesChange={async (dates) => {
              const errors = await formik.setValues({
                type: 'period',
                startDate: dates.startDate,
                endDate: dates.endDate,
              });
              if (errors && Object.keys(errors).length === 0) {
                formik.handleSubmit();
              }
            }}
            onChangeToDate={async (date) => {
              const errors = await formik.setValues({
                type: 'date',
                date,
              });
              if (errors && Object.keys(errors).length === 0) {
                formik.handleSubmit();
              }
            }}
          />

          <Callout
            title={t('budget.allocationPeriod.calloutTitle')}
            variant="info"
            className="mt-8"
          >
            <div>{t('budget.allocationPeriod.calloutDescription')}</div>

            {!!onOpenAllocationPeriodModal && (
              <Button
                className="mt-16"
                type="button"
                variant="secondaryNeutral"
                onClick={onOpenAllocationPeriodModal}
                text={t('budget.allocationPeriod.seeMoreDetails')}
              />
            )}
          </Callout>
        </>
      )}
    </div>
  );
};

const getInitialValues = (
  allocationPeriod: AllocationPeriod,
): AllocationPeriod => {
  if (allocationPeriod.type === 'period') {
    return {
      type: 'period',
      startDate: allocationPeriod.startDate,
      endDate: allocationPeriod.endDate,
    };
  }
  return {
    type: allocationPeriod.type,
    date: allocationPeriod.date,
  };
};
