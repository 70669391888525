import { useMemo } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { NavLink } from 'modules/app/layout/components/VerticalNavigation/NavLink/NavLink';
import { ReadyToSpendPopover } from 'modules/onboarding/setup-hub/components/ReadyToSpendPopover/ReadyToSpendPopover';
import { useSetupHubTasksQuery } from 'modules/onboarding/setup-hub/pages/SetupHubPage/hooks/useSetupHubTasksQuery';
import { routeFor, routes } from 'src/core/constants/routes';

export const SetupGuideNavigationItem = () => {
  const { t } = useTranslation('global');

  const company = useCompany();
  const setupHubQueryState = useSetupHubTasksQuery();

  const isFullPage = useMemo(() => {
    return (
      setupHubQueryState.status === 'success' &&
      setupHubQueryState.data.length > 0
    );
  }, [setupHubQueryState]);
  const hasOngoingTask = useMemo(() => {
    return (
      setupHubQueryState.status === 'success' &&
      setupHubQueryState.data.some((task) => task.status === 'ongoing')
    );
  }, [setupHubQueryState]);

  const setupGuidePath = routeFor(routes.SETUP_HUB.path, {
    company: company.id,
  });

  if (isFullPage) {
    return (
      <NavLink
        href={setupGuidePath}
        iconName="clipboard"
        activePaths={[routes.SETUP_HUB.path]}
        pulse={hasOngoingTask}
      >
        {t('readyToSpend.setupHub')}
      </NavLink>
    );
  }
  return <ReadyToSpendPopover />;
};
