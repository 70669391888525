import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { getIsAccountOwner } from 'src/core/selectors/globalSelectors';

import { AccountOwnerSpendingPolicy } from './AccountOwnerSpendingPolicy';
import { BasicRequesterSpendingPolicy } from './BasicRequesterSpendingPolicy';
import ZenGeorge from './Zen-George.svg';
import { useSpendingPolicy } from '../../hooks';
import './RequesterSpendingPolicy.css';

type Props = {
  className?: string;
};

export const RequesterSpendingPolicy = ({ className }: Props) => {
  const { t } = useTranslation('global');
  const hasApprovalFlowFeature = useFeature(FEATURES.APPROVAL_FLOWS);
  const spendingPolicy = useSpendingPolicy();
  const isAccountOwner = useSelector(getIsAccountOwner);

  return (
    <section
      className={classnames(
        'box bg-primary-default p-24 text-center',
        className,
      )}
    >
      <img src={ZenGeorge} alt="" />
      <h5 className="mb-4 mt-24 text-primary title-l">
        {hasApprovalFlowFeature
          ? t('control.approvalPolicyWidget.approvalPolicyTitle')
          : t('control.approvalPolicyWidget.spendingPolicyTitle')}
      </h5>
      <div className="RequesterSpendingPolicy__description text-primary body-m">
        {isAccountOwner ? (
          <AccountOwnerSpendingPolicy />
        ) : (
          <BasicRequesterSpendingPolicy
            spendingPolicy={spendingPolicy}
            hasApprovalFlowFeature={hasApprovalFlowFeature}
          />
        )}
      </div>
    </section>
  );
};
