import { EmptyState } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import Illustration from './assets/illustration.svg';

export const RestrictedAccessPanel = () => {
  const { t } = useTranslation('global');

  return (
    <div className="col-span-2 rounded-12 bg-secondary-alert-default p-24 text-center">
      <EmptyState
        illustration={
          <img width="166" height="149" alt="" src={Illustration} />
        }
        title={t('homepage.restrictedAccessPanel.title')}
        subtitle={t('homepage.restrictedAccessPanel.text')}
      />
    </div>
  );
};
