import { Avatar, PanelSection } from '@dev-spendesk/grapes';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { SeeMore } from 'modules/requests/expense/expense-form/SeeMore';
import { getDisplayNameRegisteredBeneficiary } from 'modules/requests/models/beneficiaries';
import FEATURES from 'src/core/constants/features';
import { type Payable } from 'src/core/modules/bookkeep/prepare-payables/models/payable';

type Props = {
  payable: {
    expenseBeneficiaries?: Payable['expenseBeneficiaries'];
  };
};

export const PayableBeneficiariesSection = ({ payable }: Props) => {
  const { t } = useTranslation('global');

  const isBeneficiariesFeatureEnabled = useFeature(
    FEATURES.TMP_REQUEST_BENEFICIARIES,
  );

  if (!isBeneficiariesFeatureEnabled) {
    return null;
  }

  const expenseBeneficiaries = payable.expenseBeneficiaries ?? [];
  return (
    <PanelSection
      title={t('requests.panel.beneficiariesLabel', {
        count: expenseBeneficiaries.length,
      })}
      isEditable={false}
    >
      <SeeMore>
        {expenseBeneficiaries.map((beneficiary) => (
          <div key={beneficiary.id} className="flex items-center gap-16">
            <Avatar
              src={
                beneficiary.type === 'registeredUser'
                  ? beneficiary.registeredUser.avatarUrl
                  : undefined
              }
              text={
                beneficiary.type === 'registeredUser'
                  ? getDisplayNameRegisteredBeneficiary(beneficiary)
                  : beneficiary.unregisteredUser.name
              }
              className="shrink-0"
            />
            <span className="truncate">
              {beneficiary.type === 'registeredUser' &&
                getDisplayNameRegisteredBeneficiary(beneficiary)}
              {beneficiary.type === 'unregisteredUser' &&
                `${beneficiary.unregisteredUser.name} (${beneficiary.unregisteredUser.companyName})`}
            </span>
          </div>
        ))}
      </SeeMore>
    </PanelSection>
  );
};
