import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useMarkWelcomeDashboardAsDoneMutation } from 'modules/homepage/ControllerHomepage/hooks/useMarkWelcomeDashboardAsDoneMutation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ConfirmModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('global');
  const [markWelcomeDashboardAsDone] = useMarkWelcomeDashboardAsDoneMutation();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const dismissDashboard = async () => {
    setIsErrorVisible(false);
    try {
      await markWelcomeDashboardAsDone();
      onClose();
    } catch {
      setIsErrorVisible(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('homepage.welcomeDashboard.dismissConfirmModal.title')}
      iconName="triangle-warning"
      iconVariant="warning"
      actions={
        <>
          <Button
            variant="secondaryNeutral"
            text={t('homepage.welcomeDashboard.dismissConfirmModal.cancel')}
            onClick={onClose}
          />
          <Button
            variant="primaryBrand"
            text={t('homepage.welcomeDashboard.dismissConfirmModal.confirm')}
            onClick={dismissDashboard}
          />
        </>
      }
    >
      <p>{t('homepage.welcomeDashboard.dismissConfirmModal.description')}</p>
      {isErrorVisible && (
        <Callout
          className="mt-24"
          title={t('homepage.welcomeDashboard.markAsDoneError')}
          variant="alert"
        />
      )}
    </Modal>
  );
};
