import { SkeletonAvatar, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const PayableListItemSkeleton = () => {
  return (
    <div className="flex w-full items-center py-8">
      <SkeletonAvatar variant="circle" className="mr-16" />
      <div className="w-full flex-1">
        <div className="flex justify-between pb-8">
          <SkeletonText size="xl" width="100px" />
          <SkeletonText size="xl" width="60px" />
        </div>
        <div className="flex justify-between">
          <SkeletonText size="l" width="180px" />
          <SkeletonText size="l" width="100px" />
        </div>
      </div>
    </div>
  );
};
