import { Button } from '@dev-spendesk/grapes';
import {
  ReactFlow,
  Controls,
  Background,
  BackgroundVariant,
} from '@xyflow/react';
import { useEffect } from 'react';
import '@xyflow/react/dist/style.css';

import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { useWorkflowContext } from '../../WorkflowContext';
import { WorkflowProvider } from '../../WorkflowProvider';
import { editApprovalWorkflow } from '../../api/editApprovalWorkflow';
import { edgeTypes } from '../../edge-types';
import { transformApprovalWorkflowToNodesAndEdges } from '../../helpers/transformApprovalWorkflowToNodesAndEdges';
import { transformNodesAndEdgesToApprovalWorkflow } from '../../helpers/transformNodesAndEdgesToApprovalWorkflow';
import { useApprovalWorkflowQuery } from '../../hooks/useApprovalWorkflowQuery';
import { nodeTypes } from '../../node-types';
import { NodeSettingsPanel } from '../NodeSettingsPanel';

const ApprovalByDimensionsPageInternal = () => {
  const { nodes, edges, setNodes, setEdges } = useWorkflowContext();
  const approvalWorkflowQueryState = useApprovalWorkflowQuery();
  const companyId = useCompanyId();

  useEffect(() => {
    if (approvalWorkflowQueryState.status === 'success') {
      const transformed = transformApprovalWorkflowToNodesAndEdges(
        approvalWorkflowQueryState.data,
      );
      setNodes(transformed.nodes);
      setEdges(transformed.edges);
    }
  }, [
    approvalWorkflowQueryState.status,
    approvalWorkflowQueryState.data,
    setNodes,
  ]);

  const handleSaveApprovalFlow = () => {
    const approvalWorkflow = transformNodesAndEdgesToApprovalWorkflow(
      nodes,
      edges,
    );
    editApprovalWorkflow({ companyId, approvalWorkflow });
  };

  return (
    <div className="grow">
      <ReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        fitView
        fitViewOptions={{ padding: 1.4 }}
      >
        <Controls />
        <Background gap={12} size={1} variant={BackgroundVariant.Dots} />
      </ReactFlow>
      <Button
        onClick={handleSaveApprovalFlow}
        text="Save (dev)"
        className="absolute bottom-8 left-64"
      />
      <NodeSettingsPanel />
    </div>
  );
};

export const ApprovalByDimensionsPage = () => {
  return (
    <ErrorBoundary
      context={{ team: 'spend-control', scope: 'approval-by-dimensions' }}
    >
      <WorkflowProvider>
        <ApprovalByDimensionsPageInternal />
      </WorkflowProvider>
    </ErrorBoundary>
  );
};
