import {
  Button,
  Modal,
  type ModalProps,
  SwitchField,
  Tooltip,
  Callout,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { useGoTo } from 'common/hooks/useGoTo';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { useHasOptedInToAnalyticalSplit } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitAvailability';

import { useOptInToAnalyticalSplitMutation } from '../../../../hooks/useOptInToAnalyticalSplitMutation';

type ModalName = 'confirmation' | 'firstSetup' | null;

export const AnalyticalSplitSettings = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const goTo = useGoTo();
  const { dangerNotif } = useNotifications();

  const [isTooltipOpened, setIsTooltipOpened] = useState(false);

  const [isModalOpened, setIsModalOpened] = useState(false);

  const [currentModal, setCurrentModal] = useState<ModalName>(null);

  const setOpenedModal = (modal: ModalName) => {
    if (modal) {
      setIsModalOpened(true);
      setCurrentModal(modal);
    } else {
      setIsModalOpened(false);
    }
  };

  const hasOptedInToAnalyticalSplit = useHasOptedInToAnalyticalSplit();

  const [optInToAnalyticalSplit] = useOptInToAnalyticalSplitMutation();

  return (
    <>
      <Tooltip
        isOpen={isTooltipOpened}
        onOpenChange={(isOpen) => !isOpen && setIsTooltipOpened(false)}
        placement="top"
        content={
          <div className="leading-5">
            {t(
              'bookkeep.integrations.settings.analyticalSplit.disableAnalyticalSplitTooltip',
            )}
          </div>
        }
      >
        <SwitchField
          fit="parent"
          label={
            <>
              {t(
                'bookkeep.integrations.settings.analyticalSplit.enableAnalyticalSplitTitle',
              )}
            </>
          }
          helpText={
            <Trans i18nKey="bookkeep.integrations.settings.analyticalSplit.enableAnalyticalSplitDescription">
              <a
                href={t(
                  'bookkeep.integrations.settings.analyticalSplit.readHelpCenterArticleUrl',
                )}
                target="_blank"
                rel="noreferrer"
                className="font-semibold text-primary underline"
              >
                Read more
              </a>
            </Trans>
          }
          isChecked={hasOptedInToAnalyticalSplit}
          onChange={() =>
            hasOptedInToAnalyticalSplit
              ? setIsTooltipOpened(true)
              : setOpenedModal('confirmation')
          }
        />
      </Tooltip>
      {(() => {
        let props: null | ModalProps = null;

        if (currentModal === 'confirmation') {
          props = {
            isOpen: isModalOpened,
            title: t(
              'bookkeep.integrations.settings.analyticalSplit.confirmationModalTitle',
            ),
            iconName: 'sparkle',
            iconVariant: 'info',
            actions: [
              <Button
                key="back"
                variant="secondaryNeutral"
                onClick={() => setOpenedModal(null)}
                text={t('misc.cancel')}
              />,
              <Button
                key="confirm"
                text={t(
                  'bookkeep.integrations.settings.analyticalSplit.confirmationModalConfirm',
                )}
                variant="primaryBrand"
                onClick={async () => {
                  try {
                    await optInToAnalyticalSplit();
                    setOpenedModal('firstSetup');
                  } catch {
                    dangerNotif(
                      t(
                        'bookkeep.integrations.settings.analyticalSplit.enableAnalyticalSplitError',
                      ),
                    );
                    setOpenedModal(null);
                  }
                }}
                iconName="chevron-right"
                iconPosition="right"
              />,
            ],
            children: (
              <>
                <p>
                  {t(
                    'bookkeep.integrations.settings.analyticalSplit.confirmationModalContent',
                  )}
                </p>
                <Callout
                  className="my-16"
                  variant="warning"
                  title={t(
                    'bookkeep.integrations.settings.analyticalSplit.purchaseJournalWarningTitle',
                  )}
                >
                  <Trans i18nKey="bookkeep.integrations.settings.analyticalSplit.purchaseJournalWarningDescription">
                    <a
                      href={t(
                        'bookkeep.integrations.settings.analyticalSplit.readHelpCenterArticlePurchaseJournalUrl',
                      )}
                      target="_blank"
                      rel="noreferrer"
                      className="font-semibold text-primary underline"
                    >
                      section with changes in format
                    </a>
                  </Trans>
                </Callout>
                <a
                  href={t(
                    'bookkeep.integrations.settings.analyticalSplit.readHelpCenterArticleUrl',
                  )}
                  target="_blank"
                  rel="noreferrer"
                  className="font-semibold text-primary underline"
                >
                  {t(
                    'bookkeep.integrations.settings.analyticalSplit.readHelpCenterArticle',
                  )}
                </a>
              </>
            ),
          };
        }

        if (currentModal === 'firstSetup') {
          props = {
            isOpen: isModalOpened,
            title: t(
              'bookkeep.integrations.settings.analyticalSplit.firstSetupModalTitle',
            ),
            iconName: 'check',
            iconVariant: 'success',
            actions: [
              <Button
                key="confirm"
                text={t(
                  'bookkeep.integrations.settings.analyticalSplit.firstSetupModalGoToAnalyticalFieldsSettings',
                )}
                variant="primaryBrand"
                onClick={() =>
                  goTo('COMPANY_CATEGORIES', { company: companyId })
                }
                iconName="chevron-right"
                iconPosition="right"
              />,
            ],
            children: (
              <Trans i18nKey="bookkeep.integrations.settings.analyticalSplit.firstSetupModalContent">
                <a
                  href={t(
                    'bookkeep.integrations.settings.analyticalSplit.readHelpCenterArticleUrl',
                  )}
                  target="_blank"
                  rel="noreferrer"
                  className="font-semibold text-primary underline"
                >
                  Read help article center
                </a>
              </Trans>
            ),
          };
        }

        return props ? (
          <Modal onClose={() => setOpenedModal(null)} {...props} />
        ) : null;
      })()}
    </>
  );
};
