import type {
  Filter,
  MemberId,
  SavedFilter as PayableSavedFilterWithoutId,
} from '@spendesk/bookkeeping-core-types';
import type { CompanyId } from '@spendesk/company-core-types';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUserId } from 'modules/app/hooks/useUserId';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';
import { type SavedFilter } from 'src/core/common/components/FilterBuilder';

import {
  reshapeFilter,
  reshapeFilterForApi,
  useUpdatePayableFilterQueryCache,
} from './usePayableFilter';

/**
 * @public
 */
export type UpdatePayableFilterError = unknown;

/**
 * @public
 */
export type UpdatePayableFilterRawResponse = {
  payableFilter: {
    id: string;
    name: string;
    filter: Filter;
    userId: MemberId;
    companyId: CompanyId;
  };
};

/**
 * @public
 */
export type UpdatePayableFilterResponse = SavedFilter;

/**
 * @public
 */
export type UpdatePayableFilterPayload = SavedFilter;

/**
 * @public
 */
export type UpdatePayableFilterParams = {
  filterId: string;
};

/**
 * Mutation
 **/

export const useUpdatePayableFilter = (): MutationState<
  UpdatePayableFilterPayload,
  UpdatePayableFilterResponse,
  UpdatePayableFilterError
> => {
  const companyId = useCompanyId() as CompanyId;
  const userId = useUserId() as MemberId;

  const updatePayableFilterQueryCache = useUpdatePayableFilterQueryCache();

  const [mutate, ...rest] = useMutation<
    PayableSavedFilterWithoutId & { endpointParams: { filterId: string } },
    UpdatePayableFilterResponse,
    UpdatePayableFilterRawResponse,
    UpdatePayableFilterError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: (params) => {
        const filterId =
          params && typeof params === 'object' && 'filterId' in params
            ? params.filterId
            : '';
        if (!filterId) {
          throw new Error('Filter ID is required');
        }
        return `/bookkeeping/payables/filters/${filterId}`;
      },
      method: 'put',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ rawData: { payableFilter } }) => {
        updatePayableFilterQueryCache(payableFilter.id, payableFilter);
      },
    },
    reshapeData(data) {
      return reshapeFilter(data.payableFilter);
    },
  });

  return [
    (params) =>
      mutate({
        ...reshapeFilterForApi(companyId, userId, params),
        endpointParams: { filterId: params.id },
      }),
    ...rest,
  ];
};
