import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  matchPath,
} from 'react-router-dom';

import { useParams } from 'common/hooks/useParams';
import { useTranslation } from 'common/hooks/useTranslation';
import { AccountPayableSuppliersSubList } from 'modules/bookkeep/accounts-payable/pages/AccountPayableSuppliersList/AccountPayableSuppliersSubList';
import { AccountPayableSuppliersPanelContainer } from 'modules/bookkeep/accounts-payable/pages/AccountPayableSuppliersPanel';
import { SupplierAccountAdvice } from 'modules/bookkeep/components/AccountAdvice';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { routeFor, routes } from 'src/core/constants/routes';
import { getTopNotificationOffset } from 'src/core/utils/topNotificationOffset';

import styles from './AccountPayableSuppliersMainList.module.css';
import { useHasAuxiliaryAccountsEnabled } from '../../../../hooks';
import { type SupplierAccountDefaultFor } from '../../../../settings/accounting';
import { AccountPayableSuppliersMainListTable } from '../../../components/AccountPayableMainListTable/AccountPayableSuppliersMainListTable';
import {
  type AccountPayableSuppliersRow,
  type SuppliersWithoutAccountPayableRow,
  AccountPayableSuppliersPageId,
} from '../../../types';
import { SuppliersPanelId } from '../../AccountPayableSuppliersPanel/AccountPayableSuppliersPanel';

type PreparedRow = {
  isDefaultSupplierAccount: boolean;
  supplierAccountDefaultFor: SupplierAccountDefaultFor | undefined;
  accountPayable: string | undefined;
  auxiliaryAccountCode: string | undefined;
  hasError: boolean;
  isActive: boolean;
  rowId: string;
  supplierName: JSX.Element;
  totalAmount: JSX.Element;
};

type Props = {
  integrationStatus: IntegrationStatusWithIntegration;
  hasNextPage: boolean;
  onFetchNextPage: React.MouseEventHandler<HTMLButtonElement>;
  selectedTab: 'current' | 'archived';
  suppliers: AccountPayableSuppliersRow[];
  suppliersWithoutAccountPayable: SuppliersWithoutAccountPayableRow;
  supplierSelectionToBulkArchive: string[];
  isFileViewerOpen: boolean;
  setSupplierSelectionToBulkArchive: (
    row: {
      supplierName: JSX.Element;
      totalAmount: string;
      rowId: string;
      isActive: boolean;
    },
    rowId: string,
    isChecked: boolean,
  ) => void;
  setAccountPayableIdToBulkArchive: (accountPayableId?: string) => void;
  onAllSuppliersToBulkArchiveSelectionChange: (
    rows: {
      supplierName: JSX.Element;
      totalAmount: string;
      rowId: string;
      isActive: boolean;
    }[],
    rowIds: string[],
    isChecked: boolean,
  ) => void;
};

export const AccountPayableSuppliersMainList = ({
  integrationStatus,
  hasNextPage,
  onFetchNextPage,
  selectedTab,
  suppliers,
  suppliersWithoutAccountPayable,
  supplierSelectionToBulkArchive,
  isFileViewerOpen,
  setSupplierSelectionToBulkArchive,
  setAccountPayableIdToBulkArchive,
  onAllSuppliersToBulkArchiveSelectionChange,
}: Props) => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const location = useLocation();
  const { company } = useParams(routes.COMPANY_ACCOUNTS_PAYABLE.path);
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();
  const [topErrorsOffset, setTopErrorsOffset] = useState(0);

  useEffect(() => {
    const boxErrors = document.querySelector('.AccountPayableSuppliersErrors');

    if (boxErrors) {
      const totalHeightBoxError =
        boxErrors.clientHeight +
        Number.parseInt(window.getComputedStyle(boxErrors).marginBottom);

      setTopErrorsOffset(totalHeightBoxError);
    }
  }, []);

  const invalidSupplierAccounts =
    integrationStatus.settingsValidation.supplierAccounts
      .filter((error) => error.id && error.error === 'invalidAccount')
      .map((error) => error.id);

  /**
   * Get router params outside of the route
   * We use them for table selected row state in the parent component
   */
  const matchAccountPayableId = matchPath<{ accountPayableId: string }>(
    location.pathname,
    {
      path: routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path,
      exact: false,
    },
  );
  const accountPayableIdParameter =
    matchAccountPayableId?.params.accountPayableId ?? '';

  const matchSupplierId = matchPath<{ supplierId: string }>(location.pathname, {
    path: routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS_DETAIL.path,
    exact: true,
  });

  const matchSupplierDetailId = matchPath<{ supplierId: string }>(
    location.pathname,
    {
      path: routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIER_DETAIL.path,
      exact: true,
    },
  );

  // supplierId is needed for main list, or sublist
  const supplierIdParameter =
    matchSupplierId?.params.supplierId ??
    matchSupplierDetailId?.params.supplierId ??
    '';

  const handleRowClick = (row: PreparedRow) => {
    if (row.rowId === '-') {
      if (suppliersWithoutAccountPayable.totalCount === 1) {
        history.push({
          pathname: routeFor(
            routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIER_DETAIL.path,
            {
              company,
              supplierId: suppliersWithoutAccountPayable.suppliers[0].id ?? '',
            },
          ),
          search: location.search,
        });
      } else {
        history.push({
          pathname: routeFor(routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path, {
            company,
            accountPayableId: '-',
          }),
          search: location.search,
        });
      }
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const rowData = suppliers.find(
      (supplier) =>
        row.rowId === supplier.accountPayable.id ||
        row.rowId === supplier.suppliers[0]?.id,
    )!;

    let pathname;

    if (rowData.totalCount === 1) {
      pathname = routeFor(
        routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIER_DETAIL.path,
        {
          company,
          supplierId: rowData.suppliers[0]?.id,
        },
      );
    } else if (rowData.totalCount > 1) {
      pathname = routeFor(routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path, {
        company,
        accountPayableId: rowData.accountPayable.id,
      });
    }

    const isCurrentRoute = history.location.pathname === pathname;

    if (!isCurrentRoute) {
      history.push({
        pathname,
        search: location.search,
      });
    }

    const top = document.getElementById(
      AccountPayableSuppliersPageId,
    )?.scrollTop;

    setTimeout(() => {
      const panel = document.querySelector<HTMLElement>(
        `[data-testid='${SuppliersPanelId}']`,
      );
      if (panel) {
        panel.style.transition = 'margin-top 0.15 ease-out';
        panel.style.marginTop = `${Math.max(0, (top ?? 0) - 64)}px`;
      }
    }, 0);
  };

  return (
    <>
      <div className="flex">
        <div className="w-full">
          {!!invalidSupplierAccounts.length && (
            <SupplierAccountAdvice
              className="AccountPayableSuppliersErrors mb-16"
              integrationStatus={integrationStatus}
              showError
              title={t(
                'bookkeep.integrations.datev.accountCodeAdvice.accountsPayableAccounts.title',
                {
                  count: invalidSupplierAccounts.length,
                },
              )}
            />
          )}
          <AccountPayableSuppliersMainListTable
            accountPayableIdParameter={accountPayableIdParameter}
            auxiliaryAccountsEnabled={auxiliaryAccountsEnabled}
            handleRowClick={handleRowClick}
            hasNextPage={hasNextPage}
            integrationStatus={integrationStatus}
            invalidSupplierAccounts={invalidSupplierAccounts}
            onFetchNextPage={onFetchNextPage}
            supplierIdParameter={supplierIdParameter}
            suppliers={suppliers}
            suppliersWithoutAccountPayable={suppliersWithoutAccountPayable}
            topErrorsOffset={topErrorsOffset}
          />
        </div>
        <Switch>
          {/* Account payable detail route with 1 supplier */}
          <Route path={routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIER_DETAIL.path}>
            <div
              className={classNames('sticky top-0 ml-24 w-[368px]', {
                [styles.panelEmphasized]: isFileViewerOpen,
              })}
              style={{
                height: `calc(100vh - 260px - ${getTopNotificationOffset()}px)`,
              }}
            >
              <AccountPayableSuppliersPanelContainer
                integrationStatus={integrationStatus}
                supplierId={supplierIdParameter}
              />
            </div>
          </Route>
          {/* Account payable routes with multiple suppliers */}
          <Route path={routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path}>
            <AccountPayableSuppliersSubList
              archivalStatus={selectedTab}
              supplierSelectionToBulkArchive={supplierSelectionToBulkArchive}
              setSupplierSelectionToBulkArchive={
                setSupplierSelectionToBulkArchive
              }
              setAccountPayableIdToBulkArchive={
                setAccountPayableIdToBulkArchive
              }
              onAllSuppliersToBulkArchiveSelectionChange={
                onAllSuppliersToBulkArchiveSelectionChange
              }
            />
            <Route path={routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS_DETAIL.path}>
              <div
                className={classNames(
                  'sticky top-0 ml-24 w-[368px] self-start',
                  {
                    [styles.panelEmphasized]: isFileViewerOpen,
                  },
                )}
                style={{
                  height: `calc(100vh - 260px - ${getTopNotificationOffset()}px)`,
                }}
              >
                <AccountPayableSuppliersPanelContainer
                  integrationStatus={integrationStatus}
                  supplierId={supplierIdParameter}
                />
              </div>
            </Route>
          </Route>
        </Switch>
      </div>
    </>
  );
};
