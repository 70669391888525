import { v4 as uuid } from 'uuid';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ApprovalRule } from '../../company/structure/approval-flows';
import {
  normalizeRules,
  removeAoRule,
  shouldRemoveAoRule,
} from '../../company/structure/approval-flows/models';

type ApprovalScheme = {
  id: string;
  owner: {
    id: string;
  } | null;
  rules: ApprovalRule[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reshapeApprovalScheme = (data: any): ApprovalScheme => {
  const apiApprovalRules = data.rules ?? [];
  const approvalRules = shouldRemoveAoRule({ rules: apiApprovalRules })
    ? removeAoRule({ rules: apiApprovalRules }).rules
    : apiApprovalRules;
  const normalizedRules = normalizeRules(approvalRules);
  return {
    ...data,
    rules: normalizedRules.map((normalizedRule) => ({
      ...normalizedRule,
      id: uuid(),
    })),
  };
};

export const useApprovalSchemeQuery = (
  approvalSchemeId: string | undefined,
): QueryState<ApprovalScheme> => {
  return useQuery<ApprovalScheme>({
    key: ['approval-scheme', approvalSchemeId],
    isEnabled: !!approvalSchemeId,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/approval-scheme/${approvalSchemeId}`,
    },
    reshapeData: reshapeApprovalScheme,
  });
};
