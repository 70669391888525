import {
  colors,
  Icon,
  type IconName,
  Table,
  type TagVariant,
  type TableColumn,
  Tag,
} from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { DotSeparator } from 'src/core/common/components/DotSeparator';
import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import { SupplierLogo } from 'src/core/common/components/SupplierLogo';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { formatMoney } from 'src/core/utils/money';

import { type PaymentToComplete } from '../../paymentToComplete';

type Props = {
  payments: (PaymentToComplete & { routeLink: string })[];
};

export const PaymentsList = ({ payments }: Props) => {
  const { t } = useTranslation('global');
  const history = useHistory();

  const columns: TableColumn<(typeof payments)[number]>[] = [
    {
      header: t('misc.supplier'),
      id: 'supplier',
      width: 'auto',
      renderCell: (payment) => {
        return (
          <div className="flex items-center gap-8">
            <SupplierLogo name={payment.supplier?.name} />
            <p
              className={cx(
                'text-primary body-m',
                !payment.supplier && 'text-secondary-bg-secondary',
              )}
            >
              {payment.supplier?.name ??
                t('homepage.paymentsList.row.missingSupplier')}
            </p>
          </div>
        );
      },
    },
    {
      header: t('misc.description'),
      id: 'description',
      width: 'auto',
      renderCell: (payment) => {
        return (
          <EllipsisTooltip
            text={payment.description ?? payment.transactionDescription}
          />
        );
      },
    },
    {
      header: t('forms.paymentType.label'),
      id: 'type',
      width: 64,
      renderCell: (payment) => {
        return (
          <Icon
            name={getIconFromPaymentType(payment.type)}
            color={colors.contentDecorativeIcon}
          />
        );
      },
    },
    {
      header: t('misc.amount'),
      id: 'amount',
      width: 'auto',
      align: 'right',
      renderCell: (payment) => {
        return (
          <div className="flex items-center justify-end text-primary">
            <p>{formatMoney(payment.amountBilled, payment.currencyBilled)}</p>
            {payment.amountDeclared !== payment.amountBilled && (
              <>
                <DotSeparator />
                <p className="text-secondary-bg-primary">
                  {formatMoney(
                    payment.amountDeclared,
                    payment.currencyDeclared,
                  )}
                </p>
              </>
            )}
          </div>
        );
      },
    },
    {
      header: t('misc.status'),
      id: 'status',
      width: 'auto',
      align: 'right',
      renderCell: (payment) => {
        const tagProps = getTagPropertiesFromCompletion(
          payment.completionDeadline,
          t,
        );

        if (!tagProps) {
          return <></>;
        }

        return (
          <Tag variant={tagProps.variant} iconName={tagProps.icon}>
            {tagProps.label}
          </Tag>
        );
      },
    },
  ];

  return (
    <Table
      data={payments}
      columns={columns}
      rowHeight="compact"
      getRowId={(payment) => payment.id}
      className="w-full"
      onRowClick={(payment) => {
        history.push(payment.routeLink);
      }}
    />
  );
};

const getIconFromPaymentType = (type: PaymentToComplete['type']): IconName => {
  switch (type) {
    case 'singlePurchaseCard':
      return 'card-lightning';
    case 'physicalCard':
      return 'card';
    case 'subscriptionCard':
      return 'card-rounded-arrows';
    default:
      return 'circle-question-outline';
  }
};

const getTagPropertiesFromCompletion = (
  completion: PaymentToComplete['completionDeadline'],
  translator: TGlobalFunctionTyped,
):
  | {
      variant: TagVariant;
      label: string;
      icon?: IconName;
    }
  | undefined => {
  if (!completion) {
    return undefined;
  }

  switch (completion.status) {
    case 'late': {
      return {
        variant: 'alert',
        label: translator('homepage.paymentsList.row.overdue'),
        icon: 'hexagone-cross',
      };
    }
    case 'urgent': {
      return {
        variant: 'warning',
        label: translator('homepage.paymentsList.row.dueIn', {
          count: completion.daysLeft,
        }),
        icon: 'triangle-warning',
      };
    }
    default:
      return {
        variant: 'info',
        label: translator('homepage.paymentsList.row.dueIn', {
          count: completion.daysLeft,
        }),
      };
  }
};
