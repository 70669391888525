import { Button, Modal } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import { useEffect } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type CustomField } from '../../models/customField';
import { CustomFieldValuesStep } from '../CustomFieldCreationModal/CustomFieldCreationModal';
import { validate } from '../CustomFieldCreationModal/validate';

type Props = {
  isOpen: boolean;
  customField: CustomField;
  name: string;
  onClose(): void;
  onSubmit(values: string): void;
};

export const CustomFieldEditValuesModal = ({
  onClose,
  isOpen,
  customField,
  name,
  onSubmit,
}: Props) => {
  const { t } = useTranslation('global');

  const formikProps = useFormik<Parameters<typeof validate>[0]>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      customFieldValues: undefined,
    },
    validate: (values) => {
      return validate(values, customField.totalValues);
    },
    onSubmit: (values) => {
      if (values.customFieldValues) {
        onSubmit(values.customFieldValues);
      }
    },
  });

  useEffect(() => {
    formikProps.resetForm();
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={t('customFields.edition.modalTitle', { name })}
      iconName="pen"
      iconVariant="info"
      actions={[
        <Button
          key="cancel-button"
          text={t('misc.cancel')}
          variant="secondaryNeutral"
          onClick={onClose}
        />,
        <Button
          key="submit-button"
          text={t('customFields.edition.modalSubmit')}
          isDisabled={formikProps.isSubmitting}
          onClick={() => formikProps.handleSubmit()}
        />,
      ]}
    >
      <CustomFieldValuesStep
        existingValuesCount={customField.totalValues}
        formikProps={formikProps}
      />
    </Modal>
  );
};
