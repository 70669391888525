const SPEND_TYPES = [
  'card_load',
  // 'credit_note', // Does not require an approval
  'expense',
  'invoice',
  'mileage_allowance',
  'per_diem_allowance',
  'purchase_order',
  'single_purchase', // i.e. virtual card
  'subscription_increase',
  'subscription',
] as const;

const getSpendTypeTranslation = (spendType: string) => {
  switch (spendType) {
    case ' card_load':
      return 'Card load';
    case 'credit_note':
      return 'Credit note';
    case 'expense':
      return 'Expense claim';
    case 'invoice':
      return 'Invoice';
    case 'mileage_allowance':
      return 'Mileage';
    case 'per_diem_allowance':
      return 'Per diem';
    case 'purchase_order':
      return 'Purchase order';
    case 'single_purchase':
      return 'Virtual card';
    case 'subscription_increase':
      return 'Subscription increase';
    case 'subscription':
      return 'Subscription';
    default:
      return spendType;
  }
};
export const spendTypeOptions = SPEND_TYPES.map((spendType) => ({
  key: spendType,
  label: getSpendTypeTranslation(spendType),
}));
