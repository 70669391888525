import { Button, Callout } from '@dev-spendesk/grapes';
import React, { useState, useId } from 'react';

import { FeedbackModal } from 'src/core/common/components/FeedbackModal';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import tellUs from '../../../assets/tell-us.svg';

export function TellUsBox() {
  const { t } = useTranslation('global');
  const titleId = useId();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <article
      className="box flex flex-col items-center justify-center gap-16 bg-secondary-default p-24"
      aria-labelledby={titleId}
    >
      <img src={tellUs} alt="tell-us" className="h-[70px] w-[86px]" />
      <h1 className="text-primary title-m" id={titleId}>
        {t('integration.tellUs.title')}
      </h1>
      <Button
        className="text-primary"
        text={t('integration.tellUs.button')}
        fit="content"
        variant="secondaryNeutral"
        isDropdown={false}
        onClick={() => setIsOpen(true)}
      />
      <FeedbackModal
        isOpen={isOpen}
        iconName="plug"
        feedbackLabel={t('integration.tellUs.feedback.label')}
        feedbackTags={['integrations']}
        title={t('integration.tellUs.feedback.title')}
        cancelText={t('misc.cancel')}
        confirmText={t('integration.tellUs.feedback.send')}
        onCancel={() => setIsOpen(false)}
        onConfirm={() => setIsOpen(false)}
        placeholder={t('integration.tellUs.feedback.placeholder')}
        callout={
          <Callout
            className="mt-8"
            variant="info"
            title={t('integration.tellUs.feedback.tip')}
          />
        }
      />
    </article>
  );
}
