import React from 'react';
import { NavLink as RRNavLink, matchPath } from 'react-router-dom';

import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';

type Props = {
  href: string;
  label: string;
  activePaths?: string[];
  hasEllipsisTooltip?: boolean;
};

export const SubNavLink = ({
  href,
  label,
  hasEllipsisTooltip = false,
  activePaths,
}: Props) => {
  return (
    <RRNavLink
      to={href}
      className="navigation-item navigation-toggle-display h-[36px]"
      isActive={(_, location) => {
        const match = matchPath(location.pathname, {
          path: activePaths ?? href,
          exact: false,
          strict: false,
        });
        return match !== null;
      }}
    >
      {hasEllipsisTooltip ? (
        <EllipsisTooltip className="col-start-2 w-full" text={label} />
      ) : (
        <span className="col-start-2 truncate">{label}</span>
      )}
    </RRNavLink>
  );
};
