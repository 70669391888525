import {
  Button,
  FormField,
  type IconName,
  Modal,
  TextArea,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { usePublishNoteToProductBoard } from 'src/core/common/hooks/usePublishNoteToProductBoard';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { isStringEmpty } from 'src/core/common/utils/isStringEmpty';

type Props = {
  title: string;
  subtitle?: string;
  feedbackLabel: string;
  feedbackTags: string[];
  isOpen: boolean;
  cancelText: string;
  confirmText: string;
  onCancel(): void;
  onConfirm(): void;
  iconName?: IconName;
  placeholder?: string;
  callout?: JSX.Element;
};

export const FeedbackModal = ({
  title,
  subtitle,
  feedbackLabel,
  isOpen,
  feedbackTags,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  iconName,
  placeholder,
  callout,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const [publishNoteToProductBoard] = usePublishNoteToProductBoard();
  const [feedbackContent, setFeedbackContent] = useState('');

  const onSubmitFeedback = () => {
    if (!isStringEmpty(feedbackContent)) {
      try {
        publishNoteToProductBoard({
          title: feedbackContent.slice(0, 50),
          content: feedbackContent,
          tags: feedbackTags,
        });
        pushNotif({
          type: NotificationType.Success,
          message: t('betaFeature.feedbackModal.successNotification'),
        });
      } catch {
        // silent error
      }
    }
    onConfirm();
  };

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onCancel}
          text={cancelText}
          variant="secondaryNeutral"
        />,
        <Button
          key="confirm"
          text={confirmText}
          variant="primaryBrand"
          onClick={onSubmitFeedback}
        />,
      ]}
      iconName={iconName ?? 'circle-information'}
      title={title}
      subtitle={subtitle}
      iconVariant="info"
      isOpen={isOpen}
    >
      <FormField label={feedbackLabel} className="mt-16">
        <TextArea
          value={feedbackContent}
          onChange={(event) => setFeedbackContent(event.target.value)}
          rows={5}
          placeholder={
            placeholder ?? t('betaFeature.feedbackModal.textPlaceholder')
          }
        />
      </FormField>
      {callout}
    </Modal>
  );
};
