import { Button, DropdownItem, DropdownMenu } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import { useCostCentersQuery } from 'modules/budgets/apis';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import './CostCenterFilter.css';

type Props = {
  costCenterId: string | undefined;
  setCostcenter(period: string | undefined): void;
};

export const CostCenterFilter = ({ costCenterId, setCostcenter }: Props) => {
  const { t } = useTranslation('global');
  const costCentersQueryState = useCostCentersQuery();

  return (
    <DropdownMenu
      onSelect={(option) =>
        setCostcenter(costCenterId !== option.key ? option.key : undefined)
      }
      options={
        costCentersQueryState.status === 'success'
          ? costCentersQueryState.data.map((cc) => ({
              key: cc.id,
              label: cc.name,
            }))
          : []
      }
      placement="bottom-start"
      renderButton={(getToggleButtonProps, isOpen) => (
        <Button
          {...getToggleButtonProps()}
          className={classNames('CostCenterFilter__button', {
            'CostCenterFilter__button--open': isOpen,
            'CostCenterFilter__button--active': costCenterId,
          })}
          text={t('payables.filters.costCenter')}
          variant="secondaryNeutral"
        />
      )}
      dropdownContentMaxHeight="300px"
      renderOption={(option) => (
        <DropdownItem
          label={option.label}
          isSelected={option.key === costCenterId}
        />
      )}
    />
  );
};
