import { Button, Modal } from '@dev-spendesk/grapes';
import React, { useEffect } from 'react';

import type { CostCenter } from 'modules/budgets/models/costCenter';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type ExpenseAccount } from 'src/core/modules/bookkeep/accounts-payable/types';

import { EcFormModalContent } from './EcFormModalContent';
import { type FormValues } from './formValues';
import { useEcFormikProps } from './getFormikProps';
import { type ExpenseCategory } from '../../expenseCategory';
import { type ExpenseCategoryExpenseAccountRule } from '../../hooks/useGetExpenseCategoryExpenseAccountRulesLazyQuery';

export type Props = {
  expenseCategory?: ExpenseCategory;
  expenseCategoryNames: string[];
  costCenters: CostCenter[];
  expenseAccounts: ExpenseAccount[];
  expenseCategoryExpenseAccountRules?: ExpenseCategoryExpenseAccountRule[];
  isOpen: boolean;
  onSubmit(values: FormValues): Promise<void>;
  onCancel(): void;
};

export const EcFormModal = ({
  expenseCategory,
  expenseCategoryNames,
  costCenters,
  expenseAccounts,
  expenseCategoryExpenseAccountRules = [],
  isOpen,
  onSubmit,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');

  const mode = expenseCategory ? 'edit' : 'creation';

  const formikProps = useEcFormikProps({
    costCenters,
    expenseCategory,
    onSubmit,
    expenseCategoryNames,
    expenseCategoryExpenseAccountRules,
  });

  useEffect(() => {
    formikProps.resetForm();
  }, [isOpen]);

  const handleCancel = () => {
    formikProps.resetForm();
    onCancel();
  };

  return (
    <Modal
      isOpen={isOpen}
      iconName="plus"
      iconVariant="info"
      title={
        mode === 'creation'
          ? t('expenseCategories.form.creationTitle')
          : t('expenseCategories.form.editTitle')
      }
      actions={[
        <Button
          key="cancel"
          variant="secondaryNeutral"
          text={t('expenseCategories.form.cancel')}
          onClick={handleCancel}
        />,
        <Button
          key="save"
          variant="primaryBrand"
          text={
            mode === 'creation'
              ? t('expenseCategories.form.add')
              : t('expenseCategories.form.save')
          }
          onClick={formikProps.submitForm}
        />,
      ]}
    >
      <EcFormModalContent
        mode={mode}
        costCenters={costCenters}
        expenseAccounts={expenseAccounts}
        {...formikProps}
      />
    </Modal>
  );
};
