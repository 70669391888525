import i18next from 'i18next';

export const formatNumberToDistance = ({
  number,
  distanceUnit,
  precision = 0,
}: {
  number: number;
  distanceUnit: 'km' | 'mi';
  precision?: number;
}) => {
  const formatter = new Intl.NumberFormat(i18next.language || 'en', {
    style: 'unit',
    unit: distanceUnit === 'km' ? 'kilometer' : 'mile',
    maximumFractionDigits: precision,
    minimumFractionDigits: 0,
  });

  return formatter.format(number);
};
