import { Callout } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routeFor } from 'src/core/constants/routes';

import { CustomFieldIsRequiredFormField } from './components/CustomFieldIsRequiredFormField';
import { CustomFieldNameFormField } from './components/CustomFieldNameFormField';
import { validate } from './form';
import { type FormValues } from './types';
import { SetupHubLayout } from '../../../components/SetupHubLayout';
import { VideoBlock } from '../../../components/VideoBlock';
import { routes } from '../../routes';

export const ControlSettingsCustomFieldName = () => {
  const { t } = useTranslation('global');
  const history = useHistory();

  const companyId = useCompanyId();

  const [hasSubmittedOnce, setHasSubmittedOnce] = useState<boolean>(false);

  const { errors, values, handleChange, handleSubmit, setFieldValue } =
    useFormik<FormValues>({
      initialValues: {
        name: '',
        isRequired: true,
      },
      validate: (valuesToValidate) =>
        validate({
          values: valuesToValidate,
          t,
        }),
      validateOnChange: hasSubmittedOnce,
      onSubmit: (customFieldValues) => {
        const nextPagePath = routeFor(
          routes.CONTROL_SETTINGS_CUSTOM_FIELD_VALUES.path,
          {
            company: companyId,
          },
        );
        history.push(nextPagePath, { ...customFieldValues });
      },
    });

  const onContinue = async () => {
    if (!hasSubmittedOnce) {
      setHasSubmittedOnce(true);
    }
    handleSubmit();
  };

  const goBackToHub = () => {
    const hubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(hubPath);
  };

  return (
    <SetupHubLayout
      onBack={goBackToHub}
      onNext={onContinue}
      onNextLabel={t('misc.continue')}
      rightContent={
        <VideoBlock
          id="custom_fields"
          title={t('setupHub.customField.video.title')}
          description={t('setupHub.customField.video.description')}
        />
      }
      scope="custom-field-name"
      stepIndex={0}
      stepTotal={3}
      title={t('setupHub.customField.title')}
    >
      <form className="flex flex-col gap-24" onSubmit={handleSubmit}>
        <Callout title={t('setupHub.customField.callout.title')}>
          <p>{t('setupHub.customField.callout.description')}</p>
        </Callout>
        <CustomFieldNameFormField
          error={errors.name}
          name={values.name}
          onChange={handleChange}
        />
        <CustomFieldIsRequiredFormField
          isRequired={values.isRequired}
          onChange={(isRequired) => {
            setFieldValue('isRequired', isRequired);
          }}
        />
      </form>
    </SetupHubLayout>
  );
};
