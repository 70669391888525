import { Button, FileCard, Panel, PanelSection } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { PanelItemsSection } from 'common/components/Panel';
import { useTranslation } from 'common/hooks/useTranslation';

import { BankInfoPreview } from './BankInfoPreview';
import { buildSupplierInfoItems } from './helpers';
import {
  SupplierFileViewer,
  type MediaType,
} from '../SupplierFileViewer/SupplierFileViewer';
import styles from '../panel.module.css';
import { type Supplier } from '../supplier';

type Props = {
  supplier: Supplier;
  onEditSupplierClicked: React.MouseEventHandler;
};

export const SupplierView = ({ supplier, onEditSupplierClicked }: Props) => {
  const { t } = useTranslation('global');

  const [showFilePreview, setShowFilePreview] = useState(false);

  return (
    <Panel
      className={styles.panel}
      footer={
        <Button
          fit="parent"
          variant="secondaryNeutral"
          text={t('submitMyInvoice.supplier.editSupplierInfo')}
          onClick={onEditSupplierClicked}
        />
      }
    >
      <PanelItemsSection
        title={t('submitMyInvoice.supplier.legalInfo')}
        items={buildSupplierInfoItems({
          supplier,
          translator: t,
        })}
      />

      <PanelSection title={t('submitMyInvoice.supplier.bankInfo')}>
        <BankInfoPreview supplier={supplier} />
      </PanelSection>

      {supplier.details.bankProofFile && (
        <PanelSection title={t('submitMyInvoice.supplier.proof')}>
          <FileCard
            title={supplier.details.bankProofFile.filename}
            mimeType={supplier.details.bankProofFile.mimetype ?? undefined}
            onClick={() => setShowFilePreview(!showFilePreview)}
          />
          {showFilePreview && (
            <SupplierFileViewer
              fileMediaType={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                supplier.details.bankProofFile.mimetype! as MediaType
              }
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              fileUrl={supplier.details.bankProofFile.viewUrl!}
            />
          )}
        </PanelSection>
      )}
    </Panel>
  );
};
