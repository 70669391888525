import {
  Avatar,
  Button,
  IconButton,
  Label,
  ListBox,
  Modal,
} from '@dev-spendesk/grapes';
import { useEffect } from 'react';

import { FeatureSwitch } from 'common/components/FeatureSwitch/FeatureSwitch';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { type TeamRaw, type TeamUserRaw } from 'modules/members/models/teams';
import FEATURES from 'src/core/constants/features';

import { TeamApprovalPreview } from './TeamApprovalPreview';
import { type ApprovalScheme } from './TeamList';

type Props = {
  users: TeamUserRaw[];
  group: TeamRaw;
  approvalScheme?: ApprovalScheme;
  fetchApprovalScheme: (groupId: string) => void;
  teamInvite: () => void;
  onSelectManager: () => void;
  onEditName: (group: TeamRaw) => void;
  onManageApprovers: (group: TeamRaw) => void;
  onDelete: () => void;
  onCancel: () => void;
};

export const TeamDetailsModal = ({
  users,
  group,
  approvalScheme,
  fetchApprovalScheme,
  teamInvite,
  onSelectManager,
  onEditName,
  onManageApprovers,
  onDelete,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const isCostCentersFeatureEnabled = useFeature(
    FEATURES.COST_CENTERS_ACTIVATED,
  );
  const isApprovalFlowsFeatureEnabled = useFeature(FEATURES.APPROVAL_FLOWS);

  useEffect(() => {
    fetchApprovalScheme(group.id);
  }, []);

  const getSubtitle = () => {
    let approversCount = 0;
    if (approvalScheme) {
      approversCount = approvalScheme.rules.reduce(
        (sum, rule) => sum + rule.steps.length,
        0,
      );
    }

    const membersCountText = t('members.countMembers', {
      count: group.members,
    });
    const approversCountText = !isCostCentersFeatureEnabled
      ? ` | ${t('members.countApprovers', { count: approversCount })}`
      : '';

    return `${membersCountText}${approversCountText}`;
  };

  const renderTeamName = () => {
    return (
      <div className="mb-16">
        <Label
          fit="parent"
          label={t('members.teamName')}
          hint={
            <IconButton
              iconName="pen"
              onClick={() => onEditName(group)}
              aria-label={t('misc.edit')}
            />
          }
        />
        <div className="rounded-4 bg-secondary-default px-16 py-8">
          {group.name}
        </div>
      </div>
    );
  };

  const renderTeamManagers = () => {
    if (isCostCentersFeatureEnabled) {
      return null;
    }

    const usersToDisplay = users.filter((u) => group.admin_ids.includes(u.id));

    return (
      <div className="mb-16">
        <Label
          fit="parent"
          label={t('members.teamManagers')}
          infoTipContent={t('members.aTeamManagerCanSeeAll')}
          hint={
            <IconButton
              iconName="pen"
              onClick={onSelectManager}
              aria-label={t('misc.edit')}
            />
          }
        />
        <ListBox
          rowHeight="compact"
          options={usersToDisplay.map((member) => ({
            id: member.id,
            name: member.pending ? member.email : member.fullname,
            isPending: member.pending,
            avatar: member.avatar,
          }))}
          getOptionId={({ id }) => id}
          emptyState={{
            title: t('members.teamHasNoUsers'),
          }}
        >
          {(option) => (
            <div className="flex items-center gap-8">
              <Avatar src={option.avatar} size="m" text={option.name ?? ''} />
              <div className={option.isPending ? 'text-primary' : undefined}>
                {option.name}
              </div>
            </div>
          )}
        </ListBox>
      </div>
    );
  };

  const renderApprovers = () => {
    if (isCostCentersFeatureEnabled) {
      return null;
    }

    return (
      <div className="mb-16">
        <Label
          fit="parent"
          label={t('misc.approvers')}
          hint={
            isApprovalFlowsFeatureEnabled && (
              <IconButton
                iconName="pen"
                onClick={() => onManageApprovers(group)}
                aria-label={t('misc.edit')}
              />
            )
          }
        />
        <TeamApprovalPreview selectedGroupId={group.id} />
      </div>
    );
  };

  const renderTeamMembers = () => {
    const membersPreview = group.members_preview;
    const usersToDisplay = group.users || membersPreview; // the `users` key is async

    return (
      <div className="mb-16">
        <Label
          fit="parent"
          label={t('members.teamMembersCount', {
            count: usersToDisplay.length,
          })}
          hint={
            <IconButton
              iconName="pen"
              onClick={teamInvite}
              aria-label={t('misc.edit')}
            />
          }
        />
        <ListBox
          rowHeight="compact"
          options={usersToDisplay.map((member) => ({
            id: member.id,
            name: member.pending ? member.email : member.fullname,
            isPending: member.pending,
            avatar: member.avatar,
          }))}
          getOptionId={({ id }) => id}
          emptyState={{
            title: t('members.teamHasNoUsers'),
          }}
        >
          {(option) => (
            <div className="flex items-center gap-8">
              <Avatar src={option.avatar} size="m" text={option.name ?? ''} />
              <div className={option.isPending ? 'text-primary' : undefined}>
                {option.name}
              </div>
            </div>
          )}
        </ListBox>
      </div>
    );
  };

  return (
    <Modal
      isOpen
      title={t('members.namedTeam', {
        teamName: group.name,
      })}
      subtitle={getSubtitle()}
      iconName="person-group"
      iconVariant="info"
      onClose={onCancel}
      actions={[
        <Button
          key="delete"
          variant="secondaryNeutral"
          iconName="circle-minus"
          text={t('members.deleteThisTeam')}
          onClick={onDelete}
        />,
      ]}
    >
      <div className="text-left">
        {renderTeamName()}
        <FeatureSwitch feature={FEATURES.TEAM_MANAGER}>
          {renderTeamManagers()}
        </FeatureSwitch>
        {renderApprovers()}
        {renderTeamMembers()}
      </div>
    </Modal>
  );
};
