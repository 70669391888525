import { Accordion, Icon, Popover } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { ModalMFAContainer } from 'common/components/ModalMFA';
import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useUser } from 'modules/app/hooks/useUser';
import { READY_TO_SPEND_TASKS } from 'modules/onboarding/setup-hub/constants/taskSections';
import { useGetOnboardingUserTasksQuery } from 'modules/onboarding/setup-hub/hooks/useGetOnboardingUserTasksQuery';
import { type UserTaskType } from 'modules/onboarding/setup-hub/types/userTask';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { ReadyToSpendTaskItem } from './components/ReadyToSpendTaskItem';
import { ReadyToSpendModalSlideshow } from '../ReadyToSpendModalSlideshow';

type MFAModalProperties =
  | {
      isOpen: true;
      onFactorActivated: () => void;
    }
  | {
      isOpen: false;
    };

export const ReadyToSpendPopover = () => {
  const { t } = useTranslation('global');
  const user = useUser();

  const tasksQueryState = useGetOnboardingUserTasksQuery();

  const [modalSlideshowType, setModalSlideshowType] =
    useState<UserTaskType | null>(null);

  const [mfaModalProperties, setMfaModalProperties] =
    useState<MFAModalProperties>({
      isOpen: false,
    });

  if (tasksQueryState.status !== 'success') {
    return null;
  }

  const tasks = tasksQueryState.data.filter((task) => {
    if (!READY_TO_SPEND_TASKS.includes(task.type)) {
      return false;
    }
    return task.type !== 'learn_to_approve' || user.is_approver;
  });

  if (tasks.length === 0) {
    return null;
  }

  const completedTasks = tasks.filter((task) => task.status === 'completed');
  if (completedTasks.length === tasks.length) {
    return null;
  }
  const progress = Math.floor((completedTasks.length / tasks.length) * 100);

  return (
    <ErrorBoundary context={{ team: 'growth', scope: 'ready-to-spend' }}>
      <Popover
        className="popover-hack-align w-full"
        placement="end-top"
        renderTrigger={(triggerProps) => (
          <>
            <button
              {...triggerProps}
              className="navigation-item h-40"
              type="button"
            >
              <div className="relative">
                <Icon
                  name="clipboard"
                  aria-hidden="true"
                  className="shrink-0"
                />
                <div className="absolute -right-8 -top-8 h-8 w-8 animate-pulse rounded-8 bg-primary-alert-default" />
              </div>
              <span className="navigation-toggle-display grow">
                {t('readyToSpend.triggerText', { progress })}
              </span>
            </button>
          </>
        )}
      >
        {() => (
          <div className="max-h-[80vh] w-[500px] overflow-y-auto p-24">
            <div className="mb-16">
              <p className="mb-8 text-secondary-bg-secondary title-xl">
                {t('readyToSpend.title')}
              </p>
              <p className="text-secondary-bg-primary body-m">
                {t('readyToSpend.subtitle')}
              </p>
            </div>
            <div className="mb-24 flex items-center gap-8">
              <label
                className="text-secondary-bg-primary title-m"
                htmlFor="ready-to-spend-progress"
              >
                {`${progress}%`}
              </label>
              <progress
                className="accent-primary h-[10px] flex-1"
                id="ready-to-spend-progress"
                max="100"
                value={progress}
              />
            </div>
            <Accordion>
              {READY_TO_SPEND_TASKS.map((taskType) => {
                const task = tasks.find(({ type }) => type === taskType);
                if (!task) {
                  return null;
                }
                return (
                  <ReadyToSpendTaskItem
                    key={task.id}
                    setModalType={setModalSlideshowType}
                    task={task}
                    openMFAModal={(callback) =>
                      setMfaModalProperties({
                        isOpen: true,
                        onFactorActivated: callback,
                      })
                    }
                  />
                );
              })}
            </Accordion>
          </div>
        )}
      </Popover>
      <ReadyToSpendModalSlideshow
        onCloseClicked={() => setModalSlideshowType(null)}
        type={modalSlideshowType}
      />
      <ModalMFAContainer
        isOpen={mfaModalProperties.isOpen}
        onModalClose={() => setMfaModalProperties({ isOpen: false })}
        onFactorActivated={() => {
          if (
            mfaModalProperties.isOpen &&
            mfaModalProperties.onFactorActivated
          ) {
            setMfaModalProperties({ isOpen: false });
            mfaModalProperties.onFactorActivated();
          }
        }}
      />
    </ErrorBoundary>
  );
};
