import { colors, Icon, type TriggerProps } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { CompanyDropdown } from 'src/core/modules/company/components/CompanyDropdown/CompanyDropdown';
import { useCompanyWalletSummariesQuery } from 'src/core/modules/company/hooks/useCompanyWalletSummariesQuery';

import { useIsMultiEntityHubPage } from '../helpers';

import './CompanyHeader.css';

type HeaderContentProps = {
  title: string;
};

const HeaderContent = ({ title }: HeaderContentProps) => {
  const { t } = useTranslation('global');
  const isMultiEntityHubPage = useIsMultiEntityHubPage();

  return (
    <>
      <Icon
        name={isMultiEntityHubPage ? 'square-4' : 'buildings-office'}
        className="navigation-company-content-shrink"
      />
      <div className="navigation-company-content navigation-company-translate">
        <h1 className="break-words body-m">
          {isMultiEntityHubPage
            ? t('organisation.reporting.links.button')
            : title}
        </h1>
      </div>
    </>
  );
};

export const CompanyHeader = () => {
  const user = useUser();
  const company = useCompany();
  const companyId = company.id;

  const companyWalletSummariesQueryState =
    useCompanyWalletSummariesQuery(companyId);

  if (companyWalletSummariesQueryState.status !== 'success') {
    return (
      <div className="navigation-company-header">
        <HeaderContent title={company.name} />
      </div>
    );
  }

  const companyWallets = companyWalletSummariesQueryState.data;

  if (
    companyWallets.length === 0 ||
    (companyWallets.length === 1 && !user.is_controller)
  ) {
    return (
      <div className="navigation-company-header">
        <HeaderContent title={company.name} />
      </div>
    );
  }

  return (
    <CompanyDropdown
      companies={companyWallets}
      className="popover-hack-align w-full"
      placement="end-bottom"
    >
      {({ isDropdown, ...triggerProps }: TriggerProps) => (
        <>
          <button
            className="navigation-company-switcher"
            {...triggerProps}
            type="button"
          >
            <HeaderContent title={company.name} />
            <Icon
              name="chevron-right"
              color={colors.contentDecorativeIcon}
              className="navigation-company-translate"
            />
          </button>
        </>
      )}
    </CompanyDropdown>
  );
};
