import { isValid } from 'date-fns';

import { type VehicleType } from '@finance-review/models/expense';
import { type I18nKey } from 'src/core/common/hooks/useTranslation';
import { metersToDistanceUnit } from 'src/core/utils/geo';
import { formatNumberToDistance } from 'src/core/utils/number';

import { type MileageScheme } from './mileageScheme';
import { type ComputationCost } from '../mileage/creation/api/useComputationCostQuery';
import { type ComputationCostV2 } from '../mileage/creation/api/useComputationCostV2Query';
import {
  type UkVehicleFuelType,
  type UkVehicleEngineSize,
} from '../mileage/creation/model';
import { type RequestAPI } from '../types';

const getSixthOfAprilOfCurrentYear = (currentDate: Date) => {
  return new Date(currentDate.getFullYear(), 3, 6, 0, 0, 0);
};

const getCurrentTaxYearForUK = (currentDate: Date) => {
  const startOfThisYearForUK = getSixthOfAprilOfCurrentYear(currentDate);
  if (currentDate < startOfThisYearForUK) {
    return currentDate.getFullYear() - 1;
  }
  return currentDate.getFullYear();
};

export function getTotalDistanceInTheYear(
  vehicleBalances: { year: number; schemeId: string; distance: number }[],
  journeyDate: Date,
  scheme: { id: string; schemeType: string; distanceUnit: 'km' | 'mi' },
) {
  if (!isValid(journeyDate)) {
    return 0;
  }
  const currentYear =
    scheme.schemeType === 'UK'
      ? getCurrentTaxYearForUK(journeyDate)
      : journeyDate.getFullYear();
  let vehicleBalancesCurrentYear = vehicleBalances.filter(
    (vehicleBalance) => vehicleBalance.year === currentYear,
  );
  if (vehicleBalancesCurrentYear.length > 1) {
    vehicleBalancesCurrentYear = vehicleBalancesCurrentYear.filter(
      (vehicleBalanceCurrentYear) =>
        vehicleBalanceCurrentYear.schemeId === scheme.id,
    );
  }
  return vehicleBalancesCurrentYear[0]?.distance ?? 0;
}

export function getTotalDistanceInTheYearFormatted(
  vehicleBalances: { year: number; schemeId: string; distance: number }[],
  journeyDate: Date,
  scheme: { id: string; schemeType: string; distanceUnit: 'km' | 'mi' },
) {
  const totalDistanceInMetersForCurrentYear = getTotalDistanceInTheYear(
    vehicleBalances,
    journeyDate,
    scheme,
  );
  return formatNumberToDistance({
    number: metersToDistanceUnit({
      distanceMeters: totalDistanceInMetersForCurrentYear,
      distanceUnit: scheme.distanceUnit,
    }),
    distanceUnit: scheme.distanceUnit,
  });
}

export const getRequestDistanceFormatted = ({
  mileageRequest,
  precision = 0,
}: {
  mileageRequest: MileageRequest;
  precision?: number;
}) => {
  const {
    mileage_allowance_request: mileageAllowanceRequest,
    mileage_scheme: { distanceUnit },
  } = mileageRequest;

  const { distance } =
    mileageAllowanceRequest.mileage_allowance_request_journeys[0];

  return formatNumberToDistance({
    number: metersToDistanceUnit({
      distanceMeters: distance,
      distanceUnit,
      precision,
    }),
    distanceUnit,
    precision,
  });
};

export type MileageRequest = Omit<RequestAPI, 'mileage_allowance_request'> & {
  mileage_allowance_request: {
    mileage_allowance_request_journeys: {
      date: string;
      path: string;
      mileage_allowance_request_journey_locations: {
        description: string;
      }[];
      distance: number;
      distance_from_map?: number | null;
    }[];
    passengers: number | null;
    vehicle: {
      id: string;
      userId: string;
      companyId: string;
      balances: { year: number; schemeId: string; distance: number }[];
      type: VehicleType;
      horsepower?: string;
      taxableHorsepower?: string;
      electric: boolean;
      // New properties:
      engineSize: UkVehicleEngineSize;
      fuelType: UkVehicleFuelType;
      ownership?: 'company' | 'personal';
    };
  };
  mileage_scheme: MileageScheme;
  computation?: ComputationCost['computation'];
  computationDetails?: ComputationCostV2 & {
    amount?: number;
  };
};

export const vehicleTypeToI18NKey = (vehicleType: VehicleType): I18nKey => {
  switch (vehicleType) {
    case 'car':
      return 'forms.mileageAllowance.car';
    case 'motorcycle':
      return 'forms.mileageAllowance.motorcycle';
    case 'bike':
      return 'forms.mileageAllowance.bike';
    default:
      return 'forms.mileageAllowance.unknownVehicle';
  }
};

export const hasOverriddenDistance = ({
  mileage_allowance_request,
}: MileageRequest) => {
  const journey =
    mileage_allowance_request.mileage_allowance_request_journeys?.[0];
  return (
    Boolean(journey?.distance_from_map) &&
    journey?.distance !== journey?.distance_from_map
  );
};
