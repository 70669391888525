import { Button, Tooltip } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { TokenInput } from 'common/components/TokenInput';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useNotifications } from 'modules/app/notifications';
import { companyAPI } from 'src/core/api/axios';
import appConfig from 'src/core/config';
import { formatCardExpirationDate } from 'src/core/modules/cards/models/formatCardExpirationDate';
import { SFSWhiteCardBackDesign } from 'src/core/modules/physical-cards/components/SFSCardDesign/SFSWhiteCardBackDesign';

import cardMaskSVG from './card-mask.svg';
import { type ApiCard } from '../../../../card';
import { CardDesign } from '../../../../components/CardDesign';
import { SFSCardBackDesign } from '../../../../components/SFSCardDesign/SFSCardBackDesign';

type Props = {
  card: ApiCard;
  goToNextStep: () => void;
  onFinish: () => void;
};

export const Step2 = ({ card, goToNextStep, onFinish }: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const company = useCompany();

  const [isActivating, setIsActivating] = useState(false);
  const [digitsValue, setDigitsValue] = useState(['', '', '', '']);

  const activate = async () => {
    setIsActivating(true);
    try {
      await activateCard({
        card,
        companyId: company.id,
        lastNumbers: digitsValue.join(''),
      });
      if (card.banking_provider === 'sfs') {
        onFinish();
      } else {
        goToNextStep();
      }
    } catch {
      dangerNotif(t('misc.somethingWrong'));
    } finally {
      setIsActivating(false);
    }
  };

  const isValid = () => {
    return (
      digitsValue.length === 4 &&
      digitsValue.every((value) => value && value !== '')
    );
  };

  const has4FirstDigits =
    card.card_visible_number &&
    card.banking_provider !== 'sfs' &&
    card.card_visible_number.length === 4 &&
    !Number.isNaN(Number(card.card_visible_number));
  const first4Digits = has4FirstDigits ? card.card_visible_number : '....';

  const cardNumbers = `${first4Digits} .... .... ${digitsValue
    .map((v) => v || '.')
    .join('')}`;

  return (
    <div className="w-[560px]">
      <h1 className="mb-24 text-primary title-xxl">
        {t('cards.activation.step2.activateYourCardTitle')}
      </h1>
      <div className="box flex flex-col items-center gap-24">
        <div className="relative">
          {card.type !== 'physical' && (
            <img
              alt="card-digits"
              className="absolute bottom-0 left-0 right-0 top-0 z-[2]"
              src={cardMaskSVG}
            />
          )}
          {card.banking_provider === 'sfs' ? (
            <>
              {company.currency === 'GBP' &&
              card.design_type === 'FAST_CARD' ? (
                <SFSWhiteCardBackDesign
                  width={400}
                  numbers={cardNumbers}
                  name={card.card_name}
                />
              ) : (
                <SFSCardBackDesign
                  width={400}
                  numbers={cardNumbers}
                  name={card.card_name}
                />
              )}
            </>
          ) : (
            <CardDesign
              width={400}
              name={card.card_name}
              brand={appConfig.environmentName === 'demo' ? 'visa' : card.brand}
              numbers={cardNumbers}
              isActivating
              expDate={formatCardExpirationDate(card.card_exp_date)}
            />
          )}
        </div>

        <form
          id="card-digits"
          className="w-full"
          onSubmit={(event) => event.preventDefault()}
        >
          <h2 className="text-primary title-m">
            {t('cards.activation.step2.enterDigits')}
          </h2>
          <TokenInput
            token={digitsValue}
            tokenLength={4}
            onTokenChange={setDigitsValue}
          />
        </form>

        <Tooltip
          content={!isValid() ? t('cards.fillDigits') : undefined}
          triggerAsChild
        >
          <Button
            onClick={activate}
            isDisabled={!isValid()}
            type="submit"
            form="card-digits"
            text={
              isActivating
                ? t('cards.activation.step2.activateLoading')
                : t('cards.activation.step2.activateCta')
            }
          />
        </Tooltip>
      </div>
    </div>
  );
};

const activateCard = async ({
  card,
  companyId,
  lastNumbers,
}: {
  card: ApiCard;
  companyId: string;
  lastNumbers: string;
}) => {
  if (card.status === 'BLO') {
    return companyAPI.put(
      `/cards/${card.id}/unpause`,
      {},
      {
        companyId,
      },
    );
  }
  return companyAPI.put(
    `/cards/${card.id}/activate`,
    { lastNumbers },
    {
      companyId,
    },
  );
};
