import { apiV2Url } from 'src/core/utils/api';

import { type ApprovalWorkflow } from '../approvalWorkflow';

export const editApprovalWorkflow = async ({
  companyId,
  approvalWorkflow,
}: {
  companyId: string;
  approvalWorkflow: ApprovalWorkflow;
}): Promise<void> => {
  // eslint-disable-next-line no-console
  const response = await fetch(
    apiV2Url(`/approval-workflows/${approvalWorkflow.id}`, companyId),
    {
      method: 'PUT',
      credentials: 'include',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(approvalWorkflow.definition),
    },
  );
  if (!response.ok) {
    throw new Error('Impossible to edit an approval workflow');
  }
};
