import { Tag } from '@dev-spendesk/grapes';
import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import { type Request, isLegacyRequest } from 'modules/requests/models/request';
import { getIsController, getUserId } from 'src/core/selectors/globalSelectors';

type RequestStateTagProps = {
  request: Request;
};

export const RequestStateTag = ({ request }: RequestStateTagProps) => {
  const { t } = useTranslation('global');
  const isUserController = useSelector(getIsController);
  const userId = useSelector(getUserId);

  const getContent = () => {
    const requester = isLegacyRequest(request)
      ? request.user
      : request.requester;
    // needed to add a security as for drafts this can be null (was not used for draft before start of the revamp)
    const isUserOwnRequest = userId === requester?.id;

    if (isUserController) {
      return t('requests.filters.toApprove');
    }
    if (isUserOwnRequest) {
      return t('requests.filters.submitted');
    }
    return t('requests.filters.pending');
  };

  switch (request.state) {
    case 'approved':
      return (
        <Tag className="ml-8" variant="success" iconName="circle-check">
          {t('requests.filters.approved')}
        </Tag>
      );
    case 'refused':
      return (
        <Tag className="ml-8" variant="alert" iconName="hexagone-cross">
          {t('requests.filters.refused')}
        </Tag>
      );
    case 'expired':
      return (
        <Tag className="ml-8" variant="warning" iconName="triangle-warning">
          {t('requests.filters.expired')}
        </Tag>
      );
    case 'rejected_by_controller':
      return (
        <Tag className="ml-8" variant="alert" iconName="hexagone-cross">
          {t('requests.filters.rejected')}
        </Tag>
      );
    default:
      return (
        <Tag className="ml-8" variant="info" iconName="clock-filled">
          {getContent()}
        </Tag>
      );
  }
};
