import { Button, ListBox } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import { useWalletTransactionForecastQuery } from 'modules/company';
import { PaymentContext } from 'modules/invoices/hooks';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { TransferListHeader } from './TransferListHeader';
import { TransferListRow } from './TransferListRow';
import { useConfirmTransfersTrackingEvents } from '../../hooks';
import {
  type Transfer,
  type TransferId,
  walletAmountAllocationToCheckForMissingFunds,
} from '../../models';
import { actions, selectors, useSelectorWithContext } from '../../redux';
import { TransferCancelModal } from '../TransferCancelModal';
import { TransferConfirmationModal } from '../TransferConfirmationModal';
import './TransferList.css';

type Props = {
  activeTransferId: TransferId;
  transfers: Transfer[];
  hasNextPage: boolean;
  totalTransfersCount: number;
  canApproveTransfers: boolean;
  onRowClick(transfer: Transfer): void;
  fetchNextPage(): void;
};

export const TransferList = ({
  activeTransferId,
  transfers,
  hasNextPage,
  totalTransfersCount,
  canApproveTransfers,
  onRowClick,
  fetchNextPage,
}: Props) => {
  const { t } = useTranslation();
  const { type } = PaymentContext.useContext();
  const confirmTransfersTrackingEvents = useConfirmTransfersTrackingEvents();
  const dispatch = useDispatch();
  const getWalletTransactionForecastQuery = useWalletTransactionForecastQuery();
  const transfersSelected = useSelectorWithContext(
    selectors.selectTransfersSelected,
  );
  const transfersSelectedCount = useSelectorWithContext(
    selectors.selectTransfersSelectedCount,
  );

  const [transfersToConfirm, setTransfersToConfirm] = useState<Transfer[]>([]);
  const [transfersToCancel, setTransfersToCancel] = useState<Transfer[]>([]);

  const onConfirmTransfer = (transfer: Transfer) => {
    confirmTransfersTrackingEvents.onConfirmTransfersConfirmationModalOpened({
      transfers: [transfer],
    });
    setTransfersToConfirm([transfer]);
  };

  const onCancelTransfer = (transfer: Transfer) => {
    confirmTransfersTrackingEvents.onConfirmTransfersRejectionModalOpened({
      transfers: [transfer],
    });
    setTransfersToCancel([transfer]);
  };

  const resetTransfersToConfirm = () => {
    confirmTransfersTrackingEvents.onConfirmTransfersConfirmationModalClosed({
      transfers: transfersToConfirm,
    });
    setTransfersToConfirm([]);
  };
  const resetTransfersToCancel = () => {
    confirmTransfersTrackingEvents.onConfirmTransfersRejectionModalClosed({
      transfers: transfersToCancel,
    });
    setTransfersToCancel([]);
  };

  const buildTransfersSelectionUpdate = (transfersToUpdate: Transfer[]) => {
    return () => {
      dispatch(
        actions.unselectTransfers({
          transfersToUnselect: transfersToUpdate,
          type,
        }),
      );
    };
  };

  return (
    <section className="TransferList__urgentPayments">
      <h3 className="mb-16 text-primary title-l">
        {t('invoices.transfer.pageTitle', {
          count: totalTransfersCount,
          context: canApproveTransfers ? 'approver' : 'controller',
        })}
      </h3>
      <ListBox
        className="TransferList__table"
        options={transfers}
        onOptionClick={onRowClick}
        getIsOptionActive={(transfer) => transfer.id === activeTransferId}
        getOptionId={(transfer) => transfer.id}
        checkedOptionIds={transfersSelected.map((transfer) => transfer.id)}
        onOptionChange={(checkedTransfer, _, isChecked) => {
          if (isChecked) {
            track(AnalyticEventName.FINANCE_CONFIRM_TRANSFER_SELECTED, {
              category: 'invoice',
              selectionType: 'single',
            });
          }
          dispatch(
            actions.setTransfersSelected({
              transfersSelected: isChecked
                ? transfersSelected.concat(checkedTransfer)
                : transfersSelected.filter(
                    (transfer) => transfer.id !== checkedTransfer.id,
                  ),
              type,
            }),
          );
        }}
        onAllOptionsChange={(checkedTransfers, _, isChecked) => {
          if (isChecked) {
            track(AnalyticEventName.FINANCE_CONFIRM_TRANSFER_SELECTED, {
              category: 'invoice',
              selectionType: 'select_all',
            });
          }
          dispatch(
            actions.setTransfersSelected({
              transfersSelected: isChecked ? checkedTransfers : [],
              type,
            }),
          );
        }}
        header={
          <TransferListHeader
            selectedTransfersCount={transfersSelectedCount}
            totalTransfersCount={totalTransfersCount}
          />
        }
        footer={
          hasNextPage ? (
            <div className="mx-auto my-16">
              <Button
                variant="secondaryNeutral"
                text={t('misc.loadMore')}
                onClick={fetchNextPage}
              />
            </div>
          ) : undefined
        }
      >
        {(transfer) => (
          <TransferListRow
            transfer={transfer}
            canApproveTransfers={canApproveTransfers}
            onConfirmTransfer={onConfirmTransfer}
            onCancelTransfer={onCancelTransfer}
            getWalletTransactionForecastQueryForTransfer={() =>
              getWalletTransactionForecastQuery(
                transfer.amount,
                walletAmountAllocationToCheckForMissingFunds,
              )
            }
          />
        )}
      </ListBox>
      <TransferConfirmationModal
        isOpen={transfersToConfirm.length > 0}
        onClose={resetTransfersToConfirm}
        onConfirm={buildTransfersSelectionUpdate(transfersToConfirm)}
        transfers={transfersToConfirm}
      />
      <TransferCancelModal
        isOpen={transfersToCancel.length > 0}
        onClose={resetTransfersToCancel}
        onConfirm={buildTransfersSelectionUpdate(transfersToCancel)}
        transfers={transfersToCancel}
      />
    </section>
  );
};
