import { Skeleton } from '@dev-spendesk/grapes';
import React from 'react';

import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';

import { TextFitter } from '../../../TextFitter';

const BLOCK_WIDTH = '145px';
const BLOCK_HEIGHT = '164px';

export const EntityItemActionsGroup = ({
  title,
  contents,
}: {
  title: string;
  contents: React.ReactNode[];
}) => (
  <div
    className="flex h-full flex-col gap-8 rounded-8"
    style={{ width: BLOCK_WIDTH, height: BLOCK_HEIGHT }}
  >
    <TextFitter maxLines={2}>
      <EllipsisTooltip text={title} className="text-primary title-m" />
    </TextFitter>
    <div className="flex flex-col gap-8">
      {React.Children.map(contents, (content) => (
        <div key={`entity-item-actions-group-${content?.toString()}`}>
          {content}
        </div>
      ))}
    </div>
  </div>
);

export const EntityStatisticBlockSkeleton = () => (
  <Skeleton
    width={BLOCK_WIDTH}
    height={BLOCK_HEIGHT}
    className="flex rounded-8"
  />
);
