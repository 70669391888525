import { TimelineItem } from '@dev-spendesk/grapes';

import { type I18nKey, useTranslation } from 'common/hooks/useTranslation';

import { SupplierChangeFieldValue } from './SupplierChangeFieldValue';
import { UpdaterAvatar } from './UpdaterAvatar';
import {
  type SupplierDetailsField,
  type SupplierDetailsChange,
} from '../../models';

export type Props = {
  supplierChange: SupplierDetailsChange;
};

export const SupplierChangeTimelineItem = ({ supplierChange }: Props) => {
  const { t } = useTranslation('global');

  const fieldLabel = t(historyModalFieldsToI18nKeys[supplierChange.field]);

  return (
    <div className="text-left">
      <TimelineItem date={new Date(supplierChange.updatedAt)}>
        <section>
          <div className="flex items-center">
            <UpdaterAvatar supplierChange={supplierChange} />
            <div className="body-m">
              {supplierChange.updater && (
                <span className="title-m">
                  {t('suppliers.latestUpdater.historyModal.changeDescription', {
                    field: fieldLabel,
                    username: supplierChange.updater.name,
                  })}
                </span>
              )}

              {!supplierChange.updater && (
                <span className="title-m">
                  {t(
                    'suppliers.latestUpdater.historyModal.integrationChangeDescription',
                    {
                      field: fieldLabel,
                    },
                  )}
                </span>
              )}
            </div>
          </div>
          <SupplierChangeFieldValue
            value={supplierChange.newValue}
            className="mt-8"
          />
          <SupplierChangeFieldValue
            value={supplierChange.oldValue}
            strike
            className="mt-8"
          />
        </section>
      </TimelineItem>
    </div>
  );
};

const historyModalFieldsToI18nKeys: Record<SupplierDetailsField, I18nKey> = {
  accountHolderName:
    'suppliers.latestUpdater.historyModal.fields.accountHolderName',
  accountCode: 'suppliers.latestUpdater.historyModal.fields.accountCode',
  accountNumber: 'suppliers.latestUpdater.historyModal.fields.accountNumber',
  bankCountry: 'suppliers.latestUpdater.historyModal.fields.bankCountry',
  bic: 'suppliers.latestUpdater.historyModal.fields.bic',
  iban: 'suppliers.latestUpdater.historyModal.fields.iban',
  routingNumber: 'suppliers.latestUpdater.historyModal.fields.routingNumber',
  sortCode: 'suppliers.latestUpdater.historyModal.fields.sortCode',
  legalName: 'suppliers.latestUpdater.historyModal.fields.legalName',
  registrationNumber:
    'suppliers.latestUpdater.historyModal.fields.registrationNumber',
  vatNumber: 'suppliers.latestUpdater.historyModal.fields.vatNumber',
  address: 'suppliers.latestUpdater.historyModal.fields.address',
  city: 'suppliers.latestUpdater.historyModal.fields.city',
  country: 'suppliers.latestUpdater.historyModal.fields.country',
  zipcode: 'suppliers.latestUpdater.historyModal.fields.zipcode',
};
