import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { type EntityWalletSummaryBreakdown } from '../../../../types';
import {
  BreakdownGauge,
  type BreakdownGaugeBar,
  BreakdownGaugeLegend,
} from '../../../common/BreakdownGauge';

type EntityBreakdownBlockProps = {
  currency: string;
  breakdown: EntityWalletSummaryBreakdown;
  isInactive?: boolean;
};

export const WalletSummaryBreakdown = ({
  currency,
  breakdown,
  isInactive = false,
}: EntityBreakdownBlockProps) => {
  const { t } = useTranslation('global');

  const formatAsMonetaryValue = (amount: number): string =>
    formatMonetaryValue({
      amount,
      currency,
      precision: 2,
    });

  const breakdownBars: BreakdownGaugeBar[] = [
    {
      key: 'scheduled-transfers',
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.scheduledTransfers.label',
      ),
      value: {
        raw: breakdown.scheduledTransfers,
        formatted: formatAsMonetaryValue(breakdown.scheduledTransfers),
      },
    },
    {
      key: 'card-allocations',
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.cardsLimits.label',
      ),
      value: {
        raw: breakdown.cardsLimits,
        formatted: formatAsMonetaryValue(breakdown.cardsLimits),
      },
    },
    {
      key: 'available',
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.available',
      ),
      value: {
        raw: breakdown.available,
        formatted: formatAsMonetaryValue(breakdown.available),
      },
    },
  ];

  return (
    <div>
      <BreakdownGauge
        bars={breakdownBars}
        trend={breakdown.trend}
        isInactive={isInactive}
      >
        {(preparedBars) => (
          <BreakdownGaugeLegend preparedBars={preparedBars} isCondensed />
        )}
      </BreakdownGauge>
    </div>
  );
};
