import React, { useEffect } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { openSupportChat } from 'src/core/utils/supportChat';

export const IsApproverErrorMessage = () => {
  const { pushNotif } = useNotifications();
  const { t } = useTranslation('global');

  useEffect(
    () =>
      pushNotif({
        type: NotificationType.Danger,
        message: (
          <>
            <p>
              {t(
                'invoices.transfer.errorState.fetchApprovalAuthorisationError',
              )}
            </p>
            <p className="mt-16">
              {t('invoices.transfer.errorState.contactUsIfApprover')}
            </p>
          </>
        ),
        action: {
          text: t('misc.errorState.contactSupport'),
          onClick: () => {
            openSupportChat();
          },
        },
      }),
    [],
  );

  return null;
};
